/*
Corner Ribbon

Styleguide 2.12.0
*/

/*
Corner Ribbon - Simple example

Just add the class into a container

markup:
<div class="corner-ribbon" style="width:200px; height:200px">
</div>

Styleguide 2.12.1
*/

@include block(corner-ribbon) {
  position: relative;
  overflow: hidden;
  &:before {
    content: 'Corner Ribbon';
    width: 210px;
    position: absolute;
    top: 21px;
    left: -50px;
    text-align: center;
    line-height: 2.5;
    letter-spacing: 1px;
    color: $c_moen-bright-blue;
    transform: rotate(-32deg);
    background-color: $c_concrete;
    text-transform: uppercase;
    font-family: $din-bold;
    font-size: em(12px);
  }
}


/*
Corner Ribbon - with an Image

markup:
<div class="corner-ribbon">
  <img src="https://embed.widencdn.net/img/moen/6lv0hxcp5x/x200px/WSL84502SRN-glam5.jpeg?u=4wu0a0">
</div>

Styleguide 2.12.2
*/
