
@include plmr-component('baidu') {

  @include element('container'){
    width: 100%;
    height: 100vh;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    @include media($bp-md) {
      width: 100%;
      height: 100vh;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
    @include media($bp-lg) {
      width: 100%;
      height: 719px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      overflow: hidden;
    }
  }
  @include element(containerMap){
    width: 100%;
    height: 50vh;
    float: left;
    @include media($bp-md) {
      width: 100%;
      height: 50vh;
    }
    @include media($bp-lg) {
      width: 65%;
      height: 100vh;
    }
  }
  @include element(search){
    width: 100%;
    float: left;
    text-align: center;
    font-size: 16px;
    @include media($bp-md) {
      width: 100%;
      float: left;
      text-align: center;
      font-size: 16px;
    }
    @include media($bp-lg) {
      width: 35%;
      float: left;
      text-align: center;
      font-size: 16px;
    }
  }
  @include element(content){
    margin: 32px 0 16px 0;
  }
  @include element(input){
    position: relative;
    width: 85%;
    margin: 0 auto;
    input{
      width: 100%;
      height: 40px;
      padding:0 8px;
      border: 1px solid #ccc;
      outline:none;
    }

    #search_clear{
      display:none;
      position: absolute;
      top: 10px;
      right: 16px;
      cursor: pointer;
    }
    .search_box{
      display:none;
      border:1px solid #C0C0C0;
      width:100%;
      position: absolute;
      top: 39px;
      background: #ffffff;
      z-index: 100;
      text-align: left;
      padding: 10px;
    }
    .store-container h2{
      font-size:18px;
      font-weight: 700;

      color: #222;

    }
    .search-item{
      line-height: 22px;
      color: #999;
      padding: 10px;
      cursor: pointer;
    }

  }
  @include element(nearby-content){
    position: relative;
    border-top: 1px solid #ccc;
    padding: 20px 20px 20px 40px;
    font-size: 13px;
    color: #555;
    cursor: pointer;
    border-left: 5px solid #fff;
  }
 @include element(active){
    border-left: 5px solid #5b7f95;
  }
  @include element(nearby){
    height: 215px;
    margin-top: 30px;
    overflow-y: auto;
    overflow-x: hidden;

    @include media($bp-md) {
      height: 214px;
      margin-top: 30px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    @include media($bp-lg) {

      height: 580px;
      margin-top: 30px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  @include element(nearby-information){
    text-align: left;
  }
  @include element(earby-type){
    margin-bottom: 8px;
  }
  @include element(nearby-store){
    margin-bottom: 8px;
  }
  @include element(nearby-type){
    font-weight: 700;
  }
  @include element(nearby-icon){
    width: 26px;
    height: 38px;
    position: absolute;
    left: 4px;
    top: 20px;
  }
  @include element(nearby-distance){
    display:flex;
    position: absolute;
    top:10px;
    right: 10px;
  }
  @include element(nearby-store){
    font-size: 16px;
  }
  @include element(nearby-address){
    color: #999;
  }
}

