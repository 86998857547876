/*
The Tap Grip

The Grid for The Tap

*/

$playfair: 'Playfair Display', serif;

@include block(tap) {
	@include element(grid-container) {
		position: relative;
		padding: 0 10px;
		z-index: 2;
		@include media($bp-md) {
			padding: 0 20px;
		}
		@include media($bp-lg) {
			padding: 0 30px;
		}
		@include media($bp-xl) {
			max-width: 1400px;
			margin: 0 auto;
			overflow: hidden;
		}
	}
	@include element(grid) {
		position: relative;
		z-index: 2;
		transition: all 0.5s ease-in-out;
		@include modifier(pillar) {
			.tap__grid-item .tap__grid-item__inner .tag {
				display: none !important;
			}
		}
	}
	//	THE GRID
	@include element(grid-sizer) {
		width: 100%;
		@include media($bp-sm) {
			width: 1/2*100%;
		}
		@include media($bp-md) {
			width: 1/3*100%;
			.tap-grid-tray-open & {
				width: 1/2*100%;
			}
		}
		@include media($bp-lg) {
			width: 1/3*100%;
			.tap-grid-tray-open & {
				width: 1/3*100%;
			}
		}

	}
	@include element(grid-item) {
		position: relative;
		width: 100%;
		&:before {
			content:' ';
			display: block;
			width: 100%;
			padding-top: 100%;
		}

		@include element(inner) {
			position: absolute;
			top:10px;
			left:10px;
			right:10px;
			bottom:10px;
			width: calc(100% - 20px);
			height: calc(100% - 20px);
			background-color: #FFF;
			overflow: hidden;
			border: solid 1px $c_moen-bright-gray;
			@include element(bg) {
				display: block;
				position: absolute;
				top:0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				text-decoration: none;
				outline: none;
				picture,img {
					display: block;
					position: absolute;
					top:0;
					left:0;
					width: 100%;
					height: 100%;
				}
			}
			@include element(contents) {
				position: absolute;
				z-index: 2;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				background-image: linear-gradient(-180deg, rgba(255,255,255,0.00) 39%, rgba(152,153,153,0.45) 82%, #727374 100%);
				pointer-events: none;
				opacity: 1;
				transition: opacity 0.5s ease;
				& > * {
					pointer-events: auto;
				}
				.post-title {
					position: absolute;
					bottom:20px;
					left:20px;
					width: calc(100% - 40px);
				}
			}
			@include element(hover) {
				position: absolute;
				z-index: 2;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				background-color: rgba($c_moen-gray,0.7);
				pointer-events: none;
				opacity: 0;
				transition: opacity 0.5s ease;
				color: #FFF;
				& > div {
					display: block;
					position: absolute;
					bottom: 20px;
					left:20px;
					width: calc(100% - 40px);
					p {
						display: block;
					}
					.post-title {
						margin-bottom: 13px;
					}
					p:not(.post-title) {
						font: normal 18px/26px $proxima-regular;
						color: #FFF;
						margin-bottom: 30px;
					}
					.read-more {
						display: inline-block;
						color: #FFF;
						font: normal 14px/14px $din-bold;
						text-decoration: none;
						&:after {
							content: ' ';
							display: inline-block;
							vertical-align: middle;
							width: 6px;
							height: 10px;
							background-image: url('images/icon-angle_right_white.svg');
							background-repeat: no-repeat;
							background-size: auto 100%;
							background-position: center center;
							margin-left: 10px;
						}
					}
				}
			}
			a {
				cursor: pointer;
				text-decoration: none;
				outline: none;
			}
			.post-title {
				font: bold 21px/24px $playfair;
				color: #FFF;
				margin: 0;
				padding: 0;
			}
			.tag {
				position: absolute;
				top:10px;
				left:10px;
				z-index: 3;
			}
			.favorite {
				position: absolute;
				top: 10px;
				right:10px;
				z-index: 3;
				width: auto;
				.favorite__icon {
					display: block;

					// This should probably be added to the modifier to _add-favorite but it wasn't working...	¯\_(ツ)_/¯
					img {
						display: none;
						width: 18px;
						height: 18px;
						&.favorite__icon__unliked {
							display: inline-block;
						}
					}
					&:not(.is-active):hover {
						img {
							&.favorite__icon__unliked,&.favorite__icon__liked {
								display: none;
							}
							&.favorite__icon__hover {
								display: inline-block;
							}
						}
					}
					&.is-active {
						img {
							&.favorite__icon__unliked,&.favorite__icon__hover {
								display: none !important;
							}
							&.favorite__icon__liked {
								display: inline !important;
							}
						}
					}



				}
			}
			@include media($bp-lg) {
				&:not(.nohover):hover {
					.tap__grid-item__inner__contents {
						opacity: 0;
					}
					.tap__grid-item__inner__hover {
						opacity: 1;
						& > * {
							pointer-events: auto;
						}
					}
				}
			}

		}
		@include media($bp-sm) {
			width: 1/2*100%;
		}
		@include media($bp-md) {
			width: 1/3*100%;
			.post-title {
				font: bold 18px/21px $playfair;
			}
			.tap-grid-tray-open & {
				width: 1/2*100%;
			}
		}
		@include media($bp-lg) {
			width: 1/3*100%;
			.post-title {
				font: bold 21px/24px $playfair;
			}
			.tap-grid-tray-open & {
				width: 1/3*100%;
			}
		}

		@include modifier(width2x2) {
			@include media($bp-sm) {
				width: 100%;
			}
			@include media($bp-md) {
				width: 2/3*100%;

				.post-title {
					font: bold 24px/28px $playfair;
					bottom:30px;
					left:30px;
					width: calc(100% - 60px);
				}
				.tap__grid-item__inner__hover > div {
					bottom:30px;
					left:30px;
					width: calc(100% - 60px);
				}
				.tap-grid-tray-open & {
					width: 100%;
				}
			}
			@include media($bp-lg) {
				width: 2/3*100%;
				.post-title {
					font: bold 28px/32px $playfair;
				}
				.tap-grid-tray-open & {
					width: 2/3*100%;
				}
			}
		}
		@include modifier(width2x1) {
			&:before {
				padding-top: 50%;
			}
			.post-title {
				font: bold 18px/21px $playfair;
			}
			@include media($bp-sm) {
				width: 100%;
			}
			@include media($bp-md) {
				width: 2/3*100%;
				.post-title {
					font: bold 18px/21px $playfair;
				}
				.tap-grid-tray-open & {
					width: 100%;
				}
			}
			@include media($bp-lg) {
				width: 2/3*100%;
				.post-title {
					font: bold 21px/24px $playfair;
				}
				.tap-grid-tray-open & {
					width: 2/3*100%;
				}
			}
			/*
                        @include media(rem(1400)) {
                            width: 2/4*100%;

                            .tap-grid-tray-open & {
                                width: 2/4*100%;
                            }
                        }
                        @include media(rem(2000)) {
                            width: 2/5*100%;

                            .tap-grid-tray-open & {
                                width: 2/5*100%;
                            }
                        }
            */
		}
		@include modifier(width1x2) {
			&:before {
				padding-top: 200%;
			}
			@include media($bp-sm) {
				width: 1/2*100%;
			}
			@include media($bp-md) {
				width: 1/3*100%;
				.post-title {
					font: bold 18px/21px $playfair;
				}
				.tap-grid-tray-open & {
					width: 1/2*100%;
				}
			}
			@include media($bp-lg) {
				width: 1/3*100%;
				.post-title {
					font: bold 21px/24px $playfair;
				}
				.tap-grid-tray-open & {
					width: 1/3*100%;
				}
			}
			/*
                        @include media(rem(1400)) {
                            width: 1/4*100%;

                            .tap-grid-tray-open & {
                                width: 1/4*100%;
                            }
                        }
                        @include media(rem(2000)) {
                            width: 1/5*100%;

                            .tap-grid-tray-open & {
                                width: 1/5*100%;
                            }
                        }
            */
		}

		@include modifier(inspiration) {
			color: $c_moen-maroon;
		}
		@include modifier(diy) {
			color: $c_moen-gray;
		}
		@include modifier(support) {
			color: $c_moen-green;
		}
		@include modifier(pros) {
			color: $c_moen-dark-blue;
		}
		@include modifier(product) {
			display: block;
			.tap__grid-item__inner__bg, .tap__grid-item__inner__bg img, .tap__grid-item__inner__bg picture {
				height: auto;
			}
			@include element(knockout) {
				position: absolute;
				bottom:0;
				left:0;
				right:0;
				background-color: #FFF;
				width: 100%;
				height: 25%;
				z-index: 9;
				img {
					display: block;
					width: 62%;
					height: auto;
					margin: 0 auto;
					position: absolute;
					bottom:30px;
					left:0;
					right:0;
				}
			}
			.tap__grid-item__inner .tap__grid-item__inner__hover {
				& > div {
					top: 20px;
					bottom: auto;
					@include media($bp-lg) {
						top: 30px;
					}
				}
			}
		}
		@include modifier(video) {
			&:after {
				content: ' ';
				display: block;
				background-image: url('images/icon-play-white.svg');
				background-repeat: no-repeat;
				background-size: 40px auto;
				background-position: center center;
				background-color: currentColor;
				width: 42px;
				height: 30px;
				border-radius: 8px;
				z-index: 5;
				position: absolute;
				top: 0;
				left:0;
				right:0;
				bottom:0;
				margin: auto;
				pointer-events: none;
			}
		}
		@include modifier(twitter) {
			display: block;
			color: #FFF !important;
			.tap__grid-item__inner {
				background-color: #00ACED;
				padding-bottom: 70px;
				overflow: hidden;

			}
			a {
				color: inherit;
				text-decoration: none;
			}
			.twitter-tweet {
				font: normal 18px/24px $proxima-regular;
				color: #FFF;
				padding: 24px 20px 0;
				max-height: 100%;
				overflow: scroll;
				@include media($bp-md) {
					font: normal 14px/18px $proxima-regular;
				}
				@include media($bp-lg) {
					font: normal 18px/24px $proxima-regular;
					.tap-grid-tray-open & {
						font: normal 14px/18px $proxima-regular;
					}
				}
				@include media($bp-xl) {
					font: normal 18px/24px $proxima-regular;
					.tap-grid-tray-open & {
						font: normal 18px/24px $proxima-regular;
					}
				}
				a {
					text-decoration: underline;
				}
			}
			.twitter-info {
				position: absolute;
				bottom:0;
				left:0;
				height: 70px;
				width: 100%;
				padding: 0 20px 20px 60px;
				&:before {
					content: ' ';
					display: block;
					position: absolute;
					left:20px;
					bottom:40px;
					top: 10px;
					margin: auto 0;
					width: 25px;
					height: 21px;
					background-image: url('images/twitter-white.svg');
					background-repeat: no-repeat;
					background-size: 100% auto;
					background-position: center center;
				}
				p {
					margin:0;
					padding:0;
				}
				.twitter-name {
					display: block;
					font: bold 18px/24px $playfair;
				}
				.twitter-handle {
					display: block;
					font: normal 16px/20px $proxima-regular;
				}
				.twitter-follow {
					display: block;
					position: absolute;
					right:20px;
					bottom:27px;
				}
			}
		}
		@include modifier(instagram) {
			display: block;
			p {
				margin:0;
				padding:0;
			}
			.instagram-photo {
				width: 100%;
				height: auto;
			}
			.instagram-info {
				padding: 8px;
			}
			.instagram-profile {
				width: 32px;
				height: 32px;
				border-radius: 100%;
				border: solid 0.5px $c_moen-bright-gray;
				overflow: hidden;
				display: inline-block;
				vertical-align: middle;
				margin-right: 10px;
				img {
					width: 100%;
					height: auto;
				}
			}
			.instagram-handle {
				display: inline-block;
				vertical-align: middle;
				//				margin-right: 13px;
				color: #3D719D;
				font: normal 14px/12px $proxima-bold;

			}
			.instagram-time {
				display: inline-block;
				vertical-align: middle;
				color: #C9C8CD;
				font: normal 14px/12px $proxima-bold;
				&:before {
					content: ' ';
					display: inline-block;
					vertical-align: middle;
					width: 3px;
					height: 3px;
					border-radius: 100%;
					background-color: #C9C8CD;
					margin-right: 13px;
				}
			}
			.instagram-follow {
				display: inline-block;
				vertical-align: middle;
				float: right;
				img {
					width: 50px;
					height: auto;
					margin-top: 6px;
					@include media(1110px) {
						margin-top: 2px;
						width: 75px;
					}
				}
			}
			.instagram-image {

			}
		}
		@include modifier(facebook) {
			display: block;
			.tap__grid-item__inner {
				padding-bottom: 50px;
			}
			.facebook-info {
				padding: 15px;
			}
			.facebook-profile {
				width: 40px;
				height: 40px;
				border: solid 1px $c_moen-bright-gray;
				margin-right: 10px;
				display: inline-block;
				vertical-align: bottom;
				display: none;
				@include media($bp-lg) {
					display: inline-block;
					.tap-grid-tray-open & {
						display: none;
					}
				}
				@include media($bp-xl) {
					display: inline-block;
					.tap-grid-tray-open & {
						display: inline-block;
					}
				}
			}
			.facebook-meta {
				display: inline-block;
				vertical-align: bottom;
			}
			.facebook-name {
				display: block;
				color: #365899;
				font: normal 14px/12px $proxima-bold;
				margin:0;
				padding:0;
				margin-bottom: 6px;
			}
			.facebook-date {
				display: block;
				color: #9094A3;
				font: normal 12px/12px $proxima-regular;
				margin:0;
				padding:0;
			}
			.facebook-logo {
				position: absolute;
				width: 25px;
				height: 25px;
				top: 22px;
				right: 22px;
			}
			.facebook-post {
				max-height: calc(100% - 75px);
				padding: 0 15px;
				overflow: scroll;
				p {
					font: normal 14px/21px $proxima-regular;
					color: #1D2129;
				}
				a {
					font: inherit;
					color: #365899;
				}
			}
			.facebook-like {
				position: absolute;
				bottom:0;
				left:0;
				right:0;
				width: 100%;
				height: 50px;
				background-color: #F6F7F9;
				padding: 14px;
			}

		}
		@include modifier(slider) {
			display: block;
			.slick-slider {
				visibility: hidden;
				&.slick-initialized {
					visibility: visible;
				}
			}
			.tap__grid-item__inner__bg, .tap__grid-item__inner__bg img, .tap__grid-item__inner__bg picture {
				position: static;
			}

			.tap__grid-item__inner__contents {
				.post-title {
					bottom:43px;
					@include media($bp-md) {
						bottom: 63px;
					}
				}
			}

			.slick-next, .slick-prev {
				width: 20px;
				height: 50px;
				background-image: url('images/icon-arrow-med-white.svg');
				background-size: 100% auto;
				background-position: center center;
				outline: none;
				z-index: 9;
			}
			.slick-next {
				right:15px;
				@include media($bp-md) {
					right: 20px;
				}
			}
			.slick-prev {
				left:15px;
				transform: translateY(-50%) scale(-1,1);
				@include media($bp-md) {
					left: 20px;
				}
			}
			.slick-dots {
				display: block;
				width: 100%;
				text-align: center;
				height: 23px;
				margin:0;
				padding:0;
				left:0;
				right:0;
				z-index: 9;
				font-size: 0;
				line-height: 0;
				position: absolute;
				bottom: 10px;
				@include media($bp-md) {
					bottom: 20px;
				}
				li {
					display: inline-block;
					margin: 0 2.5px;
					width: 30px;
					height: 23px;
					opacity: 0.5;
					transition: opacity 0.5s ease;
					button {
						display: block;
						position: static;
						width: 30px;
						height: 23px;
						border: none;
						box-shadow: none;
						margin:0;
						padding:0;
						background-color: #FFF;
						border-radius: 0;
						outline: none;
						background-clip: content-box;
						padding: 10px 0;
						&:before {
							display: none;
						}
					}
					&.slick-active,&:hover {
						opacity: 1;
					}
				}
			}

		}


	}

	@include element(grid-load) {
		margin: 50px auto;
		padding:0;
		text-align: center;
		.button {
			min-width: 160px;
		}
	}

	// END THE GRID

	/*
    // Standalone Modifier
        @include element(scrubber-container) {
            width: calc(100% - 34px);
            margin: 25px auto;
            position: relative;
            left: 10.5px;
        }
        @include element(scrubber) {
            display: none;
            background: #E5E5E5;
            box-shadow: inset 0 1px 2px 0 rgba(150,150,150,0.25);
            border-radius: 8px;
            position: relative;
            width: 87.5%;
    // 		width: calc(100% - 40px);
            width: calc(100% - 21px);
            margin: 0;
            height: 7px;
            &, * {
                -webkit-touch-callout: none;
                    -webkit-user-select: none;
                     -khtml-user-select: none;
                         -moz-user-select: none;
                          -ms-user-select: none;
                                  user-select: none;

            }
            b {
                position: absolute;
                top:50% !important;
                transform: translate(-50%,-50%);
                z-index: 2;
                border-radius: 100%;
                width: 21px;
                height: 21px;
                background: $c_moen-bright-blue;
                box-shadow: 0 0 2px 0 rgba(74,74,74,0.75);
                &:before {
                    content:' ';
                    display: block;
                    position: absolute;
                    top:50%;
                    left:50%;
                    transform: translate(-50%,-50%);
                    width: 7.74px;
                    height: 7.74px;
                    background: #FFFFFF;
                    box-shadow: inset 0 1px 1px 0 rgba(114,115,116,0.50);
                    border-radius: 100%;
                }
            }
        }
        @include element(scrubber-progress) {
            display: block;
            position: absolute;
            top: 0;
            left:0;
            bottom:0;
            width: 0%;
            height: 100%;
            background-color: $c_moen-bright-blue;
            z-index: 1;
            border-radius: 8px;
        }
    */
	@include modifier(standalone) {
		margin: 0 auto;
		width: 100%;
		max-width: 1280px;
		@include media($bp-md) {
			width: calc(100% - 20px);
		}
		@include media($bp-lg) {
			width: calc(100% - 20px);
		}
		@include media($bp-xl) {
			width: calc(100% - 60px);
		}
		@include element(icon-menu) {
			display: block;
			ul {
				list-style: none;
				margin: 30px 0;
				padding: 0;
				text-align: center;
				@include media($bp-lg) {
					.flexwrap & {
						display: flex;
						flex-wrap: wrap;
						flex-direction: row;
						align-items: center;
						align-content: space-between;
						justify-content: space-between;
					}
					&:hover {
						li {
							opacity: 0.5;
						}
					}
				}
				li {
					display: block;
					transition: opacity 0.25s ease;
					margin-bottom: 10px;
					@include media($bp-md) {
						display: inline-block;
						margin:0 25px 10px;
					}
					@include media($bp-lg) {
						margin:0 37.5px 10px;
						.flexwrap & {
							flex-grow: 1;
							flex-shrink: 0;
							flex-basis: auto;
							margin:0 25px 10px;
						}
						&:hover {
							opacity: 1 !important;
						}
					}

					a {
						display: inline-block;
						color: $c_moen-gray;
						font: normal 15px/18px $proxima-bold;
						text-transform: uppercase;
						letter-spacing: 0.47px;
						text-decoration: none !important;
						img {
							margin: 0 10px 0 0;
							display: inline-block;
							width: auto;
							height: auto;
							max-width: 22px;
							max-height: 22px;
						}
					}
				}
			}
		}

		&>.text-center {
			max-width: 540px;
			margin: 0 auto;
			padding: 0 20px;
		}
		/*
                .tap__scrubber {
                    display: block;
                    @include media($bp-md) {
                        display: none;
                    }
                }
        */

		.tap__grid-container {
			@media(max-width: $bp-sm-max) {
				padding-left: 0;
				padding-right: 0;

				.tap__grid.slick-slider {
					width: calc(100% - 30px);
					margin: 0 auto;
					* {
						outline: none;
					}
					& > .slick-list {
						//max-width: 252px;
						margin: 0 auto;

						max-width: calc(100% - 50px);

					}
					&>.slick-prev,&>.slick-next {
						background-image: url('images/icon-arrow_right-blue.svg');
						width: 12px;
						height: 21px;
						background-size: 12px 21px;
						background-position: center center;
						outline: none;
						&.slick-disabled {
							opacity: 0.5;
						}
					}
					&>.slick-prev {
						left: 0;
						transform: scale(-1,-1) translateY(50%)
					}
					&>.slick-next {
						right: 0;
					}
				}

			}
		}
		.tap__grid {
			@media(max-width: $bp-sm-max) {
				white-space: nowrap;
				overflow: hidden;
				max-width: 100%;
			}
		}
		.tap__grid-sizer {
			@include media($bp-sm) {
				width: 100%;
			}
			@include media($bp-md) {
				width: 1/4*100%;
			}
			@include media($bp-lg) {
				width: 1/4*100%;
			}
		}
		.tap__grid-item {
			@media(max-width: $bp-sm-max) {
				/*
                                min-width: 252px;
                                min-height: 252px;
                                max-width: 252px;
                                max-height: 252px;
                */
				width: 100%;
				display: inline-block;
				white-space: normal;
				&:before {
					padding-top: 100% !important;
				}
			}
			@include media($bp-sm) {
				width: 100%;
			}
			@include media($bp-md) {
				width: 1/4*100%;
			}
			@include media($bp-lg) {
				width: 1/4*100%;
			}

			@include modifier(width2x2) {
				@include media($bp-sm) {
					width: 100%;
				}
				@include media($bp-md) {
					width: 2/4*100%;

				}
				@include media($bp-lg) {
					width: 2/4*100%;
				}
			}
			@include modifier(width2x1) {
				@media(max-width: $bp-sm-max) {
					.tap__grid-item__inner__bg, .tap__grid-item__inner__bg img, .tap__grid-item__inner__bg picture {
						top:50%;
						left:50%;
						transform: translate(-50%,-50%);
						height: 100%;
						width: auto;
					}
				}
				@include media($bp-sm) {
					width: 100%;
				}
				@include media($bp-md) {
					width: 2/4*100%;
				}
				@include media($bp-lg) {
					width: 2/4*100%;
				}

			}
			@include modifier(width1x2) {
				@media(max-width: $bp-sm-max) {
					.tap__grid-item__inner__bg, .tap__grid-item__inner__bg img, .tap__grid-item__inner__bg picture {
						top:50%;
						left:50%;
						transform: translate(-50%,-50%);
						width: 100%;
						height: auto;
					}
				}
				@include media($bp-sm) {
					width: 100%;
				}
				@include media($bp-md) {
					width: 1/4*100%;
				}
				@include media($bp-lg) {
					width: 1/4*100%;
				}

			}
		}
	}
} // END Standalone Modifier
// TRAY TOGGLE
.tap-grid-tray-open {
	.tap {
		@include media($bp-md) {
			.tap__grid {
				margin-left: 290px;
				width: calc(100% - 290px);
			}
			.tap__grid-load {
				padding-left: 355px;
			}
		}
		@include media($bp-lg) {
			.tap__grid {
				margin-left: 370px;
				width: calc(100% - 370px);
			}
		}
	}
}

// END TRAY TOGGLE