
//.plmr-c-form-login
@include plmr-component('form-login') {
  padding: 40px 0;
  position: relative;

  //.plmr-c-form-login::before
  &::before {
    @include plmr-pseudo;
    background: plmr-color('brand', 'tertiary');
    height: 50px;
    left: 50%;
    top: 0;
    transform: translate(-50%, -25px);
    width: 1px;
    z-index: 1;

    @include plmr-mq(md) {
      height: 75px;
      transform: translate(-50%, -37px);
    }
  }

  @include plmr-mq('md') {
    padding: 60px 0;
  }

  //.plmr-c-form-login__container
  @include element('container') {
    margin: 0 auto;
    max-width: plmr-config('container', 'max-width');
    padding: 0 25px;

    @include plmr-mq('md') {
      padding: 0 55px;
    }

    @include plmr-mq('lg') {
      padding-left: calc(91 / 1300 * 100%);
      padding-right: calc(91 / 1300 * 100%);
    }

    @include plmr-mq('max') {
      padding-left: 91px;
      padding-right: 91px;
    }
  }

  //.plmr-c-form-login__form
  @include element('form') {
    box-shadow: none;
    padding-bottom: calc(40px - 1rem);

    @include plmr-mq('md') {
      margin-left: 0;
      max-width: 506px;
      padding: 40px 70px 50px 50px;
      padding-bottom: calc(10px + 1rem);
    }

    @include plmr-mq('lg') {
      margin-left: auto;
      margin-right: 0;
    }
  }

  //.plmr-c-form-login__content
  @include element('content') {
    margin-bottom: 40px;

    @include plmr-mq('lg') {
      margin-bottom: 0;
      padding-right: calc(54 / 595 * 100%);
    }

    ul {
      @include plmr-font-face($family: 'bold');
      color: plmr-color('brand', 'primary');
      margin: 18px 0 42px;

      li {
        margin-bottom: 20px;
      }
    }
  }
}
