/// This is badly coded proceed with caution!!!

@include block(homepage) {
  @include element(featured-products) {
    max-width: 100%;
    overflow: hidden;
    position: relative;
    
    .page-title {
      margin-bottom: 1em;
    }

    .product-tile {
      width: 100%;
      @include media($bp-md) {
        width: 25%;
      }
    }
  
    .product-tile__shim {
      height: 30px;
    }

    .product-tile__name {
      max-width: none;
      width: 100%;
    }

    .product-tile__image {
      margin-bottom: 0;
    }

    .product-tile__new {
      color: $c_moen-bright-blue;
    }

    .product-tile {
      .corner-ribbon:before {
        background: $c_moen-bright-blue;
        color: $c_white;
        content: 'NEW';
        width: 145px;
        top: auto;
        left: auto;
        bottom: 2px;
        right: -54px;
        transform: rotate(-40deg);
      }
    }

    .featured-products-slider {
      visibility: hidden;
      &.slick-initialized {
        visibility: visible;
      }
    }

    .slick-initialized {
      .slick-slide {
        margin: 0 2em;
        @include media($bp-md) {
          margin: 0;
        }
      }
    }
    
    @include modifier(with-slider) {
	    .section-heading--3 {
		    font-size: em(24);
            margin: 0 0 5px;
		    @include media($bp-xl) {
			    font-size: em(28);
		    }
	    }
    }
    // Featured Products Slider
    .featured-products-slider {
	    padding: 0 30px;
	    margin: 0 auto;
	    width: calc(100% - 30px);
	    max-width: 1300px;
	    & > .slick-list > .slick-track {
		    display: flex;
		    flex-direction: row;
		    align-items: stretch;
	    }
	    .slick-prev,.slick-next {
		    background-image: url('images/icon-arrow_right-blue.svg');
		    width: 12px;
		    height: 21px;
            background-size: 12px 21px;
		    background-position: center center;
		    outline: none;
	    }
	    .slick-prev {
		    left: 0;
		    transform: rotate(180deg);
	    }
	    .slick-next {
		    right: 0;
	    }
        .tile.slick-slide {
          outline: none;
          max-width: none;
          margin: 0 7.5px;
          padding: 27px 24px;
          padding-bottom: 77px;
          height: auto;
          .button--cta {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 30px;
          }
          @include media ($bp-md) {
            margin: 0 12px;
          }
        }
	    .product-tile__image-slider .slick-slide {
		    margin: 0;
	    }

    }
    // end featured products slider
  }
}

////
.product-tile__image-slider.product-detail-link {
  display: block;
  margin-bottom: 15px;
}
