

// .plmr-c-masthead
@include plmr-component('masthead') {
  position: relative;
  z-index: plmr-z($layer: 'masthead');


  // .plmr-c-masthead__container
  @include element('container') {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: plmr-config('container', 'max-width');
    min-width: plmr-config('container', 'min-width');
  }


  // .plmr-c-masthead__secondary
  @include element('secondary') {
    background-color: plmr-color('background', 'primary');
    color: plmr-color('text', 'secondary');
    min-width: plmr-config('container', 'min-width');
    position: relative;
  }


  // .plmr-c-masthead__main
  @include element('main') {
    background-color: plmr-color('brand', 'primary');
    box-shadow: 0 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    color: plmr-color('base', 'white');
    position: relative;
    // z-index: plmr-z($layer: 'masthead');
  }


  // .plmr-c-masthead__logo
  @include element('logo') {
    flex-grow: 1;
    justify-content: flex-start;
    margin: 23px 15px 22px;


    // screen width > 768px
    @include plmr-mq('md') {
      flex-grow: 0;
      margin: 26px 25px;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      margin-left: 40px;
    }
  }


  // .plmr-c-masthead__logo-link
  @include element('logo-link') {

    // .plmr-c-masthead__logo-link:active, .plmr-c-masthead__logo-link:focus, .plmr-c-masthead__logo-link:hover
    &:active,
    &:focus,
    &:hover {
      // color: plmr-color('brand', 'secondary');
    }
  }


  // .plmr-c-masthead__nav-main
  @include element('nav-main') {
    flex-grow: 1;
  }


  // .plmr-c-masthead__nav-secondary
  @include element('nav-secondary') {


    // .plmr-c-masthead__nav-secondary--left
    @include modifier('left') {
      justify-content: flex-start;
      margin-left: 40px;
    }


    // .plmr-c-masthead__nav-secondary--expand
    @include modifier('expand') {
      flex-grow: 1;
    }

  }


  // .plmr-c-masthead__language-selector
  @include element('language-selector') {
    justify-content: flex-start;
  }


  // .plmr-c-masthead__language-selector-input
  @include element('language-selector-input') {
    border-bottom: 0;
    border-top: 0;
  }


  // .plmr-c-masthead__site-search
  @include element('site-search') {
    color: plmr-color('base', 'white');
    justify-content: flex-end;
    margin-right: 40px;
  }


  // .plmr-c-masthead__navicon
  @include element('navicon') {


    // screen width > 992px
    @include plmr-mq('lg') {
      display: none;
    }

  }



  @include element('mobile') {

  }

}

