/**
@section Find A Product
@childof Product Search
@page Product Search Find A Product
@status in progress
@example
_markup/find-a-product.hbs
*/
@include plmr-component('find-a-product') {
  min-width: 320px;
  padding: 20px 0;
  position: relative;
  text-align: center;
  width: 100%;

  @include plmr-mq(md) {
    padding: 30px 0;
  }

  // .plmr-c-find-a-product__toggle
  @include element('toggle') {
    color: plmr-color('text', 'tertiary');
    display: inline-block;
    margin-left: -35px;
    position: relative;

    // .plmr-c-find-a-product__toggle:hover
    &:hover {
      cursor: pointer;
    }

    // .plmr-c-find-a-product__toggle:focus
    &:focus {
      outline: 2px dashed plmr-color('base', 'white');
    }

    @include plmr-mq(md) {
      margin-left: -45px;
    }
  }

  // .plmr-c-find-a-product__title
  @include element('title') {
    font-size: 1.143rem;
    line-height: 1;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 1.286rem;
    }
  }

  // .plmr-c-find-a-product__tog-icon
  @include element('tog-icon') {
    border: 1px solid currentColor;
    border-radius: 50%;
    color: inherit;
    height: 20px;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translate(100%, -50%);
    width: 20px;

    &::before,
    &::after {
      background: currentColor;
      content: '';
      display: block;
      height: 1px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.25s ease-in-out;
      width: 9px;

      @include plmr-mq(md) {
        width: 11px;
      }
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    // .plmr-c-find-a-product__tog-icon._toggled-open
    @include plmr-state('toggled-open') {
      &::before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }

    @include plmr-mq(md) {
      height: 24px;
      right: -20px;
      width: 24px;
    }
  }

  // .plmr-c-find-a-product__form-area
  @include element('form-area') {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;

    // .plmr-c-find-a-product__form-area._open
    @include plmr-state('open') {
      overflow: visible;
    }
  }

  // .plmr-c-find-a-product__form
  @include element('form') {
    border: 1px solid plmr-color('accent', 'light');
    margin: 30px auto 10px;
    padding-bottom: 30px;
    width: calc(100% - 30px);

    @include plmr-mq(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 50px auto 10px;
      max-width: 914px;
      padding: 6px 70px 30px;
      width: calc(100% - 170px);
    }

    @include plmr-mq(lg) {
      padding: 6px 60px 30px;
    }
  }

  // .plmr-c-find-a-product__item
  @include element('item') {
    padding-top: 25px;

    // .plmr-c-find-a-product__item--submit
    @include modifier('submit') {
      padding-top: 30px;
    }

    // .plmr-c-find-a-product__item._open
    @include plmr-state('open') {
      .plmr-c-find-a-product__drop {
        &::after {
          transform: rotate(135deg) scale(-1, -1);
        }
      }


      .plmr-c-find-a-product__list {
        opacity: 1;
        pointer-events: auto;
      }
    }

    @include plmr-mq(md) {
      //flex: 1 0 auto;
      padding-top: 30px;

      & ~ & {
        padding-left: 30px;
      }
    }
  }

  // .plmr-c-find-a-product__label
  @include element('label') {
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0;
  }

  // .plmr-c-find-a-product__drop
  @include element('drop') {
    color: plmr-color('brand', 'tertiary');
    display: inline-flex;
    padding-left: 5px;
    padding-right: 15px;
    position: relative;
    text-decoration: underline;
    transition: color 0.5s ease-in-out;

    // .plmr-c-find-a-product__drop:hover
    &:hover {
      cursor: pointer;
      @include plmr-button-link-hover(plmr-color('brand', 'tertiary'));
    }

    &::after {
      border-style: solid;
      border-width: 1.25px 1.25px 0 0;
      content: '';
      display: inline-block;
      height: 6.85px;
      left: 10px;
      position: relative;
      top: 0.85rem;
      transform: rotate(135deg);
      vertical-align: top;
      width: 6.85px;
    }
  }

  // .plmr-c-find-a-product__list
  @include element('list') {
    background: plmr-color('base', 'white');
    box-shadow: -10px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    height: auto;
    left: 50%;
    list-style: none;
    margin-top: 10px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 30px;
    transform: translateX(-50%);
    transition: opacity 0.5s ease;
    width: 140px;
    z-index: 2;

    @include plmr-mq(md) {
      width: 155px;
    }
  }

  // .plmr-c-find-a-product__option
  @include element('option') {
    background-color: plmr-color('base', 'white');
    color: plmr-color('text', 'secondary');
    font-size: 1.286rem;
    line-height: 1.5rem;
    //padding: 15px 20px 14px;
    position: relative;
    text-align: left;
    transition: background-color 0.5s ease;

    // .plmr-c-find-a-product__option:hover
    &:hover {
      background-color: plmr-color('background', 'secondary');
      cursor: pointer;
    }

    // checkmark
    &::after {
      border-bottom: 2px solid currentColor;
      border-right: 2px solid currentColor;
      content: '';
      display: none;
      height: 12px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      width: 6px;

      @include plmr-mq(md) {
        right: 30px;
      }
    }

    // .plmr-c-find-a-product__option._checked
    @include plmr-state('checked') {
      &::after {
        display: block;
      }
    }
  }

  // .plmr-c-find-a-product__opt-button
  @include element('opt-button') {
    min-height: 0;
    padding: 15px 20px 14px;
    text-align: left;
    width: 100%;

    // .plmr-c-find-a-product__opt-button:focus
    &:focus {
      outline: 2px dashed plmr-color('background', 'secondary');
      outline-offset: -2px;
    }
  }

  // .plmr-c-find-a-product__selection
  @include element('selection') {
    // .plmr-c-find-a-product__selection:focus
    &:focus {
      outline: 2px dashed plmr-color('base', 'white');
    }
  }
}
