
// Link text color for default and interactive states
// -----------------------------------------------------------------------------
@mixin plmr-link($background: null, $color: plmr-color('brand', 'tertiary'), $underline: false, $is-color-hover: true, $is-background-hover: false, $hover-background-color: null, $darken: 6%, $is-underline-hover: false) {
  @if $background != null {
    background-color: $background;
  }

  color: $color;

  @if $underline {
    text-decoration: underline;
  } @else {
    text-decoration: none;
  }

  &:active,
  &:focus,
  &:hover {

    @if $is-color-hover {
      color: darken($color, $darken);
    } @else {
      color: $color;
    }


    @if $is-background-hover {
      background-color: darken($background, $darken);
    } @else if $hover-background-color != null {
      background-color: $hover-background-color;
    }


    @if $is-underline-hover {
      text-decoration: underline;
    }

  }

}
