//.plmr-c-installation-and-guides
@include plmr-component('installation-and-guides') {
  //.plmr-c-installation-and-guides__section-wrap
  @include element('section-wrap') {
    margin: 0;
    padding: 0 25px;

    @include plmr-mq('md') {
      padding: 20px 55px 30px;
    }

    @include plmr-mq('lg') {
      flex-direction: row-reverse;
      padding: 50px 0;
      padding-left: calc(91 / 1300 * 100%);
      padding-right: calc(81 / 1300 * 100%);
    }

    @include plmr-mq('max') {
      padding-left: 91px;
      padding-right: 81px;
    }
  }

  //.plmr-c-installation-and-guides__section
  @include element('section') {
    padding: 20px 0;

    &:last-child {
      padding-top: 30px;
    }

    @include plmr-mq(lg) {
      padding: 0;

      &:first-child {
        .plmr-c-installation-and-guides__area {
          margin-left: auto;
          margin-right: 0;
        }
      }

      &:last-child {
        padding-top: 0;

        .plmr-c-installation-and-guides__area {
          max-width: calc(608 / 658 * 100%);
        }
      }
    }
  }

  //.plmr-c-installation-and-guides__area
  @include element('area') {
    max-width: 404px;

    & ~ & {
      padding-top: 28px;
    }

    //.plmr-c-installation-and-guides__area--wide
    @include modifier('wide') {
      max-width: none;
    }
  }

  //.plmr-c-installation-and-guides__area-title
  @include element('area-title') {
    @include plmr-font-face($family: 'bold');
    border-bottom: 1px solid plmr-color('accent', 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.143rem;
    line-height: 19px;
    margin-bottom: 30px;
    padding-bottom: 13px;

    @include plmr-mq(md) {
      line-height: 24px;
      margin-bottom: 18px;
      padding-bottom: 22px;
    }
  }

  //.plmr-c-installation-and-guides__video-card
  @include element('video-card') {
    & ~ & {
      padding-top: 30px;
    }
  }

  //.plmr-c-installation-and-guides__video-card-title
  @include element('video-card-title') {
    line-height: 24px;
    padding-bottom: 0;
    padding-top: 12px;

    @include plmr-mq(md) {
      font-size: 1.143rem;
      line-height: 2rem;
      padding-top: 15px;
    }
  }

  //.plmr-c-installation-and-guides__video-card-area
  @include element('video-card-area') {
    overflow: hidden;
    padding-top: calc(183/325 * 100%);
    position: relative;
    width: auto;
  }

  //.plmr-c-installation-and-guides__video-card-image
  @include element('video-card-image') {
    left: 50%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  //.plmr-c-installation-and-guides__video-card-button
  @include element('video-card-button') {
    background: rgba(plmr-color('base', 'white'), 0);
    border: 2px solid plmr-color('base', 'white');
    border-radius: 100%;
    bottom: 0;
    display: block;
    height: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease;
    width: 40px;
    z-index: 2;

    @include plmr-mq(md) {
      height: 60px;
      width: 60px;
    }

    @include plmr-mq(lg) {
      height: 75px;
      width: 75px;
    }

    //.plmr-c-banner-video__button::before
    &::before {
      @include plmr-pseudo;
      border-bottom: 10px solid transparent;
      border-left: 15px solid plmr-color('base', 'white');
      border-radius: 2px;
      border-top: 10px solid transparent;
      height: 0;
      left: calc(50% + 12px);
      top: calc(50% + 15px);
      transform: translate(-17px, -25px);
      z-index: 2;

      @include plmr-mq(md) {
        border-bottom: 13px solid transparent;
        border-left: 18px solid plmr-color('base', 'white');
        border-top: 13px solid transparent;
        top: calc(50% + 12px);
      }

      @include plmr-mq(lg) {
        border-bottom: 15px solid transparent;
        border-left: 23px solid plmr-color('base', 'white');
        border-top: 15px solid transparent;
        left: calc(50% + 9px);
        top: calc(50% + 10px);
      }
    }

    //.plmr-c-banner-video__button:hover
    &:hover {
      background: rgba(plmr-color('base', 'white'), 0.35);
      cursor: pointer;
    }
  }
}
