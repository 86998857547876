/**
Overview and examples of Flexbox Grid layout.
@section Grid
@childof Layout
@status ready
@page Layout Grid
@nobrandtoggle
*/


// $plmr-grid-columns: 12;
// $plmr-container-max-width: $gpg-container-max-width !default;
// $plmr-gutter-width: 1rem;
// $plmr-half-gutter-width: $plmr-gutter-width / 2;
// $plmr-gutter-compensation: -1 * $plmr-half-gutter-width;
// $plmr-container-min-width: $gpg-container-min-width !default;


// .plmr-l-flex-row
@include plmr-layout('flex-row') {
  @include plmr-flex-row();


  // .plmr-l-flex-row--reverse
  @include modifier('reverse') {
    @include plmr-flex-row-reverse();
  }

}




[class*='plmr-l-flex-col'] {
  @include plmr-flex-column();
}


// .plmr-l-flex-col
@include plmr-layout('flex-col') {
  flex: 1;
}



@include build-plmr-flex-grid();




/**
@section Responsive
@sectionof Grid
@example
_markup/flex/flex-responsive.hbs

*/


/**
@section Auto Width
@sectionof Grid
@example
_markup/flex/flex-auto-width.hbs
*/


/**
@section Fluid
@sectionof Grid
@example
_markup/flex/flex-fluid.hbs
*/


/**
@section Offset
@sectionof Grid
@example
_markup/flex/flex-offset.hbs
*/


/**
@section Alignment
@sectionof Grid
@status ready
*/


/**
@section Start
@sectionof Grid.Alignment
@example
_markup/flex/flex-alignment-start.hbs
*/


/**
@section Center
@sectionof Grid.Alignment
@example
_markup/flex/flex-alignment-center.hbs
*/


/**
@section End
@sectionof Grid.Alignment
@example
_markup/flex/flex-alignment-end.hbs
*/



/**
@section Row Alignment at Breakpoints
@sectionof Grid.Alignment
@example
_markup/flex/flex-alignment-breakpoints.hbs
*/


/**
@section Top
@sectionof Grid.Alignment
@example
_markup/flex/flex-alignment-top.hbs
*/


/**
@section Middle
@sectionof Grid.Alignment
@example
_markup/flex/flex-alignment-middle.hbs
*/


/**
@section Bottom
@sectionof Grid.Alignment
@example
_markup/flex/flex-alignment-bottom.hbs
*/


/**
@section Equal Heights
@sectionof Grid.Alignment
@example
_markup/flex/flex-alignment-equal-heights.hbs
*/



/**
@section Distribution
@sectionof Grid
*/


/**
@section Around
@sectionof Grid.Distribution
@example
_markup/flex/flex-distribution-around.hbs
*/


/**
@section Between
@sectionof Grid.Distribution
@example
_markup/flex/flex-distribution-between.hbs
*/







/**
@section Reordering
@sectionof Grid
*/


/**
@section First
@sectionof Grid.Reordering
@example
_markup/flex/flex-reordering-first.hbs
*/

/**
@section Last
@sectionof Grid.Reordering
@example
_markup/flex/flex-reordering-last.hbs
*/



/**
@section Reversing
@sectionof Grid
@example
_markup/flex/flex-reversing.hbs
*/
