/**
@section File Input
@childof Forms
@page Forms File Input
@status in progress
@example
_markup/form-file.hbs
*/
@include plmr-component('form-file') {
  position: relative;

  //.plmr-c-form-file__input
  @include element('input') {
    @extend .plmr-c-form-input;
    line-height: 1;
  }

  //.plmr-c-form-file__button
  @include element('button') {
    @include plmr-link($underline: true);
    bottom: 0;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: -6px;
    position: absolute;
    right: 0;
    transform: translateY(100%);
  }
}
