/**
@doc _docs/banner-mega-obnoxious.md
@section Banner Mega Obnoxious
@childof Banners
@page Banners Banner Mega Obnoxious
@status ready
@hippo banner
*/


// .plmr-c-banner-mega-obnoxious
@include plmr-component('banner-mega-obnoxious') {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;


  // .plmr-c-banner-mega-obnoxious__image-container
  @include element('image-container') {
    min-width: plmr-config('container', 'min-width');
  }


  // .plmr-c-banner-mega-obnoxious__image
  @include element('image') {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
  }


  // .plmr-c-banner-mega-obnoxious__container
  @include element('container') {
    align-content: center;
    align-items: center;
    color: plmr-color('base', 'white');
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: plmr-config('container', 'min-width');
    padding: 20px;
    position: relative;
  }



  // .plmr-c-banner-mega-obnoxious__eyebrow
  @include element('eyebrow') {
    @include plmr-font-face($family: 'italic');
    font-size: 1.1rem;
    line-height: normal;
    margin: 0;
    text-align: center;


    // screen width > 768px
    @include plmr-mq('md') {
      font-size: 1.5rem;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      font-size: 1.85rem;
    }

  }


  // .plmr-c-banner-mega-obnoxious__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: currentColor;
    font-size: 2rem;
    margin-top: 0.1em;
    text-align: center;


    // screen width > 768px
    @include plmr-mq('md') {
      font-size: 2.75rem;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      font-size: 3rem;
    }

  }


  // .plmr-c-banner-mega-obnoxious__copy
  @include element('copy') {
    color: currentColor;
    font-size: 1.1rem;
    line-height: 1.5;
    max-width: 65ch;
    text-align: center;
  }



  // .plmr-c-banner-mega-obnoxious__cta
  @include element('cta') {
    @include plmr-link($color: plmr-color('base', 'white'), $is-color-hover: false);
  }



  // .plmr-c-banner-mega-obnoxious__scroll-arrow
  @include element('scroll-arrow') {
    color: plmr-color('base', 'white');
  }

}




/**
@section Splash
@sectionof Banner Mega Obnoxious
@page Banners Banner Mega Obnoxious
@example
_markup/banner-mega-obnoxious--splash.hbs
*/

// Banner Mega -- Splash
// -----------------------------------------------------------------------------
@include plmr-component('banner-mega-obnoxious') {


  // .plmr-c-banner-mega-obnoxious__container
  @include element('container') {


    // .plmr-c-banner-mega-obnoxious__container--splash
    @include modifier('splash') {
      align-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      height: calc(100vh - (34px + 70px));
      justify-content: flex-start;
      min-width: plmr-config('container', 'min-width');
      padding: 50px;

      // screen width > 768px
      @include plmr-mq('md') {
        height: calc(100vh - (34px + 77px));
        justify-content: center;
        padding-top: 20px;
      }

    }

  }

}




/**
@section Campaign
@sectionof Banner Mega Obnoxious
@page Banners Banner Mega Obnoxious
@example
_markup/banner-mega-obnoxious--campaign.hbs
*/

// Banner Mega -- Campaign
// -----------------------------------------------------------------------------
@include plmr-component('banner-mega-obnoxious') {


  // .plmr-c-banner-mega-obnoxious__container
  @include element('container') {


    // .plmr-c-banner-mega-obnoxious__container--campaign
    @include modifier('campaign') {
      color: plmr-color('text', 'primary');
      margin: 0 auto;
      max-width: 60ch;


      // screen width > 768px
      @include plmr-mq('md') {
        color: plmr-color('base', 'white');
        height: 100%;
        left: 50%;
        margin: 0;
        max-width: 70ch;
        padding-top: 75px;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        z-index: 1;
      }


      // .plmr-c-banner-mega-obnoxious__container--campaign .plmr-c-banner-mega-obnoxious__title
      .plmr-c-banner-mega-obnoxious__title {
        color: plmr-color('brand', 'primary');


        // screen width > 768px
        @include plmr-mq('md') {
          color: currentColor;
        }

      }

    }

  }

}
