//.plmr-c-product-specs
@include plmr-component('product-specs') {
  //.plmr-c-product-specs__toggle-area
  @include element('toggle-area') {
    padding: 28px 0 40px;
    text-align: center;
  }

  //.plmr-c-product-specs__drawer
  @include element('drawer') {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease;

    //.plmr-c-product-specs__drawer._open
    @include plmr-state('open') {
      max-height: 4000px;
    }
  }
}
