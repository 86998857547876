

@include plmr-component('button-link-icon') {
  @include plmr-font-face($family: 'bold');
  background: none;
  color: currentColor;
  cursor: pointer;
  font-size: 1.1rem;
  text-decoration: none;
  text-transform: uppercase;


  @include element('label') {
    color: currentColor;
  }


  @include element('icon') {
    color: currentColor;
    margin-left: 5px;
  }


}
