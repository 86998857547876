/**
@doc _docs/carousel-feature-slider.md
@section Carousel Feature Slider
@childof Carousels
@page Carousels Carousel Feature Slider
@status ready
@hippo banner carousel
@example
_markup/carousel-feature-slider.hbs
*/

@include plmr-component('carousel-feature-slider') {
  display: block;
  height: auto;
  padding: 40px 0;
  position: relative;
  width: 100%;


  @include plmr-mq(md) {
    padding: 50px 0;
  }


  // .plmr-c-carousel-feature-slider__title
  @include element('title') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('brand', 'secondary');
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    padding: 0 0 50px 0;
    text-align: center;


    @include plmr-mq(xl) {
      font-size: 19px;
    }
  }


  // .plmr-c-carousel-feature-slider__container
  @include element('container') {
    margin-left: auto;
    margin-right: 0;
    overflow: hidden;
    width: calc(100% - 35px);


    @include plmr-mq(md) {
      //overflow: hidden;
      width: calc(100% - 90px);
    }


    @include plmr-mq(xl) {
      margin-right: auto;
      max-width: 1188px;
      width: 100%;
    }
  }


  // .plmr-c-carousel-feature-slider__slider
  @include element('slider') {
    position: relative;
    width: 375px;


    @include plmr-mq(md) {
      width: 768px;
    }


    @include plmr-mq(xl) {
      width: 100%;
    }

    // .plmr-c-carousel-feature-slider__slider._centered
    @include plmr-state('centered') {
      .slick-track {
        @include plmr-mq('xl') {
          margin-left: -148.5px;
        }
      }
    }

    // .plmr-c-carousel-feature-slider__slider._split-centered
    @include plmr-state('split-centered') {
      .slick-track {
        @include plmr-mq('xl') {
          margin-left: -297px;
        }
      }
    }


    .slick-track {
      margin-left: -87.5px;


      @include plmr-mq(md) {
        margin-left: -230px;
      }


      @include plmr-mq(xl) {
        margin-left: 0;
      }
    }


    .slick-list {
      overflow: visible;


      @include plmr-mq(xl) {
        overflow: hidden;
      }
    }


    .slick-slide {
      @include plmr-mq(xl) {
        padding: 0 35px;
        width: 297px;
      }
    }


    &.slick-initialized {
      .plmr-c-carousel-feature-slider__slide {
        display: block;
      }
    }
  }


  // .plmr-c-carousel-feature-slider__slide
  @include element('slide') {
    padding-right: 50px;


    &:not(:first-child) {
      display: none;
    }


    @include plmr-mq(md) {
      padding-right: 70px;
    }


    @include plmr-mq(lg) {
      &:hover {
        cursor: pointer;
      }
    }


    @include plmr-mq(xl) {
      padding-right: 0;
    }

    // .plmr-c-carousel-feature-slider__slide._new
    @include plmr-state('new') {
      position: relative;

      &:hover {
        @include plmr-mq('lg') {
          &::before {
            opacity: 1;
          }
        }
      }

      // .plmr-c-carousel-feature-slider__slide::before
      &::before {
        @include plmr-font-face($family: 'bold');
        @include plmr-pseudo($content: attr(data-pseudo-label));
        background-color: plmr-color('brand', 'tertiary');
        border-radius: 50%;
        color: plmr-color('base', 'white');
        display: none;
        font-size: 11px;
        height: 35px;
        left: 0;
        line-height: 8px;
        padding-top: 13px;
        pointer-events: none;
        text-align: center;
        top: 0;
        width: 35px;
        z-index: 4;

        @include plmr-mq(lg) {
          display: block;
          opacity: 0;
          transition: 0.25s ease-in-out;
        }
      }
    }
  }


  // .plmr-c-carousel-feature-slider__image-area
  @include element('image-area') {
    height: 175px;
    overflow: hidden;
    position: relative;
    width: 175px;


    @include plmr-mq(md) {
      height: 227px;
      width: 227px;
    }
  }


  // .plmr-c-carousel-feature-slider__image
  @include element('image') {
    display: block;
    height: auto;
    left: 50%;
    max-height: calc(100% - 10px);
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;


    @include plmr-mq(md) {
      max-height: calc(100% - 35px);
    }
  }


  // .plmr-c-carousel-feature-slider__specs
  @include element('specs') {
    display: none;
    margin-top: 10px;
    text-align: center;


    @include plmr-mq(lg) {
      display: block;
      opacity: 0;
      transition: opacity 0.5s ease;
    }
  }


  // .plmr-c-carousel-feature-slider__product-name
  @include element('product-name') {
    color: plmr-color('brand', 'secondary');
    padding-bottom: 20px;
    text-transform: capitalize;


    @include plmr-mq(lg) {
      font-size: 16px;
    }
  }

  // .plmr-c-carousel-feature-slider__product-model-number
  @include element('product-model-number') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'secondary');
    font-size: 0.8571rem;
    line-height: 1.143rem;
    padding: 0;
    padding-bottom: 15px;

    @include plmr-mq(md) {
      font-size: 1rem;
    }
  }

  // .plmr-c-carousel-feature-slider__product-finishes
  @include element('product-finishes') {
    padding-bottom: 20px;
  }

  // .plmr-c-carousel-feature-slider__dots
  @include element('dots') {
    // margin-left: -35px;
    padding-top: 40px;
    width: 100vw;


    @include plmr-mq(md) {
      // margin-left: -90px;
      padding-top: 75px;
    }


    @include plmr-mq(xl) {
      // margin-left: 0;
      width: 100%;
    }
  }

  // .plmr-c-carousel-feature-slider__row
  @include element('row') {
    @include plmr-mq(lg) {
      align-items: center;
      padding-left: calc(91 / 1300 * 100%);
      padding-right: calc(91 / 1300 * 100%);
    }
  }

  // .plmr-c-carousel-feature-slider__text
  @include element('text') {
    font-size: 1.143rem;
    line-height: 24px;
    padding: 0 25px 40px;

    @include plmr-mq(md) {
      padding: 0 55px 50px;
    }

    @include plmr-mq(lg) {
      max-width: 455px;
      padding: 0 30px 0 0;
    }
  }

  // .plmr-c-carousel-feature-slider__products
  @include element('products') {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;

    @include plmr-mq(md) {
      padding: 0 55px;
    }

    @include plmr-mq(lg) {
      padding: 0;
    }
  }

  // .plmr-c-carousel-feature-slider__product
  @include element('product') {
    text-align: center;
    width: calc(50% - 22.5px);

    @include plmr-mq(md) {
      width: calc(50% - 51px);
    }

    @include plmr-mq(lg) {
      // max-width: calc(212 / 600 * 100%);
      width: calc(50% - 22.5px);
    }

    @include plmr-mq(xl) {
      width: calc(50% - 47.5px);
    }
  }

  // .plmr-c-carousel-feature-slider__product-image-area
  @include element('product-image-area') {
    overflow: hidden;
    padding-top: calc(140 / 140 * 100%);
    position: relative;
  }

  // .plmr-c-carousel-feature-slider__product-image
  @include element('product-image') {
    left: 50%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  // .plmr-c-carousel-feature-slider__product-title
  @include element('product-title') {
    color: plmr-color('brand', 'primary');
    font-size: 1rem;
    line-height: 18px;
    padding-bottom: 0;
    padding-top: 15px;

    @include plmr-mq(md) {
      font-size: 1.143rem;
      line-height: 21px;
      padding-top: 25px;
    }
  }

  // .plmr-c-carousel-feature-slider__product-subtitle
  @include element('product-subtitle') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'secondary');
    font-size: 0.857rem;
    line-height: 16px;
    padding-bottom: 0;
    padding-top: 10px;

    @include plmr-mq(md) {
      font-size: 1rem;
      padding-top: 15px;
    }
  }

  // .plmr-c-carousel-feature-slider__product-finish
  @include element('product-finish') {
    padding-top: 15px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }
  }

  &:not(.slick-initialized),
  .no-js & {
    .plmr-c-carousel-feature-slider__container,
    .plmr-c-carousel-feature-slider__slider {
      width: 100%;
    }

    .plmr-c-carousel-feature-slider__slide {
      margin-left: auto;
      margin-right: auto;
      max-width: 227px;
      padding-right: 0;
    }

    .plmr-c-carousel-feature-slider__image-area {
      margin-left: auto;
      margin-right: auto;
    }
  }
}



/**
@section With Title
@sectionof Carousel Feature Slider
@page Carousels Carousel Feature Slider
@example
_markup/carousel-feature-slider--with-title.hbs
*/


/**
@doc _docs/carousel-feature-slider--with-specs.md
@section With Specs
@sectionof Carousel Feature Slider
@page Carousels Carousel Feature Slider
@example
_markup/carousel-feature-slider--with-specs.hbs
*/
@include plmr-component('carousel-feature-slider') {
  // .plmr-c-carousel-feature-slider--with-specs
  @include modifier('with-specs') {
    .plmr-c-carousel-feature-slider__specs {
      display: block;
      opacity: 1;
      padding-left: 30px;
      text-align: left;

      @include plmr-mq('md') {
        padding-left: 0;
        text-align: center;
      }
    }

    .plmr-c-carousel-feature-slider__slide._new {
      &::before {
        display: block;
        left: auto;
        margin-right: 25px;
        opacity: 1;
        right: 0;

        @include plmr-mq('md') {
          left: 0;
          margin-right: 0;
          right: auto;
        }
      }
    }
  }
}


/**
@doc _docs/carousel-feature-slider--product-highlight.md
@section Product Highlight
@sectionof Carousel Feature Slider
@example
_markup/carousel-feature-slider--product-highlight.hbs
*/
@include plmr-component('carousel-feature-slider') {
  // .plmr-c-carousel-feature-slider--product-highlight
  @include modifier('product-highlight') {
    padding-bottom: 0;

    @include plmr-mq('md') {
      padding-bottom: 0;
      padding-top: 60px;
    }

    @include plmr-mq('lg') {
      padding-bottom: 15px;
    }

    .plmr-c-carousel-feature-slider__container {
      margin-left: auto;
      margin-right: auto;
      max-width: plmr-config('container', 'max-width');
      width: auto;
    }
  }
}
