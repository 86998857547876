@include block(image-gallery) {
	display: block;
	max-width: 890px;
	margin: 20px auto;
	@include element(slider) {
		display: block;
		overflow: hidden;
		visibility: hidden;
		&.slick-initialized { 
			visibility: visible;
		}
	}
	@include element(thumbs) {
		display: block;
		overflow: hidden;
		padding: 20px 10px;
		.slick-slide {
			margin: 0 10px;
		}
		.slick-prev,.slick-next {
			width: 12px;
			height: 21px;
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: center center;
			z-index: 9;
		}
		.slick-prev {
			left:0;
			background-image: url('images/icon-angle_left_gray.svg')
		}
		.slick-next {
			right:0;
			background-image: url('images/icon-angle_left_gray.svg');
			transform: scale(-1,1) translateY(-50%);
		}
	}
	@include element(slide) {
		background-color: $c_concrete;
/*
		padding: 10px;
		background-clip: content-box;
*/
		position: relative;
		&:before {
			content: ' ';
			display: block;
			width: 100%;
			padding-top: 67%;
		}
		img {
			width: auto;
			height: auto;
/*
			max-width: calc(100% - 20px);
			max-height: calc(100% - 20px);
			top:10px;
			left:10px;
			right:10px;
			bottom:10px;
*/
			max-width: 100%;
			max-height: 100%;
			top:0px;
			left:0px;
			right:0px;
			bottom:0px;
			position: absolute;
			
			margin: auto;
		}
	}
	
}