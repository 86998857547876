
// .plmr-c-nav-inspiration
@include plmr-component('nav-inspiration') {
  max-width: 250px;


  // .plmr-c-nav-inspiration--main
  @include modifier('main') {
    margin-right: 30px;
  }


  // .plmr-c-nav-inspiration__link
  @include element('link') {
    display: block;
    margin-bottom: 15px;
  }


  // .plmr-c-nav-inspiration__image
  @include element('image') {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }


  // .plmr-c-nav-inspiration__title
  @include element('title') {
    @include plmr-font-face($family: 'regular');
    color: plmr-color('brand', 'primary');
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    max-height: 50px;
    text-overflow: ellipsis;
  }

}
