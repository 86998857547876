
// .plmr-c-nav-company-info
@include plmr-component('nav-company-info') {
  align-self: center;
  flex-grow: 1;
  margin-bottom: -19px;


  // screen width > 992px
  @include plmr-mq('lg') {
    align-self: flex-end;
    margin-bottom: 0;
  }


  // .plmr-c-nav-company-info__list
  @include element('list') {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;


    // screen width > 992px
    @include plmr-mq('lg') {
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

  }


  // .plmr-c-nav-company-info__item
  @include element('item') {
    margin-bottom: 19px;
    margin-right: 30px;



    // screen width > 768px
    @include plmr-mq('md') {

      margin-right: 35px;
      margin-bottom: 4px;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      margin-bottom: 4px;
      margin-right: 3.5%;
    }


    // screen width > 1200px
    @include plmr-mq('xl') {
      margin-right: 40px;
    }


    // .plmr-c-nav-company-info__item:last-child
    &:last-child {
      margin-right: 0;
    }

  }


  // .plmr-c-nav-company-info__link
  @include element('link') {
    @include plmr-link($color: plmr-color('base', 'white'), $is-color-hover: false);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;


    // screen width > 768px
    @include plmr-mq('md') {
      font-size: 14px;
      letter-spacing: 0.44px;
      line-height: 18px;
    }

  }

}
