
/// .main-nav
@include block('main-nav') {
  display: none;
  
  
  /// screen > 768px
  @include media ($bp-md) {
    align-self: stretch;
    display: flex;
    flex: 1 1 auto;
    order: 0;
  }
  
  
  /// .main-nav__list
  @include child('list') {
    align-content: stretch;
    display: flex;
    flex-grow: 1;
    margin: 0;
  }
  
  
  /// .main-nav__item
  @include child('item') {
    align-content: stretch;
    display: flex;
    flex-grow: 0;
  }
  
  
  /// .main-nav__link
  @include child('link') {
    align-items: center;
    align-self: stretch;
    background: transparent;
    color: $c_moen-gray;
    display: flex;
    font-family: $din-bold;
    font-size: 14px;
    justify-content: center;
    padding: 0 15px;
    position: relative;
    text-transform: uppercase;
    
    
    /// screen > 900px
    @include media(900px) {
      font-size: 15px;
      padding: 0 30px;
    }
    
    
    /// screen > 1200px
    @include media($bp-xl) {
      font-size: 16px;
    }
    
    
    /// .main-nav__link.hover, .main-nav__link.focus, .main-nav__link.open
    &.hover,
    &.focus,
    &.open {
      color: $c_moen-bright-blue;
      outline-offset: -4px;
      text-decoration: none;
    }
    
  }
    
  
  /// .main-nav__flyout  
  @include element('flyout') {
    background-color: $c_white;
    box-shadow: 0 1px 6px $c_shadow;
    color: inherit;
    display: none;
    left: 0;
    padding: 30px 30px 36px;
    position: absolute;
    top: 76px;
    width: 100%;
    z-index: z("full-nav-section");
    
    
    /// .main-nav__flyout--support
    @include modifier('support') {
      padding: 0;
    }
    
    
    /// .main-nav__flyout.open
    &.open {
      display: block;
    }
    
  }
    
}



/// .main-flyout-divider
@include block('main-flayout-divider') {
  border-bottom: 1px solid $c_moen-bright-gray;
  margin-bottom: 30px;
  padding-bottom: 28px;
}



/// .main-sub-nav
@include block('main-sub-nav') {
  width: 100%;
  
  
  /// .main-sub-nav__list
  @include child('list') {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
  }
  
  
  /// .main-sub-nav__item
  @include child('item') {
    align-self: auto;
    flex: 0 1 auto;
    margin: 0 15px;
    order: 0;
  }
  
  
  /// .main-sub-nav__link
  @include child('link') {
    display: flex;
    flex-direction: column;
    text-align: center;
    
  
    /// .main-sub-nav__link:hover  
    &:hover {
      text-decoration: none;
      
      
      /// .main-sub-nav__link:hover .main-sub-nav__label
      .main-sub-nav__label {
        color: $c_moen-bright-blue;
      }
      
    }
    
  }
  
  
  /// .main-sub-nav__image
  @include child('image') {
    display: block;
    height: auto;
    margin: 0 auto 13px;
    max-height: 90px;
    max-width: 90px;
    width: 100%;
  }
  
  
  /// .main-sub-nav__label
  @include child('label') {
    color: $c_text-base;
    font-size: 14px;
    
    
    /// screen > 992px
    @include media($bp-lg) {
      font-size: 16px;
    }
    
  }
  
}
