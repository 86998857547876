// .grid-gallery
@include block(grid-gallery) {
	display: block;
	overflow: hidden;
	position: relative;
	transition: max-height 0.5s ease;

	@include modifier("max-3") {
		@include media($bp-lg){
			.grid-gallery__grid-sizer{
				width: 1/3*100%;
			}
			.grid-gallery__grid-item{
				width: 1/3*100%;
			}
			.grid-gallery__grid-item--width2x1{
				width: 2/3*100%;
			}
			.grid-gallery__grid-item--width2x2{
				width: 2/3*100%;
			}
			.grid-gallery__grid-item--width1x2{
				width: 1/3*100%;
			}

		}
	}
	// .grid-gallery__grid
	@include element(grid) {
		display: block;
		position: relative;
		width: calc(100% + 10px);
		left:-5px;
// 		max-width: $max-width;
		margin-left: auto;
		margin-right: auto; 
	}


	// .grid-gallery__grid-sizer
	@include element(grid-sizer) {
		width: 1/2*100%;
		@include media($bp-sm) {
			width: 1/2*100%;
		}
		@include media($bp-md) {
			width: 1/3*100%;
		}
		@include media($bp-lg) {
			width: 1/4*100%;
		}
	}


	// .grid-gallery__grid-item
	@include element(grid-item) {
		position: relative;
		width: 1/2*100%;
		&:before {
			content: ' ';
			display: block;
			padding-top: 100%;
		}
		a {
			position: absolute;
			top:5px;
			right: 5px;
			bottom:5px;
			left:5px;
			display: block;
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			overflow: hidden;
			img {
				display: block;
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50%,-50%);
				transition: transform 0.5s ease;
				width: 100%;
				height: auto;
			}
			&:after {
				content: ' ';
				display: block;
				position: absolute;
				bottom: 20px;
				right:20px;
				width: 20px;
				height: 20px;
				background-image: url('images/icon-expand.svg');
				background-repeat: no-repeat;
				background-size: 20px 20px;
				background-position: center center;
				opacity: 0;
				transition: opacity 0.5s ease;
				pointer-events: none;
			}
			&:hover {
				img {
					transform: translate(-50%,-50%) scale(1.1);
				}
				&:after {
					opacity: 1;
				}
			}
		}
		@include media($bp-sm) {
			width: 1/2*100%;
		}
		@include media($bp-md) {
			width: 1/3*100%;
		}
		@include media($bp-lg) {
			width: 1/4*100%;
		}
		@include modifier(width2x2) {
			width: 100%;	
			@include media($bp-sm) {
				width: 100%;
			}		
			@include media($bp-md) {
				width: 2/3*100%;
			}
			@include media($bp-lg) {
				width: 2/4*100%;		
			}
		}
		@include modifier(width2x1) {
			&:before {
				padding-top: 50%;
			}
			width: 100%;
			@include media($bp-sm) {
				width: 100%;
			}	
			@include media($bp-md) {
				width: 2/3*100%;	
			}
			@include media($bp-lg) {
				width: 2/4*100%;	
			}
		}
		@include modifier(width1x2) {
			&:before {
				padding-top: 200%;
			}
			@include media($bp-sm) {
				width: 1/2*100%;
			}	
			@include media($bp-md) {
				width: 1/3*100%;		
			}
			@include media($bp-lg) {
				width: 1/4*100%;			
			}
		}

	}


	// .grid-gallery__slider
	@include element(slider) {
// 		display: none;
// 		position: fixed;
		position: absolute;
		top:20px;
		left:0;
		right:0;
// 		max-width: 1220px;
		max-width: calc(100% - 80px);
		margin: 0 auto;
// 		width: calc(100% - 80px);
		width: 120vh;
		height: auto;
// 		max-height: calc(100% - 40px);

		z-index: 3;
		box-shadow: 0 0 20px 0 rgba(150,150,150,0.25);
		//overflow: hidden;
		opacity: 0;
// 		transform: scale(0);
		transition: all 0.5s ease;
		pointer-events: none;
		
		&.open {
			opacity: 1;
// 			transform: scale(1);
			pointer-events: auto;
			.grid-gallery__slider-overlay {
				opacity: 0.5;
			}
		}

		.slick-prev, .slick-next {
			background-image: url('images/icon-arrow_right_large-white.svg');
			
			background-position: center center;
			width: 21px;
			height: 40px;
			background-size: 10px auto;
			z-index: 4;
			outline: none;
			top: calc(50vw - 80px);
			background-color: $c_moen-bright-blue;
			transition: 0.5s ease;
			&:hover {
				background-color: lighten($c_moen-bright-blue,4%);
				opacity: 1;
			}
			@include media($bp-lg) {
				width: 42px;
				height: 80px;
				background-size: 20px 37.86px;
				top: 50%;
			}
		}
		.slick-next {
			right: 0px;
			@include media($bp-md) {
				right: 0px;
			}
		}
		.slick-prev {
			transform: scale(-1,1) translateY(-50%);
			left: 0px;
			@include media($bp-md) {
				left: 0px;	
			}
		}
	}


	// .grid-gallery__slider-overlay
	@include element(slider-overlay) {
		display: block;
		position: fixed;
		top:50%;
		left:50%;
		width: 100vw;
		height: 100vh;
		transform: translate(-50%,-50%);
		opacity: 0;
		z-index: -1;
		transition: 0.5s ease;
		background-color: #e5e5e5;
	}


	// .grid-gallery__close
	@include element(close) {
		display: inline-block;
		position: absolute;
		top:10px;
		right:10px;
		z-index: 3;
		padding: 6.5px;
		background-color: rgba(255,255,255,0.8);
		transition: 0.5s ease;
		font-size: 0;
		line-height: 0;
		&:hover {
			background-color: rgba(255,255,255,1);
		}
		img {
			display: block;
			width: 12px;
			height: 12px;
			
		}
		@include media($bp-lg) {
			top: 20px;
			right:20px;
			padding: 13px;
			img {
				width: 24px;
				height: 24px;
			}
		}
	}


	// .grid-gallery__slide
	@include element(slide) {
		position: relative;
		img {
			width: 100%;
			height: auto;
		}
	}


	// .grid-gallery__product
	@include element(product) {
		position: relative;
		width: 100%;
		background-color: #FFF;
		box-shadow: 0 0 20px 0 rgba(150,150,150,0.25);
		padding: 30px 35px;
		font-size: 0;
		line-height: 0;
		@include media($bp-lg) {
			position: absolute;
			left: 40px;
			bottom:40px;
			width: calc(100% - 80px);
// 			max-width: 500px;
			max-width: 650px;
		}
		.product-name {
			font: normal 14px/1.333333em $din-regular;
			padding-bottom: 20px; 
			color: $c_text-base;
			@include media($bp-md) {
				font-size: 16px;
			}
			@include media($bp-lg) {
// 				font-size: 18px;
			}
		}
		.product-number {
			font: normal 12px/1em $din-regular;
			padding-bottom: 20px;
			color: $c_moen-gray;
			@include media($bp-md) {
				font-size: 14px;
			}
		}
		.product-price {
			font: normal 12px/1em $din-bold;
			color: $c_text-base;
			@include media($bp-md) {
				font-size: 14px;
				padding-bottom: 0;
			}
		}
		.button {
			min-width: 150px;
			font-size: 12px;
		}
		.col-sm-6 {
			display: inline-block;
			vertical-align: middle;
			float: none;
		}
		
	}


	// .grid-gallery__loadmore
	@include element(loadmore) {
		display: none;
		position: absolute;
		bottom:0;
		left:0;
		width: 100%;
		height: 545px;
		pointer-events: none;
		text-align: center;
		background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 15%, rgba(255,255,255,0.85) 68%, #FFFFFF 93%);
		.button {
			pointer-events: auto;
			width: 190px;
			position: absolute;
			bottom: 50px;
			left:0;
			right:0;
			margin: 0 auto;
		}
	}

	
	// .grid-gallery--limited
	// Modifier to include the load more button
	@include modifier(limited) {
		max-height: 1070px;
		.grid-gallery__loadmore {
			display: block;
		}
	}
	
}