/*
MOEN Professional Products

A variation on the consumer-targeted product page.

markup:
<!-- container element is just needed for the styleguide -->
<div class="container">
  <div class="hero-section-pro row">
    <div class="hero-section-pro__image">
      <img src="./images/styleguide/8312.jpg" alt="" aria-hidden="true">
    </div>
    <div>
      <div class="hero-section-pro__description">
        <h1 class="hero-section-pro__logo"><span class="hat">Moen Professional</span><img class="hero-section-pro__logo" src="./images/logo-moen-professional.svg" alt="" /></h1>
        <h2 class="hero-section-pro__text"><label class="hero-section-pro__sku">R-COMMERCIAL 8312</label>M–POWER chrome electronic flush valve 3/4" urinal</h2>
        <p>This is a Moen Commercial Product. Our commercial products are on Moen Pro. You will leave Moen.com with any of the links below.</p>
      </div>
      <hr />
      <div class="hero-section-pro__buy">
        <a href="#" class="hero-section-pro__buy-button button button--secondary-flat">View Product on Moen Pro</a>
        <a href="#" class="hero-section-pro__buy-button button button--secondary-flat">View Commercial Products</a>
      </div>
      <div class="row">
        <a href="#" class="link">
           Support for this product
        </a>
      </div>
    </div>
  </div>
</div>
<!-- end container -->

Styleguide 4.10.11
*/

@include block(hero-section-pro) {
  padding: 0 1em;

  @include element(logo) {
    @include media($bp-md) {
      width: auto;
      height: 25px;
    }
    width: 100%;
    height: auto;
    margin: 1em 0 .5em;
    display: block;
  }

  hr {
    height: 1px;
    background-color: $c_rule;
  }

  @include media($bp-md) {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: em(50) 0;
  }

  @include element(image) {
    img {
      height: 100%;
      width: auto;
      margin: 0 auto;
      @include media($bp-md) {
        height: auto;
        width: 100%;
      }
    }
    @include media($bp-md) {
      margin-right: 40px;
      width: 40%;
      height: auto;
    }
    text-align: center;
    height: 180px;
  }

  @include element(description) {
    display: block;
  }

  @include element(buy) {
    margin: .6rem 0 2rem 0;
    @include media($bp-sm) {
      margin-top: 2em;
      display: flex;
      justify-content: flex-start;
    }
  }

  @include element(buy-button) {
    display: block;
    @include media($bp-sm) {
      display: inline-block;
      margin-left: 1em;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  @include element(text) {
    font-size: 2rem;
    font-family: $din-regular;
    font-weight: normal;
    letter-spacing: -.05em;
    margin-bottom: .5em;
  }

  @include element(sku) {
    font-size: .8em;
    font-family: $din-light;
    display: block;
    margin: .8em 0;
  }
}
