
// .plmr-c-navicon
@include plmr-component('navicon') {
  align-items: center;
  color: plmr-color('base', 'white');
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 25px;
  position: relative;
  width: 32px;



  // .plmr-c-navicon__icon
  @include element('icon') {
    background-position: center;
    background-size: contain;
    display: inline-block;
    fill: currentColor;
    height: 100%;
    max-width: 100%;
    position: relative;
    text-indent: 99999999%;
    vertical-align: middle;
    width: 100%;
  }


  // .plmr-c-navicon__line
  @include element('line') {
    opacity: 1;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;


    // .plmr-c-navicon__line--1
    @include modifier('1') {
      transform-origin: 5px 7px;

    }


    // .plmr-c-navicon__line--3
    @include modifier('3') {
      transform-origin: 5px 15px;
    }

  }


  // .plmr-c-navicon._expanded
  @include plmr-state('expanded') {


    // .plmr-c-navicon._expanded .plmr-c-navicon__line--1
    .plmr-c-navicon__line--1 {
      transform: rotate(45deg);
    }


    // .plmr-c-navicon._expanded .plmr-c-navicon__line--2
    .plmr-c-navicon__line--2 {
      opacity: 0;
    }


    // .plmr-c-navicon._expanded .plmr-c-navicon__line--3
    .plmr-c-navicon__line--3 {
      transform: rotate(-45deg);
    }

  }

}
