// Variables for adjusting the feature height
$innovation-feature-height-md: 675px;
$innovation-feature-height-xl: 750px;
$innovation-feature-height-md-short: 650px;
$innovation-feature-height-xl-short: 650px;

// .innovations
@include block(innovations) {
	
	// .innovations__feature
	@include element(feature) {
		overflow: hidden;
		display: block;
		position: relative;
		z-index: 1;
		
		
		// .innovations__feature .container
		.container {
			min-width: 0px;
			@include media($bp-md) {
				height: $innovation-feature-height-md;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			@include media($bp-xl) {
				height: $innovation-feature-height-xl;
			}
		}
		
		@include modifier("noheight") {
			.container {
				height: auto !important;
				display: block !important;
			}
		}
		// .innovations__feature__content
		@include child(content) {
			display: block;
			text-align: center;
			padding: 60px 35px 70px;
			position: relative;
			z-index: 2;
			font: normal 16px/1.5 $proxima-regular;
			color: $c_text-base;
			// resetting for spacing
			p,h1,h2,h3 {
				font: inherit;
				margin-bottom: 30px;
				padding:0;
			}
			// adjust the small tag
			small {
				font-size: (2/3)*100%;
				color: $c_moen-gray;
			}
			// .innovations__feature__content .innovations__section-heading
			.innovations__section-heading {
				margin-bottom: 20px;
				color: $c_moen-bright-blue;
				text-decoration: none !important;
			}
			
			// .innovations__feature__content .section-heading--1 
			.section-heading--1 {
				font: normal 28px/32px $proxima-thin;
				color: $c_mid-gray;
				margin-bottom: 20px;
			}
			
			// .innovations__feature__content .cta
			.cta {
				font: normal 14px/1em $din-bold;
				color: $c_moen-bright-blue;
				text-decoration: none;
				&:after {
					content: ' ';
					display: inline-block;
					vertical-align: middle;
					width: 5px;
					height: 9px;
					background-image: url('images/icon-angle_right_blue.svg');
					background-position: center center;
					background-repeat: no-repeat;
					background-size: 5px 9px;
					margin-left: 10px;
				}
				span {
					position: relative;
					display: inline-block;
					vertical-align: middle;
					&:after {
						content: ' ';
						display: block;
						width: 100%;
						height: 3px;
						background-color: $c_moen-bright-blue;
						position: absolute;
						bottom: -8px;
						left:0;
						opacity: 0;
						transition: 0.5s ease;
					}
				}
				&:hover span:after {
					opacity: 1;
				}
			}
			// .innovations__feature__content__ul
			@include child("ul") {
				font: normal 16px/1.5em $proxima-regular;
				color: $c_text_base;
				text-align: left;
				list-style: none;
				margin:0;
				padding:0;
				li {
					margin:0;
					padding: 0 0 15px 15px;
					position: relative;
					&::before {
						content: ' ';
						display: block;
						position: absolute;
						left:0;
						top: 10px;
						width: 5px;
						height: 5px;
						background-color: $c_light-gray;
						border-radius: 100%;
						overflow: hidden;
					}
				}
			}
			
			// .innovations__feature__content @$bp-md
			@include media($bp-md) {
				width: 250px;
				padding: 0;
				text-align: left;
				font-size: 18px;
				p,h1,h2,h3 {
					margin-bottom: 40px;
				}
				
				
				// .innovations__feature__content .innovations__section-heading
				.innovations__section-heading {
					margin-bottom: 30px;
				}
				
				// .innovations__feature__content .section-heading--1
				.section-heading--1 {
					font: normal 38px/42px $proxima-thin;
					margin-bottom: 30px;
				}
			}
			
			// .innovations__feature__content @$bp-lg
			@include media($bp-lg) {
				width: 375px;
				font-size: 21px;
				// .innovations__feature__content .section-heading--1 
				.section-heading--1 {
					font: normal 48px/48px $proxima-thin;
				}
			}
			
			// .innovations__feature__content @$bp-xl
			@include media($bp-xl) {
				width: 450px;
			}
			
			// .innovations__feature__content--wide
			@include modifier("alexa") {
				padding: 50px 20px 0;
				position: static;
				@include media($bp-md) {
					padding-bottom: 50px;
				}
				@include media($bp-xl) {
					padding-bottom: 60px;
				}
			}
			
			// .innovations__feature__content--fullvideo
			@include modifier("fullvideo") {
				max-width: 250px;
				margin: 30px auto 50px;
				padding:0;
				text-align: center;
				transition: opacity 0.4s ease;
				opacity: 1;
				z-index: 3;
				pointer-events: none;
				@include media($bp-md) {
					text-align: center;
					position: absolute;
					top:50px;
					left:0;
					right:0;
					margin: 0 auto;
				}
				
				@include state("hide") {
					@include media($bp-md) {
						opacity: 0;
					}
				}
			}	
			
			
		} 
		
		// .innovations__feature__disclaimer
		@include child("disclaimer") {
			color: lighten(#000,59%);
		}
		
		// .innovations__feature__image
		@include child(image) {
			display: block;
			position: relative;
			width: 100%;
			height: 260px;
			z-index: 1;
			picture,img {
				display: block;
				position: absolute;
				top:0;
				left:50%;
				transform: translateX(-50%);
				width: auto;
				height: 260px
			}
			
			// .innovations__feature__image @$bp-md
			@include media($bp-md) {
				position: absolute;
				top:0;
				width: auto;
				height: $innovation-feature-height-md;
				flex: none;
				picture,img {
					position: relative;
					left: auto;
					right:auto;
					transform: none;
					height: $innovation-feature-height-md;
					flex: none;
				}
			}
			
			// .innovations__feature__image @$bp-xl
			@include media($bp-xl) {
				height: $innovation-feature-height-xl;
				picture,img {
					height: $innovation-feature-height-xl;
				}
			}
		}	
		
		// .innovations__feature__video
		@include child(video) {		
			position: relative;
			width: 100%;
			height: auto;
			z-index: 1;
			margin-top: 75px;
			overflow: hidden;
			
			video {
				display: block;
				width: 100%;
				height: auto;
				position: relative;
				z-index: 1;
				object-fit: cover;
			}
			
			@include modifier("fullvideo") {
				margin-top: 0;
				width: 100%;
				height: auto;
				overflow: hidden;
				/*
				video {
					position: absolute;
					top:50%;
					left:50%;
					transform: translate(-50%,-50%);
					width: auto;
					height: 100%;
				}
				@include media($bp-md) {
					height: 650px;
				}
				@include media($bp-xl) {
					height: 750px;
					video {
						width: auto;
						height: auto;
						min-width: 100%;
						min-height: 100%;
						object-fit: cover;
					}
				}
				*/
				.innovations__feature__video-play {
					z-index: 5;
					@include media($bp-md) {
						top:auto;
						transform: translateX(-50%);
						bottom: 80px;
					}
					@include media($bp-lg) {
						top:50%;
						top: calc(50% + 60px);
						transform: translate(-50%,-50%);
						bottom: auto;
					}
					@include media($bp-xl) {
						top: 50%;
					}
				}
				
			}
			
		}
		// .innovations__feature__video-play
		@include child(video-play) {	
			display: block;
			width: 70px;
			height: 70px;
			border-radius: 100%;
      // background: #2AB3E7;
      background: $c_moen-bright-blue;
			position: absolute;
			z-index: 2;
			top:50%;
			left: 50%;
			transform: translate(-50%,-50%);
			border-style: none;
			opacity: 0;
			transition: opacity .5s ease;
			&:before {
				content:'';
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-left: 20px solid #fff;
				height: 0;
				z-index: 2;
				position: absolute;
				left: calc(50% + 10px);
				top: calc(50% + 15px);
// 				transform: translate(calc(-50% - 7.5px), calc(-50% - 15px));
				transform: translate(-17px,-25px);
			}
			&.show {
				opacity: 1;
				pointer-events: auto;
			}
		}
		
		// Innovations Feature Variant with content on left
		// .innovations__feature--left
		@include modifier(left) {
			display: block;
			@include media($bp-md) {
				.innovations__feature__content {
					margin-right: auto;
					margin-left: 40px;
				}
				.innovations__feature__image {
					left: 200px;
				}
			}
			@include media($bp-lg) {
				.innovations__feature__image {
					left: 325px;
				}
			}
			@include media($bp-xl) {
				.innovations__feature__content {
					margin-right: auto;
					margin-left: 60px;
				}
				.innovations__feature__image {
					left: 425px;
				}
			}
			@include media(1600px) {
				.innovations__feature__image {
					left: 525px;
				}
			}
		}
		
		
		
		// Innovations Feature Variant with content on right
		// .innovations__feature--right
		@include modifier(right) {
			display: block;
			@include media($bp-md) {
				.innovations__feature__content {
					margin-left: auto;
					margin-right: 40px;
				}
				.innovations__feature__image {
					right: 200px;
				}
			}
			@include media($bp-lg) {
				.innovations__feature__image {
					right: 325px;
				}
			}
			@include media($bp-xl) {
				.innovations__feature__content {
					margin-left: auto;
					margin-right: 60px;
				}
				.innovations__feature__image {
					right: 425px;
				}
			}
			@include media(1600px) {
				.innovations__feature__image {
					right: 525px;
				}
			}
		}
		
		
		// Innovations Feature Variant with shorter height (used on innovations overview page)
		// .innovations__feature--short
		@include modifier(short) {
			.container {
				@include media($bp-md) {
					height: $innovation-feature-height-md-short;
				}
				@include media($bp-xl) {
					height: $innovation-feature-height-xl-short;
				}
			}
			.innovations__feature__image {
				@include media($bp-md) {
					height: $innovation-feature-height-md-short;
					picture,img {
						height: $innovation-feature-height-md-short;
					}
				}
				@include media($bp-xl) {
					height: $innovation-feature-height-xl-short;
					picture,img {
						height: $innovation-feature-height-xl-short;
					}
				}
			}
		}
		
	}
}



/// .innovation-disclaimer
@include block('innovation-disclaimer') {
	
	font-size: 10px !important;
	font-style: italic !important;
	line-height: normal !important;
	margin: 1em 0 !important;
}
