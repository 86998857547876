/**
@doc _docs/carousel-gallery.md
@section Carousel Gallery
@childof Carousels
@page Carousels Carousel Gallery
@status in progress
@hippo carousel
@example
_markup/carousel-gallery.hbs
*/
@include plmr-component('carousel-gallery') {
  opacity: 0;
  pointer-events: none;

  //.plmr-c-carousel-gallery._visible
  @include plmr-state('visible') {
    opacity: 1;
    pointer-events: auto;
  }

  //.plmr-c-carousel-gallery__inner
  @include element('inner') {
    padding: 30px 0;
    position: relative;

    @include plmr-mq(md) {
      padding: 50px 0;
    }
  }

  //.plmr-c-carousel-gallery__drag-indicator
  @include element('drag-indicator') {
    height: 70px;
    left: 50%;
    opacity: 0.65;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 69px;
    z-index: 76;

    @include plmr-mq('md') {
      height: 96px;
      width: 94px;
    }
  }

  //.plmr-c-carousel-gallery__carousel
  @include element('carousel') {
    position: relative;

    //.plmr-c-carousel-gallery__carousel::before,
    //.plmr-c-carousel-gallery__carousel::after
    &::before,
    &::after {
      @include plmr-pseudo;
      background: rgba(plmr-color('base', 'white'), 0.35);
      min-height: 100%;
      pointer-events: none;
      top: 0;
      width: 35px;
      z-index: 1;

      @include plmr-mq('md') {
        width: 55px;
      }
    }

    //.plmr-c-carousel-gallery__carousel::before
    &::before {
      left: 0;
    }

    //.plmr-c-carousel-gallery__carousel::after
    &::after {
      right: 0;
    }

    .plmr-c-carousel__arrow {
      &--prev {
        left: 5px;
      }

      &--next {
        right: 5px;
      }

      @include plmr-mq('md') {
        &--prev {
          left: 15px;
        }

        &--next {
          right: 15px;
        }
      }
    }
  }

  //.plmr-c-carousel-gallery__item
  @include element('item') {
    display: none;
    margin: 0 5px;
    max-width: 315px;
    overflow: hidden;
    position: relative;

    .slick-initialized & {
      display: block;
    }

    //.plmr-c-carousel-gallery__item:hover
    &:not(.slick-active):hover {
      cursor: pointer;
    }

    @include plmr-mq(md) {
      margin: 0 10px;
      max-width: 678px;
    }

    @include plmr-mq(lg) {
      max-width: 950px;
    }
  }

  //.plmr-c-carousel-gallery__item-image
  @include element('item-image') {
    left: 50%;
    max-height: 300px;
    min-height: 300px;
    position: relative;
    transform: translateX(-50%);

    @include plmr-mq(md) {
      max-height: 439px;
      min-height: 439px;
    }

    @include plmr-mq(lg) {
      max-height: 620px;
      min-height: 620px;
    }
  }

  //.plmr-c-carousel-gallery__item-button
  @include element('item-button') {
    background: rgba(plmr-color('base', 'white'), 0);
    border: 2px solid plmr-color('base', 'white');
    border-radius: 100%;
    bottom: 0;
    display: block;
    height: 40px;
    left: 0;
    margin: 0;
    margin-bottom: 15px;
    margin-left: 20px;
    position: absolute;
    transition: 0.5s ease;
    width: 40px;
    z-index: 2;

    @include plmr-mq(md) {
      height: 60px;
      margin-bottom: 25px;
      margin-left: 30px;
      width: 60px;
    }

    @include plmr-mq(lg) {
      height: 75px;
      margin-bottom: 50px;
      margin-left: 55px;
      width: 75px;
    }

    //.plmr-c-carousel-gallery__item-button:hover
    &:hover {
      background: rgba(plmr-color('base', 'white'), 0.35);
      cursor: pointer;
    }

    //.plmr-c-carousel-gallery__item-button::before
    &::before {
      @include plmr-pseudo;
      border-bottom: 10px solid transparent;
      border-left: 15px solid plmr-color('base', 'white');
      border-radius: 2px;
      border-top: 10px solid transparent;
      height: 0;
      left: calc(50% + 12px);
      top: calc(50% + 15px);
      transform: translate(-17px, -25px);
      z-index: 2;

      @include plmr-mq(md) {
        border-bottom: 13px solid transparent;
        border-left: 18px solid plmr-color('base', 'white');
        border-top: 13px solid transparent;
        top: calc(50% + 12px);
      }

      @include plmr-mq(lg) {
        border-bottom: 15px solid transparent;
        border-left: 23px solid plmr-color('base', 'white');
        border-top: 15px solid transparent;
        left: calc(50% + 9px);
        top: calc(50% + 10px);
      }
    }

    //.plmr-c-carousel-gallery__item-button--reel
    @include modifier('reel') {
      border-color: transparent;

      //.plmr-c-carousel-gallery__item-button--reel::before
      &::before {
        display: none;
      }
    }
  }
}
