
/// .menu-card-link
@include block('menu-card-link') {
  border: 1px solid $c_moen-bright-gray;
  display: inline-block;
  margin: 0 20px 30px;
  max-width: 180px;
  padding: 18px 15px;
  text-align: center;
  text-decoration: none !important;
  transition: border-color 0.2s ease;
  
  
  
  /// .menu-card-link:hover, .menu-card-link:active, .menu-card-link:focus
  &:hover,
  &:active,
  &:focus {
    border-color: $c_moen-bright-blue;
  }
  
  
  /// .menu-card-link__register
  @include child('image') {
    display: inline-block;
    height: 31px;
    margin-bottom: 5px;
    width: 24px;
  }
  
  
  /// .menu-card-link__description
  @include child('description') {
    color: $c_text-base;
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }
  
  
  /// .menu-card-link__cta
  @include child('cta') {
    display: inline-flex;
    flex-direction: row;
    font-family: $din-bold;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    padding-right: 15px;
    position: relative;
    
  
    /// .menu-card-link__cta::after pseudo-element  
    &::after {
      align-self: center;
      background: transparent url('images/icon-angle_right_blue.svg') 0 0 no-repeat;
      content: '';
      display: inline-block;
      flex: 0 1 auto;
      height: 14px;
      margin-top: -5px;
      position: absolute;
      right: 0;
      top: 50%;
      width: 8px;
    }
    
  }
  
}
