// Slick Carousel Defaults
//
// these feel very shameful
// -----------------------------------------------------------------------------
// sass-lint:disable-all
@include block('slick') {


  // .slick-slider
  &-slider {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
  }


  // .slick-list
  &-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;


    // .slick-list.dragging
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }

  }


  // .slick-track
  &-track {
    display: block;
    left: 0;
    position: relative;
    top: 0;

    &::before,
    &::after {
      content: '';
      display: table;
    }

    &::after {
      clear: both;
    }

    .slick-loading & {
      visibility: hidden;
    }

  }


  // .slick-slide
  &-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    [dir='rtl'] & {
      float: right;
    }

    img {
      display: block;
    }

    &.slick-loading img {
      display: none;
    }

    &.dragging img {
      pointer-events: none;
    }

    .slick-initialized & {
      display: block;
    }

    .slick-loading & {
      visibility: hidden;
    }

    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }

  }


  &-arrow.slick-hidden {
    display: none;
  }

}
// sass-lint:enable-all
