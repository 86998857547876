/**
Button Expand Toggle styles
@section Button Expand Toggle
@childof Buttons
@page Buttons Button Expand Toggle
@status in progress
*/

@include plmr-component('button-expand-toggle') {
  cursor: pointer;
  min-height: 0;
  padding-bottom: 37px;
  position: relative;

  //.plmr-c-button-expand-toggle__label:hover
  &:hover {
    .plmr-c-button-expand-toggle__icon {
      background-color: currentColor;

      &::before,
      &::after {
        background: plmr-color('base', 'white');
      }
    }
  }

  //.plmr-c-button-expand-toggle__label
  @include element('label') {
    @include plmr-font-face($family: 'bold');
    font-size: 1.143rem;
    line-height: 1.285rem;
    margin: 0;
    padding: 0;
  }

  //.plmr-c-button-expand-toggle__icon
  @include element('icon') {
    background-color: transparent;
    border: 1px solid currentColor;
    border-radius: 50%;
    bottom: 0;
    color: inherit;
    height: 20px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: 0.5s ease;
    width: 20px;

    //.plmr-c-button-expand-toggle__icon::before,
    //.plmr-c-button-expand-toggle__icon::after
    &::before,
    &::after {
      @include plmr-pseudo;
      background: currentColor;
      height: 1px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.25s ease-in-out;
      width: 9px;

      @include plmr-mq(md) {
        width: 11px;
      }
    }

    //.plmr-c-button-expand-toggle__icon::before
    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    //.plmr-c-button-expand-toggle__icon._toggled-open
    @include plmr-state('toggled-open') {
      &::before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }
}


/**
@section Primary
@sectionof Button Expand Toggle
@page Buttons Button Expand Toggle
@example
_markup/button-expand-toggle--primary.hbs
*/
@include plmr-component('button-expand-toggle') {
  //.plmr-c-button-expand-toggle--primary
  @include modifier('primary') {
    @include plmr-button-link-hover(plmr-color('brand', 'primary'));
    color: plmr-color('brand', 'primary');
  }
}


/**
@section Secondary
@sectionof Button Expand Toggle
@page Buttons Button Expand Toggle
@example
_markup/button-expand-toggle--secondary.hbs
*/
@include plmr-component('button-expand-toggle') {
  //.plmr-c-button-expand-toggle--secondary
  @include modifier('secondary') {
    @include plmr-button-link-hover(plmr-color('brand', 'secondary'));
    color: plmr-color('brand', 'secondary');
  }
}


/**
@section Tertiary
@sectionof Button Expand Toggle
@page Buttons Button Expand Toggle
@example
_markup/button-expand-toggle--tertiary.hbs
*/
@include plmr-component('button-expand-toggle') {
  //.plmr-c-button-expand-toggle--tertiary
  @include modifier('tertiary') {
    @include plmr-button-link-hover(plmr-color('brand', 'tertiary'));
    color: plmr-color('brand', 'tertiary');
  }
}


/**
Use multiple toggles to trigger the same action.
@section With Clone
@sectionof Button Expand Toggle
@page Buttons Button Expand Toggle
@example
_markup/button-expand-toggle--with-clone.hbs
*/
