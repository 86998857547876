
/**
@section Container
@childof Layout
@page Layout container
@status ready
@nobrandtoggle
@example
_markup/container/container.hbs
*/

// .plmr-l-container
@include plmr-layout('container') {
  margin-left: auto;
  margin-right: auto;
  max-width: plmr-config('container', 'max-width');
  min-width: plmr-config('container', 'min-width');
  padding-left: plmr-config('container', 'gutter-width');
  padding-right: plmr-config('container', 'gutter-width');
  width: 100%;



  /**
  @section Full Width
  @sectionof Container
  @page Layout container
  @example
  _markup/container/container-full-width.hbs
  */
  // .plmr-l-container--full-width
  @include modifier('full-width') {
    max-width: 100%;
  }

}
