// .innovations
@include block(innovations) {
	// .innovations__subnav-container
	@include element(subnav-container) {
		display: block;
		min-height: 71px;
		@include media($bp-md) {
			min-height: 91px;
		}
	}
	
	
	// .innovations__subnav
	@include element(subnav) {
		padding: 18px 20px 17px;
		border-bottom: solid 1px $c_moen-bright-gray;
		position: relative;
		z-index: 2;
		width: 100%;
		height: auto;
		background: rgba(#FFF,0.0);
		transition: background 0.5s ease;
		
		
		// .innovations__subnav.sticky
		&.sticky {
			position: fixed;
			top:0;
			left:0;
			z-index: 14;
			background: rgba(#FFF,1.0);
		}
		@include media($bp-md) {
// 			padding: 4px 30px 2px;
			padding: 14px 30px 12px;
		}
		
		// .innovations__subnav .container
		.container {
			position: static;
			min-width: 0px;
		}
		
		// .innovations__subnav .left
		.left {
			width: calc(100% - 200px);
			float: left;
			@include media($bp-md) {
				.flexbox & {
					display: flex;
					align-items: center;
					height: 64px;
				}
			}
		}
		
		
		// .innovations__subnav .right
		.right {
			width: 200px;
			float: right;
			@include media($bp-md) {
				.flexbox & {
					display: flex;
					align-items: center;
					height: 64px;
					justify-content: flex-end;
				}
			}
			.button--cta {
				min-width: 100px;
				height: 35px;
				font-size: 10px;
				line-height: 1em;
				padding: 13px 15px 12px;
				@include media($bp-md) {
					min-width: 120px;
					height: 40px;
					padding: 15px;
				}
			}
		}
		
		
		// .innovations__subnav .breadcrumbs
		.breadcrumbs {
			display: none;			
			font: normal 12px/1em $proxima-regular;
			.breadcrumbs__separator {
				width: 4px;
				height: 6.66px;
				vertical-align: middle;
			}
			
      a {
				cursor: pointer;
			}
			@include media($bp-md) {
				display: inline-block;
				vertical-align: middle;
			}
			@include media($bp-lg) {
				font-size: 14px;
				.breadcrumbs__separator {
					width: 6px;
					height: 10px;
					margin: 0 20px;
				}
			}
		}
		
		
		// .innovations__subnav .innovations__type-select
		.innovations__type-select {
			@include media($bp-md) {
				display: inline-block;
				vertical-align: middle;
				margin-left: 20px;
			}
			@include media($bp-lg) {
				margin:0;
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50%,-50%);
// 				display: inline-block;
// 				vertical-align: middle;
			}
		}
	}
	
	@include element("jumplinks-toggle") {
		font: normal 14px/18px $proxima-bold;
		text-transform: uppercase;
		letter-spacing: 0.44px;
		padding: 28px 26px 24px 0;
		position: absolute;
		top:0;
// 		left:50%;
		left: 20px;
// 		transform: translate(-50%);
		-webkit-appearance: none;
		appearance: none;
		background: none;
		border-radius: 0;
		border: 0 none;
		box-shadow: none;
		outline: none;
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 5px 5px;
			border-color: transparent transparent #8b8d8e;
			transform: scaleY(-1) translateY(2.5px);
		}
		@include state("active") {
			&::after {
				transform: scaleY(-1) translateY(2.5px) rotate(180deg);
			}
		}
		@include media($bp-md) {
			top:50%;
			left:50%;
			transform: translate(-50%,-50%);
		}
		@include media($bp-lg) {
			display: none;
		}
	}
	
	// .innovations__jumplinks
	@include element("jumplinks") {
		position: absolute;
		width: 100%;
		top:100%;
		left:50%;
		transform: translateX(-50%);
		font-size: 0;
		line-height: 0;
		color: #969696;
		max-height: 0px;
		overflow: hidden;
		text-align: center;
		background-color: #FFF;
		transition: 0.333s ease-in-out;
		@include media($bp-lg) {
// 			top:0;
			max-height: none !important;
			width: auto;
			display: inline-block;
			background: transparent;
			top: 50%;
	    transform: translate(-50%,-50%);
		}
		&:hover {
			.innovations__jumplink {
				color: rgba(#969696,0.5);
			}
		}
	}
	// .innovations__jumplink
	@include element("jumplink") {
		display: block;
		width: 100%;
		font: normal 14px/18px $proxima-bold;
		text-transform: uppercase;
		letter-spacing: 0.44px;
		padding: 15px 0;
		text-decoration: none !important;
		color: #969696;
		transition: 0.333s ease;
		&:first-child {
			margin-top: 15px;
		}
		&:last-child {
			margin-bottom: 15px;
		}
		&:hover {
			color: $c_text-base !important;
		}
		@include media($bp-lg) {
			width: auto;
			display: inline-block;
			padding: 26px 18px;
			&:first-child {
				margin-top: 0px;
			}
			&:last-child {
				margin-bottom: 0px;
			}
		}
		
		@include media($bp-xl) {
			padding: 26px 26px;
		}
		@include media($bp-xxl) {
			padding: 26px 30px;
		}
		@include state("active") {
			color: $c_moen-bright-blue !important;
		}
	}
}
