
@include block('account') {
  min-height: 200px;
  
  @include media($bp-sm) {
    min-height: 400px;
  }


  // @include child('load') {
  //   min-height: 200px;
  //   position: relative;
  // 
  //   @include media($bp-sm) {
  //     min-height: 400px;
  //   }
  // }

  @include child('update-confirmation') {
    color: $c_moen-bright-blue;
    font-size: rem(20);
    font-weight: bold;
    margin-bottom: 2em;
  }

  @include child('create-confirmation') {
    padding: em(50) 0;
  }

  @include child('error') {
    color: $c_error;
    font-size: rem(16);
    font-weight: bold;
    margin-bottom: 2em;
  }


  @include child('professional') {
    padding: em(50) 0;
  }

}
