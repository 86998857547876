/**
@doc _docs/carousel-featured-parts.md
@section Carousel Featured Parts
@childof Carousels
@page Carousels Carousel Featured Parts
@status in progress
@example
_markup/carousel-featured-parts.hbs
*/
@include plmr-component('carousel-featured-parts') {
  position: relative;

  //.plmr-c-carousel-featured-parts::before
  &::before {
    @include plmr-pseudo;
    background: plmr-color('base', 'white');
    bottom: 0;
    height: calc(990 / 1440 * 100%);
    left: 0;
    width: 100%;
    z-index: 1;

    @include plmr-mq(md) {
      height: calc(1160 / 1610 * 100%);
    }

    @include plmr-mq(lg) {
      height: calc(794 / 1244 * 100%);
    }
  }

  //.plmr-c-carousel-featured-parts::after
  &::after {
    @include plmr-pseudo;
    background: plmr-color('brand', 'tertiary');
    height: 50px;
    left: 50%;
    top: 0;
    transform: translate(-50%, -25px);
    width: 1px;
    z-index: 2;


    @include plmr-mq(md) {
      height: 75px;
      transform: translate(-50%, -37px);
    }
  }


  //.plmr-c-carousel-featured-parts__container
  @include element('container') {
    padding: 40px 25px 50px;

    @include plmr-mq(md) {
      padding: 60px 55px;
    }

    @include plmr-mq(lg) {
      padding-bottom: 75px;
      padding-left: calc(142 / 1300 * 100%);
      padding-right: calc(142 / 1300 * 100%);
    }

    @include plmr-mq(xl) {
      padding-left: 142px;
      padding-right: 142px;
    }
  }

  //.plmr-c-carousel-featured-parts__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.285rem;
    margin: 0;
    text-align: center;
  }

  //.plmr-c-carousel-featured-parts__carousel-items
  @include element('carousel-items') {
    background: plmr-color('base', 'white');
    box-shadow: 10px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
    color: plmr-color('text', 'primary');
    margin-top: 30px;
    position: relative;
    z-index: 2;

    //.plmr-c-carousel-featured-parts__carousel-items::before
    &::before {
      @include plmr-pseudo;
      border-bottom: 5px solid plmr-color('background', 'secondary');
      left: 0;
      pointer-events: none;
      top: 150px;
      width: 100%;
    }

    @include plmr-mq(md) {
      margin-top: 50px;

      &::before {
        top: 205px;
      }
    }
  }

  //.plmr-c-carousel-featured-parts__tabs
  @include element('tabs') {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
  }

  //.plmr-c-carousel-featured-parts__arrow
  @include element('arrow') {
    background-color: plmr-color('accent', 'light');
    color: plmr-color('accent', 'dark');
    min-height: 150px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: 0.333s ease;
    width: 40px;

    @include plmr-mq(md) {
      min-height: 205px;
    }

    //.plmr-c-carousel-featured-parts__arrow:hover
    &:hover {
      cursor: pointer;
    }

    //.plmr-c-carousel-featured-parts__arrow::before
    &::before {
      @include plmr-pseudo;
      display: none;
      height: 100%;
      left: 0;
      pointer-events: none;
      top: 0;
      width: 209px;

      @include plmr-mq(md) {
        display: block;
      }
    }

    //.plmr-c-carousel-featured-parts__arrow--next
    @include modifier('next') {
      right: 0;

      &::before {
        background-image: linear-gradient(270deg, plmr-color('base', 'white') 0%, rgba(plmr-color('base', 'white'), 0) 100%);
        margin-left: -210px;
      }
    }

    //.plmr-c-carousel-featured-parts__arrow--prev
    @include modifier('prev') {
      left: 0;

      &::before {
        background-image: linear-gradient(-270deg, plmr-color('base', 'white') 0%, rgba(plmr-color('base', 'white'), 0) 100%);
        margin-left: 41px;
      }
    }

    //.plmr-c-carousel-featured-parts__arrow._visible
    @include plmr-state('visible') {
      opacity: 1;
      pointer-events: auto;
    }


    // &:first-child {
    //   left: 0;
    // }

    // &:last-child {
    //   right: 0;
    // }
  }

  //.plmr-c-carousel-featured-parts__arrow-icon
  @include element('arrow-icon') {
    height: 20px;
    width: 11px;

    //.plmr-c-carousel-featured-parts__arrow-icon._previous
    @include plmr-state('previous') {
      transform: scale(-1, -1);
    }
  }

  //.plmr-c-carousel-featured-parts__tab-item
  @include element('tab-item') {
    border-bottom: 4px solid transparent;
    color: plmr-color('text', 'secondary');
    flex: 0 0 100%;
    opacity: 0.5;
    padding: 17px 0;
    position: relative;
    transition: 0.25s ease-in-out;

    //.plmr-c-carousel-featured-parts__tab-item:hover
    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    //.plmr-c-carousel-featured-parts__tab-item::after
    &::after {
      @include plmr-pseudo;
      background-color: plmr-color('brand', 'tertiary');
      bottom: -4px;
      display: none;
      height: 4px;
      left: 50%;
      transform: translateX(-50%);
      width: 150px;
      z-index: 2;
    }

    //.plmr-c-carousel-featured-parts__tab-item._active
    @include plmr-state('active') {
      color: plmr-color('brand', 'primary');
      opacity: 1;

      &::after {
        display: block;
      }
    }

    @include plmr-mq(md) {
      flex: 1 1 100%;
      max-width: 215px;
      min-width: 215px;
      padding: 25px 0 40px;

      &::after {
        width: 100%;
      }
    }
  }

  //.plmr-c-carousel-featured-parts__tab-item-image
  @include element('tab-item-image') {
    height: 75px;
    width: 75px;

    @include plmr-mq(md) {
      height: 100px;
      width: 100px;
    }
  }

  //.plmr-c-carousel-featured-parts__tab-item-name
  @include element('tab-item-name') {
    // color: plmr-color('brand', 'primary');
    line-height: 1.5rem;
    padding-bottom: 0;
    padding-top: 20px;

    @include plmr-mq(md) {
      font-size: 1.174rem;
    }
  }

  //.plmr-c-carousel-featured-parts__tab-content
  @include element('tab-content') {

  }

  //.plmr-c-carousel-featured-parts__panel
  @include element('panel') {
    // display: flex;
    // flex-flow: row wrap;
    font-size: 0;
    padding: 30px 30px 40px;

    @include plmr-mq(md) {
      padding: 40px 40px 50px;
    }

    @include plmr-mq(lg) {
      padding-bottom: 60px;
      padding-top: 50px;
    }

    @include plmr-mq(xl) {
      padding-left: 55px;
      padding-right: 50px;
    }
  }

  //.plmr-c-carousel-featured-parts__panel-content
  @include element('panel-content') {
    // flex: 1 0 100%;
    display: inline-block;
    padding-bottom: 40px;
    vertical-align: top;
    width: 100%;

    @include plmr-mq(lg) {
      // flex: 0 0 50%;
      // max-width: 50%;
      padding-bottom: 0;
      padding-right: calc(55 / 911 * 100%);
      width: 50%;
    }
  }

  //.plmr-c-carousel-featured-parts__panel-eyebrow
  @include element('panel-eyebrow') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'secondary');
    font-size: 1rem;
    padding-bottom: 15px;

    @include plmr-mq(md) {
      font-size: 1.143rem;
      line-height: 1.5rem;
      padding-bottom: 25px;
    }
  }

  //.plmr-c-carousel-featured-parts__panel-title
  @include element('panel-title') {
    // @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.285rem;
    line-height: 1.714rem;
    padding-bottom: 10px;

    @include plmr-mq(md) {
      font-size: 1.5rem;
      line-height: 2rem;
      padding-bottom: 20px;
    }
  }

  //.plmr-c-carousel-featured-parts__panel-title-brand
  @include element('panel-title-brand') {
    @include plmr-font-face($family: 'bold');
  }

  //.plmr-c-carousel-featured-parts__panel-copy
  @include element('panel-copy') {
    font-size: 1.143rem;
    line-height: 2rem;
    padding-bottom: 25px;

    p {
      padding: 0;

      &:not(:last-child) {
        padding-bottom: 2rem;
      }
    }

    @include plmr-mq(md) {
      padding-bottom: 30px;
    }
  }

  //.plmr-c-carousel-featured-parts__panel-media
  @include element('panel-media') {
    // flex: 1 0 100%;
    display: inline-block;
    vertical-align: top;
    width: 100%;

    @include plmr-mq(lg) {
      // flex: 0 0 50%;
      // max-width: 50%;
      width: 50%;
    }
  }

  //.plmr-c-carousel-featured-parts__panel-media-item
  @include element('panel-media-item') {
    & ~ & {
      padding-top: 20px;
    }
  }

  //.plmr-c-carousel-featured-parts__media
  @include element('media') {
    max-width: 455px;
    position: relative;
  }

  //.plmr-c-carousel-featured-parts__media-title
  @include element('media-title') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding-bottom: 15px;

    @include plmr-mq(md) {
      padding-bottom: 25px;
    }
  }

  //.plmr-c-carousel-featured-parts__media-button
  @include element('media-button') {
    bottom: 0;
    left: 0;
    margin: 0 0 15px 15px;
    position: absolute;
  }
}
