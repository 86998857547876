
/**
Various tool tip alignment examples
@section Tool Tip
@childof Content
@page Content Tool tip
@status in progress
@example
_markup/tool-tip.hbs
*/
@include plmr-component('tooltip') {
  color: plmr-color('text', 'secondary');
  display: none;
  line-height: 1rem;
  padding-left: 10px;
  position: relative;

  &:hover {
    .plmr-c-tooltip__flag {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @include plmr-mq(md) {
    display: inline-block;
  }

  //.plmr-c-tooltip__icon
  @include element('icon') {
    height: auto;
    width: 15px;

    //.plmr-c-tooltip__icon:hover
    &:hover {
      cursor: pointer;

      // & + .plmr-c-tooltip__flag {
      //   opacity: 1;
      // }
    }
  }

  //.plmr-c-tooltip__flag
  @include element('flag') {
    background: plmr-color('background', 'secondary');
    bottom: 100%;
    box-shadow: -5px 5px 10px 0 rgba(plmr-color('base', 'black'), 0.1);
    color: plmr-color('text', 'primary');
    font-size: 0.8571rem;
    left: 50%;
    line-height: 1rem;
    margin-bottom: 15px;
    margin-left: 5px;
    min-width: 275px;
    opacity: 0;
    padding: 10px 15px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    text-transform: none;
    transform: translateX(-50%);
    transition: 0.333s ease;
    z-index: 2;

    &:hover {
      opacity: 1;
      pointer-events: auto;
    }

    // .plmr-c-tooltip__flag::before
    &::before {
      @include plmr-pseudo;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 10px solid plmr-color('background', 'secondary');
      height: 0;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      width: 0;
    }

    //.plmr-c-tooltip__flag--left
    @include modifier('left') {
      bottom: auto;
      left: auto;
      margin: 0;
      margin-right: 10px;
      right: 100%;
      top: 50%;
      transform: translate(0, -50%);

      // .plmr-c-tooltip__flag::before
      &::before {
        left: auto;
        right: -17px;
        top: 50%;
        transform: translate(0, -50%) rotate(-90deg);
      }
    }

    //.plmr-c-tooltip__flag--right
    @include modifier('right') {
      bottom: auto;
      box-shadow: 5px 5px 10px 0 rgba(plmr-color('base', 'black'), 0.1);
      left: 100%;
      margin: 0;
      margin-left: 20px;
      top: 50%;
      transform: translate(0, -50%);

      // .plmr-c-tooltip__flag::before
      &::before {
        left: -17px;
        top: 50%;
        transform: translate(0, -50%) rotate(90deg);
      }
    }
  }
}
