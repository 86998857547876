/**
@doc _docs/banner-hero-landing.md
@section Banner Hero Landing
@childof Banners
@page Banners Banner Hero Landing
@status ready
@hippo banner
@example
_markup/banner-hero-landing.hbs
*/

// .plmr-c-banner-hero-landing
@include plmr-component('banner-hero-landing') {
  min-width: plmr-config('container', 'min-width');
  padding-bottom: 30px;
  position: relative;
  z-index: 1;


  // .plmr-c-banner-hero-landing__image-container
  @include element('image-container') {
    margin-bottom: 30px;
  }


  // .plmr-c-banner-hero-landing__image-link
  @include element('image-link') {
    display: block;
  }


  // .plmr-c-banner-hero-landing__image
  @include element('image') {
    display: block;
    height: auto;
    position: relative;
    width: 100%;
  }


  // .plmr-c-banner-hero-landing__product-container
  @include element('product-container') {
    display: none;


    // screen width > 768px
    @include plmr-mq('md') {
      display: block;
      margin-bottom: 10px;
      margin-top: -20px;
      padding: 0 20px;
      position: relative;


      // .plmr-c-banner-hero-landing--right .plmr-c-banner-hero-landing__product-container
      .plmr-c-banner-hero-landing--right & {
        text-align: right;
      }

    }

  }


  // .plmr-c-banner-hero-landing__product-link
  @include element('product-link') {

  }


  // .plmr-c-banner-hero-landing__product-info
  @include element('product-info') {
    @extend .plmr-c-banner__copy--stat;
  }


  // .plmr-c-banner-hero-landing__description
  @include element('description') {
    margin: 0 auto;
    max-width: plmr-config('content', 'max-width');
    text-align: center;
  }


  // .plmr-c-banner-hero-landing__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.5rem;
    line-height: 1.333;
    margin: 0;
    padding-bottom: 15px;


    // screen width > 768px
    @include plmr-mq('md') {
      font-size: 2rem;
      line-height: 1.125;
    }

  }


  // .plmr-c-banner-hero-landing__eyebrow
  @include element('eyebrow') {
    @extend .plmr-c-banner__title--italic;
    font-size: 16px;
    line-height: 21px;


    // screen width > 768px
    @include plmr-mq(md) {
      font-size: 18px;
      line-height: 24px;
    }

  }


  // .plmr-c-banner-hero-landing__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
  }

}


/**
@section Left CTA
@sectionof Banner Hero Landing
@page Banners Banner Hero Landing
@example
_markup/banner-hero-landing--cta-left.hbs
*/


/**
@section Right CTA
@sectionof Banner Hero Landing
@page Banners Banner Hero Landing
@example
_markup/banner-hero-landing--cta-right.hbs
*/


/**
@section Left Info
@sectionof Banner Hero Landing
@page Banners Banner Hero Landing
@example
_markup/banner-hero-landing--info-left.hbs
*/

/**
@section Right Info
@sectionof Banner Hero Landing
@page Banners Banner Hero Landing
@example
_markup/banner-hero-landing--info-right.hbs
*/
