@include block('global-footer') {
  background: $c_white;
  position: relative;
}


/// .global-footer-container
@include block('global-footer-container') {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $max-width;
  min-width: $min-width;
  padding: 0 20px;
  
  
  /// screen > 768px
  @include media($bp-md) {
    padding: 0 30px;
  }
  
  
  /// screen > 992px
  @include media($bp-lg) {
    padding: 0 40px;
  }
  
  
  /// .global-footer-container__col
  @include child('col') {
    align-self: auto;
    flex: 1 1 auto;
    order: 0;
    
    @include media($bp-xs) {
      flex: 0 1 auto;
    }  
    
    
    /// .global-footer-container__col--first
    @include modifier('first') {
      display: flex;
      flex-wrap: wrap;
      
      @include media($bp-md) {
        display: block;
      }
    }
  
    
    /// .global-footer-container__col--last
    @include modifier('last') {
      width: 100%;  
      
      @include media($bp-md) {
        // max-width: 278px;
        // flex: 1 1 5%;
        width: auto;
      }
      
      @include media($bp-lg) {
        // max-width: 325px;
      }
    }
    
    
    /// .global-footer-container__col--nav
    @include modifier('nav') {
      
      
      @include media(1020px) {
        flex: 1 1 auto;  
      }
      
    }
    
  }
}



/// .global-footer
@include block('global-footer') {
  padding: rem(50) 0;
  
  
  /// .global-footer__logo
  @include child('logo') { 
    align-self: auto;
    flex: 1 1 auto; 
    margin-bottom: rem(24);
    order: 0;
    
    @include media($bp-md) {
      flex: 0 0 auto;
      margin-bottom: rem(30);
    }
    
    /// .global-footer__logo__link
    @include child('link') {
      
    }
    
    /// .global-footer__logo__image
    @include child('image') {
      display: block;
      height: 24px;
      width: 110px;
      
      @include media($bp-md) {
        height: 28px;
        width: 130px;
      }
    }
    
  }
  
  
  /// .global-footer__copyright
  @include child('copyright') {
    align-self: auto;
    flex: 1 1 auto;
    margin-bottom: rem(30);
    width: 100%;
    
    @include media($bp-md) {
      margin-bottom: rem(24);
      max-width: 215px;
    }
    
    @include media($bp-lg) {
      max-width: 300px;
    }
    
    /// .global-footer__copyright .rtf-component
    .rtf-component {
      margin: 0;
      
      /// .global-footer__copyright .rtf-component h3
      h3:not([class*=heading--]) {
        font-size: rem(14);
        font-weight: normal;
        line-height: rem(16);
        margin: 0 0 rem(8) 0;
      }
      
      /// .global-footer__copyright .rtf-component p
      p {
        font-size: rem(12);
        margin: 0;
        padding: 0;
      }
    }
    
  }
  
  
  /// .global-footer__lanague-selector
  @include child('language-selector') {
    margin-bottom: rem(30);
    width: 100%;
    
    @include media($bp-md) {
      margin-bottom: 0;
    }
    
  }
  
  
  /// .global-footer__navigation
  @include child('navigation') {
    margin-bottom: 30px;
    
    @include media($bp-md) {
      margin-bottom: 0;
      padding: 20px 10px 0;
    }    
    
  }
  
  
  /// .global-footer__label
  @include child('label') {
    display: block;
    font-size: 16px;
    margin: 0 0 12px 0;
  }
  
  
  /// .global-footer__buy-moen
  @include child('buy-moen') {
    
    /// .global-footer__buy-moen--top
    @include modifier('top') {
      align-self: center;
      margin-bottom: 24px;
    
      @include media($bp-md) {
        display: none;
      }  
    }
    
    
    /// .global-footer__buy-moen--bottom
    @include modifier('bottom') {
      display: none;
      margin-top: rem(36);
      
      @include media($bp-md) {
        display: block;
      }
    }
    
  }
  
}



/// .footer-nav
@include block('footer-nav') {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  
  
  @include media($bp-md) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  
  
  /// screen > 1020px
  @include media(1020px) {
    flex-direction: row; 
    justify-content: center;   
  }
  
  
  /// .footer-nav__list
  @include child('list') {
    margin: 0 rem(30) 0 0;
    
    @include media($bp-md) {
      margin: 0;
    }
    
    &:first-child {
      
      
      @include media($bp-lg) {
        margin-right: percentage(70 / 660);
      }
      
          
      .footer-nav__item:first-child {
        margin-top: 0;
      }
    }
  }
  
  
  /// .footer-nav__item
  @include child('item') {
    font-size: 14px;
    margin: 7px 0;
    
    
    /// .footer-nav__item:first-child
    &:first-child {
      
      
      /// screen > 320px
      @include media($bp-xxs) {
        margin-top: 0;
      }
      
      
      /// screen > 768px
      @include media($bp-md) {
        margin-top: 7px;
      }
      
      
      /// screen > 992px
      @include media($bp-lg) {
        margin-top: 0;
      }
    
    }
    
    /// .footer-nav__item:last-child
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  
  /// .footer-nav__link
  @include child('link') {
  }
  
}
