
/// .tools-nav
@include block('tools-nav') {

  
  /// .tools-nav__title  
  @include child('title') {
    font-family: $din-bold;
    font-size: 14px;
    margin: 0 0 12px 0;
    text-transform: uppercase;
  }
  
  
  /// .tools-nav__list
  @include child('list') {
    
  }
  
  
  /// .tools-nav__item
  @include child('item') {
    margin-bottom: 15px;
  }
  
  
  /// .tools-nav__link
  @include child('link') {
    align-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    justify-content: flex-start;
    line-height: 12px;
    position: relative;
  }
  
  
  /// .tools-nav__icon
  @include child('icon') {
    align-self: auto;
    flex: 0 1 auto;
    margin-right: 15px;
    max-height: 25px;
    max-width: 25px; 
    order: 0;
  }
  
  
  /// .tools-nav__icon-placeholder
  @include child('icon-placeholder') {
    align-self: auto;
    flex: 0 1 auto;
    margin-right: 15px;
    max-height: 25px;
    max-width: 25px; 
    order: 0;
    width: 25px;
  }
  
  
  /// .tools-nav__label
  @include child('label') {
    align-self: auto;
    flex: 0 1 auto;
    order: 0;
  }
  
}
