/*
Modal

The visual display of the modals used throughout the site is controlled with these css declarations.

markup:
<div></div>

Styleguide 3.11
 */

dialog {
  display: block;
}

#js-modal-content {
  img {
    width: auto;
    max-width: 100%;
    max-height: inherit;
    margin: 0 auto;
  }
  video {
    width: 100%;
    height: auto;
  }
}


.no-scroll {
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  // z-index: z("modal-overlay");
  z-index: plmr-z($layer: 'modal', $offset: -5); // new setting reference
  background-color: $c_dark-gray;
  opacity: .8;
  cursor: default;
  .invisible {
    @include hat-core();
  }
}

.modal-overlay[data-background-click="disabled"] {
  cursor: auto;
}

@include block(modal) {
  @include outer-container();
  color: inherit;
  position: fixed;
  background: $c_white;
  // z-index: z("modal");
  z-index: plmr-z($layer: 'modal'); //new setting reference
  max-height: 98vh;
  overflow: auto;
  left: 1%;
  top: 50%;
  bottom: auto;
  padding: 0 .25em .25em;
  right: 1%;
  transform: translateY(-50%);
  width: 94vw;
  @include media($bp-md) {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 1em 1em;
  }

  @include element(body) {
    padding: 1em 2em;
    @include media($bp-md) {
      padding: 1em 3em;
    }
  }

 @include element(text-content) {
    padding: 2em;
  }

  @include element(embed) {
    clear: both;
    width: 100%;
    height: auto;
    display: block;

    @include modifier('video-player') {
      height: 0;
      padding-top: percentage(360/640);
      position: relative;
    }

    @include child('player') {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.reel-overlay {
  margin: 0 auto;
}

.modal-close {
  float: right;
  color: $c_text-base;
  text-decoration: none;
  margin: 1em .5em 1em;
  position: relative;
}

.modal-close__text {
  cursor: pointer;
  font-family: $helvetica;
  font-weight: bold;


  &:after {
    background: $c_moen-bright-blue;
    border: 2px solid $c_white;
    border-radius: 50%;
    box-shadow: 0 2px 3px 0 rgba($c_moen-gray, 0.40);
    color: $c_white;
    content: "\2715";
    display: inline-block;
    margin-left: .5em;
    padding: 2px 5px;
    text-align: center;
  }
}

.modal-ada__title {
  padding: .5em .5em .5em .25em;
}



@include block(light-modal) {
  @include outer-container();
  color: inherit;
  position: fixed;
  background: $c_white;
  z-index: z("modal");
  overflow: auto;
  top: 50%;
  bottom: auto;
  left: 50%;
  right: auto;
  transform: translate(-50%,-50%);
  width: 90%;
	max-height: 98%;
	overflow: hidden;
	padding:0;

  @include media($bp-md) {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0;
  }

  @include element(body) {
    padding: 1em 2em;
    @include media($bp-md) {
      padding: 1em 3em;
    }
  }

 @include element(text-content) {
    padding: 2em;
  }

  @include element(embed) {
    clear: both;
    width: 100%;
    height: auto;
    display: block;
    box-shadow: 0 0 50px 0 $c_concrete;
    @include modifier('video-player') {
      height: 0;
      padding-top: percentage(540/1025);
      position: relative;
    }

    @include child('player') {
	    position: absolute;
	    bottom:0;
	    left:0;
      width: 100%;
      height: 100%;
      max-height: 98vh;
      object-fit: cover;
    }
  }

}
@include block(light-modal-overlay) {
	position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: z("modal-overlay");
  background-color: #FFF;
  opacity: 0.5;
  cursor: default;
  .invisible {
    @include hat-core();
  }
}
.light-modal-close {
	display: block;
	width: 15px;
	height: 15px;
	overflow: hidden;
	position: absolute;
	top:25px;
	right:25px;
	z-index: 2;
	text-indent: -999px;
	background-image: url('images/icon-close_thin-gray.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 31px 31px;
	outline: none;
	cursor: pointer;
	@include media($bp-md) {
		width: 31px;
		height: 31px;
	}
}
