
/**
@doc _docs/banner-cta.md
@section Banner CTA
@childof Banners
@page Banners Banner CTA
@status ready
@hippo email-signup
@example
_markup/banner-cta.hbs
*/
@include plmr-component('banner-cta') {
  display: block;
  height: auto;
  padding: 60px 0;
  position: relative;
  text-align: center;
  width: 100%;


  @include plmr-mq(md) {
    padding: 75px 0 70px;
  }


  // .plmr-c-banner-cta__copy
  @include element('copy') {
    @include plmr-font-face($family: 'regular');
    color: plmr-color('brand', 'primary');
    font-size: 18px;
    line-height: 32px;
    margin: 0 auto;
    padding: 20px 18px 0;


    @include plmr-mq(md) {
      font-size: 21px;
      line-height: 38px;
      max-width: 478px;
      padding-left: 0;
      padding-right: 0;
    }
  }


  // .plmr-c-banner-cta__cta
  @include element('cta') {
    padding: 30px 0 0;
  }


  // .plmr-c-banner-cta__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: inherit;
    font-size: 27.5px;
    line-height: 32px;
    margin: 0;
    text-transform: uppercase;


    @include plmr-mq(md) {
      font-size: 34px;
      line-height: 46px;
    }
  }


  // .plmr-c-banner-cta__title-logo
  @include element('title-logo') {
    display: inline-block;
    height: 20px;
    vertical-align: baseline;
    width: auto;


    @include plmr-mq(md) {
      height: 25px;
    }
  }
}


/**
@doc _docs/banner-cta-large.md
@section Banner CTA Large
@childof Banners
@page Banners Banner CTA Large
@status ready
@hippo email-signup
*/

/**
@section Left Content
@sectionof Banner CTA Large
@page Banners Banner CTA Large
@example
_markup/banner-cta-large--left.hbs
*/
@include plmr-component('banner-cta-large') {
  margin: 0 auto;
  max-width: plmr-config('container', 'max-width');
  min-height: 582px;
  min-width: plmr-config('container', 'min-width');
  overflow: hidden;
  position: relative;
  width: 100%;

  @include plmr-mq(md) {
    height: 731px;
  }

  @include plmr-mq(lg) {
    height: 810px;
  }

  // .plmr-c-banner-cta-large__background
  @include element('background') {
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    z-index: 1;
  }

  // .plmr-c-banner-cta-large__image
  @include element('image') {
    display: block;
    height: auto;
    min-width: 100%;
    width: auto;
  }

  // .plmr-c-banner-cta-large__content
  @include element('content') {
    left: 0;
    padding: 50px 25px 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 2;

    @include plmr-mq(md) {
      padding: 75px 55px 0;
      width: 558px;
    }

    @include plmr-mq(lg) {
      padding: 75px 91px 0;
      width: 637px;
    }

    // .plmr-c-banner-cta-large__content--right
    @include modifier('right') {
      left: auto;
      right: 0;
    }
  }

  // .plmr-c-banner-cta-large__title
  @include element('title') {
    color: plmr-color('brand', 'primary');
  }

  // .plmr-c-banner-cta-large__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
    padding-top: 20px;
  }

  // .plmr-c-banner-cta-large__cta
  @include element('cta') {
    padding-bottom: 0;
    padding-top: 38px;

    @include plmr-mq(md) {
      padding-top: 25px;
    }
  }
}


/**
@section Right Content
@sectionof Banner CTA Large
@page Banners Banner CTA Large
@example
_markup/banner-cta-large--right.hbs
*/
