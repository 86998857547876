/**
@doc _docs/cardlist-supporting-information.md
@section Card List Supporting Information
@childof Card Lists
@page Cardlists Cardlist Supporting Information
@status in progress
@hippo card-list
@example
_markup/cardlist-supporting-information.hbs
*/
@include plmr-component('cardlist-supporting-info') {
  //.plmr-c-cardlist-supporting-info__container
  @include element('container') {
    @extend .plmr-l-container;
    font-size: 0;
    padding: 20px 0;

    @include plmr-mq(lg) {
      padding: 40px 0;
      padding-left: calc(38 / 1300 * 100%);
      padding-right: calc(38 / 1300 * 100%);
    }

    @include plmr-mq(xl) {
      padding-left: 38px;
      padding-right: 38px;
    }
  }

  //.plmr-c-cardlist-supporting-info__item
  @include element('item') {
    display: inline-block;
    padding: 20px 25px;
    vertical-align: top;
    width: 100%;

    @include plmr-mq(md) {
      padding: 20px 55px;
    }

    @include plmr-mq(lg) {
      padding-left: calc(53 / 1300 * 100%);
      padding-right: calc(53 / 1300 * 100%);
      width: 50%;
    }

    @include plmr-mq(xl) {
      padding-left: 53px;
      padding-right: 53px;
    }
  }

  //.plmr-c-cardlist-supporting-info__item-title
  @include element('item-title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.285rem;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 2rem;
      line-height: 2.285rem;
    }
  }

  //.plmr-c-cardlist-supporting-info__item-copy
  @include element('item-copy') {
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding-top: 15px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }

    strong,
    b {
      @include plmr-font-face($family: 'bold');
    }
  }

  //.plmr-c-cardlist-supporting-info__item-button
  @include element('item-button') {
    padding-top: 30px;
  }

  //.plmr-c-cardlist-supporting-info__video-title
  @include element('video-title') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    font-size: 1.143rem;
    line-height: 1.714rem;
    margin-bottom: 25px;
    padding-bottom: 0;
  }

  //.plmr-c-cardlist-supporting-info__video-preview
  @include element('video-preview') {
    position: relative;
  }

  //.plmr-c-cardlist-supporting-info__video-button
  @include element('video-button') {
    bottom: 0;
    left: 0;
    margin: 20px;
    position: absolute;
    z-index: 2;
  }
}


/**
@doc _docs/cardlist-supporting-information--with-video.md
@section With Video
@sectionof Card List Supporting Information
@page Cardlists Cardlist Supporting Information
@example
_markup/cardlist-supporting-information--with-video.hbs
*/
