/**
@doc _docs/banner-experience-split.md
@section Banner Experience Split
@childof Banners
@page Banners Banner Experience Split
@status in progress
@hippo banner
*/

/**
@section Content Left
@sectionof Banner Experience Split
@example
_markup/banner-experience-split--left.hbs
*/
@include plmr-component('banner-experience-split') {
  //.plmr-c-banner-experience-split--left
  @include modifier('left') {
    .plmr-c-banner-experience-split__inner {
      @include plmr-mq(md) {
        padding-left: 55px;
        padding-right: calc(325 / 768 * 100%);
      }
  
      @include plmr-mq('lg') {
        padding-left: calc(142 / 1300 * 100%);
        padding-right: calc(652 / 1300 * 100%);
      }
  
      @include plmr-mq('xl') {
        padding-left: 142px;
        padding-right: 652px;
      }
    }

    .plmr-c-banner-experience-split__image-area {
      left: auto;
      right: 0;

      @include plmr-mq('md') {
        margin-left: auto;
        margin-right: -50px;
      }

      @include plmr-mq('lg') {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}

/**
@section Content Right
@sectionof Banner Experience Split
@example
_markup/banner-experience-split--right.hbs
*/
@include plmr-component('banner-experience-split') {
  position: relative;

  //.plmr-c-banner-experience-split::before
  &::before {
    @include plmr-pseudo;
    background: plmr-color('brand', 'tertiary');
    height: 50px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -25px);
    width: 1px;
    z-index: 2;

    @include plmr-mq(md) {
      height: 75px;
      transform: translate(-50%, -37px);
    }
  }

  //.plmr-c-banner-experience-split__inner
  @include element('inner') {
    overflow: hidden;
    padding: 60px 35px 268px;
    position: relative;

    @include plmr-mq(md) {
      padding-bottom: 75px;
      padding-left: calc(325 / 768 * 100%);
      padding-right: 55px;
      padding-top: 75px;
    }

    @include plmr-mq('lg') {
      padding-bottom: 120px;
      padding-left: calc(652 / 1300 * 100%);
      padding-right: calc(142 / 1300 * 100%);
      padding-top: 100px;
    }

    @include plmr-mq('xl') {
      padding-left: 652px;
      padding-right: 142px;
    }
  }

  //.plmr-c-banner-experience-split__content
  @include element('content') {
    position: relative;
    text-align: center;
    z-index: 2;
  }

  //.plmr-c-banner-experience-split__content-review
  @include element('content-review') {
    color: plmr-color('brand', 'primary');
    padding-bottom: 15px;
    padding-top: 0;

    [data-bv-show='rating_summary'] { // bazaar voice integration
      display: flex;
      justify-content: center;
      padding-left: 32px;
    }
  }

  //.plmr-c-banner-experience-split__content-review-link
  @include element('content-review-link') {
    margin-left: 10px;
  }

  //.plmr-c-banner-experience-split__content-title
  @include element('content-title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 2rem;
    line-height: 2.285rem;
    margin: 0;
    padding-bottom: 15px;

    @include plmr-mq(md) {
      font-size: 2.571rem;
      line-height: 3.428rem;
      padding-bottom: 20px;
    }
  }

  //.plmr-c-banner-experience-split__content-copy
  @include element('content-copy') {
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding-bottom: 28px;

    @include plmr-mq(md) {
      padding-bottom: 48px;
    }
  }

  //.plmr-c-banner-experience-split__image-area
  @include element('image-area') {
    bottom: 0;
    height: auto;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    width: 400px;
    z-index: 1;

    @include plmr-mq('md') {
      margin-left: -50px;
      width: 525px;
    }

    @include plmr-mq('lg') {
      margin-left: 0;
      width: 768px;
    }
  }

  //.plmr-c-banner-experience-split__image
  @include element('image') {
    max-width: 100%;
  }
}
