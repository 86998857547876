
//.plmr-c-product-reviews
@include plmr-component('product-reviews') {
  //.plmr-c-product-reviews__drawer
  @include element('drawer') {
    max-height: 0;
    overflow: hidden;
    text-align: center;
    transition: max-height 0.25s ease-in-out;

    //.plmr-c-product-reviews__drawer._open
    @include plmr-state('open') {
      max-height: 1000px; // fallback
    }
  }

  //.plmr-c-product-reviews__button
  @include element('button') {
    margin-bottom: 40px;
    margin-top: 30px;
  }
}


@import 'product-specs';

@import 'featured-product-specs';

@import 'additional-product-specs';

@import 'product-hero';

@import 'product-info';

@import 'valve-selection';

@import 'installation-support';

@import 'replacement-parts';

@import 'installation-and-guides';

@import 'add-to-cart';
