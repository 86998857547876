
/**
@doc _docs/borders.md
@section Borders
@childof Utilities
@page Borders
@status ready
@example
_markup/borders.hbs
*/


/**
@doc _docs/border-width.md
@section Border Width
@sectionof Borders
@example
_markup/border-width.hbs
*/


/**
@doc _docs/border-style.md
@section Border Style
@sectionof Borders
@example
_markup/border-style.hbs
*/


/**
@doc _docs/border-color.md
@section Border Color
@sectionof Borders
@example
_markup/border-color.hbs
*/



// .plmr-u-border
@include plmr-utility('border') {
  border: 1px solid  currentColor;

  // .plmr-u-border--#${plmr-colors key}-#{$plmr-colors variant}
  // .plmr-u-border--brand-primary
  @include plmr-modifiers($plmr-colors, 'border-color');


  // .plmr-u-border--#{$width}
  // .plmr-u-border--2
  @include plmr-modifiers(map-get($plmr-borders, 'width'), 'border-width');


  // .plmr-u-border--#{$style}
  // .plmr-u-border--dashed
  @include plmr-modifiers(map-get($plmr-borders, 'style'), 'border-style');

}



// .plmr-u-border-top
@include plmr-utility('border-top') {
  border-top: 1px solid currentColor;

  // .plmr-u-border-top--{width}
  // .plmr-u-border-top--5
  @include plmr-modifiers(map-get($plmr-borders, 'width'), 'border-top-width');


  // .plmr-u-border-top--{style}
  // .plmr-u-border-top--dashed
  @include plmr-modifiers(map-get($plmr-borders, 'style'), 'border-top-style');


  // .plmr-u-border-top--{color}
  // .plmr-u-border-top--brand-primary
  @include plmr-modifiers($plmr-colors, 'border-top-color');

}




// .plmr-u-border-bottom
@include plmr-utility('border-bottom') {
  border-bottom: 1px solid currentColor;

  // .plmr-u-border-bottom--{width}
  // .plmr-u-border-bottom--5
  @include plmr-modifiers(map-get($plmr-borders, 'width'), 'border-bottom-width');


  // .plmr-u-border-bottom--{style}
  // .plmr-u-border-bottom--dashed
  @include plmr-modifiers(map-get($plmr-borders, 'style'), 'border-bottom-style');


  // .plmr-u-border-bottom--{color}
  // .plmr-u-border-bottom--brand-primary
  @include plmr-modifiers($plmr-colors, 'border-bottom-color');

}




// .plmr-u-border-left
@include plmr-utility('border-left') {
  border-left: 1px solid currentColor;

  // .plmr-u-border-left--{width}
  // .plmr-u-border-left--5
  @include plmr-modifiers(map-get($plmr-borders, 'width'), 'border-left-width');


  // .plmr-u-border-left--{style}
  // .plmr-u-border-left--dashed
  @include plmr-modifiers(map-get($plmr-borders, 'style'), 'border-left-style');

  // .plmr-u-border-left--{color}
  // .plmr-u-border-left--brand-primary
  @include plmr-modifiers($plmr-colors, 'border-left-color');

}




// .plmr-u-border-right
@include plmr-utility('border-right') {
  border-right: 1px solid currentColor;

  // .plmr-u-border-right--{width}
  // .plmr-u-border-right--5
  @include plmr-modifiers(map-get($plmr-borders, 'width'), 'border-right-width');


  // .plmr-u-border-right--{style}
  // .plmr-u-border-right--dashed
  @include plmr-modifiers(map-get($plmr-borders, 'style'), 'border-right-style');

  // .plmr-u-border-right--{color}
  // .plmr-u-border-right--brand-primary
  @include plmr-modifiers($plmr-colors, 'border-right-color');

}




@import 'border-radius';
