
/**
@doc _docs/cardlist-zoom.md
@section Card List Zoom
@childof Card Lists
@page Cardlists Cardlist Zoom
@status in progress
@hippo card-list
@example
_markup/cardlist-zoom.hbs
*/
@include plmr-component('cardlist-zoom') {
  padding: 80px 0 20px;

  // screen > 768
  @include plmr-mq(md) {
    padding: 120px 0 30px;
  }

  // screen > 992
  @include plmr-mq(lg) {
    padding: 150px 0 40px;
  }

  //.plmr-c-cardlist-zoom__container
  @include element('container') {
    font-size: 0;
    padding: 0 10px;

    // screen > 768
    @include plmr-mq(md) {
      padding: 0 15px;
    }

    // screen > 992
    @include plmr-mq(lg) {
      padding: 0 20px;
    }
  }

  //.plmr-c-cardlist-zoom__item
  @include element('item') {
    background-color: plmr-color('background', 'tertiary');
    display: block;
    height: 300px;
    margin: 20px auto;
    overflow: hidden;
    padding: 30px 28px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: 0.5s ease;
    width: calc(100% - 20px);

    //.plmr-c-cardlist-zoom__item:hover
    &:hover {
      background-color: darken(plmr-color('background', 'tertiary'), 2.75%);

      .plmr-c-cardlist-zoom__item-image {
        transform: scale(1.1);

        //.plmr-c-cardlist-zoom__item-image._centered
        @include plmr-state('centered') {
          transform: translateX(-50%) scale(1.05);
        }
      }
    }

    // screen > 768
    @include plmr-mq(md) {
      display: inline-block;
      height: 450px;
      margin: 0 15px;
      padding: 50px 45px 0;
      width: calc(50% - 30px);
    }

    // screen > 992
    @include plmr-mq(lg) {
      height: 650px;
      margin: 0 20px;
      padding: 60px 40px 0;
      width: calc(50% - 40px);
    }
  }

  //.plmr-c-cardlist-zoom__item-eyebrow
  @include element('item-eyebrow') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'secondary');
    font-size: 1.143rem;
    line-height: 1;
    padding-bottom: 15px;

    // screen > 768
    @include plmr-mq(md) {
      font-size: 1.285rem;
      line-height: 1.714rem;
      padding-bottom: 20px;
    }
  }

  //.plmr-c-cardlist-zoom__item-heading
  @include element('item-heading') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0;
    padding-bottom: 20px;

    // screen > 768
    @include plmr-mq(md) {
      font-size: 2.285rem;
      line-height: 2.571rem;
      padding-bottom: 30px;
    }

    // screen > 992
    @include plmr-mq(lg) {
      font-size: 2.571rem;
      line-height: 3.428rem;
      padding-bottom: 40px;
    }
  }

  //.plmr-c-cardlist-zoom__item-image
  @include element('item-image') {
    bottom: 0;
    display: block;
    height: 140px;
    pointer-events: none;
    position: absolute;
    right: 0;
    transition: 0.5s ease;
    width: auto;

    // screen > 768
    @include plmr-mq(md) {
      height: 185px;
    }

    // screen > 992
    @include plmr-mq(lg) {
      height: 350px;
    }

    // screen > 1200
    @include plmr-mq(xl) {
      height: 400px;
    }

    //.plmr-c-cardlist-zoom__item-image._tall
    @include plmr-state('tall') {
      height: 190px;

      // screen > 768
      @include plmr-mq(md) {
        height: 275px;
      }

      // screen > 992
      @include plmr-mq(lg) {
        height: 440px;
      }

      // screen > 1200
      @include plmr-mq(xl) {
        height: 470px;
      }
    }

    //.plmr-c-cardlist-zoom__item-image._centered
    @include plmr-state('centered') {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
