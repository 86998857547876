
// GRID FILTER
@include block(tap) {	
	@include element(grid-filter) {
		
		transition: all 0.5s ease-in-out;
		height: 70px;
		text-align: right;
		padding: 33px 10px 23px;
		@include media($bp-md) {
			height: 90px;
			padding: 45px 10px 30px; 
		}
		@include media ($bp-lg) {
			height: 120px;
			padding: 50px 10px 20px;
		}
		.filter {
			display: inline-block;
			vertical-align: middle;
			.form-label {
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
				font: normal 13px/13px $proxima-bold;
				color: $c_text-base;
				text-transform: uppercase;
			}
			.form-select-wrapper {
				display: inline-block;
				vertical-align: middle;
				width: 190px;
				margin-right: 20px;
				border-color: $c_moen_bright_gray;
				.tap-grid-tray-open & {
					@include media($bp-lg) {
						width: 85px;
					}
					@include media(1300px) {
						width: 190px;
					}
				}
				&:before {
					background-image: url('images/icon-angle_right_blue.svg');
					width: 6px;
					right: 16px;
					transform: translateY(-50%) rotate(90deg);
				}
				.form-select {
					padding: 17px 30px 17px 15px;
					color: $c_text-base;
					outline: none;
					font: normal 13px/16px $proxima-regular;
					overflow: hidden;
				}
			}
			&:last-child {
				.form-select-wrapper {
					margin-right: 0;
				}
			}
		}
		@include element(popup) {
			pointer-events: none;
			position: fixed;
			z-index: 999;
			top:0;
			left:0;
			right:0;
			bottom: 0;
			background-color: rgba(#969696,0.5);
			height: 100%;
			width: 100%;
			opacity: 0;
			transition: opacity 0.5s ease;
			.inner {
				background-color: #FFF;
				position: absolute;
				top:50%;
				left:0;
				right:0;
				margin: 0 auto;
				transform: translateY(-50%) scale(0);
				z-index: 9;
				transition: transform 0.5s ease;
				padding: 60px 20px 40px;
				text-align: center;
				width: 100%;
				height: 100vh;
				@include media($bp-md) {
					padding: 50px;
					max-width: 500px;
					max-height: 470px;
				}
				.tap__grid-filter__toggle {
					position: absolute;
					top:25px;
					right:25px;
				}
				.heading {
					font: bold 24px/30px $playfair;
					color: $c_text-base;
				}
				.filter {
					display: block;
					width: 100%;
					text-align: left;
					padding: 15px 10px 0;
					border-bottom: solid 1px $c_moen_bright_gray;
					&:first-of-type {
						border-top: solid 1px $c_moen_bright_gray;
					}
					.form-label {
						margin-bottom: 0;
					}
					.form-select-wrapper {
						display: block;
						width: 100%;
						border-color: transparent;
						&:before {
							right: 10px;
						}
						.form-select {
							padding-top: 10px;
							padding-left: 0;
							font-size: 16px;
						}
						.tap-grid-tray-open & {
							width: 100%;
						}
					}
				}
				.buttons {
					left:20px;
					right:20px;
					width: calc(100% - 40px);
					text-align: center;
					padding:0;
					position: absolute;
					bottom: 40px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					align-content: space-between;
					@include media($bp-md) {
						bottom:50px;						
						left:50px;
						right:50px;
						width: calc(100% - 100px);
					}
					button {
						
						
					}
				}
			}
			&.open {
				pointer-events: auto;
				opacity: 1;
				.inner{
					transform: translateY(-50%) scale(1);
				}
			}
		}
		@include element(toggle) {
			color: $c_moen-bright-blue;
			font: normal 12px/15px $proxima-bold;
			text-transform: uppercase;
			letter-spacing: 0.46px;
			text-decoration: none;
			img {
				margin-right: 10px;
			}
		}
		
		
	}
}
// END GRID FILTER
// TRAY TOGGLE
.tap-grid-tray-open {
	.tap {
		@include media($bp-md) {
			.tap__grid-filter {
				margin-left: 290px;
				width: calc(100% - 290px);
			}
		}
		@include media($bp-lg) {
			.tap__grid-filter {
				margin-left: 370px;
				width: calc(100% - 370px);
			}
		}
	}
}

// END TRAY TOGGLE