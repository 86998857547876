/**
@doc _docs/navigation-path-funnel.md
@section Path Funnel Navigation
@childof Navigation
@page Navigation Path Funnel Navigation
@status in progress
@hippo navigation
@example
_markup/navigation-path-funnel.hbs
*/
@include plmr-component('navigation-path-funnel') {
  padding-top: 40px;

  @include plmr-mq('md') {
    padding-top: 60px;
  }

  //.plmr-c-navigation-path-funnel__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.285rem;
    margin-bottom: 28px;
    padding: 0 35px;
    text-align: center;

    @include plmr-mq('md') {
      font-size: 2rem;
      margin: 0 auto 40px;
      max-width: 538px;
      padding: 0;
    }

    @include plmr-mq('lg') {
      max-width: 608px;
    }
  }

  //.plmr-c-navigation-path-funnel__steps
  @include element('steps') {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0 4.5px;

    @include plmr-mq('md') {
      margin: 0 auto;
      max-width: 768px;
      padding: 0;
    }
  }

  //.plmr-c-navigation-path-funnel__step
  @include element('step') {
    color: plmr-color('text', 'secondary');
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    font-size: 1rem;
    line-height: 1.285rem;
    padding: 30px 11.5px 32px;
    position: relative;
    text-align: center;
    width: 0;
    word-wrap: break-word;

    @include plmr-mq('md') {
      font-size: 1.143rem;
      line-height: 1.5rem;
      padding: 30px 0 36px;
    }

    //.plmr-c-navigation-path-funnel__step::before
    &::before {
      @include plmr-pseudo;
      background-color: transparent;
      border: 3px solid plmr-color('accent', 'light');
      border-radius: 50%;
      height: 18px;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 18px;
      z-index: 2;
    }

    //.plmr-c-navigation-path-funnel__step::after
    &::after {
      @include plmr-pseudo;
      background-color: plmr-color('accent', 'light');
      height: 5px;
      left: -50%;
      margin-left: 7px;
      top: 6px;
      // width: 100%;
      width: calc(100% - 14px);
      z-index: 1;
    }

    &:first-child {
      &::after {
        display: none;
      }
    }

    //.plmr-c-navigation-path-funnel__step._active
    @include plmr-state('active') {
      //.plmr-c-navigation-path-funnel__step._active::before
      &::before {
        border-color: plmr-color('brand', 'secondary');
      }

      //.plmr-c-navigation-path-funnel__step._active::after
      &::after {
        background-color: plmr-color('brand', 'secondary');
      }
    }

    //.plmr-c-navigation-path-funnel__step._completed
    @include plmr-state('completed') {
      //.plmr-c-navigation-path-funnel__step._completed::before
      &::before {
        background-color: plmr-color('brand', 'secondary');
        border-color: plmr-color('brand', 'secondary');
      }

      //.plmr-c-navigation-path-funnel__step._completed::after
      &::after {
        background-color: plmr-color('brand', 'secondary');
      }

      .plmr-c-navigation-path-funnel__step-name,
      .plmr-c-navigation-path-funnel__step-anchor {
        display: block;
      }
    }
  }

  //.plmr-c-navigation-path-funnel__step-label
  @include element('step-label') {
  }

  //.plmr-c-navigation-path-funnel__step-name
  @include element('step-name') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    display: none;

    @include plmr-mq('md') {
      line-height: 1.714rem;
      margin: 0 auto;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include plmr-mq('lg') {
      max-width: 200px;
    }
  }

  //.plmr-c-navigation-path-funnel__step-anchor
  @include element('step-anchor') {
    @include plmr-link($underline: true);
    bottom: 0;
    cursor: pointer;
    display: none;
    font-size: 1rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}
