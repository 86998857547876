
/// .secondary-menu-toggle
@include block('secondary-menu-toggle') {
  height: 75px;
  max-width: 75px;
  width: 100%;
  // z-index: 1;
}


/// .header-secondary
@include block('header-secondary') {
  margin: 0 auto;
  max-width: $max-width;
  min-width: $min-width;
  position: relative;
  z-index: z("flyout-menu");
  
  
  /// .header-secondary[aria-expanded='false']
  &[aria-expanded='false'] {
    display: none;
  }
  
}




/// .secondary-menu
@include block('secondary-menu') {
  background: $c_white;
  box-shadow: 0 2px 15px 0 rgba($c_text-base, 0.50);
  padding: 0 20px 20px 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: z("flyout-menu");
  
  
  /// screen > 768px
  @include media($bp-md) {
    max-width: 300px;
    padding: 30px 40px;
  }
  
  
  /// .secondary-menu__language-selector
  @include child('language') {
    padding-top: 20px;
  
    
    /// screen > 768px  
    @include media($bp-md) {
      display: none;
    }
    
  }
  
  
  /// .secondary-menu__buy-moen
  @include child('buy-moen') {
    
    
    /// .secondary-menu__buy-moen--top
    @include modifier('top') {
      background: $c_concrete;
      margin: 0 -20px;
      padding: 20px;
      position: relative;
      
      
      /// screen > 768px  
      @include media($bp-md) {
        display: none;
      }
      
    }
    
    
    /// .secondary-menu__buy-moen--bottom
    @include modifier('bottom') {
      display: none;
      
      
      /// screen > 768px
      @include media($bp-md) {
        display: block;
      }
      
    }
    
  }
  
}


/// .secondary-menu-divider
@include block('secondary-menu-divider') {
  border-bottom: 1px solid $c_moen-bright-gray;
  margin-bottom: 20px;
  padding-bottom: 15px;
}



/// .navigation-overlay
@include block('navigation-overlay') {
  background: rgba($c_black, 0.6);
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 115px;
  width: 100%;
  z-index: z("navigation-overlay");
  
  
  /// screen > 768px
  @include media($bp-md) {
    display: none;
  }
  
}
