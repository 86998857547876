
@include block('legacy') {
  
  body {
    padding-top: rem(28);
  }
}


@include block('legacy-banner') {
  background: $c_legacy;
  border: 1px solid #fdd8d8;
  color: $c_bv_error;
  font-size: rem(14);
  left: 0;
  padding: 5px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1000;  
  
  @include child('label') {
    font-weight: bold;
    margin-right: 10px;
  }
}
