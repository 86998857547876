/**
@doc _docs/border-radius.md
@section Border Radius
@childof Borders
@page Borders Border Radius
@status ready
@example
_markup/border-radius.hbs
*/


/**
@doc _docs/border-radius--sizing.md
@section Sizing
@sectionof Border Radius
@example
_markup/border-radius--sizing.hbs
*/


// .plmr-u-rounded
// .plmr-u-rounded--{border-radius}
// .plmr-u-rounded--sm
// .plmr-u-rounded--md
// .plmr-u-rounded--lg
@include plmr-utility('rounded') {
  @include plmr-modifiers(map-get($plmr-borders, 'radius'), 'border-radius');
}


// .plmr-u-rounded-circle
@include plmr-utility('rounded-circle') {
  border-radius: 50%;
}


// .plmr-u-rounded-top
// .plmr-u-rounded-top--{border-radius}
// .plmr-u-rounded-top--sm
// .plmr-u-rounded-top--md
// .plmr-u-rounded-top--lg
@include plmr-utility('rounded-top') {
  @include plmr-modifiers($map: map-get($plmr-borders, 'radius'), $attributes: ('border-top-left-radius', 'border-top-right-radius'));
}


// .plmr-u-rounded-right
// .plmr-u-rounded-right--{border-radius}
// .plmr-u-rounded-right--sm
// .plmr-u-rounded-right--md
// .plmr-u-rounded-right--lg
@include plmr-utility('rounded-right') {
  @include plmr-modifiers($map: map-get($plmr-borders, 'radius'), $attributes: ('border-bottom-right-radius', 'border-top-right-radius'));
}


// .plmr-u-rounded-bottom
// .plmr-u-rounded-bottom--{border-radius}
// .plmr-u-rounded-bottom--sm
// .plmr-u-rounded-bottom--md
// .plmr-u-rounded-bottom--lg
@include plmr-utility('rounded-bottom') {
  @include plmr-modifiers($map: map-get($plmr-borders, 'radius'), $attributes: ('border-bottom-left-radius', 'border-bottom-right-radius'));
}


// .plmr-u-rounded-left
// .plmr-u-rounded-left--{border-radius}
// .plmr-u-rounded-left--sm
// .plmr-u-rounded-left--md
// .plmr-u-rounded-left--lg
@include plmr-utility('rounded-left') {
  @include plmr-modifiers($map: map-get($plmr-borders, 'radius'), $attributes: ('border-bottom-left-radius', 'border-top-left-radius'));
}



