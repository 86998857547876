
/// .header-main
@include block('header-main') {
  background: $c_white;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 0 30px 0 rgba($c_moen-gray, 0.40);
  position: relative;
}




/// .header-logo
@include block('header-logo') {
  align-self: auto;
  flex: 1 1 auto;
  margin: 0 0 0 20px;
  order: 0;
  
  
  /// screen > 768px
  @include media($bp-md) {
    margin-left: 30px;
  }
  
  
  /// screen > 992px
  @include media($bp-lg) {
    margin-left: 40px;
  }
  

  /// .header-logo__image
  @include child('image') {
    height: 26px;
    transition: width 0.5s ease;
    width: 120px;
    
    
    /// screen > 768px
    @include media($bp-md) {
      height: 28px;
      width: 130px;  
    }
    
    
    /// screen > 900px
    @include media(900px) {
      height: 32px;
      width: 150px;
    }
    
  }
  
}
