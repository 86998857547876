
//
$plmr-borders: (
  'width': (
    'base': 1px,
    '1': 1px,
    '2': 2px,
    '3': 3px,
    '4': 4px,
    '5': 5px
  ),

  'style': (
    'base': solid,
    'dashed': dashed,
    'dotted': dotted,
    'none': none,
    'solid': solid
  ),

  'radius': (
    'base': 2px,
    'sm': 5px,
    'md': 7px,
    'lg': 10px
  )
);

