// draws a dashed line around containers to indicate initial freemarker template setup:
.ftl-temp {
  border: 1px dashed orange;
}

// Hippo Edit Buttons
// These lines solves the issue with buttons 
// that were overlaying at top when edit
// the importants are used in order to overwrite 
// the style added in the DOM by the channel manager
a.hst-cmseditlink {
  display: inline !important;
  position: relative !important;
}


.hippo-overlay-element-link.hippo-overlay-element-visible {
  height: 40px !important;
  width: 40px !important;
}
