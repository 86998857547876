
@include block('garbage-disposals') {

  @include child('section') {
    padding: 2em 0;

    @include child('header') {
      font-size: 1.5em;

      @include media($bp-sm) {
        font-size: 2em;
      }
    }
  }

  .slick-arrow {
    height: 90px;
    width: 40px;
    z-index: 1
  }

  .slick-prev {
    background-image: url('images/icon-arrow_left_large-gray.svg');
    left: 0;
  }

  .slick-next {
    background-image: url('images/icon-arrow_right_large-gray.svg');
    right: -8px;
  }

}

@include block('browse-garbage-disposals') {
  @include clearfix;
  position: relative;
}

@include block('garbage-disposal') {
  // background: $c_moen-dark-blue;
  float: left;
  width: 50%;

  @include media($bp-sm) {
    width: percentage(1/3);
  }


  @include media($bp-md) {
    width: percentage(1/7);
  }


  @include child('link') {
    color: inherit;
    display: block;
    padding: 1.7em 1em 1em 1em;

    &:hover {
      background-color: $c_concrete;
      text-decoration: none;
    }
  }

  @include child('image') {
    height: 188px;
    margin: 0 auto 1.5em auto;
    max-height: 188px;
    max-width: 86px;
    text-align: center;
    width: 86px;

    position: relative;

    img {
      bottom: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);

    }
  }

  @include child('title') {
    color: $c_moen-bright-blue;
    font-weight: bold;
    margin-bottom: 1em;
    text-align: center;

    &:hover {
      text-decoration: none;
    }

    @include child('link') {
      display: block;
      font-weight: normal;
      margin-top: 0.5em;

      .garbage-disposal__link:hover & {
        text-decoration: underline;
      }
    }
  }

  @include child('info') {
    text-align: center;

    @include child('item') {
      border-bottom: 1px solid currentColor;
      font-size: rem(12);
      list-style: none;
      padding: 0.5em;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  @include child('horsepower-meter') {
    display: none;
    margin-top: 2em;

    @include media($bp-sm) {
      display: block;
    }

    @include child('image') {
      display: block;
      margin: 0 auto 1.5em auto;
    }
  }
}


@include block('garbage-disposals-features') {
  // background: $c_concrete;
  background: #f5f5f5;

  p {
    // color: $c_dark-gray;
    font-size: rem(18);
  }

}


@include block('garbage-disposal-how-to-videos') {
  background: $c_concrete;

}



@include block('garbage-disposals-videos') {

  @include child('list') {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 1020px;
    padding: 1.875em 0.625em 0;

    @include media($bp-md) {
      flex-direction: row;
    }
  }
}


@include block('garbage-disposals-warranty') {
  background: $c_moen-gray;

  @include child('logo') {

  }
}


@include block('disposals-features') {
  padding: 2em 0 3em 0;


  @include child('title') {
    color: $c_moen-bright-blue;
    font-size: rem(25);
  }

  @include child('note') {
    font-size: rem(11) !important;
    font-style: italic;
  }

  @include child('shop') {
    margin: 4em 0 2em 0;

    p {
      font-size: rem(14);
    }
  }
}


@include block('disposals-features-overview') {

  @include child('content') {
    position: relative;

    @include modifier('soundshield') {
      padding-right: 60px;

      &::before {
        background: url('https://embed.widencdn.net/img/moen/efwdxmj448/exact/icon-ear.png?u=hhyaeb') no-repeat;
        content: '';
        display: block;
        height: 50px;
        position: absolute;
        right: 0;
        top: 3em;
        width: 42px;
      }
    }

    @include modifier('power-cord') {
      padding-right: 60px;

      &::before {
        background: url('https://embed.widencdn.net/img/moen/9ot1dbtdwx/exact/icon-clock.png?u=hhyaeb') no-repeat;
        background-size: 100%;
        content: '';
        display: block;
        height: 45px;
        position: absolute;
        right: 0;
        top: 3em;
        width: 45px;
      }
    }
  }
}

@include block('disposals-features-visual') {
  // background: $c_moen-dark-blue;
  height: 0;
  margin: 0 auto 2em auto;
  max-height: 375px;
  max-width: 172.5px;
  padding-top: percentage(.5 * (750 / 410));
  position: relative;
  width: percentage(.5 * (345 / 410));

  @include media($bp-xs) {
    margin: 0 0 2em 0;
    max-height: 750px;
    max-width: 345px;
    padding-top: percentage(750 / 410);
    width: percentage(345 / 410);
  }

  @include child('image') {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::before {
    // background-image: url('/binaries/content/gallery/moen/kitchen/garbage-disposals/features/features_zoom_universal-xpress-mount.png');
    // background-color: $c_moen-maroon;
    background-image: none;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: block;
    height: percentage(248 / 750);
    left: -9999em;
    max-height: 248px;
    max-width: 248px;
    position: absolute;
    width: percentage(248 / 350);
    z-index: 2;


    .garbage-disposals-features--universal-xpress-mount & {
      background-image: url('https://embed.widencdn.net/img/moen/ryjxb0v30v/exact/features_zoom_universal-xpress-mount.png?quality=100&u=hhyaeb');
      left: percentage(150 / 350);
      top: percentage(-23 / 750);
    }

    .garbage-disposals-features--vortex-motor & {
      background-image: url('https://embed.widencdn.net/img/moen/apfcmtlaba/exact/features_zoom_vortex-motor.png?quality=100&u=hhyaeb');
      left: percentage(48 / 350);
      top: percentage(257 / 750);
    }

    .garbage-disposals-features--jams-less & {
      background-image: url('https://embed.widencdn.net/img/moen/9ycpcv6mlm/exact/features_zoom_jams-less.png?quality=100&u=hhyaeb');
      left: percentage(40 / 350);
      top: percentage(148 / 750);
    }

    .garbage-disposals-features--continuous-feed & {
      background-image: url('https://embed.widencdn.net/img/moen/yy3wqpsz19/exact/features_zoom_continuous-feed.png?quality=100&u=hhyaeb');
      left: percentage(40 / 350);
      top: percentage(-30 / 750);
    }

    .garbage-disposals-features--soundshield & {
      background-image: url('https://embed.widencdn.net/img/moen/sd3ildy6he/exact/features_zoom_soundshield.png?quality=100&u=hhyaeb');
      left: percentage(105 / 350);
      top: percentage(220 / 750);
    }

    .garbage-disposals-features--power-cord & {
      background-image: url('https://embed.widencdn.net/img/moen/xuoizlkwgu/exact/features_zoom_power-cord.png?quality=100&u=hhyaeb');
      left: percentage(110 / 350);
      top: percentage(530 / 750);
    }
  
  }
}


@include block('disposals-features-nav') {
  margin: 0.5em 0 3em 0;

  @include child('item') {
    // background-image: url('/binaries/content/gallery/moen/kitchen/garbage-disposals/features/features_navigation.png');
    background-image: url('https://embed.widencdn.net/img/moen/ymgpc2pfjn/exact/features_navigation.png?quality=100&u=hhyaeb');
    background-repeat: no-repeat;
    background-size: 84px auto;
    border-radius: 50%;
    display: inline-block;
    height: 42px;
    margin-left: 0.5%;
    margin-bottom: 0.5%;
    opacity: 0.35;
    text-indent: -9999em;
    width: 42px;

    &:first-child {
      margin-left: 0;
    }

    @include media($bp-sm) {
      margin-left: 1%;
      margin-bottom: 1%;
    }

    @include media($bp-md) {
      background-size: 84px auto;
      height: 42px;
      width: 42px;
    }

    @include state('active') {
      cursor: default;
      opacity: 1;
    }

    @include modifier('performance') {
      background-position: 0 0;
    }

    @include modifier('universal-xpress-mount') {
      background-position: 0 -42px;
    }

    @include modifier('vortex-motor') {
      background-position: 0 -84px;
    }

    @include modifier('jams-less') {
      background-position: 0 -126px;
    }

    @include modifier('continuous-feed') {
      background-position: 0 -168px;
    }

    @include modifier('soundshield') {
      background-position: 0 -210px;
    }

    @include modifier('power-cord') {
      background-position: 0 -252px;
    }

    @include modifier('eco-friendly') {
      background-position: 0 -294px;
    }
    
    @include modifier('batch-feed') {
      background-position: 0 -336px;
    }    

    &:hover:not(.is-active) {
      opacity: 1;
      background-position-x: -42px;
    }

  }
}

@include block('disposal-additional-features') {

  @include child('item') {
    list-style: none;
    margin: 1em 0;
    min-height: 50px;
    padding: 1em 0 0 75px;
    position: relative;

    &::before {
      background-position: right 50%;
      background-repeat: no-repeat;
      background-size: 100%;
      content: '';
      display: block;
      height: 50px;
      left: 0;
      position: absolute;
      top: 0;
      width: 60px;
    }

    @include modifier('splash-guard') {

      &::before {
        background-image: url('https://embed.widencdn.net/img/moen/6iunaf2rid/exact/icon-splash.png?u=hhyaeb');
        background-size: 36px;
      }
    }

    @include modifier('drain-elbow') {

      &::before {
        background-image: url('https://embed.widencdn.net/img/moen/mn2vypfsms/exact/icon-elbow.png?u=hhyaeb');
      }
    }

    @include modifier('drain-stopper') {

      &::before {
        background-image: url('https://embed.widencdn.net/img/moen/mqeuliaygi/exact/icon-stopper.png?u=hhyaeb');
        background-size: 37px;
      }
    }

    @include modifier('sink-flange') {

      &::before {
        background-image: url('https://embed.widencdn.net/img/moen/18dr8cgxg6/exact/icon-flange.png?u=hhyaeb');
        background-size: 38px;
      }
    }
  }
}

@include block('thd-banner-content') {
  padding-right: 0.5em;

  @include child('shop-now') {
    margin: 1em 0 !important;
    width: 100% !important;
  }
}
