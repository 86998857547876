
// .plmr-c-nav-secondary
@include plmr-component('nav-secondary') {
  //align-self: flex-end;
  display: flex;
  flex: 0 1 auto;
  font-size: 11px;
  justify-content: flex-end;
  line-height: 14px;
  //padding: 12px 0 8px;


  @include plmr-state('visible-on-md') {
    display: none;

    @include plmr-mq('md') {
      display: flex;
    }
  }


  // .plmr-c-nav-secondary--actions
  @include modifier('actions') {
    padding: 0;

  }


  // .plmr-c-nav-secondary__list
  @include element('list') {

    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
  }


  // .plmr-c-nav-secondary__item
  @include element('item') {
    margin-right: 16px;
    @media (min-width:768px) {
      margin-right: 30px;
    }
  }


  // .plmr-c-nav-secondary__icon
  @include element('icon') {
    padding: 9px 0;
  }


  // .plmr-c-nav-secondary__button-item
  @include element('button-item') {

  }


  // .plmr-c-nav-secondary__button
  @include element('button') {
    border-radius: 0;
    font-size: 11px;
    line-height: 14px;
    padding: 13px 15px 7px;


    // screen width > 768px
    @include plmr-mq('md') {
      padding: 13px 15px 7px;
    }
  }


  // .plmr-c-nav-secondary__link
  @include element('link') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('text', 'secondary');
    letter-spacing: 1px;
    min-height: 0; // for input button on sign out
    text-decoration: none;
    text-transform: uppercase;


    // .plmr-c-nav-secondary__link:active, .plmr-c-nav-secondary__link:focus, .plmr-c-nav-secondary__link:hover
    &:active,
    &:focus,
    &:hover {
      color: darken(plmr-color('text', 'secondary'), 10%);
    }

  }
  @include element('item-wheretobuy'){
    vertical-align: middle;
    display: flex;
    align-items: center;
    a{
      color: #8b888c;
    }
    #submitBtn{
      cursor: pointer;
    }
  }

}
