// .product-finish-gallery

@include block(product-finish-gallery) {
	display: block;
	position: relative;
	// .product-finish-gallery__selector
	// Switch between product and finish sliders
	@include element(selector) {
		display: block;
		text-align: center;
		padding: 40px 0;
		font-size: 0;
		position: relative;
		@include media($bp-md) {
			padding: 47px 40px 57px;
		}
		@include media($bp-xl) {
			padding: 47px 60px 60px;
		}
		// .product-finish-gallery__selector .section-heading--1
		.section-heading--1 {
			font: normal 18px/21px $proxima-thin;
			color: $c_mid-gray;
			margin: 0;
			padding: 0 0 20px;
			@include media($bp-md) {
				float: left;
				display: inline-block;
				vertical-align: middle;	
				line-height: 40px;
				position: absolute;
				left:0;
				top:0;
			}
			@include media($bp-lg) {
				font-size: 21px;
			}
		}
		// .product-finish-gallery__selector a:not(.button)
		a:not(.button) {
			display: inline-block;
			vertical-align: middle;
			background: transparent;
			border-radius: 25px;
			padding: 10px 25px;
			font: normal 14px/1em $proxima-regular;
			color: $c_text-base;
			margin: 0 10px;
			transition: all 0.5s ease;
			text-decoration: none !important;
			&:hover {
				color: $c_mid-gray;
			}
			&.active {
				color: $c_mid-gray;
				background: $c_moen-bright-gray;
			}
			@include media($bp-md) {
				margin: 3px 10px;
			}
			@include media($bp-lg) {
				margin: 3px 15px;
			}
		}
		// .product-finish-gallery__selector .button
		.button {
			display: none;
			font-size: 10px;
			@include media($bp-md) {
				display: inline-block;
				vertical-align: middle;
				float: right;
			}
		}
	}
	// .product-finish-gallery__current-product
	@include element(current-product) {
		background-color: #FFF;
		display: block;
		position: relative;
		z-index: 1;
		padding: 80px 35px 90px;
		margin-top: -50px;
		text-align: center;
		* {
			transition: opacity 0.5s ease;
		}
		.current-product-name,.current-product-links {
			max-width: 250px;
			margin: 0 auto;
			padding:0;
		}
		.current-product-name {
			font: normal 16px/24px $proxima-regular;
			color: $c_text-base;
			padding-bottom: 30px;
		}
		.current-product-links {
			font-size: 0;
			line-height: 0;
			max-width: 400px;
			// .current-product-links a
			a {
				display: inline-block;
				vertical-align: middle;
				font: normal 14px/1em $din-bold;
				color: $c_moen-bright-blue;
				position: relative;
				margin: 0 25px;
				padding-bottom: 20px;
				&:first-child {
// 					margin-right: 25px;
						
				}
				&:last-child {
// 					margin-left: 25px;
				}
				span {
					position: relative;
					display: inline-block;
					vertical-align: middle;
					&:after {
						content: ' ';
						display: block;
						width: 100%;
						height: 3px;
						background-color: $c_moen-bright-blue;
						position: absolute;
						bottom: -8px;
						left:0;
						opacity: 0;
						transition: 0.5s ease;
					}
				}
				img {
					width: auto;
					height: 10px;
					display: inline-block;
					margin-left: 10px;
					vertical-align: middle;
				}
				&:hover span:after {
					opacity: 1;
				}
			}
			// .current-product-links + .button
			& + .button {
				margin-top: 30px;
				@include media($bp-md) {
					display: none;
				}
			}
		}
		
		@include media($bp-md) {
			padding: 130px 0 140px;
			margin-top: -60px;
			.current-product-name {
				max-width: 300px;
			}
			.current-product-name {
				font-size: 18px;
				line-height: 28px;
			}
		}
	}
	// .product-finish-gallery__sliders
	// Slider wrapper
	@include element(sliders) {
		position: relative;
		z-index: 2;
	}
	// .product-finish-gallery__product-slider
	// Slider of all products
	@include element(product-slider) {
		display: block;
		width: 100%;
		position: relative;
		z-index: 2;
		visibility: hidden;
		&.slick-initialized {
			visibility: visible;
			cursor: pointer;
			cursor: grab;
			&:active {
				cursor: pointer;
				cursor: grabbing;
			}
		}
	}
	// .product-finish-gallery__finish-slider
	// Slider of finishes
	@include element(finish-slider) {
		display: none;
		width: 100%;
		height: auto;
	}
	@include element(independent-slider) {
		display: none !important;
		width: 100%;
		height: auto;
		@include state("active") {
			display: block !important;
		}
	}
	// .product-finish-gallery__product
	// Individual product item (for both product and finish sliders)
	@include element(product) {
		display: block;
		padding:0 25px;
		outline: none;
		* {
			outline: none;
		}
		a {
			display: block;
			position: relative;
			height: 275px;
			cursor: pointer;
			cursor: grab;
			&:active {
				cursor: pointer;
				cursor: grabbing;
			}
			.flexwrap & {
				display: flex;
				align-items: flex-end;
			}
			img {
				display: inline-block;
				//height: 230px;
				height: 275px;
				transform: scale(0.8363636364);
				width: auto;
				transition: all 0.33333s ease;
				vertical-align: bottom;
				transform-origin: bottom;
				position: relative;
				z-index: 1;
				.no-cssfilters &, .ie11 & {
					opacity: 0.5;
				}
			}
			img + img {
				position: absolute;
				top:0;
				left:0;
				right:0;
				margin: auto;
				z-index: 2;
			}
			img:first-of-type {
				filter: brightness(0) invert(1);
			}
			img:last-of-type {
				opacity: 0.5;
			}
		}
		&.slick-current {
			a {
				img {
					transform: scale(1);
					.no-cssfilters &,.ie11 & {
						opacity: 1;
					}
				}
				img:last-of-type {
					opacity: 1;
				}

			}
		}
		@include media($bp-md) {
			padding: 0 60px;
			a {
// 				height: 550px;
				height: 450px;
				img {
// 					height: 550px;
					height: 450px;
				}
			}
		}
		@include media($bp-lg) {
			padding: 0 60px;
			a {
// 				height: 610px;
				height: 450px;
				img {
// 					height: 610px;
					height: 450px;
				}
			}
		}
	}
	
	&.slider-type-finish {
		.product-finish-gallery__product-slider {
			display: none;
		}
		.product-finish-gallery__finish-slider.current {
			display: block;
		}
	}
	
}