/**
@doc _docs/navigation-topics.md
@section Topics Navigation
@childof Navigation
@page Navigation Topics Navigation
@status in progress
@hippo navigation
@example
_markup/navigation-topics.hbs
*/
@include plmr-component('navigation-topics') {
  //.plmr-c-navigation-topics__container
  @include element('container') {
    color: plmr-color('brand', 'primary');
    padding: 30px 25px 50px;

    @include plmr-mq('md') {
      padding: 60px 55px 70px;
    }

    @include plmr-mq('lg') {
      padding-left: calc(91 / 1300 * 100%);
      padding-right: calc(91 / 1300 * 100%);
      padding-top: 70px;
    }

    @include plmr-mq('xl') {
      padding-left: 91px;
      padding-right: 91px;
    }
  }

  //.plmr-c-navigation-topics__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    font-size: 1.714rem;
    line-height: 2.285rem;
    margin: 0;
    margin-bottom: 20px;

    @include plmr-mq('md') {
      font-size: 2.285rem;
      line-height: 2.571rem;
      margin-bottom: 30px;
    }
  }

  //.plmr-c-navigation-topics__items
  @include element('items') {
    list-style: none;
  }

  //.plmr-c-navigation-topics__item
  @include element('item') {
    border-color: plmr-color('accent', 'light');
    border-style: solid;
    border-width: 1px 0 1px 0;
    margin-bottom: -1px;
  }

  //.plmr-c-navigation-topics__item-anchor
  @include element('item-anchor') {
    @include plmr-link($underline: false, $color: plmr-color('brand', 'primary'), $is-underline-hover: true);
    display: block;
    font-size: 1.285rem;
    line-height: 1.714rem;
    padding: 20px 15px;
    position: relative;

    @include plmr-mq('md') {
      font-size: 1.5rem;
      line-height: 2rem;
      padding: 30px 80px 30px 20px;
    }

    //.plmr-c-navigation-topics__item-anchor:hover
    &:hover {
      @include plmr-mq('lg') {
        .plmr-c-navigation-topics__item-arrow {
          background-color: plmr-color('brand', 'primary');

          &::before {
            border-color: plmr-color('base', 'white');
          }
        }
      }
    }
  }

  //.plmr-c-navigation-topics__item-arrow
  @include element('item-arrow') {
    background-color: transparent;
    border: 2px solid plmr-color('brand', 'primary');
    border-radius: 50%;
    display: block;
    height: 30px;
    margin-top: 15px;
    pointer-events: none;
    position: relative;
    transition: background-color 0.25s ease;
    width: 30px;

    @include plmr-mq('md') {
      margin-top: 0;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    //.plmr-c-navigation-topics__item-arrow::before
    &::before {
      @include plmr-pseudo;
      border-bottom: 3px solid plmr-color('brand', 'primary');
      border-left: 3px solid plmr-color('brand', 'primary');
      border-radius: 2px;
      height: 10px;
      left: 50%;
      margin-left: -2px;
      top: 50%;
      transform: rotate(-135deg) translate(-50%, -50%);
      transform-origin: 0 0;
      width: 10px;
    }
  }

  //.plmr-c-navigation-topics__item-icon
  @include element('item-icon') {
    //display: none;
    color: inherit;
    height: auto;
    left: 0;
    margin-top: 25px;
    max-height: 25px;
    max-width: 29px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: auto;
  }

  //.plmr-c-navigation-topics__button
  @include element('button') {
    padding-top: 30px;
    text-align: right;

    @include plmr-mq('md') {
      padding-top: 40px;
    }
  }
}


/**
@doc _docs/navigation-topics--with-icon.md
@section With Icon
@sectionof Topics Navigation
@example
_markup/navigation-topics--with-icon.hbs
*/
@include plmr-component('navigation-topics') {
  //.plmr-c-navigation-topics--with-icon
  @include modifier('with-icon') {
    .plmr-c-navigation-topics__item-anchor {
      font-size: 1.5rem;
      line-height: 2rem;
      padding-bottom: 25px;
      padding-left: 50px;
      padding-top: 25px;

      @include plmr-mq('md') {
        padding-right: 30px;
      }
    }

    .plmr-c-navigation-topics__item-arrow {
      display: none;
    }
  }
}


/**
@doc _docs/navigation-topics--no-sizing.md
@section No Sizing
@sectionof Topics Navigation
@example
_markup/navigation-topics--no-sizing.hbs
*/
@include plmr-component('navigation-topics') {
  //.plmr-c-navigation-topics--no-sizing
  @include modifier('no-sizing') {
    .plmr-c-navigation-topics__container {
      padding: 0;
    }
  }
}
