
@include block('social-share') {
  display: inline-flex;
  justify-content: flex-end;
  align-items: baseline;
  margin: 0;
  
  @include child('label') {
    display: none;
    
    @include media($bp-md) {
      display: block;
    }
  }
  
  @include child('icon') {
    display: inline-block;
    height: 24px;
    margin: 0 0.5em;
    width: 24px;
    
    @include media($bp-md) {
      height: 16px;
      margin: 0 7px;
      width: auto;
    }
  }
  
  a {
    text-decoration: none;
  }
  
  
}
