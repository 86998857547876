
/*
Collection of filter components
@section Filtering
@childof Galleries
@page Galleries Filtering
@status in progress
*/


@include plmr-component('filtering') {
  // .plmr-c-filtering__action-link
  @include element('action-link') {
    @include plmr-link($color: plmr-color('brand', 'tertiary'), $underline: true);
    font-size: 0.8571rem;
    line-height: inherit;
    margin-left: 15px;

    // .plmr-c-filtering__action-link:hover
    &:hover {
      cursor: pointer;
    }
  }

  // .plmr-c-filtering__tags
  @include element('tags') {
    display: none;
    font-size: 0;
    margin: -5px 0;
    padding: 0 30px;
    vertical-align: middle;

    @include plmr-mq('xl') {
      padding: 30px 30px 1px;
    }

    // .plmr-c-filtering__tags._visible
    @include plmr-state('visible') {
      display: inline-block;
    }
  }

  // .plmr-c-filtering__tag
  @include element('tag') {
    background: plmr-color('background', 'tertiary');
    color: darken(plmr-color('text', 'secondary'), 8%);
    cursor: pointer;
    font-size: 0.8571rem;
    line-height: 1;
    margin: 5px 15px 5px 0;
    min-height: 2.2em;
    padding: 7px 27px 7px 8px;
    position: relative;

    // .plmr-c-filtering__tag--clear
    @include modifier('clear') {
      @include plmr-link($underline: true);
      background: transparent;
      padding: 0;
    }
  }

  // .plmr-c-filtering__tag-icon
  @include element('tag-icon') {
    display: inline-block;
    height: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 27px;

    // .plmr-c-filtering__tag-icon::before,
    // .plmr-c-filtering__tag-icon::after
    &::before,
    &::after {
      @include plmr-pseudo;
      background-color: currentColor;
      height: 1px;
      left: 50%;
      line-height: 10px;
      top: 5px;
      width: 10px;
    }

    // .plmr-c-filtering__tag-icon::before
    &::before {
      transform: translateX(-50%) rotate(45deg);
    }

    // .plmr-c-filtering__tag-icon::after
    &::after {
      transform: translateX(-50%) rotate(-45deg);
    }
  }

  //.plmr-c-filtering__pagination
  @include element('pagination') {
    margin: 0 auto;
    width: (325 / 375 * 100%);

    @include plmr-mq(xl) {
      padding: 0 55px 0 27.5px;
      width: 100%;
    }
  }

  // .plmr-c-filtering__menu
  @include element('menu') {
    background: transparent;
    display: block;
    left: 0;
    max-width: plmr-config('container', 'max-width');
    min-height: 100vh;
    min-width: plmr-config('container', 'min-width');
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.25s linear;
    width: 100vw;
    z-index: plmr-z('modal');

    @include plmr-mq(xl) {
      background: transparent;
      min-height: 0;
      position: relative;
      transform: none;
      width: auto;
      z-index: 2;
    }

    // .plmr-c-filtering__menu._open
    @include plmr-state('open') {
      background: rgba(plmr-color('text', 'primary'), 0.3);
      transform: translateX(0);
      transition: transform 0.25s linear, background 0.333s ease-in-out 0.25s;
    }
  }

  //.plmr-c-filtering__menu-sidebar
  @include element('menu-sidebar') {
    height: auto;
    max-height: 100vh;
    min-height: 100vh;
    overflow: scroll;
    padding-bottom: 40px; // account for mobile device nav bar
    padding-right: calc(95 / 375 * 100%);
    padding-top: 50px;
    position: relative;
    //width: (280 / 375 * 100%);

    @include plmr-mq(md) {
      background: transparent;
      padding: 0 0 20px 40px;
      padding-right: calc(477 / 768 * 100%);
      width: 100%;
      //padding: 0 40px 20px;
      //width: (331 / 768 * 100%);
    }

    @include plmr-mq(lg) {
      padding-right: calc(701 / 992 * 100%);
    }

    @include plmr-mq(xl) {
      background: plmr-color('base', 'white');
      max-height: none;
      max-width: 331px;
      min-height: 0;
      overflow: visible;
      padding: 0 50px 0 25px;
      //min-width: 331px;
      //width: calc(331/1300 * 100%);
    }

    //.plmr-c-filtering__menu-sidebar::before
    &::before {
      @include plmr-pseudo;
      background: plmr-color('base', 'white');
      height: auto;
      left: 0;
      min-height: 100%;
      pointer-events: none;
      position: fixed;
      top: 0;
      width: (280 / 375 * 100%);
      z-index: -1;

      @include plmr-mq(md) {
        width: (331 / 768 * 100%);
      }

      @include plmr-mq(lg) {
        width: (331 / 992 * 100%);
      }

      @include plmr-mq(xl) {
        display: none;
      }
    }
  }

  //.plmr-c-filtering__menu-section
  @include element('menu-section') {
    border-top: 1px solid plmr-color('accent', 'light');

    //.plmr-c-filtering__menu-section--first
    @include modifier('first') {
      .plmr-c-filtering__menu-heading {
        font-size: 1.286rem;
      }

      .plmr-c-filtering__menu-heading--toggle {
        font-size: 1rem;

        &::before,
        &::after {
          width: 14px;
        }
      }

      @include plmr-mq(md) {
        border-top: 0;
      }
    }

    //.plmr-c-filtering__menu-section._has-banner
    @include plmr-state('has-banner') {
      border-top: 0;
      padding: 40px 15px;

      @include plmr-mq(md) {
        padding-left: 0;
        padding-right: 0;
      }

      @include plmr-mq(xl) {
        padding-bottom: 0;
      }
    }
  }

  //.plmr-c-filtering__menu-heading
  @include element('menu-heading') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'secondary');
    font-size: 1.143rem;
    line-height: 1;
    padding: 25px 35px 25px 25px;
    position: relative;

    @include plmr-mq(md) {
      padding: 30px 0;
    }

    //.plmr-c-filtering__menu-heading--toggle
    @include modifier('toggle') {
      @include plmr-font-face($family: 'bold');
      color: plmr-color('text', 'secondary');
      display: block;
      font-size: 0.8571rem;
      line-height: 1rem;
      outline-offset: -1px;
      padding: 20px 35px;
      position: relative;
      text-align: left;
      text-transform: uppercase;
      width: 100%;

      @include plmr-mq(md) {
        padding: 25px 0 22px;
      }

      //.plmr-c-filtering__menu-heading--toggle::before,
      //.plmr-c-filtering__menu-heading--toggle::after
      &::before,
      &::after {
        @include plmr-pseudo;
        background: currentColor;
        height: 1px;
        right: 0;
        top: 50%;
        transform: translate(-35px, -50%);
        transition: transform 0.25s ease-in-out;
        width: 10px;

        @include plmr-mq(md) {
          transform: translate(0, -50%);
        }
      }

      //.plmr-c-filtering__menu-heading--toggle::before
      &::before {
        transform: translate(-35px, -50%) rotate(90deg);

        @include plmr-mq(md) {
          transform: translate(0, -50%) rotate(90deg);
        }
      }

      //.plmr-c-filtering__menu-heading--toggle._open
      @include plmr-state('open') {
        //padding-bottom: 0;

        &::before {
          transform: translate(-35px, -50%) rotate(0deg);

          @include plmr-mq(md) {
            transform: translate(0, -50%) rotate(0deg);
          }
        }
      }
    }

    //.plmr-c-filtering__menu-heading--secondary
    @include modifier('secondary') {
      @include plmr-font-face;
      color: plmr-color('brand', 'primary');
    }
  }

  @include element('menu-section-clear') {
    @include plmr-font-face($family: 'base');
    @include plmr-link($underline: true);
    font-size: 0.8571rem;
    line-height: 1;
    min-height: 0;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);

    @include plmr-mq(md) {
      display: none;
    }
  }

  //.plmr-c-filtering__menu-subsection
  @include element('menu-subsection') {
    border-top: 1px solid plmr-color('accent', 'light');
  }

  //.plmrc-filtering__menu-subsection-container
  @include element('menu-subsection-container') {
    max-height: 1000px;
    opacity: 1;
    pointer-events: all;
    transition: 0.25s ease;
    //overflow: hidden;

    //.plmr-c-filtering__menu-subsection-container._collapsed
    @include plmr-state('collapsed') {
      max-height: 0;
      opacity: 0;
      pointer-events: none;
      transition: 0.25s ease;
    }
  }

  //.plmr-c-filtering__menu-buttons
  @include element('menu-buttons') {
    display: flex;
    height: auto;
    left: 0;
    min-width: (280 / 375 * 100%);
    position: fixed;
    top: 0;
    width: auto;

    @include plmr-mq(md) {
      display: none;
    }
  }

  //.plmr-c-filtering__menu-button
  @include element('menu-button') {
    @include plmr-font-face($family: 'bold');
    background: plmr-color('accent', 'light');
    color: rgba(plmr-color('text', 'primary'), 0.55);
    flex: 1 0 50%;
    font-size: 0.7857rem;
    letter-spacing: 2px;
    line-height: 8px;
    outline-offset: -1px;
    padding: 21px 0;
    text-transform: uppercase;

    //.plmr-c-filtering__menu-button--primary
    @include modifier('primary') {
      background: plmr-color('brand', 'primary');
      color: plmr-color('base', 'white');
    }
  }

  // .plmr-c-filtering__menu-list
  @include element('menu-list') {
    border-top: 1px solid plmr-color('accent', 'light');
    list-style: none;
    margin: 0 auto -5px 25px;
    padding: 15px 10px 0;
    width: (220 / 280 * 100%);

    @include plmr-mq(md) {
      margin-left: 0;
      padding-left: 5px;
      padding-right: 5px;
      width: 100%;
    }

    // .plmr-c-filtering__menu-list--links
    @include modifier('links') {
      @include plmr-font-face($family: 'bold');
      border: 0;
      margin-left: 0;
      padding-bottom: 30px;
      padding-left: 25px;
      position: relative;
      width: 100%;

      &::before {
        @include plmr-pseudo;
        border-top: 1px solid plmr-color('accent', 'light');
        font-size: 0;
        height: 0;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        // width: (201 / 251 * 100%);
      }
    }
  }

  // .plmr-c-filtering__menu-list-item
  @include element('menu-list-item') {
    padding: 5px 0;

    // .plmr-c-filtering__menu-list-item--title
    @include modifier('title') {
      color: plmr-color('brand', 'secondary');
      font-size: 1.143rem;
    }
  }

  //.plmr-c-filtering__menu-sorting
  @include element('menu-sorting') {
    padding-bottom: 5px;
    padding-top: 30px;

    .plmr-c-filtering__menu-heading {
      padding-bottom: 7px;
      padding-top: 0;
    }

    @include plmr-mq(md) {
      display: none;
    }
  }

  //.plmr-c-filtering__menu-banner
  @include element('menu-banner') {
    background: plmr-color('background', 'secondary');
    padding: 30px 25px 40px;
  }

  //.plmr-c-filtering__banner-eyebrow
  @include element('banner-eyebrow') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('brand', 'secondary');
    font-size: 1.143rem;
    line-height: 1.714rem;
    margin: 0;
  }

  //.plmr-c-filtering__banner-title
  @include element('banner-title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.286rem;
    margin: 0;
    padding-top: 5px;
  }

  //.plmr-c-filtering__banner-callout
  @include element('banner-callout') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    padding-top: 25px;
  }

  //.plmr-c-filtering__banner-callout-image
  @include element('banner-callout-image') {
    height: auto;
    overflow: hidden;
    padding-top: 50%;
    position: relative;
    width: 100%;

    //.plmr-c-filtering__banner-callout-image--src
    @include modifier ('src') {
      height: auto;
      left: 50%;
      max-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;
    }
  }


  //.plmr-c-filtering__banner-callout-title
  @include element('banner-callout-title') {
    padding: 0;
    padding-top: 14px;
  }

  //.plmr-c-filtering__banner-callout-link
  @include element('banner-callout-link') {
    padding: 0;
    padding-top: 14px;
  }

  //.plmr-c-filtering__open-button
  @include element('open-button') {
    @extend .plmr-c-button, .plmr-c-button--secondary;
    vertical-align: middle;

    @include plmr-mq(xl) {
      display: none;
    }
  }

  //.plmr-c-filtering__close-button
  @include element('close-button') {
    background: plmr-color('brand', 'secondary');
    color: plmr-color('base', 'white');
    display: block;
    height: 50px;
    left: (280 / 375 * 100%);
    padding: 13px;
    position: absolute;
    top: 0;
    width: 50px;
    z-index: 2;

    @include plmr-mq(md) {
      height: auto;
      left: (351 / 768 * 100%);
      max-width: 120px;
      top: 29px;
      width: auto;
    }

    @include plmr-mq(lg) {
      left: (351 / 992 * 100%);
    }

    @include plmr-mq(xl) {
      display: none;
    }

    //.plmr-c-filtering__close-button--modal
    @include modifier('modal') {
      @include plmr-mq(md) {
        display: none;
      }
    }

    //.plmr-c-filtering__close-button--tablet
    @include modifier('tablet') {
      @extend .plmr-c-button, .plmr-c-button--secondary;
      display: none;

      @include plmr-mq(md) {
        display: inline-block;
      }
    }
  }


  /// Elements that may be nested inside of a menu-subsection

  /// Description List
  // .plmr-c-filtering__description-list
  @include element('description-list') {
    color: darken(plmr-color('text', 'secondary'), 8%);
    font-size: 1.143rem;
    line-height: 1.286rem;
    //padding: 12px 35px 20px;
    padding: 0 35px 20px;

    @include plmr-mq(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // .plmr-c-filtering__description-list-item
  @include element('description-list-item') {

  }

  // .plmr-c-filtering__description-list-button
  @include element('description-list-button') {
    // .plmr-c-filtering__description-list-button:hover
    &:hover {
      cursor: pointer;
    }
  }

  // .plmr-c-filtering__description-list-term
  @include element('description-list-term') {
    @include plmr-font-face($family: 'bold');
    padding-bottom: 5px;

    // .plmr-c-filtering__description-list-term--descriptor
    @include modifier('descriptor') {
      padding: 10px 0 0 5px;

      // .plmr-c-filtering__description-list-term--descriptor._active
      @include plmr-state('active') {
        @include plmr-font-face($family: 'bold');
      }
    }
  }

  /// END Description List Elements


  /// Itemized List
  //.plmr-c-filtering__itemized-list
  @include element('itemized-list') {
    color: darken(plmr-color('text', 'secondary'), 8%);
    font-size: 1.143rem;
    line-height: 1.286rem;
    list-style: none;
    padding: 0 35px 15px;
    //padding: 12px 35px 15px;


    @include plmr-mq(md) {
      padding-left: 0;
      padding-right: 0;
    }

    //.plmr-c-filtering__itemized-list--no-heading
    @include modifier('no-heading') {
      padding: 0 35px 15px 25px;
    }
  }

  //.plmr-c-filtering__itemized-list-item
  @include element('itemized-list-item') {
    align-items: flex-start;
    display: flex;
    padding: 5px 0;
    position: relative;

    //.plmr-c-filtering__itemized-list-item:hover
    &:hover {
      cursor: pointer;

      .plmr-c-filtering__itemized-list-button--checkbox {
        &::before {
          background: plmr-color('accent', 'light');
        }
      }

      .plmr-c-filtering__itemized-list-button--finish {
        border-color: plmr-color('accent', 'dark');
      }

      .plmr-c-filtering__itemized-list-button--view-icon {

      }
    }

    //.plmr-c-filtering__itemized-list-item._active
    @include plmr-state('active') {
      .plmr-c-filtering__itemized-list-label {
        @include plmr-font-face($family: 'bold');
      }

      .plmr-c-filtering__itemized-list-button--checkbox {
        border-color: plmr-color('brand', 'primary');

        &::before {
          background: plmr-color('brand', 'primary');
        }
      }

      .plmr-c-filtering__itemized-list-button--checklist {
        @include plmr-font-face($family: 'bold');
        border-bottom: 2px solid currentColor;
        color: plmr-color('brand', 'tertiary');

        &::before {
          display: block;
        }
      }

      .plmr-c-filtering__itemized-list-button--finish {
        border-color: plmr-color('brand', 'tertiary');
      }

      .plmr-c-filtering__itemized-list-button--view-icon {
        border: 0;
        color: plmr-color('accent', 'dark');
      }
    }
  }

  //.plmr-c-filtering__itemized-list-button
  @include element('itemized-list-button') {
    min-height: 0;
    outline-offset: -1px;
    text-align: left;

    //.plmr-c-filtering__itemized-list-button:hover
    &:hover {
      cursor: pointer;
    }

    //.plmr-c-filtering__itemized-list-button--checkbox
    @include modifier('checkbox') {
      border: 1px solid plmr-color('accent', 'light');
      border-radius: 2px;
      height: 18px;
      min-height: 1px;
      position: relative;
      width: 18px;

      //.plmr-c-filtering__itemized-list-button--checkbox::before
      &::before {
        @include plmr-pseudo;
        background: transparent;
        height: auto;
        left: 50%;
        min-height: (11 / 18 * 100%);
        min-width: (11 / 18 * 100%);
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.25s ease-in-out;
        width: auto;
      }
    }

    //.plmr-c-filtering__itemized-list-button--finish
    @include modifier('finish') {
      border: 2px solid transparent;
      border-radius: 50%;
      flex: 1 0 31px;
      height: 31px;
      padding: 1px;
      text-align: left;
      transition: border-color 0.25s ease-in-out;
      width: 31px;
    }

    //.plmr-c-filtering__itemized-list-button--link
    @include modifier('link') {
      @include plmr-font-face($family: 'bold');
      @include plmr-link($underline: true);
      border: 0;
      flex: none;
      font-size: 1rem;
      height: auto;
      line-height: 1.286rem;
      padding: 5px 0;
      width: auto;
    }

    //.plmr-c-filtering__itemized-list-button--checklist
    @include modifier('checklist') {
      margin-bottom: 5px;
      padding: 5px 0 0;

      //.plmr-c-filtering__itemized-list-button--checklist::before
      &::before {
        @include plmr-pseudo;
        background-color: transparent;
        border-bottom: 2px solid plmr-color('accent', 'dark');
        border-right: 2px solid plmr-color('accent', 'dark');
        display: none;
        height: 14px;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 7px;
      }
    }

    //.plmr-c-filtering__itemized-list-button--view-icon
    @include modifier('view-icon') {
      color: plmr-color('accent', 'light');
      margin-right: 17px;
      min-width: 21px;
      padding: 0;
    }
  }

  //.plmr-c-filtering__itemized-list-label
  @include element('itemized-list-label') {
    //.plmr-c-filtering__itemized-list-label--checkbox
    @include modifier('checkbox') {
      padding-left: 17px;
    }

    //.plmr-c-filtering__itemized-list-label--finish
    @include modifier('finish') {
      flex: 1 0 calc(100% - 29px);
      min-height: 31px;
      padding: 7px 4px 0 8px;
    }
  }

  //.plmr-c-filtering__itemized-list-tooltip
  @include element('itemized-list-tooltip') {
    color: plmr-color('text', 'secondary');
    display: none;
    line-height: 1rem;
    padding-left: 11px;
    position: relative;

    @include plmr-mq(md) {
      display: block;
    }

    //.plmr-c-filtering__itemized-list-tooltip--on-menu-heading
    @include modifier('on-menu-heading') {
      @include plmr-mq(md) {
        display: inline-block;
        vertical-align: text-top;
      }
    }
  }

  // .plmr-c-filtering__itemized-list-tooltip-icon
  @include element('itemized-list-tooltip-icon') {
    // .plmr-c-filtering__itemized-list-tooltip-icon:hover
    &:hover {
      & + .plmr-c-filtering__itemized-list-tooltip-flag {
        opacity: 1;
      }
    }
  }

  // .plmr-c-filtering__itemized-list-tooltip-flag
  @include element('itemized-list-tooltip-flag') {
    @include plmr-font-face($family: 'light');
    background: plmr-color('background', 'secondary');
    bottom: 100%;
    box-shadow: -5px 5px 10px 0 rgba(plmr-color('base', 'black'), 0.1);
    color: plmr-color('text', 'primary');
    font-size: 0.8571rem;
    left: 50%;
    line-height: 1rem;
    margin-bottom: 17px;
    margin-left: 5px;
    min-width: 275px;
    opacity: 0;
    padding: 10px 15px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    text-transform: none;
    transform: translateX(-50%);
    transition: 0.333s ease;
    z-index: 2;

    // .plmr-c-filtering__itemized-list-tooltip-flag::before
    &::before {
      @include plmr-pseudo;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 10px solid plmr-color('background', 'secondary');
      height: 0;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      width: 0;
    }
  }

  /// END Itemized List Elements

  /// Finishes List Elements
  //.plmr-c-filtering__finish-list-image
  @include element('finish-list-image') {
    @extend .plmr-u-img--responsive;
    display: block;
  }
}

