
/**
@doc _docs/carousel-category-collection-detail.md
@section Carousel Category Collection Detail
@childof Carousels
@page Carousels Category Collection Detail
@status ready
@hippo banner carousel
@example
_markup/carousel-category-collection-detail.hbs
*/
@include plmr-component('carousel-category-collection-detail') {
  padding: 30px 0 60px;

  @include plmr-mq(md) {
    padding: 40px 0 92px;
  }

  //.plmr-c-carousel-category-collection-detail__inner
  @include element('inner') {
    @extend .plmr-l-container;
    padding: 0;
    position: relative;

    @include plmr-mq(md) {
      padding-top: 40px;
    }

    @include plmr-mq(lg) {
      padding-top: 50px;
    }

    //.plmr-c-carousel-category-collection-detail__inner::before
    &::before {
      @include plmr-pseudo;
      background: plmr-color('base', 'white');
      bottom: 0;
      height: (506 / 536 * 100%);
      right: 0;
      width: (340 / 375 * 100%);
      z-index: 1;

      @include plmr-mq(md) {
        bottom: auto;
        height: (340 / 390 * 100%);
        right: 25px;
        top: 0;
        width: (718 / 768 * 100%);
      }

      @include plmr-mq(lg) {
        //right: 40px;
        right: 3.0769%;
        width: (1169 / 1300 * 100%);
      }
    }
  }

  //.plmr-c-carousel-category-collection-detail__slider
  @include element('slider') {
    position: relative;
    z-index: 2;
  }

  //.plmr-c-carousel-category-collection-detail__slide
  @include element('slide') {
    padding-right: 25px;

    &:not(:first-child) {
      display: none;
    }

    .slick-initialized & {
      display: block;
    }

    @include plmr-mq(md) {
      padding-right: 55px;
    }

    @include plmr-mq(lg) {
      padding-right: 142px;
    }
  }

  //.plmr-c-carousel-category-collection-detail__slide-inner
  @include element('slide-inner') {
    @include plmr-mq(md) {
      display: flex;
      flex-flow: row nowrap;
    }
  }

  //.plmr-c-carousel-category-collection-detail__description
  @include element('description') {
    background: plmr-color('brand', 'primary');
    color: plmr-color('base', 'white');
    padding: 40px 25px;
    text-align: center;

    @include plmr-mq(md) {
      flex: 0 0 32.6788%; //calc(233/713 * 100%);
      padding: 30px 25px 40px;
      text-align: left;
    }

    @include plmr-mq(lg) {
      flex: 0 0 29.5336%; //calc(342/1158 * 100%);
    }

    @include plmr-mq(xl) {
      padding: 50px 40px 48px;
    }
  }

  //.plmr-c-carousel-category-collection-detail__title
  @include element('title') {
    font-size: 1.286rem;
    line-height: 1.714rem;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    //.plmr-c-carousel-category-collection-detail__title--category
    @include modifier('category') {
      @include plmr-font-face($family: 'bold');
    }
  }

  //.plmr-c-carousel-category-collection-detail__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
    color: plmr-color('base', 'white');
    display: none;

    @include plmr-mq(md) {
      display: block;
      max-height: 154px;
      overflow: hidden;
      padding-top: 15px;
      position: relative;
    }

    //.plmr-c-carousel-category-collection-detail__copy._overflow
    @include plmr-state('overflow') {
      //.plmr-c-carousel-category-collection-detail__copy._overflow::before
      &::before {
        display: block;
      }
    }

    //.plmr-c-carousel-category-collection-detail__copy::before
    &::before {
      background-color: plmr-color('brand', 'primary');
      bottom: 0;
      content: '...';
      display: none;
      padding-bottom: 2px;
      position: absolute;
      right: 0;
      width: 23px;
    }
  }

  //.plmr-c-carousel-category-collection-detail__button
  @include element('button') {
    padding-top: 25px;
  }

  //.plmr-c-carousel-category-collection-detail__images
  @include element('images') {
    @include plmr-mq(md) {
      flex: 0 0 67.3212%; //(100% - (233/713 * 100%));
      font-size: 0;
    }

    @include plmr-mq(lg) {
      flex: 0 0 70.4664%; //(100% - (342/1158 * 100%);
    }
  }

  //.plmr-c-carousel-category-collection-detail__image
  @include element('image') {
    background: plmr-color('background', 'secondary');
    display: block;
    height: auto;
    overflow: hidden;
    padding-top: calc(200 / 350 * 100%);
    position: relative;
    width: 100%;

    @include plmr-mq(md) {
      display: inline-block;
      padding-top: calc(300 / 411 * 100%);
      width: (300 / 480 * 100%);
    }

    @include plmr-mq(lg) {
      padding-top: calc(350 / 598 * 100%);
      width: (510 / 816 * 100%);
    }

    @include plmr-mq(xl) {
      min-height: 100%;
      padding-top: calc(350 / 816 * 100%);
    }

    //.plmr-c-carousel-category-collection-detail__image--src
    @include modifier('src') {
      display: block;
      height: auto;
      left: 50%;
      max-height: 100%;
      min-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.5s ease;
      width: auto;

      //.plmr-c-carousel-category-collection-detail__image--src:hover
      &:hover {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }

  //.plmr-c-carousel-category-collection-detail__knockout
  @include element('knockout') {
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: 0;
    overflow: hidden;
    padding-top: calc(175 / 350 * 100%);
    position: relative;
    width: (290 / 350 * 100%);

    @include plmr-mq(md) {
      display: inline-block;
      padding-top: calc(200 / 274 * 100%);
      width: calc(100% - (300/480 * 100%));
    }

    @include plmr-mq(lg) {
      float: right;
      margin-left: auto;
      margin-right: 0;
      max-width: 200px;
      padding-top: calc(350 / 598 * 100%);
      width: calc(100% - (510 / 816 * 100%));
    }

    @include plmr-mq(xl) {
      min-height: 100%;
      padding-top: calc(350 / 816 * 100%);
    }

    //.plmr-c-carousel-category-collection-detail__knockout::before
    &::before {
      @include plmr-font-face($family: 'bold');
      @include plmr-pseudo;
      background-color: plmr-color('brand', 'tertiary');
      border-radius: 50%;
      color: plmr-color('base', 'white');
      content: attr(data-pseudo-label);
      display: none;
      font-size: 11px;
      height: 35px;
      left: 0;
      line-height: 8px;
      margin-left: calc(57.5 / 290 * 100%);
      padding-top: 13px;
      pointer-events: none;
      text-align: center;
      top: 15px;
      width: 35px;
      z-index: 1;

      @include plmr-mq(md) {
        margin-left: calc(16.5 /180 * 100%); //20.5
        margin-top: calc(-250 / 350 * 100%);
        // margin-top: calc(-180 / 350 * 100%);
        top: 50%;
        transform: translateY(-50%);
      }

      @include plmr-mq(lg) {
        margin-left: 0;
        margin-top: calc(-260 / 340 * 100%);
      }
    }

    //.plmr-c-carousel-category-collection-detail__knockout--src
    @include modifier('src') {
      display: block;
      height: auto;
      left: 50%;
      max-height: 100%;
      max-width: 100%;
      padding: 20px 0 30px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;

      @include plmr-mq(md) {
        margin-top: -50px;
        padding: 0;
        padding-left: 32px;
      }

      @include plmr-mq(lg) {
        padding-left: 0;
      }
    }

    //.plmr-c-carousel-category-collection-detail__knockout._new
    @include plmr-state('new') {
      &::before {
        display: block;
      }
    }
  }

  //.plmr-c-carousel-category-collection-detail__nav
  @include element('nav') {
    bottom: -60px;
    height: 35px;
    left: 0;
    position: absolute;
    width: 100%;

    @include plmr-mq(md) {
      bottom: -92px;
      height: 51px;
    }
  }
}
