/**
@doc _docs/spacer.md
@section Spacer
@childof Spacers
@page Spacers Spacer
@status ready
*/

/**
@section Primary Background
@sectionof Spacer
@page Spacers Spacer
@example
_markup/spacer--background-primary.hbs
*/
@include plmr-component('spacer') {
  background-color: plmr-color('base', 'white');
  display: block;
  height: 30px;
  min-width: 320px;
  position: relative;
  width: 100%;
  z-index: 1;


  @include plmr-mq(md) {
    height: 40px;
  }


  @include plmr-mq(xl) {
    height: 50px;
  }


  &::before {
    background: plmr-color('brand', 'tertiary');
    content: '';
    height: 50px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -25px);
    width: 1px;


    @include plmr-mq(md) {
      height: 75px;
      transform: translate(-50%, -37px);
    }
  }


  @include modifier('no-accent') {
    &::before {
      display: none;
    }
  }
}


/**
@section Secondary Background
@sectionof Spacer
@page Spacers Spacer
@example
_markup/spacer--background-secondary.hbs
*/


/**
@section No Accent
@sectionof Spacer
@page Spacers Spacer
@example
_markup/spacer--no-accent-secondary.hbs
*/
