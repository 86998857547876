/**
@doc _docs/carousel-coordinating-items.md
@section Carousel Coordinating Items
@childof Carousels
@page Carousels Carousel Coordinating Items
@status in progress
@example
_markup/carousel-coordinating-items.hbs
*/
@include plmr-component('carousel-coordinating-items') {
  position: relative;

  //.plmr-c-carousel-coordinating-items::after
  &::before {
    @include plmr-pseudo;
    background: plmr-color('brand', 'tertiary');
    height: 50px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -25px);
    width: 1px;
    z-index: 2;

    @include plmr-mq(md) {
      height: 75px;
      transform: translate(-50%, -37px);
    }
  }

  //.plmr-c-carousel-coordinating-items::after
  &::after {
    @include plmr-pseudo;
    background-color: plmr-color('base', 'white');
    left: 0;
    min-height: 399px;
    min-width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  //.plmr-c-carousel-coordinating-items__inner
  @include element('inner') {
    @extend .plmr-l-container;
    padding: 0;
    position: relative;
    z-index: 2;
  }

  //.plmr-c-carousel-coordinating-items__intro
  @include element('intro') {
    margin: 0 auto;
    max-width: 768px;
    padding: 50px 35px 0;
    text-align: center;

    @include plmr-mq(md) {
      padding: 75px 80px 0;
    }
  }

  //.plmr-c-carousel-coordinating-items__intro-title
  @include element('intro-title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 2rem;
    line-height: 2.285rem;
    margin: 0;
    padding-bottom: 15px;

    @include plmr-mq(md) {
      font-size: 2.285rem;
      line-height: 2.571rem;
      padding-bottom: 20px;
    }
  }

  //.plmr-c-carousel-coordinating-items__intro-text
  @include element('intro-text') {
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding-bottom: 20px;

    @include plmr-mq(md) {
      padding-bottom: 30px;
    }
  }

  //.plmr-c-carousel-coordinating-items__carousel
  @include element('carousel') {
    padding-bottom: 50px;
    padding-left: 25px;

    @include plmr-mq(md) {
      padding-bottom: 60px;
      padding-left: 60px;
    }

    @include plmr-mq(xl) {
      margin: 0 auto;
      max-width: 1200px;
      padding-left: 100px;
      padding-right: 125px;
    }

    &:not(.slick-initialized) {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
    }
  }

  //.plmr-c-carousel-coordinating-items__dots
  @include element('dots') {
    padding-right: 25px;

    @include plmr-mq(md) {
      padding-right: 60px;
    }

    @include plmr-mq(xl) {
      padding-left: 25px;
      padding-right: 0;
    }
  }

  //.plmr-c-carousel-coordinating-items__item
  @include element('item') {
    padding: 30px 0 35px;
    width: 300px;

    &:only-child {
      .plmr-c-carousel-coordinating-items__item-card {
        margin-right: auto;
      }
    }

    @include plmr-mq(md) {
      padding-top: 50px;
      width: 325px;
    }
  }

  //.plmr-c-carousel-coordinating-items__item-card
  @include element('item-card') {
    background-color: plmr-color('base', 'white');
    box-shadow: -10px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
    display: block;
    margin-left: auto;
    margin-right: 0;
    min-height: 352px;
    padding: 35px 33px 40px;
    text-decoration: none;
    width: 275px;

    //.plmr-c-carousel-coordinating-items__item-card:hover
    &:hover {
      cursor: pointer;
    }

    @include plmr-mq(md) {
      padding-left: 45px;
      padding-right: 45px;
      width: 300px;
    }
  }

  //.plmr-c-carousel-coordinating-items__item-image-area
  @include element('item-image-area') {
    height: 210px;
    overflow: hidden;
    position: relative;
    width: 210px;
  }

  //.plmr-c-carousel-coordinating-items__item-image
  @include element('item-image') {
    left: 50%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  //.plmr-c-carousel-coordinating-items__item-name
  @include element('item-name') {
    color: plmr-color('brand', 'primary');
    font-size: 1.143rem;
    line-height: 1.5rem;
    padding-bottom: 0;
    padding-top: 25px;
    text-align: center;
    text-transform: capitalize;
  }

  //.plmr-c-carousel-coordinating-items__item-name-collection
  @include element('item-name-collection') {
    @include plmr-font-face($family: 'bold');
  }

  //.plmr-c-carousel-coordinating-items__intro-button

}

/**
@doc _docs/carousel-coordinating-items--no-accent.md
@section No Accent
@sectionof Carousel Coordinating Items
@example
_markup/carousel-coordinating-items--no-accent.hbs
*/
@include plmr-component('carousel-coordinating-items') {
  //.plmr-c-carousel-coordinating-items--no-accent
  @include modifier('no-accent') {
    //.plmr-c-carousel-coordinating-items--no-accent::before
    &::before {
      display: none;
    }

    .plmr-c-carousel-coordinating-items__intro-title {
      padding-bottom: 20px;

      @include plmr-mq(md) {
        padding-bottom: 30px;
      }
    }

    .plmr-c-carousel-coordinating-items__item {
      padding-top: 50px;
      width: 325px;

      @include plmr-mq(xl) {
        padding-bottom: 0;
      }

      &.slick-active.slick-current {
        .plmr-c-carousel-coordinating-items__item-image-area {
          opacity: 1;
        }

        .plmr-c-carousel-coordinating-items__item-name {
          opacity: 1;
        }
      }
    }

    .plmr-c-carousel-coordinating-items__item-image-area {
      height: 275px;
      margin-left: auto;
      margin-right: auto;
      opacity: 0.5;
      transition: opacity 0.5s ease;
      width: 275px;

      @include plmr-mq(md) {
        height: 300px;
        width: 300px;
      }
    }

    .plmr-c-carousel-coordinating-items__item-card {
      box-shadow: none;
      margin: 0 auto;
      padding: 0;
    }

    .plmr-c-carousel-coordinating-items__item-name {
      margin-left: auto;
      margin-right: auto;
      max-width: 210px;
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    .plmr-c-carousel-coordinating-items__carousel {
      padding-left: 0;

      @include plmr-mq(md) {
        padding-bottom: 75px;
      }

      @include plmr-mq(xl) {
        padding-left: 112.5px;
        padding-right: 112.5px;
      }
    }

    .plmr-c-carousel-coordinating-items__dots {
      padding-right: 0;
    }

    .plmr-c-carousel__arrow {
      @include plmr-mq(xl) {
        margin-top: -60px;
      }
    }
  }
}
