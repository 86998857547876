/**
Collection of tabbed content.
@section Tabbed Content
@childof Components
@page Tabbed Content
@status in progress
@example
_markup/tabbed-content.hbs
*/

@include plmr-component('tabbed-content') {
  background-color: plmr-color('background', 'secondary');
  padding-bottom: 60px;
  position: relative;
  z-index: 2;

  @include plmr-mq(md) {
    padding-bottom: 75px;
  }

  //.plmr-c-tabbed-content::before
  &::before {
    @include plmr-pseudo;
    background: plmr-color('brand', 'tertiary');
    bottom: 0;
    height: 50px;
    left: 50%;
    transform: translate(-50%, 25px);
    width: 1px;

    @include plmr-mq(md) {
      height: 75px;
      transform: translate(-50%, 37px);
    }
  }

  //.plmr-c-tabbed-content__inner
  @include element('inner') {
    padding: 0;

    @include plmr-mq(lg) {
      padding-left: calc(91 / 1300 * 100%);
      padding-right: calc(91 / 1300 * 100%);
      padding-top: 1px;
    }

    @include plmr-mq(xl) {
      padding-left: 91px;
      padding-right: 91px;
    }
  }

  //.plmr-c-tabbed-content__tabs-container
  @include element('tabs-container') {
    box-shadow: 10px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);

    @include plmr-mq(lg) {
      margin-top: -33px;
    }
  }

  //.plmr-c-tabbed-content__tabs
  @include element('tabs') {
    display: flex;
    flex-flow: row wrap;
  }

  //.plmr-c-tabbed-content__tab
  @include element('tab') {
    background-color: plmr-color('background', 'tertiary');
    border-top: 4px solid transparent;
    color: plmr-color('text', 'secondary');
    cursor: pointer;
    flex: 1 0 50%;
    font-size: 1.1428rem;
    line-height: 1.7142rem;
    outline-offset: -1px;
    padding: 24px 0;
    padding-left: calc(25 / 375 * 100%);
    padding-right: calc(25 / 375 * 100%);
    transition: background-color 0.333s ease;
    width: 50%;

    @include plmr-mq(md) {
      font-size: 1.2857rem;
      line-height: 2rem;
      padding: 32px 50px;
    }

    @include plmr-mq(xl) {
      padding-left: calc(66 / 1188 * 100%);
      padding-right: calc(66 / 1188 * 100%);
    }

    //.plmr-c-tabbed-content__tab:hover
    &:hover {
      color: darken(plmr-color('text', 'secondary'), 8%);
    }

    //.plmr-c-tabbed-content__tab._active
    @include plmr-state('active') {
      background-color: plmr-color('base', 'white');
      border-color: plmr-color('brand', 'tertiary');
      color: plmr-color('brand', 'secondary');
      font-size: 1.2857rem;
      margin-top: -8px;

      @include plmr-mq(md) {
        font-size: 1.5rem;
      }
    }

    strong,
    b {
      @include plmr-font-face($family: 'bold');
    }
  }

  //.plmr-c-tabbed-content__panels
  @include element('panels') {
    background-color: plmr-color('base', 'white');
    min-height: 0;
    transition: min-height 0.333s ease;
  }

  //.plmr-c-tabbed-content__panel
  @include element('panel') {
    display: none;
    //padding: 50px 25px 40px;
    padding: 0 25px;

    @include plmr-mq(md) {
      //padding: 50px 55px;
      padding: 0 55px;
    }

    //.plmr-c-tabbed-content__panel._active
    @include plmr-state('active') {
      display: block;
    }
  }

  //.plmr-c-tabbed-content__copy
  @include element('copy') {
    font-size: 16px;
    line-height: 24px;
  }

  //.plmr-c-tabbed-content__modal-content
  @include element('modal-content') {
    padding: 0 10px;

    @include plmr-mq(md) {
      margin-left: auto;
      margin-right: auto;
      max-width: 850px;
      padding: 0 36px;
    }
  }
}
