//.plmr-c-banner-content-info
@include plmr-component('banner-content-info') {
  font-size: 0;
  padding: 20px 0;

  //.plmr-c-banner-content-info__intro
  @include element('intro') {
    line-height: 24px;
    padding: 15px 0;
    text-align: center;
  }

  //.plmr-c-banner-content-info__intro-title
  @include element('intro-title') {
    color: plmr-color('brand', 'primary');
    font-size: 18px;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 21px;
      line-height: 2rem;
    }
  }

  //.plmr-c-banner-content-info__intro-copy
  @include element('intro-copy') {
    font-size: 16px;
    padding-top: 10px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }
  }

  //.plmr-c-banner-content-info__item
  @include element('item') {
    display: inline-block;
    font-size: 16px;
    padding: 20px 0;
    vertical-align: top;
    width: 100%;

    @include plmr-mq('md') {
      padding: 20px 31px;
      width: 50%;
    }

    @include plmr-mq('xl') {
      padding: 20px 27.5px;
    }

    &:nth-of-type(odd) {
      padding-left: 0;
    }

    &:nth-of-type(even) {
      padding-right: 0;
    }
  }

  //.plmr-c-banner-content-info__item-title
  @include element('item-title') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    margin: 0;
    padding-bottom: 25px;

    //.plmr-c-banner-content-info__item-title--light
    @include modifier('light') {
      @include plmr-font-face($family: 'regular');
    }
  }

  //.plmr-c-banner-content-info__item-copy
  @include element('item-copy') {
    font-size: 16px;
    line-height: 2rem;
    //padding-top: 25px;

    p {
      padding-bottom: 2em;
    }

    em {
      color: plmr-color('brand', 'primary');
      font-style: normal;
    }
  }

  //.plmr-c-banner-content-info__item-image
  @include element('item-image') {
    padding-top: 20px;
  }
}