/*
Site Map

markup:
<div class="container">
  <!-- Site Menu Instance One -->
  <div class="sitemap__row">
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading"><a href="">Bathroom</a></h2>
        <ul class="list sitemap__menu--level-2">
          <li><a class="arrowlink" href="">Faucets</a></li>
          <li><a class="arrowlink" href="">Shower &amp; Spa</a></li>
          <li><a class="arrowlink" href="">Accessories</a></li>
          <li><a class="arrowlink" href="">Lighting</a></li>
          <li><a class="arrowlink" href="">Safety</a></li>
          <li><a class="arrowlink" href="">Design &amp; Planning</a></li>
        </ul>
      </li>
    </ul>
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading"><a href="">Kitchen</a></h2>
        <ul class="list sitemap__menu--level-2">
          <li><a class="arrowlink" href="">Faucets</a></li>
          <li><a class="arrowlink" href="">Sinks</a></li>
          <li><a class="arrowlink" href="">Garbage Disposals</a></li>
          <li><a class="arrowlink" href="">Accessories</a></li>
          <li><a class="arrowlink" href="">Filtration</a></li>
          <li><a class="arrowlink" href="">Design &amp; Planning</a></li>
        </ul>
      </li>
    </ul>
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading"><a href="">Parts &amp; Support</a></h2>
        <ul class="list sitemap__menu--level-2">
          <li><a class="arrowlink" href="">Parts &amp; Instruction Sheets</a></li>
          <li><a class="arrowlink" href="">Installation Help</a></li>
          <li><a class="arrowlink" href="">Contact Us</a></li>
          <li><a class="arrowlink" href="">Literature Center</a></li>
          <li><a class="arrowlink" href="">Warranty Information</a></li>
          <li><a class="arrowlink" href="">FAQs</a></li>
          <li><a class="arrowlink" href="">Find Replacement Parts</a></li>
          <li><a class="arrowlink" href="">Buy Replacement Filters</a></li>
          <li><a class="arrowlink" href="">Product Registration</a></li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- Site Menu Instance Two -->
  <div class="sitemap__row">
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading"><a href="">Buy Select Products</a></h2>
        <ul class="list sitemap__menu--level-2">
          <li><a class="arrowlink" href="">Replacement Parts</a></li>
          <li><a class="arrowlink" href="">Filters</a></li>
        </ul>
      </li>
      <li>
    </ul>
    <ul class="list sitemap__menu--level-1 col-md-3">
      <h2 class="sitemap__menu-section-heading"><a href="">Where to Buy</a></h2>
      <ul class="list sitemap__menu--level-2">
        <li><a class="arrowlink" href="">Basic Search</a></li>
        <li><a class="arrowlink" href="">Online Retailers</a></li>
      </ul>
      </li>
    </ul>
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading"><a href="">Customer Support</a></h2>
        <ul class="list sitemap__menu--level-2">
          <li><a class="arrowlink" href="">Contact Us</a></li>
          <li><a class="arrowlink" href="">FAQ</a></li>
          <li><a class="arrowlink" href="">Warranty Information</a></li>
          <li><a class="arrowlink" href="">Parts &amp; Instruction Sheets</a></li>
          <li><a class="arrowlink" href="">Installation Help</a></li>
          <li><a class="arrowlink" href="">Literature Center</a></li>
          <li><a class="arrowlink" href="">Product Registration</a></li>
        </ul>
      </li>
    </ul>
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading"><a href="">Eco-Performance</a></h2>
        <ul class="list sitemap__menu--level-2">
          <li><a class="arrowlink" href="">Our Commitment</a></li>
          <li><a class="arrowlink" href="">Our Partnerships</a></li>
          <li><a class="arrowlink" href="">Efficient Products</a></li>
          <li><a class="arrowlink" href="">Getting Involved</a></li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- Site Menu Instance Three -->
  <div class="sitemap__row">
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading"><a href="">About Moen</a></h2>
        <ul class="list sitemap__menu--level-2">
          <li><a class="arrowlink" href="">Al Moen and the Moen Story</a></li>
          <li><a class="arrowlink" href="">What Sets Moen Apart?</a></li>
          <li><a class="arrowlink" href="">Corporate Philosophy</a></li>
          <li><a class="arrowlink" href="">Smart Innovations</a></li>
          <li><a class="arrowlink" href="">Moen Brands</a></li>
          <li><a class="arrowlink" href="">Awards</a></li>
          <li><a class="arrowlink" href="">Meet The Executives</a></li>
          <li><a class="arrowlink" href="">Corporate Locations</a></li>
          <li><a class="arrowlink" href="">Career Opportunities</a></li>
        </ul>
      </li>
    </ul>
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading"><a href="">Press Room</a></h2>
        <ul class="list sitemap__menu--level-2">
          <li><a class="arrowlink" href="">Press Releases</a></li>
          <li><a class="arrowlink" href="">Feature Articles</a></li>
          <li><a class="arrowlink" href="">Research Articles</a></li>
          <li><a class="arrowlink" href="">Online Press Kits</a></li>
          <li><a class="arrowlink" href="">Photography</a></li>
          <li><a class="arrowlink" href="">Logos</a></li>
          <li><a class="arrowlink" href="">Trade Advertising</a></li>
          <li><a class="arrowlink" href="">Quotes</a></li>
          <li><a class="arrowlink" href="">Tips</a></li>
          <li><a class="arrowlink" href="">Lists</a></li>
          <li><a class="arrowlink" href="">Ask The Experts</a></li>
          <li><a class="arrowlink" href="">Videos</a></li>
          <li><a class="arrowlink" href="">Customer Advertising</a></li>
        </ul>
      </li>
    </ul>
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading"><a href="">Account</a></h2>
        <ul class="list sitemap__menu--level-2">
          <li><a class="arrowlink" href="">Login</a></li>
          <li><a class="arrowlink" href="">My Account</a></li>
          <li><a class="arrowlink" href="">My Project</a></li>
          <li><a class="arrowlink" href="">Cart</a></li>
        </ul>
      </li>
    </ul>
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading"><a href="" class="">Moen Worldwide</a></h2>
        <ul class="list sitemap__menu--level-2">
          <li>
            <h3 class="">United States</h3>
            <ul class="list sitemap__menu--level-3">
              <li>
                <a class="arrowlink" href="">English</a>
              </li>
            </ul>
          </li>
          <li>
            <h3 class="small">Canada</h3>
            <ul class="list sitemap__menu--level-3">
              <li>
                <a class="arrowlink" href="">English</a>
              </li>
              <li>
                <a class="arrowlink" href="">Français</a>
              </li>
            </ul>
          </li>
          <li>
            <h3 class="small">Mexico</h3>
            <ul class="list sitemap__menu--level-3">
              <li>
                <a class="arrowlink" href="">English</a>
              </li>
              <li>
                <a class="arrowlink" href="">Español</a>
              </li>
            </ul>
          </li>
          <li>
            <h3 class="small">China</h3>
            <ul class="list sitemap__menu--level-3">
              <li>
                <a class="arrowlink" href="">中文简体</a>
              </li>
            </ul>
          </li>
          <li>
            <h3 class="small">India</h3>
            <ul class="list sitemap__menu--level-3">
              <li>
                <a class="arrowlink" href="">English</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    </div>
  <!-- Site Menu Instance Three -->
  <div class="sitemap__row">
    <ul class="list sitemap__menu--level-1 col-md-3">
      <li>
        <h2 class="sitemap__menu-section-heading">Other Pages</h2>
        <ul class="list sitemap__menu--level-2">
          <li><a class="arrowlink" href="">Privacy Policy</a></li>
          <li><a class="arrowlink" href="">Legal Notice</a></li>
        </ul>
      </li>
    </ul>
  </div>
</div> <!-- close .container -->

Styleguide 4.19
*/

@include block(sitemap) {
  font-size: 1rem;

  @include element(row) {
    @include outer-container();
    border-bottom: none;
    margin: 0 0 1em 0;
    padding: 0 0 1em 0;
    .arrowlink {
      @include media($bp-md) {
        font-size: em(14);
      }
    }
    @include media($bp-md) {
      border-bottom: 1px dotted $c_light-gray;
    }
  }

  @include element(menu) {
    margin: 0 0 1em 0;

    @include modifier(level-1) {
      & > li {
        margin: 0 0 1em 0;
        @include media($bp-md) {
          margin-bottom: .5em;
        }
      }
    }

    @include modifier(level-2) {
      & > li {
        margin: 0 0 1em 0;
        @include media($bp-md) {
          margin-bottom: .5em;
        }
      }
    }

    @include modifier(level-3) {
      margin-bottom: 1em;
      & > li {
        margin: 0 0 1em 1em;
        @include media($bp-md) {
          margin-bottom: .5em;
        }
      }
    }
  }

  @include element(menu-section-heading) {
    font-family: $din-medium;
    font-weight: normal;
    font-size: em(18);
    line-height: 1;
    margin: 1em 0;
    padding: 0;
    @include media($bp-md) {
      margin-top: 0;
    }
  }
}
