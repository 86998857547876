@include block(homepage) {
  @include element(email) {
    .text-box__inner {
      max-width: 680px;
      padding: 3em 2em;
    }

    .banner__image-bg > img {
      max-height: none;
    }
  }
}
