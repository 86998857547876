
// Animation Bounce
// -----------------------------------------------------------------------------
// sass-lint:disable-all
@keyframes plmr-animation-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }

}

// Animation Fade in/Fade out
// -----------------------------------------------------------------------------
@keyframes plmr-animation-fadeinout {
  0%, 100% {
    opacity: 0;
  }

  50% {
    opacity: 1
  }

}


@keyframes plmr-animation-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }

}

// sass-lint:enable-all


// Animation Utility
// -----------------------------------------------------------------------------
@include plmr-utility('animation') {
  // .plmr-u-animation--fade-in
  @include modifier('fade-in') {
    opacity: 1;
    transition: opacity 0.35s ease-in-out;


    // .plmr-u-animation--fade-in._hidden
    @include plmr-state('hidden') {
      opacity: 0;
    }


    // .plmr-u-animation--fade-in._visible
    @include plmr-state('visible') {
      opacity: 1;
    }


    // .plmr-u-animation._slow
    @include plmr-state('slow') {
      transition-delay: 0.15s;
    }

    // .plmr-u-animation._slower
    @include plmr-state('slower') {
      transition-delay: 0.45s;
    }
  }

  // .plmr-u-animation--fade-in-right
  @include modifier('fade-in-right') {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.75s ease;


    // .plmr-u-animation--fade-in-right._hidden
    @include plmr-state('hidden') {
      opacity: 0;
      transform: translateX(-20px);
    }
  }

  // .plmr-u-animation--slide-up
  @include modifier('slide-up') {
    opacity: 0;
    transform: translateY(150px);
    transition: 1s ease;


    // .plmr-u-animation--slide-up._appearing
    @include plmr-state('appearing') {
      opacity: 1;
      transform: translateY(0);
    }


    // .plmr-u-animation--slide-up._visible
    @include plmr-state('visible') {
      opacity: 1;
    }
  }


  // .plmr-u-animation--bounce
  @include modifier('bounce') {
    animation: plmr-animation-bounce 2s infinite;
  }


  @include modifier('fadeinout') {
    animation: plmr-animation-fadeinout 4s linear;
  }


  @include modifier('fadeout') {
    animation: plmr-animation-fadeout 2.5s 1s ease;
  }


}





