@mixin room-features-room-elements {
  display: block;
  position: absolute;
  top:0;left:0;
  width: 100%;
  height: 100%;
}


@include block(room-features) {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 400px;
  max-width: 690px;
  margin: 0 auto;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    width: 58px;
    height: 44px;
    background-image: url("images/room-features/drag-icon.svg");
    background-position: center center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    left:50%;
    bottom:60px;
    transform: translateX(-50%);
    z-index: 2;
    @include media($bp-md) {
      display: none;
    }
  }
  @include media($bp-md) {
    height: 450px;
  }
  @include media($bp-xl) {
    height: 630px;
    max-width: 966px;
  }
  // ROOM
  @include element(room) {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 690px;
    @include media($bp-xl) {
      height: 630px;
      width: 966px;
    }
  }
  @include element(room-cn) {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 690px;

    @include media($bp-md) {
      height: 100%;
      width: 100%;
    }
    @include media($bp-xl) {
      height: 100%;
      width: 62.36559%;
      min-width: 500px;
    }
    @include media($bp-lg) {
      height: 100%;
      width: 62.36559%;
    }
  }
  // ROOM ELEMENTS

  @include element(map) {
    @include room-features-room-elements();
    z-index: 1;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  @include element(map-cn) {
    @include room-features-room-elements();
    position: static;
  }
  @include element(points) {
    @include room-features-room-elements();
    z-index: 3;
  }
  // INDIVIDUAL POINTS
  @include element(point) {
    position: absolute;
    z-index: 4;
    @include media($bp-md) {
      &:before {
        content:' ';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 15px 15px 0;
        border-color: transparent $c_white transparent transparent;
        position: absolute;
        top:50%;
        right:-5px;
        transform: translate(15px,-15px);
        opacity: 0;
        transition: opacity 0.5s ease;
        z-index: 4;
        pointer-events: none;
      }
      &.left-side {
        &:before {
          border-width: 15px 0 15px 15px;
          border-color: transparent transparent transparent $c_white;
          right: auto;
          left: -5px;
          transform: translate(-15px,-15px);
        }
      }
      &.active:before {
        opacity: 1;
      }
    }
    @include element(icon) {
      display: block;
      width: 21px;
      height: 21px;
      background-image: url("images/room-features/point.svg");
      background-position: center center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      transition: all 0.5s ease;
      cursor: pointer;
      &:hover {
        background-image: url("images/room-features/point-active.svg");
      }
    }
    @include element("content") {
      display: block;
      opacity: 0;
      pointer-events: none;
      background-color: $c_white;
      // 			width: 175px;
      // 			width: 200px;
      width: 250px;
      height: auto;
      text-align: center;
      position: absolute;
      padding: 20px 35px 25px;
      box-shadow: 0 0 20px 0 rgba(150,150,150,0.35);
      transition: opacity 0.5s ease;
      .close {
        width: 15px;
        height: 15px;
        position: absolute;
        top:15px;
        right:15px;
        opacity: 0.75;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .tile {
        border: none;
        box-shadow: none !important;
        padding:0;
        margin: 0 auto;
        width: 100%;

        .product-tile__like {
          display: none;
        }

        .product-tile__image-slider {
          max-width: 130px;
          margin: 0 auto;
        }
        .rating {
          margin-left: 0;
        }
        .product-tile__name {
          font-size: em(12);
        }
        .cta {
          margin-left: 0;
          font-size: em(12);
        }
      }
      @include media($bp-md) {
        transform: translateY(-50%);
        left: 40px;
        top:50%;
        width: 213px;
        .room-features__point.left-side & {
          transform: translate(-100%,-50%);
          left: -20px;
        }
        .tile {
          .product-tile__image-slider {
            max-width: 100%;
          }
          .product-tile__name {
            font-size: em(14);
          }
          .cta {
            font-size: em(14);
          }
        }
        .close {
          display: none;
        }

      }
    }
    &.active {
      z-index: 5;
      .room-features__point__content {
        opacity: 1;
        pointer-events: auto;
      }
      .room-features__point__icon {
        background-image: url("images/room-features/point-active.svg");
        transform: rotate(45deg);
      }
    }
  }
}

@include  block(room-features-cn){
  height: auto;
  max-width: 100%;
  @include media($bp-xl) {
    height: auto;
    max-width: 100%;
  }
}
@include block(room-features-container) {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  @include media($bp-md) {
    width: calc(100% - 60px);
    max-width: 1220px;

  }
  & + .room-features-container {
    margin-top: 10px;
    @include media($bp-md) {
      margin-top: 30px;
    }
  }
  .room-features-description {
    position: relative;
    z-index: 2;
    background-color: $c_white;
    margin: -50px auto 0;
    padding: 40px 30px;
    width: calc(100% - 40px);
    max-width: 640px;
    .section-heading--1 {
      font-size: em(24);
      padding-bottom: em(12);
    }
    .button--cta {
      min-width: 150px;
      margin-top: 14px;
    }
    @include media($bp-md) {
      margin: -30px auto 0;
      width: 100%;
      p:not(.section-heading--1) {
        width: calc(100% - 190px);
        float: left;
      }
      .button--cta {
        float: right;
        margin-top: 0;
      }
    }
    @include media($bp-lg) {
      margin:0;
      padding: 50px;
      p:not(.section-heading--1) {
        width: 100%;
        float: none;
      }
      .button--cta {
        float: none;
        margin-top: 14px;
      }
    }
  }
  .room-features-description-cn {
    top: 0;
    transform: translate(0%,0%);
    text-align: center;

    .section-heading--1 {
      font-size: em(24);
      padding-bottom: em(12);
    }
    .section-heading--1-cn{
      font-family: fzdbs-ligth,DINWebLight,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
      font-style: normal;
      font-weight: 400;
      color: #5b7f95;
      font-size: 2rem;
      line-height: 2.286rem;
      margin: 0;
    }
    .section-p--1-cn{
      color: #1f1f1f;
      font-size: 1.143rem;
      line-height: 1.714rem;
    }
    .button--cta {
      min-width: 150px;
      margin-top: 14px;
    }
    @include media($bp-md) {
      margin: -30px auto 0;
      width: 100%;
      p:not(.section-heading--1) {
        width: calc(100% - 190px);
        float: left;
      }
      .button--cta {
        float: right;
        margin-top: 0;

      }
    }
    @include media($bp-lg) {
      margin:0;
      padding: 50px;
      p:not(.section-heading--1) {
        width: 100%;
        float: none;
      }
      .button--cta {
        float: none;
        margin-top: 14px;
      }
    }
  }
  @include media($bp-lg) {
    .room-features-description {
      position: absolute;
      bottom:0;
      width: 350px;
      min-height: 350px;
    }
    .room-features-description-cn {
      width: 37.63441%;
      min-height: 300px;
      top: 50%;
      transform: translate(0%, -50%);
    }
    &.left {
      .room-features-description {
        left:0;
      }
      .room-features {
        margin-left: auto;
        margin-right: 0;
      }
      .room-features-cn{
        display: flex;
        justify-content: flex-end;
      }
    }
    &.right {
      .room-features-description {
        right:0;
      }
      .room-features {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
  @include media($bp-xl) {

  }
}

@include  block(room-features-container-cn){
  @include media($bp-md){
    margin-bottom: 70px;
    width:100%;
    box-shadow: 20px 10px 20px 0 rgba(0,0,0,.1);
    max-width: 1300px;
    position: relative;
  }
}
