
/**
@doc _docs/banner-customer-service.md
@section Banner Customer Service
@childof Banners
@page Banners Banner Customer Service
@status in progress
@hippo helpful-tools
@example
_markup/banner-customer-service.hbs
*/
@include plmr-component('banner-customer-service') {
  padding: 40px 35px 50px;
  position: relative;

  @include plmr-mq(md) {
    padding: 50px 325px 60px 55px;
  }

  @include plmr-mq(xl) {
    padding-bottom: 112px;
    padding-left: 91px;
    //padding-right: calc(654 / 1300 * 100%);
    padding-right: 754px;
    padding-top: 75px;
  }

  //.plmr-c-banner-customer-service__contact
  @include element('contact') {
    color: plmr-color('brand', 'primary');
  }

  //.plmr-c-banner-customer-service__contact-title
  @include element('contact-title') {
    @include plmr-font-face($family: 'light');
    font-size: 24px;
    line-height: 32px;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  //.plmr-c-banner-customer-service__contact-number
  @include element('contact-number') {
    @include plmr-font-face($family: 'bold');
    font-size: 2rem;
    line-height: 36px;
    padding-bottom: 0;
    padding-top: 10px;

    @include plmr-mq(md) {
      font-size: 36px;
      line-height: 48px;
    }

    //.plmr-c-banner-customer-service__contact-number--sub
    @include modifier('sub') {
      @include plmr-font-face($family: 'regular');
      color: plmr-color('text', 'secondary');
      font-size: 18px;
      line-height: 24px;
      padding-top: 15px;

      @include plmr-mq(md) {
        font-size: 21px;
        line-height: 2rem;
      }
    }
  }

  //.plmr-c-banner-customer-service__contact-cta
  @include element('contact-cta') {
    padding-bottom: 0;
    padding-top: 30px;
  }

  //.plmr-c-banner-customer-service__hours
  @include element('hours') {
    color: plmr-color('text', 'secondary');
    font-size: 16px;
    line-height: 24px;
    padding-top: 30px;

    @include plmr-mq(xl) {
      padding: 0;
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  //.plmr-c-banner-customer-service__hours-title
  @include element('hours-title') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    line-height: 2rem;
    padding-bottom: 0;
  }

  //.plmr-c-banner-customer-service__hours-listing
  @include element('hours-listing') {
    padding-bottom: 0;
  }

  //.plmr-c-banner-customer-service__knockout
  @include element('knockout') {
    display: none;

    @include plmr-mq(md) {
      bottom: 0;
      display: block;
      height: 307px;
      position: absolute;
      right: 25px;
      width: 298px;
    }

    @include plmr-mq(xl) {
      height: 335px;
      right: calc(379 / 1300 * 100%);
      width: 325px;
    }
  }

  //.plmr-c-banner-customer-service__knockout-image
  @include element('knockout-image') {
    bottom: 0;
    height: auto;
    left: 50%;
    position: absolute;
    top: auto;
    transform: translate(-50%, 0);
    width: auto;
  }

}
