
/**
@doc _docs/banner-text-over-image.md
@section Banner Text Over Image
@childof Banners
@page Banners Banner Text Over Image
@status ready
@hippo banner
@example
_markup/banner-text-over-image.hbs
*/
@include plmr-component('banner-text-over-image') {
  //.plmr-c-banner-text-over-image__inner
  @include element('inner') {
    min-height: 450px;
    padding: 50px 35px;
    position: relative;

    @include plmr-mq(md) {
      padding: 75px 85px;
    }

    @include plmr-mq(lg) {
      padding-bottom: 90px;
      padding-top: 90px;
    }
  }

  //.plmr-c-banner-text-over-image__background
  @include element('background') {
    height: 100%;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  //.plmr-c-banner-text-over-image__background-image
  @include element('background-image') {
    display: block;
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  //.plmr-c-banner-text-over-image__heading
  @include element('heading') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 2rem;
    line-height: 2.571rem;
    margin: 0;
    padding-bottom: 20px;

    @include plmr-mq(md) {
      font-size: 2.571rem;
      line-height: 3.428rem;
    }
  }

  //.plmr-c-banner-text-over-image__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
    font-size: 1.286rem;
    line-height: 2.286rem;

    @include plmr-mq(md) {
      font-size: 1.5rem;
      line-height: 2.714rem;
    }

    //.plmr-c-banner-text-over-image__copy--bold
    @include modifier('bold') {
      @include plmr-font-face($family: 'bold');
    }
  }

  //.plmr-c-banner-text-over-image__content
  @include element('content') {
    margin: 0 auto;
    max-width: 608px;
    position: relative;
    text-align: center;
    z-index: 1;
  }
}


/**
@doc _docs/banner-text-over-image--light-text.md
@section Light Text
@sectionof Banner Text Over Image
@page Banners Banner Text Over Image
@example
_markup/banner-text-over-image--light-text.hbs
*/
@include plmr-component('banner-text-over-image') {
  //.plmr-c-banner-text-over-image--text-light
  @include modifier('text-light') {
    color: plmr-color('base', 'white');

    .plmr-c-banner-text-over-image__heading,
    .plmr-c-banner-text-over-image__copy {
      color: plmr-color('base', 'white');
    }
  }
}


/**
@doc _docs/banner-text-over-image--no-image.md
@section No Image
@sectionof Banner Text Over Image
@page Banners Banner Text Over Image
@example
_markup/banner-text-over-image--no-image.hbs
*/


/**
@doc _docs/banner-text-over-image--small.md
@section Small
@sectionof Banner Text Over Image
@page Banners Banner Text Over Image
@example
_markup/banner-text-over-image--small.hbs
*/
@include plmr-component('banner-text-over-image') {
  //.plmr-c-banner-text-over-image--small
  @include modifier('small') {
    .plmr-c-banner-text-over-image__inner {
      min-height: 300px;

      @include plmr-mq(lg) {
        padding-bottom: 81px;
        padding-top: 75px;
      }
    }
  }
}
