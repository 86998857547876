
/**
@doc _docs/cardlist-additional-resources.md
@section Card List Additional Resources
@childof Card Lists
@page Cardlists Cardlist Additional Resources
@status ready
@hippo card-list
@example
_markup/cardlist-additional-resource.hbs
*/
@include plmr-component('cardlist-additional-resource') {
  @extend .plmr-l-container;
  padding: 40px 0 40px 25px;

  @include plmr-mq(md) {
    padding: 50px 0 50px 55px;
  }

  @include plmr-mq(xl) {
    padding-left: 91px;
  }

  //.plmr-c-cardlist-additional-resource__inner
  @include element('inner') {
    background-color: plmr-color('background', 'secondary');
    display: flex;
    flex-flow: row wrap;
    padding: 20px 0;

    @include plmr-mq(md) {
      padding: 35px 39px 35px 14px;
    }

    @include plmr-mq(xl) {
      padding: 50px 114.5px 50px 22.5px;
    }
  }

  //.plmr-c-cardlist-additional-resource__item
  @include element('item') {
    display: flex;
    flex: 0 0 100%;
    flex-flow: row wrap;
    padding: 20px 30px;

    @include plmr-mq(md) {
      flex: 0 0 50%;
      padding: 25px 16px;
      width: 50%;
    }

    @include plmr-mq(lg) {
      flex: 0 0 33.333%;
      width: 33.333%;
    }

    @include plmr-mq(xl) {
      padding: 25px 27.5px;
    }
  }

  //.plmr-c-cardlist-additional-resource__item-icon-outer
  @include element('item-icon-outer') {
    color: plmr-color('brand', 'primary');
    flex-shrink: 0;
    width: 30px;

    @include plmr-mq(md) {
      width: 40px;
    }
  }

  //.plmr-c-cardlist-additional-resource__item-icon
  @include element('item-icon') {
    height: 30px;
    width: 30px;

    @include plmr-mq(md) {
      height: 40px;
      width: 40px;
    }
  }

  //.plmr-c-cardlist-additional-resource__item-content
  @include element('item-content') {
    flex-shrink: 0;
    padding-left: 20px;
    width: calc(100% - 30px);

    @include plmr-mq(md) {
      width: calc(100% - 40px);
    }
  }

  //.plmr-c-cardlist-additional-resource__item-title
  @include element('item-title') {
    color: plmr-color('brand', 'primary');
    font-size: 1.286rem;
    line-height: 1.714rem;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  //.plmr-c-cardlist-additional-resource__item-copy
  @include element('item-copy') {
    display: none;

    @include plmr-mq(md) {
      display: block;
      font-size: 1.143rem;
      line-height: 1.714rem;
      padding-top: 15px;
    }
  }

  //.plmr-c-cardlist-additional-resource__item-cta
  @include element('item-cta') {
    padding-top: 20px;

    @include plmr-mq(md) {
      padding-top: 30px;
    }
  }
}

/**
@doc _docs/cardlist-additional-resources--large.md
@section Large
@sectionof Card List Additional Resources
@example
_markup/cardlist-additional-resource--large.hbs
*/
@include plmr-component('cardlist-additional-resource') {
  //.plmr-c-cardlist-additional-resource--large
  @include modifier('large') {
    .plmr-c-cardlist-additional-resource__item-icon {
      @include plmr-mq(md) {
        height: 60px;
        width: 60px;
      }
    }

    .plmr-c-cardlist-additional-resource__item-icon-outer {
      @include plmr-mq(md) {
        width: 60px;
      }
    }

    .plmr-c-cardlist-additional-resource__item-content {
      @include plmr-mq(md) {
        width: calc(100% - 60px);
      }
    }

    .plmr-c-cardlist-additional-resource__item-title {
      @include plmr-mq(lg) {
        font-size: 2rem;
        line-height: 2.285rem;
      }
    }
  }
}
