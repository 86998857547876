//.plmr-c-cardlist-info
@include plmr-component('cardlist-info') {
  font-size: 0;
  padding: 15px 0;

  @include plmr-mq(md) {
    padding: 15px 0 25px;
  }

  //.plmr-c-cardlist-info__intro
  @include element('intro') {
    line-height: 24px;
    padding: 15px 0;
    text-align: center;
  }

  //.plmr-c-cardlist-info__intro-title
  @include element('intro-title') {
    color: plmr-color('brand', 'primary');
    font-size: 18px;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 21px;
      line-height: 2rem;
    }
  }

  //.plmr-c-cardlist-info__intro-copy
  @include element('intro-copy') {
    font-size: 16px;
    padding-top: 10px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }
  }

  //.plmr-c-cardlist-info__item
  @include element('item') {
    display: inline-block;
    font-size: 16px;
    padding: 15px 0;
    vertical-align: top;
    width: 100%;

    @include plmr-mq('md') {
      padding: 15px 20px;
      width: 50%;
    }

    @include plmr-mq('xl') {
      padding: 20px 27.5px;
    }

    &:nth-of-type(odd) {
      padding-left: 0;
    }

    &:nth-of-type(even) {
      padding-right: 0;
    }
  }

  //.plmr-c-cardlist-info__item-title
  @include element('item-title') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    margin: 0;
    padding-bottom: 25px;

    //.plmr-c-cardlist-info__item-title--light
    @include modifier('light') {
      @include plmr-font-face($family: 'regular');
    }
  }

  //.plmr-c-cardlist-info__item-copy
  @include element('item-copy') {
    font-size: 14px;
    line-height: 21px;

    p {
      padding-bottom: 2em;
    }

    em {
      color: plmr-color('brand', 'primary');
      font-style: normal;
    }
  }

  //.plmr-c-cardlist-info__item-image
  @include element('item-image') {
    padding-top: 20px;
  }
}


//.plmr-c-cardlist-info
@include plmr-component('cardlist-info') {
  //.plmr-c-cardlist-info--with-intro
  @include modifier('with-intro') {
    .plmr-c-cardlist-info__item-title {
      padding-bottom: 15px;
    }

    .plmr-c-cardlist-info__item {
      @include plmr-mq(md) {
        &:nth-child(odd) {
          padding-left: 20px;
          padding-right: 0;
        }

        &:nth-child(even) {
          padding-left: 0;
          padding-right: 20px;
        }
      }
    }
  }
}
