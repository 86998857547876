


//
@mixin plmr-spacing($space-type) {

  @for $i from 0 through ceil(150 / 5) {

    $value: $i * 5;

    // All Sides
    .plmr-u-#{$space-type}--#{$value} {
      #{$space-type}: #{$value}px;
    }

    // Top/Bottom
    .plmr-u-#{$space-type}-vertical--#{$value} {
      #{$space-type}-bottom: #{$value}px;
      #{$space-type}-top: #{$value}px;
    }

    // Left/Right
    .plmr-u-#{$space-type}-horizontal--#{$value} {
      #{$space-type}-left: #{$value}px;
      #{$space-type}-right: #{$value}px;
    }

    // Bottom
    .plmr-u-#{$space-type}-bottom--#{$value} {
      #{$space-type}-bottom: #{$value}px;
    }

    // Top
    .plmr-u-#{$space-type}-top--#{$value} {
      #{$space-type}-top: #{$value}px;
    }

    // Left
    .plmr-u-#{$space-type}-left--#{$value} {
      #{$space-type}-left: #{$value}px;
    }

    // Right
    .plmr-u-#{$space-type}-right--#{$value} {
      #{$space-type}-right: #{$value}px;
    }

  }

}




@mixin plmr-spacing-padding() {

  @include plmr-spacing(padding);

}



@mixin plmr-spacing-margin() {
  @include plmr-spacing(margin);
}
