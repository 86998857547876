
/**
Condensed select language menu styling.
@section Condensed Select Language
@sectionof Select Language
@example
_markup/select-language-condensed.hbs
*/


// .plmr-c-select-language
@include plmr-component('select-language') {

  // .plmr-c-select-language--condensed
  @include modifier('condensed') {


    // .plmr-c-select-language .plmr-c-select-language__input
    .plmr-c-select-language__input {
      font-size: 12px;
      height: 34px;
      line-height: 14px;
      padding-bottom: 0;
      padding-top: 0;
    }

  }

}
