/*
Hippo Form elements

This is the example of form elements for the Hippo Enterprise Forms with angular.
Form elements inherit styles from the uniform.js library (selector, uploader, radio, checker, button).
More information on Atom: _uniform.scss

markup:
<div class="hippo-form">
  <form action="">
    <div class="hippo-form-page">
      <div class="hippo-form-group hippo-form-group--half-size">
        <label class="form-label" for="">Input Text</label>
        <input type="text" name="text" class="form-input form-input__text">
      </div>
      <div class="hippo-form-group hippo-form-group--half-size">
        <label class="form-label" for="">Input Text</label>
        <input type="text" name="text" class="form-input form-input__text">
      </div>
    </div>
    <div class="hippo-form-page">
      <div class="hippo-form-group">
        <label class="form-label" for="">Input Text</label>
        <input type="text" name="text" class="form-input form-input__text">
      </div>
      <div class="hippo-form-group">
        <label class="form-label" for="">Input Text <span class="hippo-form__required">*</span></label>
        <input type="text" name="text" class="form-input form-input__text">
      </div>
      <div class="hippo-form-group">
        <label class="form-label" for="">Input Text <span class="hippo-form__required">*</span></label>
        <input type="text" name="text" class="form-input form-input__text">
        <span class='hippo-form__error-message'>Please re-enter a valid email address.</span>
      </div>
      <div class="hippo-form-group">
        <label class="form-label" for="selReason">Select</label>
        <div class="selector selector--unrounded icon-select-arrow-down">
          <span>Uniform Select</span>
          <select id="selReason" class="form-select" name="selReason">
            <option>Tiger</option>
            <option>Leopard</option>
            <option>Lynx</option>
          </select>
        </div>
      </div>
      <div class="hippo-form-group">
        <label class="form-label" for="selReason">File uploader</label>
        <div class="uploader">
          <input class="file" type="file">
          <em class="hippo-form__hint">Maximum file size 8 MB</em>
        </div>
      </div>
      <div class="hippo-form-group">
        <label class="form-label">Comments<span class="form__required"></span></label>
        <textarea class="form-textarea" cols="40" rows="10">
        </textarea>
        <em class="hippo-form__hint">If your request is concerning an existing Moen product in your home,
  please provide details of the issue(s) you are experiencing with your product.</em>
        </div>
      </div>
    </div>
  </form>
</div>

Styleguide 3.23
*/

@include block(hippo-form) {
  position: relative;
  $border-form: 2px solid $c_form_border;

  @include element(heading) {
    margin-bottom: 1em;
  }

  @include element(icon) {
    height: 2.2em;
    width: 2.2em;
  }

  @include element(title) {
    font-family: $din-light;
    font-size: em(30px);
    margin-left: .5em;
    padding-bottom: 0;
  }

  @include element(required) {
    color: red;
  }

  @include element(thumb) {
    height: auto;
    width: 50px;
  }

  @include element(hint) {
    display: block;
    font-size: em(14px);
    font-style: italic;
  }

  @include element(error-message) {
    display: block;
    color: $c_error;
  }

  @include element(modal) {
    h1 {
      font-size: em(44);
      font-family: $din-light;
      font-weight: normal;
      letter-spacing: -.05em;
    }
    h2 {
      font-size: em(22);
      font-family: $din-medium;
      font-weight: normal;
    }
    h3 {
      font-size: em(22);
      font-family: $din-medium;
      font-weight: normal;
    }
    h4 {
      font-size: em(18);
      font-family: Arial, sans-serif;
      font-weight: normal;
    }
    h5 {
      font-size: em(17);
      font-family: Arial, sans-serif;
      font-weight: normal;
    }
    h6 {
      font-size: em(16);
      font-family: Arial, sans-serif;
      font-weight: normal;
    }
  }

  // Overriding _form-elements.scss
  .form-input {
    border: $border-form;
  }
}

@include block(hippo-form-section) {
  margin-bottom: 2em;
}

@include block(hippo-form-page) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  @include modifier('hidden') {
    display: none;
  }
}

@include block(hippo-form-group) {
  margin-bottom: 1em;
  width: 100%;
  @include state('hidden') {
    display: none;
  }
  @include modifier(half-size) {
    max-width: 100%;
    padding-right: 0;
    @include media($bp-sm) {
      max-width: 48%;
      flex-basis: 48%;
    }
  }
  @include modifier(inline) {
    display: inline-block;
    max-width: 100%;
    padding-right: 0;
    @include media($bp-sm) {
      max-width: 30%;
      padding-right: 2em;
      & + .form-group--inline {
        padding-right: 0;
      }
    }
  }
}

@include block(hippo-form-thankyou) {
  padding: .5em 0;
  @include element(inner) {
    @include outer-container();
  }

  @include element(title) {
    text-align: center;
    font-weight: normal;
    margin-bottom: .5em;
  }

  @include element(text) {
    font-size: 1.5em;
  }

}


.hippo-form {

  .form-label {
    display: block;
    font-family: $din-bold;
    margin-bottom: .5em;
  }

  .form-textarea {
    width: 100%;
  }

  // Overriding _uniform.scss
  div.uploader {
    span.uploader__filename,
    span.action {
      display: none;
    }
  }

  div.checker {
    font-family: $helvetica;
  }

  div.selector {
    border-radius: 0;
    span {
      font-family: $helvetica;
    }
  }

  div.selector,
  .form-input {
    height: 40px;
    width: 100%;
  }

  // Reset Label Font Family
  .form-label--group {
    display: inline-block;
    font-family: $helvetica;
    margin-right: .5em;
    div.checker {
      border: 0;
      input {
        border: initial;
        position: relative;
        opacity: 1;
        width: auto;
      }
    }
  }
}
