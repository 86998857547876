/**
See gallery/product listing page for example usage
@section Product Item
@childof Content
@page Content Product Item
@status in progress
@example
_markup/product-item.hbs
*/
@include plmr-component('product-item') {
  display: inline-block;
  height: auto;
  margin: 10px 0;
  min-height: 316px;
  min-width: 160px;
  padding: 0 12.5px;
  position: relative;
  vertical-align: top;
  width: 50%;

  // .plmr-c-product-item._new
  @include plmr-state('new') {
    .plmr-c-product-item__inner {
      &::before {
        background-color: plmr-color('brand', 'tertiary');
        border-radius: 50%;
        display: block;
        height: 35px;
        left: 0;
        padding-top: 13px;
        top: 10px;
        width: 35px;

        @include plmr-mq(lg) {
          left: 12.5px;
        }
      }
    }
  }

  // .plmr-c-product-item._discontinued
  @include plmr-state('discontinued') {
    .plmr-c-product-item__inner {
      &::before {
        background-color: darken(plmr-color('text', 'secondary'), 8%);
        display: block;
        left: 50%;
        letter-spacing: 2px;
        margin-top: calc((140/375 * 100%) + 30px);
        padding: 10px;
        text-transform: uppercase;
        top: 0;
        transform: translateX(-50%);

        @include plmr-mq(md) {
          margin-top: calc((140/375 * 100%) + 62.5px);
          padding: 10px 20px 12px;
        }

        @include plmr-mq(lg) {
          margin-top: calc((140/375 * 100%) + 50px);
        }
      }
    }

    .plmr-c-product-item__image-area {
      opacity: 0.5;
    }
  }

  @include plmr-mq(md) {
    margin: 15px 0;
    padding: 0 31px;
  }

  @include plmr-mq(lg) {
    padding: 0 27.5px;
    width: 33.33%;
  }

  // .plmr-c-product-item__inner
  @include element('inner') {
    border: 1px solid transparent;
    min-height: 100%;
    padding: 30px 10px 10px;
    position: relative;
    text-align: center;
    transition: 0.333s ease;
    z-index: 1;

    // .plmr-c-product-item__inner::before
    &::before {
      @include plmr-font-face($family: 'bold');
      @include plmr-pseudo;
      // background-color: plmr-color('brand', 'tertiary');
      // border-radius: 50%;
      color: plmr-color('base', 'white');
      content: attr(data-pseudo-label);
      display: none;
      font-size: 11px;
      // height: 35px;
      // left: 0;
      line-height: 8px;
      // padding-top: 13px;
      pointer-events: none;
      text-align: center;
      // top: 10px;
      // width: 35px;
      z-index: 4;

      @include plmr-mq(lg) {
        // left: 12.5px;
      }
    }

    // .plmr-c-product-item__inner:hover
    &:hover {
      @include plmr-mq(lg) {
        border: 1px solid plmr-color('accent', 'light');

        .plmr-c-product-item__arrows {
          opacity: 1;
          pointer-events: auto;
        }

        .plmr-c-product-item__review {
          opacity: 1;
        }
      }
    }

    @include plmr-mq(md) {
      padding: 50px 20px 20px;
    }
  }

  // .plmr-c-product-item__arrows
  @include element('arrows') {
    color: plmr-color('accent', 'dark');
    display: none;
    height: auto;
    left: 50%;
    min-height: 18px;
    min-width: 100%;
    opacity: 0;
    padding-top: 100%;
    pointer-events: none;
    position: absolute;
    //top: 0;
    top: 32px;
    transform: translate(-50%, 0%);
    transition: 0.333s ease;
    width: auto;
    z-index: 1;
  }

  // .plmr-c-product-item__arrow
  @include element('arrow') {
    cursor: pointer;
    height: 18px;
    min-height: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;

    // .plmr-c-product-item__arrow--prev
    @include modifier('prev') {
      left: 5px;
      transform: translateY(-50%) scale(-1, -1);
    }

    // .plmr-c-product-item__arrow--next
    @include modifier('next') {
      right: 5px;
    }

    // .plmr-c-product-item__arrow._disabled
    @include plmr-state('disabled') {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  // .plmr-c-product-item__image-area
  @include element('image-area') {
    display: block;
    height: auto;
    margin-bottom: 15px;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;
    z-index: 2;

    @include plmr-mq(md) {
      margin-bottom: 25px;
    }
  }

  // .plmr-c-product-item__image-track
  @include element('image-track') {
    display: flex;
    flex-wrap: nowrap;
    height: auto;
    left: 0;
    max-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 0;
    transition: left 0.25s linear;
    width: auto;
  }

  // .plmr-c-product-item__image
  @include element('image') {
    @extend .plmr-u-img--responsive;
    opacity: 0;
    transition: opacity 0.25s ease-in;

    .no-js & {
      opacity: 1;
    }

    // .plmr-c-product-item__image._current
    @include plmr-state('current') {
      opacity: 1;
    }
  }

  // .plmr-c-product-item__specs
  @include element('specs') {
    @include plmr-mq(lg) {
      min-height: 187px;
    }
  }

  // .plmr-c-product-item__name
  @include element('name') {
    @include plmr-link($color: plmr-color('brand', 'primary'));
    display: block;
    font-size: 1rem;
    line-height: 1.286rem;
    margin-top: 1px;

    @include plmr-mq(md) {
      font-size: 1.143rem;
      line-height: 1.5rem;
    }

    @include plmr-mq(lg) {
      min-height: 63px;
    }

    // .plmr-c-product-item__name--collection
    @include modifier('collection') {
      @include plmr-font-face($family: 'bold');
    }

    // .plmr-c-product-item__name--model
    @include modifier('model') {
      @include plmr-font-face($family: 'italic');
      color: plmr-color('text', 'secondary');
      font-size: 0.8571rem;
      line-height: 1.143rem;
      padding: 0;
      padding-top: 10px;

      @include plmr-mq(md) {
        font-size: 1rem;
        padding-top: 15px;
      }
    }

    // .plmr-c-product-item__name--msrp
    @include modifier('msrp') {
      padding-bottom: 0;
      padding-top: 10px;

      @include plmr-mq(md) {
        padding-top: 15px;
      }

      @include plmr-mq(lg) {
        min-height: 0;
      }  
    }
  }

  // .plmr-c-product-item__review
  @include element('review') {
    color: plmr-color('brand', 'primary');
    display: none;
    opacity: 0;
    padding-top: 10px;
    transition: 0.333s ease;

    @include plmr-mq(md) {
      padding-top: 15px;
    }

    @include plmr-mq(lg) {
      display: block;
    }
  }

  // .plmr-c-product-item__finishes
  @include element('finishes') {
    padding-top: 15px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }
  }
}

/**
@section Discontinued
@sectionof Product Item
@example
_markup/product-item--discontinued.hbs
*/

/**
See gallery/product listing page for example usage
@section List View
@sectionof Product Item
@page Content Product Item
@example
_markup/product-item-list-view.hbs
*/
@include plmr-component('product-item') {
  // .plmr-c-product-item--list-view
  @include modifier('list-view') {
    margin: 0;
    min-height: 185px;
    min-width: 100%;
    width: 100%;

    @include plmr-mq(md) {
      margin: 0;
    }

    @include plmr-state('discontinued') {
      .plmr-c-product-item__inner {
        &::before {
          left: 0;
          margin-left: calc(21% - 53px);
          margin-top: calc(21% + 15px);
          transform: none;

          @include plmr-mq('md') {
            margin-left: calc(16% - 58px);
            margin-top: 16%;
          }

          @include plmr-mq('lg') {
            margin-top: 16%;
          }
        }
      }
    }

    .plmr-c-product-item__inner {
      font-size: 0;
      text-align: left;

      @include plmr-mq(md) {
        padding-top: 20px;
      }
    }

    .plmr-c-product-item__image-area {
      display: inline-block;
      margin-bottom: 0;
      padding-top: 43%;
      vertical-align: top;
      width: 43%;

      @include plmr-mq(md) {
        padding-top: 32%;
        width: 32%;
      }
    }

    .plmr-c-product-item__specs {
      display: inline-block;
      padding-left: 15px;
      vertical-align: top;
      width: 57%;

      @include plmr-mq(md) {
        padding-left: 75px;
        padding-top: 20px;
        width: 68%;
      }

      @include plmr-mq(lg) {
        min-height: 0;
      }
    }

    .plmr-c-product-item__name {
      @include plmr-mq(lg) {
        min-height: 0;
      }
    }

    .plmr-c-product-item__review {
      display: block;
      opacity: 1;
    }

    .plmr-c-product-item__arrows {
      left: 0;
      min-width: 35%;
      padding-top: 32%;
      top: 15px;
      transform: none;
    }
  }
}
