//.plmr-c-replacement-parts
@include plmr-component('replacement-parts') {
  //.plmr-c-replacement-parts__container
  @include element('container') {
    padding: 0;
  }

  //.plmr-c-replacement-parts__section-wrap
  @include element('section-wrap') {
    margin: 0;
    padding: 0 15px;

    @include plmr-mq('md') {
      padding: 20px 55px 30px;
    }

    @include plmr-mq('lg') {
      padding: 50px 0;
      padding-left: calc(91 / 1300 * 100%);
      padding-right: calc(81 / 1300 * 100%);
    }

    @include plmr-mq('max') {
      padding-left: 91px;
      padding-right: 81px;
    }
  }

  //.plmr-c-replacement-parts__section
  @include element('section') {
    padding: 20px 0;

    @include plmr-mq(xl) {
      padding: 0;
    }
  }

  //.plmr-c-replacement-parts__pdf-viewer
  @include element('pdf-viewer') {
    padding-top: calc(465 / 345 * 100%);
    position: relative;

    @include plmr-mq('md') {
      padding-top: calc(692 / 658 * 100%);
    }

    @include plmr-mq('xl') {
      margin: 0 auto;
      max-width: 490px;
      padding-top: calc(692 / 490 * 100%);
    }
  }

  //.plmr-c-replacement-parts__pdf-viewer-object
  @include element('pdf-viewer-object') {
    height: 100%;
    left: 0;
    max-height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  //.plmr-c-replacement-parts__table
  @include element('table') {
    @include plmr-mq(xl) {
      margin-left: auto;
      margin-right: 0;
      max-width: 562px;
    }

    @include plmr-mq(max) {
      max-width: 600px;
    }
  }

  //.plmr-c-replacement-parts__table-row
  @include element('table-row') {
    align-items: normal;
    border-bottom: 1px solid plmr-color('accent', 'light');
    border-top: 1px solid plmr-color('accent', 'light');
    display: flex;
    flex-flow: row wrap;
    padding: 5px 0;

    & ~ & {
      margin-top: -1px;
    }

    @include plmr-mq('md') {
      flex-wrap: nowrap;
      padding: 0;
    }

    //.plmr-c-replacement-parts__table-row--header
    @include modifier('header') {
      border-top: 0;
      display: none;

      .plmr-c-replacement-parts__table-cell {
        padding-top: 0;
      }

      @include plmr-mq('md') {
        display: flex;

        .plmr-c-replacement-parts__table-heading {
          display: block;
        }
      }
    }
  }

  //.plmr-c-replacement-parts__table-cell
  @include element('table-cell') {
    flex: 1 1 100%;
    max-width: calc(100% / 3);
    padding: 10px 8px;

    &:first-child { // part info cell
      color: plmr-color('text', 'secondary');
    }

    &:last-child { // button cell
      align-items: flex-end;
      display: inline-flex;
      min-width: 121px;
    }

    @include plmr-mq('md') {
      max-width: 175px;
      padding: 15px 0;

      &:first-child { // part info cell
        min-width: 175px;
      }

      &:last-child { // button cell
        display: block;
        min-width: 121px;
      }
    }
  }

  //.plmr-c-replacement-parts__table-heading
  @include element('table-heading') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    display: block;
    font-size: 1rem;
    line-height: 17px;

    @include plmr-mq(md) {
      display: none;
      font-size: 1.143rem;
      line-height: 19px;
    }
  }

  //.plmr-c-replacement-parts__select
  @include element('select') {
    margin-top: 10px;

    @include plmr-mq(md) {
      margin-top: 0;
    }
  }

  //.plmr-c-replacement-parts__button
  @include element('button') {
    padding: 12px;
    white-space: nowrap;
  }

  //.plmr-c-replacement-parts__link
  @include element('link') {
    display: block;

    @include plmr-mq(md) {
      margin-bottom: 5px;
    }
  }
}
