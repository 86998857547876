// .innovations
@include block(innovations) {
	// .innovations__table
	@include element("table") {
		font-size: 0;
		line-height: 0;
		color: $c_mid-gray;
		padding: 20px 0px 15px;
		width: calc(100% - 20px);
		margin: 10px auto;
		border-bottom: 1px solid $c_moen-bright-gray;
		@include media($bp-md) {
			padding: 20px 0 15px;
			width: calc(100% - 80px);
			max-width: 1112px;
		}
		// .innovations__table__head
		@include child("head") {
			padding: 20px 0;
			border-bottom: 1px solid $c_moen-bright-gray;
			text-align: right;
			background-color: white;
			z-index: 9;
			.innovations__table__col {
				font: normal 14px/1em $proxima-regular;
				min-width: 100px;
				padding: 0 5px;
				text-align: center;
				vertical-align: middle;
				margin: 0 !important;
				
			}
			@include state("sticky") {
				position: fixed;
				top:71px;
				left:0;
				right:0;
				margin: 0 auto;
				width: calc(100% - 20px);
			}
			@include state("bottom") {
				position: absolute;
				bottom:0;
				top:auto;
				left:0;
				right:0;
				margin: 0 auto;
				width: calc(100% - 20px);
			}
			@include child("outer") {
				min-height: 69px;
			}
			@include media($bp-md) {
				padding: 0px 40px 20px;
				.innovations__table__col {
					font-size: 18px;
				}
			}
			@include media($bp-xl) {
				.innovations__table__col {
					width: 300px;
					padding: 0 25px;
					margin:0;
				}
			}
		}
		// .innovations__table__row
		@include child("row") {
			padding: 25px 10px;
			text-align: center;
			&::before,&::after {
				content: ' ';
				display: table;
				clear: both;
			}
			@include media($bp-md) {
				padding: 25px 40px;
			}
			@include media($bp-xl) {
				.innovations__table__col {
					width: 300px;
					margin: 0 !important;
					padding: 0 25px;
					&:first-of-type {
						width: calc(100% - 600px);
						padding:0;
					}
				}
			}
		}
		// .innovations__table__col
		@include child("col") {
			display: inline-block !important;
			vertical-align: middle;
			float: none !important;
			font: normal 12px/1.5em $proxima-regular;
			@include media($bp-md) {
				font-size: 14px;
				line-height: 24px;
			}
			
		}
		// .innovations__table__heading
		@include child("heading") {
			font: normal 12px/1.1666666667em $din-bold;
			color: $c_moen-gray;
			text-transform: uppercase;
			padding:0;
			// .innovations__table__heading--features
			@include modifier("features") {
				margin-bottom: 25px;
			}
			@include media($bp-md) {
				font-size: 16px;
			}
		}
		// .innovations__table__icon
		@include child("icon") {
			display: inline-block;
			vertical-align: middle;
			width: auto;
			height: 17px;
			@include media($bp-md) {
				height: 28px;
			}
		}
		// .innovations__table__finish
		@include child("finish") {
			display: block;
			margin: 0 auto 5px;
			width: 18px;
			height: auto;
			@include media($bp-md) {
				width: 30px;
				display: inline-block;
				margin: 5px;
			}
		}
		// .innovations__table__description
		@include child("description") {
			padding: 12px 0 0;
			font: 12px/18px $proxima-regular;
			@include media($bp-md) {
				color: $c_moen-gray;
				font: 16px/24px $proxima-regular;
			}
		}
		// .innovations__table__info
		@include child("info") {
			position: relative;
			display: inline-block;
			@include media($bp-md) {
				padding: 0 9px 11px;
			}
		}
		// .innovations__table__info-icon
		@include child("info-icon") {
			display: block;
			width: 12px;
			height: 12px;
			margin: 10px auto 0;
			@include media($bp-md) {
				width: 16px;
				height: 16px;
				margin:0;
				position: absolute;
				bottom:0;
				right:0;
				z-index: 1;
			}
		}
		// .innovations__table__info-popup
		@include child("info-popup") {
			position: absolute;
			bottom:0;
			right:0;
// 			transform-origin: bottom right;
// 			transform: scale(0);
			border-radius: 100%;
			overflow: hidden;
			box-shadow: 0 0 20px 0 rgba(150,150,150,0.25);
// 			transition: transform 0.2s ease-in-out;
			transition: opacity 0.2s ease-in-out;
			pointer-events: none;
			opacity: 0;
			z-index: 2;
			@include state("active") {
// 				transform: scale(1);
				opacity: 1;
				pointer-events: auto;
			}
		}
		
	}
}