
@include block('contact-us') {
  
  .icon-label__icon--contact-us {
    height: 1.5em;
    width: 1.5em;
  }
  
  @include child('error') {
    color: $c_error;
    font-size: em(18);
    font-weight: bold;
    margin-bottom: 2em;
  }
  
}


@include block('contact-page-title') {
  margin-bottom: em(50);
  padding-top: em(25);
  
  @include child('h1') {
    font-family: $din-light;
    font-size: rem(30);
    font-weight: normal;
    letter-spacing: -.05em;
    line-height: 1.1em;   
    margin: 0;
    
    @include media($bp-sm) {
      font-size: rem(35);
    }
  }
  
  @include child('icon') {
    height: 1.2em;
    margin-top: -1%;
    width: 1.2em;
  }
    
}


@include block('contact-form-close') {
  
  @include child('label') {
    color: $c_text-dark;  
  }
  
  @include child('icon') {
    height: em(30);
    width: em(30);
  }
  
  &:hover {
    text-decoration: none;
  }
  
}


@include block('contact-us-buttons') {
    
  @include child('cancel') {
    display: block;
    text-align: center;
    width: 100%;
    
    @include media($bp-sm) {
      display: inline;
      line-height: 1;
      margin-left: em(20);
      text-align: left;
      vertical-align: text-top;
      width: auto;
    }
  }
  
}


@include block('contact-us-confirmation') {
  
  @include child('message') {
    font-size: em(20);
    
    p {
      font-size: inherit;
    }
  }
}
