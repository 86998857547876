/**
@doc _docs/cardlist-bucket-callouts.md
@section Card List Bucket Callouts
@childof Card Lists
@page Cardlists Cardlist Bucket Callouts
@status ready
@hippo card-list
@example
_markup/cardlist-bucket-callouts.hbs
*/
@include plmr-component('cardlist-bucket-callouts') {
  background: plmr-color('base', 'white');
  position: relative;
  z-index: 1;

  // .plmr-c-cardlist-bucket-callouts::before
  &::before {
    background: plmr-color('background', 'secondary');
    bottom: 0;
    content: '';
    display: block;
    height: calc(100% - 107px);
    left: 0;
    position: absolute;
    width: 100%;

    @include plmr-mq(lg) {
      height: calc(100% - 210px);
    }
  }

  // .plmr-c-cardlist-bucket-callouts__container
  @include element('container') {
    font-size: 0;
    padding: 0 35px 40px;
    position: relative;
    text-align: center;
    z-index: 2;

    @include plmr-mq(md) {
      padding: 0 24px 50px;
    }

    @include plmr-mq(lg) {
      padding: 0 89px 50px;
    }
  }

  // .plmr-c-cardlist-bucket-callouts__item
  @include element('item') {
    display: inline-block;
    text-align: center;

    & ~ & {
      padding-top: 48px;
    }

    @include plmr-mq(md) {
      padding: 0 31px;
      vertical-align: top;
      width: 50%;

      & ~ & {
        padding-top: 0;
      }
    }

    @include plmr-mq(lg) {
      padding: 0 53px;
    }
  }

  // .plmr-c-cardlist-bucket-callouts__media
  @include element('media') {
    height: auto;
    overflow: hidden;
    padding-top: 66.25%;
    position: relative;
    width: 100%;
  }

  // .plmr-c-cardlist-bucket-callouts__image
  @include element('image') {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    transition: transform 0.5s ease;
    width: auto;

    // .plmr-c-cardlist-bucket-callouts__image:hover
    &:hover {
      transform: translateX(-50%) scale(1.1);
    }
  }

  // .plmr-c-cardlist-bucket-callouts__play
  @include element('play') {
    background: rgba(plmr-color('base', 'white'), 0);
    border: 2px solid plmr-color('base', 'white');
    border-radius: 100%;
    bottom: 0;
    display: block;
    height: 40px;
    left: 0;
    margin: 15px;
    position: absolute;
    transition: 0.5s ease;
    width: 40px;

    @include plmr-mq(xl) {
      height: 50px;
      margin: 25px;
      width: 50px;
    }

    // .plmr-c-cardlist-bucket-callouts__play::before
    &::before {
      border-bottom: 10px solid transparent;
      border-left: 15px solid plmr-color('base', 'white');
      border-radius: 2px;
      border-top: 10px solid transparent;
      content: '';
      height: 0;
      left: calc(50% + 12px);
      position: absolute;
      top: calc(50% + 15px);
      transform: translate(-17px, -25px);
      z-index: 2;

      @include plmr-mq(xl) {
        &::before {
          border-bottom: 13px solid transparent;
          border-left: 18px solid plmr-color('base', 'white');
          border-top: 13px solid transparent;
          top: calc(50% + 12px);
				}
      }
    }

    // .plmr-c-cardlist-bucket-callouts__play:hover
    &:hover {
      background: rgba(plmr-color('base', 'white'), 0.35);
      cursor: pointer;
    }
  }

  // .plmr-c-cardlist-bucket-callouts__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.286rem;
    margin: 0;
    padding-top: 40px;

    @include plmr-mq(md) {
      font-size: 2rem;
    }
  }

  // .plmr-c-cardlist-bucket-callouts__link
  @include element('link') {
    padding-top: 40px;
  }

  // .plmr-c-cardlist-bucket-callouts__copy
  @include element('copy') {
    font-size: 1.143rem;
    line-height: 2rem;
    padding-top: 20px;
  }

  // .plmr-c-cardlist-bucket-callouts__button
  @include element('button') {
    padding-top: 40px;
    text-align: center;

    @include plmr-mq(md) {
      padding-top: 60px;
    }
  }
}

/**
@doc _docs/cardlist-bucket-callouts--with-modal.md
@section With Modal
@sectionof Card List Bucket Callouts
@page Cardlists Cardlist Bucket Callouts
@example
_markup/cardlist-bucket-callouts--with-modal.hbs
*/
