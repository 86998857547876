
:root {
  font-family: plmr-config('font', 'font-family');
  font-size: plmr-config('font', 'font-size-root');
}



// Base body styling
// -----------------------------------------------------------------------------
body {
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
  background-color: plmr-color('base', 'white');
  color: plmr-color('text', 'primary');
  font-family: inherit;
  font-size: inherit;
  line-height: plmr-config('font', 'line-height');
  overflow-x: auto;
}



// Base link styling
// -----------------------------------------------------------------------------
a {
  @include plmr-link($color: plmr-color('brand', 'tertiary'), $underline: true);
}



// Focus Event styling
// -----------------------------------------------------------------------------
:focus {
  //outline: 1px dashed currentColor;
}



// Selection styling
// -----------------------------------------------------------------------------
::selection {
  background-color: plmr-color('brand', 'primary');
  color: plmr-color('base', 'white');
  text-shadow: none;
}



// Base Paragraph styling
// -----------------------------------------------------------------------------
p {
  font-family: inherit;
  font-size: inherit;
  padding-bottom: 1em;
}




// Fixed state
// -----------------------------------------------------------------------------
@include plmr-object('body') {

  @include plmr-state('fixed') {
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
  }

}


//font-style
//-----------------------------------------------------------------------------
.plmr-u-text--normal{
  font-style: normal !important;
}
.plmr-u-text--italic{
  font-style:italic !important;
}
.plmr-u-text--oblique{
  font-style:oblique !important;
}
