/*
Facet Filtering

Styleguide 4.15
*/

/*
Facet Buttons with tooltips

Tooltips are added dynamically by javascript

markup:
<div class="facets__filters is-active">
  <div class="facets__inner">
    <div class="facets__container">
      <div class="facets__slider">
        <button class="facets__button button button--default button--down-arrow is-active has-tooltip">
          Product Type
          <span class="tooltip-info js-facets-tooltip">
            <span class="facets-tooltip"></span>
          </span>
        </button>
        <button class="facets__button button button--default button--down-arrow has-tooltip">Any Price<span class="tooltip-info js-facets-tooltip"></span></button>
        <button class="facets__button button button--default button--down-arrow">Any Style</button>
        <button class="facets__button button button--default button--down-arrow">Any Collection</button>
      </div>
    </div>
  </div>
</div>

Styleguide 4.15.1
*/


/*
Facet Filtering

markup:
<div class="facets">
  <div class="facets__form">
    <form>
      <div class="facets__main">
        <div class="facets__select-sort form-select--blue">
          <select name="sort" class="form-select">
            <option disabled="disabled" selected="selected">Sort</option>
            <option>Most Popular</option>
            <option>Customer Review</option>
            <option>Price: Low to High</option>
            <option>Price: High to Low</option>
          </select>
        </div>
        <div class="facets__main-buttons">
          <button class="facets__button-filters button  button--default-border button--down-arrow">FILTER</button>
          <button class="facets__button-apply-filter button button--default-border">Apply Filters</button>
          <a class="facets__button button button--default facets__clear-all">Clear All Filters</a>
        </div>
      </div>
      <div class="facets__filters is-active">
        <div class="facets__inner">
          <div class="facets__container">
            <div class="facets__slider">
              <button class="facets__button button button--default button--down-arrow is-active has-tooltip">Product Type
                <span class="tooltip-info js-facets-tooltip">
                  <span class="facets-tooltip"></span>
                </span>
              </button>
              <button class="facets__button button button--default button--down-arrow has-tooltip">Any Price
                <span class="tooltip-info js-facets-tooltip">
                  <span class="facets-tooltip"></span>
                </span>
              </button>
              <button class="facets__button button button--default button--down-arrow has-tooltip">Any Style</button>
              <button class="facets__button button button--default button--down-arrow has-tooltip">Any Collection</button>
            </div>
            <div class="facets__list is-active">
              <div class="facets__ck form-checkbox form-checkbox--white">
                <input id="ckArmDiverter" name="ckArmDiverter" type="checkbox">
                <label for="ckArmDiverter">Arm Diverter (36)
                  <span class="tooltip-info js-facets-tooltip">
                    <span class="facets-tooltip"></span>
                  </span>
                </label>
              </div>
              <div class="facets__ck form-checkbox form-checkbox--white">
                <input id="ckBodySprays" name="ckBodySprays" type="checkbox">
                <label for="ckBodySprays">Body Sprays (15)</label>
              </div>
              <div class="facets__ck form-checkbox form-checkbox--white">
                <input id="ckBracket" name="ckBracket" type="checkbox">
                <label for="ckBracket">Bracket (10)</label>
              </div>
              <div class="facets__ck form-checkbox form-checkbox--white">
                <input id="ckDropEli" name="ckDropEli" type="checkbox">
                <label for="ckDropEli">Drop Eli (13)</label>
              </div>
              <div class="facets__ck form-checkbox form-checkbox--white form-checkbox--image">
                <input id="ckOilRubbedBronze" name="ckOilRubbedBronze" type="checkbox">
                <label for="ckOilRubbedBronze"><img src="./images/styleguide/oil-rubbed-bronze.png" alt="" aria-hidden="true">Oil Rubbed Bronze (13)</label>
              </div>
            </div>
            <div class="facets__select-more-filters form-select--blue form-select--blue-no-border">
              <select name="moreFilters" class="form-select">
                <option disabled="disabled" selected="selected">More Filters</option>
                <option>Most Popular</option>
                <option>Customer Review</option>
                <option>Price: Low to High</option>
                <option>Price: High to Low</option>
              </select>
            </div>
            <div class="facets__bottom-filters">
              <a href="javascript:void(0);" class="facets__remove-filter link">Rainshower</a>
              <a href="javascript:void(0);" class="facets__remove-filter link">1.5 GPM (5.7 L/min) max.</a>
              <a href="javascript:void(0);" class="facets__remove-filter link">Arm Diverter</a>
              <a href="javascript:void(0);" class="facets__remove-filter link">up to 500</a>
              <a href="javascript:void(0);" class="facets__remove-filter link">AB1953/S152/HB372</a>
              <button class="facets__clear-all button button--default-border">Clear All Filter</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

Styleguide 4.15.2
*/
@mixin filtersDesktopPos() {
  top: 66px; // It change with JS
  width: 100%;
}

@include block('facets') {
  font-size: 1rem;
  position: relative;
  z-index: z("facets");

  // Elements

  @include element('form') {
    position: relative;
  }
  @include element('container') {
    @include outer-container();
    display: flex;
    flex-direction: column;
    padding: 3px 0;
    position: relative;
    z-index: 1;
    & .facets__slider-container > * {
      margin-bottom: .3em;
      order: 0;
    }
    @include media ($bp-lg) {
      flex-direction: row;
      & .facets__slider-container > * {
        margin-bottom: 0;
      }
    }
  }

  @include element('main') {
    background: $c_facets_bg;
    text-align: center;
    padding: 1em .5em;
    display: flex;
    justify-content: flex-start;
    align-items: strech;
    & > * {
      margin-right: .5em;
    }
    & > :last-child {
      margin-right: 0;
    }
    @include media ($bp-lg) {
      justify-content: center;
    }
  }

  @include element('bottom-filters') {
    display: none;
    @include media($bp-lg) {
      border-top: 1px solid $c_facet_borders;
      display: block;
      width: 100%;
      padding-top: .5em;
      position: absolute;
      right: 0;
      bottom: 0;
      text-align: right;
      & > * {
        display: inline-block !important;
        vertical-align: middle;
      }
    }
  }

  @include element('select-sort') {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    .form-select {
      width: 100%;
      height: 100%;
      option {
        background-color: $c_select_sort_bg;
        color: $c_text-dark;
      }
    }
    @-moz-document url-prefix() {
      .form-select {
        height: 32px;
        padding: 5px;
      }
    }
    @include media ($bp-sm) {
      display: flex;
      margin-bottom: 0;
      flex-basis: 30%;
    }
    @include media ($bp-lg) {
      flex-basis: 300px;
    }
  }

  @include element('select-more-filters') {
    border-color: $c_button_filters_bg;
    line-height: 1.3;
    min-width: 120px;
    &:after {
      border-top-color: $c_button_filters_bg;
    }
    .form-select {
      background-color: $c_facet_gray;
      color: $c_button_filters_bg;
      height: 32px;
      padding: .4em 1em .5em;
      @-moz-document url-prefix() {
        padding: .3em 1em .6em;
      }
    }
    @include media ($bp-lg) {
      display: none;
    }
  }


  @include element('main-buttons') {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include media ($bp-sm) {
      flex-basis: 70%;
      & > * {
        margin-right: .5em;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    @include media ($bp-lg) {
      margin-right: 0;
      flex-basis: auto;
    }
  }

  @include element('button') {
    font-family: $din-regular;
    color: $c_button_text_blue;
    background-color: $c_facet_gray;
    border-color: $c_button_filters_bg;
    font-family: $din-regular;

    &:not(.facets__clear-all) {
      width: 100%;
    }
    &:active,
    &:focus,
    &:hover {
      background-color: $c_button_filters_bg;
      color: $c_white;
      opacity: 1;
      &:after {
        border-top-color: $c_white;
      }
    }
    &:after {
      border-top-color: $c_button_filters_bg;
    }
    // select all but the first 4
    &:not(:nth-of-type(-n+4)) {
      display: none;
    }

    // overriding button and modifiers styles
    &.button,
    &.button:after {
      transition: transform .1s ease-in-out,
                  background .1s ease-in-out;
    }

    &.button--default-border {
      vertical-align: middle;
      position: relative;
      &.is-active,
      &:active,
      &:focus,
      &:hover {
        color: $c_white;
        opacity: .9;
        text-decoration: none !important;
      }
      p:hover {
        color: $c_white;
        text-decoration: none !important;
      }
    }

    &.button--down-arrow {
      @include media($bp-lg) {
        &:hover,
        &:active {
          color: $c_button_text_blue;
          background-color: $c_button_filters_bg_hover;
          .tooltip-info {
            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%22150%22%20height%3D%22150%22%20viewBox%3D%220%200%20150%20150%22%3E%3Cstyle%20type%3D%22text/css%22%3E%20%20%0A%09.st0%7Bfill%3A%230090D7%3B%7D%0A%3C/style%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M100%20121.9v-15.6c0-0.9-0.3-1.7-0.9-2.2s-1.3-0.9-2.2-0.9h-9.4v-50c0-0.9-0.3-1.7-0.9-2.2S85.3%2050%2084.4%2050H53.1c-0.9%200-1.7%200.3-2.2%200.9S50%2052.2%2050%2053.1v15.6c0%200.9%200.3%201.7%200.9%202.2s1.3%200.9%202.2%200.9h9.4v31.2h-9.4c-0.9%200-1.7%200.3-2.2%200.9s-0.9%201.3-0.9%202.2v15.6c0%200.9%200.3%201.7%200.9%202.2s1.3%200.9%202.2%200.9h43.8c0.9%200%201.7-0.3%202.2-0.9S100%20122.8%20100%20121.9zM87.5%2034.4V18.8c0-0.9-0.3-1.7-0.9-2.2s-1.3-0.9-2.2-0.9H65.6c-0.9%200-1.7%200.3-2.2%200.9s-0.9%201.3-0.9%202.2v15.6c0%200.9%200.3%201.7%200.9%202.2s1.3%200.9%202.2%200.9h18.8c0.9%200%201.7-0.3%202.2-0.9S87.5%2035.3%2087.5%2034.4zM150%2075c0%2013.6-3.4%2026.2-10.1%2037.6s-15.8%2020.6-27.3%2027.3S88.6%20150%2075%20150s-26.2-3.4-37.6-10.1%20-20.6-15.8-27.3-27.3S0%2088.6%200%2075s3.4-26.2%2010.1-37.6%2015.8-20.6%2027.3-27.3S61.4%200%2075%200s26.2%203.4%2037.6%2010.1%2020.6%2015.8%2027.3%2027.3S150%2061.4%20150%2075z%22/%3E%3C/svg%3E');
          }
        }
      }
      &.is-active {
        color: $c_white;
        background-color: $c_button_filters_bg;
        &:after {
          border-top-color: $c_white;
          transform: rotate(180deg);
          top: 45%;
        }
      }
    }

    @include media ($bp-lg) {
      border: 0;
      border-bottom: 3px solid $c_button_filters_bg;
      padding-right: 1em !important;
      width: inherit;
      &:after {
        display: none;
      }
      // select all but the first 4
      &:not(:nth-child(-n+4)) {
        display: inline-block;
      }
    }
  }

  @include element('remove-filter') {
    display: none;
    @include media($bp-lg) {
      position: relative;
      padding-right: .5em;
      &:last-child {
        padding-right: 1em;
      }
      &:after {
        display: inline-block;
        content: 'X';
        font-size: .5em;
        vertical-align: top;
        text-decoration: none;
        margin-left: .5em;
      }
    }
  }

  @include element('button-apply-filter') {
    background-color: #fff;
    border: 0;
    color: #b27932;
    padding: .5em .5em;
    &:active{
      background-color: transparent;
    }
    &:focus{
      background-color: transparent;
    }
    &:hover{
      background-color: transparent;
    }

    @include media ($bp-sm) {
      display: flex;
    }
    @include media ($bp-lg) {
      //display: none;
    }
  }

  @include element('button-filters') {
    background-color: $c_button_filters_bg;
    width: 100%;
    & > span:last-child {
      display: none;
    }
    & > span:first-child {
      display: block;
    }
    &:active,
    &:focus,
    &:hover {
      background-color: $c_button_filters_bg;
    }
    &.is-active {
      background-color: $c_button_filters_bg;
      & > span:last-child {
        display: block;
      }
      & > span:first-child {
        display: none;
      }
    }
    @include media ($bp-sm) {
      width: auto;
    }
  }

  @include element('button-close') {
    background-color: $c_button_filters_bg;
    display: none;
    margin: 0px;
  }

  @include element('clear-all') {
    background-color: transparent;
    border-color: #b27932;
    color: #b27932;
    vertical-align: middle;
    @include media ($bp-sm) {
      display: flex;
    }
  }

  @include element('button-more-filters') {
    display: none;
    @include media ($bp-lg) {
      display: inline-flex;
    }
  }

  @include element('inner') {
    background: $c_facet_gray;
    @include media ($bp-lg) {
      padding-bottom: 1em;
      width: 100%;
    }
  }

  @include element('lists') {
    .facets__list.is-active,
    .facets__list {
      display: none;
    }

    @include media($bp-lg) {
      .facets__list.is-active {
        display: flex;
      }
    }
  }

  @include element('list') {
    display: none;
    flex-direction: column;
    flex-wrap: nowrap;
    line-height: 1.5em;
    overflow: hidden;
    max-height: 240px;
    padding: .5em 1em;
    @include state(active) {
      display: flex;
      align-content: flex-start;
      @include media ($bp-lg) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;
        .facets__ck {
          width: 100%;
        }
      }
    }
  }

  @include element('ck') {
    border: 0;
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    font-size: rem(14px);
    height: 40px;
    padding-right: 30px;
    justify-content: flex-start;
    align-items: center;
  }

  @include element('filters') {
    display: none;
    @include state('active') {
      display: block;
      position: absolute;
      top: 66px;
      right: 0;
      width: 300px;
      @include media ($bp-lg) {
        position: relative;
        top: 0;
      }
    }
    .facets__button {
      text-align: left;
    }
    .facets__inner {
      .facets__button-apply-filter,
      .facets__clear-all {
        display: inherit;
      }
      .facets__clear-all {
        order: 1;
      }
    }

    @include media ($bp-sm) {
      &.is-active {
        top: 66px;
      }
      .facets__inner {
        .facets__button-apply-filter,
        .facets__clear-all {
          display: none;
        }
      }
    }

    @include media ($bp-lg) {
      @include filtersDesktopPos();
      position: relative;
      padding: 0;
      &.is-active {
        @include filtersDesktopPos();
        top: 0;
        display: flex;
        .facets__button {
          margin: .5em;
        }
      }
      .facets__button,
      .facets__select-more-filters {
        margin: 10px 0;
        width: auto;
      }
      .facets__inner {
        .facets__button-close {
          display: flex;
        }
        .facets__button-close {
          margin-left: auto;
          margin-top: 10px;
        }
      }
      .facets__list {
        background-color: transparent;
        left: 0;
        position: absolute;
        top: 54px;
        width: 100%;
      }
      .facets__container {
        display: flex;
        height: 200px; // It changes with JS
        justify-content: center;
        align-items: flex-start;
      }
      .facets__inner {
        .facets__button-apply-filter {
          display: block;
          position: absolute;
          bottom: -10px;
          right: 0;
        }
      }
    }
  }

  // Filters Counter
  @include element('filter-counter') {
    @include media($bp-md) {
      display: none;
    }
  }

  /**
  * Facets Slider
  */
  @include element('slider') {
    font-size: 14px;
    @include media($bp-lg) {
      max-height: 70px;
      overflow-y: hidden;
      padding: 0 150px;
      width: 100%;
    }

    // Overriding slick styles
    .slick-slider {
      padding: 0 .5em;
    }

    .slick-list {
      .facets__button {
        font-size: 1em;
        text-align: center;
        width: auto;
      }
    }

    .slick-disabled {
      display: none !important;
    }

    .slick-next,
    .slick-prev {
      background-size: 20%;
      color: $c_link_color;
      font-size: 1em;
      height: 30px;
      line-height: 1;
      overflow: hidden;
      text-align: center;
      width: 75px;
      & > span {
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        padding: 0 .25em;
      }
    }

    .slick-next {
      background-image: url(./images/icon-angle_right_blue.svg);
      background-position: right center;
      right: -135px;
      & > span {
        left: 0;
      }
    }

    .slick-prev {
      background-image: url(./images/icon-angle_left_blue.svg);
      background-position: left center;
      left: -135px;
      & > span {
        right: 0;
      }
    }
  }

  @include element('slider-container') {
    @include media($bp-md) {
      & .facets__button + .facets__list,
      & .facets__button + .facets__list.is-active {
        display: none;
      }
    }
  }

  .tooltip__modal-info {
    position: absolute !important; // NOTE: this position absolute overrides popper position setting when it changes it to fixed
  }
}



.facets-stickynav {
  @include state('stick') {
    @include media($bp-md) {
      z-index: z("banners") + 1;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}

.moen_plp_fliter_mb_title_tags{
  margin: 0 15px;
  margin-top: 0 !important;
  margin-bottom: 1em;
  display: none;
}
.moen_plp_fliter_title_tags{
  display: none;
  align-items: center;
  column-gap: 6px;
  flex-wrap: wrap;
  row-gap: 6px;
  margin-top:15px;


  &_item{
    background: #F5F5F5;
    color: #888888;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    column-gap: 4px;
    font-weight: 600;
    cursor: pointer;
    .title{
      font-weight: 500;
    }
  }
}


.moen_plp{
  &_box{
    display: flex;
  }
  &_fliter{
    &_box{
      width: 22%;
      margin-right: 3%;
      display: block;
      margin-left: 0;
      padding: 0;
    }
    &_title{
      border-bottom: 1px solid #eee;
      padding-bottom: 15px;


      &_heard{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }


      &_left{
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 1px;
      }
      &_right{
        color: #b27932;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
    &_attr{

      border-bottom: 1px solid #eee;

     &_more{
       cursor: pointer;
       color: #b27932;
       padding: 0 0 10px;
       display: none;
     }
     &_hide{
       cursor: pointer;
       color: #b27932;
       padding: 0 0 10px;
       display: none;
     }

      &_item{
        display: flex;
        align-items: center;
        margin: 10px 0 10px 0;
         &_checked{
           width: 18px;
           height: 18px;
           background: red;
           margin-right: 10px;
         }
      }


      &_title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        cursor: pointer;
        letter-spacing: 1px;
        font-size: 15px;

        .icon_fold{
          width: 12px;
          height: 12px;
          background-size: cover;
          background-image: url('https://www.moen.cn/resources/asset/image/DN2goGXP6rr4Td6zxpfoYf/300px@1x/icon_fold.png?q=90');
          background-repeat:no-repeat ;
        }

        .icon_unfold{
          width: 15px;
          height: 2px;
          background-size: cover;
          background-image: url('https://www.moen.cn/resources/asset/image/Vw9FhYEjtRGzgffWQqiheQ/300px@1x/icon_unfold.png?q=90');
          background-repeat:no-repeat ;
        }

      }

      &_right{
         color: #b27932;
         cursor: pointer;
      }
    }
  }
  &_content{
    &_box{
      width: 75%;
    }
  }
}

.moen_plp_box  .form-checkbox--white label{
  letter-spacing: 1px;
  font-size: 14px;
}
.moen_plp_box  .form-checkbox--white label img{
   width:22px;
  position: relative;
  top: -2px;
  margin: 0 2px;
}
.moen_plp_box .form-checkbox--white [type=checkbox]:checked+label:after,.cbp-spmenu-content-box .form-checkbox--white [type=checkbox]:checked+label:after  {
  content: "";
  height: 14px;
  position: absolute;
  top: 2px;
  left: 0;
  width: 14px;
  background: url(./images/icon-checkmark_white.svg) #b27932 no-repeat;
}

.heightAuto{
  max-height: fit-content;
}
.moen_plp_results__header{
  align-items: center;
  border-bottom:0;
  max-width: 1300px;
  margin:  0 auto;
  padding: 1.5em 0;
  padding-bottom: 1em;
  &_box{
    border-bottom: 1px solid #d4d4d4;
  }
  .facets__select-sort{
    background: transparent;
    width: 170px;
    padding: 5px 8px;
    font-size: 12px;
    border-color: #b3b3b3;
  }

  .form-select{
    background: transparent;
    color: #333;
    font-size: 14px !important;
    border: none;
    outline: none; /* 去除点击时出现的轮廓 */
    -webkit-appearance: none; /* 针对某些浏览器需要隐藏默认样式*/
    -moz-appearance: none; /* 针对Firefox */
    appearance: none; /* 针对Chrome、Safari和其他现代浏览器 */
  }
  .form-select--blue-border:after{
    border-top: .3125em solid #000;
    right: .8em;
  }
}

#js_m_moen_plp_attr_title{
  display: none;
}
.m_results__header--range_box .results__header--range{
  display: none;
}


@media only screen and (max-width: 768px) {

  #js_m_moen_plp_attr_title{
    display: block;
    font-size: 15px;
    color: #5c5c5c;
    cursor: pointer;
  }

  .moen_plp_fliter_box{
      display: none;
  }
  .moen_plp_content_box{
      width: 100%;
  }
  .moen_plp_fliter_mb_title_tags{
    display: flex;
  }
  .moen_plp_results__header{
    margin: 0 15px;
  }
  .moen_plp_fliter_attr_title{
    padding: 25px 20px;
  }
  .facets__list{
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 0 0 1.5em;
  }
  .facets__ck{
    width: 100%;
    font-size: 1rem;
    padding-right: 0;
  }
  .moen_plp_fliter_attr_left{
    font-size: 16px;
    letter-spacing: 1px;
  }
  .results__header--range{
    display: none;
  }

  .m_results__header--range_box  .results__header--range{
    display: block;
    font-size: 14px;
    text-align: right;
    padding: 15px 15px 5px 0;
  }

  .facets__ck:last-child{
    height: 40px;
  }

  .cbp-spmenu-content-box .form-checkbox--white [type=checkbox]:checked+label:before,  .cbp-spmenu-content-box .form-checkbox--white [type=checkbox]:not(:checked)+label:before{
     width: 18px;
     height: 18px;

  }

 .cbp-spmenu-content-box .form-checkbox--white [type=checkbox]:checked+label:after, .cbp-spmenu-content-box .form-checkbox--white [type=checkbox]:not(:checked)+label:after{
   width: 18px;
   height: 18px;
  }

  .cbp-spmenu-content-box .form-checkbox--white label{
     line-height: 20px;
    letter-spacing: 1px;
    padding-left: 1.8em;
  }




  .moen_plp_fliter_attr_hide, .moen_plp_fliter_attr_more{
    margin-left: 20px;
    display: none;
  }
}



/* General styles for all menus */

.cbp-spmenu {
  background: #fff;
  position: fixed;
}
.cbp-spmenu-colse{
  cursor: pointer;

}
.cbp-spmenu h3 {
  color: #afdefa;
  font-size: 1.9em;
  padding: 20px;
  margin: 0;
  font-weight: 300;
  background: #0d77b6;
}

.cbp-spmenu a {
  display: block;
  color: #333;
  font-size: 1.1em;
  font-weight: 300;
}

.cbp-spmenu a:hover {
  background: #258ecd;
}

.cbp-spmenu a:active {
  background: #afdefa;
  color: #47a3da;
}

/* Orientation-dependent styles for the content of the menu */

.cbp-spmenu-vertical {
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
}

.cbp-spmenu-vertical a {
  border-bottom: 1px solid #258ecd;
  padding: 1em;
}

.cbp-spmenu-horizontal {
  width: 100%;
  height: 150px;
  left: 0;
  z-index: 1000;
  overflow: hidden;
}

.cbp-spmenu-horizontal h3 {
  height: 100%;
  width: 20%;
  float: left;
}

.cbp-spmenu-horizontal a {
  float: left;
  width: 20%;
  padding: 0.8em;
  border-left: 1px solid #258ecd;
}

/* Vertical menu that slides from the left or right */

.cbp-spmenu-left {
  left: -100%;
}

.cbp-spmenu-right {
  right: -100%;
}

 .cbp-spmenu-content-box{
   margin: 65px 0 100px;
 }

.cbp-spmenu-left.cbp-spmenu-open {
  left: 0px;
  overflow: auto;

}

.cbp-spmenu-right.cbp-spmenu-open {
  right: 0px;
}

/* Horizontal menu that slides from the top or bottom */

.cbp-spmenu-top {
  top: -150px;
}

.cbp-spmenu-bottom {
  bottom: -150px;
}

.cbp-spmenu-top.cbp-spmenu-open {
  top: 0px;
}

.cbp-spmenu-bottom.cbp-spmenu-open {
  bottom: 0px;
}

/* Push classes applied to the body */

.cbp-spmenu-push {
  overflow-x: hidden;
  position: relative;
  left: 0;
}

.cbp-spmenu-push-toright {
  left: 240px;
}

.cbp-spmenu-push-toleft {
  left: -240px;
}

/* Transitions */

.cbp-spmenu,
.cbp-spmenu-push {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}


/* Example media queries */

@media screen and (max-width: 55.1875em){

  .cbp-spmenu-horizontal {
    font-size: 75%;
    height: 110px;
  }

  .cbp-spmenu-top {
    top: -110px;
  }

  .cbp-spmenu-bottom {
    bottom: -110px;
  }

}

.cbp-spmenu-footer{
  display: flex;
  width: 100%;
  background: #fff;
  align-items: center;
  padding: 20px 0;
  text-align: center;
  box-shadow: -3px -3px 10px 0px #eaeaea;
  position: fixed;
  bottom: 0;
  justify-content: space-evenly;
  &>div{
    width: 40%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    letter-spacing: 2px;
    font-size: 15px;
    padding: 13px 0;
    color: #333;
    cursor: pointer;
    justify-content: center;
  }
  &>button{
    width: 40%;
    background: #5b7f95;
    color: #fff;
    padding: 15px 0;
    border-radius: 4px;
    letter-spacing: 2px;
    font-size: 15px;
    cursor: pointer;
    justify-content: center;
  }

}




.cbp-spmenu-footer .facets__button-apply-filter:active, .cbp-spmenu-footer .facets__button-apply-filter:focus, .cbp-spmenu-footer .facets__button-apply-filter:hover{
  background-color:#5b7f95;
}

@media screen and (max-height: 26.375em){

  .cbp-spmenu-vertical {
    font-size: 90%;
    width: 190px;
  }

  .cbp-spmenu-left,
  .cbp-spmenu-push-toleft {
    left: -190px;
  }

  .cbp-spmenu-right {
    right: -190px;
  }

  .cbp-spmenu-push-toright {
    left: 190px;
  }
}

.cbp-spmenu-title{

  font-size: 18px;
  font-weight: 600;
  color: #333;
  letter-spacing: 1px;

  &-box{
    position: fixed;
    background: #fff;
    width: 100%;
    display: flex;
    align-content: center;
    padding: 20px 20px 20px;
    justify-content: space-between;
    box-shadow: 1px 1px 9px #eee;
    z-index: 2;
  }

}


