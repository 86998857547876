/**
@doc _docs/navigation-category.md
@section Category Navigation
@childof Navigation
@page Navigation Category Navigation
@status in progress
@hippo navigation
@example
_markup/navigation-category.hbs
*/
@include plmr-component('navigation-category') {
  background-color: plmr-color('background', 'secondary');

  @include plmr-mq('md') {
    background-color: transparent;
  }

  //.plmr-c-navigation-category__container
  @include element('container') {
    padding: 0;

    @include plmr-mq('md') {
      min-width: 0;
      // padding: 76px 30px 0 25px;
    }
  }

  //.plmr-c-navigation-category__toggle
  @include element('toggle') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'secondary');
    cursor: pointer;
    display: block;
    font-size: 1.143rem;
    line-height: 18px;
    min-width: 100%;
    padding: 24px 25px;
    position: relative;
    text-align: left;

    @include plmr-mq('md') {
      display: none;
    }
  }

  //.plmr-c-navigation-category__toggle-icon
  @include element('toggle-icon') {
    margin-left: 25px;
    position: relative;

    //.plmr-c-navigation-category__toggle-icon::before
    &::before {
      @include plmr-pseudo;
      background: currentColor;
      height: 1px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 13px;
    }

    //.plmr-c-navigation-category__toggle-icon::after
    &::after {
      @include plmr-pseudo;
      background: currentColor;
      height: 13px;
      right: 0;
      top: 50%;
      transform: translate(-6px, -50%);
      transition: 0.25s ease-in-out;
      width: 1px;
    }

    //.plmr-c-navigation-category__toggle-icon._toggled-open
    @include plmr-state('toggled-open') {

      //.plmr-c-navigation-category__toggle-icon._open::after
      &::after {
        transform: translate(-6px, -50%) rotate(90deg);
      }
    }
  }

  //.plmr-c-navigation-category__heading
  @include element('heading') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'secondary');
    display: none;
    font-size: 1.143rem;
    line-height: 18px;
    padding: 24px 25px;
    text-align: left;

    @include plmr-mq('md') {
      border-bottom: 1px solid plmr-color('accent', 'light');
      border-style: solid;
      display: block;
      font-size: 1.286rem;
      padding: 0 0 32px;
    }
  }

  //.plmr-c-navigation-category__items
  @include element('items') {
    color: darken(plmr-color('text', 'secondary'), 8%);
    font-size: 1.143rem;
    line-height: 24px;
    max-height: 0;
    overflow: hidden;
    transition: 0.25s ease;

    @include plmr-mq('md') {
      max-height: none;
    }

    //.plmr-c-navigation-category__items._open
    @include plmr-state('open') {
      max-height: 500px;
    }
  }

  //.plmr-c-navigation-category__list
  @include element('list') {
    list-style: none;
    padding: 0 25px 5px;

    @include plmr-mq(md) {
      padding: 18px 0 0;
    }
  }

  //.plmr-c-navigation-category__list-item
  @include element('list-item') {
    margin-bottom: 25px;

    @include plmr-mq('md') {
      margin-bottom: 30px;
    }
  }

  //.plmr-c-navigation-category__list-button
  @include element('list-button') {
    cursor: pointer;
    text-align: left;

    //.plmr-c-navigation-category__list-button._active
    @include plmr-state('active') {
      @include plmr-font-face($family: 'bold');
    }
  }
}

