/**
@doc _docs/general-content.md
@section General Content
@childof Content
@page Content General Content
@status in progress
@hippo simple document
@example
_markup/general-content.hbs
*/
@include plmr-component('general-content') {
  @include plmr-font-face($family: 'regular');
  color: plmr-color('text', 'primary');
  font-size: 1.143rem;
  line-height: 1.714rem;
  //left and right padding to be moved to banner tabs variant
  // padding: 30px 25px 0;

  @include plmr-mq('md') {
    // padding: 34px 25px 0 31px;
  }

  @include plmr-mq('lg') {
    // padding-left: calc(27.5 / 975 * 100%);
    // padding-right: calc(166 / 975 * 100%);
    // padding-top: 52px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    margin-bottom: 20px;
    margin-top: 0;
  }

  h5,
  h6 {
    color: plmr-color('text', 'secondary');
  }

  h1 {
    font-size: 2rem;
    line-height: 2.571rem;

    @include plmr-mq('md') {
      font-size: 2.571rem;
      line-height: 3.428rem;
    }
  }

  h2 {
    font-size: 1.714rem;
    line-height: 2.286rem;

    @include plmr-mq('md') {
      font-size: 2.286rem;
      line-height: 2.571rem;
    }
  }

  h3 {
    font-size: 1.714rem;
    line-height: 2.286rem;

    @include plmr-mq('md') {
      font-size: 2rem;
      line-height: 2.286rem;
    }
  }

  h4 {
    @include plmr-font-face($family: 'regular');
    font-size: 1.286rem;
    line-height: 1.714rem;

    @include plmr-mq('md') {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  h5 {
    @include plmr-font-face($family: 'italic');
    font-size: 1.143rem;
    line-height: 1.714rem;

    @include plmr-mq('md') {
      font-size: 1.286rem;
    }
  }

  h6 {
    @include plmr-font-face($family: 'bold');
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1;
    text-transform: uppercase;
  }

  img {
    @extend .plmr-u-img--responsive;
    display: block;
    margin: 25px 0;

    &.plmr-u-img--left {
      margin: 0 25px 0 0;
    }

    &.plmr-u-img--right {
      margin: 0 0 0 25px;
    }
  }

  p {
    margin-bottom: 2rem;
    padding-bottom: 0;

    strong,
    b {
      color: inherit;
    }
  }

  strong,
  b {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
  }

  ul {
    list-style: none;
    margin: 25px 0;

    strong,
    b {
      color: inherit;
    }

    li {
      padding-left: 2rem;
      position: relative;

      &::before {
        @include plmr-pseudo($content: '\25CF');
        font-size: 1rem;
        left: 0;
        top: 0;
      }
    }
  }
}
