/**
@doc _docs/cardlist-thumbnail.md
@section Card List Thumbnail
@childof Card Lists
@page Cardlists Cardlist Thumbnail
@status ready
@hippo card-list
@example
_markup/cardlist-thumbnail.hbs
*/
@include plmr-component('cardlist-thumbnail') {
  @extend .plmr-l-container;
  padding: 0;

  .slick-track {
    padding-bottom: 25px;
  }

  // .plmr-c-cardlist-thumbnail__cards
  @include element('cards') {
    @include plmr-mq(lg) {
      align-items: flex-start;
      display: flex;
      justify-content: center;
    }
  }

  // .plmr-c-cardlist-thumbnail__card
  @include element('card') {
    background-color: plmr-color('base', 'white');
    box-shadow: -10px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
    margin-right: 25px;
    min-height: 400px;
    position: relative;
    width: 275px;
    z-index: 2;

    // .plmr-c-cardlist-thumbnail__card:hover
    &:hover {
      cursor: pointer;
    }

    @include plmr-mq(md) {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 150px;
      margin: 0 auto;
      min-height: 0;
      width: 483px;

      & ~ & {
        margin-top: 30px;
      }
    }

    @include plmr-mq(lg) {
      margin: 0 25px;

      & ~ & {
        margin-top: 0;
      }
    }
  }

  // .plmr-c-cardlist-thumbnail__card-content
  @include element('card-content') {
    padding: 20px 30px;

    @include plmr-mq(md) {
      flex: 1 0 calc(100% - 150px);
      padding: 0 30px;
    }
  }

  // .plmr-c-cardlist-thumbnail__card-image
  @include element('card-image') {
    height: 172px;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include plmr-mq(md) {
      flex: 1 0 150px;
      height: 100%;
    }

    // .plmr-c-cardlist-thumbnail__card-image--src
    @include modifier('src') {
      height: auto;
      left: 50%;
      max-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;
    }
  }

  // .plmr-c-cardlist-thumbnail__card-title
  @include element('card-title') {
    @include plmr-font-face($family: 'bold');
    @include plmr-link($color: plmr-color('brand', 'primary'));
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding: 0;
  }

  // .plmr-c-cardlist-thumbnail__card-link
  @include element('card-link') {
    padding: 0;
    padding-top: 25px;

    @include plmr-mq(md) {
      padding-top: 23px;
    }
  }
}
