/**
See gallery/product listing page for example usage
@section Collection Item
@childof Content
@page Content Collection Item
@status in progress
@example
_markup/collection-item.hbs
*/
@include plmr-component('collection-item') {
  display: inline-block;
  height: auto;
  margin: 10px 0;
  max-width: (plmr-config('container', 'max-width') / 2);
  min-width: plmr-config('container', 'min-width');
  padding: 0 15px;
  position: relative;
  vertical-align: top;
  width: 100%;

  @include plmr-mq(md) {
    margin: 15px 0;
    padding: 0 31px;
    width: 50%;
  }

  @include plmr-mq(lg) {
    margin: 27.5px 0;
    padding: 0 27.5px;
  }

  @include element('inner') {
    min-height: 390px;
    padding-bottom: 30px;

    @include plmr-mq(md) {
      min-height: 471px;
      padding-bottom: 0;
    }

    @include plmr-mq(lg) {
      min-height: 445px;
    }
  }

  // .plmr-c-collection-item__images
  @include element('images') {
    display: block;
    height: auto;
    padding-top: 73.33%;
    position: relative;
    width: 100%;
    z-index: 1;

    @include plmr-mq(md) {
      padding-top: 80.49%;
    }
  }

  // .plmr-c-collection-item__media
  @include element('media') {
    height: auto;
    left: 0;
    min-height: 92.09%;
    min-width: 91.3%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: auto;

    @include plmr-mq(md) {
      min-width: 100%;
    }

    // .plmr-c-collection-item__media--small
    @include modifier('small') {
      bottom: 0;
      box-shadow: -6px 6px 13px 0 rgba(plmr-color('base', 'black'), 0.15);
      left: auto;
      min-height: 50.75%;
      min-width: 27.54%;
      padding-top: 0;
      right: 0;
      top: auto;
      z-index: 2;

      @include plmr-mq(md) {
        bottom: -111px;
        box-shadow: -10px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
        min-height: 56.82%;
        min-width: 33.54%;
        right: 15px;
      }

      @include plmr-mq(lg) {
        bottom: -55px;
        min-height: 46.15%;
        min-width: 27.23%;
        right: 25px;
      }
    }
  }

  // .plmr-c-collection-item__image
  @include element('image') {
    height: auto;
    left: 50%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.5s ease;
    width: auto;


    @include plmr-mq(lg) {
      &:hover {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }

    // .plmr-c-collection-item__image--knockout
    @include modifier('knockout') {
      left: 0;
      opacity: 0;
      padding: 9% 16% 9% 2%;
      position: relative;
      top: 0;
      transform: none;
      transition: opacity 0.25s ease-in;
      width: 54%;

      // .no-js & {
      //   opacity: 1;
      // }

      // .plmr-c-collection-item__image--knockout._current
      @include plmr-state('current') {
        opacity: 1;
      }

      @include plmr-mq(lg) {
        &:hover {
          transform: none;
        }
      }
    }
  }

  // .plmr-c-collection-item__image-track
  @include element('image-track') {
    background: plmr-color('base', 'white');
    display: flex;
    flex-wrap: nowrap;
    height: auto;
    left: 0;
    max-height: 100%;
    //min-width: 100%;
    min-width: (100% * 2.5);
    position: absolute;
    top: 0;
    transition: left 0.25s linear;
    width: auto;
  }

  // .plmr-c-collection-item__specs
  @include element('specs') {
    position: relative;

    @include plmr-mq(md) {
      min-height: 207px;
      padding-bottom: 100px;
      padding-right: 44.21%;
    }

    @include plmr-mq(lg) {
      min-height: 0;
      padding-bottom: 0;
      padding-right: 0;
    }
  }

  // .plmr-c-collection-item__name
  @include element('name') {
    @include plmr-link($color: plmr-color('brand', 'primary'));
    display: block;
    font-size: 1.143rem;
    line-height: 1.5rem;

    @include plmr-mq(md) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @include plmr-mq(lg) {
      max-width: 61.63%;
    }

    // .plmr-c-collection-item__name--collection
    @include modifier('collection') {
      @include plmr-font-face($family: 'bold');
    }
  }

  // .plmr-c-collection-item__copy
  @include element('copy') {
    display: none;
    line-height: 1.5rem;
    padding-top: 15px;
  }

  // .plmr-c-collection-item__finishes
  @include element('finishes') {
    padding-top: 15px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }
  }

  // .plmr-c-collection-item__buttons
  @include element('buttons') {
    font-size: 0;
    padding-top: 25px;

    @include plmr-mq(md) {
      bottom: 15px;
      height: auto;
      left: 0;
      max-width: 55.79%; // 100% less the spec padding
      position: absolute;
      width: auto;
    }

    @include plmr-mq(lg) {
      bottom: auto;
      left: auto;
      max-width: none;
      position: relative;
    }
  }

  // .plmr-c-collection-item__button
  @include element('button') {
    @extend .plmr-c-button-link, .plmr-c-button-link--tertiary;

    & ~ & {
      margin-left: 40px;
    }

    @include plmr-mq(md) {
      &:not(:last-child) {
        margin-right: 40px;
      }

      & ~ & {
        margin-left: 0;
        margin-top: 20px;
      }
    }

    @include plmr-mq(lg) {
      & ~ & {
        margin-top: 0;
      }
    }
  }
}


/**
See gallery/product listing page for example usage
@section List View
@sectionof Collection Item
@page Content Collection Item
@example
_markup/collection-item-list-view.hbs
*/
@include plmr-component('collection-item') {
  // .plmr-c-collection-item__list-view
  @include modifier('list-view') {
    max-width: plmr-config('container', 'max-width');

    @include plmr-mq(md) {
      font-size: 0;
      margin: 10px 0;
      padding: 0 25px;
      width: 100%;
    }

    @include plmr-mq(lg) {
      margin: 15px 0;
    }

    .plmr-c-collection-item__inner {
      @include plmr-mq(md) {
        font-size: 0;
        min-height: 329px;
      }

      @include plmr-mq(lg) {
        min-height: 325px;
      }
    }

    .plmr-c-collection-item__copy {
      display: block;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .plmr-c-collection-item__images,
    .plmr-c-collection-item__specs {
      @include plmr-mq(md) {
        display: inline-block;
        vertical-align: top;
      }
    }

    .plmr-c-collection-item__images {
      @include plmr-mq(md) {
        padding-top: 36.91%;
        width: calc(100% - ((294 / 718) * 100%)); // specs / inner width
      }

      @include plmr-mq(lg) {
        padding-top: 35.79%;
        width: calc(100% - ((357 / 910) * 100%));
      }
    }

    .plmr-c-collection-item__media {
      @include plmr-mq(md) {
        min-width: 85.85%;
      }

      @include plmr-mq(lg) {
        min-width: (455 / 557 * 100%);
      }

      @include modifier('small') {
        @include plmr-mq(md) {
          bottom: 0;
          min-height: (160 / 265 * 100%);
          min-width: (119 / 424 * 100%);
          right: 0;
        }

        @include plmr-mq(lg) {
          min-height: (200 / 325 * 100%);
          min-width: (149 / 557 * 100%);
        }
      }
    }

    .plmr-c-collection-item__specs {
      @include plmr-mq(md) {
        min-height: 0;
        padding: 0 0 0 30px;
        width: ((294 / 718) * 100%);
      }

      @include plmr-mq(lg) {
        padding: 0 0 0 55px;
        width: ((357 / 912) * 100%);
      }
    }

    .plmr-c-collection-item__buttons {
      bottom: auto;
      left: auto;
      max-width: none;
      position: relative;
    }

    .plmr-c-collection-item__finishes {
      @include plmr-mq(md) {
        padding-top: 25px;
      }
    }
  }
}
