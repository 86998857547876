/*
Basic Collapsible Table

In the basic form, the table data is organized by row. From a mobile-first perspective, the row begins as a vertically arranged grouping with the table heading content repeated for each group. Once the specified minimum width-breakpoint has been reached (moving wider), the table 'uncollapses' and is rendered in the traditional column/row format.

markup:
<table class="table table--responsive">
  <caption>Collapsing Table</caption>
  <thead>
    <tr>
      <th scope="column">Heading 1</th>
      <th scope="column">Heading 2</th>
      <th scope="column">Heading 3</th>
      <th scope="column">Heading 4</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-th="Heading 1">Column 1 Data 1</td>
      <td data-th="Heading 2">Column 2 Data 1</td>
      <td data-th="Heading 3">Column 3 Data 1</td>
      <td data-th="Heading 4">Column 4 Data 1</td>
    </tr>
    <tr>
      <td data-th="Heading 1">Column 1 Data 2</td>
      <td data-th="Heading 2">Column 2 Data 2</td>
      <td data-th="Heading 3">Column 3 Data 2</td>
      <td data-th="Heading 4">Column 4 Data 2</td>
    </tr>
    <tr>
      <td data-th="Heading 1">Column 1 Data 3</td>
      <td data-th="Heading 2">Column 2 Data 3</td>
      <td data-th="Heading 3">Column 3 Data 3</td>
      <td data-th="Heading 4">Column 4 Data 3</td>
    </tr>
    <tr>
      <td data-th="Heading 1">Column 1 Data 4</td>
      <td data-th="Heading 2">Column 2 Data 4</td>
      <td data-th="Heading 3">Column 3 Data 4</td>
      <td data-th="Heading 4">Column 4 Data 4</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td data-th="Heading 1">Column 1 Footer</td>
      <td data-th="Heading 2">Column 2 Footer</td>
      <td data-th="Heading 3">Column 3 Footer</td>
      <td data-th="Heading 4">Column 4 Footer</td>
    </tr>
  </tfoot>
</table>

Styleguide 3.21
*/

@include block(table) {
  width: 100%;
  $cell-padding: .5em !default;

  @include modifier(responsive) {
    margin: 0;
    // min-width: $abs-min-width; //320px

    caption {
      margin: .5em 0;
    }
/*
    tr {
    // leave specific border styling to implementation
      border-top: 1px solid $c_moen-gray;
      border-bottom: 1px solid $c_moen-gray;

    }*/

    thead {
      tr {
        border: none; // ensures a visible artifact doesn't show
      }
    }

    th {
      display: none;
    }

    td {
      display: block;
      padding: $cell-padding 0;

      &:first-child {
        //padding-top: $cell-padding;
        padding: $cell-padding 0;
      }
      &:last-child {
        //padding-bottom: $cell-padding;
        padding: $cell-padding 0;
      }

      &:before {
        content: attr(data-th) ": ";
        font-weight: normal;
        font-family: $din-medium;

        // magic number: adjust on specific implementations
        width: 40%;
        display: inline-block;
        // end options

        @include media($bp-sm) {
          display: none;
        }
      }
    }

    th, td {
      text-align: left;
      vertical-align: top;
      padding: ($cell-padding/2) 0;

      @include media($bp-sm) {
        display: table-cell;
        padding: $cell-padding;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
    th {
      @include media($bp-sm) {
        @include undo-hat-core();
      }
    }
  }
}

/*
Basic Collapsible Table - No heading repeat

In the basic form, the table data is organized by row, but the css-generated heading data is not placed into the row. Markup is the same as above, but with the additional class modifier.

markup:
<table class="table table--responsive table--no-headings">
  <caption>Collapsing Table, No Headings on Small Width</caption>
  <thead>
    <tr>
      <th scope="column">Heading 1</th>
      <th scope="column">Heading 2</th>
      <th scope="column">Heading 3</th>
      <th scope="column">Heading 4</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-th="Heading 1">Column 1 Data 1</td>
      <td data-th="Heading 2">Column 2 Data 1</td>
      <td data-th="Heading 3">Column 3 Data 1</td>
      <td data-th="Heading 4">Column 4 Data 1</td>
    </tr>
    <tr>
      <td data-th="Heading 1">Column 1 Data 2</td>
      <td data-th="Heading 2">Column 2 Data 2</td>
      <td data-th="Heading 3">Column 3 Data 2</td>
      <td data-th="Heading 4">Column 4 Data 2</td>
    </tr>
    <tr>
      <td data-th="Heading 1">Column 1 Data 3</td>
      <td data-th="Heading 2">Column 2 Data 3</td>
      <td data-th="Heading 3">Column 3 Data 3</td>
      <td data-th="Heading 4">Column 4 Data 3</td>
    </tr>
    <tr>
      <td data-th="Heading 1">Column 1 Data 4</td>
      <td data-th="Heading 2">Column 2 Data 4</td>
      <td data-th="Heading 3">Column 3 Data 4</td>
      <td data-th="Heading 4">Column 4 Data 4</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td data-th="Heading 1">Column 1 Footer</td>
      <td data-th="Heading 2">Column 2 Footer</td>
      <td data-th="Heading 3">Column 3 Footer</td>
      <td data-th="Heading 4">Column 4 Footer</td>
    </tr>
  </tfoot>
</table>

Styleguide 3.21.1
*/

@include block(table) {
  width: 100%;
  $cell-padding: .5em !default;

  @include modifier(no-headings) {
    td {
      &:before {
        content: none;
      }
    }
  }
}
