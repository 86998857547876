
// .plmr-c-mastfoot-company-info
@include plmr-component('mastfoot-company-info') {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  order: 1;


  // screen width > 768px
  @include plmr-mq('md') {
    flex-direction: row;
    flex-wrap: wrap;
  }


  // screen width > 992px
  @include plmr-mq('lg') {
    flex-direction: column;
    flex-wrap: nowrap;
  }


  // .plmr-c-mastfoot-company-info__copyright
  @include element('copyright') {
    flex-grow: 1;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    width: calc(100% - 135px);


    // screen width > 992px
    @include plmr-mq('lg') {
      width: 100%;
    }

  }


  // .plmr-c-mastfoot-company-info__copy
  @include element('copy') {
    flex: 1 1 auto;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 30px;
    margin-right: 30px;
    max-width: 450px;


    // screen width > 768px
    @include plmr-mq('md') {

    }

    // screen width > 992px
    @include plmr-mq('lg') {
      flex: 1 0 auto;
      margin-right: 0;
    }

  }


  // .plmr-c-mastfoot-company-info__nav
  @include element('nav') {
    margin-bottom: 47px;


    // screen width > 768px
    @include plmr-mq('md') {
      flex: 1 1 100%;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      flex: 1 0 auto;
    }

  }

}
