/*
Favorite

Markup:
<div class="favorite">
  <span>Add to Favorites</span>
  <a href="#" class="favorite__icon">
    <img src="./images/icon-favorite_o_gray.svg" alt="" aria-hidden="true">
    <img src="./images/icon-favorite_gray.svg" alt="" aria-hidden="true">
  </a>
</div>
<br>
<div class="favorite">
  <span>Add to Favorites</span>
  <a href="#" class="favorite__icon is-active">
    <img src="./images/icon-favorite_o_gray.svg" alt="" aria-hidden="true">
    <img src="./images/icon-favorite_gray.svg" alt="" aria-hidden="true">
  </a>
</div>

Styleguide 3.9
*/

@include block(favorite) {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  @include media($bp-sm) {
    align-items: baseline;
    justify-content: space-between;
    width: auto;
  }

  @include element(icon) {
    display: none;
    margin: 0 .5em;
    height: 1em;
    width: 1em;

    @include child('unliked') {
      display: inline;
    }

    @include child('liked') {
      display: none;
    }

    @include state(active) {
      display: block;

      .favorite__icon__unliked {
        display: none;
      }

      .favorite__icon__liked {
        display: inline;
      }
    }
  }
}

/// Hide Favorites from SearchSpring results because our functionality
/// needs to be refactored to accessible globally
[class*="ss-"] .product-tile__like {
  display: none !important;
}
