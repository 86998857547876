@include block(tap) {
	@include element(icon-menu) {		
		width: 100%;
		height: 75px;
		text-align: center;
		@include element(inner) {
			z-index: 3;
			position: relative;
			background-color: $c_concrete;
			background-color: #EEE;
			width: 100%;
			height: 75px;
			transition: all 0.5s ease;
			&.sticky {
				position: fixed; 
				top:0;
				left:0;
				@media screen and (max-width: $bp-sm-max) {
					height: 60px;
					.tap__icon-menu__toggle {
						padding: 20px 26px 17px 0;
					}
				}
			}
		}
		a {
			display: inline-block;
			font: normal 13px/18px $proxima-bold;
			color: $c_moen-gray;
			text-transform: uppercase;
			letter-spacing: 0.41px;
			vertical-align: middle;
			cursor: pointer;
			text-decoration: none !important;
			transition: color 0.5s ease;
		}
		
		@include element(back) {
			position: absolute;
			margin:0;
			padding:0;
			display: block;
			max-width: 6px;
			height: 12px;
			text-align: left;
			top:50%;
			left: 20px;
			transform: translateY(-50%);
			overflow: hidden;
			a {				
				margin:0;
				padding:0;
				display: inline-block;
				width: auto;
				height: 12px;
				padding-left: 25px;
				font: normal 12px/12px $din-bold !important;
				text-transform: none !important;
				color: $c_text-base;
				vertical-align: top;
				position: relative;
				&:before {
					content:' ';
					display: block;
					position: absolute;
					top:0;
					left:0;
					width: 6px;
					height: 12px;
					background-image: url("images/icon-angle_left_gray.svg");
					background-repeat: no-repeat;
					background-size: auto 10px;
					background-position: center center;
				}
			}
			@include media($bp-md) {
				max-width: 1400px;
				width: 100%;
				right: 20px;
				z-index: 1;
				margin: auto;
			}
		}
		
		@include element(toggle) {
			padding: 28px 26px 24px 0;
			position: relative;
			z-index: 2;
			transition: all 0.5s ease;
			@include media($bp-lg) {
				display: none !important;
			}
			
			img {
				display: none;
				vertical-align: middle;
				margin-bottom: 0;
				margin-right: 10px;
				width: auto;
				height: auto;
				max-width: 23px;
				max-height: 23px;
				line-height: 23px;
				&.pillar-icon-current {
					display: inline-block;
				}
			}
			&:after {
				content:'';
				position: absolute;
				top:50%;
				right: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 5px 5px 5px;
				border-color: transparent transparent $c_moen-gray transparent;
				transform: scale(1,-1) translateY(2.5px);
			}
			&.toggled {
				&:after {
					transform: scale(1,1) translateY(-2.5px);	
				}
			}
		}
		@include element(menu) {
			list-style: none;
			padding:0;
			margin:0;
			display: block;
			position: absolute;
			background-color: $c_concrete;
			width: 100%;
			height: auto;
			max-height: 0px;
			overflow: hidden;
			transition: max-height 0.5s ease, background-color 0.5s ease;
			z-index: 3;
			@include media($bp-lg) {
				background: transparent !important;
			}
			&.active {
				max-height: 300px;
			}
			li {
				display: block;
				padding:15px 0;
				margin:0;
				transition: opacity 0.5s ease;
				a {
					line-height: 23px;
				}
				img {
					display: inline-block;
					vertical-align: middle;
					margin-bottom: 0;
					margin-right: 10px;
					width: auto;
					height: auto;
					max-width: 23px;
					max-height: 23px;
					line-height: 23px;
					&.pillar-icon-white {
						display: none;
					}
					&.pillar-icon-current {
						display: none;
					}
				}
				&:first-child {
					padding-top: 20px;
				}
				&:last-child {
					padding-bottom: 30px;
				}
			}
			@include media($bp-md) {
				li {
					display: inline-block;
					vertical-align: middle;
					padding: 27px 0 24px !important;
					margin-right: 52px;
					&:last-child {
						margin-right: 0;
					}
				}
			}
			@include media($bp-lg) {
				position: relative;
				max-height: 1000px;
				background-color: transparent;
				pointer-events: none;
				li,a {
					pointer-events: auto;
				}
				&:hover {
					li {
						opacity: 0.5;
					}
				}
				li:hover {
					opacity: 1 !important;
				}
			}
			@include media($bp-xl) {
				li {
					margin-right: 75px;
					a {
						font-size: 15px;
					}
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
		
		@include element(search) {
			position: absolute;
			top: 0;
			left:0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			display: none !important; // HIDE UNTIL WE ENABLE SEARCH
			@include element(icon) {
				pointer-events: auto;
				position: absolute;
				top:50%;
				right:20px;
				transform: translateY(-50%);
				@include media($bp-md) {
					right:30px;
				}@include media($bp-xl) {
					right:60px;
				}
				svg {
					fill: $c_moen-bright-blue;
					path {
						fill: $c_moen-bright-blue;
					}
				}
			}
			@include element(field) {
				pointer-events: auto;
			}
		}
		
		@include modifier(default) {
			.tap__icon-menu__inner.sticky {
				background-color: $c_moen-bright-blue;
				.tap__icon-menu__toggle {
					color: #FFF;
					&:after {
						border-color: transparent transparent #FFF transparent;
					}
				}
				.tap__icon-menu__menu {
					background-color: $c_moen-bright-blue;
					li img {
						display: none;
						&.pillar-icon-white {
							display: inline-block;
						}
					}
				}
				.tap__icon-menu__search__icon svg {
					fill:#FFF;
					path {
						fill:#FFF;
					}
				}
				a {
					color: #FFF;
				}
			}
			
			// Default page has a different break point than others because it doesn't have a back link
			.tap__icon-menu__toggle {
				
				@include media($bp-md) {
					display: none !important;
				}
			}
			.tap__icon-menu__menu {
				@include media($bp-md) {
					position: static;
					max-height: 1000px;
				}
			}
			// No back link on default because default is the overview page
			.tap__icon-menu__back {
				display: none !important;
			}
			
			
		}

		@include modifier(inspiration) {
			.tap__icon-menu__menu {
				a.current {
					color: $c_moen-maroon;
					img {
						display: none;
						&.pillar-icon-current {
							display: inline-block;
						}
					}
				}	
			}
			.tap__icon-menu__toggle {
				color: $c_moen-maroon;	
			}
			.tap__icon-menu__search__icon svg {
				fill: $c_moen-maroon;
				path {
					fill: $c_moen-maroon;
				}
			}
			.tap__icon-menu__inner.sticky {
				background-color: $c_moen-maroon;
				.tap__icon-menu__back {
					a:before {
						background-image: url('images/icon-angle_right_white.svg');
						transform: scale(-1,1);
					}
				}
				.tap__icon-menu__toggle {
					color: #FFF;
					&:after {
						border-color: transparent transparent #FFF transparent;
					}
					img {
						display: none;
						&.pillar-icon-white {
							display: inline-block;
						}
						&.pillar-icon-current {
							display: none;
						}
					}
				}
				.tap__icon-menu__menu {
					background-color: $c_moen-maroon;
					li img {
						display: none;
						&.pillar-icon-white {
							display: inline-block;
						}
					}
				}
				.tap__icon-menu__search__icon svg {
					fill:#FFFFFF;
					path {
						fill:#FFFFFF;
					}
				}
				a {
					color: #FFF !important;
				}
			}
		}

		@include modifier(diy) {
			.tap__icon-menu__menu {
				a.current {
					color: $c_moen-gray;
					img {
						display: none;
						&.pillar-icon-current {
							display: inline-block;
						}
					}
				}	
			}
			.tap__icon-menu__toggle {
				color: $c_moen-gray;	
			}
			.tap__icon-menu__search__icon svg {
				fill: $c_moen-gray;
				path {
					fill: $c_moen-gray;
				}
			}
			.tap__icon-menu__inner.sticky {
				background-color: $c_moen-gray;
				.tap__icon-menu__back {
					a:before {
						background-image: url('images/icon-angle_right_white.svg');
						transform: scale(-1,1);
					}
				}
				.tap__icon-menu__toggle {
					color: #FFF;
					&:after {
						border-color: transparent transparent #FFF transparent;
					}
					img {
						display: none;
						&.pillar-icon-white {
							display: inline-block;
						}
						&.pillar-icon-current {
							display: none;
						}
					}
				}
				.tap__icon-menu__menu {
					background-color: $c_moen-gray;
					li img {
						display: none;
						&.pillar-icon-white {
							display: inline-block;
						}
					}
				}
				.tap__icon-menu__search__icon svg {
					fill:#FFF;
					path {
						fill:#FFF;
					}
				}
				a {
					color: #FFF !important;
				}
			}
		}
		
		@include modifier(support) {
			.tap__icon-menu__menu {
				a.current {
					color: $c_moen-green;
					img {
						display: none;
						&.pillar-icon-current {
							display: inline-block;
						}
					}
				}	
			}
			.tap__icon-menu__toggle {
				color: $c_moen-green;	
			}
			.tap__icon-menu__search__icon svg {
				fill: $c_moen-green;
				path {
					fill: $c_moen-green;
				}
			}
			.tap__icon-menu__inner.sticky {
				background-color: $c_moen-green;
				.tap__icon-menu__back {
					a:before {
						background-image: url('images/icon-angle_right_white.svg');
						transform: scale(-1,1);
					}
				}
				.tap__icon-menu__toggle {
					color: #FFF;
					&:after {
						border-color: transparent transparent #FFF transparent;
					}
					img {
						display: none;
						&.pillar-icon-white {
							display: inline-block;
						}
						&.pillar-icon-current {
							display: none;
						}
					}
				}
				.tap__icon-menu__menu {
					background-color: $c_moen-green;
					li img {
						display: none;
						&.pillar-icon-white {
							display: inline-block;
						}
					}
				}
				.tap__icon-menu__search__icon svg {
					fill:#FFF;
					path {
						fill:#FFF;
					}
				}
				a {
					color: #FFF !important;
				}
			}
		}
		
		@include modifier(pros) {
			.tap__icon-menu__menu {
				a.current {
					color: $c_moen-dark-blue;
					img {
						display: none;
						&.pillar-icon-current {
							display: inline-block;
						}
					}
				}	
			}
			.tap__icon-menu__toggle {
				color: $c_moen-dark-blue;	
			}
			.tap__icon-menu__search__icon svg {
				fill: $c_moen-dark-blue;
				path {
					fill: $c_moen-dark-blue;
				}
			}
			.tap__icon-menu__inner.sticky {
				background-color: $c_moen-dark-blue;
				.tap__icon-menu__back {
					a:before {
						background-image: url('images/icon-angle_right_white.svg');
						transform: scale(-1,1);
					}
				}
				.tap__icon-menu__toggle {
					color: #FFF;
					&:after {
						border-color: transparent transparent #FFF transparent;
					}
					img {
						display: none;
						&.pillar-icon-white {
							display: inline-block;
						}
						&.pillar-icon-current {
							display: none;
						}
					}
				}
				.tap__icon-menu__menu {
					background-color: $c_moen-dark-blue;
					li img {
						display: none;
						&.pillar-icon-white {
							display: inline-block;
						}
					}
				}
				.tap__icon-menu__search__icon svg {
					fill:#FFF;
					path {
						fill:#FFF;
					}
				}
				a {
					color: #FFF !important;
				}
			}
		}
		
	}
}