/// Shopping Cart Checkout

@include block('checkout-navigation') {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 3px;
  margin-bottom: 1.5em;

  @include media($bp-sm) {
    flex-direction: row;
    margin-left: 0;
    margin-bottom: 4em;
  }

  @include child(step) {
    cursor: default;
    flex: 1 1 auto;
    min-height: 1px;
    position: relative;
    text-align: left;

    &::before,
    &::after {
      content: ' ';
      position: absolute;
      z-index: 1;
      background-color: $c_moen-gray;
      width: 3px;
      height: 50%;
      top: 20px;
      left: 8px;
    }

    @include media($bp-sm) {
      text-align: center;

      &::before,
      &::after {
        @include transition(background-color 0.2s ease);
        content: '';
        width: 50%;
        position: absolute;
        z-index: 1;
        height: 3px;
        top: 19px;

      }

      &::before {
        left: 0;
        right: 50%;
      }

      &::after {
        left: 50%;
        right: 100%;
      }

    }

    @include modifier(current) {

      &::before {
        height: 6px;
        top: 17px;
        background-color: $c_moen-bright-blue;

        @include media($bp-sm) {
          height: 6px;
          top: 17px;
        }
      }
    }

    @include modifier(taken) {
      &::before,
      &::after {
        width: 6px;
        top: 17px;
        left: 7px;
        background-color: $c_moen-bright-blue;
        @include media($bp-sm) {
          height: 6px;
          top: 17px;
        }
      }
    }

    &:first-child {
      &::before {
        content: none;
      }
    }

    &:last-child {
      &::after {
        content: none;
      }
    }

    @media(max-width: ($bp-sm - 1)) {
      &:last-child {
        &::before {
          content: none;
        }
      }
    }
  }

  @include element(label) {
    color: rgba($c_moen-gray, 0.50);
    cursor: default;
    text-transform: uppercase;
    font-family: $din-bold;
    font-weight: normal;
    font-size: inherit;
    text-align: center;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 15px;
    padding-left: 25px;
    
    
    &:hover {
      color: $c_moen-bright-blue;
    }


    @include child('item') {

      @include media($bp-sm) {
        display: block;
      }
    }

    &::before {
      content: ' ';
      border-radius: 20px;
      display: block;
      background-color: $c_white;
      border: 3px solid $c_moen-gray;
      position: absolute;
      z-index: 2;
      height: 20px;
      width: 20px;
      left: 0;
    }

    @include media($bp-sm) {
      padding-top: 2.5em; //35px;
      padding-left: 0;
      padding-bottom: 0;

      &::before {
        margin: 10px auto 20px;
        top: 0;
        left: 50%;
        margin-left: -10px;
      }
    }

    @include modifier(current) {
      color: $c_moen-bright-blue;

      &::before {
        border: 4px solid $c_moen-bright-blue;
        height: 24px;
        width: 24px;
        top: -3px;
        left: -2px;
      }
      @include media($bp-sm) {
        &::before {
          height: 30px;
          width: 30px;
          margin-top: 5px;
          margin-bottom: 5px;
          margin-left: -15px;
          top: 0;
        }
      }
    }

    @include modifier(taken) {
      color: $c_moen-gray;
      cursor: pointer;

      &::before {
        background: $c_moen-bright-blue;
        border: none;
      }
    }

    @include modifier('is-disabled') {
      cursor: default;
    }
  }
}

@include block('checkout-edit') {
  font-size: rem(12);
  line-height: rem(22);
  vertical-align: middle;
}


@include block('checkout-process') {
  display: none;

  @include modifier('started') {
    display: block;
  }
}



@include block('checkout-step') {
  display: none;
  margin-bottom: 3em;

  @include modifier('active') {
    display: block;
  }

  @include child('title') {
    font-size: rem(30);
    margin-bottom: 1em;
  }

  @include child('error') {
    color: $c_error;
    font-weight: bold;
    margin-bottom: 2em;
  }

}

@include block('checkout-delivery-options') {
  margin-bottom: 4em;

  @include child('item') {
    margin-bottom: 1em;
  }
}


@include block('checkout-review-section') {
  margin-bottom: 3em;
}


@include block('order-info') {

  @include state('sticky') {

    @include media($bp-md) {
      // position: fixed;
      // top: 0;
      // width: 23.23176%;
      position: relative;
    }
  }
}


@include block('order-summary') {
  box-shadow: 0 0 4px rgba($c_black, 0.3);
  margin-bottom: 2em;
  padding: 0.5em;

  @include media($bp-md) {
    margin-top: 4em;
  }

  @include modifier('is-hidden') {
    display: none;

    @include media($bp-md) {
      display: block;
    }
  }
}



@include block('order-summary-list') {
  @include clearfix;
  font-size: rem(12);

  dt {
    clear: left;
    float: left;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  dd {
    float: right;
    margin-bottom: 1em;
    text-align: right;

    &:last {
      margin-bottom: 0;
    }
  }
  
  
  
  @include child('row') {
    align-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: row; 
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 1em;
    
    @include modifier('coupon') {
      font-size: rem(14);
      font-weight: bold;
      margin-top: 2em;
    }
  }  
  
  @include child('item') {
    align-self: auto;
    flex: 0 1 auto;
    order: 0;
    
    @include modifier('subtotal') {
      font-weight: bold;  
    }
    
    @include modifier('total') {
      font-size: rem(18);
      font-weight: bold;
    }
  }
  
  

  @include child('subtotal') {
    font-weight: bold;
  }

  @include child('total') {
    font-size: rem(18);
    font-weight: bold;
    margin-bottom: 0 !important;
  }

  @include child('coupon') {
    font-size: rem(14);
    font-weight: bold;
    margin-top: 2em;
    margin-bottom: 0 !important;

    @include modifier('moen-blue') {
      color: $c_moen-bright-blue;
    }
  }

}



@include block('order-checkout') {

  @include child('print') {

    @media print {
      display: none;
    }
  }

  @include child('process') {
    position: relative;
  }

  @include child('confirmation') {
    display: none;
  }

  @include modifier('is-complete') {

    .order-checkout__process {
      display: none;
    }

    .order-checkout__confirmation {
      display: block;
    }
  }
}


@include block('order-address') {

  @include child('item') {

  }
}
