

//.plmr-c-cardlist-icon
@include plmr-component('cardlist-icon') {
  font-size: 0;
  padding: 25px 0;
  text-align: left;

  //.plmr-c-cardlist-icon__item
  @include element('item') {
    display: inline-block;
    font-size: 16px;
    line-height: 2rem;
    padding: 25px 0;
    text-align: center;
    vertical-align: top;
    width: 100%;

    @include plmr-mq('md') {
      padding: 25px 31px;
      width: 50%;
    }

    @include plmr-mq('xl') {
      padding: 25px 53px;
    }

    &:nth-of-type(odd) {
      padding-left: 0;
    }

    &:nth-of-type(even) {
      padding-right: 0;
    }
  }

  //.plmr-c-cardlist-icon__item-icon
  @include element('item-icon') {
    color: plmr-color('brand', 'primary');
  }

  //.plmr-c-cardlist-icon__item-title
  @include element('item-title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 24px;
    line-height: 32px;
    margin: 0;
    padding-top: 25px;

    @include plmr-mq(md) {
      font-size: 28px;
      padding-top: 30px;
    }
  }

  //.plmr-c-cardlist-icon__item-copy
  @include element('item-copy') {
    font-size: 16px;
    line-height: 24px;
    padding-top: 15px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }
  }

  //.plmr-c-cardlist-icon__item-button
  @include element('item-button') {
    padding-top: 25px;

    @include plmr-mq(md) {
      padding-top: 30px;
    }
  }
}
