
/// .site-header-search
@include block('site-header-search') {
  align-self: auto;
  flex: 0 1 auto;
  order: 0;
  
  
  /// screen > 448px
  @include media($bp-xs) {
    margin: 13px 15px 12px 0;
  }
  
  
  /// screen > 992px
  @include media($bp-lg) {
    flex: 1 1 auto;
    max-width: 325px;
  }
  
}



/// .header-search-toggle
@include block('header-search-toggle') {
  background-size: 28%;
  border: 0;
  display: block;
  height: 75px;
  position: relative;
  width: 71px;
  
  
  /// .header-search-toggle::before pseudo-element
  &::before {
    background: $c_moen-bright-gray;
    content: '';
    display: block;
    height: 33px;
    margin-top: -16.5px;
    position: absolute;
    top: 50%;
    width: 1px;
  }
  
  
  /// .site-header-search--mobile .header-search-toggle
  .site-header-search--mobile & {
    background-color: $c_moen-bright-gray !important;
    background-image: none !important;
    
  
    /// .site-header-search--mobile .header-search-toggle::before pseudo-element  
    &::before {
      background-color: $c_moen-gray;
      height: 25px;
      left: 35px;
      margin-top: 0;
      position: absolute;
      top: 25px;
      transform: rotate(-45deg); 
      width: 2px;
    }
    
    
    /// .site-header-search--mobile .header-search-toggle::after pseudo-element  
    &::after {
      background-color: $c_moen-gray;
      content: '';
      display: block;
      height: 25px;
      left: 35px;
      position: absolute;
      top: 25px;
      transform: rotate(45deg); 
      width: 2px;
    }
    
  }
  
  
  /// screen > 448px
  @include media($bp-xs) {
    display: none;
    visibility: hidden;
  }
  
}



/// .header-search-container
@include block('header-search-container') {
  display: none;
  

  /// .site-header-search--mobile .header-search-container
  .site-header-search--mobile & {  
    background: $c_moen-bright-gray;
    box-shadow: 0 2px 15px 0 rgba($c_text-base, 0.50);
    display: block;
    left: 0;
    padding: 15px 10px;
    position: absolute;
    top: 75px;
    width: 100%;
    z-index: z("flyout-menu"); 
     
    
    /// screen > 448px
    @include media($bp-xs) {
      background: transparent;
      box-shadow: none;
      display: block;
      left: auto;
      padding: 0;
      position: relative;
      top: auto;
    }
    
  }
  
  
  /// screen > 448px
  @include media($bp-xs) {
    background: transparent;
    box-shadow: none;
    display: block;
    left: auto;
    padding: 0;
    top: auto;
  }
  
}


/// .search-box
@include block('search-box') {
  align-self: stretch;
  background: $c_white;
  border: 1px solid $c_moen-bright-gray;
  display: flex;
  justify-content: space-between;
  height: 50px;
  // width: 100%;
  
  
  /// .search-box__input
  @include child('input') {
    align-self: stretch;
    background: transparent;
    color: $c_moen-gray;
    flex: 1 1 auto;
    font-family: $din-regular;
    font-size: 14px;
    padding: 0 15px;
    order: 0;
    
    
    /// screen > 448px
    @include media($bp-xs) {
      max-width: 115px;  
    }
    
    
    /// screen > 992px
    @include media($bp-lg) {
      max-width: none;
      padding: 0 19px;
    }
    
  }
  
  
  /// .search-box__button
  @include child('button') {
    align-self: auto;
    border: 0;
    flex: 0 1 auto;
    order: 0;
  }
  
}
