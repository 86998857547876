/*
Social Follow

Styleguide 4.2
*/

/*
Social Follow Nav

*<em><strong>sg-placeholder and sg-placeholder--padded is only for Styleguide, don't use</strong></em>

markup:
<div class="sg-placeholder sg-placeholder--padded">
<nav id="social-follow-nav" class="social-follow-nav footer-social-follow" aria-labelledby="social-follow-nav-label">
  <label id="social-follow-nav-label" class="global-footer__label">Stay Connected</label>
  <ul class="social-follow-nav__list">
    <li class="social-follow-nav__item"><a href="https://www.facebook.com/moen" data-social-network="facebook" rel="external" target="_blank" class=""> <span class="hat">facebook</span><img src="../images/facebook.svg" alt="" class="social-follow-nav__image" /> </a></li>
    <li class="social-follow-nav__item"><a href="https://twitter.com/moen" data-social-network="twitter" rel="external" target="_blank" class=""> <span class="hat">twitter</span><img src="../images/twitter.svg" alt="" class="social-follow-nav__image" /> </a></li>
    <li class="social-follow-nav__item"><a href="https://www.pinterest.com/moen/" data-social-network="pinterest" rel="external" target="_blank" class=""> <span class="hat">pinterest</span><img src="../images/pinterest.svg" alt="" class="social-follow-nav__image" /> </a></li>
    <li class="social-follow-nav__item"><a href="http://www.houzz.com/pro/moen" data-social-network="houzz" rel="external" target="_blank" class=""> <span class="hat">house</span><img src="../images/houzz.svg" alt="" class="social-follow-nav__image" /> </a></li>
    <li class="social-follow-nav__item"><a href="https://www.instagram.com/moeninc/" data-social-network="instagram" rel="external" target="_blank" > <span class="hat">instagram</span><img src="../images/instagram.png" alt="" class="social-follow-nav__image" /> </a></li>
    <li class="social-follow-nav__item"><a href="https://www.youtube.com/user/moenfaucets" data-social-network="youtube" rel="external" target="_blank" class=""> <span class="hat">youtube</span><img src="../images/youtube.svg" alt="" class="social-follow-nav__image" /> </a></li>
    <li class="social-follow-nav__item"><a href="https://open.spotify.com/user/moenspotifychannel" data-social-network="spotify" rel="external" target="_blank"> <span class="hat">spotify</span><img src="../images/spotify.svg" alt="spotify" class="social-follow-nav__image"> </a></li>
  </ul>
</nav>
</div>

Styleguide 4.2.1
*/


/// .social-follow-nav
@include block('social-follow-nav') {
  margin: 0 0 28px 0;  
  
  /// .social-follow-nav__list
  @include child('list') {
    display: flex;
    justify-content: space-between;
    list-style: none;
  }
  
  
  /// .social-follow-nav__item
  @include child('item') {
    margin-right: 10px;
    
    
    @include media($bp-lg) {
      margin-right: 14px;
    }
    
    
    &:last-child {
      margin-right: 0;
    }
    
  }
  
  /// .social-follow-nav__link
  @include child('link') {
    text-decoration: none;
  }
  
  /// .social-follow-nav__image
  @include child('image') {
    height: auto;
    max-width: 30px;
    width: 100%;
  }
  
}


/*
Social Follow Menu

Used on /social
<br />
*<em><strong>sg-placeholder and sg-placeholder--padded is only for Styleguide, don't use</strong></em>

markup:
<div class="sg-placeholder sg-placeholder--padded">
<div class="social-follow-menu">
  <ul class="social-follow-menu__list">
    <li class="social-follow-menu__item">
      <a href="https://www.facebook.com/moen" data-social-network="facebook" rel="external" target="_blank"> 
        <span class="hat">facebook</span>
        <img src="../images/facebook.svg" alt="facebook" class="social-follow-menu__image"> 
      </a>        
    </li>
    <li class="social-follow-menu__item">
      <a href="https://twitter.com/moen" data-social-network="twitter" rel="external" target="_blank"> 
        <span class="hat">twitter</span>
        <img src="../images/twitter.svg" alt="twitter" class="social-follow-menu__image"> 
      </a>        
    </li>
    <li class="social-follow-menu__item">
      <a href="https://www.pinterest.com/moen/" data-social-network="pinterest" rel="external" target="_blank"> 
        <span class="hat">pinterest</span>
        <img src="../images/pinterest.svg" alt="pinterest" class="social-follow-menu__image"> 
      </a>        
    </li>
    <li class="social-follow-menu__item">
      <a href="http://www.houzz.com/pro/moen" data-social-network="houzz" rel="external" target="_blank"> 
        <span class="hat">house</span>
        <img src="../images/houzz.svg" alt="house" class="social-follow-menu__image"> 
      </a>        
    </li>
    <li class="social-follow-menu__item">
      <a href="https://www.instagram.com/moeninc/" data-social-network="instagram" rel="external" target="_blank"> 
        <span class="hat">instagram</span>
        <img src="../images/instagram.png" alt="instagram" class="social-follow-menu__image"> 
      </a>        
    </li>
    <li class="social-follow-menu__item">
      <a href="https://www.youtube.com/user/moenfaucets" data-social-network="youtube" rel="external" target="_blank"> 
        <span class="hat">youtube</span>
        <img src="../images/youtube.svg" alt="youtube" class="social-follow-menu__image"> 
      </a>        
    </li>
    <li class="social-follow-menu__item">
      <a href="https://open.spotify.com/user/moenspotifychannel" data-social-network="spotify" rel="external" target="_blank"> 
        <span class="hat">spotify</span>
        <img src="../images/spotify.svg" alt="spotify" class="social-follow-menu__image"> 
      </a>        
    </li>
  </ul>
</div>
</div>

Styleguide 4.2.2
*/

/// .social-follow-menu
@include block('social-follow-menu') {
  margin: 30px 0;
  
  
  /// .social-follow-menu__list
  @include child('list') {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    
    
    @include media($bp-sm) {
      justify-content: space-between;
    }
  }
  
  
  /// .social-follow-menu__item
  @include child('item') {
    align-self: auto;
    flex: 0 1 auto;
    margin: 0 10px 10px 0;
    order: 0;
    
    
    /// .social-folow-menu__item:last-child
    &:last-child {
      margin-right: 0;
    }
    
  }
  
  
  /// .social-follow-menu__link
  @include child('link') {
    text-decoration: none;
  }
  
  
  /// .social-follow-menu__image
  @include child('image') {
    display: block;
    height: 50px;
    width: 50px;
    
    
    @include media($bp-sm) {
      height: 80px;
      width: 80px;
    }
  }

}
