
//.plmr-c-banner-tabbed-content-search
@include plmr-component('banner-tabbed-content-search') {
  padding-bottom: 40px;
  padding-top: 50px;
  text-align: center;

  //.plmr-c-banner-tabbed-content-search__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.285rem;

    @include plmr-mq('md') {
      font-size: 2.285rem;
      line-height: 2.571rem;
    }
  }

  //.plmr-c-banner-tabbed-content-search__button-wrap
  @include element('button-wrap') {
    padding-top: 20px;
  }

  //.plmr-c-banner-tabbed-content-search__button
  @include element('button') {
    @include plmr-link($underline: true);
    cursor: pointer;
  }

  //.plmr-c-banner-tabbed-content-search__input
  @include element('input') {
    padding-top: 30px;

    @include plmr-mq('md') {
      padding: 40px 30px 0;
    }

    @include plmr-mq('xl') {
      padding: 40px 122px 0;
    }
  }

  //.plmr-c-banner-tabbed-content-search
  //.plmr-c-banner-tabbed-content-search
}
