/**
@section Textarea Input
@childof Forms
@page Forms Textarea Input
@status in progress
@example
_markup/form-textarea.hbs
*/
@include plmr-component('form-textarea') {
  @extend .plmr-c-form-input;
  min-height: 150px;
}
