/**
@doc _docs/form-text.md
@section Form Text
@childof Forms
@page Forms Form Text
@status in progress
@example
_markup/form-text.hbs
*/
@include plmr-component('form-text') {
  color: plmr-color('text', 'primary');
  font-size: 14px;
  line-height: 21px;
  margin: 10px 0;
  padding: 0;

  strong,
  b {
    @include plmr-font-face($family: 'bold');
  }
}


/**
@doc _docs/form-text--helper.md
@section Helper Text
@sectionof Form Text
@example
_markup/form-text--helper.hbs
*/
@include plmr-component('form-text') {
  //.plmr-c-form-text--helper
  @include modifier('helper') {
    @include plmr-font-face($family: 'italic');
    color: darken(plmr-color('text', 'secondary'), 8%);
    font-size: 12px;
  }
}
