/*
 Vimeo embed styling for the temp Moen homepage.
 Uses the intrinic iframe embed technique (see mixin for usage and links to pattern resources).
*/

$video-max-width: 1196px !default;
$video-max-height: 450px !default;

@include block(homepage) {
  @include element(video) {
    display: none;
    
    @include media($bp-md) {
      display: block;
    }

    iframe {
      .player {
        .video-wrapper {
          background: transparent !important;
        }
      }
    }
  }

  @include element(video-thumbnail) {
    position: relative;
    text-align: center;
    &:before {
      background: url(images/icon-play_outline_white.svg) no-repeat;
      content: '';
      height: 75px;
      max-height: 100px;
      max-width: 100px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 75px;
      z-index: 1;
    }

    img {
      height: auto;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  @include element(video-modal) {
      width: 100%;
      min-height: 330px;
  }
}
