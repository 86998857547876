/*
PDP Multimedia Section

Styleguide 4.10.3
*/

@include block (pdp-multimedia) {
  @include outer-container(1350px);
  display: block;
  font-size: 1rem;
  overflow: hidden;
  max-height: 320px;
  width: 100%;
  position: absolute;
  top: -318px;
  @include media($bp-md) {
    position: relative;
    top: 0;
  }

  @include modifier(less-than-3) {
    display: block;
    @include media($bp-md) {
      display: none;
    }
  }

  @include element(image) {
    display: block;
    height: 318px;
    position: relative;
    border-left: 2px solid $c_white;
    border-right: 2px solid $c_white;
    @include modifier('360') {
      .pdp-multimedia__icon {
        background-size: 25%;
        opacity: .75;
      }
    }
    img {
      height:100%;
      width: auto;
    }
    @include modifier(hero) {
      display: block;
      @include media($bp-md) {
        display: none !important; // to override slick display:block
      }
    }
  }

  @include element(video) {
    display: block;
    height: 318px;
    position: relative;
    border-left: 2px solid $c_white;
    border-right: 2px solid $c_white;
    img {
      height:100%;
      width: auto;
    }
  }

  @include element(icon) {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .5;
    background-position: 50%;
  }
  

  @include element(button) {
    background-color: transparent;
    position: absolute;
    height: 100%;
    top: 0;
    padding: 0 5px;
    @include media($bp-md) {
      background-color: rgba($c_white,.35);
      &:hover,
      &:active,
      &:focus {
        background-color: rgba(255,255,255,.55);
      }
    }
    @include media($bp-lg) {
      padding: 0 1em;
    }
    @include modifier(prev) {
      z-index: 1;
      left: 0px;
    }
    @include modifier(next) {
      right: 0px;
    }
  }

  .slick-next,
  .slick-prev {
    background-position: center center;
    height: 90px;
    width: 40px;
    position: relative;
  }

  .slick-prev {
    z-index: 1;
    background-image: url("./images/icon-arrow_left_large-dark-gray.svg");
    left: 0;
  }

  .slick-next {
    background-image: url("./images/icon-arrow_right_large-dark-gray.svg");
    right: 0;
  }
}

