
// .plmr-c-nav-main-card
@include plmr-component('nav-main-card') {
  border: 1px solid plmr-color('accent', 'light');
  // max-width: 100%;
  padding: 30px 2%;
  text-align: center;
  // width: 100%;
  width: 250px;

  // @include plmr-mq('md') {
  //   max-width: 250px;
  // }


  // .plmr-c-nav-main-card
  @include plmr-mq('xl') {
    // max-width: 302px;
    padding: 30px 45px;
    width: 302px;
  }


  // .plmr-c-nav-main-card--service
  @include modifier('service') {
    align-self: flex-start;
    // margin-left: 10%;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;

    // screen width > 962px
    @include plmr-mq('lg') {
      // top: -43px;
      margin-bottom: 0;
      margin-top: 0;
    }
  }


  // .plmr-c-nav-main-card__title
  @include element('title') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }


  // .plmr-c-nav-main-card__link
  @include element('link') {
    display: block;
    margin-bottom: 20px;
  }

  @include element('link-large') {
    color: plmr-color('brand', 'primary');
    display: block;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
  }


  // .plmr-c-link-phone__small
  @include element('link-small') {
    color: plmr-color('text', 'secondary');
    display: block;
    font-size: 16px;
    line-height: 18px;
  }


  // .plmr-c-nav-main-card__copy
  @include element('copy') {
    color: plmr-color('text', 'primary');
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 30px;
  }

}
