/**
@doc _docs/banner-image-divider.md
@section Banner Image Divider
@childof Banners
@page Banners Banner Image Divider
@status ready
@hippo banner
@example
_markup/banner-image-divider.hbs
*/
@include plmr-component('banner-image-divider') {
  height: 175px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include plmr-mq(lg) {
    height: 300px;
  }

  // .plmr-c-banner-image-divider__image
  @include element('image') {
    display: block;
    height: auto;
    left: 50%;
    max-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;

    @include plmr-mq(xxl) {
      max-height: none;
      min-height: 100%;
      top: 0;
      transform: translate(-50%, 0);
    }
  }
}
