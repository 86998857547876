

/// .coverflow-carousel
@include block('coverflow-carousel') {
  @include clearfix;
  
  
  /// .coverflow-carousel__nav
  @include child('nav') {
    background-color: $c_black;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 60% 60%;
    border-radius: 50%;
    opacity: 1;
    padding: 15px;
    transition: all 0.3s ease;
    z-index: z("coverflow-carousel-nav");
    
    
    /// screen > 768px
    @include media($bp-md) {
      padding: 30px;  
    }
    
    
    /// .coverflow-carousel__nav--previous
    @include modifier('previous') {
      background-image: url('images/icon-angle_left_white.svg'); 
    }
    
    
    /// .coverflow-carousel__nav--next
    @include modifier('next') {
      background-image: url('images/icon-angle_right_white.svg'); 
    }
    
    
    /// .coverflow-carousel__nav.slick-disabled
    &.slick-disabled {
      cursor: default;
      opacity: 0;
    }
    
  }
  
  
  /// .coverflow-carousel__item  
  @include child('item') {
    cursor: pointer;
    float: left;
    margin: 0 5px;
    opacity: 0.7;
    padding: 25px 0;
    transform: scale(0.8);
    transition: all 0.3s ease;
    width: 50%;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      padding: 50px 0;
    }
    
    
    /// .coverflow-carousel__item:hover, .coverflow-carousel__item:active, .coverflow-carousel__item:focus  
    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
    
    
    /// .coverflow-carousel__item.slick-center
    &.slick-center {
      opacity: 1;
      transform: scale(1.15);
    

      /// screen width > 768px
      @include media($bp-md) {
        transform: scale(1.5);
      }
      
      
      /// screen width > 992px
      @include media($bp-lg) {
        transform: scale(1.25);
      }
      
    }
    
    
    /// .no-outline .coverflow-carousel__item
    .no-outline & {
      outline: none;
    }
    
  }  
  
  
  /// .coverflow-carousel__link
  @include child('link') {
    display: block;
    
    
    /// .no-outline .coverflow-carousel__link
    .no-outline & {
      outline: none;
    }
    
  }
  
  
  /// .coverflow-carousel__image
  @include child('image') {
    display: block;
    height: auto;
    transition: all 0.3s ease;
    width: 100%;
  }
  
}
