// Only the shameful styles shall live here
//
// This is for quick fixes and stuff to be refactored later
.plmr-c-banner-cta,
.plmr-c-campaign-slider__copy,
.plmr-c-product-spec__copy,
.plmr-c-card-list-lightbox__copy,
.plmr-c-banner__copy,
.plmr-c-collection-item__copy,
.plmr-c-cardlist-staggered__copy,
.plmr-c-banner-category-collection__copy,
.plmr-c-cardlist-related__card-body,
.plmr-c-cardlist-additional-resource__item-copy,
.plmr-c-cardlist-supporting-info__item-copy,
.plmr-c-tabbed-content__copy,
.plmr-c-cardlist-info__intro-copy,
.plmr-c-cardlist-info__item-copy,
.plmr-c-cardlist-accordion__item-content-body,
.plmr-c-carousel-featured-campaign__item-text,
.plmr-c-product-info-condensed__copy,
.plmr-c-carousel-feature-slider__text {
  p {
    &:last-child {
      padding-bottom: 0;
    }
  }
}


.plmr-c-collection-slider-nav__slider {
  position: absolute;
}


.plmr-c-card-list-thumbnail__card {
  min-height: 400px;
}

.plmr-c-banner-category-collection-detail__slide {
  .plmr-c-banner-category-collection-detail__knockout--src {
    @include plmr-mq(md) {
      margin-top: -50px;
    }
  }
}

.plmr-c-carousel-featured-parts__media {
  // override default slick margin styles
  img {
    margin: 0;
  }
}

// Turn off debugging for Price Spider widget.
.ps-widget img:not([alt]) {
  border: 0;
}