
/// .lazyload
@include block('lazyload') {
  opacity: 0;
  transition: opacity 0.3s;
  
  
  /// .lazyload.is-loaded
  @include state('loaded') {
    opacity: 1;
  }
  
}



/// .lazyload-slide-image
@include block('lazyload-slide-image') {
  opacity: 0;
  transition: opacity 0.3s;
  
  
  /// .lazyload-slide-image.is-loaded
  @include state('loaded') {
    opacity: 1;
  }
  
}
