/*
Contact Us

markup:
<div class="contact-us">
  <div class="form__heading icon-label">
    <img src="./images/icon-envelope.svg" alt="telephone icon" class="form__icon icon-label__icon" />
    <h2 class="form__title icon-label__label">Email Moen</h2>
  </div>
  <div class="contact-us__form form">
    <div class="row">
      <div class="col-md-12">
        <fieldset class="form-section">
          <div class="form-group">
            <label for="selReason">Reason For Contacting Moen</label>
            <div class="selector selector--unrounded icon-select-arrow-down">
              <span>-- Please select --</span>
              <select id="selReason" class="form-select" name="selReason">
              <option disabled="disabled" selected="selected">Select a Pet...</option>
               <optgroup label="Cats">
                 <option>Tiger</option>
                 <option>Leopard</option>
                 <option>Lynx</option>
               </optgroup>
               <optgroup label="Dogs">
                 <option>Grey Wolf</option>
                 <option>Red Fox</option>
                 <option>Fennec</option>
               </optgroup>
              </select>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</div>

Styleguide 4.33
*/

@include block(contact-us) {
  font-size: 1rem;
  padding-top: 2em;
  @include outer-container();
}
