/*
Image-Link Molecules

The base image-link combination is a text label centered above its corresponding image.

Markup:
<a href="#" class="link-image">
  <span class="link-image__label">Label</span>
  <img class="link-image__image" src="https://placeimg.com/100/100/nature" alt="" />
</a>

Styleguide 3.2
*/

@include block(link-image) {
  @include link-base();
  @include element(label) {
    display: block;
    line-height: 1;
    margin: 0 auto 1em;
  }
  @include element(image) {
    display: block;
  }
  display: inline-block;
  text-align: center;
}

/*
Image-Link Molecule Used in Full Navigation

The full-nav image-link combination has modified link color and spacing.

Markup:
<a href="#" class="link-image-menu">
  <span class="link-image-menu__label">Label</span>
  <img class="link-image-menu__image" src="https://placeimg.com/100/100/nature" alt="" />
</a>

Styleguide 3.2.1
*/
@include block(link-image-menu) {
  @include link-base();
  font-family: $din-medium;
  &:link,
  &:visited {
    color: inherit;
  }
  &:hover,
  &:active,
  &:focus {
    color: $c_link_color;
  }
  @include element(label) {
    color: $c_nav_dark_gray;
    display: block;
    text-transform: uppercase;
    font-size: inherit;
    line-height: 1;
  }
  @include element(image) {
    display: block;
    margin: em(20) auto 0;
  }
  // display: inline-block;
  text-align: center;
}

/*
List Image Molecule - Label Below

Image link molecules, typically use in list components. These are set with a default image width of 235px.

Styleguide 3.2.2
*/

/*
List Image Molecule - Label Below, centered

Markup:
<div style="margin:20px auto"> <!-- for styleguide formatting only -->
  <a href="#" class="list-image-link list-image-link--centered">
    <img class="list-image-link__image" src="./images/styleguide/one-bowl.jpg" alt="" />
    <div>One Bowl</div>
  </a>
</div>

Styleguide 3.2.2.1
*/

/*
List Image Molecule - Label Below, left-aligned

Markup:
<div style="margin:20px auto"> <!-- for styleguide formatting only -->
  <a href="#" class="list-image-link">
    <img class="list-image-link__image" src="./images/styleguide/two-bowl.jpg" alt="" />
    <div>Two Bowls</div>
  </a>
</div>

Styleguide 3.2.2.2
*/

/*
List Image Molecule - Label below, centered

Note the special use of pre-composed gauge thickness images rather than the complexity of positioned text in this solution.

Markup:
<div style="margin:20px auto"> <!-- for styleguide formatting only -->
  <a href="#" class="list-image-link list-image-link--centered">
    <img class="list-image-link__image" src="./images/styleguide/gauge-16.png" alt="" />
    <div>16 Gauge</div>
  </a>
</div>

Styleguide 3.2.2.3
*/

@include block(list-image-link) {
  font-family: $din-regular;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 0 .5em;
  margin: 0 0 2em;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 184px;
  max-width: 184px;
  position: relative;

  & > a {
    max-width: 100%; // ie10, ie11 fix
    display: flex;
    display: block; // ie10, ie11 fix
    height: 140px; // ie10, ie11 fix
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 140px;
    justify-content: flex-start;
  }
  & > a:hover {
    text-decoration-color: $c_text-base;
  }
  @include media($bp-sm) {
    margin: 0 .25%;
    margin: 0 5px;
  }
  @include element(label) {
    font-family: $din-medium;
    color: $c_text-base;
    text-decoration: none;
    display: block;
    padding-top: 1em;
    text-transform: uppercase;
    @include modifier(plain) {
    	text-transform: none;
    }
  }

  @include element(image) {
    width: 100%;
    height: auto;

    @include modifier(auto) {
    	width: auto;
    }
  }
  
  
  @include child('image-preview') {
    max-height: 98px;
  }

  @include modifier(centered) {
    align-items: center;
    text-align: center;
  }

  @include state(swatch) {
    margin:0 0 1.25em 0;
    width: 194px;
    max-width: 194px;
    & > img {
      margin-bottom: em(20);
      max-width: 120px;
    }
    &:empty {
      width: auto;
    }
  }

  @include element(shim) {
    height: 0;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .list {
    margin-top: 1em;
    line-height: 1.5em;
  }
}

// Force to hide .list-image-link__shim
// so they could be centered on IE-11 & IE-10
// It uses modernizr class .no-flexboxlegacy

.no-flexboxlegacy {
  .list-image-link__shim {
    display: none;
  }
}




@include block('list-image-link-categories') {
  margin-bottom: 2em;
  
  
  @include media($bp-sm) {
    margin-bottom: 2em;
  }
  
  
  & > a {
    flex-basis: 170px;
    height: 170px;
    
  }
  
  
  @include child('image') {
    max-height: 98px;
  }
}
