/**
@section Button Link
@childof Buttons
@page Buttons Button Link
@status ready
*/


/**
Primary button link styling
@section Primary
@sectionof Button Link
@page Buttons Button Link
@example
_markup/button-link-primary.hbs
*/
@include plmr-component('button-link') {


  // .plmr-c-button-link--primary
  @include modifier('primary') {
    color: plmr-color('brand', 'primary');

    @include plmr-button-link-hover(plmr-color('brand', 'primary'));
  }

}



/**
Secondary button link styling
@section Secondary
@sectionof Button Link
@page Buttons Button Link
@example
_markup/button-link-secondary.hbs
*/
@include plmr-component('button-link') {


  // .plmr-c-button-link--secondary
  @include modifier('secondary') {
    color: plmr-color('brand', 'secondary');

    @include plmr-button-link-hover(plmr-color('brand', 'secondary'));
  }

}






/**
Tertiary button link styling
@section Tertiary
@sectionof Button Link
@page Buttons Button Link
@example
_markup/button-link-tertiary.hbs
*/
@include plmr-component('button-link') {


  // .plmr-c-button-link--tertiary
  @include modifier('tertiary') {
    color: plmr-color('brand', 'tertiary');

    @include plmr-button-link-hover(plmr-color('brand', 'tertiary'));
  }

}

