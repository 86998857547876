/*
Headings

HTML headings repertoire

<i>*Not yet styled</i>

markup:
<h1 class="heading--1"> Heading H1 </h1>
<h2> Heading H2* </h2>
<h2 class="heading--2"> Heading H2 </h2>
<h3 class="heading--3"> Heading H3 </h3>
<h4 class="heading--4"> Heading H4 </h4>
<h5 class="heading--5"> Heading H5 </h5>
<h6> Heading H6* </h6>
<h6 class="heading--6"> Heading H6 </h6>

Styleguide 2.2
*/

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  line-height: 1;
  margin-top: 0.1em;
  margin-bottom: 0.5em;
  font-weight: normal;
}
.button--secondary-color:disabled{
  background: #ccc !important;
}
.error-prompt-show{
  color: #c60c30;
  font-size: 12px;
  text-align: center;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

#account-app{
  margin: 0 auto;
}

@include block('plmr-c'){

  input{
    text-indent: 10px;
  }
  @include element('login-box'){
    width: 100%;
    input{
      outline: none;
    }
    button{
      outline: none;

    }

  }
  @include element('login-back'){
    width: 100%;
    height: 156px;
    background-size: cover;
    padding: 26px 0 8px;
    text-align: center;
    h1{
      font-size: 24px;
      font-weight: normal;
      color: #555555;
      margin-bottom: 0.6em;
      display: none;
    }
    p{
      display: none;
      font-size: 16px;
    }
    @media (min-width:768px){
      height: 220px;
      h1{
        display: block;
      }
      p{
        display: block;
      }
    }
    @media (min-width:992px){
      height: 260px;
    }
  }
  @include element('login-title'){
    padding: 26px 0 8px;
    text-align: center;
    display: block;
    h1{
      font-size: 24px;
      font-weight: normal;
      color: #555555;
    }
    @media (min-width:768px){
      display: none;
    }
  }
  @include element('login-tab'){
    width: 100%;
    max-width: 800px;
    padding: 0 6%;
    margin: auto;
    box-sizing: border-box;
    .asterisk{
      display: inline-block;
      text-indent: 2px;
      color: #db2323;
      font-size: 15px;
    }

  }
  @include element('register-tab'){
    @media (min-width:992px) {
      margin: 0 auto;
    }
  }
  @include element('login-form'){
    padding-top: 6px;
    .login-div{
      width: 100%;
      margin-top: 18px;
      box-sizing: border-box;
      justify-content: space-between;
      .login-div-mob{
        display: flex;
        justify-content: space-between;
        .active-c{
          background: #cccccc;
        }
      }
      label{
        display: flex;
        font-weight: 400;
        margin-bottom: 8px;
        min-width: 100px;
      }
      .login-inp-mob{
        width: 50%;
        @media (min-width:768px) {
          width: 70%;
        }
        @media (min-width:992px) {
          width: 70%;
        }
      }
      @media (min-width:768px) {
        display: flex;
        align-items: baseline;
        margin-top: 40px;
        .login-div-mob{
          width: 80%;
        }
      }
      @media (min-width:992px) {
        margin-top: 52px;
      }
    }
    .register-div {
      @media (min-width:992px) {
        margin-top: 21px;
        justify-content: flex-start;
        label{
          width: 17%;
        }
      }
    }
    .login-exception{
      @media (min-width:768px) {
        justify-content: normal;
      }
    }
    #display_box{
      @media (min-width:992px){
        display: flex;
      }
    }
  }
  @include element('login-success-box'){
      margin: 40px auto 100px;
      color: #1f1f1f;
      div{
        text-align: center;
        font-size:16px;
        p{
          padding: 0;
          a{
            text-decoration:none;
            border-bottom:1px solid #5b7f95;
            display: inline-block;
            color: #5b7f95;
          }
        }
        p:nth-child(1){
          font-size:28px;
        }
        img:nth-child(2){
          display: inline-block;
          width: 42px;
          height: 42px;
          margin:26px 0;
        }
        p:nth-child(3){
          margin-bottom:12px;
        }
      }
    @media (min-width:768px){
      margin: 80px auto 200px;
      color: #1f1f1f;
      div{
        text-align: center;
        font-size:16px;
        p{
          padding: 0;
        }
        p:nth-child(1){
          font-size:32px;
        }
        img:nth-child(2){
          display: inline-block;
          width: 86px;
          height: 86px;
          margin:26px 0;
        }
        p:nth-child(3){
          margin-bottom:12px;
        }
      }
    }
  }
  @include element ('login-inp'){
    width: 100%;
    height: 46px;
    font-size: 14px;
    text-indent: 10px;
    color: #1f1f1f;
    box-sizing: border-box;
    border-radius: 2px;
    @media (min-width:768px) {
      width: 80%;
    }
  }
  @include element('register-inp'){
    width: 100%;
    height: 46px;
    font-size: 14px;
    text-indent: 10px;
    color: #1f1f1f;
    box-sizing: border-box;
    border-radius: 2px;
    @media (min-width:768px) {
      width: 80%;
    }
    @media (min-width:992px) {
      width: 56%;
      height: 30px;
    }
  }
  @include element('login-forget'){
    font-size: 14px;
    margin-top: 16px;
    text-align: right;
    color: #c88a3d;
    a{
      text-decoration: none;
      padding-bottom: 1px;
      border-bottom: 1px solid #c8893b;
    }
  }
  @include element('login-btn'){
    text-align: center;
    margin-top: 40px;
    button{
      width: 178px;
      height: 48px;
      font-size: 14px;
      align-items: center;
      background: #597e96;
      color: #fff;
      margin-bottom: 40px;
      letter-spacing: 2px;
      outline: none;
      cursor: pointer;
    }
  }
  @include element('login-agreement'){
    justify-content: normal;

    @media (min-width:768px){
      padding-left:20.5%;
    }
  }
  @include element('signup-agreement'){
    @media (min-width:768px){
      padding-left: 20%;
    }
    @media (min-width:992px){
      padding-left: 17%;
    }
  }
  @include element('login-span'){
    display: inline-block;
    font-size: 12px;
    vertical-align: top;
    text-indent: 6px;
    letter-spacing:1px;
    span{
      color: #82878d;
    }
    .login-span{
      color: #597e96;
    }
  }

  //注册

  @include element('account_type'){
    text-align: center;
    h3{
      color: #587e95;
      font-size: 16px;
      margin: 32px 0 15px;
    }
    h1{
      font-size: 24px;
      font-weight: normal;
      color: #555555;
      margin-bottom: 22px;
    }
    @media (min-width:992px){
      width: 26%;
      border-right: 1px solid #555555;
    }
  }
  @include element('login-select_type'){
    width: 126px;
    height: 36px;
    background: #597e96;
    color: #FFFFFF;
    text-indent: 10px;
    -webkit-appearance: menulist;
    outline: none;
    .select_type-op{
      color: #555555;
      background: #FFFFFF;
      text-indent: 10px;
    }
    @media (min-width:992px) {
      display: none;
    }
  }
  @include element('login_type-h3'){
    margin-top: 34px;
    font-size: 14px;
    color: #587e95;
    @media (min-width:768px){
      font-size: 16px;
    }
  }
  @include element('login-comtainer'){
    @media (min-width:992px){
      display: flex;
      padding: 0 10%;
    }

  }
  @include element('login-verification'){
    cursor: pointer;
    width: 126px;
    height: 46px;
    background: #597e96;
    border-radius: 2px;
    color: #fff;
    @media (min-width:992px) {
      height: 32px;
    }
  }

  @include element('border-bottom'){
    @media (min-width:992px) {
      width: 80%;
      display: block;
      margin: 26px auto 0;
      border-top: 1px solid #555555;
    }
  }
  @include element('border-bottom-z'){
    @media (min-width:992px) {
      width: 100%;
    }
  }
  @include element ('login-tab_type'){
    width: 136px;
    margin: auto;
    button{
      display: none;
      width: 100%;
      height: 48px;
      text-align: center;
      align-items: center;
      background: #dbd9da;
      margin-bottom: 22px;
    }
    .tab_type-active{
      background: #597e96;
      color: #FFFFFF;
      @media (min-width:992px) {
        display: block;
      }
    }
  }
  @include element('prompt-box'){
    color: #c60c30;
    font-size: 12px;
    padding: 4px 0 0;
    @media (min-width:768px) {
      padding: 4px 0 0 20%;
    }
    @media (min-width:992px) {
      padding: 4px 0 0 17%;
    }
  }
  @include element('prompt-box-info'){
    color: #c60c30;
    font-size: 12px;
    padding: 4px 0 0;
    @media (min-width:768px) {
      padding: 4px 0 0 23%;
    }
    @media (min-width:992px) {
      padding: 4px 0 0 20.5%;
    }
  }
  @include element('secure-box'){
    color: #c60c30;
    font-size: 12px;
    padding: 4px 0 0;
    @media (min-width:768px) {
      padding: 4px 0 0 19%;
    }
    @media (min-width:992px) {
      padding: 4px 0 0 20.5%;
    }
  }
  @include element('prompt-box-login'){
    color: #c60c30;
    font-size: 12px;
    padding: 4px 0 0;
    @media (min-width:768px) {
      padding: 4px 0 0 20%;
    }
    @media (min-width:992px) {
      padding: 4px 0 0 20%;
    }
  }
  @include element('prompt-box-forgot'){
    @media (min-width:992px) {
      padding: 4px 0 0 17%;
    }
  }
}



@include block('plmr-c-account'){
  width: 100%;
  padding: 0 0 100px;
  input{
    text-indent: 10px;
    color: #1f1f1f;
  }
  @media (min-width:768px) {
    padding: 0 100px 200px;
  }
  box-sizing: border-box;
  @include element('title'){
    margin: 38px auto;
    display: none;
    @media (min-width:768px) {
      display: block;
    }
  }
  @include element('content'){
    width: 100%;
  }
  @include element('content-small'){
    width: 100%;
    display: flex;
  }
  @include element('left'){
    display: none;
    width: 20%;
    max-width: 194px;
    height: 256px;
    border-right: 1px solid #5b7f95;
    @media (min-width:768px) {
      display: flex;
      width: 194px;
    }
    ul{
      width: 100%;
      margin: auto;
      .account-banner-active{
        background: #5b7f95;
        a{
          color: #ffffff;
          font-size: 16px;
        }
      }
      li{
        list-style: none;
        height: 46px;
        text-align: center;
        line-height: 46px;
        a{
          width: 100%;
          display: inline-block;
          color: black;
          text-decoration: none;
        }
      }
    }
  }
  @include element('right'){
    width: 80%;
    margin: auto;
    box-sizing: border-box;
    @media (min-width:768px) {
      margin:0 40px 0 90px;
    }
    @media (min-width:992px) {
      max-width: 600px;
    }
    img{
      display: block;
      width: 100%;
      margin-top:38px;
      @media (min-width:768px) {
        margin-top: 0;
      }
    }
    h4{
      color: #5b7f95;
      font-size: 16px;
      @media (min-width:768px) {
        display: none;
      }
    }
  }
  @include element('right-yh'){
    text-align: center;
  }
  @include element('yh-box'){
    position: relative;
    margin-bottom:38px;
    max-width:690px;
    .copy-button{
      display: block;
      position: absolute;
      right: 10%;
      bottom: 10%;
      background: #5b7f95;
      color: #ffffff;
      font-size: 12px;
      text-decoration: none;
      padding: 12px 22px;
      @media (min-width:992px) {
        font-size: 14px;
      }
    }
  }

  //密码
  @include element('right-form'){
    width: 100%;
    @media (min-width:992px) {
      padding-left: 46px;
    }
    h5{
      color: #5b7f95;
      font-size: 16px;
      text-align: center;
      @media (min-width:768px) {
        color: black;
      }
    }
    h4{
      display: block;
      text-align: center;
      @media (min-width:768px) {
        display: none;
      }
    }
    .right-form-content{
      width: 100%;
      margin: 50px auto 0;
      .gm-password{
        position: relative;
        width: 100%;
        margin-top: 26px;
        align-items: center;
        label{
          display: inline-block;
          width: 114px;
        }
        input{
          width: 100%;
          height: 38px;
          border: 1px solid #5b7f95;
          outline: none;
          margin-top: 10px;
          @media (min-width:768px) {
            margin-top: 0;
          }
          @media (min-width:992px) {
            max-width: 320px;
          }
        }
        .select-box{
          width: 100%;
          letter-spacing: 0;
          @media (min-width:768px) {
            letter-spacing: 10px;
          }
          @media (min-width:992px) {
            letter-spacing: 8px;
            max-width: 320px;
          }
          select{
            appearance:none;
            -moz-appearance:none;
            -webkit-appearance:none;
            padding: 0 16px 0 10px;
            width: 34%;
            &:nth-child(3){
              width: 26%;
            }
            &:nth-child(5){
              width: 26%;
            }
          }
          text{
            display: inline-block;
            width: 7%;
            text-align: center;
          }
        }
        .info-select-box{
          letter-spacing: 0 !important;
        }
        select{
          -webkit-appearance: menulist-button;
          border: 1px solid #5b7f95;
          height: 38px;
          outline: none;
        }
        .radio{
          width: 100%;
          margin-top: 10px;
          span{
            margin-right: 30px;
          }
          input{
            width: auto;
            height: auto;
            width: 20px;
            height: 20px;
            vertical-align: sub;
            letter-spacing: 10px;
          }
          @media (min-width:768px) {
            margin-top: 0;
          }
          @media (min-width:992px) {
            max-width: 320px;
          }
        }
        @media (min-width:768px) {
          display: flex;
        }

      }
      .bj-password{
        @media (min-width:992px) {
          max-width: 554px;
        }
        input[type="radio"] + span::before {
          content: "\a0"; /*不换行空格*/
          display: inline-block;
          vertical-align: middle;
          font-size: 18px;
          width: 18px;
          height: 18px;
          margin-right: .4em;
          border-radius: 50%;
          border: 1px solid #5b7f95;
          text-indent: .15em;
          line-height: 1;
        }
        input[type="radio"]:checked + span::before {
          background-color: #5b7f95;
          background-clip: content-box;
          padding: .2em;
        }
        input[type="radio"] {
          position: absolute;
          bottom: 0px;
          opacity: 0;
        }
      }
    }
    .right-button-bottom{
      text-align: center;
      padding-top: 26px;
      box-sizing: border-box;
      button{
        width: 152px;
        height: 36px;
        background: #5b7f95;
        color: #ffffff;
        cursor: pointer;
      }
      @media (min-width: 768px){
        padding-top: 56px;
      }
    }
    .right-button-bottom-info{
      text-align: center;
      padding-top: 56px;
        @media (min-width: 768px){
          padding-top: 80px;
          text-align: left;
          padding-left: 18%;
      }
    }
    .right-form-content{
      @media (min-width: 768px){
        padding-left: 0;
        margin-top: 0;
      }
      @media (min-width: 992px){
        padding-left: 0;
        margin-top: 0;
      }
    }
  }


  //个人中心
  @include element('center-title'){
    text-align:center;
    font-size: 16px;
    h4{
      color: #5b7f95;
      margin: 36px 0;
      @media (min-width:768px) {
        color: #1f1f1f;
        font-size: 22px;
      }
    }
  }
  @include element('center-box'){
    background: #eeeeee;
    ul{
      width: 100%;
      max-width: 508px;
      display: flex;
      padding: 30px 16px 0;
      margin: auto;
      flex-wrap:wrap;
      justify-content: center;
      @media (min-width:768px) {
        padding: 52px 46px;
        max-width: 100%;
        justify-content: space-between;
      }
      li{
        list-style:none;
        width: 40%;
        background: #ffffff;
        margin: 0 18% 10% 0;
        text-align: center;
        padding: 16px;
        box-sizing: border-box;
        &:nth-child(2),&:nth-child(4){
          margin-right: 0;
        }
        @media (min-width:768px) {
          width: 150px;
          height: 150px;
          margin:0;
        }
        @media (min-width:992px) {
          width: 170px;
          height: 170px;
          margin:0;
        }
        a{
          display: inline-block;
          width: 100%;
          text-decoration: none;
          color: #5b7f95;
          font-size: 16px;
          p{
            padding: 10px 0 0;
            @media (min-width:768px) {
              padding: 25px 0 0;

            }
            @media (min-width:992px) {
              padding: 35px 0 0;
            }
          }
        }
      }
    }
    @media (min-width:768px) {
      width: 100%;
      margin:0 auto;
    }
    @media (min-width:992px) {
      width: 86%;
    }
  }

  #user-info-app{
    margin: 0 auto;
  }
  //个人信息
  @include element('table'){

    .the-tr{
      display: flex;
      margin-bottom: 48px;
      span{
        display: inline-block;
        letter-spacing: 2px;
        width: 28%;
        margin-left: 8%;
        &:nth-child(2){
          width: 82%;
          margin-left: 10%;
          line-height: 20px;
        }
      }
    }
    @media (min-width:768px){
      margin-left: 36px !important;
      text-align: left;
    }
  }

  //收藏夹
  @include element('right-collection'){
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    max-width: 860px;
    padding: 0 10px;
    @media (min-width:768px) {
      width: 80%;
      margin: 38px 0 0 45px;
      padding: 0;
    }
    a{
      text-decoration: none;
    }
    h4{
      color: #5b7f95;
      font-size: 16px;
      text-align: center;
      margin-bottom: 46px;
      @media (min-width:768px) {
        display: none;
      }
    }
  }
  @include element('list-paging'){
    padding: 10px 24px 0;
    .next-a{
      float: right;
    }
  }
  @include element('left-collection'){
    border: 0;
  }
  @include element('container-top'){
    .container-top-content{
      display: flex;
      justify-content: space-between;
    }
    .content-left-title{
      line-height: 38px;
      p{
        padding: 0;
      }
    }
    .content-right-inp{
      position: relative;
      width: 150px;
      @media (min-width:768px){
        width: 208px;
      }
      input{
        max-width:208px;
        width: 100%;
        height: 38px;
        border: 1px solid #5b7f95;
        text-indent:18px;
        outline: none;
      }
      img{
        display: inline-block;
        position: absolute;
        top: 0;
        width: 10%;
        max-height: 38px;
        right: 5%;
        height: 100%;
        cursor: pointer;
      }
    }
    @media (min-width:992px) {
      padding: 0 2%;
    }
  }

  @include element('container-buttom'){
    margin-top: 22px;
    @media (min-width:768px){
      margin-top:36px;
    }
  }
  @include element('container-buttom-list'){
    ul{
      li{
        list-style: none;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @include element('list-content-ul'){
    display: flex;
    flex-wrap: wrap;
    max-width: 750px;
    margin: 0 auto;
  }
  @include element('list-li'){
    //width: 50%;
    list-style:none;
    padding: 20px 24px;
    text-align: center;
    position:relative;
    img{
      max-width: 165px;
      width: 100%;
    }
    p{
      padding: 1em 0;
      color: #5b7f95;
      font-size: 14px;
      &:nth-child(2){
       color: #c8893b;
        font-size: 12px;
      }
    }
    span{
      width: 20px;
      height: 20px;
      position: absolute;
      top: 3%;
      right: 4%;
      img{
        width: 100%;
      }
    }
    @media (min-width: 768px){
      width: 50%;
    }
    @media (min-width: 992px){
      width: 33.3%;
    }
    &:hover{
      background: #f3f3f3;
    }
  }
}


  @media (min-width: 768px){
    #account-exception {

        padding: 0;
    }
  }

.address-tab {
  border: 1px solid #5b7f95;
  width: 100%;
  padding: 8px;
  margin-top: -1px;
  position: absolute;
  top: 37px;
  left: 0;
  background: #fff;
  z-index: 1;
  display: none;
}

@media (min-width:992px){
  .address-tab{
    max-width: 320px;
  }
}

.address-tab ul {
  margin: 0;
  padding: 0;
  float: none;
  white-space: normal;
}

.address-tab ul.tab {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.address-tab .tab li {
  list-style: none;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin-right: 5px;
  border-right: 1px solid #ddd;
  padding: 2px 10px;
  background: #fff;
  font-size: 13px;
  font-weight: 700;
  border-bottom: none;
  cursor: pointer;
}

.address-tab .tab li:after {
  display: inline-block;
  content: ' ';
  width: 10px;
  height: 10px;
  margin-left: 5px;
  background-image: url("https://www.moen.cn/resources/asset/image/6emuXQ9PGDPBcgBovSEXqY/300px@1x/dav.png?q=90");
  background-position: center;
  background-repeat: no-repeat;
}
.addressBox{
  position: relative;
  width: 100%;
  outline: none;
}
@media (min-width:992px) {
  .addressBox{
    max-width: 320px;
  }

}

.address-arrow{
  position: absolute;
  top: 18px;
  right: 6px;
  width: 20px !important;
  height: 20px !important;
  z-index: 1;
  margin: 0 !important;
  @media (min-width: 768px) {
    top: 8px;
  }
}
.select-box{
  margin-top: 10px;
  @media (min-width: 768px) {
    top: 0;
  }
}


.address-tab .tab li.current:after {
  -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);

}

.address-province-select {
  position: relative;
}

.address-tab .tab .current {
  /* border: 2px solid #e4393c; */
  border-bottom: 2px solid #fff;
}

.address-tab .tab-con {
  /* border: 1px solid #ccc; */
  /* margin-top: -1px; */
  padding-top: 8px;
}

.address-tab .tab-con li {
  list-style: none;
  /* width: 6.4em; */
  margin-left: 5px;
  margin-right: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: inline-block;
  font-size: 13px;
  /* text-align: center; */
  /* color: #999; */
  cursor: pointer;
  /* white-space: pre-wrap; */
}

.address-tab .tab-con li a {
  text-decoration: none;
  margin-right: 0;
  color: #666;
}

.address-tab .tab-con li.long-area {
  width: 13em
}

.address-tab .tab-con li.longer-area {
  width: 26em
}

#addressEditModal,#addressAddModal {
  width: 100%;
}

#addressEditModal .checkout-subheading ,#addressAddModal .checkout-subheading{
  margin: 0;
}






@include block(heading) {
  @include modifier(1) {
    font-size: em(44);
    font-family: $din-light;
    font-weight: normal;
    letter-spacing: -.05em;
  }
  @include modifier(2) {
    font-size: em(22);
    font-family: $din-medium;
    font-weight: normal;
  }
  @include modifier(3) {
    font-size: em(22);
    font-family: $din-medium;
    font-weight: normal;
  }
  @include modifier(4) {
    font-size: em(18);
    font-family: Arial, sans-serif;
    font-weight: normal;
  }
  @include modifier(5) {
    font-size: em(17);
    font-family: Arial, sans-serif;
    font-weight: normal;
  }
  @include modifier(6) {
    font-size: em(16);
    font-family: Arial, sans-serif;
    font-weight: normal;
  }
}

/*
Heading Styles

markup:
<h1 class="section-heading--1">Section Heading h1</h1>
<h2 class="section-heading--1">Section Heading h2</h2>
<h3 class="section-heading--1">Section Heading h3</h3>
<h1 class="section-heading--2">Section Heading h1</h1>
<h2 class="section-heading--2">Section Heading h2</h2>
<h3 class="section-heading--2">Section Heading h3</h3>
<h1 class="section-heading--3">Section Heading h1</h1>
<h2 class="section-heading--3">Section Heading h2</h2>
<h3 class="section-heading--3">Section Heading h3</h3>
<p>I'm just demo content that follows the headings</p>
<h2 class="section-heading--2">Section Heading h2</h2>
<h3 class="section-heading--3 section-heading--bottom-margin">Section Heading h3</h3>
<p>I'm just demo content that follows the headings</p>
<h2 class="section-heading--2 section-heading--bottom-margin">Section Heading h2</h2>
<h3 class="section-heading--3 section-heading--top-margin">Section Heading h3</h3>
<p>I'm just demo content that follows the headings</p>

Styleguide 2.2.1
*/

/*
Product Finder Heading Styles

markup:
<section class="container">
<h3 class="question-heading--intro">Looking to identify your product?<br />
We’ll help you find it in just a couple of clicks.</h3>
<h4 class="question-heading">First, Which Room Are We In?</h4>
</section>

Styleguide 2.2.2
*/

@include block(question-heading) {

  font-size: em(32);
  font-family: $din-regular;
  text-align: center;
  line-height: 1;

  @include modifier(intro) {
    font-size: em(24);
    font-family: $din-light;
    text-align: center;
    line-height: 1.2;

    &::before {
      content: '';
      height: 1px;
      width: 85px;
      display: block;
      background-color: $c_moen-bright-gray;
      margin: rem(25) auto rem(25);
    }

  }
}

@mixin section-heading-core {
  font-family: $din-light;
  margin-bottom: 0;
}

@include block(section-heading) {
  @include modifier(1) {
    @include section-heading-core();
    font-size: em(34);
  }
  @include modifier(2) {
    @include section-heading-core();
    font-size: em(30);
  }
  @include modifier(3) {
    @include section-heading-core();
    font-size: em(22);
  }
  @include modifier(bottom-margin) {
    margin-bottom: 1em;
  }
  @include modifier(top-margin) {
    margin-top: 1em;
  }
}

/*
Multi-weight Headings

markup:
<h1 class="heading-multiweight--1"> Heading <span>H1</span></h1>

Styleguide 2.2.3
*/

@mixin heading-multiweight {
  font-weight: normal;
  font-family: $proxima-bold;
  span {
    font-family: $proxima-thin;
  }
}

@include block(heading-multiweight) {
  @include modifier(1) {
    @include heading-multiweight();
    font-size: em(42);
    line-height: rem(38);
  }
}


/*
Base Typography


markup:
<p>Base Paragraph. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est</p>
<p><i>Lorem ipsum dolor sit amet,</i> consectetur adipisicing elit, <em>sed do eiusmod tempor</em> incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
<p>Lorem <b>ipsum dolor</b> sit amet, <strong>consectetur adipisicing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
<p>Lorem <small>ipsum dolor</small> sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
<p>Lorem <sup>ipsum</sup> dolor <sub>sit</sub> amet, consectetur adipisicing <sup><small>elit</small></sup>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>

Styleguide 2.3
*/

// p {
//   color: inherit;
//   font-family: $din-regular;
//   font-size: 1rem;
//   padding-bottom: 1em;

//   & i,
//   & em {
//     font-family: inherit;
//     font-weight: inherit;
//   }

//   & b,
//   & strong {
//     font-family: inherit;
//     font-weight: 600;
//     //color: darken($c_text-base, 2%);
//   }
// }

/*
Subscript, Superscript

markup:
<p>This is a TradeMark<sup>&reg;</sup> entity - the line-height should not be affected. This is a footnote reference<sub>[1]</sub> entity - the line-height should not be affected. Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit
</p>

Styleguide 2.3.1
*/

sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
  font-size: .8em;
}

sub {
  top: 0.4em;
}

/*
Truncated text

A class to display long text runs terminated with ellipses.

markup:
<div>Some very long text is not controlled with the truncate-ellipses class, and is therefore allowed to run on and possibly break the layout.</div>
<br />
<div class="truncate-ellipses">This very long text should be clipped and terminated with an ellipses character because it is assigned the truncate-ellipses class.</div>

Styleguide 2.3.2
*/

@mixin truncate-ellipses-core() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncate-ellipses {
  @include truncate-ellipses-core();
}

/*
Text alignment

markup:
<p class="text-left">text left aligned</p>
<p class="text-center">text center aligned</p>
<p class="text-right">text right aligned</p>

Styleguide 2.3.3
*/
@mixin text-alignment($alignment) {
  .text-#{$alignment} {
    text-align: $alignment;
  }
}

@include text-alignment(left);
@include text-alignment(center);
@include text-alignment(right);

/*
Text transform

markup:
<p class="text-uppercase">uppercase text</p>
<p class="text-lowercase">Lowercase Text</p>
<p class="text-capitalize">capitalize text</p>

Styleguide 2.3.4
*/
@mixin text-transform($case) {
  .text-#{$case} {
    text-transform: $case;
  }
}

@include text-transform(uppercase);
@include text-transform(lowercase);
@include text-transform(capitalize);

//  @mixin text-color($color) {
//    .text--$color {
//      color: $color;
//    }
// }
//
// @include text-color($c_moen-bright-blue);


.text--moen-bright-blue {
  color: $c_moen-bright-blue;
}


.text--important {
  color: $c_error;
  font-weight: bold;
}

.text--disclaimer {
  color: $c_moen-gray;
  font-size: 0.6em !important;
}

/// To bring attention to copy for review
.text--attention {
  color: $c_attention;
  font-weight: bold;
  font-size: em(18);
  font-style: italic;
}


// ::selection {
//   background: $c_moen-bright-blue;
//   color: $c_white;
// }

/*
Label Types

[Some Description]

markup:
<label class="label">Lorem LABEL Ipsum</label>
<br />
<span class="label">Lorem SPAN Ipsum</span>

Styleguide 2.7
*/

.label {
  text-transform: uppercase;
}
