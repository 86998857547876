/*
FCR

Styleguide 4.34.0
*/

@include block(fcr) {
  @include outer-container(100%);
  font-size: 1rem;
  
  @include element(title) {
    font-size: 1.5em;
    padding: .5em 0;
    @include media($bp-md) {
      font-size: 2.75em;
    }
  }
}

/*
FCR - Hero

markup:
<div class="fcr container--main">
  <section class="fcr-hero">
    <h1 class="fcr__title fcr-hero__title heading--1">Installation and Support</h1>
    <div class="fcr-hero__banner row">
      <div class="fcr-hero__image col-sm-4" style="background-image:url(http://placehold.it/350x270);">
      </div>
      <div class="col-sm-8">
        <div class="fcr-hero__description">
          <p class="fcr__title heading--1">100885 and 14960 Diverters Removal and Installation</p>
          <a class='button button--secondary' href="#">Download PDF</a>
        </div>
      </div>
    </div>
  </section>
</div>

Styleguide 4.34.1
*/

@include block(fcr-hero) {
  @include outer-container();
  @include element(title) {
    margin: .5em 0;
  }

  @include element(banner) {
    background-color: $c_fcr_gray_background;
    @include media($bp-sm) {
      display: flex;
    }
  }

  @include element(image) {
    background-size: cover;
    background-position: center;
    background-color: $c_white;
    min-height: 250px;
  }

  @include element(description) {
    align-items: flex-start;
    color: $c_white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 1.2em .5em;
    &:first-child {
      margin-bottom: .5em;
    }
  }
}

/*
FCR - Tools

These is an example about how it will work if different images sizes are added

markup:
<div class="fcr">
  <section class="fcr-tools">
    <h1 class="fcr__title heading--1">Tools Needed</h1>
    <hr class="section-rule section-rule--blue" />
    <div class="fcr-tools__tiles">
      <div class="fcr-tools__tool">
        <div class="fcr-tools__tool-image-container">
          <img class="fcr-tools__tool-image" src="http://placehold.it/190x140">
        </div>
        <div class="fcr-tools__tool-name">
          these are tool names
        </div>
      </div>
      <div class="fcr-tools__tool">
        <div class="fcr-tools__tool-image-container">
          <img class="fcr-tools__tool-image" src="http://placehold.it/360x160">
        </div>
        <div class="fcr-tools__tool-name">
          these are tool names
        </div>
      </div>
      <div class="fcr-tools__tool">
        <div class="fcr-tools__tool-image-container">
          <img class="fcr-tools__tool-image" src="http://placehold.it/230x140">
        </div>
        <div class="fcr-tools__tool-name">
          these are tool names
        </div>
      </div>
      <div class="fcr-tools__tool">
        <div class="fcr-tools__tool-image-container">
          <img class="fcr-tools__tool-image" src="http://placehold.it/180x130">
        </div>
        <div class="fcr-tools__tool-name">
          these are tool names
        </div>
      </div>
      <div class="fcr-tools__tool">
        <div class="fcr-tools__tool-image-container">
          <img class="fcr-tools__tool-image" src="http://placehold.it/180x100">
        </div>
        <div class="fcr-tools__tool-name">
          these are tool names
        </div>
      </div>
      <!-- shim -->
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div> 
    <hr class="section-rule section-rule--blue" />
  </section>
</div>

Styleguide 4.34.2
*/

@include block(fcr-tools) {
  @include outer-container();
  margin-top: 2em;

  @include element(tiles) {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 1.5em;

    @include media($bp-sm) {
      justify-content: flex-start;
    }
  }

  @include element(tool) {
    margin: .5em;
    flex-grow: 0;
    flex-basis: auto;
    flex-shrink: 0;
    &:empty {
      height: 0;
      margin: 0;
    }
  }

  @include element(tool-image-container) {
    height: 60px;
    padding: 1em;
    text-align: center;
  }

  @include element(tool-image) {
    height: 100%;
    width: auto;
  }

  @include element(tool-name) {
    padding: .5em 0 1em 0;
    text-align: center;
  }
}


/*
FCR - Sections

markup:
<div class="fcr">
  <div class="fcr-section">
    <h2 class="fcr__title fcr-section__title heading--1">Removal of the diverter</h2>
  </div>
</div>

Styleguide 4.34.3
*/

@include block(fcr-section) {
  background-color: $c_fcr_gray_background;

  @include element(title) {
    @include outer-container();
    color: $c_white;
    margin: .7em auto;
    padding-left: .25em;
    @include media($bp-md) {
      padding: 1em 0;
    } 
  }
}

/*
FCR - Steps

markup:
<div class="fcr">
  <div class="fcr-step">
    <div class="fcr-step__numbering">
      <div class="fcr-step__number">1</div>
    </div>
    <div class="fcr-step__content">
      <div class="fcr-step__graphic-container">
        <img class="fcr-step__graphic" src="./images/styleguide/fcr-graphic.jpg">
      </div>
      <div class="fcr-step__details">
        <div class="fcr-step__callout-text">Callout Text</div>
        <div class="fcr-step__description">
          <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
        </div>
        <a href="#" class="button button--secondary">Download the height chart</a>
      </div>
      <div class="fcr-step__image-container">
        <a href="#"><img class="fcr-step__image" src="./images/styleguide/fcr-image.jpg"></a>
      </div>
    </div>
  </div>
  <div class="fcr-step">
    <div class="fcr-step__numbering">
      <div class="fcr-step__number">1</div>
    </div>
    <div class="fcr-step__content">
      <div class="fcr-step__graphic-container">
        <img class="fcr-step__graphic" src="./images/styleguide/fcr-graphic.jpg">
      </div>
      <div class="fcr-step__details">
        <div class="fcr-step__callout-text">Callout Text</div>
        <div class="fcr-step__description">
          <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
        </div>
        <a href="#" class="button button--secondary">Download the height chart</a>
      </div>
      <div class="fcr-step__image-container">
        <a href="#"><img class="fcr-step__image" src="./images/styleguide/fcr-image.jpg"></a>
      </div>
    </div>
  </div>
  <div class="fcr-step">
    <div class="fcr-step__numbering">
      <div class="fcr-step__number">1</div>
    </div>
    <div class="fcr-step__content">
      <div class="fcr-step__graphic-container">
        <img class="fcr-step__graphic" src="./images/styleguide/fcr-graphic.jpg">
      </div>
      <div class="fcr-step__details">
        <div class="fcr-step__callout-text">Callout Text</div>
        <div class="fcr-step__description">
          <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
        </div>
        <a href="#" class="button button--secondary">Download the height chart</a>
      </div>
      <div class="fcr-step__image-container">
        <a href="#"><img class="fcr-step__image" src="./images/styleguide/fcr-image.jpg"></a>
      </div>
    </div>
  </div>
  <div class="fcr-step">
    <div class="fcr-step__numbering">
      <div class="fcr-step__number">1</div>
    </div>
    <div class="fcr-step__content">
      <div class="fcr-step__details">
        <div class="fcr-step__callout-text">Callout Text</div>
        <div class="fcr-step__description">
          <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
        </div>
        <a href="#" class="button button--secondary">Download the height chart</a>
      </div>
      <div class="fcr-step__decision">
          <div class="fcr-step__decision-top">
            <div class="fcr-step__decision-text">
              <div class="fcr-step__callout-text">100885 Diverter</div>
              <div class="fcr-step__description">
                <p>The diverter is 1/2" in diameter. Color may vary.</p>
              </div>
              <a href="#" class="button button--secondary">I HAVE THIS ONE <span>(Go to step xx)</span></a>
            </div>
            <div class="fcr-step__decision-image-container">
              <a href="#"><img class="fcr-step__decision-image" src="./images/styleguide/fcr-image.jpg"></a>
            </div>
          </div>
          <label class="conjunction-badge">OR</label>
          <div class="fcr-step__decision-bottom">
            <div class="fcr-step__decision-text">
              <div class="fcr-step__callout-text">14960 Diverter</div>
              <div class="fcr-step__description">
                <p>The diverter is 1/2" in diameter. Color may vary.</p>
              </div>
              <a href="#" class="button button--secondary">I HAVE THIS ONE <span>(Go to step xx)</span></a>
            </div>
            <div class="fcr-step__decision-image-container">
              <a href="#"><img class="fcr-step__decision-image" src="./images/styleguide/fcr-image.jpg"></a>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

Styleguide 4.34.4
*/

@mixin fcr-decisions() {
  align-items: center;
  color: $c_white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  .fcr-step__callout-text {
    color: $c_white;
    font-size: 1.6em;
    font-weight: normal;
    margin-bottom: 0;
  }
  .fcr-step__decision-text {
    text-align: center;
  }
  .fcr-step__decision-image-container {
    padding-bottom: 1em;
  }
  @include media($bp-sm) {
    flex-direction: row;
    .fcr-step__decision-text {
      text-align: left;
    }
    .fcr-step__decision-image-container {
      padding-bottom: 0;
    }
  }
}

@include block(fcr-step) {
  @include outer-container();
  align-items: stretch;
  border: 1px solid $c_fcr_border;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & + .fcr-step {
    margin-top: -1px; 
    // This negative margin hides the extra border of the step
  }

  &:nth-of-type(even) {
    .fcr-step__numbering {
      background-color: $c_fcr_even;
    }
  }

  &:nth-of-type(odd) {
    .fcr-step__numbering {
      background-color: $c_fcr_odd;
      .fcr-step__number {
        color: $c_text_base;
      }
    }
  }

  @include element(numbering) {
    background-color: $c_fcr_even;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 10%;
    flex-shrink: 0;
    flex-grow: 0;
    max-width: 85px;
    min-width: 60px;
  }

  @include element(number) {
    min-width: 1em;
    color: $c_white;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    
    
    @include media($bp-md) {
      font-size: 2em;
    }
  }

  @include element(content) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    padding: 1em;
    flex-basis: 90%;
    flex-shrink: 1;
    flex-grow: 1;
    @include media($bp-md) {
      flex-direction: row;
    }
  }
  
  @include element(image-container) {
    position: relative;
    height: 100%;
    width: 100%;
    a {
      display: block;
      position: relative;
      text-align: center;
      width: 100%;
    }
    @include media($bp-md) {
      flex-basis: 20%;
      max-width: 180px;
    }
  }

  @include element(play-icon) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include element(graphic-container) {
    display: flex;
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: center;
    padding-bottom: 1em;

    & + .fcr-step__details {
      border-left: 0;
      flex-basis: auto;
      margin: 0;
      @include media($bp-md) {
        border-left: 1px dotted $c_fcr_border;
        height: 100%;
        margin: 0 1.5em;
        flex-shrink: 3;
        flex-basis: 60%;
      }
    }

    @include media($bp-md) {
      align-items: center;
      flex-basis: 20%;
      padding-bottom: 0;
    }
  }

  @include element(image) {
    height: auto;
    width: 100%;
    max-width: 200px;
    @include media($bp-md) {
      max-width: none;
    }
  }

  @include element(graphic) {
    max-height: 120px;
    width: auto;
    @include media($bp-md) {
      max-height: none;
      width: 100%;
    }
  }

  @include element(description) {
    width: 100%;
    p {
      text-align: center;
    }
    @include media($bp-md) {
      p {
        text-align: left;
      }
    }
  }

  @include element(details) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;
    @include media($bp-md) {
      align-items: flex-start;
      justify-content: center;
      padding: 1.5em;
      flex-shrink: 1;
      flex-basis: 80%;
    }
  }

  @include element(callout-text) {
    color: $c_fcr_callout_text;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: .5em;
  }
  @include element(decision) {
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 0;
    height: 100%;
    position: relative;
    width: 100%;
    @include media($bp-md) {
      flex-basis: 50%;
    }
  }

  @include element(decision-top) {
    @include fcr-decisions();
    background-color: $c_moen-bright-blue;
    border-bottom: 4px solid $c_white;
  }

  @include element(decision-bottom) {
    @include fcr-decisions();
    background-color: $c_text_base;
    border-top: 4px solid $c_white;
  }

  @include element(decision-text) {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 1.5em;
    width: 100%;
    p {
      text-align: left;
    }
    .button.button--secondary {
      line-height: 1.5em;
      white-space: normal;
      
      span {
        display: block;
      }
    }
    @include media($bp-sm) {
      flex-shrink: 1;
    }
    @include media($bp-md) {
      flex-basis: 60%;
    }
  }

  @include element(decision-image-container) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 1em;
    a {
      display: block;
      max-height: 160px;
      overflow: hidden;
      width: 100%;
    }
    @include media($bp-md) {
      flex-shrink: 1;
      flex-basis: 40%;
    }
  }

  @include element(decision-image) {
    height: auto;
    width: 100%;
    max-width: 200px;
  }

  @include element(iframe) {
    & > iframe {
      width: 100%;
      min-height: 100%;
    }
  }

}

/*
FCR - Exaple with all the sections

markup:
<div class="fcr container--main">
  <section class="fcr-hero">
    <h1 class="fcr__title fcr-hero__title heading--1">Installation and Support</h1>
    <div class="fcr-hero__banner row">
      <div class="fcr-hero__image col-sm-4" style="background-image:url(http://placehold.it/350x270);">
      </div>
      <div class="col-sm-8">
        <div class="fcr-hero__description">
          <p class="fcr__title heading--1">100885 and 14960 Diverters Removal and Installation</p>
          <a class='button button--secondary' href="#">Download PDF</a>
        </div>
      </div>
    </div>
  </section>
  <section class="fcr-tools">
    <h1 class="fcr__title heading--1">Tools Needed</h1>
    <hr class="section-rule section-rule--blue" />
    <div class="fcr-tools__tiles">
      <div class="fcr-tools__tool">
        <div class="fcr-tools__tool-image-container">
          <img class="fcr-tools__tool-image" src="http://placehold.it/190x140">
        </div>
        <div class="fcr-tools__tool-name">
          these are tool names
        </div>
      </div>
      <div class="fcr-tools__tool">
        <div class="fcr-tools__tool-image-container">
          <img class="fcr-tools__tool-image" src="http://placehold.it/360x160">
        </div>
        <div class="fcr-tools__tool-name">
          these are tool names
        </div>
      </div>
      <div class="fcr-tools__tool">
        <div class="fcr-tools__tool-image-container">
          <img class="fcr-tools__tool-image" src="http://placehold.it/230x140">
        </div>
        <div class="fcr-tools__tool-name">
          these are tool names
        </div>
      </div>
      <div class="fcr-tools__tool">
        <div class="fcr-tools__tool-image-container">
          <img class="fcr-tools__tool-image" src="http://placehold.it/180x130">
        </div>
        <div class="fcr-tools__tool-name">
          these are tool names
        </div>
      </div>
      <div class="fcr-tools__tool">
        <div class="fcr-tools__tool-image-container">
          <img class="fcr-tools__tool-image" src="http://placehold.it/180x100">
        </div>
        <div class="fcr-tools__tool-name">
          these are tool names
        </div>
      </div>
      <!-- shim -->
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div> 
    <hr class="section-rule section-rule--blue" />
  </section>
  <div class="fcr-section">
    <h2 class="fcr__title fcr-section__title heading--1">Removal of the diverter</h2>
  </div>
  <section class="fcr-step-list">
    <div class="fcr-step">
      <div class="fcr-step__numbering">
        <div class="fcr-step__number">1</div>
      </div>
      <div class="fcr-step__content">
        <div class="fcr-step__graphic-container">
          <img class="fcr-step__graphic" src="./images/styleguide/fcr-graphic.jpg">
        </div>
        <div class="fcr-step__details">
          <div class="fcr-step__callout-text">Callout Text</div>
          <div class="fcr-step__description">
            <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
          </div>
          <a href="#" class="button button--secondary">Download the height chart</a>
        </div>
        <div class="fcr-step__image-container">
          <a href="#"><img class="fcr-step__image" src="./images/styleguide/fcr-image.jpg"></a>
        </div>
      </div>
    </div>
    <div class="fcr-step">
      <div class="fcr-step__numbering">
        <div class="fcr-step__number">1</div>
      </div>
      <div class="fcr-step__content">
        <div class="fcr-step__graphic-container">
          <img class="fcr-step__graphic" src="./images/styleguide/fcr-graphic.jpg">
        </div>
        <div class="fcr-step__details">
          <div class="fcr-step__callout-text">Callout Text</div>
          <div class="fcr-step__description">
            <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
          </div>
          <a href="#" class="button button--secondary">Download the height chart</a>
        </div>
        <div class="fcr-step__image-container">
          <a href="#"><img class="fcr-step__image" src="./images/styleguide/fcr-image.jpg"></a>
        </div>
      </div>
    </div>
    <div class="fcr-step">
      <div class="fcr-step__numbering">
        <div class="fcr-step__number">1</div>
      </div>
      <div class="fcr-step__content">
        <div class="fcr-step__graphic-container">
          <img class="fcr-step__graphic" src="./images/styleguide/fcr-graphic.jpg">
        </div>
        <div class="fcr-step__details">
          <div class="fcr-step__callout-text">Callout Text</div>
          <div class="fcr-step__description">
            <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
          </div>
          <a href="#" class="button button--secondary">Download the height chart</a>
        </div>
        <div class="fcr-step__image-container">
          <a href="#"><img class="fcr-step__image" src="./images/styleguide/fcr-image.jpg"></a>
        </div>
      </div>
    </div>
    <div class="fcr-step">
      <div class="fcr-step__numbering">
        <div class="fcr-step__number">1</div>
      </div>
      <div class="fcr-step__content">
        <div class="fcr-step__details">
          <div class="fcr-step__callout-text">Callout Text</div>
          <div class="fcr-step__description">
            <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
          </div>
          <a href="#" class="button button--secondary">Download the height chart</a>
        </div>
        <div class="fcr-step__decision">
            <div class="fcr-step__decision-top">
              <div class="fcr-step__decision-text">
                <div class="fcr-step__callout-text">100885 Diverter</div>
                <div class="fcr-step__description">
                  <p>The diverter is 1/2" in diameter. Color may vary.</p>
                </div>
                <a href="#" class="button button--secondary">I HAVE THIS ONE <span>(Go to step xx)</span></a>
              </div>
              <div class="fcr-step__decision-image-container">
                <a href="#"><img class="fcr-step__decision-image" src="./images/styleguide/fcr-image.jpg"></a>
              </div>
            </div>
            <label class="conjunction-badge">OR</label>
            <div class="fcr-step__decision-bottom">
              <div class="fcr-step__decision-text">
                <div class="fcr-step__callout-text">14960 Diverter</div>
                <div class="fcr-step__description">
                  <p>The diverter is 1/2" in diameter. Color may vary.</p>
                </div>
                <a href="#" class="button button--secondary">I HAVE THIS ONE <span>(Go to step xx)</span></a>
              </div>
              <div class="fcr-step__decision-image-container">
                <a href="#"><img class="fcr-step__decision-image" src="./images/styleguide/fcr-image.jpg"></a>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
  <div class="fcr-section">
    <h2 class="fcr__title fcr-section__title heading--1">Removal of the diverter</h2>
  </div>
  <div class="fcr-step">
    <div class="fcr-step__numbering">
      <div class="fcr-step__number">1</div>
    </div>
    <div class="fcr-step__content">
      <div class="fcr-step__graphic-container">
        <img class="fcr-step__graphic" src="./images/styleguide/fcr-graphic.jpg">
      </div>
      <div class="fcr-step__details">
        <div class="fcr-step__callout-text">Callout Text</div>
        <div class="fcr-step__description">
          <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
        </div>
        <a href="#" class="button button--secondary">Download the height chart</a>
      </div>
      <div class="fcr-step__image-container">
        <a href="#"><img class="fcr-step__image" src="./images/styleguide/fcr-image.jpg"></a>
      </div>
    </div>
  </div>
  <div class="fcr-step">
    <div class="fcr-step__numbering">
      <div class="fcr-step__number">1</div>
    </div>
    <div class="fcr-step__content">
      <div class="fcr-step__graphic-container">
        <img class="fcr-step__graphic" src="./images/styleguide/fcr-graphic.jpg">
      </div>
      <div class="fcr-step__details">
        <div class="fcr-step__callout-text">Callout Text</div>
        <div class="fcr-step__description">
          <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
        </div>
        <a href="#" class="button button--secondary">Download the height chart</a>
      </div>
      <div class="fcr-step__image-container">
        <a href="#"><img class="fcr-step__image" src="./images/styleguide/fcr-image.jpg"></a>
      </div>
    </div>
  </div>
  <div class="fcr-step">
    <div class="fcr-step__numbering">
      <div class="fcr-step__number">1</div>
    </div>
    <div class="fcr-step__content">
      <div class="fcr-step__graphic-container">
        <img class="fcr-step__graphic" src="./images/styleguide/fcr-graphic.jpg">
      </div>
      <div class="fcr-step__details">
        <div class="fcr-step__callout-text">Callout Text</div>
        <div class="fcr-step__description">
          <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
        </div>
        <a href="#" class="button button--secondary">Download the height chart</a>
      </div>
      <div class="fcr-step__image-container">
        <a href="#"><img class="fcr-step__image" src="./images/styleguide/fcr-image.jpg"></a>
      </div>
    </div>
  </div>
  <div class="fcr-step">
    <div class="fcr-step__numbering">
      <div class="fcr-step__number">1</div>
    </div>
    <div class="fcr-step__content">
      <div class="fcr-step__details">
        <div class="fcr-step__callout-text">Callout Text</div>
        <div class="fcr-step__description">
          <p>Step Type #1 is the "superset". All steps #2 -11 and 13 are permutations of that step, based on what the contributor enters in the "Step" content block.</p>
        </div>
        <a href="#" class="button button--secondary">Download the height chart</a>
      </div>
      <div class="fcr-step__decision">
          <div class="fcr-step__decision-top">
            <div class="fcr-step__decision-text">
              <div class="fcr-step__callout-text">100885 Diverter</div>
              <div class="fcr-step__description">
                <p>The diverter is 1/2" in diameter. Color may vary.</p>
              </div>
              <a href="#" class="button button--secondary">I HAVE THIS ONE <span>(Go to step xx)</span></a>
            </div>
            <div class="fcr-step__decision-image-container">
              <a href="#"><img class="fcr-step__decision-image" src="./images/styleguide/fcr-image.jpg"></a>
            </div>
          </div>
          <label class="conjunction-badge">OR</label>
          <div class="fcr-step__decision-bottom">
            <div class="fcr-step__decision-text">
              <div class="fcr-step__callout-text">14960 Diverter</div>
              <div class="fcr-step__description">
                <p>The diverter is 1/2" in diameter. Color may vary.</p>
              </div>
              <a href="#" class="button button--secondary">I HAVE THIS ONE <span>(Go to step xx)</span></a>
            </div>
            <div class="fcr-step__decision-image-container">
              <a href="#"><img class="fcr-step__decision-image" src="./images/styleguide/fcr-image.jpg"></a>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

Styleguide 4.34.5
*/




/// .repair-issue-description
@include block('repair-issue-description') {
  margin-bottom: 30px;
}


/// .repair-issue-nav
@include block('repair-issue-nav') {
  margin: 0 0 50px 0;
  padding: 0 0 50px 0;
  
  
  /// .repair-issue-nav__list  
  @include child('list') {
    list-style: none;  
  }
  
  
  /// .repair-issue-nav__item
  @include child('item') {
    font-size: 16px;
    margin-bottom: 0.5em;
    
    
    /// .repair-issue-nav__item:last-child  
    &:last-child {
      margin-bottom: 0;
    }
    
  }
  
  
  /// .repair-issue-nav__link
  @include child('link') {
    
  }
  
}
