/* Mixins for Hidden Accessible Text (HAT)
 *
 * usage: <a class="offscreen" href="test.html">this link is offscreen unless it has focus</a>
 */

// Hidden Accessible Text
//
// Technique to hide certain text elements from visual display, but remains exposed to text-to-speech devices.
//
// Markup:
// Completely hidden link: <a class="hat" href="test.html">this link is always in a visually-hidden state</a>
// <br />
// Hidden, but keyboard focus will reveal: <a class="hat-can-focus" href="test.html">this link is visually-hidden, unless it gains focus via user</a>
//
// Styleguide 11.1

@mixin hat-core() {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin undo-hat-core() {
  border: inherit;
  clip: inherit;
  height: auto;
  overflow: inherit;
  position: inherit;
  width: inherit;
}

@mixin hat() {
  @include hat-core();
}

@mixin hat-can-focus() {
  @include hat-core();

  &:focus {
    clip: auto;
    height: auto;
    overflow: auto;
    position: relative;
    width: auto;
  }
}

.hat {
  @include hat-core();
}

.hat-can-focus {
  @include hat-can-focus();
}
