
@include block('preference-quiz') {

  @include child('load') {
    min-height: 200px;
    position: relative;

    @include media($bp-sm) {
      min-height: 300px;
    }
  }

  @include child('error') {
    color: $c_error;
    min-height: 200px;
    position: relative;

    @include media($bp-sm) {
      min-height: 100px;
      padding: 3em 0;
    }

    @include child('title') {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }

  @include child('selection') {
    position: relative;
  }

}



@include block('preference-choice') {

  @include child('list') {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 1020px;
  }

}


@include block('preference-choice-card') {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-start;
  margin: 0 5px 1em;
  max-height: 245px;
  max-width: 490px;
  // overflow: hidden;
  position: relative;
  width: 50%;

  @include child('overlay') {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(126, 126, 126, .1) 60%, rgba(0, 0, 0, 0.63) 100%);
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }

  @include child('image') {

  }

  @include child('title') {
    bottom: -25px;
    // color: $c_moen-bright-blue;
    font-family: $din-light;
    font-size: rem(20);
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;

    @include media($bp-xs) {
      bottom: 2%;
      color: $c_white;
      font-size: rem(26);
    }


    @include modifier('option') {
      font-size: rem(14);

      @include media($bp-xs) {
        font-size: rem(18);
      }

      @include media($bp-sm) {
        font-size: rem(22);
      }

      @include media($bp-md) {
        font-size: rem(26);
      }
    }

  }

  &:hover .preference-choice-card__overlay {
    background: rgba($c_moen-bright-blue, 0.84);
  }

  @include state('selected-choice') {

    &::after {
      background: url('./images/icon-check_selected-white.svg') no-repeat 50% 50%;
      background-size: 100%;
      bottom: 50%;
      content: '';
      height: 100%;
      left: 50%;
      max-width: 100px;
      max-height: 105px;
      position: absolute;
      transform: translateX(-50%) translateY(50%);
      width: 30%;

      @include media($bp-xs) {
        width: 20%;
      }
    }

    .preference-choice-card__overlay {
      background: rgba($c_moen-bright-blue, 0.84);
    }

    .preference-choice-card__title {
      color: $c_moen-bright-blue;

      @include media($bp-xs) {
        color: $c_white;
      }
    }
  }

  @include modifier('option') {
    max-height: 313px;
    max-width: 320px;
    width: 33%;

    &.is-selected-choice::after {
      width: 65%;

      @include media($bp-xs) {
        width: 50%;
      }
    }
  }

}



@include block('preference-quiz-thanks') {
  display: none;
  padding: 3em 0 2em 0; 

  @include modifier('email') {
    
    .preference-quiz--email & {
      display: block;
    }
  }
  
  @include modifier('build') {
    
    .preference-quiz--build & {
      display: block;
    }
  }


  @include child('wrapper') {
    border-top: 1px solid #9a9a9a;
    border-bottom: 1px solid #9a9a9a;
    // box-shadow: 0 3px 3px -2px #a3a3a3;
    // box-shadow: 0 3px 5px -6px #777;
    margin: 0 auto 2em auto;
    max-width: 700px;
    padding: 2em 0 1em 0;
    position: relative;

    &::before {
      border-style: solid;
      border-width: 14px 14px 0 14px;
      border-color: #fff transparent transparent transparent;
      bottom: -13px;
      content: '';
      display: block;
      height: 0;
      left: 50%;
      margin-left: -14px;
      position: absolute;
      width: 0;
      z-index: 1;

      @include media($bp-xs) {
        bottom: -14px;
      }
    }

    &::after {
      border-style: solid;
      border-width: 15px 15px 0 15px;
      border-color: #9a9a9a transparent transparent transparent;
      // border-color: transparent;
      // box-shadow: 0 3px 3px -2px #9a9a9a;
      bottom: -15px;
      content: '';
      display: block;
      height: 0;
      left: 50%;
      margin-left: -15px;
      position: absolute;
      width: 0;
    }
  }

  @include child('title') {

    &::before {
      background: $c_white url('./images/icon-email-signup.svg') 50% 0 no-repeat;
      background-size: 50%;
      content: '';
      display: block;
      height: 53px;
      left: 50%;
      position: absolute;
      top: -30%;
      transform: translateX(-50%) translateY(20%);
      width: 118px;

      @include media($bp-xs) {
        top: -40%;
      }

      @include media($bp-md) {
        top: -50%;
        transform: translateX(-50%) translateY(50%);
      }
    }
  }
}


@include block('preference-group') {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 2rem 0;

  @include child('note') {
    font-size: rem(12);
  }

  @include modifier('is-disabled') {
    .preference-group__title,
    .preference-group__note {
      color: $c_disabled;
    }

    .preference-choice-card {
      cursor: default;

      &:hover .preference-choice-card__overlay {
        background: none;
      }
    }

    .preference-choice-card__overlay {
      background: none;
    }

    .preference-choice-card__image {
      opacity: 0.2;
    }
  }

  @include modifier('is-hidden') {
    display: none;
  }

}


@include block('preference-group-buttoms') {
  padding: 1rem 0 2rem 0;
}


@include block('preference-quiz-confirmation-modal') {

  @include media($bp-md) {
    height: auto;
  }

  @include child('body') {

    @include media($bp-md) {
      padding: 4em 8em;
    }
  }

  &--is-shown & {
    display: block;
  }

}
