
// Typography Variables
// -----------------------------------------------------------------------------
$plmr-font-size-base: plmr-config('font', 'font-size') !default;
$plmr-font-size-xxs: $plmr-font-size-base * 0.75 !default;
$plmr-font-size-xs: $plmr-font-size-base * 0.875 !default;
$plmr-font-size-sm: $plmr-font-size-base * 1.125 !default;
$plmr-font-size-md: $plmr-font-size-base * 1.25 !default;
$plmr-font-size-lg: $plmr-font-size-base * 1.5 !default;
$plmr-font-size-xl: $plmr-font-size-base * 2 !default;
$plmr-font-size-xxl: $plmr-font-size-base * 2.5 !default;
$plmr-font-size-xxxl: $plmr-font-size-base * 3 !default;

$plmr-line-height-base: plmr-config('font', 'line-height') !default;
$plmr-line-height-xxs: 0.9 !default;
$plmr-line-height-xs: 1 !default;
$plmr-line-height-sm: 1.1 !default;
$plmr-line-height-md: 1.5 !default;
$plmr-line-height-lg: 1.75 !default;
$plmr-line-height-xl: 2 !default;
$plmr-line-height-xxl: 2.25 !default;
$plmr-line-height-xxxl: 2.5 !default;



// Font Sizes
// -----------------------------------------------------------------------------
$plmr-font-size: (
  'base': $plmr-font-size-base,
  'xxs': $plmr-font-size-xxs,
  'xs': $plmr-font-size-xs,
  'sm': $plmr-font-size-sm,
  'md': $plmr-font-size-md,
  'lg': $plmr-font-size-lg,
  'xl': $plmr-font-size-xl,
  'xxl': $plmr-font-size-xxl,
  'xxxl': $plmr-font-size-xxxl
);



// Line Heights
// -----------------------------------------------------------------------------
$plmr-line-height: (
  'base': $plmr-line-height-base,
  'xxs': $plmr-line-height-xxs,
  'xs': $plmr-line-height-xs,
  'sm': $plmr-line-height-sm,
  'md': $plmr-line-height-md,
  'lg': $plmr-line-height-lg,
  'xl': $plmr-line-height-xl,
  'xxl': $plmr-line-height-xxl,
  'xxxl': $plmr-line-height-xxxl
);



// Text Properties
// -----------------------------------------------------------------------------
$plmr-text: (
  'alignment': (
    'left': left,
    'right': right,
    'center': center,
    'justify': justify
  ),

  'style': (
    'italic': italic
  ),

  'decoration': (
    'underline': underline,
    'no-underline': none,
    'line-through': line-through,
    'overline': overline
  ),

  'transform': (
    'uppercase': uppercase,
    'lowercase': lowercase,
    'capitalize': capitalize
  ),

  'weight': (
    'light': 200,
    'bold': 600
  )

);



// Function for font-size settings pass a key and return value $plmr-font-size
// -----------------------------------------------------------------------------
@function plmr-font-size($key) {
  @if map-has-key($plmr-font-size, $key) {

    $map: map-get($plmr-font-size, $key);
    @return $map;

  }

  @warn 'Unknown `#{$key}` in $plmr-font-size.';
  @return null;

}



// Function for line-height settings pass a key and return value $plmr-font-size
// -----------------------------------------------------------------------------
@function plmr-line-height($key) {
  @if map-has-key($plmr-line-height, $key) {

    $map: map-get($plmr-line-height, $key);
    @return $map;

  }

  @warn 'Unknown `#{$key}` in $plmr-line-height.';
  @return null;

}





