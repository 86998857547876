
/// .menu-group
@include block('menu-group') {
  margin: 0 0 25px;
  
  
  /// .menu-group__title
  @include child('title') {
    font-size: 18px;
  }
  
  
  /// .menu-group__sub-title
  @include child('sub-title') {
    font-size: 14px;
    margin-bottom: 15px;
  }
  
}



@include block('mobile-menu-group') {
  margin: 0 20px 25px;
}
