/*
Close button

Used in association with collapsible containers seen on the contact us, product finder, etc.

Button is the recommended HTML object over an anchor tag, since it controls a UI action and not a hypertext navigation

Note: the container shown here has been artifically set with a height, since the button is absolutely positioned.

markup:
<div class="container container--main" style="height: 100px">
  <button class="button-close button-close--pin-top-left"><span>close</span><img src="./images/icon-close-gray.svg" alt="" aria-hidden="true" class="icon-close" /></button>
</div>

Styleguide 3.3.5
*/

@include block(button-close) {
  padding: 0  0 0 .5em; // allows icon to sit flush-right
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include modifier(pin-top-left) {
    position: absolute;
    top: 0;
    right: 0;
  }

  @include block(icon-close) {
    width: 2em;
    height: 2em;
    margin-left: 1em;
  }
}
