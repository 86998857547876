
/**
@doc _docs/carousel-collage.md
@section Carousel Collage
@childof Carousels
@page Carousels Carousel Collage
@status ready
@hippo banner carousel
@example
_markup/carousel-collage.hbs
*/
// .plmr-c-carousel-collage
@include plmr-component('carousel-collage') {
  background: plmr-color('background', 'secondary');
  position: relative;
  z-index: 1;

  // .plmr-c-carousel-collage__container
  @include element('container') {
    @extend .plmr-l-container;
    padding: 0;
    position: relative;
  }

  // .plmr-c-carousel-collage__cta
  @include element('cta') {
    display: block;
    padding: 0;
  }

  // .plmr-c-carousel-collage__description
  @include element('description') {
    background-color: plmr-color('base', 'white');
    max-width: plmr-config('container', 'max-width');
    opacity: 1;
    padding: 30px 192px 145px 25px;
    position: relative;
    transition: all 0.75s ease;
    z-index: 2;


    @include plmr-mq(md) {
      margin: -54px auto 0;
      padding: 40px 360px 60px 30px;
    }


    @include plmr-mq(lg) {
      margin: -130px auto 0;
      min-height: 235px;
      padding: 40px 511px 50px 50px;
      width: calc(100% - 180px);
    }

    // .plmr-c-carousel-collage__description._hidden
    @include plmr-state('hidden') {
      opacity: 0;
    }

  }

  @include element('description-v2') {
    padding: 30px 192px 35px 25px;
  }

  // .plmr-c-carousel-collage__eyebrow
  @include element('eyebrow') {
    @include plmr-font-face($family: 'normal');
    color: plmr-color('text', 'tertiary');
    display: none;
    line-height: 1.5rem;
    font-size: 1.1428571429rem;
    margin: 0;
    padding-bottom: 15px;

    @include plmr-mq(md) {
      display: block;
    }

  }

  @include element('eyebrow-v2') {
    line-height: 2rem;
  }

  // .plmr-c-carousel-collage__image
  @include element('image') {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;

    @supports(object-fit: cover) {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }


  }

  // .plmr-c-carousel-collage__item
  @include element('item') {
    box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
    font-size: 0;
    opacity: 1;
    position: relative;
    transform: translateX(0);
    transition: all 0.75s ease;

    // .plmr-c-carousel-collage__item--large
    @include modifier('large') {
      box-shadow: none;
      width: 100%;
      z-index: 1;

      @include plmr-mq(md) {
        height: 413px;
        width: 77%;

        // Force the image to fit the container height
        .slick-slider,
        .slick-list,
        .slick-track,
        .slick-slide {
          height: 100%;
        }

      }


      @include plmr-mq(lg) {
        height: 700px;
      }

    }

    @include modifier('large-v2') {
      @include plmr-mq(md) {
        height: auto;
        width: 100%;
      }
      @include plmr-mq(lg) {
        height: auto;
      }

    }

    // .plmr-c-carousel-collage__item--medium
    @include modifier('medium') {
      position: absolute;
      right: 0;
      top: 230px;
      width: 172px;
      z-index: 3;


      @include plmr-mq(md) {
        height: 399px;
        top: 47px;
        width: 293px;
      }


      @include plmr-mq(lg) {
        height: 550px;
        right: 40px;
        top: 52px;
        width: 404px;
      }


    }

    // .plmr-c-carousel-collage__item--small
    @include modifier('small') {
      display: none;
      z-index: 4;


      @include plmr-mq(md) {
        display: block;
        height: 147px;
        position: absolute;
        right: 85px;
        top: 413px;
        width: 298px;
      }


      @include plmr-mq(lg) {
        height: 200px;
        right: 142px;
        top: 500px;
        width: 404px;
      }


    }

    // .plmr-c-carousel-collage__item._hidden
    @include plmr-state(hidden) {
      opacity: 0;
      transform: translateX(-20px);
    }


  }

  // .plmr-c-carousel-collage__nav
  @include element('nav') {
    @include plmr-font-face($family: 'italic');
    bottom: 75px;
    font-size: 0.875rem;
    font-style: italic;
    left: 25px;
    line-height: 30px;
    padding: 0 42px;
    position: absolute;

    // .plmr-c-carousel-collage__nav .plmr-c-carousel__arrow--prev
    .plmr-c-carousel__arrow--prev {
      left: 0;
    }

    // .plmr-c-carousel-collage__nav .plmr-c-carousel__arrow--next
    .plmr-c-carousel__arrow--next {
      right: 0;
    }

    @include plmr-mq(md) {
      bottom: auto;
      left: auto;
      right: 115px;
      top: 230px;
    }

    @include plmr-mq(lg) {
      right: 189px;
      top: 160px;
    }

  }

  @include element('nav-v2') {
    bottom: auto;
    left: auto;
    right: 30px;
    top: 100px;
    @include plmr-mq(md) {
      bottom: auto;
      left: auto;
      right: 30px;
      top: 130px;
    }

    @include plmr-mq(lg) {
      right: 30px;
      top: 105px;
    }

  }

  // .plmr-c-carousel-collage__nav-text
  @include element('nav-text') {
    display: block;
  }

  // .plmr-c-carousel-collage__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.333;
    margin: 0;
    padding-bottom: 20px;

    @include plmr-mq(md) {
      font-size: 2rem;
      line-height: 1.125;
    }


  }

  // .plmr-c-carousel-collage__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
  }

}



