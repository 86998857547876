//.plmr-c-featured-product-specs
@include plmr-component('featured-product-specs') {
  position: relative;

  &::after {
    @include plmr-pseudo;
    background-color: plmr-color('accent', 'light');
    bottom: 0;
    height: 1px;
    left: 50%;
    max-width: 960px;
    transform: translateX(-50%);
    width: calc(305 / 375 * 100%);
    z-index: 1;

    @include plmr-mq('md') {
      width: calc(598 / 768 * 100%);
    }
  }

  //.plmr-c-featured-product-specs__container
  @include element('container') {
    // font-size: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 47.5px 15px 32.5px;
    // text-align: center;

    @include plmr-mq('md') {
      justify-content: space-between;
      padding: 65px 115px 58px;
    }

    @include plmr-mq('lg') {
      flex-wrap: nowrap;
      justify-content: center;
      padding: 75px 0 19px;
      // padding-left: calc(91 / 1300 * 100%);
      // padding-right: calc(91 / 1300 * 100%);
      padding-left: calc(38 / 1300 * 100%);
      padding-right: calc(38 / 1300 * 100%);
    }

    @include plmr-mq('xl') {
      // padding-left: 91px;
      // padding-right: 91px;
      padding-left: 38px;
      padding-right: 38px;
    }
  }

  //.plmr-c-featured-product-specs__item
  @include element('item') {
    flex: 1 1 50%;
    margin: 27.5px 0;
    padding: 0 15px;
    text-align: center;

    @include plmr-mq('md') {
      margin: 35px 0;

      &:nth-child(odd) {
        padding-left: 0;
        padding-right: calc((100% - 400px) / 2);
      }

      &:nth-child(even) {
        padding-left: calc((100% - 400px) / 2);
        padding-right: 0;
      }
    }

    @include plmr-mq('lg') {
      margin-left: calc(53 / 1224 * 100%);
      margin-right: calc(53 / 1224 * 100%);
      max-width: 200px;
      min-width: 180px;

      &:nth-child(odd) {
        padding-right: 0;
      }

      &:nth-child(even) {
        padding-left: 0;
      }
    }

    @include plmr-mq('xl') {
      margin-left: 53px;
      margin-right: 53px;
    }

    @include plmr-mq('max') {
      margin-left: 53px;
      margin-right: 53px;
    }
  }

  //.plmr-c-featured-product-specs__item-text
  @include element('item-text') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0;
    text-transform: capitalize;
  }

  //.plmr-c-featured-product-specs__item-name
  @include element('item-name') {
    font-size: 1.143rem;
    line-height: 1.285rem;
    margin: 0 auto;
    max-width: 200px;
    padding-bottom: 0;
    padding-top: 20px;

    @include plmr-mq('md') {
      font-size: 1.285rem;
      padding-top: 30px;
    }
  }

  //.plmr-c-featured-product-specs__item-info
  @include element('item-info') {
    display: block;
    margin-top: 5px;
    padding: 0;

    @include plmr-mq('md') {
      margin-top: 0;
      vertical-align: bottom;
    }
  }

  //.plmr-c-featured-product-specs__item-info-icon
  @include element('item-info-icon') {
    height: 15px;
    width: 15px;

    @include plmr-mq('md') {
      margin-left: 9px;
    }
  }

  //.plmr-c-featured-product-specs__item-info-flag
  @include element('item-info-flag') {
    min-width: 100%;

    @include plmr-mq('md') {
      min-width: 200px;
    }

    @include plmr-mq('lg') {
      min-width: 225px;
    }
  }

  //.plmr-c-featured-product-specs__item-icon
  @include element('item-icon') {
    flex: 1 1 100%;
    max-height: 50px;
    width: auto;
  }

  //.plmr-c-featured-product-specs__item-icon-wrap
  @include element('item-icon-wrap') {
    color: plmr-color('brand', 'primary');
    display: flex;
  }
}

//.plmr-c-featured-product-specs
@include plmr-component('featured-product-specs') {
  //.plmr-c-featured-product-specs--condensed
  @include modifier('condensed') {
    .plmr-c-featured-product-specs__container {
      justify-content: space-between;
      padding: 25px 0;
      padding-left: calc(18.66667% / 2);
      padding-right: calc(18.66667% / 2);

      @include plmr-mq('md') {
        padding: 20px 145px;
      }

      @include plmr-mq('lg') {
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-left: calc(188 / 1300 * 100%);
        padding-right: calc(188 / 1300 * 100%);
      }

      @include plmr-mq('xl') {
        padding-left: 193px;
        padding-right: 193px;
      }
    }

    .plmr-c-featured-product-specs__item {
      margin: 15px 0;
      text-align: left;

      &:nth-child(odd) {
        padding-left: 0;
        padding-right: calc((100% - 290px) / 2);
      }

      &:nth-child(even) {
        padding-left: calc((100% - 290px) / 2);
        padding-right: 0;
      }

      @include plmr-mq('md') {
        margin: 20px 0;

        &:nth-child(odd) {
          padding-right: calc((100% - 356px) / 2);
        }

        &:nth-child(even) {
          padding-left: calc((100% - 356px) / 2);
        }
      }

      @include plmr-mq('lg') {
        max-width: 176px;
        min-width: 176px;

        &:nth-child(odd) {
          padding-right: 0;
        }

        &:nth-child(even) {
          padding-left: 0;
        }

        &:not(:nth-child(4n + 1)) {
          margin-left: calc((100% - (176px * 4)) / 3);
        }
      }

      @include plmr-mq('max') {
        &:not(:nth-child(4n + 1)) {
          // margin-left: calc((100% - (176px * 5)) / 5);
        }
      }
    }

    .plmr-c-featured-product-specs__item-name {
      margin-left: 0;
      padding-top: 0;

      @include plmr-mq('md') {
        font-size: 1.143rem;
      }
    }

    .plmr-c-featured-product-specs__item-text {
      padding-top: 10px;
    }

    .plmr-c-featured-product-specs__item-info {
      width: 0;

      @include plmr-mq('md') {
        width: auto;
      }
    }

    .plmr-c-featured-product-specs__item-info-flag {
      min-width: 200px;

      @include plmr-mq('md') {
        bottom: auto;
        left: auto;
        margin: 0;
        margin-right: 10px;
        right: 100%;
        top: 50%;
        transform: translate(0, -50%);

        // .plmr-c-featured-product-specs__item-info-flag::before
        &::before {
          left: auto;
          right: -17px;
          top: 50%;
          transform: translate(0, -50%) rotate(-90deg);
        }
      }

      @include plmr-mq('lg') {
        min-width: 225px;
      }
    }
  }
}
