@include plmr-component('mastfoot-sina') {

  //.plmr-c-mastfoot-sina__box
  @include element('box'){
    padding-top: 0;
  }

  @include element('title'){
        margin-bottom: 5px;
       a{
         color: #FFFFFF;
       }
  }

  //.plmr-c-mastfoot-sina__text
  @include element('text'){
    display: inline-block;
    vertical-align: middle;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }


  //.plmr-c-mastfoot-sina__btn
  @include element('btn'){
     a{
       width: 110px;
       background: #5b7f95;
       color: #fff;
       text-decoration: none;
       padding: 10px 20px;
       margin-top: 15px;
       margin-left: 20px;
       display: inline-block;
       text-align: center;
     }
  }

}
