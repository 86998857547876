// Brand Typography Overview
//
//	DIN creates a distinctive visual impression
//	in our messaging to complement the other
//	design elements in our toolkit.
//
//	DIN Light is used for all principal text
//	applications, including messaging, body copy,
//	titles and headlines.
//
//	DIN Regular is used for all supporting text
//	applications, including captions, text blocks
//	and callouts. It may be used as needed for
//	body copy in PowerPoint® presentations to
//	ensure text legibility on screen.
//
//	DIN Bold is used when emphasis is required.
//
//  The "buy it for" campaign leverages Proxima Nova
//  All BIF Campaign headlines are stylized in lowercase and will always begin with “buy it for” in Proxima Nova Bold. The second part of the headline is always Proxima Nova Thin.
//
//  BIF Campaign body copy utilizes sentence case and is in Proxima Nova Regular for legibility. In instances where we feature a CTA, we use Proxima Nova Bold to draw attention.
//
// Styleguide 1.2.0

// DIN Webfont Samples
//
// <ul>
// <li>FF DIN light - [describe typical usage rules here]...</li>
// <li>FF DIN Regular - [describe typical usage rules here]...</li>
// <li>FF DIN Medium - [describe typical usage rules here]...</li>
// <li>FF DIN Regular - [describe typical usage rules here]...</li>
// <li>FF Proxima Nova Thin - [describe typical usage rules here]...</li>
// <li>FF Proxima Nova Regular - [describe typical usage rules here]...</li>
// <li>FF Proxima Nova Bold - [describe typical usage rules here]...</li>
// </ul>
//
// markup:
//	<p class="din-light">FF Din Light, <b>Bold,</b> <i>Oblique</i></p>
//	<p class="din-regular">FF Din Regular, <b>Bold,</b> <i>Oblique</i></p>
//	<p class="din-medium">FF Din Medium, <b>Bold,</b> <i>Oblique</i></p>
//	<p class="din-bold">FF Din Bold, <b>Bold,</b> <i>Oblique</i></p>
//	<p class="proxima-nova-thin">FF Proxima Nova Thin, <b>Bold,</b> <i>Oblique</i></p>
//	<p class="proxima-nova-regular">FF Proxima Nova Regular, <b>Bold,</b> <i>Oblique</i></p>
//	<p class="proxima-nova-bold">FF Proxima Nova Bold, <b>Bold,</b> <i>Oblique</i></p>
//
// Styleguide 1.2.1

.din-light {
  font-family: DINWebLight;
  & b,
  & strong {
    font-family: DINWebRegular;
  }
}

.din-regular {
  font-family: DINWebRegular;
  & b,
  & strong {
    font-family: DINWebBold;
  }
}

.din-medium {
  font-family: DINWebMedium;
  & b,
  & strong {
    font-family: DINWebBold;
  }
}

.din-bold {
  font-family: DINWebBold;
  & b,
  & strong {
    font-family: DINWebBold;
  }
}

.proxima-nova-thin {
  font-family: proxima-nova-thin;
  & b,
  & strong {
    font-family: proxima-nova-regular;
  }
}

.proxima-nova-regular {
  font-family: proxima-nova-regular;
  & b,
  & strong {
    font-family: proxima-nova-bold;
  }
}

.proxima-nova-bold {
  font-family: proxima-nova-bold;
  & b,
  & strong {
    font-family: proxima-nova-bold;
  }
}
