@include block(homepage) {
  @include element(replacement-form) {
    margin-top: 1.5em;
    max-width: 420px;
    margin: 0 auto;
  }

  @include element(replacement-stamp) {
    display: none;
    @include media($bp-md) {
      height: auto;
      left: -230px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 250px;
      & > img {
        max-height: none !important;
        height: auto;
        max-width: 100%;
      }
      display: block;
    }
  }

  @include element(replacement) {
    background-color: $c_home_replacement_bg;
    .form-input__text {
      color: $c_text-dark;
      background-color: $c_home_replacement_inputs;
      padding: 1em;
      
      // Changing the placeholder text
      // cannot let to nest styles in the sass way
      // (or it could be caused by the bemify plugin)
      // like : 
      // &::-webkit-input-placeholder,
      // &:-moz-placeholder {
      //  property: value
      // }
      &::-webkit-input-placeholder {
        color: $c_text-dark;
      }
      &:-moz-placeholder {
        color: $c_text-dark;
      }
      &::-moz-placeholder {
        color: $c_text-dark;
      }
      &:-ms-input-placeholder {
         color: $c_text-dark;
      }
    }

    .input-add-on {
      .button {
        background-color: $c_home_replacement_inputs;
        margin-top: 0;
        width: auto;
      }
    }

    .banner__inner {
      height: 400px;
    }

    .banner--text-bg-white {
      .text-box__inner {
        background-color: $c_home_replacement_text_box;
        max-width: 680px;
        padding: 3em 1em;
        width: 95%;
        @include media($bp-md) {
          padding: 3em 2em;
          width: 50%;
        }
      }

      .text-box__head {
        margin-bottom: .5em;
      }

      .text-box__body {
        font-size: 1em;
        margin-bottom: 1em;
      }
    }

    .find_model_number {
      display: block;
      margin-top: 1em;
    }
  }
  
  @include element(replacement-columned) {
    background-color: $c_concrete;
    padding: 20px 0px 40px;
    @include media($bp-md) {
      padding: 20px 0px 60px;
    }
    @include element(logo) {
      width: 100px;
      height: auto;
      display: inline-block;
      margin-bottom: 20px;
    }
    .section-heading--3 {
      font-size: em(24);
    }
    form {
      position: relative;
      margin-bottom: 15px;
      input {
        text-transform: none;
        padding: 18px 50px 16px 17px;
        border: solid 1px $c_moen-bright-gray;
        width: 100%;
        outline: none;
        transition: border-color 0.3s ease;
      }
      input:hover,input:focus {
        border-color: darken($c_moen_bright_gray,31%);
      }
      input:focus::-webkit-input-placeholder { color:transparent; }
      input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
      input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
      input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
      button {
        position: absolute;
        top:50%;
        right: 19px;
        transform: translateY(-50%);
        width: 19px;
        height: 19px;
        background-image: url('images/icon-search-bright-blue.svg');
        background-size: 19px auto;
        background-position: center center;
        outline: none;
      }
      & + a {
        font-size: em(14);
      }
    }
    .col-md-6:first-child {
      padding-bottom: 40px;
      border-bottom: solid 1px rgba($c_moen-gray, 0.25);
      @include media($bp-md) {
        padding-bottom: 0;
        padding-right: 30px;
        border-bottom: none;
        position: relative;
        &:after {
          content:' ';
          display: block;
          width: 1px;
          height: 250px;
          position: absolute;
          top:50%;
          transform: translateY(-50%);
          right:-6.5%;
          background-color: rgba($c_moen-gray,0.25);
        }
      }
      form,p {
        max-width: 375px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .col-md-6:last-child {
      padding-top: 40px;
      @include media ($bp-md) {
        padding-left: 30px;
      }
      a {
        display: inline-block;
        font-family: $din-bold;
        font-size: em(14);
        padding-bottom: 5px;
        img {
          margin-left: 10px;
        }
      }
      
      p {
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
