/**
Collection of buttons.
@section Buttons
@childof Components
@page Buttons
@nobrandtoggle
*/


// .plmr-c-button
@include plmr-component('button') {
  @include plmr-font-face($family: 'bold');
  border: 0;
  border-radius: 2px;
  color: plmr-color('base', 'white');
  cursor: pointer;
  display: inline-block;
  font-size: plmr-font-size('xxs');
  letter-spacing: 2px;
  line-height: 8px;
  padding: 0.85rem 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.5s ease;


  // .plmr-c-button:hover, .plmr-c-button:focus, .plmr-c-button:active
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }


  // .plmr-c-button:focus
  &:focus {
    // outline: 2px dashed plmr-color('background', 'secondary');
    outline-offset: -1px;
  }

  // .plmr-c-button[disabled]
  &[disabled] {
    background-color: plmr-color('accent', 'light');
    pointer-events: none;
  }

  @include plmr-mq(md) {
    padding: 1rem 1.6rem;
  }

  // .plmr-c-button__icon
  @include element('icon') {
    display: inline-block;
    height: auto;
    line-height: 0;
    margin-left: 15px;
    max-height: 15px;
    min-width: 15px;
    vertical-align: text-top;
    width: auto;
  }
}


// .plmr-c-button-link
@include plmr-component('button-link') {
  @include plmr-font-face($family: 'bold');
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: plmr-font-size('xxs');
  letter-spacing: 2px;
  line-height: 8px;
  padding: 0 0 0.625rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.5s ease;
  white-space: nowrap;


  // .plmr-c-button-link::after
  &::after {
    background-color: currentColor;
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }


  // .plmr-c-button-link:hover, .plmr-c-button-link:focus, .plmr-c-button-link:active
  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;

    &::after {
      height: 3px;
    }
  }


  // .plmr-c-button-link:focus
  &:focus {
    // outline: 2px dashed plmr-color('background', 'secondary');
    outline-offset: -1px;
  }
}




// Mixin to change Button hover background color
// -----------------------------------------------------------------------------
@mixin plmr-button-hover($background, $text: plmr-color('base', 'white')) {

  &:hover,
  &:focus,
  &:active {
    background-color: darken($background, 6%);
    color: $text;
  }

}




// Mixin to change Button Link hover color
// -----------------------------------------------------------------------------
@mixin plmr-button-link-hover($text) {

  &:hover,
  &:focus,
  &:active {
    color: darken($text, 6%);
  }

}



@import 'button-primary';

@import 'button-secondary';

@import 'button-tertiary';

@import 'button-link';

// @import 'button-link-primary';

// @import 'button-link-secondary';

// @import 'button-link-tertiary';

@import 'button-link-icon';

@import 'button-scroll-arrow';

@import 'button-play-icon';

@import 'button-expand-toggle';




