
// img[alt=""] {
//   border: 5px dashed orange;
// }


// img:not([alt]) {
//   border: 5px dashed hotpink;
// }



@include plmr-object('debug') {

  // blank alt text
  img[alt=""] {
    border: 5px dashed orange;
  }

  // alt text has been forgotten
  img:not([alt]) {
    border: 5px dashed hotpink;
  }

}
