
/// .brand-logo
@include block('brand-logo') {
  
  
  /// .brand-logo__link
  @include child('link') {
    display: inline-block;
  }
  
  
  /// .brand-logo__image
  @include child('image') {
    display: block;
  }
}
