
// .plmr-c-nav-footer
@include plmr-component('nav-footer') {
  display: none;


  // .plmr-c-nav-footer._visible-on-md
  @include plmr-state('visible-on-md') {

    // screen width > 768px
    @include plmr-mq('md') {
      display: flex;
      justify-content: flex-start;
    }

  }


  // .plmr-c-nav-footer__list
  @include element('list') {
    display: flex;
    flex-direction: column;
  }


  // .plmr-c-nav-footer__item
  @include element('item') {
    margin-bottom: 15px;

    // .plmr-c-nav-footer__item:last-child
    &:last-child {
      margin-bottom: 0;
    }

  }


  // .plmr-c-nav-footer__link
  @include element('link') {
    @include plmr-font-face($family: 'bold');
    @include plmr-link($color: plmr-color('base', 'white'), $is-color-hover: false);
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;
  }

}




