/**
@doc _docs/banner-article-split.md
@section Banner Article Split
@childof Banners
@page Banners Banner Article Split
@status ready
@hippo banner
*/

/**
@section Image Left
@sectionof Banner Article Split
@page Banners Banner Article Split
@example
_markup/banner-article-split--left.hbs
*/

/**
@section Image Right
@sectionof Banner Article Split
@page Banners Banner Article Split
@example
_markup/banner-article-split--right.hbs
*/

// .plmr-c-banner-article-split
@include plmr-component('banner-article-split') {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: plmr-config('container', 'max-width');
  min-width: plmr-config('container', 'min-width');
  padding-bottom: 30px;
  width: 100%;


  // screen width > 768px
  @include plmr-mq('md') {
    flex-direction: row;


    // .plmr-c-banner-article-split--right
    @include modifier('right') {
      flex-direction: row-reverse;
    }

  }


  // .plmr-c-banner-article-split__image-container
  @include element('image-container') {
    align-self: auto;
    // background-position: center center;
    // background-size: cover;
    flex: 0 0 100%;
    // min-height: 250px;
    order: 0;
    position: relative;


    /// screen width > 768px
    @include plmr-mq('md') {
      flex: 0 0 60%;
      min-height: 300px;
    }

  }


  // .plmr-c-banner-article-split__image
  @include element('image') {
    display: block;
    height: auto;
    width: 100%;
  }


  // .plmr-c-banner-article-split__image-link
  @include element('image-link') {
    display: block;
    // height: 100%;
    // left: 0;
    // position: absolute;
    // top: 0;
    // width: 100%;
  }



  // .plmr-c-banner-article-split__description
  @include element('description') {
    align-self: auto;
    flex: 0 1 100%;
    order: 0;
    padding: 20px 25px;
    text-align: center;


    // screen width > 768px
    @include plmr-mq('md') {
      flex: 0 1 40%;
      padding: 25px;
      text-align: left;
    }

  }


  // .plmr-c-banner-article-split__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.5rem;
    line-height: 1.333;
    margin: 0;
    padding-bottom: 15px;


    // screen width > 768px
    @include plmr-mq('md') {
      font-size: 2rem;
      line-height: 1.125;
    }

  }


  // .plmr-c-banner-article-split__title-link
  @include element('title-link') {
    @include plmr-link($color: plmr-color('brand', 'primary'), $is-underline-hover: true);
  }


  // .plmr-c-banner-article-split__eyebrow
  @include element('eyebrow') {
    @extend .plmr-c-banner__title--italic;
    font-size: 16px;
    line-height: 21px;


    // screen width > 768px
    @include plmr-mq(md) {
      font-size: 18px;
      line-height: 24px;
    }

  }


  // .plmr-c-banner-article-split__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
    margin-bottom: 3rem;
  }


  // .plmr-c-banner-article-split__link
  @include element('link') {
    @include plmr-link($color: plmr-color('brand', 'tertiary'), $underline: true, $is-underline-hover: true);
  }


  @include element('copy__h1') {
    font-size: 24px;
    margin-bottom: 43px;
    display: inline-block;
    box-sizing: border-box;

    @include plmr-mq(md) {

      display:inline-block;
      font-size: 22px;
      margin-bottom: 68%;
      box-sizing: border-box;
    }
    @include plmr-mq(lg) {
      display:inline-block;
      font-size: 28px;
      margin-bottom: 78%;
      box-sizing: border-box;
    }
    @media screen and (max-width: 1200px) {
      font-size:26px;
    }
  }


}

