/*
Collection of lightbox popup skins
@section Lightbox
@sectionof Components
@page Components

*/


/*
Default lightbox popup
@section Lightbox Default
@sectionof Components.Lightbox
@page Components
@status in progress
@example
_markup/lightbox.hbs
*/
@include plmr-component('lightbox') {
  background: rgba(plmr-color('text', 'primary'), 0.75);
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: 0.5s ease;
  width: 100%;
  z-index: 1;

  // .plmr-c-lightbox._active
  @include plmr-state('active') {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }

  // .plmr-c-lightbox__dialog
  @include element('dialog') {
    background: plmr-color('base', 'white');
    bottom: auto;
    color: inherit;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    max-height: 94%;
    max-width: plmr-config('container', 'max-width');
    min-width: plmr-config('container', 'min-width');
    padding: 0;
    position: fixed;
    right: auto;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    z-index: 1;
  }

  // .plmr-c-lightbox__skin
  @include element('skin') {
    border-color: plmr-color('base', 'white');
    border-width: 48px 10px 10px;
    display: block;
    height: auto;
    width: 100%;
  }

  // .plmr-c-lightbox__inner
  @include element('inner') {
    height: 0;
    overflow: hidden;
    padding-top: 52.68293%;
    position: relative;
  }

  // .plmr-c-lightbox__close
  @include element('close') {
    color: plmr-color('brand', 'secondary');
    display: block;
    height: 15px;
    position: absolute;
    right: 10px;
    top: 12px;
    width: 15px;
    z-index: 3;

    // .plmr-c-lightbox__close:hover
    &:hover {
      cursor: pointer;
    }

    @include plmr-mq(md) {
      height: 25px;
      width: 25px;
    }
  }

  // .plmr-c-lightbox__media
  @include element('media') {
    bottom: 0;
    height: auto;
    left: 0;
    max-height: 94vh;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}
