
// .plmr-c-modal
@include plmr-component('modal') {
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: 0.5s ease;
  width: 100%;
  z-index: plmr-z('modal');


  // .plmr-c-modal__overlay
  @include element('overlay') {
    background: rgba(plmr-color('text', 'primary'), 0.75);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }


  // .plmr-c-modal__dialog
  @include element('dialog') {
    background: plmr-color('base', 'white');
    bottom: auto;
    color: inherit;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    max-height: 94%;
    max-width: plmr-config('container', 'max-width');
    min-width: plmr-config('container', 'min-width');
    overflow: auto;
    padding: 0;
    position: fixed;
    right: auto;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    z-index: 1;
  }


  // .plmr-c-modal__skin
  @include element('skin') {
    border-color: plmr-color('base', 'white');
    border-width: 48px 10px 10px;
    display: block;
    height: auto;
    width: 100%;
  }


  // .plmr-c-modal__close
  @include element('close') {
    color: plmr-color('brand', 'secondary');
    display: block;
    height: 15px;
    position: absolute;
    right: 10px;
    top: 12px;
    width: 15px;
    z-index: 3;


    // .plmr-c-modal__close:hover
    &:hover {
      cursor: pointer;
    }


    // screen width > 768px
    @include plmr-mq(md) {
      height: 25px;
      width: 25px;
    }

  }


  // .plmr-c-modal__title
  @include element('title') {
    @include plmr-font-face($family: bold);
    color: plmr-color('brand', 'primary');
    font-size: 22px;
    margin-bottom: 0.5em;
  }


  // .plmr-c-modal__media-container
  @include element('media-container') {
    margin-bottom: 1rem;
    max-height: calc(94vh - 125px);
    overflow: hidden;
    padding-top: 52.68293%;
    position: relative;
  }


  // .plmr-c-modal__media
  @include element('media') {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }


  // .plmr-c-modal__image
  @include element('image') {
    display: block;
    height: auto;
    margin-bottom: 1rem;
    width: 100%;
  }


  // .plmr-c-modal__copy
  @include element('copy') {
    font-size: 1.25rem;
  }

}
