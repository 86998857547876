/*
Featured Product

Video card with product tile

Markup:
<div class="featured-product">
  <div class="featured-product__right">
    <div class="card-video card-video--fullwidth">
      <a href="#" class="card-video__link js-modal" data-modal-content-id="video-how-to-remove-a-garbage-disposal">
        <div class="card-video__image">
          <img src="css/images/featured-product/U_ByMoen2.png" />
        </div>
        <div class="card-video__title">
          customize, control and pause your shower
        </div>
      </a>
      <div class="is-hidden" id="video-how-to-remove-a-garbage-disposal">
        <iframe class="homepage__video-modal" src="https://www.youtube.com/embed/qIBnbPJSBtQ?rel=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
    </div>
  </div>
  <div class="featured-product__left">
    <a class="tile product-tile" href="#">
      <div class="product-tile__like"></div>
      <div class="product-tile__head">
        <img src="css/images/featured-product/u.png" class="product-tile__image" />
        <p class="product-tile__name">U By Moen Shower Terra Digital Shower Controller</p>
      </div>
      <div class="product-tile__body">
        <div class="rating product-tile__rating">
          <div class="stars">
            <div class="ratings-meter">
              <div class="cutouts"><span style="width:95.676%;"></span></div>
            </div>
            <div class="rating">4.8</div>
            <div class="review-count">(6)</div>
          </div>
        </div>
        <p class="cta">See the Details &gt;</p>
      </div>
    </a>
  </div>
</div>

Styleguide #.#.1
*/
@include block("featured-product") {
  max-width: 840px;
  margin:0 auto;
  @include media($bp-md) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  @include element("left") {
    display: block;
    
    .product-tile {
      width: 100%;
      max-width: 230px;
      text-align: left;
      position: relative;
      padding:0;
      padding-left: 56px;
      background-color: transparent !important;
      margin-left:auto;
      margin-right: auto;
      @include media($bp-md) {
        padding-left: 110px;
        margin-left: 0;
        width: 280px;
        max-width: 280px;
      }
      .product-tile__sku {
        display: none;
      }
      .product-tile__body {
        .rating {
          justify-content: flex-start;
          .rating {
            display: none;
          }
          .ratings-meter {
            height: 10px;
            width: 70px;
            .cutouts:before {
              width: 76px;
              content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20130%2022%22%3E%3Cstyle%20type%3D%22text/css%22%3E%20%20%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C/style%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M0%200v22h130V0H0zM18.2%2020L12%2016l-6.2%204%202.1-6.8L2%208.8h7.5L12%202l2.5%206.8H22l-5.8%204.4L18.2%2020zM44.8%2020l-6.2-4%20-6.2%204%202.1-6.8%20-5.8-4.4H36L38.5%202%2041%208.8h7.5l-5.8%204.4L44.8%2020zM71.2%2020L65%2016l-6.2%204%202.1-6.8L55%208.8h7.5L65%202l2.5%206.8H75l-5.8%204.4L71.2%2020zM97.8%2020l-6.2-4%20-6.2%204%202.1-6.8%20-5.8-4.4H89L91.5%202%2094%208.8h7.5l-5.8%204.4L97.8%2020zM124.2%2020l-6.2-4%20-6.2%204%202.1-6.8L108%208.8h7.5L118%202l2.5%206.8h7.5l-5.8%204.4L124.2%2020z%22/%3E%3C/svg%3E") !important;
              height: auto;
              top: -1px;
              top: -1px;
              top: -1px;
            }
          }
          .review-count {
            font: normal em(10) $din-regular;
            color: $c_moen-bright-blue;
            margin-left: 7px;
          }
        }
        .cta {
          font: normal em(14) $din-bold;
          color: $c_moen-bright-blue;
          margin-left: 6px;
        }
      }
      .product-tile__image {
        position: absolute;
        top:0%;
        left: 0;
        width: 31px;
        height: auto;
        //transform: translateY(-50%);
        margin-bottom: 0;
        @include media($bp-md) {
          width: 62px;
        }
      }
      .product-tile__name {
        color: $c_text-base;
        font: normal em(14) $din-regular;
        padding-top:0;
      }
      
    }
  }
  @include element("right") {
    display: block;
    margin-bottom: 30px;
    flex: 1 1 0px; // IE FIX
    @include media($bp-md) {
      width: 50%;
      margin-bottom: 0;
    }
    @include media($bp-lg) {
      width: auto;
    }
    .card-video {
      margin-bottom: 0;
    }
    .card-video__link {
      text-decoration: none;
    }
    .card-video__image {
      padding-bottom: 0px;
      text-align: center;
      &:before {
        width: 40px;
        height: 40px;
        @include media($bp-md) {
          width: 65px;
          height: 65px;
        }
      }
      &:after {
        display: none;
      }
      &>:first-child {
        position: static;
        width: auto;
        height: auto;
        max-width: 100%;
      }
    }
    .card-video__title {
      background: none transparent;
      color: $c_text-base;
      text-align: center;
      position: static;
      padding: 0;
      font-family: $din-regular;
      font-weight: normal;
      font-size: em(14);
      @include media($bp-md) {
        font-size: em(18);
      }
      @include media($bp-lg) {
        font-size: em(21);
      }
    }
    
  }
  
}
