
@include block('w2b-hero') {
  margin-bottom: 2em !important;
  margin-top: 2.5em !important;
  
  // -------------------------------------
  //  Product Title
  // -------------------------------------  
  @include child('product-title') {
    font-size: em(24);
    font-family: $din-light;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: -0.05em;
    margin-bottom: 2px;
    text-transform: capitalize;
  }
  
  
  // -------------------------------------
  //  Model Number
  // -------------------------------------
  @include child('model-number') {
    font-size: em(14);
    margin-bottom: 0.5em;
  }
  
  
  // -------------------------------------
  //  Product Image
  // -------------------------------------
  @include child('product-image') {
    margin: 0 auto 1em auto;
    max-height: 150px;
    max-width: 150px;
    
    @include media($bp-md) {
      margin-bottom: 0;
      max-height: 175px;
      max-width: 175px;
    }
  }
  
  
  // -------------------------------------
  //  Sharing Group
  // -------------------------------------
  @include child('sharing-group') {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    font-size: em(13);
    justify-content: space-between;
    
    
    @include media($bp-md) {
      border-bottom: 1px solid $c_moen-bright-gray;
      margin-bottom: 2em;
      padding-bottom: 2em;
    }
  }
  
  
  // -------------------------------------
  //  Add to Favorites
  // -------------------------------------
  @include child('favorite') {
    margin-bottom: 1em;
    
    @include media($bp-md) {
      margin-bottom: 0;
    }
    
    @include block('favorite') {

      @include child('icon') {
        display: block;

        @include child('unliked') {
          display: block;
        }

        @include child('liked') {
          display: none;
        }

        @include state('active') {

          .favorite__icon__unliked {
            display: none;
          }

          .favorite__icon__liked {
            display: block;
          }
        }

      }
    }
  }
  
  
  // -------------------------------------
  //  Product Finishes Section
  // -------------------------------------
  @include child('finishes') {
    margin-bottom: 1em;
    
    @include media($bp-md) {
      margin-bottom: 1.5em;
    }
  }
  
  
  // -------------------------------------
  //  Product Pricing
  // -------------------------------------
  @include child('price') {
    font-size: 1.3em;
    margin-bottom: 1em;
    text-align: left;
    
    @include media($bp-md) {
      margin-bottom: 0.5em;
    }
    
    & > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  
}


// -------------------------------------
//  Social Sharing
// -------------------------------------
@include block('w2b-social-share') {
  
  @include modifier('top') {
    display: none;
      
    @include media($bp-md) {
      display: inline-flex;
    }
  }
  
  @include modifier('bottom') {
    display: flex;
    justify-content: center;
    
    @include media($bp-md) {
      display: none;
    }
  }  
}


// -------------------------------------
//  Product Finishes
// -------------------------------------
@include block('w2b-finishes') {
  
  @include child('label') {
    margin-bottom: 0.5em;
  }
  
  @include child('list') {
    
  }
  
  @include child('item') {
    display: inline-block;
    margin-right: 5px;
  }
  
  @include child('button') {
    border: 2px solid $c_white;
    border-radius: 50%;
    display: block;
    line-height: 1rem;
    
    &:hover {
      border-color: $c_moen-bright-blue;
    }
    
    @include state('selected') {
      border-color: $c_moen-bright-blue;
    }
  }
  
}
