
// z-index Ordering
// -----------------------------------------------------------------------------
$plmr-z-layers: (
  // regions
  'modal': 800,
  'mobile-menu': 700,
  'masthead': 500,
  'feedback': 475,
  'mastfoot': 450,


  // generic
  'carousel': 75 // setting this in the JavaScript
);




// Function for z-index settings pass a key and return value $plmr-z-layers
// -----------------------------------------------------------------------------
@function plmr-z($layer, $offset: 0) {

  @if map-has-key($plmr-z-layers, $layer) {

    @if $offset != 0 {
      $map: map-get($plmr-z-layers, $layer) + $offset;
      @return $map;

    } @else {
      $map: map-get($plmr-z-layers, $layer);
      @return $map;
    }

  }

  @warn 'Unknown `#{$layer}` in $plmr-z-layers.';
  @return null;

}





