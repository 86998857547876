// Outer container default with no padding or margin - used for most components:

$main-container-max: $max-width + 32px;

.container {
  @include outer-container();
  position: relative;
  min-width: $min-width;
  
  @include modifier(main) {
    margin: 1em $grid-outside-spacing 0;

    @include media( $main-container-max ) {
      margin: 1em auto 0;
    }
  }
  @include modifier(full-width) {
    @include outer-container(100%);
    position: relative;
  }
  
  @include modifier(wide) {
    max-width: 1220px;
    margin: 1em 20px 0;
    @include media($bp-md) {
      margin: 1em 30px 0;
    }
    @include media($bp-lg) {
      margin: 1em 40px 0;
    }
    @include media( $main-container-max ) {
      margin: 1em auto 0;
    }
    @include media(1300px) {
      margin: 1em auto 0;
    }
  }
}

// article container - has in-built padding on top.
@include block(article) {
  font-size: 1rem;
  @include element(container) {
    @include outer-container();
    @include state(block) {
      margin-bottom: 1em;
    }
    padding-top: 1em;
  }

  @include element(heading) {
    font-size: 2.8em;
    font-family: $din-light;
    font-weight: normal;
    letter-spacing: -.05em;
    margin: 1em 0;
  }

}




/// .content-container
@include block('content-container') {
  margin: 0 auto;
  min-width: $min-width;
  padding: 50px 20px;
  text-align: center;
  
  
  /// screen width > 768px
  @include media($bp-md) {
    padding: 50px 40px;
  }
  
  
  /// .content-container__title
  @include child('title') {
    color: inherit;
    font-family: $din-light;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: -0.05em;
    line-height: 1.1;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      font-size: 35px;
    }
    
  }
  
  
  /// .content-container__content
  @include child('content') {
    color: inherit;
    font-family: $din-regular;
    font-size: 16px;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      margin: 0 auto;
      max-width: 600px;
    }
    
  }
  
}
