/*
See gallery/product listing page for example usage
@section Gallery Product Card List (Grid View)
@childof Card Lists
@page Components
@status in progress
@example
_markup/card-list-gallery-product.hbs
*/
@include plmr-component('card-list-gallery') {
  @extend .plmr-l-container;
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  margin-bottom: -10px;
  margin-top: -10px;
  //padding: 30px 0 40px;
  padding: 30px 0;

  @include plmr-mq(md) {
    margin-bottom: -15px;
    margin-top: -15px;
    //padding: 30px 0 50px;
    padding: 30px 0 35px;
  }

  @include plmr-mq(lg) {
    //padding: 40px 27.5px 50px;
    padding: 20px 27.5px 50px;
  }

  @include plmr-mq(xl) {
    padding-left: 0;
  }
}


/*
See gallery/product listing page for example usage
@section Gallery Product Card List (List View)
@sectionof Components.Card Lists
@page Components
@status in progress
@example
_markup/card-list-gallery-product-list-view.hbs
*/
@include plmr-component('card-list-gallery') {
  // .plmr-c-card-list-gallery--product-list
  @include modifier('product-list') {
    margin-bottom: 0;
    margin-top: 0;

    @include plmr-mq(md) {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}


/*
See gallery/product listing page for example usage
@section Gallery Collection Card List (Grid View)
@sectionof Components.Card Lists
@page Components
@status in progress
@example
_markup/card-list-gallery-collection.hbs
*/
@include plmr-component('card-list-gallery') {
  // .plmr-c-card-list-gallery--collection-grid
  @include modifier('collection-grid') {
    @include plmr-mq(md) {
      margin-left: -6px;
      margin-right: -6px;
    }

    @include plmr-mq(lg) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}


/*
See gallery/product listing page for example usage
@section Gallery Collection Card List (List View)
@sectionof Components.Card Lists
@page Components
@status in progress
@example
_markup/card-list-gallery-collection-list-view.hbs
*/
