/**
@doc _docs/carousel-featured-campaign.md
@section Carousel Featured Campaign
@childof Carousels
@page Carousels Carousel Featured Campaign
@status in progress
@hippo carousel
*/

/**
@section Content Left
@sectionof Carousel Featured Campaign
@example
_markup/carousel-featured-campaign--left.hbs
*/
@include plmr-component('carousel-featured-campaign') {
  padding: 40px 0 40px;
  position: relative;

  @include plmr-mq('md') {
    padding: 50px 0 50px;
  }

  //.plmr-c-carousel-featured-campaign::after
  &::after {
    @include plmr-pseudo;
    background: plmr-color('base', 'white');
    bottom: 0;
    height: calc(100% - 265px);
    left: 0;
    min-width: 100%;
    z-index: 1;

    @include plmr-mq('md') {
      // height: calc(100% - 550px);
      height: 126px;
    }

    @include plmr-mq('lg') {
      // height: calc(100% - 680px);
      height: 140px;
    }
  }

  //.plmr-c-carousel-featured-campaign__container
  @include element('container') {
    @extend .plmr-l-container;
    padding: 0;
    padding-right: 25px;
    position: relative;
    z-index: 2;

    @include plmr-mq('md') {
      padding-right: 55px;
    }

    @include plmr-mq('lg') {
      padding-right: 91px;
    }
  }

  //.plmr-c-carousel-featured-campaign__carousel
  @include element('carousel') {
    box-shadow: 20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);

    & > :not(:first-child) {
      display: none;
    }
  }

  //.plmr-c-carousel-featured-campaign__item
  @include element('item') {
    @include plmr-mq('md') {
      display: flex;
      flex-flow: row-reverse nowrap;
    }
  }

  //.plmr-c-carousel-featured-campaign__item-image-wrap
  @include element('item-image-wrap') {
    height: auto;
    min-width: 100%;
    overflow: hidden;
    padding-top: calc(200 / 350 * 100%);
    position: relative;

    @include plmr-mq('md') {
      flex: 1 1 55%;
      min-height: 550px;
      min-width: 0;
      padding-top: 0;
    }

    @include plmr-mq('lg') {
      flex: 1 1 59%;
      min-height: 700px;
    }
  }

  //.plmr-c-carousel-featured-campaign__item-image
  @include element('item-image') {
    display: block;
    left: 50%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @include plmr-mq('md') {
      max-width: none;
      min-height: 100%;
      min-width: 100%;
    }
  }

  //.plmr-c-carousel-featured-campaign__item-content
  @include element('item-content') {
    background: plmr-color('brand', 'secondary');
    color: plmr-color('base', 'white');
    padding: 30px 25px 100px;

    @include plmr-mq('md') {
      flex: 1 1 45%;
      padding: 60px 60px 105px 55px;
    }

    @include plmr-mq('lg') {
      flex: 1 1 41%;
      padding: 138px 0 249px;
      padding-left: calc(91 / 1209 * 100%);
      padding-right: calc(102 / 1209 * 100%);
    }
  }

  //.plmr-c-carousel-featured-campaign__item-heading
  @include element('item-heading') {
    @include plmr-font-face($family: 'italic');
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0;

    @include plmr-mq('md') {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  //.plmr-c-carousel-featured-campaign__item-text
  @include element('item-text') {
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding-top: 15px;
  }

  //.plmr-c-carousel-featured-campaign__item-button
  @include element('item-button') {
    margin-top: 30px;
  }

  //.plmr-c-carousel-featured-campaign__dots
  @include element('dots') {
    bottom: 0;
    justify-content: flex-start;
    left: 0;
    margin-bottom: 40px;
    padding-left: 19px;
    position: absolute;
    width: auto;
    z-index: 3;

    @include plmr-mq('md') {
      margin-bottom: 50px;
      padding-left: 49px;
    }

    @include plmr-mq('lg') {
      margin-bottom: 100px;
      padding-left: calc(85 / 1209 * 100%);
    }
  }
}


/**
@section Content Right
@sectionof Carousel Featured Campaign
@example
_markup/carousel-featured-campaign--right.hbs
*/
@include plmr-component('carousel-featured-campaign') {
  //.plmr-c-carousel-featured-campaign--right
  @include modifier('right') {
    .plmr-c-carousel-featured-campaign__container {
      padding: 0;
      padding-left: 25px;

      @include plmr-mq('md') {
        padding-left: 55px;
      }
  
      @include plmr-mq('lg') {
        padding-left: 91px;
      }
    }

    .plmr-c-carousel-featured-campaign__carousel {
      box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    }

    .plmr-c-carousel-featured-campaign__item {
      @include plmr-mq('md') {
        flex-flow: row nowrap;
      }
    }

    .plmr-c-carousel-featured-campaign__dots {
      @include plmr-mq('md') {
        left: auto;
        right: 0;
        width: 45%;
      }

      @include plmr-mq('lg') {
        width: 41%;
      }
    }
  }
}
