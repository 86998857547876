

/// .matte-black-headline
@include block('matte-black-headline') {  
  color: $c_white;
  font-family: $din-light;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 2px 0 rgba($c_black, 0.8);
  
  
  /// .matte-black-headline--1  
  @include modifier('1') {
    font-size: 20px;
    line-height: 1.155;
    margin-bottom: 35px;
  
    
    /// screen width > 768px
    @include media($bp-md) {
      font-size: 45px;
    }
    
    
    /// screen width > 992px
    @include media($bp-lg) {
      font-size: 54px;
    }
    
  }
  
  
  /// .matte-black-headline--2
  @include modifier('2') {
    font-size: 30px;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      font-size: 50px;
    }
    
    
    /// screen width > 992
    @include media($bp-lg) {
      font-size: 80px;
    }
    
  }
  
}



/// .matte-black-cta
@include block('matte-black-cta') {
  text-shadow: 0 2px 0 rgba($c_black, 0.8);
  
  
  /// .matte-black-cta:hover, .matte-black-cta:active, .matte-black-cta:focus
  &:hover,
  &:active,
  &:focus {
    text-shadow: 0 2px 0 rgba($c_moen-bright-blue, 0.8);
  }
  
}



/// .matte-black-intro
@include block('matte-black-intro') {
  background: $c_black;
  left: 0;
  min-width: $min-width;
  position: relative;  
  top: 0;
  width: 100%;
  z-index: z("matte-black-container");
  
  
  /// screen height > 500px
  @media (min-height: 500px) {
    align-content: stretch;
    align-items: center;
    display: flex;
    flex-direction: column;  
    justify-content: center;
    height: 100vh;
    padding-top: 115px;
    position: fixed;
  }
  
  
  /// .matte-black-intro::after pseudo-element
  &::after {
    background-color: transparent;
    background-image: linear-gradient(to top, rgba($c_black, 1) 0%, rgba($c_black,0) 100%);
    bottom: 0;
    content: '';
    display: block;
    height: 50px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: z("matte-black-gradient");
    
    
    /// screen width > 768px
    @include media($bp-md) {
      height: 100px;
    }
    
  }
    
  
  /// .matte-black-intro__content  
  @include child('content') {
    align-self: center;
    flex: 0 1 auto;
    margin: 0 auto;
    max-width: $max-width;
    min-width: $min-width;
    order: 0;
    padding: 20px;
    text-align: center;
    
    
    /// screen height < 500px & screen width > 768px
    @media (max-height: 500px) and (min-width: $bp-md) {
      padding: 20px 45px;
    }
    
    
    /// screen height > 500px
    @media (min-height: 500px) {
      align-content: center;
      align-items: center;
      display: flex;
      flex: 0 1 50%;
      flex-direction: column;
      justify-content: center;
    }
    
    
    /// screen width > 768px
    @include media($bp-md) {
      padding: 0 45px;
    }
    
  }
  
  
  /// .matte-black-intro__background-container
  @include child('background-container') {    
    align-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 928px;
    order: 0;
    position: relative;
    width: 100%;
    z-index: z("matte-black-intro-image");
  
    
    /// screen height > 500px
    @media (min-height: 500px) {
      flex: 0 1 50%;
    }
    
  }
  
  
  /// .matte-black-intro__background-image
  @include child('background-image') {
    display: block;
    height: auto;
    max-height: 350px;
    max-width: 439px;
    width: percentage( 928 / 1300 );    
    
    
    /// screen height > 850px
    @media (min-height: 850px) {
      max-height: 444px;
      max-width: 557px;  
    }
    
  }
  
  
  /// .matte-black-intro__title
  @include child('title') {
    width: 100%;
  }
  
  
  /// .matte-black-intro__scroll
  @include child('scroll') {
    
    
    /// .matte-black-intro__scroll.is-scrolling
    @include state('scrolling') {

    }
    
  }
  
}




/// .matte-black-details
@include block('matte-black-details') {
  min-width: $min-width;
  position: relative;
  width: 100%;
  z-index: z("matte-black-details");
  
  
  /// screen height > 500px
  @media (min-height: 500px) {
    margin-top: calc(100vh - 115px);
  }
  
  
  /// .matte-black-details::before pseudo-element
  &::before {
    background-color: $c_black;
    background-image: linear-gradient(to bottom, rgba($c_black, 0) 0%, rgba($c_black, 1) 100%);
    content: '';
    display: block;
    height: 50vh;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    
    
    /// screen height > 500px
    @media (min-height: 500px) {
      background-color: transparent;
    }
    
  }
  
  
  /// .matte-black-details__content
  @include child('content') {
    background: $c_black;
    position: relative;
  }
  
  
  /// .matte-black-details__background-container
  @include child('background-container') {
    height: 100%;
    left: 0;
    margin: 0 auto;
    max-width: 1500px;    
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: z("matte-black-background");
  }
    
    
  /// .matte-black-details__background-image
  @include child('background-image') {
    display: block;
    height: auto;
    left: 50%;
    max-width: 1778px;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 75%;
  
  
    /// screen height < 600px & screen width > 768px
    @media (max-height: 600px) and (min-width: $bp-md) {
      width: 40%;
    }
    
    
    /// screen height < 750px & screen width > 768px
    @media (max-height: 750px) and (min-width: $bp-md) {
      width: 55%;
    }
    
    
    /// screen height > 750px & screen width > 768px
    @media (min-height: 750px) and (min-width: $bp-md) {
      width: 55%;
    }

    
    /// screen width > 1200px
    @include media($bp-xl) {
      bottom: 0;
      top: auto;
      transform: translateX(-50%);
    }
    
    
    /// screen height < 650px & screen width > 1200px
    @media (max-height: 600px) and (min-width: $bp-xl) {
      width: 40%;
    }
    
    
    /// screen height > 650px & screen width > 1200px
    @media (min-height: 650px) and (min-width: $bp-xl) {
      width: 65%;
    }
    
    
    /// screen height < 650px & screen width > 1400px
    @media (max-height: 650px) and (min-width: $bp-xxl) {
      width: 40%;
    }
    
      
    /// screen height > 650px & screen width > 1400px
    @media (min-height: 650px) and (min-width: $bp-xxl) {
      width: 60%;
    }
    
    
    /// screen height > 900px & screen width > 1400px
    @media (min-height: 900px) and (min-width: $bp-xxl) {
      width: 75%;
    } 
        
  }
  
}




/// .matte-black-section
@include block('matte-black-section') {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: z("matte-black-section");
  
  
  /// .matte-black-section__headline
  @include child('headline') {
    
  }
  
  
  /// .matte-black-section__content
  @include child('content') {
    align-content: center;
    align-items: center;
    color: $c_white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: $max-width;
    padding: 100px 20px;
    width: 100%;
    
    
    /// screen height > 500px & screen width > 992px
    @media (min-height: 500px) and (min-width: $bp-lg) {
      height: 100vh;
      padding: 0 20px;  
    }
    
  }
  
  
  /// .matte-black-section__description
  @include child('description') {
    align-self: center;
    font-family: $din-light;
    font-size: 18px;
    line-height: 1.463;
    margin-bottom: 60px;
    text-align: center;
    text-shadow: 0 2px 0 rgba($c_black, 0.8);
    width: 100%;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      font-size: 25px;
    }
    
    
    /// screen width > 992px
    @include media($bp-lg) {
      font-size: 40px;
    }
    
  }
  
}




/// .matte-black-carousel
@include block('matte-black-carousel') {
  background: $c_white;
  padding: 25px percentage(100 / 1300);
  position: relative;
  z-index: z("matte-black-carousel");
  
  
  /// screen width > 768px
  @include media($bp-md) {
    padding: 100px percentage(100 / 1300);  
  }
  
  
  /// .matte-black-carousel__content
  @include child('content') {
    color: $c_black;
    padding: 15px 0;
    text-align: center;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      padding: 30px 0;
    }
    
  }
  
  
  /// .matte-black-carousel__product-link
  @include child('product-link') {
    color: inherit;  
    font-family: $din-light;
    font-size: 20px;
    transition: all 0.3s ease;
    
    
    /// screen width > 544px
    @include media($bp-sm) {
      font-size: 30px;
    }
    
    
    /// screen width > 768px
    @include media($bp-md) {
      font-size: 40px;
    }
    
    
    /// screen width > 992px
    @include media($bp-lg) {
      font-size: 50px;
    }
    
    
    /// .matte-black-carousel__product-link::after pseudo-element
    &::after {
      background: transparent url('images/icon-angle_right_black.svg') no-repeat 0 0;
      background-size: 100%;
      content: '';
      display: inline-block;
      height: 10px;
      margin-left: 15px;
      transition: all 0.3s ease;
      vertical-align: middle;
      width: 5px;
      
      
      /// screen width > 768px
      @include media($bp-md) {
        height: 15px;
        width: 7px;
      }
      
      
      /// screen width > 992px
      @include media($bp-lg) {
        height: 20px;
        width: 10px;
      }
      
    }
    
    
    /// .matte-black-carousel__product-link:hover, .matte-black-carousel__product-link:active, .matte-black-carousel__product-link:focus
    &:hover, 
    &:active,
    &:focus {
      color: $c_moen-bright-blue;
      text-decoration: none;
      
      
      /// .matte-black-carousel__product-link:hover::after pseudo-element, .matte-black-carousel__product-link:active::after pseudo-element, .matte-black-carousel__product-link:focus::after pseudo-element
      &::after {
        background-image: url('images/icon-angle_right_blue.svg');
      }
      
    }
  
  }
  
}




/// .matte-black-animation
@include block('matte-black-animation') {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-play-state: paused;
  animation-timing-function: ease;
  backface-visibility: hidden;
  opacity: 0;

  
  /// .load-animation .matte-black-animation
  .load-animation & {
    animation-play-state: running;  
  }
  
  
  /// .matte-black-animation--1
  @include modifier('1') {
    // animation-delay: 0s;
  }
  
  
  /// .matte-black-animation--2
  @include modifier('2') {
    animation-delay: 0.5s;
  }
  
  
  /// .matte-black-animation--3
  @include modifier('3') {
    animation-delay: 0.8s;
  }
  
  
  /// .matte-black-animation--4
  @include modifier('4') {
    animation-delay: 0.8s;
  }
  
}
