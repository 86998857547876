
// Moen Colors
// -----------------------------------------------------------------------------
$color-moen-brand-primary: #5b7f95 !default;
$color-moen-brand-secondary: #1b365d !default;
//$color-moen-brand-tertiary: #c6893f !default;
$color-moen-brand-tertiary: #c8893b !default;

$color-moen-text-primary: #1f1f1f !default;
$color-moen-text-secondary: #8b888c !default;
$color-moen-text-tertiary: #19345e !default;

$color-moen-background-primary: $color-white !default;
$color-moen-background-secondary: #ecedef !default;
$color-moen-background-tertiary: #f6f6f6 !default;

$color-moen-accent-light: #d8d8d8 !default;
$color-moen-accent-dark: #8b888c !default;

$color-moen-status-error: #d50001 !default;


// Style Guide Definitions
// -----------------------------------------------------------------------------

/**
@section Primary Palette
@sectionof Moen.Colors
@page Brands Moen
*/


/**
@colordef Primary
@color {hex} #5b7f95
@color {var} $color-moen-brand-primary
@color {textcolor} light
@colorof Moen.Colors.Primary Palette
*/


/**
@colordef Secondary
@color {hex} #1b365d
@color {var} $color-moen-brand-secondary
@color {textcolor} light
@colorof Moen.Colors.Primary Palette
*/


/**
@colordef Tertiary
@color {hex} #c8893b
@color {var} $color-moen-brand-tertiary
@color {textcolor} light
@colorof Moen.Colors.Primary Palette
*/


/**
@section Typography Palette
@sectionof Moen.Colors
@page Brands Moen
*/


/**
@colordef Text Primary
@color {hex} #1f1f1f
@color {var} $color-moen-text-primary
@color {textcolor} light
@colorof Moen.Colors.Typography Palette
*/


/**
@colordef Text Secondary
@color {hex} #8b888c
@color {var} $color-moen-text-secondary
@color {textcolor} light
@colorof Moen.Colors.Typography Palette
*/


/**
@colordef Text Tertiary
@color {hex} #19345e
@color {var} $color-moen-text-tertiary
@color {textcolor} light
@colorof Moen.Colors.Typography Palette
*/


/**
@section Background Palette
@sectionof Moen.Colors
@page Brands Moen
*/


/**
@colordef Background Primary
@color {hex} #ffffff
@color {var} $color-moen-background-primary
@color {textcolor} dark
@colorof Moen.Colors.Background Palette
*/


/**
@colordef Background Secondary
@color {hex} #ecedef
@color {var} $color-moen-background-secondary
@color {textcolor} dark
@colorof Moen.Colors.Background Palette
*/


/**
@colordef Background Tertiary
@color {hex} #f6f6f6
@color {var} $color-moen-background-tertiary
@color {textcolor} dark
@colorof Moen.Colors.Background Palette
*/


/**
@section Accent Palette
@sectionof Moen.Colors
@page Brands Moen
*/


/**
@colordef Accent Primary
@color {hex} #d8d8d8
@color {var} $color-moen-accent-light
@color {textcolor} dark
@colorof Moen.Colors.Accent Palette
*/


/**
@colordef Accent Secondary
@color {hex} #8b888c
@color {var} $color-moen-accent-dark
@color {textcolor} light
@colorof Moen.Colors.Accent Palette
*/


/**
@section Status Palette
@sectionof Moen.Colors
@page Brands Moen
*/


/**
@colordef Status Primary
@color {hex} #d50001
@color {var} $color-moen-status-error
@color {textcolor} light
@colorof Moen.Colors.Status Palette
*/




// Moen Brand Colors config
// -----------------------------------------------------------------------------
$brand-colors: (
  'brand': (
    'primary': $color-moen-brand-primary,
    'secondary': $color-moen-brand-secondary,
    'tertiary': $color-moen-brand-tertiary
  ),

  'text': (
    'primary': $color-moen-text-primary,
    'secondary': $color-moen-text-secondary,
    'tertiary': $color-moen-text-tertiary
  ),

  'background': (
    'primary': $color-moen-background-primary,
    'secondary': $color-moen-background-secondary,
    'tertiary': $color-moen-background-tertiary
  ),

  'accent': (
    'light': $color-moen-accent-light,
    'dark': $color-moen-accent-dark
  ),

  'status': (
    'error': $color-moen-status-error
  )
);


