
/**
@section Sort Bar
@childof Galleries
@page Galleries Sort Bar
@status in progress
@example
_markup/sortbar.hbs
*/
@include plmr-component('filtering') {
  // .plmr-c-filtering__sortbar
  @include element('sortbar') {
    background: plmr-color('background', 'tertiary');
    border-bottom: 1px solid plmr-color('accent', 'light');
    border-top: 1px solid plmr-color('accent', 'light');
    display: none;
    height: auto;
    min-width: plmr-config('container', 'min-width');
    width: auto;

    @include plmr-mq(md) {
      display: block;
    }

    // .plmr-c-filtering__sortbar--blend
    @include modifier('blend') {
      background-color: plmr-color('background', 'secondary');
      border-color: transparent;
    }

    // .plmr-c-filtering__sortbar._sticky
    @include plmr-state('sticky') {
      border-color: plmr-color('accent', 'light');
      border-top: 0;
      left: 0;
      min-width: 100vw;
      position: fixed;
      top: 0;
      z-index: plmr-z('modal');

      .plmr-c-filtering__sortbar-view-toggle,
      .plmr-c-filtering__sortbar-result-count {
        display: none;
      }

      @include plmr-mq(lg) {
        .plmr-c-filtering__sortbar-selected-count {
          display: block;
        }
      }
    }
  }

  // .plmr-c-filtering__sortbar-container
  @include element('sortbar-container') {
    @extend .plmr-l-container;
    align-items: center;
    display: flex;
    padding: 19px 25px;
    position: relative;
  }

  // .plmr-c-filtering__sortbar-selected-count
  @include element('sortbar-selected-count') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'secondary');
    display: none;
    font-size: 1.286rem;
    left: 50%;
    line-height: 1;
    padding: 0;
    padding-top: 5px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  // .plmr-c-filtering__sortbar-selected-clear
  @include element('sortbar-selected-clear') {
    @include plmr-font-face($family: 'base');
    @include plmr-link($color: plmr-color('text', 'secondary'), $underline: true);
    font-size: 0.8571rem;
    line-height: 1;
    margin-left: 20px;
    min-height: 0;
    padding-right: 20px;
    position: relative;

    // .plmr-c-filtering__sortbar-selected-clear:hover
    &:hover {
      cursor: pointer;
    }

    // .plmr-c-filtering__sortbar-selected-clear:before,
    // .plmr-c-filtering__sortbar-selected-clear:after
    &::before,
    &::after {
      @include plmr-pseudo;
      background-color: currentColor;
      height: 1px;
      left: 80%;
      margin-top: 1px;
      pointer-events: none;
      top: 50%;
      width: 10px;
    }

    &::before {
      transform: translateY(-50%) rotate(45deg);
    }

    &::after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  // .plmr-c-filtering__sortbar-specs
  @include element('sortbar-specs') {
    flex: 1 0 (123 / 325 * 100%); //(154 / 325 * 100%); // 109
    padding-right: 14px;

    @include plmr-mq(md) {
      flex: 1 0 40%;//(219 / 718 * 100%);
      line-height: 1.714rem;
    }

    @include plmr-mq(lg) {
      flex: 1 0 50%;
      padding-right: (14 / 1300 * 100%);
    }

    // .plmr-c-filtering__sortbar-specs--half-width
    @include modifier('half-width') {
      flex: 1 0 50%;
    }
  }

  // .plmr-c-filtering__sortbar-category
  @include element('sortbar-category') {
    color: plmr-color('brand', 'primary');
    line-height: 1.5rem;
    padding: 0;

    @include plmr-mq(md) {
      font-size: 1.143rem;
      line-height: 1.714rem;
    }

    @include plmr-mq(lg) {
      display: inline-block;
      vertical-align: middle;

      & ~ & {
        padding-left: 30px;
      }
    }

    // .plmr-c-filtering__sortbar-category--term
    @include modifier('term') {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
      white-space: nowrap;
      width: 70px;

      @include plmr-mq('md') {
        width: 200px;
      }

      @include plmr-mq('lg') {
        width: 70px;
      }

      @include plmr-mq('xl') {
        width: 150px;
      }
    }
  }

  // .plmr-c-filtering__sortbar-result-count
  @include element('sortbar-result-count') {
    color: plmr-color('text', 'secondary');
    display: none;
    padding: 0;
    padding-top: 6px;

    @include plmr-mq(md) {
      display: inline-block;
      vertical-align: middle;
    }

    @include plmr-mq(lg) {
      padding-left: 30px;
      padding-top: 0;
    }
  }

  // .plmr-c-filtering__sortbar-options
  @include element('sortbar-options') {
    flex: 1 0 (201 / 325 * 100%);//(171 / 325 * 100%);
    font-size: 0;
    text-align: right;

    @include plmr-mq(md) {
      flex: 1 0 60%;//(401 / 718 * 100%);
    }

    @include plmr-mq(lg) {
      flex: 1 0 50%;
    }

    // .plmr-c-filtering__sortbar-options--half-width
    @include modifier('half-width') {
      flex: 1 0 50%;
    }
  }

  // .plmr-c-filtering__sortbar-open-button
  @include element('sortbar-open-button') {
    @extend .plmr-c-filtering__open-button;

    @include plmr-mq('md') {
      display: none;
    }
  }

  // .plmr-c-filtering__sortbar-view-toggle
  @include element('sortbar-view-toggle') {
    display: none;
    margin-left: 25px;

    @include plmr-mq(md) {
      display: inline-block;
      vertical-align: middle;
    }
  }

  // .plmr-c-filtering__sortbar-view-active
  @include element('sortbar-view-active') {
    color: plmr-color('accent', 'dark');

    @include plmr-mq(md) {
      display: none;
    }
  }

  // .plmr-c-filtering__sortbar-view-list
  @include element('sortbar-view-list') {
    background: plmr-color('background', 'tertiary');
    border-top: 1px solid plmr-color('accent', 'light');
    height: auto;
    list-style: none;
    margin: 0;
    min-width: 65px;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 100%;
    transition: opacity 0.333s ease;
    width: auto;
    z-index: plmr-z('modal');

    @include plmr-mq(md) {
      border-top: 0;
      min-width: 0;
      opacity: 1;
      pointer-events: auto;
      position: relative;
      z-index: 1;
    }

    // .plmr-c-filtering__sortbar-view-list._open
    @include plmr-state('open') {
      opacity: 1;
      pointer-events: auto;
    }
  }

  // .plmr-c-filtering__sortbar-view-item
  @include element('sortbar-view-item') {
    @include plmr-link($color: plmr-color('accent', 'light'));
    display: block;
    margin: 20px;

    @include plmr-mq(md) {
      display: inline-block;
      margin: 0;

      & ~ & {
        padding-left: 20px;
      }
    }

    // .plmr-c-filtering__sortbar-view-item._active
    @include plmr-state('active') {
      color: plmr-color('accent', 'dark');
    }
  }

  // .plmr-c-filtering__sortbar-view-button
  @include element('sortbar-view-button') {
    // .plmr-c-filtering__sortbar-view-button:hover
    &:hover {
      cursor: pointer;
    }
  }


  // .plmr-c-filtering__sortbar-sorting
  @include element('sortbar-sorting') {
    display: none;

    @include plmr-mq(md) {
      display: inline-block;
      vertical-align: middle;
    }
  }

  // .plmr-c-filtering__sortbar-sorting-label
  @include element('sortbar-sorting-label') {
    color: plmr-color('text', 'secondary');
    display: inline-block;
    font-size: 1rem; //1.143rem;
    line-height: 2rem;
    padding: 0;
    vertical-align: middle;

    & ~ & {
      border-left: 1px solid plmr-color('accent', 'light');
      margin-left: 30px;
      padding-left: 30px;
    }
  }

  // .plmr-c-filtering__sortbar-sorting-drop
  @include element('sortbar-sorting-drop') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'tertiary');
    display: inline-flex;
    padding-left: 5px;
    padding-right: 15px;
    position: relative;
    text-decoration: underline;
    transition: color 0.5s ease-in-out;

    &::after {
      @include plmr-pseudo;
      border-style: solid;
      border-width: 1.25px 1.25px 0 0;
      display: inline-block;
      height: 6.85px;
      left: 10px;
      position: relative;
      top: 0.85rem;
      transform: rotate(135deg);
      vertical-align: top;
      width: 6.85px;
    }

    @include plmr-state('open') {
      &::after {
        transform: rotate(135deg) scale(-1, -1);
      }

      .plmr-c-filtering__sortbar-sorting-list {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  // .plmr-c-filtering__sortbar-sorting-list
  @include element('sortbar-sorting-list') {
    @include plmr-font-face($family: 'base');
    background: plmr-color('base', 'white');
    box-shadow: -10px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    height: auto;
    left: 50%;
    list-style: none;
    margin-top: 10px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 30px;
    transform: translateX(-50%);
    transition: opacity 0.5s ease;
    width: 200px;
    z-index: 2;

    // .plmr-c-filtering__sortbar-sorting-list--flush-right
    @include modifier('flush-right') {
      left: 0;
    }
  }

  // .plmr-c-filtering__sortbar-sorting-selection
  @include element('sortbar-sorting-selection') {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    // .plmr-c-filtering__sortbar-sorting-selection:hover
    &:hover {
      cursor: pointer;
    }

    @include plmr-mq(md) {
      max-width: 60px;
    }

    @include plmr-mq(xl) {
      max-width: 75px;
    }
  }

  // .plmr-c-filtering__sortbar-sorting-option
  @include element('sortbar-sorting-option') {
    background-color: #fff;
    color: plmr-color('text', 'secondary');
    font-size: 1.286rem;
    line-height: 1.5rem;
    position: relative;
    text-align: left;
    transition: background-color 0.5s ease;

    // .plmr-c-filtering__sortbar-sorting-option::after
    &::after {
      @include plmr-pseudo;
      border-bottom: 2px solid currentColor;
      border-right: 2px solid currentColor;
      display: none;
      height: 12px;
      right: 28px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      width: 6px;
    }

    // .plmr-c-filtering__sortbar-sorting-option:hover
    &:hover {
      background-color: plmr-color('background', 'secondary');
    }

    @include plmr-state('checked') {
      &::after {
        display: block;
      }
    }
  }

  // .plmr-c-filtering__sortbar-sorting-optbutton
  @include element('sortbar-sorting-optbutton') {
    min-height: 0;
    outline-offset: -1px;
    padding: 15px 20px 14px;
    text-align: left;
    width: 100%;

    // .plmr-c-filtering__sortbar-sorting-optbutton:hover
    &:hover {
      cursor: pointer;
    }
  }
}
