/**
@doc _docs/banner-mega-split.md
@section Banner Mega Split
@childof Banners
@page Banners Banner Mega Split
@status in progress
@hippo banner
*/

//.plmr-c-banner-mega-split
@include plmr-component('banner-mega-split') {
  overflow: hidden;

  //.plmr-c-banner-mega-split__container
  @include element('container') {
    padding: 0;
    position: relative;
    z-index: 1;

    @include plmr-mq(md) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      min-height: 650px;
    }
  }

  //.plmr-c-banner-mega-split__content
  @include element('content') {
    padding: 60px 35px 70px;
    position: relative;
    text-align: center;
    z-index: 2;

    @include plmr-mq(md) {
      padding: 0;
      text-align: left;
      width: 250px;
    }

    @include plmr-mq(lg) {
      width: 375px;
    }
  }

  //.plmr-c-banner-mega-split__eyebrow
  @include element('eyebrow') {
    @include plmr-font-face($family: 'italic');
    @include plmr-link($color: plmr-color('text', 'secondary'), $underline: false, $is-color-hover: false);
    cursor: pointer;
    display: block;
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding-bottom: 20px;

    @include plmr-mq(md) {
      font-size: 1.285rem;
    }
  }

  //.plmr-c-banner-mega-split__heading
  @include element('heading') {
    @extend .plmr-c-banner__title;
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 2rem;
    line-height: 2.571rem;
    padding-bottom: 20px;

    @include plmr-mq(md) {
      font-size: 2.571rem;
      line-height: 3.428rem;
      max-width: 250px;
    }
  }

  //.plmr-c-banner-mega-split__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
    padding-bottom: 20px;
  }

  //.plmr-c-banner-mega-split__subcopy
  @include element('subcopy') {
    @extend .plmr-c-banner__copy, .plmr-c-banner__copy--stat;
    padding-bottom: 20px;
  }

  //.plmr-c-banner-mega-split__image-container
  @include element('image-container') {
    display: block;
    height: 260px;
    position: relative;
    width: 100%;
    z-index: 1;

    @include plmr-mq(md) {
      flex: none;
      height: 100%;
      // left: 200px;
      position: absolute;
      top: 0;
      width: auto;
    }
  }

  //.plmr-c-banner-mega-split__image
  @include element('image') {
    display: block;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: auto;

    @include plmr-mq(md) {
      flex: none;
      left: auto;
      position: relative;
      right: auto;
      transform: none;
    }
  }

  //.plmr-c-banner-mega-split__anchor
  @include element('anchor') {
    cursor: pointer;
    margin-top: 10px;

    @include plmr-mq(md) {
      margin-top: 20px;
    }
  }

  //.plmr-c-banner-mega-split__anchor-icon
  @include element('anchor-icon') {
    height: 15px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
  }

  //.plmr-c-banner-mega-split__video
  @include element('video') {
    display: none;

    @include plmr-mq(lg) {
      display: block;
      height: 100%;
      position: absolute;
      right: -1px;
      transform: none;
      z-index: 3;
    }
  }

  //.plmr-c-banner-mega-split__modal-button
  @include element('modal-button') {
    margin-top: 10px;

    @include plmr-mq(md) {
      margin-top: 20px;
    }

    @include plmr-mq(lg) {
      display: none;
    }
  }
}


/**
@section Left
@sectionof Banner Mega Split
@example
_markup/banner-mega-split--left.hbs
*/

//.plmr-c-banner-mega-split
@include plmr-component('banner-mega-split') {
  //.plmr-c-banner-mega-split--left
  @include modifier('left') {
    .plmr-c-banner-mega-split__content {
      @include plmr-mq(md) {
        margin-left: 40px;
        margin-right: auto;
      }

      @include plmr-mq(xl) {
        // margin-left: 60px;
        margin-left: 91px;
      }
    }

    .plmr-c-banner-mega-split__image-container {
      @include plmr-mq(md) {
        left: 200px;
      }

      @include plmr-mq(lg) {
        left: 325px;
      }

      @include plmr-mq(xl) {
        left: 425px;
      }
    }
  }
}


/**
@section Right
@sectionof Banner Mega Split
@example
_markup/banner-mega-split--right.hbs
*/

//.plmr-c-banner-mega-split
@include plmr-component('banner-mega-split') {
  //.plmr-c-banner-mega-split--right
  @include modifier('right') {
    .plmr-c-banner-mega-split__content {
      @include plmr-mq(md) {
        margin-left: auto;
        margin-right: 40px;
      }

      @include plmr-mq(xl) {
        // margin-right: 60px;
        margin-right: 91px;
      }
    }

    .plmr-c-banner-mega-split__image-container {
      @include plmr-mq(md) {
        right: 200px;
      }

      @include plmr-mq(lg) {
        right: 325px;
      }

      @include plmr-mq(xl) {
        right: 425px;
      }
    }
  }
}


/**
@doc _docs/banner-mega-split--video.md
@section With Video, Left Content
@sectionof Banner Mega Split
@example
_markup/banner-mega-split--video.hbs
*/

//.plmr-c-banner-mega-split
@include plmr-component('banner-mega-split') {
  //.plmr-c-banner-mega-split--video
  @include modifier('video') {
    .plmr-c-banner-mega-split__container {
      @include plmr-mq(md) {
        min-height: 585px;
      }
    }

    .plmr-c-banner-mega-split__image-container {
      height: 280px;
      max-width: 100%;
      min-width: 100%;
      overflow: hidden;

      @include plmr-mq(md) {
        height: 100%;
        right: 0;
      }
    }

    .plmr-c-banner-mega-split__content {
      @include plmr-mq(md) {
        align-items: flex-start;
        background: linear-gradient(90deg, plmr-color('base', 'white') 75%, rgba(plmr-color('base', 'white'), 0));
        display: inline-flex;
        flex-flow: column;
        justify-content: center;
        left: 0;
        max-width: 500px;
        min-height: 100%;
        padding: 0 45px 0 40px;
        position: absolute;
        top: 0;
        width: 50%;
        z-index: 2;
      }

      @include plmr-mq(lg) {
        max-width: 600px;
        padding-left: 91px;
        width: calc(50% + 100px);
      }
    }

    .plmr-c-banner-mega-split__heading {
      @include plmr-mq(md) {
        max-width: 350px;
      }
    }

    .plmr-c-banner-mega-split__copy {
      font-size: 1.285rem;
      line-height: 2.285rem;

      @include plmr-mq(md) {
        font-size: 1.5rem;
        line-height: 2.714rem;
      }
    }

    .plmr-c-banner-mega-split__image {
      @include plmr-mq(md) {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }

    .plmr-c-banner-mega-split__anchor {
      @include plmr-font-face($family: 'bold');
      color: plmr-color('text', 'secondary');
      display: none;
      font-size: 1.143rem;
      line-height: 1;
      opacity: 0;
      padding-right: 25px;
      pointer-events: none;
      position: relative;
      transition: opacity 0.25s ease;

      @include plmr-mq(lg) {
        display: inline-block;
      }

      // .plmr-c-banner-mega-split__anchor._visible
      @include plmr-state('visible') {
        opacity: 1;
        pointer-events: auto;
      }
    }

    //.plmr-c-banner-mega-split--video._right
    @include plmr-state('right') {
      .plmr-c-banner-mega-split__content {
        @include plmr-mq(md) {
          background: linear-gradient(-90deg, plmr-color('base', 'white') 75%, rgba(plmr-color('base', 'white'), 0));
          left: auto;
          padding: 0 40px 0 45px;
          right: 0;
        }

        @include plmr-mq(lg) {
          padding-left: 45px;
          padding-right: 91px;
        }
      }

      .plmr-c-banner-mega-split__image {
        @include plmr-mq(md) {
          left: 0;
          right: auto;
        }
      }

      .plmr-c-banner-mega-split__video {
        @include plmr-mq(lg) {
          left: -1px;
          right: auto;
        }
      }
    }
  }
}


/**
@section With Video, Right Content
@sectionof Banner Mega Split
@example
_markup/banner-mega-split--video--right.hbs
*/
