
/// .customer-actions
@include block('customer-actions') {
  align-self: flex-end;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-right: 19px;
  
  
  /// .customer-actions__nav
  @include child('nav') {
    align-items: center;
    display: flex;  
    justify-content: flex-end;
    
    
    /// .customer-actions__nav--menu-links
    @include modifier('menu-links') {
      display: none;
      
      
      /// screen > 768px
      @include media($bp-md) {
        display: flex;
      }
      
    }
    
  }
  
  
  /// .customer-actions__item
  @include child('item') {
    margin: 10px;  
  }
  
  
  /// .customer-actions__link
  @include child('link') {
    color: $c_white;
    font-family: $proxima-regular;
    font-size: 14px;
    text-transform: uppercase;
    
  
    /// .customer-actions__link--input-button  
    @include modifier('input-button') {
      cursor: pointer;
      
      
      /// .customer-actions__link--input-button:hover, .customer-actions__link--input-button:focus
      &:hover,
      &:focus {
        text-decoration: underline;
      }
      
    }
    
    
    /// .customer-actions__link--icon
    @include modifier('icon') {
      display: block;
      background-color: transparent;
      background-repeat: none;
      background-position: 0 0;
      background-size: 100%;
    }
    
    
    /// .customer-actions__link--login
    @include modifier('login') {
      background-image: url('images/global/icon-account.svg');
      height: 20px;
      width: 20px;
    }
    
    
    /// .customer-actions__link--cart
    @include modifier('cart') {
      background-image: url('images/global/icon-cart.svg');
      height: 19px;
      width: 20px;
    }
    
    
    /// .customer-actions__link--favorties
    @include modifier('favorites') {
      background-image: url('images/global/icon-favorite_white-outline.svg');
      height: 17px;
      width: 18px;
    }
    
  }
  
}



/// .actions-icon
@include block('actions-icon') {
  position: relative;
  
  
  /// .actions-icon__count-display
  @include child('count-display') {
    background: $c_header-icon-count-bg;
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 rgba($c_black, 0.35);
    color: $c_white;
    display: none;
    font-family: $din-bold;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    position: absolute;
    right: -9px;
    text-align: center;
    top: -7px;
    width: 16px;
    
    
    /// .actions-icon__count-display--max
    @include modifier('max') {
      letter-spacing: 2px;
      text-indent: -2px;
    
      
      /// .actions-icon__count-display--max::after pseudo-element  
      &::after {
        content: '+';
        display: inline-block;
        position: absolute;
        top: -2px;
      }
      
    }
    
  }
  
  
  /// .actions-icon--displaying-count
  @include modifier('displaying-count') {
    
    
    /// .actions-icon--displaying-count .actions-icon__count-display
    .actions-icon__count-display {
      display: block;
    }
    
  }
  
}
