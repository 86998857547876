
/// .navicon
@include block('navicon') {
  align-items: center;
  background: $c_concrete;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 75px;
  width: 75px;
  
  
  /// .navicon__button
  @include child('button') {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    height: 18px;
    position: relative;
    width: 25px;
  }  
  
  
  /// .navicon__icon
  @include child('icon') {
    background: #979797;
    display: block;
    height: 2px;
    transition: transform 0.2s ease;
    width: 100%;
  
  
    /// .navicon__icon::before pseudo-element
    &::before {
      background: #979797;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      top: 0;
      transition: transform 0.2s ease;
      width: 25px;
    }
  
  
    /// .navicon__icon::after pseudo-element
    &::after {
      background: #979797;
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      transition: transform 0.2s ease;
      width: 25px;
    }
    
  }
  
  /// .navicon--expanded
  @include modifier('expanded') {
      
      
    /// .navicon--expanded .navicon__icon
    .navicon__icon {
      background: transparent;
      height: 18px;


      /// .navicon--expanded .navicon__icon::before pseudo-element
      &::before { 
        top: 45%;
        transform: rotate(-45deg); 
      }
    
    
      /// .navicon--expanded .navicon__icon::after pseudo-element
      &::after { 
        bottom: auto;
        top: 45%;
        transform: rotate(45deg); 
      }
      
    }
    
  }
  
}
