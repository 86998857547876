/**
@doc _docs/forms.md
@section Forms
@childof Components
@page Forms
@nobrandtoggle
*/





@mixin plmr-form-input-base() {
  @include plmr-font-face($family: 'regular');
  appearance: none;
  background-color: plmr-color('background', 'primary');
  border: 1px solid plmr-color('accent', 'light');
  border-radius: 0;
  color: currentColor;
  display: block;
  width: 100%;
}

@mixin plmr-form-input-global() {
  // height: 50px;
  font-size: 14px;
  line-height: 21px;
  padding: 14px 20px 13px;

  @include plmr-mq(md) {
    font-size: 16px;
  }
}

@mixin plmr-form-label-base() {
  @include plmr-font-face($family: 'regular');
  color: plmr-color('text', 'primary');
  display: block;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;

  @include plmr-mq(md) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 9px;
  }
}



//.plmr-c-form
@include plmr-component('form') {
  //.plmr-c-form select, .plmr-c-form input
  select,
  input {
    font-size: 14px;
    height: 50px;

    @include plmr-mq('md') {
      font-size: 16px;
    }
  }

  //.plmr-c-form__cancel
  @include element('cancel') {
    @include plmr-font-face($family: 'bold');
  }

  //.plmr-c-form__submit
  @include element('submit') {
    margin-right: 30px;
  }
}

//.plmr-c-form-box
@include plmr-component('form-box') {
  box-shadow: 20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
  margin-left: auto;
  margin-right: auto;
  max-width: 965px;
  padding: 30px;
  padding-bottom: calc(30px - 1rem);

  @include plmr-mq(md) {
    padding: 60px 30px;
    padding-bottom: calc(10px + 1rem);
  }

  @include plmr-mq(lg) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

//.plmr-c-form-label 
@include plmr-component('form-label') {
  @include plmr-form-label-base();

  //.plmr-c-form-label__require
  @include element('require') {
    color: plmr-color('status', 'error');
    display: inline-block;
    padding-left: 8px;
  }
}

//.plmr-c-form-section
@include plmr-component('form-section') {
  display: block;
  margin-bottom: calc(30px - 1rem);
  // margin-top: calc(40px - 1rem);

  @include plmr-mq('md') {
    margin-bottom: calc(40px - 1rem);
  }

  //.plmr-c-form-section__title
  @include element('title') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'tertiary');
    display: block;
    font-size: 1.143rem;
    line-height: 21px;
    margin: 0;
    margin-bottom: 20px;

    @include plmr-mq('md') {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    //.plmr-c-form-section__title--large
    @include modifier('large') {
      font-size: 1.714rem;
      line-height: 36px;
      margin-bottom: 15px;

      @include plmr-mq('md') {
        font-size: 2rem;
        line-height: 42px;
        margin-bottom: 25px;
      }
    }
  }
}

//.plmr-c-form-group-container
@include plmr-component('form-group-container') {
  @extend .plmr-l-flex-row;
  min-width: 100%;
}

//.plmr-c-form-group
@include plmr-component('form-group') {
  position: relative;

  //.plmr-c-form-group::after pseudo element
  &::after {
    @include plmr-font-face($family: 'italic');
    @include plmr-pseudo($content: attr(data-form-error-text));
    bottom: 0;
    color: plmr-color('status', 'error');
    font-size: 12px;
    left: 0;
    line-height: 21px;
    margin-left: 0.5rem;
  }

  //.plmr-c-form-group._error
  @include plmr-state('error') {
    padding-bottom: 2rem;

    .plmr-c-form-label {
      color: plmr-color('status', 'error');
    }

    input,
    select,
    textarea {
      border-color: plmr-color('status', 'error');
      border-width: 2px;
      color: plmr-color('status', 'error');
    }

    select {
      padding-bottom: 16px;
      padding-top: 16px;
    }
  }
}


@import 'form-input';

@import 'form-checkbox';

@import 'form-file';

@import 'form-radio';

@import 'form-select';

@import 'form-search';

@import 'form-text';

@import 'form-textarea';

@import 'form-login';
