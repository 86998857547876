
// Matte Black colors
// -----------------------------------------------------------------------------
$colors-matte-black-primary: $color-black !default;
$colors-matte-black-secondary: #494949 !default;




// Matte Black Colors config
// -----------------------------------------------------------------------------
$matte-black-colors: (
  'primary': $colors-matte-black-primary,
  'secondary': $colors-matte-black-secondary
);




// Matte Black Overrides to layout
// -----------------------------------------------------------------------------
@include plmr-object('matte-black') {

  // .plmr-o-matte-black .plmr-c-button--primary
  .plmr-c-button--primary {
    background-color: plmr-landing-colors($matte-black-colors, 'primary');
  }

}
