
@mixin bgColor($name, $color) {
  .bg--#{$name} {
    background-color: $color;
  }
}


@include bgColor(moen-bright-blue, $c_moen-bright-blue);

@include bgColor(white, $c_white);

@include bgColor(concrete, $c_concrete);




@include block('bg'){

  @include modifier('shadowed') {
    box-shadow: 0px 0px 20px 0px rgba($c_black, 0.3);
  }

  @include modifier('moen-bright-blue') {
    a {
      color: $c_white;
    }
  }
}
