/*
Tooltips

Styleguide 3.16
*/


/*
Tooltip Help

markup:
<br>
<br>
<a href="javacript:void(0);" class="tooltip">
  <div class="tooltip__icon">
    <img src="images/icon-question_mark.svg" alt="Help">
  </div>
  <div class="tooltip__modal">Manufacturer Suggested Retail Price is suggested only. Store pricing will vary.</div>
</a>
<br>
<br>

Styleguide 3.16.1
*/

@include block(tooltip) {
  $tooltipWidth: 20px;
  cursor: help;
  display: inline-block;
  font-size: 1rem;
  position: relative;

  @include element(icon) {
    height: $tooltipWidth;
    position: relative;
    text-align: center;
    width: $tooltipWidth;
    img {
      display: inherit;
      height: auto;
      width: 100%;
    }
  }
  &:hover {
    .tooltip__modal {
      opacity: 1;
      visibility: visible;
      &[x-placement="right"] {
        margin-left: $tooltipWidth;
      }
      &[x-placement="left"] {
        margin-right: $tooltipWidth;
      }
      &[x-placement="bottom"] {
        margin-top: $tooltipWidth;
      }
      &[x-placement="top"] {
        margin-bottom: $tooltipWidth;
      }
    }
  }

  @include element(modal) {
    background: $c_white;
    border: 2px solid $c_tooltip_gray;
    color: $c_text-dark;
    font-size: em(14px);
    height: auto;
    left: $tooltipWidth;
    line-height: 1.2;
    margin-left: $tooltipWidth;
    opacity: 0;
    padding: .5em;
    position: absolute;
    text-align: left;
    top: 50%;
    transform: translateY(-50%);
    transition: margin .25s ease-in-out,
                visibility 0s ease-in-out .25s,
                opacity .25s ease-in-out .25s;
    visibility: hidden;
    width:250px;
    &:before {
      top: 50%;
      transform: translateY(-50%);
      border-top: $tooltipWidth solid transparent;
      border-bottom: $tooltipWidth solid transparent;
      border-right: $tooltipWidth solid $c_tooltip_gray;
      content: '';
      display: block;
      height: 0;
      left: -$tooltipWidth;
      position: absolute;
      width: 0;
    }
    /*=============================================>>>>>
    = Popper JS Styling =
    ===============================================>>>>>*/
    &[x-placement="right"] {
      top: 50%;
      transform: translateY(-50%);
      margin-left: $tooltipWidth * 1.2;
    }
    &[x-placement="left"] {
      margin-left: 0;
      margin-right: $tooltipWidth * 1.2;
      &:before {
        left: auto;
        right: -$tooltipWidth;
        top: 50%;
        transform: rotateY(180deg) translateY(-50%);
      }
    }
    &[x-placement="bottom"] {
      margin-top: $tooltipWidth * 1.2;
    }
    &[x-placement="top"] {
      margin-bottom: $tooltipWidth * 1.2;
    }
  }

}

/*
Tooltip Info

This tooltip is used in the facets filter from the product gallery
the tooltip should be added dynamically and has a js-hookName-tooltip to instanciate
the popper method. 

markup:
<button class="button button--default is-active has-tooltip">
  Product Type<span class="tooltip-info js-hookName-tooltip"></span>
</button>
<!--START Example adding the tooltip__modal-info goes anywhere in the DOM-->
<div class="tooltip__modal-info tooltip__modal-info--large is-active">
  <div class="row">
    <div class="heading--6 col-xxs-12">This is the Title</div>
    <div class="col-xxs-12">
      <p>Dress your home with style that lasts. Use these tools to find the right products for you. Buy it for looks, buy it for life.®</p>
    </div>
  </div>
  <div class="tooltip__container row">
    <figure>
      <div class="tooltip__image">
        <img src="http://placehold.it/300x400/" alt="" aria-hidden="true">
      </div>
      <figcaption>Chrome</figcaption>
    </figure>
    <figure>
      <div class="tooltip__image">
        <img src="http://placehold.it/300x100/" alt="" aria-hidden="true">
      </div>
      <figcaption>Brushed Nickel</figcaption>
    </figure>
    <figure>
      <div class="tooltip__image">
        <img src="http://placehold.it/300x300/" alt="" aria-hidden="true">
      </div>
      <figcaption>Polished Nickel with long description</figcaption>
    </figure>
  </div>
</div>
<!--END Example adding the tooltip__modal-info goes -->
<br>
<br>
<div class="form-checkbox">
  <input id="ckName" name="ckName" type="checkbox">
  <label class="has-tooltip" for="ckName">Arm Diverter (36) <span class="tooltip-info js-hookName-tooltip"></span></label>
</div>

Styleguide 3.16.2
*/

@include block('has-tooltip') {
  display: inline-block;
  position: relative;
  & > .tooltip-info {
    display: none;
    cursor: help;
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%22150%22%20height%3D%22150%22%20viewBox%3D%220%200%20150%20150%22%3E%3Cstyle%20type%3D%22text/css%22%3E%20%20%0A%09.st0%7Bfill%3A%230090D7%3B%7D%0A%3C/style%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M100%20121.9v-15.6c0-0.9-0.3-1.7-0.9-2.2s-1.3-0.9-2.2-0.9h-9.4v-50c0-0.9-0.3-1.7-0.9-2.2S85.3%2050%2084.4%2050H53.1c-0.9%200-1.7%200.3-2.2%200.9S50%2052.2%2050%2053.1v15.6c0%200.9%200.3%201.7%200.9%202.2s1.3%200.9%202.2%200.9h9.4v31.2h-9.4c-0.9%200-1.7%200.3-2.2%200.9s-0.9%201.3-0.9%202.2v15.6c0%200.9%200.3%201.7%200.9%202.2s1.3%200.9%202.2%200.9h43.8c0.9%200%201.7-0.3%202.2-0.9S100%20122.8%20100%20121.9zM87.5%2034.4V18.8c0-0.9-0.3-1.7-0.9-2.2s-1.3-0.9-2.2-0.9H65.6c-0.9%200-1.7%200.3-2.2%200.9s-0.9%201.3-0.9%202.2v15.6c0%200.9%200.3%201.7%200.9%202.2s1.3%200.9%202.2%200.9h18.8c0.9%200%201.7-0.3%202.2-0.9S87.5%2035.3%2087.5%2034.4zM150%2075c0%2013.6-3.4%2026.2-10.1%2037.6s-15.8%2020.6-27.3%2027.3S88.6%20150%2075%20150s-26.2-3.4-37.6-10.1%20-20.6-15.8-27.3-27.3S0%2088.6%200%2075s3.4-26.2%2010.1-37.6%2015.8-20.6%2027.3-27.3S61.4%200%2075%200s26.2%203.4%2037.6%2010.1%2020.6%2015.8%2027.3%2027.3S150%2061.4%20150%2075z%22/%3E%3C/svg%3E');
    background-size: cover;
    content: '';
    cursor: pointer;
    height: 1em;
    margin-left: .5em;
    position: relative;
    text-align: center;        
    vertical-align: bottom;
    width: 1em;
    z-index: z('tooltip-icon');
    @include media('$bp-lg') {
      display: inline-block;
    }
  }
  &.is-active,
  &:focus {
    & > .tooltip-info  {
      background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%22150%22%20height%3D%22150%22%20viewBox%3D%220%200%20150%20150%22%3E%3Cstyle%20type%3D%22text/css%22%3E%20%20%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C/style%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M100%20121.9v-15.6c0-0.9-0.3-1.7-0.9-2.2s-1.3-0.9-2.2-0.9h-9.4v-50c0-0.9-0.3-1.7-0.9-2.2S85.3%2050%2084.4%2050H53.1c-0.9%200-1.7%200.3-2.2%200.9S50%2052.2%2050%2053.1v15.6c0%200.9%200.3%201.7%200.9%202.2s1.3%200.9%202.2%200.9h9.4v31.2h-9.4c-0.9%200-1.7%200.3-2.2%200.9s-0.9%201.3-0.9%202.2v15.6c0%200.9%200.3%201.7%200.9%202.2s1.3%200.9%202.2%200.9h43.8c0.9%200%201.7-0.3%202.2-0.9S100%20122.8%20100%20121.9zM87.5%2034.4V18.8c0-0.9-0.3-1.7-0.9-2.2s-1.3-0.9-2.2-0.9H65.6c-0.9%200-1.7%200.3-2.2%200.9s-0.9%201.3-0.9%202.2v15.6c0%200.9%200.3%201.7%200.9%202.2s1.3%200.9%202.2%200.9h18.8c0.9%200%201.7-0.3%202.2-0.9S87.5%2035.3%2087.5%2034.4zM150%2075c0%2013.6-3.4%2026.2-10.1%2037.6s-15.8%2020.6-27.3%2027.3S88.6%20150%2075%20150s-26.2-3.4-37.6-10.1%20-20.6-15.8-27.3-27.3S0%2088.6%200%2075s3.4-26.2%2010.1-37.6%2015.8-20.6%2027.3-27.3S61.4%200%2075%200s26.2%203.4%2037.6%2010.1%2020.6%2015.8%2027.3%2027.3S150%2061.4%20150%2075z%22/%3E%3C/svg%3E');
    }
  }
}

/*
Tooltip Info Modals

This is a style reference. The modal position should be managed by
the popper plugin

markup:
<h2>Large Modal</h2>
<div class="tooltip__modal-info tooltip__modal-info--large is-active">
  <div class="row">
    <div class="heading--6 col-xxs-12">This is the Title</div>
    <div class="col-xxs-12">
      <p>Dress your home with style that lasts. Use these tools to find the right products for you. Buy it for looks, buy it for life.®</p>
    </div>
  </div>
  <div class="tooltip__container row">
    <figure>
      <div class="tooltip__image">
        <img src="http://placehold.it/500x500/" alt="" aria-hidden="true">
      </div>
      <figcaption>Chrome</figcaption>
    </figure>
    <figure>
      <div class="tooltip__image">
        <img src="http://placehold.it/300x300/" alt="" aria-hidden="true">
      </div>
      <figcaption>Brushed Nickel with long text</figcaption>
    </figure>
    <figure>
      <div class="tooltip__image">
        <img src="http://placehold.it/500x500/" alt="" aria-hidden="true">
      </div>
      <figcaption>Polished Nickel</figcaption>
    </figure>
  </div>
</div>
<br>
<br>
<br>
<h2>Small Modal</h2>
<div data-tooltip-modal="" class="tooltip__modal-info tooltip__modal-info--small is-active">
  <div class="tooltip__col-left">
    <figure>
      <div class="tooltip__image">
        <img src="http://placehold.it/500x500/" alt="" aria-hidden="true">
      </div>
    </figure>
  </div>
  <div class="tooltip__col-right">
    <div class="heading--6">This is the Title</div>
    <p>Dress your home with style that lasts. Use these tools to find the right products for you. Buy it for looks, buy it for life.®</p>
  </div>
</div>
<br>
<br>
<h2>Small Modal - no image</h2>
<div data-tooltip-modal="" class="tooltip__modal-info tooltip__modal-info--small is-active">
  <div class="tooltip__col-right">
    <div class="heading--6">This is the Title</div>
    <p>Dress your home with style that lasts. Use these tools to find the right products for you. Buy it for looks, buy it for life.®</p>
  </div>
</div>

Styleguide 3.16.3
*/

@include block('tooltip') {

  @include element('modal-info') {
    color: $c_text-base;
    border: 1px solid $c_tooltip_gray;
    visibility: hidden;
    font-size: 1rem;
    background-color: $c_white;
    opacity: 0;
    padding: .5em;
    text-align: left;
    white-space: normal;
    transition: opacity .25s linear;
    box-shadow: 4px 3px 12px rgba($c_shadow, .3);
    z-index: z("modal-facet-tooltips");
    p {
      padding: .7em 0;
    }

    p,
    figcaption {
        font-size: .9em;
    }

    figure {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      overflow: hidden;
      padding: 0 .25em;
      text-align: center;
    }

    .tooltip__image {
      height: 100%;
      width: 100%;
      position: relative;
    }

    @include modifier('large') {
      max-width: 380px;
      figure {
        flex-basis: 100%;
        flex-shrink: 1;
        flex-grow: 0;
        max-width: 140px;
        padding: 0 1em;
        height: 100%;
      }
      figcaption {
        display: block;
        text-align: center;
      }
      figcaption {
        padding: .5em 0;
      }
      .tooltip__image {
        > img {
          max-width: 100%;
          height: auto;
          max-height: 100px;
          width: 100%;
        }
      }
    }

    @include modifier('small') {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 320px;
      p {
        padding: .5em 0;
      }
      figure {
        padding-right: .5em;
      }
      .tooltip__image {
        > img {
          height: auto;
          width: 100%;
        }
      }
      .tooltip__col-left {
        flex-basis: auto;
        flex-shrink: 0;
        flex-grow: 0;
        max-width: 40%;
      }
    }

    @include state('active') {
      visibility: visible;
      opacity: 1;
    }

    /*=============================================>>>>>
    = Popper JS Styling =
    ===============================================>>>>>*/

    $tooltipWidth: 10px;
    &[x-placement="right"] {
      margin-left: $tooltipWidth * 1.2;
    }
    &[x-placement="left"] {
      margin-left: 0;
      margin-right: $tooltipWidth * 1.2;
    }
    &[x-placement="bottom"] {
      margin-top: $tooltipWidth * 1.2;
    }
    &[x-placement="top"] {
      margin-bottom: $tooltipWidth * 1.2;
    }

  }

  @include element('container') {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }

}
