/*
PDP Y.M.A.L. Section

Styleguide 4.10.9
*/

@include block (pdp-youmightlike) {
  @include outer-container();
  font-size: 1rem;
  overflow: hidden;
  text-align: center;
  margin: 1.5em 0;

@include element(line){
  padding: 0 1em;
  @include media($bp-md) {
    display: none;
  }
   .pdp-youmightlike__line_inner{
     width: 100%;
     height: 1px;
     border-top: 1px solid #ececec;
     margin: 12px 0;
   }
}

  @include element(heading) {
    font-family: $din-light;
    font-weight: 600;
    font-size: 16px;
    color: #5B7F95;
    text-align: left;
    margin: 0px;
    padding: 0 1em;
    margin-top: 5px;
    @include media($bp-md) {
      margin-bottom: 20px;
    }
  }

  @include element(body) {
    @include clearfix();
    margin-top: 1em;
    padding: 0 0.6em;

  }

  @include element(title) {
    color: $c_moen-bright-blue;
    font-size: em(12px);
    padding-bottom: .2em;
    @include media($bp-md) {
      font-size: em(14px);
    }
  }
  @include element(producttitle) {
    font-size: em(14px);
    font-weight: 500;
    color: #333333;
    line-height: 18px;
    @include media($bp-md) {
      font-size: em(16px);
      padding-bottom: 0.5em;
    }
    //min-height: 47px;
  }



  @include element(image) {
    display: block;
    padding: .5em;
    img {
      height: auto;
      max-width: 100%;
    }
  }

  // Instead, we have to hard-code it like this:
  //.slick-track{
  //  display: flex;
  //  flex-wrap: wrap;
  //  @include media($bp-md) {
  //    display: block;
  //  }
  //}


  .slick-next,
  .slick-prev {
    height: 90px;
    width: 40px;
  }
  .slick-prev {
    z-index: 1;
    left: 0;
    @include media($bp-md) {
      left: 0;
    }
    background-image: url("./images/icon-arrow_left_large-gray.svg");
  }

  .slick-next {
    right: 0px;
    @include media($bp-md) {
      right: -5px;
    }
    background-image: url("./images/icon-arrow_right_large-gray.svg");
  }
}

@media (max-width: 767px) {

}
