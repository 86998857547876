@include block(tap) {
// GRID TRAY
	@include element(grid-tray) {
  	position: absolute !important;
		z-index: 3;
		width: 276px;
		height: 100%;
// 		height: auto;
/*
		max-height: 100vh; 
		overflow: scroll;
*/
// 		transform: translateX(-100%) !important;
// 		transition: transform 0.5s ease-in-out;
		transition: left 0.5s ease-in-out;
		background: #FFFFFF;
		top:0;
		left:0;
		left: -276px !important;
		@include media($bp-lg) {
			width:355px;
			left: -355px !important;
		}
		@include element(toggle) {
			cursor: pointer;
			position: absolute;
			top:0;
			right:1px;
			transform: translateX(100%);
			width: 68px;
			height: 45px;
			padding: 0px;
			background: #FFFFFF;
			border: 1px solid #E5E5E5;
			box-shadow: 0 0 20px 0 rgba(150,150,150,0.25);
			z-index: 2;
			outline: none;
			//transition: all 0.5s ease;
			text-align: center;
			top:16px;
			@include media($bp-md) {
				width: 26px;
				top:25px;
			}
			@include media($bp-lg) {
				top: 50px;
			}
			@include media(1400px) {
				display: none;
			}
			&:after {
				content: ' ';
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5.5px 0 5.5px 5px;
				border-color: transparent transparent transparent $c_moen-gray;
				vertical-align: middle;
		    margin-top: -5px;
		    margin-left: -2px;
			}
			span {
				display: inline-block;
				font: normal 11px/10px $proxima-bold;
				text-transform: uppercase;
				color: $c_moen-gray;
				vertical-align: middle;
				margin-right: 10px;
				@include media($bp-md) {
					display: none;
				}
			}
			
		}
		@include element(inner) {
// 			position: absolute;			
			position: relative;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			height: auto;
			background-color: #FFF;
			z-index: 3;
			box-shadow: 0 0 20px 0 rgba(150,150,150,0);
			transition: box-shadow 0.5s ease, top 0.1s linear;
			padding: 30px 25px 30px 30px;
			@include media($bp-lg) {
				padding: 50px 40px;
			}
			@include media(1520px) {
				padding-left: 0;
			}
			&:before {
				content: ' ';
				display: block;
				position: absolute;
				top: 0;
				left: -999px;
				padding-left: 999px;
				background: #FFF;
				height: 100%;
			}
			.heading {
				font: bold 21px/30px $playfair;
				color: $c_text_base;
				@include media($bp-lg) {
					font: bold 24px/30px $playfair;
				}
			}
			.tap__most-popular {
				list-style: none;
				padding:0;
				margin:0;
				margin-bottom: 15px;
				li {
					display: block;
					padding:0;
					margin:0;
					margin-bottom: 15px;
					a {
						color: $c_text-base;
						text-decoration: none;
					}
					.tag {
						margin-bottom: 5px;
					}
					p {
						font: normal 16px/21px $proxima-regular;
						color: $c_text-base;
						margin: 0;
						padding:0;
						
					}
				}
			}
			
		}
	}
	
	@include element(grid-tray-callout) {
		display: inline-block;
		padding:15px;
		position: relative;
		border: solid 1px $c_moen_bright_gray;
		margin-bottom: 20px;
		.inner {
			position: absolute;
			left:0;
			right:0;
			width: 100%;
			bottom: 30px;
			background: rgba($c_concrete,0.9);
			padding:20px;
			p {
				color: $c_mid-gray;
				font: normal 14px/18px $proxima-regular;
				@include media($bp-lg) {
					font: normal 16px/18px $proxima-regular;
				}
			}
			.button--cta {
				display: inline-block;
			}
			.social-follow-menu img {
				width: 30px;
				height: auto;
				margin-top: 0;
				margin-right: 15px;
				margin-bottom: 15px;
				@include media($bp-lg) {
					margin-right: 10px;
				}
			}			
			.social-follow-menu li:last-child img {
				margin-right: 0;
			}
		}
		@include modifier(press) {
			background: $c_concrete;
			padding: 30px 20px;
			.heading {
				font: bold 21px/18px $playfair !important;
				color: $c_moen-bright-blue;			
				padding-bottom: 15px;	
			}
			p {
				font: normal 16px/21px $proxima-regular;
				color: $c_moen-gray;
				padding-bottom: 20px;
			}
			a {
				font: normal 14px/14px $din-bold;
				color: $c_moen-bright-blue;
			}
		}
	}
// END GRID TRAY 
}
.tap-grid-tray-open {
	.tap {
		.tap__grid-tray {
			left: 0% !important;
			.tap__grid-tray__toggle {
				width: 26px;
				&:after {
					transform: rotate(180deg);
				}
				span {
					display: none;
				}
			}
			.tap__grid-tray__inner {
				box-shadow: 0 0 20px 0 rgba(150,150,150,0.25);
			}
		}
	}
}