// .plmr-c-valve-selection
@include plmr-component('valve-selection') {

  // .plmr-c-valve-selection__label
  @include element('label') {
    color: plmr-color('brand', 'primary');
    font-size: 1rem;
    line-height: 1.143rem;
    padding-bottom: 0;

    @include plmr-mq('md') {
      font-size: 1.143rem;
      line-height: 1.285rem;
    }
  }

  //.plmr-c-valve-selection__label-tooltip
  @include element('label-tooltip') {
  }

  // .plmr-c-valve-selection__input
  @include element('input') {
    max-width: 353px;
    padding-top: 10px;

    @include plmr-mq('md') {
      margin-bottom: -10px;
      padding-top: 15px;
    }
  }

  // .plmr-c-valve-selection__spec
  @include element('spec') {
    color: plmr-color('text', 'primary');
    font-size: 0.875rem;
    line-height: 1.143rem;
    padding-bottom: 0;
    padding-top: 10px;

    @include plmr-mq('md') {
      font-size: 1rem;
      line-height: 1.285rem;
      padding-top: 20px;
    }
  }

  // .plmr-c-valve-selection__msrp
  @include element('msrp') {
    color: darken(plmr-color('text', 'secondary'), 8%);
    padding-bottom: 0;
    padding-top: 15px;

    @include plmr-mq('md') {
      font-size: 1.143rem;
      line-height: 2rem;
      padding-top: 20px;
    }
  }
}
