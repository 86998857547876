
/// .header-container
@include block('header-container') {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $max-width;
  min-width: $min-width;
  
  
  /// .header-container--top
  @include modifier('top') {
    flex-direction: column;
    
    
    /// screen > 768px
    @include media($bp-sm) {
      flex-direction: row;
    }
    
  }
  
}


/// .site-header
@include block('site-header') {
  position: relative;
  z-index: z("site-header");
}
