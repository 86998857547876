
// .plmr-c-feedback
@include plmr-component('feedback') {
  max-width: 80%;
  position: fixed;
  right: 0;
  top: 65%;
  transform: translateX(100%) translateY(-50%);
  transition: all 0.5s linear;
  z-index: plmr-z($layer: 'feedback');



  @include plmr-state('init') {
    right: -200%;
    // transform: translateX(500%) translateY(-50%);
    visibility: hidden;
  }

  // .plmr-c-feedback._ready
  @include plmr-state('ready') {
    // transform: translateX(100%) translateY(-50%);
    // transition-delay: 2s;
  }


  // .plmr-c-feedback._open
  @include plmr-state('open') {
    transform: translateX(0) translateY(-50%);
  }


  // .plmr-c-feedback__toggle
  @include element('toggle') {
    @include plmr-font-face($family: 'medium');
    background: plmr-color('brand', 'secondary');
    border-radius: 5px 5px 0 0;
    box-shadow: 0 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    color: plmr-color('base', 'white');
    cursor: pointer;
    left: -51px;
    letter-spacing: 0.44px;
    padding: 8px 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    transform-origin: center center;
    transition: all 0.2s linear;
    z-index: plmr-z($layer: 'feedback', $offset: 1);


    // need to break out into a global mixin
    &:active,
    &:focus,
    &:hover {
      background: darken(plmr-color('brand', 'secondary'), 6%);
      padding-bottom: 25px;
    }


    // .plmr-c-feedback__toggle._open
    @include plmr-state('open') {

      &:active,
      &:focus,
      &:hover {
        padding-bottom: 8px;
      }

    }

  }


  // .plmr-c-feedback__window
  @include element('window') {
    background: plmr-color('background', 'primary');
    border: 3px solid darken(plmr-color('brand', 'secondary'), 6%);
    box-shadow: 0 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    max-width: 600px;
    min-width: 260px;
    padding: 50px 20px 20px;
    position: relative;
    text-align: center;
    width: 100%;


    // screen width > 768px
    @include plmr-mq('md') {
      padding: 50px;
      width: 600px;
    }

  }


  // .plmr-c-feedback__close
  @include element('close') {
    color: plmr-color('accent', 'dark');
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }


  // .plmr-c-feedback__logo
  @include element('logo') {
    color: plmr-color('brand', 'primary');
    margin-bottom: 2em;
  }


  // .plmr-c-feedback__title
  @include element('title') {
    font-size: 18px;
    margin-bottom: 1.5em;
  }


  // .plmr-c-feedback__ctas
  @include element('ctas') {
    margin: 0 auto;
    min-width: 214px;
    width: 100%;


    // screen > 768px
    @include plmr-mq('md') {
      max-width: 80%;
    }

  }

}
