
// Base Config setting variables
// -----------------------------------------------------------------------------
$font-family-helvetica: Arial, 'Helvetica Neue', Helvetica, sans-serif !default;

$base-container-max-width: 1500px !default;
$base-container-min-width: 320px !default;
$base-gutter-width: 1rem !default;

$base-content-max-width: 700px !default;

$base-font-family: $font-family-helvetica !default;
$base-font-size-root: 14px !default;
$base-font-size: 1rem !default;

$base-line-height: 1.2 !default;

$base-grid-columns: 12 !default;



// Base Config settings
// -----------------------------------------------------------------------------
$base-config: (
  'container': (
    'min-width': $base-container-min-width,
    'max-width': $base-container-max-width,
    'gutter-width': $base-gutter-width
  ),

  'content': (
    'max-width': $base-content-max-width
  ),

  'font': (
    'font-family': $base-font-family,
    'font-size': $base-font-size,
    'font-size-root': $base-font-size-root,
    'line-height': $base-line-height
  ),

  'grid': (
    'columns': $base-grid-columns
  )
);



// Base fonts
// -----------------------------------------------------------------------------
$base-fonts: (
  'base': $base-font-family
);
