
// Font Styling Mixin
// -----------------------------------------------------------------------------

@mixin plmr-font-face($family: 'base', $style: normal, $weight: normal) {
  font-family: plmr-font($family);

  // font-style properties
  @if $family == 'italic' {
    font-style: italic;
  } @else {
    font-style: $style;
  }

  // font-weight properties
  @if $family == 'bold' {
    font-weight: bold;
  } @else {
    font-weight: $weight;
  }

}
