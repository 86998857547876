
// Main Navigation - Flyout
// -----------------------------------------------------------------------------

// .plmr-c-nav-main-flyout
@include plmr-component('nav-main-flyout') {
  @include plmr-link($color: plmr-color('text', 'primary'));
  background-color: plmr-color('background', 'primary');
  box-shadow: 0 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
  display: none;
  left: 0;
  position: absolute;
  top: 70px;
  width: 100%;


  // screen width > 768px
  @include plmr-mq('md') {
    top: 77px;
  }


  // .plmr-c-nav-main-flyout._open
  @include plmr-state('open') {
    display: block;
  }


  // .plmr-c-nav-main-flyout__container
  @include element('container') {
    background-position: 0 -10px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    margin: 0 auto;
    max-width: plmr-config('container', 'max-width');
    min-height: 400px;
    padding-top: 50px;
    position: relative;
    width: 100%;

    @include modifier('inspiration') {
      padding: 50px 90px;
    }

    @include modifier('about-moen'){
      padding: 50px 20px;
    }

  }

  // .plmr-c-nav-main-flyout__product
  @include element('product') {
    display: block;
    height: 400px;
    left: 0;
    position: absolute;
    top: 0;
    width: 120px;


    // screen width > 992px
    @include plmr-mq('lg') {
      width: 248px;
    }


    // .plmr-c-nav-main-flyout__product--parts
    @include modifier('parts') {


      // screen width > 992px
      @include plmr-mq('lg') {
        width: 395px;
      }
    }


    // .plmr-c-nav-main-flyout__product--service
    @include modifier('service') {
      display: none;

      // screen width > 992px
      @include plmr-mq('lg') {
        display: block;
      }

    }

  }


  // .plmr-c-nav-main-flyout__product-image
  @include element('product-image') {
    display: block;
    height: auto;
    left: -115%;
    position: relative;
    width: auto;


    // screen > 992px
    @include plmr-mq('lg') {
      left: auto;
      max-height: 100%;
      max-width: 100%;

    }

  }


  // .plmr-c-nav-main-flyout__main
  @include element('main') {
    display: flex;
    flex-grow: 1;
    margin-left: 2%;
    width: calc(100% - 175px);


    // screen width > 992px
    @include plmr-mq('lg') {
      margin-left:0;
      width:100%;
    }



    // .plmr-c-nav-main-flyout__main--category
    @include modifier('category') {
      display: block;
      padding-right: 90px;

      // screen width > 992px
      @include plmr-mq('lg') {
        padding-right: 0;
      }

    }


    // .plmr-c-nav-main-flyout__main--inspiration
    @include modifier('inspiration') {

      // screen width > 992px
      @include plmr-mq('lg') {
        justify-content: space-between;
        margin-left: 0;
        max-width: 100%;
        width: 100%;

      }



    }
    @include modifier('aboutmoen') {
      @include plmr-mq('lg') {
        width: 50%;
      }
    }


    // .plmr-c-nav-main-flyout__main--parts
    @include modifier('parts') {

      // screen width > 992px
      @include plmr-mq('lg') {
        justify-content: space-between;
        margin-left: percentage(500 / 1300);
        margin-right: percentage(90 / 1300);
        max-width: 100%;
        width: calc(100% - 590px);
      }

    }


    // .plmr-c-nav-main-flyout__main--service
    @include modifier('service') {
      // justify-content: space-between;
      margin-left: 0;
      margin-right: 0;
      padding-left: 25px;
      padding-right: 25px;

      // screen width > 992px
      @include plmr-mq('lg') {
        margin-left: percentage(50 / 1300);
        margin-right: percentage(50 / 1300);
        max-width: 100%;
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
      }

      // screen width > 1200px
      @include plmr-mq('xl') {
        // padding-right: percentage(140 / 1300);
      }

    }

  }



  @include element('main-about'){
    margin-left: 0;
  }

  // .plmr-c-nav-main-flyout__side
  @include element('side') {
    display: none;


    // screen width > 992px
    @include plmr-mq('lg') {
      display: block;
      flex-grow: 0;
      max-width: 250px;
      width: calc(100% - 250px);
    }


    // .plmr-c-nav-main-flyout__side--inspiration
    @include modifier('inspiration') {
      padding-left: 105px;
    }




  }


  // .plmr-c-nav-main-flyout__section
  @include element('section') {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }


  // .plmr-c-nav-main-flyout__section-column
  @include element('section-column') {
    display: flex;
    flex-direction: column;
  }


  // .plmr-c-nav-main-flyout__section-column-actions
  @include element('section-column-actions') {
    display: flex;
    flex-direction: column;

    // screen width 962px
    @include plmr-mq('lg') {
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-around;
    }

  }


  // .plmr-c-nav-main-flyout__browse-all
  @include element('browse-all') {
    @include plmr-font-face($family: 'bold');
    display: inline-block;
    font-size: 14px;
    line-height: 18px;


    // .plmr-c-nav-main-flyout__browse-all--anchored
    @include modifier('anchored') {
      bottom: 50px;
      position: absolute;
    }

  }


  // .plmr-c-nav-main-flyout__button-cta
  @include element('button-cta') {

    // .plmr-c-nav-main-flyout__button-cta--service
    @include modifier('service') {
      margin-bottom: 24px;
    }
  }
}


// .plmr-c-nav-main-sub-nav
@include plmr-component('nav-main-sub-nav') {
  max-width: 250px;


  // screen width > 768px
  @include plmr-mq('md') {
    margin-bottom: 30px;
  }


  // screen width > 992px
  @include plmr-mq('lg') {
    // margin-bottom: 50px;
    margin-right: percentage(30 / 615);

  }


  // screen width > 1400px
  @include plmr-mq('xxl') {
    margin-right: percentage(49.5 / 615);
  }


  // .plmr-c-nav-main-sub-nav__section-title
  @include element('section-title') {
    @include plmr-font-face($family: 'regular');
    color: plmr-color('brand', 'primary');
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 17px;
  }


  // .plmr-c-nav-main-sub-nav__title
  @include element('title') {
    @include plmr-font-face($family: 'bold');
    @include plmr-link($color: plmr-color('brand', 'primary'));
    display: inline-block;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 21px;
    text-decoration: none;
  }


  // .plmr-c-nav-main-sub-nav__list
  @include element('list') {

  }


  // .plmr-c-nav-main-sub-nav__item
  @include element('item') {
    margin-bottom: 5px;
  }


  // .plmr-c-nav-main-sub-nav__link
  @include element('link') {
    @include plmr-font-face($family: 'regular');
    @include plmr-link($color: plmr-color('text', 'primary'));
    font-size: 16px;
    line-height: 28px;
  }

}

@include plmr-component('nav-main-sub-nav-moen-cn') {

  @include plmr-mq('md') {
    margin-right: 40px;
  }
  @include plmr-mq('lg') {
    margin-right: 18%;

  }
  // screen width > 1400px
  @include plmr-mq('xxl') {
    margin-right: 22%;
  }


}


