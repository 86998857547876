@include plmr-component('mastfoot-qr') {
  display: none;
  // screen width > 768px
  @include plmr-mq('md'){
    display: flex;
    flex-grow: 0.3;
    order: 3;
    justify-content: space-around;
    padding-top: 20px;
  }

  //.plmr-c-mastfoot-qr__box
  @include element('box'){
    width: 120px;
    text-align: center;
  }

  //.plmr-c-mastfoot-qr__img
  @include element('img'){
    text-align: center;
    //padding: 10px;
    background: #fff;
    margin-bottom: 10px;
  }

  //.plmr-c-mastfoot-qr__fsmall
  @include element('fsmall'){
    font-size: 12px;
    margin-bottom: 2px;
  }

  //.plmr-c-mastfoot-qr__flarge
  @include element('flarge'){
    font-size: 15px;
  }
}
