/*
Card Components

Styleguide 3.15
*/

$c_card_bg_blue: $c_moen-bright-blue !default;
$c_card_bg_translucent_blue : rgba($c_card_bg_blue, 0.9) !default;

@include block(card) {
  // SETTINGS COMMON TO ALL CARDS:
  font-size: 1rem;
  font-weight: normal;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;

  width: 320px;
  max-width: 320px;

  overflow: hidden;

  padding: .5rem;
  margin: 0 0 1em;

  background-color: $c_white;


  .ie11 & {
    max-width: none;
  }

  .ie11 .card-list__list--two-cards & {
    flex-grow: 0;
    // width: 360px !important;
    width: 100%;

    @include media($bp-md) {
      width: percentage( 360 / 1000 );
    }
  }

  sup {
    font-size: 0.5em;
    top: -0.7em;
  }

  @include element(title) {
    font-weight: inherit;
    letter-spacing: -.05em;
  }

  @include element(subtitle) {
    font-weight: inherit;
  }

  @include element(content) {
    font-weight: inherit;
  }

  @include element(image) {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  @include element(logo) {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  @include element(links) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .card__content-wrapper {
    width: 100%;
  }

  .card__back-content {
    p {
      width: 100%;
    }
  }

  .link--icon--right-white {
    padding-right: 1.5em; // maybe this is a better value globally? it helps with very narrow widths when container is flexing.
    align-self: center;
  }
  .link--icon--left-white {
    padding-left: 0;
  }

  // THIS BREAKPOINT / WIDTH VALUE ALLOWS CARDS TO DISPLAY AS 3 OR 4 ON A FLEX ROW:
  @include media($bp-xs) {
    width: 230px;
    margin: 0 7px 1em;

    .ie11 & {
      // width: 100%; // rperry - 2017-07-17 -- don't remember why I added this but causing some chaos in IE
    }
  }

  // SETTINGS FOR ALL CARD SHIMS:
  &.shim {
    padding: 0;
    margin: 0;
    height: 0;
    min-height: 0;
    border: none;
    box-shadow: none;
    background: none;
    @include media($bp-xs) {
      margin: 0 7px;
    }
  }

  &.card--simple-white-box.shim {
    display: none;
    padding: 0;
    @include media($bp-xs) {
      display: flex;
    }
    @include media($bp-md) {
      padding: 30px;
    }
  }
}

/*
whitespace card with top blue line

A single-instance example of this card. See organisms section for the full card-list component layout.

markup:
<div class="card card--top-blue-border">
  <div class="pointer-item is-selected"></div>
  <h2 class="card__title">Transitional Styles</h2>
  <img class="card__image" src="./images/styleguide/product_shot_transitional.jpg" alt="" />
  <div class="card__links">
    <a href="javascript:void(0);" class="link link--icon--left">
      <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">View Kiran<sup>™</sup> Faucet
    </a>
    <a href="javascript:void(0);" class="link link--icon--left">
      <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">View All Transitional Styles
    </a>
  </div>
</div>

Styleguide 3.15.1
*/

@include block(card) {

  @include modifier(top-blue-border) {
    border-top: 4px solid $c_moen-bright-blue;

    height: 340px;
    min-height: 340px;

    padding: 0;
    margin-bottom: em(20);

    @include transition(height 0.3s, box-shadow 0.3s);

    .pointer-item {
      visibility: hidden;
      margin-bottom: em(20);
      &.is-selected {
        &:after {
          border-top-color: $c_moen-bright-blue;
        }
      }
    }

    .card__title {
      color: $c_moen-bright-blue;
      font-size: em(26);
      font-family: $din-light;
      font-weight: normal;
      margin: 0 0 em(10);
      text-align: center;
    }

    .card__image {
      margin: 0 0 em(10);
      height: 230px; // fixed height to allow for any aspect ratio coming in from Widen DAM
    }

    .card__links {
      font-size: em(15);
    }

    .link {
      margin-right: 1.2em; // offset against in-built icon-link padding - helps to make link appear horizontally centered.
      visibility: hidden;
      text-align: center;
      display: block;
      max-width: 100%; // ie10
    }

    &:hover,
    &:focus,
    &.is-active {
      box-shadow: 0 0 8px 2px $c_nav_gray;
      text-decoration: none;
      height: 380px;
      .link,
      .pointer-item {
        visibility: visible;
      }
    }
  }
}

/*
simple white boxes

[description]

markup:
<a class="card card--simple-white-box" href="javascript:void(0);">
  <img class="card__image" src="./images/icon-instructions.svg" alt="" />
  <h2 class="card__title">Parts &amp; Instruction Sheets</h2>
  <p class="card__subtitle">Find instruction sheets, product specs, parts diagrams, and more.</p>
</a>

Styleguide 3.15.2
*/

@include block(card) {
  @include modifier(simple-white-box) {
    box-shadow: 2px 2px 4px -3px #333;
    border: 3px solid transparent;
    text-align: center;
    min-height: 280px;
    width: auto !important;
    margin-bottom: em(25);
    @include transition(border 0.5s);

    // .ie11 & {
    //   flex-basis: 100%;
    // }


    @include media($bp-sm) {
      flex-basis: 40%;
    }
    @include media($bp-md) {
      padding: 30px;
      flex-basis: 30%;
    }
    @include media($bp-lg) {
      flex-basis: 25%;
    }

    @include state(narrow) {
      @include media($bp-lg) {
        flex-basis: 20%;
      }
    }

    .card__title {
      color: $c_moen-bright-blue;
      font-family: $din-bold;
      font-size: em(16);
      line-height: 1.25;
      margin: 10px auto;
    }

    .card__image {
      width: 100%;
      height: 100px;

      @include modifier(auto){
      	width: auto;
      }
    }

    .card__subtitle {
      color: $c_dove-gray;
      font-size: em(14);
      line-height: 1.2;
      max-width: 100%; // ie10, ie11 fix.
    }

    &:hover,
    &:focus,
    &.is-active {
      border-color: $c_moen-bright-blue;
    }
  }
}

/*
blue boxes

[description]

markup:
<a class="card card--blue-box" href="javascript:void(0);" style="background-image: url(./images/styleguide/innovation-motionsense.jpg);">
  <div class="card__front-content">
    <h3 class="card__title">MotionSense<sup>®</sup></h3>
    <p class="card__subtitle">Hands-free convenience</p>
  </div>
  <div class="card__back-content">
    <p class="card__back-image" style="background-image: url(https://embed.widencdn.net/img/moen/xo5sjvcu2r/200px/motionsense_logo.png?u=4wu0a0);">MotionSense</p>
    <p>MotionSense<sup>®</sup> responds the instant you need it, delivering exceptional, hands-free convenience in the kitchen.</p>
    <span class="link--icon--right-white">Explore MotionSense <img class="icon" src="./images/icon-arrow_point_right_white.svg" alt="" aria-hidden="true"></span>
  </div>
</a>


Styleguide 3.15.3
*/

@include block(card) {
  @include modifier(blue-box) {
    display: block;
    background: transparent no-repeat;
    background-size: cover;
    position: relative;

    height: 323px;
    min-height: 323px;

    // flex-basis: 100%;

    .card__front-content {
      color: $c_white;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $c_card_bg_translucent_blue;
      padding: 15px;
      min-height: 100px;
    }

    .card__back-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $c_card_bg_translucent_blue;
      color: $c_white;
      text-align: center;
      padding: 0 .5em; // have to restate padding since this is pos:absolute
      font-size: em(18);
      line-height: 1.334;

      @include transition(opacity 0.3s linear);

      p {
        font-size: inherit;
        text-align: left;
      }
    }

    .card__title {
      font-size: em(24);
      line-height: 1.25;
      margin: 0;
      padding: 0
    }

    .card__subtitle {
      margin: 0;
      padding: 0;
    }

    .card__back-image {
      width: 70%;
      height: 25%;
      text-indent: -99999px;
      overflow: hidden;
      padding: 0;
      margin: 1em auto;
      background-position: 50%;
      background-size: contain
    }

    &:hover,
    &:focus,
    &.is-active {
      .card__front-content {
        display: none;
      }
      .card__back-content {
        opacity: 1;
      }
    }
  }
}

/*
text left, 3 right

[description]

markup:
<a class="card card--box" href="javascript:void(0);" style="background-image: url(./images/styleguide/card_microban.jpg);">
      <div class="card__front-content">
        <h3 class="card__title">MotionSense<sup>®</sup></h3>
        <p class="card__subtitle">Hands-free convenience</p>
      </div>
      <div class="card__back-content">
        <p class="card__back-image" style="background-image: url(https://embed.widencdn.net/img/moen/xo5sjvcu2r/200px/motionsense_logo.png?u=4wu0a0);">MotionSense</p>
        <p>MotionSense<sup>®</sup> responds the instant you need it, delivering exceptional, hands-free convenience in the kitchen.</p>
        <span class="link--icon--right-white">Explore MotionSense <img class="icon" src="./images/icon-arrow_point_right_white.svg" alt="" aria-hidden="true"></span>
      </div>
    </a>

Styleguide 3.15.4
*/
@include block(card) {
  @include modifier(box) {
    position: relative;
    display: block;

    background: transparent 50% 0 no-repeat;
    background-size: cover;

    //height: 276px;
    min-height: 276px;

    &.card {
      width: 100%; // overridden
      max-width: 100%; // overridden
      @include media($bp-md) {
        width: 230px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background: linear-gradient(to bottom, rgba($c_white, 0) 0%, rgba(126, 126, 126, .1) 60%, rgba($c_black, 0.63) 100%);
    }

    .card__front-content {
      position: absolute;
      color: $c_white;
      //width: 100%;
      bottom: 0;
      left: 0;
      padding: 15px;
    }

    .card__back-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: .5em;

      position: absolute;
      opacity: 0;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-color: $c_card_bg_translucent_blue;
      color: $c_white;

      text-align: center;

      font-size: em(18);
      line-height: 1.334;

      @include transition(opacity 0.3s linear);

    }

    .card__title {
      font-size: em(24);
      line-height: 1.25;
      margin: 0;
      padding: 0
    }

    .card__subtitle {
      padding: 0;
    }

    .card__back-image {
      width: 100%;
      height: 50px;
      text-indent: -99999px;
      overflow: hidden;
      padding: 0;
      margin: 1em;
      background-position: 50%;
      background-size: contain;
    }

    &:hover,
    &:focus,
    &.is-active {
      .card__front-content {
        display: none;
      }
      .card__back-content {
        opacity: 1;
      }
    }
  }
}

/*
tall card

[description]

markup:
<a class="card card--tall-box" href="javascript:void(0);" style="background-image: url(./images/styleguide/card_microban.jpg);">
  <div class="card__front-content">
    <h3 class="card__title">MotionSense<sup>®</sup></h3>
    <p class="card__subtitle">Hands-free convenience</p>
  </div>
  <div class="card__back-content">
    <p class="card__back-image" style="background-image: url(https://embed.widencdn.net/img/moen/xo5sjvcu2r/200px/motionsense_logo.png?u=4wu0a0);">MotionSense</p>
    <div class="card__content-wrapper">
      <p>MotionSense<sup>®</sup> responds the instant you need it, delivering exceptional, hands-free convenience in the kitchen.</p>
      <span class="link--icon--right-white">Explore MotionSense <img class="icon" src="./images/icon-arrow_point_right_white.svg" alt="" aria-hidden="true"></span>
    </div>
  </div>
</a>

Styleguide 3.15.5
*/
@include block(card) {
  @include modifier(tall-box) {
    position: relative;
    display: block;

    background: transparent 50% 0 no-repeat;
    background-size: cover;

    min-height: 406px;

    &.card {
      width: 100%; // overridden
      max-width: 100%; // overridden
      @include media($bp-md) {
        width: 230px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background: linear-gradient(to bottom, rgba($c_white, 0) 0%, rgba(126, 126, 126, .1) 60%, rgba($c_black, 0.63) 100%);
    }

    .card__front-content {
      position: absolute;
      color: $c_white;
      bottom: 0;
      left: 0;
      padding: 15px;
    }

    .card__back-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: .5em;

      position: absolute;
      opacity: 0;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-color: $c_card_bg_blue;
      color: $c_white;

      text-align: center;

      font-size: em(18);
      line-height: 1.334;

      @include transition(opacity 0.3s linear);

      .card__content-wrapper {
        width: 100%;
      }
    }

    .card__title {
      font-size: em(24);
      line-height: 1.25;
      margin: 0;
      padding: 0
    }

    .card__subtitle {
      padding: 0;
    }

    .card__back-image {
      width: 100%;
      height: 30%;
      text-indent: -99999px;
      overflow: hidden;
      padding: 0;
      margin: 1em auto;
      background-position: 50%;
      background-size: contain
    }

    &:hover,
    &:focus,
    &.is-active {
      .card__front-content {
        display: none;
      }
      .card__back-content {
        opacity: 1;
      }
    }

  }
}

/*
blue wide card with hover

[description]

markup:
<a class="card card--blue-box-wide" href="javascript:void(0);" style="background-image: url(./images/styleguide/card_mpact.jpg);">
  <div class="card__front-content">
    <h3 class="card__title">M●PACT<sup>®</sup></h3>
    <p class="card__subtitle">Change your fixtures with ease</p>
  </div>
  <div class="card__back-content">
    <div class="card__logo" style="background-image: url(https://embed.widencdn.net/img/moen/dnf52tmesp/100px/mpact.png?u=4wu0a0);">M•PACT</div>
    <div class="card__content-wrapper">
      <p class="card__content">With M●PACT, you can change your faucet style without replacing any plumbing. It‘s as easy as changing a light bulb.</p>
      <div class="card__links">
        <span class="link link--icon--left-white">
        <img class="icon" src="./images/icon-arrow_right_thick_white.svg" alt="" aria-hidden="true">Explore M●PACT
      </span>
      </div>
    </div>
  </div>
</a>

Styleguide 3.15.6
*/
@include block(card) {
  @include modifier(blue-box-wide) {
    position: relative;
    //display: block;

    background: transparent right 0 no-repeat;
    background-size: cover;

    min-height: 276px;

    &.card {
      width: 100%; // overridden
      max-width: 100%; // overridden
    }

    box-shadow: 0 0 0 0 #999;

    margin: 0 auto 1em; // overridden

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background: linear-gradient(to bottom, rgba($c_white, 0) 0%, rgba(126, 126, 126, .1) 60%, rgba($c_black, 0.63) 100%);
    }

    .card__front-content {
      position: absolute;
      color: $c_white;
      bottom: 20px;
      left: 20px;
    }

    .card__back-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: .5em;

      position: absolute;
      opacity: 0;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-color: $c_card_bg_translucent_blue;
      color: $c_white;

      @include transition(opacity 0.3s linear);

      @include media($bp-md) {
        // flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }

    }

    .card__title {
      font-size: em(30);
      line-height: 1.25;
      margin: 0;
      padding: 0;
    }

    .card__subtitle {
      padding: 0;
    }

    .card__content-wrapper {
      position: relative;
      padding: 0 1em;
    }

    .card__links {
      margin-top: 1em;
      margin: 0;
      max-width: 100%;
      .link--icon--left-white {
        margin-top: 1em;
        padding-right: 1em;
      }
    }

    .card__content {
      padding: 0;
      text-align: center;
      max-width: 100%; // ie10, ie11 fix
      margin: 0;
    }
    /*
        .card__logo {
          position: relative;
          width: 50%;
          height: 50%;
          text-indent: -99999px;
          overflow: hidden;
          background-position: 50%;
          background-size: contain;
          background-repeat: no-repeat;
          margin: 0 auto .5em;
          @include media($bp-md) {
            margin: 0;
          }
        }*/

    .card__back-image {
      width: 100%;
      height: 100%;
      text-indent: -99999px;
      overflow: hidden;
      padding: 0;
      margin: 1em;
      max-width: 220px;
      max-height: 90px;
      background-position: 50%;
      background-size: contain;
    }

    &:hover,
    &:focus,
    &.is-active {
      .card__front-content {
        display: none;
      }
      .card__back-content {
        opacity: 1;
      }
    }

    // Overriding defaults keeps this layout as 1 wide card:
    @include media($bp-xs) {
      max-width: 660px;
      margin: 0 7px 1em;
    }
  }
  @include modifier(logo-left) {
    .card__back-content {
      padding: 0 2em;
      @include media($bp-md) {
        flex-direction: row;
      }
    }
    .card__logo {
      max-width: 70%;
      margin-bottom: 1em;
      @include media($bp-md) {
        max-width: 30%;
        margin-bottom: 0;
      }
    }
    .card__content {
      padding: 0 1em;
    }
  }
}

/*
text right, image left

[description]

markup:
<div class="card">
</div>

Styleguide 3.15.7
*/

/*
no hover

[description]

markup:
<div class="card card--no-hover">
  <img src="./images/styleguide/visual-assets_sub_1.jpg" alt="" class="card__image">
  <ul class="list card__links">
    <li class="card__link-item"><a class="link" href="javascript:void(0);" title="Moen Logos">Logos</a></li>
    <li class="card__link-item"><a class="link" href="javascript:void(0);" title="Moen Brand Standards" target="_blank">Moen Brand Standards</a></li>
  </ul>
</div>

Styleguide 3.15.8
*/

@include block(card) {

  @include modifier(no-hover) {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 7px 1em;
    padding: 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background: linear-gradient(to bottom, rgba($c_white, 0) 0%, rgba(126, 126, 126, .1) 60%, rgba($c_black, 0.63) 100%);
    }

    .card__image {
      display: block;
      width: 100%;
      height: auto;
      min-height: 236px;
    }

    .card__links {
      align-self: flex-start;
      display: block;
      text-align: left;
      font-size: em(14);
      line-height: 1;
      position: absolute;
      padding-left: 1em;
      bottom: 1em;
      .link {
        color: $c_white;
      }
    }

    .card__link-item {
      margin-bottom: .5em;
    }
  }
}



/// .card-no-hover-single-link
@include block('card-no-hover-single-link') {


  /// .card-no-hover-single-link::before pseudo-element
  &::before {
    display: none;
  }


  /// .card-no-hover-single-link__link
  @include child('link') {
    color: $c_white;
    display: block;
    font-size: 14px;
    line-height: 1;
    position: relative;
    text-align: left;



    /// .card-no-hover-single-link__link::before pseudo-element
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background: linear-gradient(to bottom, rgba($c_white, 0) 0%, rgba(126, 126, 126, .1) 60%, rgba($c_black, 0.63) 100%);
    }


    /// .card-no-hover-single-link__link:hover
    &:hover {

      /// .card-no-hover-single-link__link:hover .card__link-item
      .card__link-item {
        text-decoration: underline;
      }

    }

  }

}




/*
hover

[description]

markup:
<div class="card card--hover" style="background-image: url(./images/styleguide/general.jpg);">
  <div class="card__title">Faucets &amp; Parts</div>
  <div class="card__front-content">
  </div>
  <div class="card__back-content">
    <ul class="list card__links">
      <li class="card__link-item">
        <a href="#" class="link link--icon--left-white">
          <img class="icon" src="./images/icon-arrow_right_thick_white.svg" alt="" aria-hidden="true">Lifetime Limited Warranty
        </a>
      </li>
      <li class="card__link-item">
        <a href="#" class="link link--icon--left-white">
          <img class="icon" src="./images/icon-arrow_right_thick_white.svg" alt="" aria-hidden="true">10 year Multi-Family Limited Warranty
        </a>
      </li>
      <li class="card__link-item">
        <a href="#" class="link link--icon--left-white">
          <img class="icon" src="./images/icon-arrow_right_thick_white.svg" alt="" aria-hidden="true">5 Year Limited Warranty
        </a>
      </li>
      <li class="card__link-item">
        <a href="#" class="link link--icon--left-white">
          <img class="icon" src="./images/icon-arrow_right_thick_white.svg" alt="" aria-hidden="true">One Year Warranty - Parts
        </a>
      </li>
    </ul>
  </div>
</div>


Styleguide 3.15.9
*/

@include block(card) {
  @include modifier(hover) {
    height: 320px;
    position: relative;
    box-shadow: 0 0 0 0 #999;

    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;

    .card__front-content {
      color: $c_white;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: .5em; // restated here as this is absolutely positioned.
    }

    .card__back-content {
      opacity: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: baseline;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $c_card_bg_translucent_blue;
      color: $c_white;
      padding: 0 .5em; // restated here as this is absolutely positioned.
      font-size: em(18);
      line-height: 1.334;

      p {
        font-size: inherit;
      }

      @include transition(opacity 0.3s linear);

      .link--icon--left-white {
        font-weight: normal;
        padding-right: .8em; // maybe this is a better value globally? it helps with very narrow widths when container is flexing.
        &:hover,
        &:focus,
        &.is-active {
          text-decoration: underline;
        }
      }
    }

    .card__title {
      position: absolute;
      bottom: 30px;
      left: 30px;
      color: $c_white;
      font-size: em(30);
      line-height: 1;
      font-family: $din-regular;
      opacity: 1;
      height: 1em;
    }

    .card__links {
      font-size: em(14);
      display: block;
      padding: 0 0 em(30) 0;
      align-items: flex-start;
      text-align: left;
    }

    .card__link-item {
      margin-top: .5em;
    }

    .link {
      color: $c_white;
    }

    .card__subtitle {
      margin: 0;
      padding: 0;
    }

    .card__back-image {
      width: auto;
      height: 50px;
      overflow: hidden;
      padding: 0;
      margin: 70px auto 30px;
      background-position: 50%;
      background-size: contain
    }

    &:hover,
    &:focus,
    &.is-active {
      .card__front-content {
        display: none;
      }
      .card__back-content {
        opacity: 1;
      }
      .card__title {
        top: 30px;
        z-index: 1;
      }
    }

    // Overriding defaults keeps this layout as max 3 cards per flex row:
    @include media($bp-xs) {
      width: 320px;
      max-width: 320px;
    }
  }
}

/*
Large Card

(seen in Variant 9 video poster frame)

markup:
<a class="card card--blue-box-large" href="javascript:void(0);" style="background-image: url(./images/styleguide/thoughtful_design_magnetix.jpg);">
  <div class="card__front-content">
    <h3 class="card__title">Latest Innovation</h3>
  </div>
</a>

Styleguide 3.15.10
*/
@include block(card) {
  @include modifier(blue-box-large) {
    display: block;
    background: transparent 50% 0 no-repeat;
    background-size: cover;
    position: relative;

    min-height: 395px;

    &.card {
      width: 100%; // overridden
      max-width: 100%; // overridden
      @include media($bp-md) {
        width: 230px;
      }
    }

    .card__front-content {
      // color: $c_white;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $c_white;
      padding: 15px;
    }

    .card__title {
      font-size: em(24);
      line-height: 1;
      margin: 0;
      padding: 0;
    }

    .card__subtitle {
      margin: 0;
      padding: 0;
    }
  }
}

/*
Question Card

This card type is used for the question choices on content such as the "Identify my Product" page.

markup:
<li class="question-card" data-answer="kitchen">
  <a href="javascript:void(0);" class="question-card__content Xroom-option-kitchen">
    <image class="question-card__default-image" src="./images/styleguide/parts_finder/room_kitchen-default.jpg" />
    <image class="question-card__active-image"  src="./images/styleguide/parts_finder/room_kitchen-active.jpg"  />
    <label class="question-card__label Xselected">Kitchen</label>
  </a>
</li>

Styleguide 3.15.11
*/


@mixin question-card-image-base {
  height: auto;
  display: inline-block;
  margin: 0 auto;
  max-width: 188px;
  width: 100%;
  @include media($bp-md) {
    max-width: 288px;
  }
}


@include block(question-card) {
  display: inline-block;
  list-style-type: none; // should this be instead assigned to the parent list container?
  text-align: center;
  @include media($bp-md) {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
    max-width: 25%;
  }


  @include element(content) {
    display: block;
    text-decoration: none;
    color: $c_text-base;
    padding: 5px;
    @include media($bp-sm) {
      padding: 10px;
    }
    @include media($bp-md) {
      padding: 20px;
    }
    border: 1px solid transparent;
    position: relative;

    &:hover,
    &:focus,
    &.is-active {
      text-decoration: none;
      border-color: $c_moen-gray;
    }
  }

  @include element(default-image) {
    @include question-card-image-base();
    //opacity: 1;
    //height: auto;
  }

  @include element(active-image) {
    @include question-card-image-base();
    //opacity: 0;
    //height: 0;
  }

  @include element(label) {
    display: block;
    text-transform: uppercase;
    font-size: 1em;
    line-height: 1.2;
    text-align: center;
    margin: 10px auto 0;
  }

}

/*
Question Card (bath, example 2)

markup:
<li class="question-card" data-answer="bath">
  <a href="javascript:void(0);" class="question-card__content Xroom-option-bath">
    <image class="question-card__default-image" src="./images/styleguide/parts_finder/room_bath-default.jpg" />
    <image class="question-card__active-image"  src="./images/styleguide/parts_finder/room_bath-active.jpg"  />
    <label class="question-card__label Xselected">bath</label>
  </a>
</li>

Styleguide 3.15.11.1
*/

/*
Question Card (laundry & utility, example 3)

markup:
<li class="question-card" data-answer="bath">
  <a href="javascript:void(0);" class="question-card__content Xlaundry-and-utility">
    <image class="question-card__default-image" src="./images/styleguide/parts_finder/room_laundry_and_utility-default.jpg" />
    <image class="question-card__active-image"  src="./images/styleguide/parts_finder/room_laundry_and_utility-active.jpg"  />
    <label class="question-card__label Xselected">laundry &amp; utility</label>
  </a>
</li>

Styleguide 3.15.11.2
*/

/*
Question Card (SVG Show All icon, example 4)

markup:
<li class="question-card" data-answer="showall">
  <a href="javascript:void(0);" class="question-card__content Xlaundry-and-utility">
    <image class="question-card__default-image" src="./images/styleguide/parts_finder/product-finder-showall-default.svg" />
    <image class="question-card__active-image"  src="./images/styleguide/parts_finder/product-finder-showall-active.svg"  />
    <label class="question-card__label Xselected">show all</label>
  </a>
</li>

Styleguide 3.15.11.3
*/

/*
Question Card (SVG Not Sure icon, example 4)

markup:
<li class="question-card" data-answer="notsure">
  <a href="javascript:void(0);" class="question-card__content Xlaundry-and-utility">
    <image class="question-card__default-image" src="./images/styleguide/parts_finder/product-finder-notsure-default.svg" />
    <image class="question-card__active-image"  src="./images/styleguide/parts_finder/product-finder-notsure-active.svg"  />
    <label class="question-card__label Xselected">show all</label>
  </a>
</li>

Styleguide 3.15.11.4
*/



/*
Video Card

markup:
<div class="card-video">
  <a href="#" class="card-video__link js-modal" data-modal-content-id="video-how-to-remove-a-garbage-disposal">
    <div class="card-video__image">
      <img src="//embed.widencdn.net/img/moen/h19losk76r/exact/video-poster-how-to-remove.jpeg?quality=100" class="img--scaled" />
    </div>
    <div class="card-video__title">
      How to Remove a Garbage Disposal
    </div>
  </a>
  <div class="is-hidden" id="video-how-to-remove-a-garbage-disposal">
    <video src="//embed.widencdn.net/stream/sd/moen/hy3fqx7z11/garbage_disposal_removal_retail.mp4" poster="//embed.widencdn.net/img/moen/khr8icuh8q/exact/modal_video-poster-how-it-works.png" controls="">
    </video>
  </div>
</div>

Styleguide 3.15.12
*/

@include block('card-video') {
  display: block;
  margin: 0 auto 1em;
  max-width: 445px;
  overflow: hidden;
  position: relative;

  @include media($bp-md) {
    align-items: center;
    display: flex;
    flex-basis: auto;
    margin: 0 1% 1em;
    min-width: 0;
  }

  @include media($bp-md) {
    align-items: center;
    display: flex;
    flex-basis: auto;
    margin: 0 1% 1em;
  }

  @include child('link') {
    display: block;
  }

  @include child('image') {
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &:before {
      background: url(images/icon-play_outline_white.svg) no-repeat;
      content: '';
      height: 75px;
      max-height: 100px;
      max-width: 100px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 75px;
      z-index: 1;

      @include media($bp-md) {
        height: 100px;
        width: 100px;
      }
    }

    &:after {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(126, 126, 126, .1) 60%, rgba(0, 0, 0, 0.63) 100%);
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  @include child('title') {
    background: rgba($c_moen-bright-blue, 0.9);
    bottom: 0;
    color: $c_white;
    font-family: $din-medium;
    left: 0;
    padding: 0.8em 0.8em 0.6em;
    position: absolute;
    width: 100%;
    z-index:  1;
  }
}

/*
Card Informational

markup:
<div class="card-informational">
  <div class="card-informational__head">
    <div class="card-informational__image">
      <img src="//embed.widencdn.net/img/moen/h19losk76r/exact/video-poster-how-to-remove.jpeg?quality=100" alt="Amy Before" class="img--scaled">
    </div>
    <div class="card-informational__title">
      Before
    </div>
  </div>
  <div class="card-informational__description">
    <h2 class="card-informational__description__title">My Remodeling Goal</h2>
    <p>
      Functionality was the name of the remodeling game with this dated
      fullbath. Besides needing a LONG overdue makeover itself, the washer and
      dryer were located in the basement of this home and my main priority was
      getting them upstairs into a bath/wash room. I also wanted a built-in
      ironing board for loads of convenience! (Everyone should have one!) I
      didn't want to compromise the spacious feel in this room, so we decided
      to go with a smaller sized vanity. It turned out even better than I had
      imagined!
    </p>
  </div>
</div>

Styleguide 3.15.13
*/


@include block('card-informational') {
  display: block;
  margin: 0 auto 1em;
  max-width: 445px;
  overflow: hidden;
  position: relative;

  @include media($bp-md) {
    align-items: center;
    // display: flex;
    // flex-basis: auto;
    margin: 0 1% 1em;
    min-width: 0;
  }

  @include media($bp-md) {
    align-items: center;
    // display: flex;
    flex-basis: auto;
    margin: 0 1% 1em;
  }

  @include child('head') {
    overflow: hidden;
    position: relative;
  }
  @include child('image') {
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &:after {
      background: linear-gradient(to bottom, rgba($c_white, 0) 0%, rgba(126, 126, 126, .1) 60%, rgba($c_black, 0.63) 100%);
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  @include child('title') {
    background: rgba($c_moen-bright-blue, 0.9);
    bottom: 0;
    color: $c_white;
    font-family: $din-medium;
    left: 0;
    padding: 0.8em 0.8em 0.6em;
    position: absolute;
    width: 100%;
    z-index:  1;
  }

  @include child('description') {
    padding: 1em;

    @include child('title') {
      font-family: $din-bold;
    }
  }
}

/*
Card Home

markup:
<a class="card card--home" href="#" style="background-image: url(https://embed.widencdn.net/img/moen/cfidkajzxu/640px/7185esrs_det_spray.jpeg?u=eqphjv);">
  <div class="card__front-content">
    <h3 class="card__title">Power Clean</h3>
    <p class="card__subtitle">More force, less splash</p>
  </div>
</a>

Styleguide 3.15.14
*/

@include block(card) {
  @include modifier(home) {
    position: relative;
    background: transparent 50% 0 no-repeat;
    background-size: cover;
    height: 276px !important;

    // .ie11 & {
    //   width: 230px;
    //
    //   @include media($bp-xs) {
    //     width: 230px;
    //   }
    // }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background: linear-gradient(to bottom, rgba($c_white, 0) 0%, rgba(126, 126, 126, .1) 60%, rgba($c_black, 0.63) 100%);
    }

    .card__front-content {
      color: $c_white;
      padding: 15px;
      text-align: left;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
    }

    .card__title {
      font-size: em(24);
      line-height: 1.25;
      margin: 0;
      padding: 0
    }

    .card__subtitle {
      padding: 0;
    }

  }
}

/*
Card Video Full Width

markup:
<div class="card-video card-video--fullwidth">
  <a href="#" class="card-video__link js-modal" data-modal-content-id="video-how-to-remove-a-garbage-disposal">
    <div class="card-video__image">
      <img src="//embed.widencdn.net/img/moen/h19losk76r/exact/video-poster-how-to-remove.jpeg?quality=100" />
    </div>
    <div class="card-video__title">
      How to Remove a Garbage Disposal
    </div>
  </a>
  <div class="is-hidden" id="video-how-to-remove-a-garbage-disposal">
    <video src="//embed.widencdn.net/stream/sd/moen/hy3fqx7z11/garbage_disposal_removal_retail.mp4" poster="//embed.widencdn.net/img/moen/khr8icuh8q/exact/modal_video-poster-how-it-works.png" controls="">
    </video>
  </div>
</div>

Styleguide 3.15.15
*/

@include block(card-video) {
  @include modifier(fullwidth) {
    width: 100%;
    max-width: 100%;

    .card-video__image {
      @include fluid-aspect(16 9);
    }
    .card-video__link {
      width: 100%;
    }
  }
}


/*
Card Animated

markup:
<div class="card-animated">
  <a href="#">
    <img src="./images/animated-cards/1.jpg" data-static="./images/animated-cards/1.jpg" data-animated="./images/animated-cards/1.gif">
    <div class="card-animated__captions">
      <p class="card-animated__caption--1">More force, less splash</p>
      <p class="card-animated__caption--2">Power Clean</p>
    </div>
  </a>
</div>

Styleguide 3.15.16
*/
@mixin card-animated-caption-base {
  font-family: $din-regular;
  color: $c_white;
  padding-bottom: 10px;
  line-height: 1em;
}

@include block(card-animated) {
  cursor: pointer;
  position: relative;
  margin: 10px 0px;
  flex: 1 1 0px; // IE FIX
  overflow: hidden;


  @include media($bp-md) {
    margin: 10px 7.5px;
  }

  &:before {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    z-index: 1;
    background: linear-gradient(to bottom, rgba(150,150,150,0) 0%, rgba(150,150,150,0.7) 100%);
    pointer-events: none;
  }


  &:hover,
  &:active,
  &:focus {


    .card-animated__image {
      transform: scale(1.1);
    }

  }


  @include child('image') {
    display: block;
    transition: all 1s ease;
  }


  a {
    display: block;
  }

  // img {
  //   max-width: 100%;
  //   width: 500px; /* to force gif width to be bigger than the box */
  // }

  @include child(captions) {
    width: calc(100% - 30px);
    position: absolute;
    bottom:15px;
    left: 15px;
    z-index: 2;
  }

  @include child(caption){
    @include modifier(1) {
      @include card-animated-caption-base();
      font-size: em(21);
    }
    @include modifier(2) {
      @include card-animated-caption-base();
      font-size: em(16);
    }
  }
}




/// .card-plain
@include block('card-plain') {


  /// .card-plain__image-container
  @include child('image-container') {
    margin-bottom: 0.5em;
  }


  /// .card-plain__image
  @include child('image') {
    display: block;
    margin: 0 auto;
    max-height: 250px;
    max-width: 250px;


    /// .card-plain__image.is-loaded
    @include state('loaded') {
      height: auto;
      width: 100%;
    }

  }


  /// .card-plain__title
  @include child('title') {
    font-family: $din-medium;
    font-size: 20px;
    line-height: 1.2;
    margin: 0 auto 0.5em;
    max-width: 250px;
    text-align: center;
  }

}



/// .card-links
@include block('card-links') {
  list-style: none;
  margin: 0;


  /// .card-links__item
  @include child('item') {
    font-family: $din-regular;
    font-size: 14px;
    margin: 0 0 0.5em 0;
    text-align: center;
  }


  /// .card-links__link
  @include child('link') {

  }

}
