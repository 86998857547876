// Compatible Devices List
// .comp-device-list
@include block("comp-device-list") {
	padding: 20px 0 0;
	text-align: left;
	font-size: 0;
	line-height: 0;
	overflow: visible;
	@include media($bp-md) {
		padding-top: 31px;
	}
	// .comp-device-list__inner
	@include element("inner") {
		// max-height: 0px;
		opacity: 0;
		pointer-events: none;
		overflow: hidden;
		transition: 0.5s ease;
		width: calc(100% + 5px); // For the margins on the actual devices
		background-color: $c_white;
		@include state("active") {
			opacity: 1;
			pointer-events: auto;
		}
		@include media($bp-md) {
			max-height: none !important;
			transition: opacity 0.5s ease;
			position: absolute;
			z-index: 2;
			bottom: 0;
			left:0;
			width: 100%;
			padding: 19px 175px 19px 40px;
			text-align: left;
			overflow: visible;
			&::before {
				content: ' ';
				display: block;
				width: calc(100% - 80px);
				height: 1px;
				margin: 0 auto;
				background-color: $c_moen-bright-gray;
				position: absolute;
				top:0;
				left:0;
				right:0;
			}
			&::after {
				content: ' ';
				display: block;
				position: absolute;
				top:0;
				bottom:0;
				left:50%;
				width: 100vw;
				height: 100%;
				background-color: $c_white;
				transform: translateX(-50%);
				z-index: -1;
			}
		}
		@include media($bp-xl) {
			padding: 25px 225px 50px 20px;
			text-align: left;
		}
	}
	// .comp-device-list__toggle
	@include element("toggle") {
		display: inline-block;
		font: normal 14px/1em $din-bold;
		color: $c_moen-bright-blue;
		position: relative;
		padding-right: 36px;
		margin-bottom: 15px;
		&::after {
			content: ' ';
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			width: 21px;
			height: 21px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			background-image: url('./images/icon-circle-expand-plus.svg');
		}
		@include state("active") {
			&::after {
				background-image: url('./images/icon-circle-expand-minus.svg');
			}
		}
	}
	// .comp-device-list__all
	@include element("all") {
		display: block;
		font: normal 12px/1.3333333333em $din-bold;
		color: $c_moen-bright-blue;
		margin-top: 25px;
		&::after {
			content: ' ';
			display: inline-block;
			width: 6px;
			height: 10px;
			background-image: url('./images/icon-arrow_right-blue.svg');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			margin-left: 10px;
		}
		@include media($bp-md) {
			max-width: 200px;
			text-align: right;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			margin-top: 0;
			font: normal 14px/16px $din-bold;
			padding-right: 16px;
			padding-left: 5px;
			
			position: absolute;
			top:50%;
			right:30px;
			text-align: center;
			transform: translateY(-50%);
			&::after {
				position: absolute;
				top:50%;
				right:0;
				transform: translateY(-50%);
			}
		}
		@include media($bp-xl) {
			right: 75px;
		}
	}
}

// Compatible Device (Singular)
// .comp-device
@include block("comp-device") {
	display: inline-block;
	vertical-align: middle;
	width: 90px;
	margin: 10px 5px 0 0;
	@include media($bp-md) {
		margin: 0 20px 0 0;
	}
	@include media($bp-xl) {
		margin: 0 40px 0 0;
		width: 150px;
	}
	// .comp-device__img
	@include element("img") {
		display: block;
		width: 100%;
		height: auto;
		margin: 0 0 10px;
		@include media($bp-xl){
			margin-bottom: 15px;
		}
	}
	// .comp-device__name
	@include element("name") {
		text-align: center;
		font: normal 12px/1.3333333333em $proxima-regular;
		color: $c_text-base;
		@include media($bp-xl){
			font-size: 16px;
		}
	}
}
