/**
@doc _docs/banner-support.md
@section Banner Support
@childof Banners
@page Banners Banner Support
@status in progress
@hippo banner
@example
_markup/banner-support.hbs
*/
@include plmr-component('banner-support') {
  //.plmr-c-banner-support__container
  @include element('container') {
    padding-top: 10px;

    @include plmr-mq(lg) {
      padding-top: 25px;
    }
  }

  //.plmr-c-banner-support__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 2rem;
    line-height: 2.571rem;
    margin: 0;
    text-align: center;

    @include plmr-mq('md') {
      font-size: 2.571rem;
      line-height: 3.428rem;
    }
  }
  //plmr-c-banner-support__product
  @include element('product') {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 25px 25px 40px;

    @include plmr-mq(md) {
      display: block;
      padding: 40px 55px 10px;

      //plmr-c-banner-support__product::before,
      //plmr-c-banner-support__product::after
      &::before,
      &::after {
        @include plmr-pseudo($display: table, $position: relative);
        clear: both;
      }
    }

    @include plmr-mq(lg) {
      padding-left: calc(91 / 1300 * 100%);
      padding-right: calc(91 / 1300 * 100%);
    }
  }

  //.plmr-c-banner-support__product-item
  @include element('product-item') {
    flex: 1 1 100%;
  }

  //.plmr-c-banner-support__product-image-area
  @include element('product-image-area') {
    max-width: calc(50% - 7.5px);
    overflow: hidden;
    padding-top: calc(155 / 325 * 100%);
    position: relative;

    @include plmr-mq(md) {
      float: left;
      max-width: none;
      padding-top: calc(208 / 658 * 100%);
      width: calc(208 / 658 * 100%);
    }

    @include plmr-mq(lg) {
      padding-top: calc(251 / 1118 * 100%);
      width: calc(251 / 1118 * 100%);
    }
  }

  //.plmr-c-banner-support__product-image
  @include element('product-image') {
    left: 50%;
    max-width: calc(100% - 30px);
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @include plmr-mq(md) {
      max-width: calc(100% - 40px);
    }

    @include plmr-mq(lg) {
      max-width: calc(100% - 50px);
    }
  }

  //.plmr-c-banner-support__product-spec
  @include element('product-spec') {
    max-width: calc(50% + 7.5px);
    padding-left: 7.5px;

    @include plmr-mq(md) {
      float: right;
      max-width: none;
      padding-left: 32px;
      padding-top: 20px;
      width: calc(100% - (208/658 * 100%));
    }

    @include plmr-mq(lg) {
      padding-left: 55px;
      width: calc(100% - (251/1118 * 100%));
    }
  }

  //.plmr-c-banner-support__product-spec-model
  @include element('product-spec-model') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'secondary');
    font-size: 1rem;
    line-height: 18px;
    padding: 0;

    @include plmr-mq(md) {
      font-size: 1.143rem;
      line-height: 21px;
    }
  }

  //.plmr-c-banner-support__product-spec-name
  @include element('product-spec-name') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.143rem;
    line-height: 21px;
    padding-bottom: 0;
    padding-top: 16px;
    text-transform: capitalize;

    @include plmr-mq(md) {
      padding-top: 20px;
    }

    //.plmr-c-banner-support__product-spec-name--collection
    @include modifier('collection') {
      @include plmr-font-face($family: 'bold');
    }
  }

  //.plmr-c-banner-support__product-link-group
  @include element('product-link-group') {
    @include plmr-font-face($family: 'bold');
    text-align: center;

    @include plmr-mq(md) {
      float: right;
      max-width: none;
      padding-left: 32px;
      text-align: left;
      width: calc(100% - (208/658 * 100%));
    }

    @include plmr-mq(lg) {
      padding-left: 55px;
      width: calc(100% - (251/1118 * 100%));
    }
  }

  //.plmr-c-banner-support__product-link
  @include element('product-link') {
    display: block;

    @include plmr-mq(md) {
      display: inline-block;
    }

    &:not(:first-child) {
      margin-top: 25px;

      @include plmr-mq(md) {
        margin-left: 50px;
      }
    }
  }

  //.plmr-c-banner-support__product-link-button
  @include element('product-link-button') {
    margin-top: 42px;

    @include plmr-mq(md) {
      margin-left: 0;
    }
  }
}
