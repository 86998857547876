
// Brushed Gold colors
// -----------------------------------------------------------------------------
//$colors-brushed-gold-primary: #d3a54a !default;
//$colors-brushed-gold-secondary: #17222c !default;

$colors-brushed-gold-primary: #d4a641 !default;
$colors-brushed-gold-secondary: #17222c !default;
//$colors-brushed-gold-tertiary: #1b365d !default;




// Brushed Gold Colors config
// -----------------------------------------------------------------------------
$brushed-gold-colors: (
  'primary': $colors-brushed-gold-primary,
  'secondary': $colors-brushed-gold-secondary,
  //'tertiary': $colors-brushed-gold-tertiary
);




// Brushed Gold Overrides to layout
// -----------------------------------------------------------------------------
@include plmr-object('brushed-gold') {

  // .plmr-o-brushed-gold .plmr-c-button--primary
  .plmr-c-button--primary {
    background-color: plmr-landing-colors($brushed-gold-colors, 'primary');

    @include plmr-button-hover(plmr-landing-colors($brushed-gold-colors, 'primary'));
  }


  // .plmr-o-brushed-gold .plmr-c-button-link--primary
  .plmr-c-button-link--primary {
    color: plmr-landing-colors($brushed-gold-colors, 'primary');

    @include plmr-button-link-hover(plmr-landing-colors($brushed-gold-colors, 'primary'));
  }


  // .plmr-o-brushed-gold .plmr-c-banner-cta.plmr-u-bg--background-secondary, .plmr-o-brushed-gold .plmr-c-banner-hero-knockout.plmr-u-bg--background-secondary
  // .plmr-o-brushed-gold .plmr-c-collection-slider.plmr-u-bg--background-secondary
  .plmr-c-banner-cta.plmr-u-bg--background-secondary,
  .plmr-c-banner-hero-knockout.plmr-u-bg--background-secondary,
  .plmr-c-collection-slider.plmr-u-bg--background-secondary {
    background-color: plmr-landing-colors($brushed-gold-colors, 'secondary');
    color: plmr-color('base', 'white');
  }


  // .plmr-o-brushed-gold .plmr-c-banner-hero-knockout__eyebrow, .plmr-o-brushed-gold .plmr-c-banner-intro__eyebrow
  // .plmr-o-brushed-gold .plmr-c-collection-slider-nav__eyebrow, .plmr-o-brushed-gold .plmr-c-button-link--teriary
  .plmr-c-banner-hero-knockout__eyebrow,
  .plmr-c-banner-intro__eyebrow,
  .plmr-c-collection-slider-nav__eyebrow,
  .plmr-c-button-link--tertiary {
    //color: plmr-landing-colors($brushed-gold-colors, 'tertiary');
    color: plmr-color('brand', 'secondary');
  }


  // .plmr-o-brushed-gold .plmr-c-banner-hero-knockout__title, .plmr-o-brushed-gold .plmr-c-banner-intro__title,
  // .plmr-o-brushed-gold .plmr-c-banner-cta__copy, .plmr-o-brushed-gold .plmr-c-collection-slider-nav__title
  .plmr-c-banner-hero-knockout__title,
  .plmr-c-banner-intro__title,
  .plmr-c-banner-cta__copy,
  .plmr-c-collection-slider-nav__title {
    color: plmr-landing-colors($brushed-gold-colors, 'primary');
  }


  //.plmr-o-brushed-gold .plmr-c-banner-intro__copy
  .plmr-c-banner-intro__copy {
    color: plmr-color('text', 'secondary');
  }


  //.plmr-o-brushed-gold .plmr-c-slider__dots
  .plmr-c-slider__dots {
    li {
      button {
        border: 2px solid plmr-color('brand', 'secondary');


        &::before {
          background: plmr-color('brand', 'secondary');
        }
      }


      &.slick-active {
        button {
          background: plmr-color('brand', 'secondary');
        }
      }
    }
  }

  //.plmr-o-brushed-gold .plmr-c-banner-quad-collage
  .plmr-c-banner-quad-collage {
    &::before {
      background: $colors-brushed-gold-secondary;
    }
  }
}
