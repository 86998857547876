/*
Product Gallery Rating Stars

Markup:
<div class="stars stars--gray">
  <div class="ratings-meter">
    <div class="cutouts"><span style="width:95.676%;"></span></div>
  </div>
  <div class="rating">4.8</div>
</div>

Styleguide 3.8
*/

@include block(stars--gray) {
  .ratings-meter {
    .cutouts {
      span {
        background-color: $c_dove-gray;
      }
    }
  }
}

/*
Bazaarvoice’s Rating Stars Markup

Markup:
<div style="padding: 10px;">
  <div class="stars">
    <div class="ratings-meter">
      <div class="cutouts"><span style="width:95.676%;"></span></div>
    </div>
    <div class="rating">4.8</div>
  </div>
</div>

Styleguide 3.8.1
*/

@include block(ratings-meter) {
  background-color: $c_pdp_collection;
  overflow: hidden;
  position: relative;
  opacity: .99; // establish new stacking context for .ratings-meter-mask and .ratings-meter-bar (see below)
  width: 100px;
  height: 13px;
  .cutouts {
    overflow: hidden;
    font-family: initial;
    line-height: 1;
    height: 100%;
    font-size: 0;
    position: relative;

    &:before {
      content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20130%2022%22%3E%3Cstyle%20type%3D%22text/css%22%3E%20%20%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C/style%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M0%200v22h130V0H0zM18.2%2020L12%2016l-6.2%204%202.1-6.8L2%208.8h7.5L12%202l2.5%206.8H22l-5.8%204.4L18.2%2020zM44.8%2020l-6.2-4%20-6.2%204%202.1-6.8%20-5.8-4.4H36L38.5%202%2041%208.8h7.5l-5.8%204.4L44.8%2020zM71.2%2020L65%2016l-6.2%204%202.1-6.8L55%208.8h7.5L65%202l2.5%206.8H75l-5.8%204.4L71.2%2020zM97.8%2020l-6.2-4%20-6.2%204%202.1-6.8%20-5.8-4.4H89L91.5%202%2094%208.8h7.5l-5.8%204.4L97.8%2020zM124.2%2020l-6.2-4%20-6.2%204%202.1-6.8L108%208.8h7.5L118%202l2.5%206.8h7.5l-5.8%204.4L124.2%2020z%22/%3E%3C/svg%3E");
      display: block;
      position: relative;
      top: -2px;
      left: -3px;
      right: -3px;
      width: 106px;
      height: 100%;
      z-index: 2;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $c_moen-bright-blue;
      z-index: 1;
      transition: width 1s ease-in-out;
    }
  }
}

//@include block(rating) {
//  @include block(stars) {
//    display: block;
//    height: 14px;
//    margin: 0;
//    padding: 0;
//  }
//  @include block(cutouts) {
//    background-image: url(images/icon-star_bright_gray.svg);
//    background-position: 0 0;
//    background-repeat: no-repeat;
//    display: inline-block;
//    height: 14px;
//    vertical-align: top;
//    width: 14px;
//    @include state(active) {
//      background-image: url(images/icon-star_gray.svg);
//    }
//  }
//}
