

/// .header-top
@include block('header-top') {
  background: $c_moen-bright-blue;
  color: $c_white;
  
  
  /// .header-top a
  a {
    color: inherit;
  }
  
  
  /// .header-top__translate
  @include child('translate') {
    align-self: flex-start;
    margin: 7px 0 0 20px; 
    
    
    /// screen > 768px
    @include media($bp-md) {
      margin-left: 30px;
    }
    
    
    /// screen > 992px
    @include media($bp-lg) {
      margin-left: 40px;
    }
    
  }
  
  
  /// .header-top__language-selector
  @include child('language-selector') {
    display: none;
    font-family: $proxima-regular;
    margin-left: 20px;
    
    
    /// screen > 768px
    @include media($bp-md) {
      display: block;
      margin-left: 30px;
      width: 200px;
    }
    
    
    /// screen > 992px
    @include media($bp-lg) {
      margin-left: 40px;
    }
    
    
    /// .header-top__language-selector optgroup, .header-top__language-selector option
    optgroup,
    option {
      color: $c_text-base;
    }
    
  }
  
}
