/*
Favorites List

markup:
<div class="container">
<!-- start favorites list table -->
<table class="table table--responsive table--no-headings favorites-list">
  <caption>Favorites List</caption>
  <thead>
    <tr>
      <th scope="column" class="favorites-list__remove"></th>
      <th scope="column" class="favorites-list__product-image"></th>
      <th scope="column" class="favorites-list__part-number">Part No.</th>
      <th scope="column" class="favorites-list__part-description">Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="favorites-list__remove"><a href="#" class="link link--icon--left"><img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Remove</a></td>
      <td class="favorites-list__product-image"><img class="favorites-list__product-image--image" src="./images/styleguide/s43001bn.jpg" alt="product image" /></td>
      <td class="favorites-list__part-number">S43001BN</td>
      <td class="favorites-list__part-description"><a href="#">Brushed nickel one-handled bathroom faucet</a></td>
    </tr>
    <tr>
      <td class="favorites-list__remove"><a href="#" class="link link--icon--left"><img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Remove</a></td>
      <td class="favorites-list__product-image"><img class="favorites-list__product-image--image" src="./images/styleguide/ts22003bn.jpg" alt="product image" /></td>
      <td class="favorites-list__part-number">TS22003BN</td>
      <td class="favorites-list__part-description"><a href="#">Brushed nickel Posi-Temp® tub/shower</a></td>
    </tr>
    <tr class="favorites-list__valve-label">
      <td class="favorites-list__remove"></td>
      <td class="favorites-list__valve-label">Valve information:</td>
      <td class="favorites-list__part-number">2520</td>
      <td class="favorites-list__part-description"><a href="#">Brushed nickel Posi-Temp® tub/shower</a></td>
    </tr>
  </tbody>
</table>
<!-- end favorites list table -->
</div>

Styleguide 4.29
*/

@include block(favorites-list) {
  table-layout: fixed;
  caption {
    @include hat-core();
  }

  thead tr {
    border-bottom: 1px solid $c_moen-gray;
  }

  tbody tr {
    border-bottom: 1px dotted $c_moen-gray;
  }

  @include element(part-number) {
    font-family: $din-medium;
  }

  @include element(product-image) {
    position: relative;
    @include modifier(image) {
      max-height: 100px;
      width: auto;
    }
  }

  @include element(valve-label) {
    // no styling directives here, but putting this in for future consideration.
  }

  @include element(part-description) {
    // no styling directives here, but putting this in for future consideration.
  }

  @include element (remove) {
    .link--icon--left {
      padding-left: 0;
    }
  }
}
