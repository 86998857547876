/*
Loading Indicator

CSS Animation with fallback to animated GIF for browsers that don't support CSS Animations.

Markup:
<div class="container container--main loading-indicator--is-absolute" style="height:300px;">
  <div class="loading-indicator">
    <div class="loading-indicator__spinner">
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--1"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--2"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--3"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--4"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--5"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--6"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--7"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--8"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--9"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--10"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--11"></div>
      <div class="loading-indicator__spinner__step loading-indicator__spinner__step--12"></div>
    </div>
  </div>
</div>

Styleguide 2.11
*/

@include block('loading-indicator') {
  background: rgba($c_white, 0.75);
  display: none;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  // z-index: z("modal");
  z-index: plmr-z($layer: 'modal');

  @include modifier('is-fixed') {

    .loading-indicator {
      display: block;
      position: fixed;
    }
  }
  
  @include modifier('is-absolute') {
    
    .loading-indicator {
      display: block;
      position: absolute;
    }
  }

  @include child('spinner') {
    background: none;
    height: 200px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.75);
    width: 200px;

    .no-cssanimations & {
      background: url('images/loading.gif') no-repeat 50% 50%;
    }
    
    @include child('step') {
      background: $c_moen-bright-blue;
      border-radius:10px;
      height: 40px;
      left: 93px;
      position: absolute;
      top: 80px;
      width:14px;
      animation: spinner-animation 1s linear infinite;
      
      .no-cssanimations & {
        display: none;
      }

      @include modifier('1') {
        animation-delay: -0.5s;
        transform:rotate(0deg) translate(0,-60px);
      }

      @include modifier('2') {
        transform:rotate(30deg) translate(0,-60px);
        animation-delay: -0.4166666666666667s;
      }

      @include modifier('3') {
        transform:rotate(60deg) translate(0,-60px);
        animation-delay: -0.33333333333333337s;
      }

      @include modifier('4') {
        transform:rotate(90deg) translate(0,-60px);
        animation-delay: -0.25s;
      }

      @include modifier('5') {
        transform:rotate(120deg) translate(0,-60px);
        animation-delay: -0.16666666666666669s;
      }

      @include modifier('6') {
        transform:rotate(150deg) translate(0,-60px);
        animation-delay: -0.08333333333333331s;
      }

      @include modifier('7') {
        transform:rotate(180deg) translate(0,-60px);
        animation-delay: 0s;
      }

      @include modifier('8') {
        transform:rotate(210deg) translate(0,-60px);
        animation-delay: 0.08333333333333337s;
      }

      @include modifier('9') {
        transform:rotate(240deg) translate(0,-60px);
        animation-delay: 0.16666666666666663s;
      }

      @include modifier('10') {
        transform:rotate(270deg) translate(0,-60px);
        animation-delay: 0.25s;
      }

      @include modifier('11') {
        transform:rotate(300deg) translate(0,-60px);
        animation-delay: 0.33333333333333337s;
      }

      @include modifier('12') {
        transform:rotate(330deg) translate(0,-60px);
        animation-delay: 0.41666666666666663s;
      }

    }

  }

}


@keyframes spinner-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}



// -----------------------------------------
// Legacy Version -- TO BE REMOVED
// -----------------------------------------
@include block(loading-display) {
  background: rgba($c_white, 0.7);
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;


  @include element(image) {
    display: block;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}


@include block(show-loading-display) {

  .loading-display {
    display: block;
  }

  @include modifier(is-on-top) {
    .loading-display__image {
      top: 10%;
    }
  }

  @include modifier(is-on-bottom) {
    .loading-display__image {
      bottom: 2%;
    }
  }

  @include modifier(is-centered) {
    .loading-display__image {
      top: 45%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

}
