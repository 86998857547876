
// Moen Config setting variables
// -----------------------------------------------------------------------------
$moen-container-max-width: 1300px !default;

$moen-font-family: $font-family-din-regular !default;
$moen-font-size-root: 14px !default;
$moen-font-size: 1rem !default;



// Moen Config settings
// -----------------------------------------------------------------------------
$brand-config: (
  'container': (
    'max-width': $moen-container-max-width
  ),

  'font': (
    'font-family': $moen-font-family,
    'font-size': $moen-font-size,
    'font-size-root': $moen-font-size-root
  )
);
