
/**
@doc _docs/carousel-icon-tabbing.md
@section Carousel Icon Tabbing
@childof Carousels
@page Carousels Carousel Icon Tabbing
@status in progress
@hippo banner carousel
@example
_markup/carousel-icon-tabbing.hbs
*/
// .plmr-c-carousel-icon-tabbing
@include plmr-component('carousel-icon-tabbing') {
  @extend .plmr-l-container;
  padding: 40px 25px;

  @include plmr-mq(md) {
    padding: 50px 25px;
  }

  @include plmr-mq(lg) {
    padding: 50px 142px;
  }


  // .plmr-c-carousel-icon-tabbing__nav-item
  @include element('nav-item') {
    color: plmr-color('text', 'secondary');
    padding-bottom: 16px;
    position: relative;
    text-align: center;

    &:not(:first-child) {
      display: none;
    }

    .slick-initialized & {
      display: block;
    }

    @include plmr-mq(md) {
      padding-bottom: 0;
    }

    @include plmr-mq(lg) {
      // .plmr-c-carousel-icon-tabbing__nav-item:hover
      &:hover {
        // .plmr-c-carousel-icon-tabbing__nav-item-label::after
        .plmr-c-carousel-icon-tabbing__nav-item-label::after {
          display: inline-block;
        }
      }
    }

    // .plmr-c-carousel-icon-tabbing__nav-item._active
    @include plmr-state('active') {
      color: plmr-color('brand', 'primary');

      // .plmr-c-carousel-icon-tabbing__nav-item-label::after
      .plmr-c-carousel-icon-tabbing__nav-item-label::after {
        background: plmr-color('brand', 'primary');
        display: inline-block;
      }
    }
  }

  // .plmr-c-carousel-icon-tabbing__nav-item-button
  @include element('nav-item-button') {
    outline-offset: -1px;

    // .plmr-c-carousel-icon-tabbing__nav-item-button:hover
    &:hover {
      cursor: pointer;
    }
  }

  // .plmr-c-carousel-icon-tabbing__nav-item-icon
  @include element('nav-item-icon') {
    display: block;
    height: 51px;
    margin: 0 auto 24px;
    width: auto;

    @include plmr-mq(md) {
      height: 84px;
      margin-bottom: 15px;
    }
  }


  // .plmr-c-carousel-icon-tabbing__nav-item-label
  @include element('nav-item-label') {
    @include plmr-font-face($family: 'bold');
    display: inline-block;
    line-height: 1.286rem;
    padding: 0;

    // .plmr-c-carousel-icon-tabbing__nav-item-label::after
    &::after {
      @include plmr-pseudo;
      background: plmr-color('text', 'secondary');
      display: none;
      height: 3px;
      margin-top: 31px;
      position: static;
      width: 100%;
    }

    @include plmr-mq(md) {
      font-size: 1.143rem;
      line-height: 1.714rem;

      // .plmr-c-carousel-icon-tabbing__nav-item-label::after
      &::after {
        margin-top: 0;
      }
    }
  }


  // .plmr-c-carousel-icon-tabbing__slide
  @include element('slide') {
    margin-top: 30px;

    // .plmr-c-carousel-icon-tabbing__slide::after
    &::after {
      @include plmr-pseudo;
      clear: left;
    }

    @include plmr-mq(md) {
      margin-top: 50px;
    }

    &:not(:first-child) {
      display: none;
    }

    .slick-initialized & {
      display: block;
    }
  }


  // .plmr-c-carousel-icon-tabbing__content-area
  @include element('content-area') {
    @include plmr-mq(md) {
      float: right;
      width: 45%;
    }
  }


  // .plmr-c-carousel-icon-tabbing__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.286rem;
    margin: 0 0 15px 0;

    @include plmr-mq(md) {
      font-size: 2rem;
    }
  }


  // .plmr-c-carousel-icon-tabbing__content
  @include element('content') {
    font-size: 1.143rem;
    line-height: 2rem;
    padding: 0;
  }


  // .plmr-c-carousel-icon-tabbing__general-cta
  @include element('general-cta') {
    margin-top: 30px;
  }


  // .plmr-c-carousel-icon-tabbing__product-cta
  @include element('product-cta') {
    margin-top: 40px;
  }


  // .plmr-c-carousel-icon-tabbing__image-area
  @include element('image-area') {
    margin-top: 40px;

    @include plmr-mq(md) {
      float: left;
      margin-right: 32px;
      margin-top: 0;
      width: calc(55% - 32px);
    }

    @include plmr-mq(lg) {
      margin-right: 55px;
      width: calc(55% - 55px);
    }
  }


  // .plmr-c-carousel-icon-tabbing__image
  @include element('image') {
    height: auto;
    width: 100%;
  }
}
