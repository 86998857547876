
/**
@doc _docs/banner-hero-knockout.md
@section Banner Hero Knockout
@childof Banners
@page Banners Banner Hero Knockout
@status ready
@hippo banner
@example
_markup/banner-hero-knockout.hbs
*/
@include plmr-component('banner-hero-knockout') {
  display: block;
  height: auto;
  position: relative;
  width: 100%;


  &::before {
    background: plmr-color('base', 'white');
    bottom: 0;
    content: '';
    display: block;
    height: 105px;
    left: 0;
    min-width: 100%;
    pointer-events: none;
    position: absolute;
    width: auto;
    z-index: 1;


    @include plmr-mq(md) {
      height: 194px;
    }


    @include plmr-mq(xl) {
      height: 100px;
    }
  }


  // .plmr-c-banner-hero-knockout__callout
  @include element('callout') {
    background: plmr-color('base', 'white');
    display: block;
    height: auto;
    max-width: 567px;
    min-width: 224px;
    position: relative;
    text-align: left;
    transition: transform 0s linear;
    width: 63.75%;
    will-change: transform;
    z-index: 2;


    @include plmr-mq(md) {
      width: 50%;
    }
  }


  // .plmr-c-banner-hero-knockout__container
  @include element('container') {
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    min-height: 527px;
    min-width: 320px;
    padding-top: 60px;
    position: relative;
    width: 100%;


    @include plmr-mq(md) {
      overflow: hidden;
      padding: 110px 25px 484px;
    }


    @include plmr-mq(xl) {
      padding-left: 123px;
      padding-top: 100px;
    }
  }


  // .plmr-c-banner-hero-knockout__cta
  @include element('cta') {
    padding: 20px 0 0;


    @include plmr-mq(md) {
      padding: 30px 0 0;
    }
  }


  // .plmr-c-banner-hero-knockout__eyebrow
  @include element('eyebrow') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'secondary');
    display: block;
    font-size: plmr-font-size('sm');
    line-height: 21px;
    margin: 0;


    @include plmr-mq(md) {
      font-size: plmr-font-size('md');
    }
  }


  // .plmr-c-banner-hero-knockout__image
  @include element('image') {
    display: block;
    height: auto;
    left: 50%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }


  // .plmr-c-banner-hero-knockout__image-area
  @include element('image-area') {
    height: auto;
    min-height: 90.5%;
    min-width: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: 1;


    @include plmr-mq(md) {
      min-height: 93.5%;
    }

    @include plmr-mq(xl) {
      min-height: 420px;
    }
  }


  // .plmr-c-banner-hero-knockout__intro
  @include element('intro') {
    padding: 25px 25px 45px;
    text-align: left;


    @include plmr-mq(md) {
      padding: 40px 40px 80px;
    }


    @include plmr-mq(xl) {
      padding: 60px 60px 135px;
    }
  }


  // .plmr-c-banner-hero-knockout__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    display: block;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
    padding: 10px 0 0;


    @include plmr-mq(md) {
      font-size: 32px;
      line-height: 36px;
      padding: 15px 0 0;
    }
  }


  // .plmr-c-banner-hero-knockout__figure
  @include element('figure') {
    height: auto;
    margin: 94px 0 0;
    max-width: 610px;
    min-height: 243px;
    min-width: 157px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 0s linear;
    width: 42%;
    will-change: transform;
    z-index: 1;


    @include plmr-mq(md) {
      margin: 161px 0 0;
      min-height: 415px;
      padding-top: 0;
      width: 54%;
    }


    @include plmr-mq(md) {
      margin: 151px 0 0;
      min-height: 448px;
    }
  }

  // .plmr-c-banner-hero-knockout__figcaption
  @include element('figcaption') {
    @include plmr-font-face($family: 'bold');
    bottom: 0;
    color: inherit;
    display: block;
    font-size: 10px;
    left: 0;
    letter-spacing: 1.82px;
    line-height: 8px;
    position: absolute;
    text-transform: uppercase;

    @include plmr-mq(md) {
      font-size: 11px;
    }
  }

  // .plmr-c-banner-hero-knockout__cutouts
  @include element('cutouts') {
    bottom: 0;
    height: 105px;
    left: 0;
    min-width: 100%;
    position: absolute;
    width: auto;
    z-index: 3;


    @include plmr-mq(md) {
      height: 194px;
    }


    @include plmr-mq(xl) {
      height: 100px;
    }


    &::before {
      background: plmr-color('brand', 'tertiary');
      content: '';
      height: 50px;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translate(-50%, -25px);
      width: 1px;


      @include plmr-mq(md) {
        height: 75px;
        transform: translate(-50%, -37px);
      }
    }
  }


  // .plmr-c-banner-hero-knockout__cutout
  @include element('cutout') {
    //bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    top: 0;


    // .plmr-c-banner-hero-feature__cutout--large
    @include modifier('large') {
      height: 239px;
      margin-left: 10px;
      margin-top: -154px;
      width: 203px;


      @include plmr-mq(md) {
        height: 477px;
        margin-left: -41px;
        margin-top: -333px;
        width: 405px;
      }


      @include plmr-mq(xl) {
        height: 530px;
        margin-left: 12%;
        margin-top: -454px;
        width: 450px;
      }
    }


    // .plmr-c-banner-hero-feature__cutout--small
    @include modifier('small') {
      height: 124px;
      left: auto;
      margin-right: 10px;
      margin-top: -39px;
      right: 0;
      width: 124px;


      @include plmr-mq(md) {
        height: 248px;
        margin-right: 50px;
        margin-top: -104px;
        width: 248px;
      }


      @include plmr-mq(xl) {
        height: 300px;
        margin-right: 145px;
        margin-top: -199px;
        width: 300px;
      }
    }
  }


  // .plmr-c-banner-hero-knockout__cutout-image
  @include element('cutout-image') {
    display: block;
    height: auto;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
}
