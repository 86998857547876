

// General Mobile Menu styling
// -----------------------------------------------------------------------------

// .plmr-c-mobile-menu
@include plmr-component('mobile-menu') {
  // background-color: plmr-color('background', 'tertiary');
  // box-shadow: 0 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
  // color: plmr-color('text', 'secondary');
  // // display: flex;
  // // display: none;
  // height: 100%;
  // max-width: 377px;
  // overflow-x: hidden;
  // overflow-y: scroll;
  // position: fixed;
  // right: 0;
  // top: $plmr-mobile-menu-offset;
  // width: 100%;
  // will-change: transform;
  /* ---  PREVIOUS --- */

  // background: rgba(plmr-color('base', 'black'), 0.5);
  bottom: 0;
  display: block;
  left: 0;
  // max-width: 377px;
  position: fixed;
  // right: 0;
  top: calc(34px + 70px);
  // transition: transform 250ms ease-in-out;
  // will-change: transform;
  visibility: hidden;
  width: 100%;
  z-index: plmr-z($layer: 'mobile-menu');


  @include plmr-state('active') {
    // display: block;
    // transform: translateX(0);
    visibility: visible;
  }


  // screen width > 768px
  @include plmr-mq('md') {
    top: calc(34px + 77px);
  }



  @include plmr-state('offcanvas') {
    transform: translateX(100%);
  }

  @include plmr-state('transition') {

  }


  // -----------------------------
  // work in progress
  @include element('overlay') {
    background: rgba(plmr-color('base', 'black'), 0.5);
    // bottom: 0;
    height: 100%;
    // left: 0;
    opacity: 0;
    position: absolute;
    // right: 0;
    // top: 0;
    // transform: translateX(100%);
    transition: opacity 250ms ease-in-out;
    width: 100%;
    will-change: opacity;


    @include plmr-state('opened') {
      opacity: 1;
      // transform: translateX(0);
    }
  }
  // -----------------------------



  @include element('container') {
    background-color: plmr-color('background', 'tertiary');
    box-shadow: 0 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    height: 100%;
    max-width: 377px;
    // overflow-y: scroll;
    padding-bottom: 50px;
    position: fixed;
    right: 0;
    width: 100%;
    will-change: transform;


  }


  @include element('nav') {
    // background-color: plmr-color('background', 'tertiary');
    // box-shadow: 0 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    // color: plmr-color('text', 'secondary');
    height: 100%;
    // max-width: 377px;
    overflow-x: hidden;
    overflow-y: scroll;
    // position: absolute;
    position: relative;
    // right: 0;
    // top: 0;
    // transform: translateX(100%);
    // transition: transform 250ms ease-in-out;
    // width: 100%;
    // will-change: transform;


    &:focus {
      outline: 2px dashed plmr-color('brand', 'tertiary');
      outline-offset: 10px;
    }


    @include plmr-state('active') {
      // display: block;
      // transform: translateX(0);
    }

  }

}




// Mobile Nav Button shared styles
// -----------------------------------------------------------------------------

@mixin plmr-mobile-nav-button() {
  cursor: pointer;
  display: block;
  padding: 30px 25px;
  position: relative;
  text-align: left;
  width: 100%;


  &:focus {
    outline-offset: -1px;
  }
}




// Mobile Navigation
// -----------------------------------------------------------------------------

// .plmr-c-nav-mobile
@include plmr-component('nav-mobile') {
  bottom: 0;
  color: plmr-color('text', 'secondary');
  // display: none;
  left: 0;
  overflow: scroll;
  padding-bottom: 50px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 250ms ease-in-out;
  visibility: hidden;
  will-change: transform;


  @include modifier('main') {
    transform: translateX(-100%);
  }



  // .plmr-c-nav-mobile._active
  @include plmr-state('active') {
    transform: translateX(0);
    visibility: visible;
  }


  // .plmr-c-nav-mobile__item
  @include element('item') {
    margin-bottom: 15px;


    // .plmr-c-nav-mobile__item:last-child
    &:last-child {
      margin-bottom: 0;
    }


    // .plmr-c-nav-mobile__item--content-padding
    @include modifier('content-padding') {
      padding: 0 25px;
    }

  }


  // .plmr-c-nav-mobile__category-item
  @include element('category-item') {


    // .plmr-c-nav-mobile__category-item._hidden-on-md
    @include plmr-state('hidden-on-md') {

      // screen width > 768px
      @include plmr-mq('md') {
        display: none;
      }

    }

  }


  // .plmr-c-nav-mobile__link-cta-item
  @include element('link-cta-item') {
    padding: 30px 0;
  }


  // .plmr-c-nav-mobile__divider-item
  @include element('divider-item') {
    border-bottom: 1px solid plmr-color('accent', 'light');
    padding: 30px 0;


    // .plmr-c-nav-mobile__divider-item--content-padding
    @include modifier('content-padding') {
      padding-left: 25px;
      padding-right: 25px;
    }

    // .plmr-c-nav-mobile__divider-item:last-child
    &:last-child {
      border-bottom: 0;
    }

  }


  // .plmr-c-nav-mobile__category
  @include element('category') {
    @include plmr-font-face($family: 'bold');
    @include plmr-mobile-nav-button;
    @include plmr-link($color: plmr-color('text', 'secondary'));
    background-color: plmr-color('background', 'primary');
    border-bottom: 1px solid plmr-color('accent', 'light');
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;


    // .plmr-c-nav-mobile__category--secondary
    @include modifier('secondary') {
      @include plmr-link($color: plmr-color('brand', 'primary'));
    }


    // .plmr-c-nav-mobile__category._active
    @include plmr-state('active') {
      text-align: center;
    }

  }


  // .plmr-c-nav-mobile__label
  @include element('label') {
    display: block;
    width: calc(100% - 25px);
  }


  // .plmr-c-nav-mobile__arrow
  @include element('arrow') {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);


    // .plmr-c-nav-mobile__arrow._flipped
    @include plmr-state('flipped') {
      left: 25px;
      right: auto;
      transform: translateY(-50%) rotate(180deg);
    }

  }


  // .plmr-c-nav-mobile__section
  @include element('section') {
    @include plmr-font-face($family: 'regular');
    @include plmr-mobile-nav-button;
    @include plmr-link($color: plmr-color('brand', 'primary'));
    border-bottom: 1px solid plmr-color('accent', 'light');
    cursor: default;
    font-size: 21px;
    line-height: 24px;
  }



  // .plmr-c-nav-mobile__main-menu
  @include element('main-menu') {
    @include plmr-font-face($family: 'bold');
    @include plmr-mobile-nav-button;
    @include plmr-link($color: plmr-color('text', 'secondary'));
    background-color: plmr-color('accent', 'light');
    font-size: 11px;
    letter-spacing: 0.85px;
    line-height: 14px;
    padding: 15px 30px 15px 40px;
    text-transform: uppercase;
  }


  // .plmr-c-nav-mobile__sub-category
  @include element('sub-category') {
    @include plmr-font-face($family: 'bold');
    @include plmr-mobile-nav-button;
    @include plmr-link($color: plmr-color('brand', 'primary'));
    border-bottom: 1px solid plmr-color('accent', 'light');
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 16px;


    // .plmr-c-nav-mobile__sub-category._active
    @include plmr-state('active') {
      border-bottom: 0;
      cursor: default;
    }

  }


  // .plmr-c-nav-mobile__secondary
  @include element('secondary') {
    padding: 30px 0;

    @include plmr-state('hidden-on-md') {

      // screen > 768px
      @include plmr-mq('md') {
        display: none;
      }
    }
  }


  // .plmr-c-nav-mobile__secondary-list
  @include element('secondary-list') {
    // padding: 30px 0;
  }


  // .plmr-c-nav-mobile__secondary-item
  @include element('secondary-item') {
    margin-bottom: 27px;
  }


  // .plmr-c-nav-mobile__secondary-link
  @include element('secondary-link') {
    @include plmr-font-face($family: 'bold');
    @include plmr-link($color: plmr-color('text', 'secondary'));
    display: block;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 14px;
    padding: 0 25px;
    text-transform: uppercase;
  }


  // .plmr-c-nav-mobile__link-cta
  @include element('link-cta') {
    @include plmr-font-face($family: 'bold');
    @include plmr-link($color: plmr-color('brand', 'tertiary'), $underline: true);
    display: block;
    font-size: 14px;
    line-height: 18px;
    padding: 0 25px;


    // .plmr-c-nav-mobile__link-cta--sub-category
    @include modifier('sub-category') {
      padding-left: 35px;
    }

  }


  // .plmr-c-nav-mobile__link
  @include element('link') {
    @include plmr-link($color: plmr-color('text', 'primary'));
    display: block;
    font-size: 16px;
    line-height: 28px;
    padding: 0 25px 0 35px;
  }


  // .plmr-c-nav-mobile__select-language
  @include element('select-language') {
    margin: 0 25px 50px;

    // .plmr-c-nav-mobile__select-language._hidden-on-md
    @include plmr-state('hidden-on-md') {

      // screen width > 768px
      @include plmr-mq('md') {
        display: none;
      }

    }

  }

}






