
/**
Primary button styling
@section Primary Button
@childof Buttons
@page Buttons Primary Button
@status ready
@example
_markup/button-primary.hbs
*/
@include plmr-component('button') {


  // .plmr-c-button--primary
  @include modifier('primary') {
    background: plmr-color('brand', 'primary');

    @include plmr-button-hover(plmr-color('brand', 'primary'));
  }

}


/**
Primary button styling
@section With Icon
@sectionof Primary Button
@page Buttons Primary Button
@example
_markup/button-with-icon.hbs
*/
