
/// .scroll-arrow
@include block('scroll-arrow') {
  bottom: 48px;
  content: '';
  cursor: pointer;
  display: block;
  height: 50px;
  left: 50%;
  opacity: 1;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 1s ease;
  width: 45px;
  z-index: z("scroll-arrow");

    
  /// .scroll-arrow::after pseudeo-element
  &::after {
    animation: bounce 2s infinite;
    background: url('images/icon-arrow_down_white.svg') no-repeat 50% 50%;        
    bottom: 0;
    content: '';
    display: block;
    height: 20px;
    position: absolute;
    width: 45px;
  }
    
    
  /// .scroll-arrow.is-scrolling
  @include state('scrolling') {
    cursor: default;
    opacity: 0;
    
      
    /// .scroll-arrow.is-scrolling::after pseudeo-element  
    &::after {
      animation-play-state: paused;  
    }
      
  }
  
}
