/*
Search Results Page Header

The header is made up of the page heading and the search input box.

markup:
<div class="search-results-page-header">
  <h1 class="search-results-page-header__heading">68 Search Results for</h1>
  <div class="input-add-on search-results-page-header__input-add-on">
    <label for="search_terms" class="hat">Search</label>
    <input itemprop="query-input"
      type="search" name="search_terms"
      id="search_terms"
      placeholder="Placeholder Value"
      class="form-input form-input__search input-add-on__field"
      value="ab1953">
    <button type="submit" class="button button--icon button--search input-add-on__item">
      <span>Submit Search</span>
    </button>
  </div>
</div>

Styleguide 4.30.1
*/

.search-results-page-header {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 1em 0;

  @include media($bp-sm) {
    flex-direction: row;
    justify-content: center;
  }

  .button--icon {
    width: 52px;
    @include media($bp-sm) {
      width: 37px;
    }
  }
}

.search-results-page-header__input-add-on {
  width: 100%;
  font-size: 1.2em;
  @include media($bp-sm) {
    width: auto;
    flex: 0 0 40%;
  }
  .form-input {
    padding: .7em;
    @include media($bp-sm) {
      padding: .3em;
    }
  }
}

.search-results-page-header__heading {
  flex-grow: 1;
  font-size: em(30);
  line-height: 1;
  color: $c_moen-gray;
  padding: 0;
  //margin: 0 0 .2em 0;
  margin: 0 0 .4em 0;
  text-align: center;

  @include media($bp-sm) {
    margin: 0 .2em 0 0;
    flex-grow: 0;
  }
}



.search-results-page-header__heading-cn{
  @include media($bp-sm) {
    margin: 0 1em 0 0;
    flex-grow: 0;
  }
}


ul.search-results-navigation-cn{
  justify-content: left;
}
li.search-results-navigation__item-cn{
  margin-bottom: 10px;
  font-size: 16px;
  flex:none;
  margin-right: 1em;
}
h2.search-results-section-header__heading-cn{
  font-size: 22px;
  margin-bottom:10px;
  flex: 1;
}
a.search-results-section-header__link-cn{
  font-size: 16px;
  margin-bottom: 8.2px;
  line-height: 22px;
}
