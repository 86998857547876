
$playfair: 'Playfair Display', serif;

// Vertical spacing baseline values:
$spacing-base-v: .5em !default;

// Baseline form input padding values:
$form-element-padding-base: em(6) !default;

// Carousel height (+ max carousel image background height) values:
$carousel-height-base: 440px !default;

// Carousel max image background width value:
$carousel-image-width-base: 1500px !default;

// Smallest width value:
$abs-min-width: 320px !default;
