// .innovations
@include block(innovations) {
	// .innovations__demo
	@include element("demo") {
		background-color: #FFF;
		box-shadow: 0px 0px 30px rgba(0,0,36,0.20);
		max-width: 1080px;
		margin: 0 auto 70px;
		position: relative;
		z-index: 1;
		&::before {
			content: ' ';
			display: block;
			padding-top: 66%;
		}
		// .innovations__demo__iframe
		@include child("iframe") {
			display: block;
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}
	}
}