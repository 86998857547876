/**
@doc _docs/text.md
@section Text
@childof Utilities
@page Text
@status ready
*/

/**
@section Basics
@sectionof Text
@example
_markup/text-basics.hbs
*/



@include plmr-utility('text') {

  // Text Alignment
  // .plmr-u-text--{alignment}
  @include plmr-modifiers(map-get($plmr-text, 'alignment'), 'text-align');


  // Font Weight
  // .plmr-u-text--{style}
  @include plmr-modifiers(map-get($plmr-text, 'weight'), 'font-weight');


  // Font Style
  // .plmr-u-text--{style}
  @include plmr-modifiers(map-get($plmr-text, 'style'), 'font-style');


  // Text Decoration
  // .plmr-u-text--{style}
  @include plmr-modifiers(map-get($plmr-text, 'decoration'), 'text-decoration');


  // Text Transform
  // .plmr-u-text--{transform}
  @include plmr-modifiers(map-get($plmr-text, 'transform'), 'text-transform');


  // Text Color
  // .plmr-u-text--{color}
  @include plmr-modifiers($plmr-colors, 'color');

  // .plmr-u-text--black
  @include modifier('black') {
    color: plmr-color('base', 'black');
  }

}


/**
@section Text Color
@sectionof Text
@example
_markup/text-color.hbs
*/


/**
@section Text Size
@sectionof Text
@example
_markup/text-size.hbs
*/

@include plmr-utility('text') {

  //
  @include plmr-modifiers($plmr-font-size, 'font-size');

}

