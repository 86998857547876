/**
@doc _docs/cardlist-staggered.md
@section Card List Staggered
@childof Card Lists
@page Cardlists Cardlist Staggered
@status ready
@hippo card-list
@example
_markup/cardlist-staggered.hbs
*/
@include plmr-component('cardlist-staggered') {
  background-color: plmr-color('base', 'white');
  position: relative;

  // .plmr-c-cardlist-staggered::before
  &::before {
    @include plmr-pseudo;
    background-color: plmr-color('background', 'secondary');
    bottom: 0;
    height: auto;
    left: 0;
    min-height: (963 / 1286 * 100%);
    min-width: 100%;
    width: auto;
    z-index: 1;

    @include plmr-mq(md) {
      min-height: (439 / 762 * 100%);
      padding: 0;
    }
  }

  // .plmr-c-cardlist-staggered__inner
  @include element('inner') {
    @extend .plmr-l-container;
    font-size: 0;
    padding: 25px 0;
    position: relative;
    z-index: 2;

    @include plmr-mq(md) {
      padding: 60px 0;
    }

    @include plmr-mq(lg) {
      padding: 75px 0;
    }
  }

  // .plmr-c-cardlist-staggered__item
  @include element('item') {
    display: inline-block;
    padding: 15px 0;
    vertical-align: top;
    width: 100%;

    @include plmr-mq(md) {
      padding: 0;
      width: 50%;

      // .plmr-c-cardlist-staggered__item:nth-of-type(even)
      &:nth-of-type(even) {
        padding-top: 75px;

        .plmr-c-cardlist-staggered__media {
          box-shadow: -12px 6px 12px 0 rgba(plmr-color('base', 'black'), 0.15);
        }
      }
    }
  }

  // .plmr-c-cardlist-staggered__media
  @include element('media') {
    height: auto;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  // .plmr-c-cardlist-staggered__image
  @include element('image') {
    height: auto;
    left: 50%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  // .plmr-c-cardlist-staggered__content
  @include element('content') {
    background-color: plmr-color('base', 'white');
    line-height: 1.714rem;
    margin: -20px auto 0;
    padding: 20px 20px 30px;
    position: relative;
    width: (325 / 375 * 100%);
    z-index: 2;

    @include plmr-mq(md) {
      padding: 20px 30px 30px;
    }

    @include plmr-mq(lg) {
      padding: 25px 40px 40px;
      width: (550 / 650 * 100%);
    }
  }

  // .plmr-c-cardlist-staggered__title
  @include element('title') {
    @include plmr-font-face($family: 'regular');
    color: plmr-color('brand', 'primary');
    font-size: 1.286rem;

    @include plmr-mq(md) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  // .plmr-c-cardlist-staggered__copy
  @include element('copy') {
    color: plmr-color('text', 'primary');
    font-size: 1.143rem;
    padding-top: 1rem;

    @include plmr-mq(md) {
      padding-top: 10px;
    }
  }

  // .plmr-c-cardlist-staggered__button
  @include element('button') {
    padding-top: 30px;
  }
}
