
@include block('shopping-cart') {
  padding-top: 2em;

  @include child('load') {
    min-height: 400px;
    position: relative;
  }

  @include modifier('')

  @include modifier('is-empty') {
    padding-bottom: 7em;
  }

  @include child('title') {

    .shopping-cart--is-empty & {
      color: $c_error;
    }

  }
}


@include block('shopping-cart-app') {
  min-height: 200px;
}


@include block('cart') {

  @include modifier('order-review') {
    font-size: rem(11);
  }

  .ng-cloak {
    display: block !important;
    font-size: 0 !important;
    text-indent: -9999em !important;

    // @include media($bp-sm) {
    //   display: table-cell !important;
    // }
  }


  @include element('th') {
    display: none;

    @include media($bp-sm) {
      border-bottom: 1px solid $c_moen-light-gray;
      color: $c_moen-bright-blue;
      display: table-cell;
      font-weight: bold;
      padding: 5px;
    }

    @media print {
      border-bottom: 1px solid $c_moen-light-gray;
      color: $c_moen-bright-blue;
      display: table-cell;
      font-weight: bold;
      padding: 5px;
    }

    @include modifier('part') {

      @include media($bp-sm) {
        text-align: left;
      }

      @media print {
        text-align: left;
      }
    }

    @include modifier('total') {

      @include media($bp-sm) {

        .cart--order-review & {
          text-align: right;
          padding-right: 4%;
          padding-left: 4%;
        }

      }

      @media print {
        .cart--order-review & {
          text-align: right;
          padding-right: 4%;
          padding-left: 4%;
        }
      }
    }

  }


  @include element('td') {
    display: block;
    font-size: rem(14);
    padding: 2px 5px;


    &::before {
      color: $c_moen-bright-blue;
      font-weight: bold;

      @include media($bp-sm) {
        display: none;
      }

      @media print {
        display: none;
      }
    }


    @include modifier('part') {
      border-top: 1px solid $c_moen-light-gray;
      padding-top: 10px;
      padding-bottom: 10px;

      &::before {
        content: attr(data-th);
        display: none;
        margin-bottom: 10px;
        width: 100%;
      }
    }

    @include media($bp-sm) {
      border-bottom: 1px solid $c_moen-light-gray;
      border-top: 0;
      display: table-cell;
      padding: 10px;
    }

    @media print {
      border-bottom: 1px solid $c_moen-light-gray;
      border-top: 0;
      display: table-cell;
      padding: 10px;
    }



    @include modifier('price') {
      line-height: 29px;

      @include media($bp-sm) {
        text-align: center;
      }

      @media print {
        text-align: center;
      }
    }



    @include modifier('quantity') {

      .form-select-wrapper {
        // float: left;
        display: inline-block;
        width: 45px;

        @include media($bp-xxs) {
          display: block;
          margin: 0 auto;
        }
      }

      .form-input__number {
        width: 55px !important;
      }

      .cart--order-review & {
        line-height: 29px;
      }

    }

    @include modifier('total') {
      line-height: 29px;


      @include media($bp-sm) {
        text-align: center;

        .cart--order-review & {
          padding-right: 4%;
          padding-left: 4%;
          text-align: right;
        }
      }

      @media print {
        .cart--order-review & {
          // padding-right: 4%;
          // padding-left: 4%;
          text-align: right;
        }
      }

    }


    @include modifier('action') {
      clear: left;
      padding-bottom: 1em;
      padding-top: 1.25em;

      @include media($bp-xxs) {
        padding-top: 1em;
        text-align: center;
      }

      @include media($bp-sm) {
        clear: none;
        padding-bottom: 0;
        padding-top: 0;
      }
    }


    @include modifier('subtotal') {
      border-top: 1px solid $c_moen-light-gray;
      font-size: rem(18);
      font-weight: bold;
      padding-top: 1em;
      padding-bottom: 1em;
      position: relative;
      text-align: right;

      &-label {
        display: none;

        @include media($bp-sm) {
          color: $c_moen-bright-blue;
          display: table-cell;
          font-size: rem(18);
          font-weight: bold;
          text-align: right;
        }
      }


      &::before {
        content: attr(data-th);
        display: inline-block;
        left: 0;
        margin-right: 5px;
        position: absolute;
        text-align: left;
        width: 6em;

        @include media($bp-sm) {
          display: none;
          // left: -120%;
          // position: absolute;

        }
      }

      @include media($bp-sm) {
        border-bottom: 0;
        text-align: center;


      }
    }

    @include modifier('order-label') {
      display: none;
      text-align: right;

      @include media($bp-sm) {
        display: table-cell;
        padding: 5px;
      }
    }


    @include modifier('order-subtotal') {
      font-weight: bold;
      padding: 2em 5px 5px 5px;
      position: relative;

      &-label {
        font-weight: bold;

        @include media($bp-sm) {
          padding-top: 2em;
        }
      }


      @include media($bp-sm) {
        padding: 2em 4% 2px 4%;
        text-align: right
      }

      &::before {
        color: currentColor;
        // content: attr(data-th);
        // display: inline-block;
        font-size: rem(14);
        font-weight: bold;
        // margin-right: 0.5em;

        @include media($bp-sm) {
          display: none;
        }
      }
    }

    @include modifier('order-shipping') {
      padding: 5px;
      position: relative;

      @include media($bp-sm) {
        padding: 5px 4%;
        text-align: right
      }

      &::before {
        color: currentColor;
        font-size: rem(14);
        font-weight: normal;

        @include media($bp-sm) {
          display: none;
        }
      }
    }

    @include modifier('order-tax') {
      padding: 5px;
      position: relative;

      @include media($bp-sm) {
        padding: 5px 4%;
        text-align: right
      }

      &::before {
        color: currentColor;
        font-size: rem(14);
        font-weight: normal;

        @include media($bp-sm) {
          display: none;
        }
      }
    }

    @include modifier('order-total') {
      font-size: rem(18);
      font-weight: bold;
      padding: 5px;
      position: relative;

      @include media($bp-sm) {
        padding: 5px 4%;
        text-align: right
      }

      @media print {
        text-align: right
      }

      &-label {
        color: $c_moen-bright-blue;
        font-size: rem(18);
        font-weight: bold;
      }

      &::before {
        color: $c_moen-bright-blue;
        font-size: rem(18);
        font-weight: bold;

        @include media($bp-sm) {
          display: none;
        }
      }
    }
  }

  @include element('coupon') {
    margin-top: 2em;


    @include element('form-group') {
      margin-bottom: 0.25em;
      position: relative;
    }

    @include element('label') {
      background: $c_moen-bright-blue;
      color: $c_white;
      height: 94%;
      left: 1px;
      line-height: 31px;
      margin: 0;
      position: absolute;
      text-align: center;
      top: 1px;
      width: 7em;
    }

    @include element('input') {
      padding-left: 7.5em;
      text-transform: uppercase;
    }

    @include element('note') {
      font-size: rem(12);
    }

  }

  @include element('disclaimer') {
    font-size: rem(11);
    padding-top: 5em;

    p {
      font-size: inherit;
    }
  }

  @include element('foot') {

    .cart--order-review & {
      border-top: 1px solid $c_moen-light-gray;
    }

    @include media($bp-sm) {
      border-top: 0;
    }

    @media print {
      border-top: 0;
    }

    td {
      border-bottom: 0;
    }
  }

}

.cart__td--price,
.cart__td--quantity,
.cart__td--total {
  height: 33px;
  padding-left: 5em;
  position: relative;

  &::before {
    content: attr(data-th);
    display: inline-block;
    left: 0;
    position: absolute;
    margin-right: 5px;
    width: 5em;
    line-height: 29px;

    @include media($bp-xxs) {
      display: block;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: auto;
    }
  }

  @include media($bp-xxs) {
    float: left;
    height: auto;
    padding-left: 5px;
    padding-top: 2em;
    text-align: center;
    width: 33%;
  }

  @include media($bp-sm) {
    float: none;
    line-height: normal;
    width: auto;
  }

  @media print {
    height: auto;
    line-height: normal;
    padding-left: 5px;
    padding-top: 2em;
    text-align: center;
    width: auto;
  }
}


.cart__td--order-subtotal,
.cart__td--order-shipping,
.cart__td--order-tax,
.cart__td--order-total {
  padding-left: 6em;
  position: relative;
  text-align: right;

  &::before {
    content: attr(data-th);
    display: inline-block;
    left: 0;
    margin-right: 5px;
    position: absolute;
    text-align: left;
    width: 6em;

    @media print {
      display: none;
    }
  }
}



@include block('cart-input') {

  max-width: 40px;
  width: auto !important;
  padding: 2px;
  text-align: center;


  @include media($bp-sm) {
    max-width: 55px;
  }

}




@include block('cart-item') {

  @include element('part') {
    font-size: rem(18);
    font-weight: bold;
    margin: 0 0 5px 0;
  }

  @include element('remove') {



  }

}


@include block('shopping-cart-table') {
  position: relative;
}




@mixin build-cart-grid() {
  @each $name, $width in $grid-breakpoints {
    @media (min-width: $width) {
      @for $i from 1 through $grid-num-cols {
        // span-columns() is a neat.bourbon.io function to generate grid width
        .cart-col-#{$name}-#{$i} {
          width: percentage(($i / $grid-num-cols));
        }
      }
    }
  }
}

// build grid classes
@include build-cart-grid();
