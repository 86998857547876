// PLMR Width Media Query
@mixin plmr-mq($search, $query: 'min') {

  $width: map-get($plmr-breakpoints, $search);

  @if ( $width > 0 ) {
    @media (#{$query}-width: $width) {
      @content;
    }
  } @else {
    @content;
  }

}


// PLMR Height Media Query
@mixin plmr-mq-height($height, $query: 'min') {

  @if ( $height > 0 ) {
    @media (#{$query}-height: $height) {
      @content;
    }
  } @else {
    @content;
  }

}



// PLMR Print Media Query
@mixin plmr-mq-print() {

  @media print {
    @content;
  }

}
