/**
@doc _docs/cardlist-category-callouts.md
@section Card List Category Callouts
@childof Card Lists
@page Cardlists Cardlist Category Callouts
@status ready
@hippo card-list
@example
_markup/cardlist-category-callouts.hbs
*/
// .plmr-c-cardlist-category-callouts
@include plmr-component('cardlist-category-callouts') {
  display: block;
  padding: 50px 0 0;
  position: relative;
  text-align: center;

  @include plmr-mq(md) {
    padding: 40px 0 0;
  }

  // .plmr-c-cardlist-category-callouts::before
  &::before {
    @include plmr-pseudo();
    background-color: plmr-color('background', 'primary');
    bottom: 0;
    height: calc(100% - 225px);
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    z-index: 1;

    @include plmr-mq(md) {
      height: calc(100% - 300px);
    }

    @include plmr-mq(lg) {
      height: calc(100% - 400px);
    }

  }

  // .plmr-c-cardlist-category-callouts__title
  @include element('title') {
    color: plmr-color('brand', 'primary');
    @include plmr-font-face('light');
    font-size: 1.7142857143rem;
    line-height: 1.3333333333;
    margin: 0;
    padding: 0;

    @include plmr-mq(md) {
      font-size: 2.2857142857rem;
      line-height: 1.125;
    }

  }

  // .plmr-c-cardlist-category-callouts__subtitle
  @include element('subtitle') {
    @include plmr-font-face('base');
    font-size: 1.2857142857rem;
    line-height: 1.3333333333;
    margin: 0;
    padding-top: 10px;

    @include plmr-mq(md) {
      font-size: 1.5rem;
      line-height: 1.8095238095;
    }

  }

  // .plmr-c-cardlist-category-callouts__navigation
  @include element('navigation') {
    display: none;
    margin: 0 auto;
    max-width: 500px;
    padding-top: 20px;
  }

  // .plmr-c-cardlist-category-callouts__cta
  @include element('cta') {
    padding-top: 10px;
  }

  // .plmr-c-cardlist-category-callouts__list
  @include element('list') {
    font-size: 0;
    padding: 36px 0 30px;
    position: relative;
    transition: opacity 0.5s ease;
    z-index: 2;

    @include plmr-mq(md) {
      padding-top: 75px;
    }

    @include plmr-state('hidden') {
      opacity: 0;
      pointer-events: none;
    }
    // .plmr-c-cardlist-category-callouts__list._absolute
    @include plmr-state('absolute') {
      bottom: 0;
      left: 0;
      position: absolute;

      // .plmr-c-cardlist-category-callouts__list._absolute._hidden
      // This is needed for browsers that don't honor pointer-events: none
      // so you cannot click a hidden absolute item
      @include plmr-state('hidden') {
        transform: translateX(-100%);
        z-index: -1;
      }
    }

  }

  // .plmr-c-cardlist-category-callouts__item
  @include element('item') {
    cursor: pointer;
    display: inline-block;
    margin: 0 15px 30px;
    overflow: hidden;
    position: relative;
    transform: translate(0);
    transition: all 0.5s ease;
    vertical-align: top;
    width: 95px;
    z-index: 2;

    @include plmr-mq(md) {
      margin: 0 30px 30px;
      width: 118px;
    }

    @include plmr-mq(lg) {
      margin: 0 25px 30px;
      width: 204px;

      &:hover {
        .plmr-c-cardlist-category-callouts__caption {
          background-color: plmr-color('background', 'secondary');
        }
      }

    }

    @include plmr-state('hidden') {
      opacity: 0;
      transform: translateX(-10px);
      transition: none;
    }

  }

  // .plmr-c-cardlist-category-callouts__image-container
  @include element('image-container') {
    display: block;
    height: 120px;
    position: relative;
    width: 100%;

    @include plmr-mq(md) {
      height: 162px;
    }

    @include plmr-mq(lg) {
      height: 280px;
    }

  }

  // .plmr-c-cardlist-category-callouts__image
  @include element('image') {
    display: block;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @supports(object-fit: contain) {
      height: 100%;
      left: 0;
      object-fit: contain;
      object-position: center;
      position: static;
      top: 0;
      transform: none;
      width: 100%;
    }

  }

  // .plmr-c-cardlist-category-callouts__caption
  @include element('caption') {
    background-color: plmr-color('background', 'tertiary');
    border-radius: 2px;
    color: plmr-color('text', 'primary');
    display: inline-block;
    font-size: 1rem;
    line-height: 1.875;
    margin-top: 15px;
    width: 100%;

    @include plmr-mq(md) {
      font-size: 1.1428571429rem;
      line-height: 40px;
      margin-top: 25px;
    }

  }

}
