
/*
Buy Moen CTA

markup:
<div class="buy-moen-cta">
  <a href="tel:1-800-289-6636" class="buy-moen-cta__link link--tel">1-800-Buy-Moen</a>
</div>

Styleguide 3.24
*/


/// .buy-moen-cta
@include block('buy-moen-cta') {
  font-family: $din-bold;
  font-size: rem(18);
  text-transform: uppercase;  
  
  @include media($bp-md) {
    font-size: rem(24);
  }
  
  
  /// .buy-moen-cta__link
  @include child('link') {
    color: inherit;
    cursor: default;
    text-decoration: none;
    
    /// .buy-moen-cta__link:hover
    &:hover {
      text-decoration: none;
    }
  }
}
