/**
@doc _docs/banner-quad-collage.md
@section Banner Quad Collage
@childof Banners
@page Banners Banner Quad Collage
@status ready
@example
_markup/banner-quad-collage.hbs
*/
@include plmr-component('banner-quad-collage') {
  display: block;
  height: auto;
  max-height: 620px;
  padding-bottom: 57.6%;
  padding-top: 30px;
  position: relative;
  width: 100%;


  @include plmr-mq(md) {
    height: 898px;
    max-height: none;
    padding-bottom: 0;
    padding-top: 0;
  }


  @include plmr-mq(xl) {
    height: 1194px;
  }

  &::before,
  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }


  &::before {
    background: plmr-color('brand', 'primary');
    height: 60%;

    @include plmr-mq(md) {
      height: 488px;
    }
  }


  &::after {
    background: plmr-color('background', 'secondary');
    height: 19%;


    @include plmr-mq(md) {
      height: 158px;
    }
  }


  // .plmr-c-banner-quad-collage__container
  @include element('container') {
    margin-left: auto;
    margin-right: auto;
    max-width: 425px;
    min-width: 320px;
    padding-left: 38px;
    padding-right: 38px;
    position: relative;


    @include plmr-mq(md) {
      max-width: 768px;
      padding: 0;
    }


    @include plmr-mq(xl) {
      max-width: 1300px;
    }
  }


  // .plmr-c-banner-quad-collage__item
  @include element('item') {
    display: block;
    height: auto;
    position: relative;
    width: 100%;


    @include plmr-mq(md) {
      left: 0;
      position: absolute;
      top: 0;
    }


    // .plmr-c-banner-quad-collage__item--one
    @include modifier('one') {
      .plmr-c-banner-quad-collage__image-area {
        box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
        padding-top: 98.67%;
        transition: transform 0s linear;
        will-change: transform;
        z-index: 2;


        @include plmr-mq(md) {
          height: 324px;
          margin: 0 25px 0 auto;
          padding-top: 0;
          width: 328px;
        }


        @include plmr-mq(xl) {
          height: 450px;
          margin: 0 142px 0 auto;
          width: 455px;
        }
      }


      @include plmr-mq(md) {
        margin-top: 125px;
        //transform: translateY(125px);
        z-index: 3;
      }


      @include plmr-mq(xl) {
        margin-top: 168px;
        //transform: translateY(168px);
      }
    }


    // .plmr-c-banner-quad-collage__item--two
    @include modifier('two') {
      margin-top: 200px;
      position: absolute;
      right: 0;
      top: 0;
      //transform: translateY(75%);
      z-index: 2;


      @include plmr-mq(md) {
        margin-top: 515px;
        //transform: translateY(515px);
        z-index: 3;
      }


      @include plmr-mq(xl) {
        margin-top: 658px;
        //transform: translateY(658px);
      }


      .plmr-c-banner-quad-collage__image-area {
        margin: 0 20px 0 auto;
        padding-top: 60%;
        width: 203px;


        @include plmr-mq(md) {
          height: 375px;
          margin: 0 0 0 auto;
          padding-top: 0;
          width: 338px;
        }


        @include plmr-mq(xl) {
          height: 500px;
          margin: 0 86px 0 auto;
          width: 450px;
        }
      }
    }


    // .plmr-c-banner-quad-collage__item--three
    @include modifier('three') {
      transition: transform 0s linear;
      will-change: transform;


      @include plmr-mq(md) {
        margin-top: 357px;
        //transform: translateY(357px);
        z-index: 2;
      }


      @include plmr-mq(xl) {
        margin-top: 461px;
        //transform: translateY(461px);
      }


      .plmr-c-banner-quad-collage__image-area {
        @include plmr-mq(md) {
          height: 456px;
          margin: 0 auto 0 25px;
          width: 448px;
        }


        @include plmr-mq(xl) {
          height: 620px;
          margin: 0 auto 0 192px;
          width: 609px;
        }
      }
    }


    // .plmr-c-banner-quad-collage__item--four
    @include modifier('four') {
      @include plmr-mq(md) {
        margin-top: 30px;
        z-index: 1;
      }


      .plmr-c-banner-quad-collage__image-area {
        @include plmr-mq(md) {
          height: 315px;
          margin: 0 auto 0 46px;
          width: 315px;
        }


        @include plmr-mq(xl) {
          height: 350px;
          margin: 0 auto 0 218px;
          width: 350px;
        }
      }
    }
  }


  // .plmr-c-banner-quad-collage__image-area
  @include element('image-area') {
    display: block;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
  }


  // .plmr-c-banner-quad-collage__image
  @include element('image') {
    display: block;
    height: auto;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
}
