//
// https://gist.github.com/tobiasahlin/7a421fb9306a4f518aab
//
// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  transition(width 0.2s, height 0.3s ease-in-out); (let post-css take care of the vendor prefixing)
//
// Pass in any number of transitions
@mixin transition($transitions...) {
  $unfolded-transitions: ();
  @each $transition in $transitions {
    $unfolded-transitions: append($unfolded-transitions, unfold-transition($transition), comma);
  }
  transition: $unfolded-transitions;
}

@function unfold-transition ($transition) {
  // Default values
  $property: all;
  $duration: 0.2s;
  $easing: null; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $default-properties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfolded-transition: ();
  @for $i from 1 through length($default-properties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i);
    } @else {
      $p: nth($default-properties, $i);
    }
    $unfolded-transition: append($unfolded-transition, $p);
  }

  @return $unfolded-transition;
}
