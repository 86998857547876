/**
Can be used in various areas across the site including PDPs. Also see Finishes Gallery component.
@section Finishes Nav
@childof Navigation
@page Navigation Finishes Nav
@status in progress
@example
_markup/finishes-nav.hbs
*/
@include plmr-component('finishes-nav') {
  font-size: 0;
  margin: 0 -4px;

  // .plmr-c-finishes-nav__finish
  @include element('finish') {
    border: 2px solid transparent;
    border-radius: 50%;
    display: inline-block;
    height: 31px;
    margin: 0 4px;
    position: relative;
    transition: 0.333s ease-in-out;
    width: 31px;

    // .plmr-c-finishes-nav__finish:hover
    &:hover {
      border-color: plmr-color('accent', 'dark');
    }

    // .plmr-c-finishes-nav__finish._selected
    @include plmr-state('selected') {
      border-color: plmr-color('brand', 'tertiary');
    }

    // .plmr-c-finishes-nav__finish._unavailable
    @include plmr-state('unavailable') {
      // .plmr-c-finishes-nav__finish._unavailable::before,
      // .plmr-c-finishes-nav__finish._unavailable::after
      &::before,
      &::after {
        @include plmr-pseudo;
        background-color: plmr-color('accent', 'dark');
        height: 1px;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 100%;
        z-index: 2;
      }

      &::after {
        transform: translateY(-50%) rotate(-45deg);
      }

      .plmr-c-finishes-nav__finish-image {
        opacity: 0.5;
      }
    }
  }

  // .plmr-c-finishes-nav__finish-button
  @include element('finish-button') {
    display: block;
    max-width: 100%;
    padding: 1px;

    // .plmr-c-finishes-nav__finish-button:hover
    &:hover {
      cursor: pointer;
    }
  }

  // .plmr-c-finishes-nav__finish-image
  @include element('finish-image') {
    display: block;
    max-height: 100%;
    max-width: 100%;
  }

  // .plmr-c-finishes-nav__finish-name
  @include element('finish-name') {
    @include plmr-font-face($family: 'italic');
    bottom: -35px;
    display: none;
    font-size: 1rem;
    height: auto;
    left: 50%;
    line-height: 1.5rem;
    min-width: calc(100% * 8);
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: auto;
  }
}

/**
You can change the data-show-finish-name attribute to true if you want the finish name to show when selected.
@section Large
@sectionof Finishes Nav
@example
_markup/finishes-nav-large.hbs
*/
@include plmr-component('finishes-nav') {
  // .plmr-c-finishes-nav--large
  @include modifier('large') {
    margin: 0 -8px;

    .plmr-c-finishes-nav__finish {
      height: 40px;
      margin: 0 8px;
      width: 40px;
    }

    .plmr-c-finishes-nav__finish-button {
      padding: 4px;
    }
  }
}

/**
@section Mini
@sectionof Finishes Nav
@example
_markup/finishes-nav-mini.hbs
*/
@include plmr-component('finishes-nav') {
  // .plmr-c-finishes-nav--mini
  @include modifier('mini') {
    .plmr-c-finishes-nav__finish {
      height: 26px;
      width: 26px;
    }
  }
}


/**
Example of finishes nav with an unavailable item.
@section Unavailable
@sectionof Finishes Nav
@example
_markup/finishes-nav-unavailable.hbs
*/
