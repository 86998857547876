/*
Site Footer

markup:
<footer class="site-footer">
  <div class="site-footer__row">
    <div class="col-lg-4">
      <div>
        <div>
          <section class="rtf-component">
            <div>
              <div>
                <article class="has-edit-button">
                  <h3>© 2017 Moen Incorporated</h3>
                  <p>As the #1 faucet brand in North America, Moen offers a diverse selection of thoughtfully designed kitchen and bath faucets, showerheads, accessories, bath safety products and kitchen sinks for residential and commercial applications
                    each delivering the best possible combination of meaningful innovation, useful features, and lasting value.</p>
                </article>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="col-lg-2">
      <nav>
        <ul class="list--ruled">
          <li>
            <a href="/site/press-room" class="link">Mobile Site</a></li>
          <li>
            <a href="/site/" class="link">Privacy Policy</a></li>
          <li>
            <a href="/site/" class="link">Legal Notice</a></li>
          <li>
            <a href="/site/" class="link">CA Supply Chain Act</a></li>
          <li>
            <a href="/site/" class="link">Sitemap</a></li>
        </ul>
      </nav>
    </div>
    <div class="col-lg-2">
      <nav>
        <ul class="list--ruled">
          <li>
            <a href="/site/" class="link">Contact Us</a></li>
          <li>
            <a href="/site/" class="link">About Moen</a></li>
          <li>
            <a href="/site/" class="link">Press Room</a></li>
          <li>
            <a href="/site/" class="link">Career Opportunities</a></li>
          <li>
            <a href="/site/" class="link">Online Retailers</a></li>
        </ul>
      </nav>
    </div>
    <div class="col-lg-4">
      <div class="site-footer--follow">
        <nav id="footer-menu-social-follow" class="social-follow-menu" aria-labelledby="menu-social-follow-label">
          <label id="menu-social-follow-label">Stay Connected</label>
          <ul class="list--unstyled list--horizontal is-justified">
            <li>
              <a href="https://www.facebook.com/moen" data-social-network="facebook" rel="external"> <span class="hat">facebook</span><img src="./images/facebook.svg" alt=""> </a>
            </li>
            <li>
              <a href="https://twitter.com/moen" data-social-network="twitter" rel="external"> <span class="hat">twitter</span><img src="./images/twitter.svg" alt=""> </a>
            </li>
            <li>
              <a href="https://www.pinterest.com/moen/" data-social-network="pinterest" rel="external"> <span class="hat">pinterest</span><img src="./images/pinterest.svg" alt=""> </a>
            </li>
            <li>
              <a href="http://www.houzz.com/pro/moen" data-social-network="houzz" rel="external"> <span class="hat">house</span><img src="./images/houzz.svg" alt=""> </a>
            </li>
            <li>
              <a href="https://www.instagram.com/moeninc/" data-social-network="instagram" rel="external"> <span class="hat">instagram</span><img src="./images/instagram.png" alt=""> </a>
            </li>
            <li>
              <a href="https://www.youtube.com/user/moenfaucets" data-social-network="youtube" rel="external"> <span class="hat">youtube</span><img src="./images/youtube.svg" alt=""> </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="site-footer--follow">
        <div class="email-signup ng-scope" id="email-signup-app" data-campaign="" data-guid="">
          <div class="footer-signup ng-scope" ng-controller="EmailSignupController">
            <h2 class="footer-signup__title">Don’t miss a thing!</h2>
            <p class="footer-signup__sub-title"><small>Get email updates from Moen.</small></p>
            <a class="button button--secondary send-me-updates" ng-click="openSignupModal($event)">
              <img src="./images/icon-email-signup_white.svg" alt="" class="send-me-updates__image"> Send Me Updates
            </a>
            <div class="modal email-signup-modal" id="email-signup-modal">
              <div class="modal__header">
                <div class="modal-close">
                  <div class="modal-close__text"></div>
                </div>
              </div>
              <div class="modal__body">
                <h2 class="heading--2 section-heading--bottom-maurgin text-center">Don’t miss your chance at a <sup>$</sup>100 gift card.</h2>
                <p class="text-center">
                  <small>
            Sign up to receive Moen design trends and tips, maintenance reminders, and future discounts, and we'll
            enter your name into a drawing to win a $100 gift card!*
          </small>
                </p>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-8 col-sm-push-4">
                      <form name="emailSignupForm" ng-submit="registerUser()" novalidate="" class="ng-pristine ng-invalid ng-invalid-required ng-valid-email">
                        <div class="email-signup-error-messages">
                        </div>
                        <div class="form email-signup__form">
                          <div class="form-group">
                            <input type="text" name="firstname" placeholder="First Name" ng-model="user.firstname" class="form-input form-input__text email-signup__input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" ng-class="{ 'is-error' : emailSignupForm.firstname.$invalid &amp;&amp; emailSignupForm.firstname.$touched }"
                              required="">
                          </div>
                          <div class="form-group">
                            <input type="text" name="lastname" placeholder="Last Name" ng-model="user.lastname" class="form-input form-input__text email-signup__input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" ng-class="{ 'is-error' : emailSignupForm.lastname.$invalid &amp;&amp; emailSignupForm.lastname.$touched }"
                              required="">
                          </div>
                          <div class="form-group">
                            <input type="email" name="email" placeholder="Email Address" ng-model="user.email" class="form-input form-input__email email-signup__input ng-pristine ng-untouched ng-empty ng-valid-email ng-invalid ng-invalid-required" ng-class="{ 'is-error' : emailSignupForm.email.$invalid &amp;&amp; emailSignupForm.email.$touched }"
                              required="">
                          </div>
                          <div class="form-group">
                            <input type="number" name="zipcode" placeholder="Zip Code" ng-model="user.zipcode" class="form-input form-input__text email-signup__input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" ng-class="{ 'is-error' : emailSignupForm.zipcode.$invalid &amp;&amp; emailSignupForm.zipcode.$touched }"
                              required="">
                          </div>
                          <button type="submit" class="button button--secondary" ng-disabled="emailSignupForm.$invalid" disabled="disabled">Yes! Register me!</button>
                          <div class="loading-display">
                            <img src="/site/binaries/content/gallery/moen/notifications/loading.svg" alt="loading" class="loading-display__image preference-loading-display__image">
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <p class="text-center">
                  <a href="https://pro.moen.com/register" target="_blank" rel="external">
                    <small><strong>Trade Professional?</strong> Click here to learn more about our exclusive MoenPro eNewsletter.</small>
                  </a>
                </p>
                <p class="text-right"><a href=""><small>* Terms &amp; Conditions and Privacy Policy</small></a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <hr class="shadowed-rule">
    <div class="col-md-3 site-footer__lang-switch">
      <form novalidate="">
        <label for="language-selector" class="hat">Select Language</label>
        <select class="form-select form-select__language js-lang-select" id="language-selector">
          <option disabled="disabled" selected="selected" value="">Select Language</option>
          <optgroup label="Asia">
            <option value="http://www.moen.com.hk/">English</option>
          </optgroup>
          <optgroup label="Mexico">
            <option value="http://en.moen.com.mx/">English</option>
            <option value="http://www.moen.com.mx/enes/">Español</option>
          </optgroup>
          <optgroup label="中国-China">
            <option value="http://www.moen.cn/enzh/">中文简体</option>
          </optgroup>
          <optgroup label="India">
            <option value="http://www.moen.co.in/">English</option>
          </optgroup>
          <optgroup label="United&nbsp;States">
            <option value="http://localhost:8080/site/">English</option>
            <option value="http://espanol.moen.com/">Español</option>
          </optgroup>
          <optgroup label="Canada">
            <option value="http://localhost:8080/site/ca-fr">Français</option>
            <option value="http://localhost:8080/site/ca-en">English</option>
          </optgroup>
        </select>
      </form>
    </div>
  </div>
</footer>

Styleguide 4.4
*/

@include block(site-footer) {
  @include outer-container();
  border-color: $c_rule;
  box-shadow: 0 0 6px $c_shadow;
  padding: 1em $grid-outside-spacing;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .rtf-component {
    margin: inherit;
    h3:not([class*=heading--]) {
      font-size: 1rem;
      font-weight: normal;
      margin: inherit;

    }
  }

  @include media($max-width + $grid-outside-spacing-bp-offset) {
    padding: 1em;
  }

  @include element(row) {
    @include row();

    @include media($bp-lg) {
      [class*="col-"] {
        padding-top: 1em;
      }
      [class*="col-"]:last-child {
        padding-top: 0;
      }
    }
  }

  @include element(lang-switch) {
    padding: 0 1rem;
    .form-select__language {
      min-height: 40px;
    }
  }

  @include modifier(follow) {
    background-color: $c_moen-bright-gray;
    padding: rem(16);
    &:first-child {
      margin-top: 1em;
      @include media($bp-lg) {
        margin-top: 0;
      }
    }
  }

  @include modifier(heading) {
    font-weight: 600;
    padding-bottom: .5em;
  }

}
