

// Mixin to create finish gradient
// -----------------------------------------------------------------------------
@mixin plmr-finish-gradient($background: null, $is-fade-to-white: true) {

  &::before {
    @if $background != null {
      background: linear-gradient(to left, lighten($background, 13%) 0%, $background 100%);
    }
  }

  &::after {
    @if $is-fade-to-white {
      background: linear-gradient(to right, rgba(plmr-color('base', 'white'), 0) 0%, rgba(plmr-color('base', 'white'), 0) 45%, rgba(plmr-color('base', 'white'), 0.3) 99%, rgba(plmr-color('base', 'white'), 0.3) 100%);
    }
  }
}


/**
@doc _docs/cardlist-finish-gallery.md
@section Card List Finish Gallery
@childof Card Lists
@page Cardlists Cardlist Finish Gallery
@status ready
@example
_markup/cardlist-finish-gallery.hbs
*/
@include plmr-component('cardlist-finish-gallery') {
  background: plmr-color('base', 'white');
  position: relative;
  z-index: 1;

  // .plmr-c-cardlist-finish-gallery__background
  @include element('background') {
    display: block;
    height: auto;
    left: 0;
    min-height: 62%;
    min-width: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 1;

    @include plmr-mq(md) {
      min-height: 68%;
    }

    @include plmr-mq(lg) {
      min-height: 73%;
    }
  }

  // .plmr-c-cardlist-finish-gallery__gradient
  @include element('gradient') {
    background: transparent;
    height: auto;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    opacity: 1;
    position: absolute;
    top: 0;
    transition: 0.75s ease;
    width: auto;

    // .plmr-c-cardlist-finish-gallery__gradient::before,
    // .plmr-c-cardlist-finish-gallery__gradient::after
    &::before,
    &::after {
      @include plmr-pseudo;
      background: transparent;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
    }

    // .plmr-c-cardlist-finish-gallery__gradient._hidden
    @include plmr-state('hidden') {
      opacity: 0;
    }

    /// states for finish background colors
    // .plmr-c-cardlist-finish-gallery__gradient._brushed-nickel
    @include plmr-state('brushed-nickel') {
      @include plmr-finish-gradient($background: plmr-color('finish', 'brushed-nickel'));
    }

    // .plmr-c-cardlist-finish-gallery__gradient._matte-black
    @include plmr-state('matte-black') {
      @include plmr-finish-gradient($background: plmr-color('finish', 'matte-black'), $is-fade-to-white: false);
    }

    // .plmr-c-cardlist-finish-gallery__gradient._chrome
    @include plmr-state('chrome') {
      @include plmr-finish-gradient($background: plmr-color('finish', 'chrome'));
    }

    // .plmr-c-cardlist-finish-gallery__gradient.walnut
    @include plmr-state('walnut') {
      @include plmr-finish-gradient($background: plmr-color('finish', 'walnut'));
    }

    // .plmr-c-cardlist-finish-gallery__gradient.deep-blue
    @include plmr-state('deep-blue') {
      @include plmr-finish-gradient($background: plmr-color('finish', 'deep-blue'));
    }

    // .plmr-c-cardlist-finish-gallery__gradient.deep-blue
    @include plmr-state('blue') {

      @include plmr-finish-gradient($background: plmr-color('finish', 'blue'));
    }

    // .plmr-c-cardlist-finish-gallery__gradient.deep-blue
    @include plmr-state('ice-white') {
      @include plmr-finish-gradient($background: plmr-color('finish', 'ice-white'));
    }

    // .plmr-c-cardlist-finish-gallery__gradient._bronze
    @include plmr-state('bronze') {
      @include plmr-finish-gradient($background: plmr-color('finish', 'bronze'), $is-fade-to-white: false);
    }

    // .plmr-c-cardlist-finish-gallery__gradient._polished-nickel
    @include plmr-state('polished-nickel') {
      @include plmr-finish-gradient($background: plmr-color('finish', 'polished-nickel'));
    }

    // .plmr-c-cardlist-finish-gallery__gradient._spot-resist-stainless
    @include plmr-state('spot-resist-stainless') {
      @include plmr-finish-gradient($background: plmr-color('finish', 'spot-resist-stainless'));
    }
  }

  // .plmr-c-cardlist-finish-gallery__inner
  @include element('inner') {
    @extend .plmr-l-container;
    overflow: hidden;
    padding: 0;
    position: relative;
    z-index: 2;
  }

  // .plmr-c-cardlist-finish-gallery__intro
  @include element('intro') {
    display: block;
    height: auto;
    left: 50%;
    min-width: 100%;
    padding: 0;
    padding-top: 60px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: auto;
    z-index: 999;

    @include plmr-mq(md) {
      padding: 0;
      padding-top: 75px;
    }

    @include plmr-mq(lg) {
      left: 0;
      min-width: 0;
      padding: 0;
      padding-left: 7%;
      padding-top: 140px;
      text-align: left;
      transform: none;
    }
  }

  // .plmr-c-cardlist-finish-gallery__intro-eyebrow
  @include element('intro-eyebrow') {
    color: plmr-color('base', 'white');
  }

  // .plmr-c-cardlist-finish-gallery__intro-title
  @include element('intro-title') {
    color: plmr-color('base', 'white');
  }

  // .plmr-c-cardlist-finish-gallery__item
  @include element('item') {
    display: block;
    height: auto;
    min-height: 606px;
    padding-bottom: 160px;
    padding-top: 165px;
    position: relative;
    text-align: center;
    width: 100%;

    &:not(:first-child) {
      display: none;
    }

    @include plmr-mq(md) {
      min-height: 886px;
      padding-bottom: 179px;
      padding-top: 207px;
    }

    @include plmr-mq(lg) {
      min-height: 615px;
      padding-bottom: 75px;
      padding-top: 140px;
    }
  }

  // .plmr-c-cardlist-finish-gallery__image-area
  @include element('image-area') {
    height: auto;
    margin: 0 auto;
    max-width: 250px;
    min-height: 250px;
    overflow: hidden;
    padding-top: 0;
    position: relative;
    width: 100%;

    @include plmr-mq(md) {
      max-width: 450px;
      min-height: 460px;
    }

    @include plmr-mq(lg) {
      min-height: 400px;
    }
  }

  // .plmr-c-cardlist-finish-gallery__image
  @include element('image') {
    height: auto;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    padding-top: 50px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;

    @include plmr-mq(md) {
      padding-top: 60px;
    }

    @include plmr-mq(lg) {
      padding-top: 0;
    }
  }

  // .plmr-c-cardlist-finish-gallery__button
  @include element('button') {
    @include plmr-mq(lg) {
      left: 7%;
      position: absolute;
      top: 272px;
    }
  }

  // .plmr-c-cardlist-finish-gallery__nav
  @include element('nav') {
    bottom: 0;
    height: auto;
    left: 0;
    min-height: 120px;
    min-width: 100%;
    // overflow: hidden;
    position: absolute;
    text-align: center;
    width: auto;

    @include plmr-mq(lg) {
      bottom: auto;
      color: plmr-color('base', 'white');
      left: auto;
      max-width: 383px;
      min-width: 0;
      padding-left: 30px;
      right: 0;
      text-align: left;
      top: 205px;
      width: 30%;
    }
  }

  .slick-initialized {
    .plmr-c-cardlist-finish-gallery__item {
      display: block;
    }
  }
}
