@include block('product-registration') {

  @include child('load') {
    min-height: 200px;
    position: relative;

    @include media($bp-sm) {
      min-height: 400px;
    }
  }


  @include child('error') {
    color: $c_error;
    font-size: rem(16);
    font-weight: bold;
    margin-bottom: 2em;
  }

  @include child('image') {
    height: auto;
    max-width: 100px;
    width: 100%;
  }

  @include child('select') {

    @include modifier('month') {
      margin-bottom: 1em;

      @include media($bp-sm) {
        margin-bottom: 0;
      }
    }
  }

  @include child('row') {
    margin-bottom: 2em;

    @include media($bp-md) {
      margin-bottom: 0;
    }
  }

  @include child('remove') {
    display: inline-block;
    font-size: rem(12);

    @include media($bp-md) {
      margin-top: 2.75em;
    }

    @include modifier('image-upload') {
      margin-top: 2.75em;
    }
  }
}


@include block('product-registration-confirmation-info') {
  margin-bottom: 2em;

  @include child('item') {

  }

  @include child('products') {
    margin-bottom: 2em;

    td {
      vertical-align: middle;

      &::before {
        display: block;
        margin-bottom: 0.5em;
        width: 100%;
      }
    }

    @include child('row') {
      border-bottom: 1px solid $c_moen-light-gray;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
