/**
@doc _docs/banners.md
@section Banners
@childof Components
@page Banners
@nobrandtoggle
*/
@include plmr-component('banner') {

  // .plmr-c-banner__title
  @include element('title') {
    margin: 0;

    @include modifier('italic') {
      @include plmr-font-face($family: 'italic');
      color: plmr-color('brand', 'secondary');
      font-size: 1.5rem;
      line-height: 2rem;

      @include plmr-mq(md) {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
  }

  // .plmr-c-banner__copy
  @include element('copy') {
    color: plmr-color('text', 'primary');
    font-size: 1.143rem;
    line-height: 1.714rem;

    // .plmr-c-banner__copy--stat
    @include modifier('stat') {
      @include plmr-font-face($family: 'italic');
      color: darken(plmr-color('text', 'secondary'), 8%);
      font-size: 0.8571rem;
      line-height: 1.5rem;
    }
  }
}

@import 'banner-article-split';

@import 'banner-collage';

@import 'banner-content-split';

@import 'banner-cta';

@import 'banner-customer-service';

@import 'banner-featured-innovation';

@import 'banner-hero-knockout';

@import 'banner-hero-landing';

@import 'banner-hero-split';

@import 'banner-image-divider';

@import 'banner-intro';

@import 'banner-mega-obnoxious';

@import 'banner-quad-collage';

@import 'banner-text-over-image';

@import 'banner-customer-service';

@import 'banner-tabbed-content-search';

@import 'banner-content-info';

@import 'banner-video';

@import 'banner-experience-split';

@import 'banner-support';

@import 'banner-mega-split';
