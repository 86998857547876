
/**
Tertiary button styling
@section Tertiary Button
@childof Buttons
@page Buttons Tertiary Button
@status ready
@example
_markup/button-tertiary.hbs
*/
@include plmr-component('button') {


  // .plmr-c-button--tertiary
  @include modifier('tertiary') {
    background: plmr-color('brand', 'tertiary');

    @include plmr-button-hover(plmr-color('brand', 'tertiary'));
  }

}
