
/*
@doc _docs/cardlist-category-collection.md
@section Card List Category Collection
@childof Card Lists
@page Cardlists Cardlist Category Collection
@status ready
@example
_markup/cardlist-category-collection.hbs
*/
@include plmr-component('cardlist-category-collection') {
  padding: 30px 0;

  @include plmr-mq(md) {
    padding: 67px 25px 40px 0;
  }

  @include plmr-mq(lg) {
    // padding-right: 40px;
    padding-right: 3.0769%;
  }

  //.plmr-c-cardlist-category-collection__inner
  @include element('inner') {
    @extend .plmr-l-container;
    max-width: 1260px;
    padding: 0;
    padding-bottom: 120px;
    position: relative;

    @include plmr-mq(md) {
      align-items: flex-start;
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: 92px;
    }

    @include plmr-mq(lg) {
      //padding-bottom: 80px;
      padding-bottom: 12.45%;
    }

    @include plmr-mq(xl) {
      padding-bottom: 80px;
    }

    //.plmr-c-cardlist-category-collection__inner::before
    &::before {
      @include plmr-pseudo;
      background: plmr-color('base', 'white');
      bottom: 0;
      height: (560 / 610 * 100%);
      left: 0;
      width: 100%;
      z-index: 1;

      @include plmr-mq(md) {
        height: (418 / 468 * 100%);
        left: 25px;
        width: (628 / 743 * 100%); //653
      }

      @include plmr-mq(lg) {
        left: 40px;
        width: (1169 / 1260 * 100%);
      }
    }
  }

  //.plmr-c-cardlist-category-collection__arrows
  @include element('arrows') {
    bottom: 40px;
    height: 30px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 164px;
    z-index: 5;

    @include plmr-mq(md) {
      bottom: 50px;
      left: 10.37%;
      transform: none;
    }

    @include plmr-mq(lg) {
      left: 4.15%;
    }

    @include plmr-mq(xl) {
      bottom: 10.6837%;
      left: 6.8461%;
    }
  }

  //.plmr-c-cardlist-category-collection__description
  @include element('description') {
    background: plmr-color('brand', 'primary');
    color: plmr-color('base', 'white');
    padding: 40px 25px;
    position: relative;
    text-align: center;
    width: (350 / 375 * 100%);
    z-index: 2;

    @include plmr-mq(md) {
      flex: 0 0 (293 / 743 * 100%);
      padding: 0;
      padding-top: calc(350 / 743 * 100%);
    }

    @include plmr-mq(lg) {
      flex: 0 0 (342 / 1260 * 100%);
      padding-top: calc(350 / 952 * 100%);
    }

    @include plmr-mq(xl) {
      min-height: 350px;
      padding-top: calc(350 / 1260 * 100%);
    }
  }

  //.plmr-c-cardlist-category-collection__description-inner
  @include element('description-inner') {
    @include plmr-mq(md) {
      display: block;
      left: 50%;
      margin-top: -67px;
      padding: 0 25px;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100%;
    }

    @include plmr-mq(xl) {
      padding: 0 40px;
    }
  }

  //.plmr-c-cardlist-category-collection__knockout
  @include element('knockout') {
    display: none;

    @include plmr-mq(md) {
      display: block;
      height: auto;
      left: 0;
      margin: 0 auto 20px;
      padding-top: calc(135 / 243 * 100%);
      position: relative;
      top: 0;
      width: (135 / 243 * 100%);
    }

    @include plmr-mq(lg) {
      padding-top: calc(135 / 208 * 100%);
      width: (135 / 208 * 100%);
    }

    @include plmr-mq(xl) {
      padding-top: calc(135 / 262 * 100%);
      width: (135 / 262 * 100%);
    }

    //.plmr-c-cardlist-category-collection__knockout--src
    @include modifier('src') {
      @extend .plmr-u-img--responsive;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  //.plmr-c-cardlist-category-collection__title
  @include element('title') {
    font-size: 1.286rem;
    line-height: 1.714rem;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    //.plmr-c-cardlist-category-collection__title--category
    @include modifier('category') {
      @include plmr-font-face($family: 'bold');
    }

    //.plmr-c-cardlist-category-collection__title--on-slide
    @include modifier('on-slide') {
      @include plmr-link($color: plmr-color('brand', 'primary'), $underline: false);
      font-size: 1.143rem;
      line-height: 1.5rem;

      @include plmr-mq(lg) {
        opacity: 0;
        transition: opacity 1.5s ease 1s;

        .no-js & {
          opacity: 1;
        }
      }
    }
  }

  //.plmr-c-cardlist-category-collection__copy
  @include element('copy') {
    display: none;

    @include plmr-mq(md) {
      display: block;
      font-size: 1.143rem;
      line-height: 1.714rem;
      max-height: 111px;
      overflow: hidden;
      padding-top: 15px;
      position: relative;
    }

    @include plmr-mq(xl) {
      max-height: 135px;
    }

    //.plmr-c-cardlist-category-collection__copy._overflow
    @include plmr-state('overflow') {
      //.plmr-c-cardlist-category-collection__copy._overflow::before
      &::before {
        display: block;
      }
    }

    //.plmr-c-cardlist-category-collection__copy::before
    &::before {
      background-color: plmr-color('brand', 'primary');
      bottom: 0;
      content: '...';
      display: none;
      padding-bottom: 4px;
      //padding-right: 7px;
      position: absolute;
      right: 0;
      width: 23px;

      // @include plmr-mq(md) {
      //   display: block;
      // }
    }
  }

  //.plmr-c-cardlist-category-collection__button
  @include element('button') {
    padding-top: 25px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }

    //.plmr-c-cardlist-category-collection__button--on-slide
    @include modifier('on-slide') {
      color: plmr-color('text', 'primary');
      padding-top: 15px;

      @include plmr-mq(md) {
        padding-top: 15px;
      }
    }
  }

  //.plmr-c-cardlist-category-collection__slider
  @include element('slider') {
    padding-top: 30px;
    position: relative;
    z-index: 2;

    @include plmr-mq(md) {
      flex: 0 0 auto;
      max-width: calc(100% - (293 / 743 * 100%));
      padding-top: 0;
    }

    @include plmr-mq(lg) {
      max-width: calc(100% - (342 / 1260 * 100%));

      .no-js & {
        min-width: calc(100% - (342 / 1260 * 100%));
      }
    }
  }

  //.plmr-c-cardlist-category-collection__slide
  @include element('slide') {
    padding: 0 25px;

    &:not(:first-child) {
      display: none;
    }

    .slick-initialized & {
      display: block;
    }

    @include plmr-mq(md) {
      padding: 0 30px 5px;
    }

    @include plmr-mq(lg) {
      padding-left: 1.209%; //55px;
      padding-right: 0;

      .no-js & {
        width: 50%;
      }

      &.slick-current,
      &.slick-active {
        @include plmr-mq(lg) {
          .plmr-c-cardlist-category-collection__title--on-slide,
          .plmr-c-cardlist-category-collection__slide-knockout--src,
          .plmr-c-cardlist-category-collection__slide-image--src {
            opacity: 1;
          }
        }
      }
    }

    //.plmr-c-cardlist-category-collection__slide._faded
    @include plmr-state('faded') {
      opacity: 0;
    }
  }

  //.plmr-c-cardlist-category-collection__images
  @include element('images') {
    height: auto;
    margin-bottom: -20px;
    padding-top: calc(259 / 325 * 100%);
    position: relative;
    width: 100%;
    z-index: 1;

    @include plmr-mq(md) {
      margin-bottom: -55px;
      padding-top: calc(363 / 390 * 100%);
    }
  }

  //.plmr-c-cardlist-category-collection__slide-image
  @include element('slide-image') {
    background: plmr-color('background', 'secondary');
    display: block;
    height: calc(100% - 41px);
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: calc(100% - 30px);

    @include plmr-mq(md) {
      height: calc(100% - 75px);
      width: 100%;
    }

    //.plmr-c-cardlist-category-collection__slide-image--src
    @include modifier('src') {
      display: block;
      height: auto;
      left: 50%;
      max-height: 100%;
      min-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.5s ease;
      width: auto;

      @include plmr-mq(lg) {
        opacity: 0;
        transition: opacity 1.5s ease 0.25s, transform 0.5s ease;

        .no-js & {
          opacity: 1;
        }
      }

      //.plmr-c-cardlist-category-collection__slide-image--src:hover
      &:hover {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }

  //.plmr-c-cardlist-category-collection__slide-knockout
  @include element('slide-knockout') {
    background: plmr-color('base', 'white');
    bottom: 0;
    box-shadow: -6px 6px 13px 0 rgba(plmr-color('base', 'black'), 0.15);
    display: block;
    height: auto;
    min-height: calc(128 / 259 * 100%);
    min-width: calc(95 / 325 * 100%);
    overflow: hidden;
    position: absolute;
    right: 0;
    width: auto;
    z-index: 1;

    @include plmr-mq(md) {
      min-height: calc(150 / 363 * 100%);
      min-width: calc(110 / 390 * 100%);
      right: 25px;
    }

    @include plmr-mq(xl) {
      min-height: calc(150 / 375 * 100%);
      min-width: calc(110 / 404 * 100%);
    }

    //.plmr-c-cardlist-category-collection__slide-knockout--src
    @include modifier('src') {
      @extend .plmr-u-img--responsive;
      left: 0;
      padding: 0 7px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @include plmr-mq(lg) {
        opacity: 0;
        transition: opacity 1.5s ease 1.5s;

        .no-js & {
          opacity: 1;
        }
      }
    }
  }

  //.plmr-c-cardlist-category-collection__slide-content
  @include element('slide-content') {
    padding-right: calc(115 / 325 * 100%);
    position: relative;
    z-index: 2;

    @include plmr-mq(md) {
      padding-right: calc(130 / 390 * 100%);
    }

    @include plmr-mq(lg) {
      padding-right: calc(155 / 404 * 100%);
    }
  }
}
