/*
Banners

All banner styles.
Banners background is added on inline-style that will be added dynamically from the backend.

Banner class is extended by adding these modifiers:
- banner--cta
- banner--quote
- banner--split
- banner--split-logo
- banner--text-center
- banner--text-left
- banner--text-only
- banner--text-right

For the text-box properties use the text-box classes:
[Molecules > Text Box](http://localhost:3000/section/3.4)

Styleguide 4.6
*/

/*
Variant 1

White box with a product bar, box left.

Markup:
<div class="banner banner--text-left banner--cta banner--font-proxima" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="col col-md-6 col-sm-12 banner__inner-left">
            <div class="text-box text-box--border">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated <a href="#">fixtures</a> for your bathroom.</div>
                    <a href="" class="button button--secondary">Featuring the <b>Wynford Faucet</b></a>
                    <a href="#" class="link link--icon--left">
                       <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Read More
                    </a>
                </div>
            </div>
        </div>
        <div class="col col-md-6 col-sm-12 banner__inner-right">
            <a href="" class="button button--banner">Featuring the <b>Wynford Faucet</b></a>
        </div>
    </div>
</div>

Styleguide 4.6.1
*/

/*
Variant 2

White box with a product bar, box right.

Markup:
<div class="banner banner--text-right banner--cta banner--font-proxima" style="background-color:#eee;">
  <div class="banner__image-bg">
    <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
  </div>
  <div class="banner__inner row">
     <div class="col col-md-6 col-sm-12 banner__inner-left">
       <a href="#" class="button button--banner">Featuring the <b>Wynford Faucet</b></a>
     </div>
     <div class="col col-md-6 col-sm-12 banner__inner-right">
       <div class="text-box text-box--border">
         <div class="text-box__inner">
           <div class="text-box__head">Bathroom Lighting</div>
           <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
         </div>
       </div>
     </div>
    </div>
 </div>

Styleguide 4.6.2
*/

/*
Variant 3

White box with CTA button and gray text.

Markup:
<div class="banner banner--text-left banner--font-proxima" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="col col-md-6 col-sm-12 banner__inner-left">
            <div class="text-box text-box--title-gray">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
        <div class="col col-md-6 col-sm-12 banner__inner-right">
        </div>
    </div>
</div>

Styleguide 4.6.3
*/

/*
Variant 4

White box with CTA button and logo on the top.

Markup:
<div class="banner banner--text-left banner--font-proxima" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="col col-md-6 col-sm-12 banner__inner-left">
            <div class="text-box text-box--logo-top">
                <div class="text-box__inner">
                    <img src="http://placehold.it/350x50?text=Moen logo text">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
        <div class="col col-md-6 col-sm-12 banner__inner-right">
        </div>
    </div>
</div>

Styleguide 4.6.4
*/

/*
Variant 5

Image Only

Markup:
<div class="banner">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
</div>

Styleguide 4.6.5
*/

/*
Variant 6

No box, text left aligned.
Use the text-box modifier to add shadow: text-box--text-shadow

Markup:
<div class="banner banner--text-left banner--font-proxima" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="col col-md-6 col-sm-12 banner__inner-left">
            <div class="text-box text-box--no-bg text-box--text-gray">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__subtitle">Subtitle</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
        <div class="col col-md-6 col-sm-12 banner__inner-right">
        </div>
    </div>
</div>
<br>
<div class="banner banner--text-left banner--font-proxima" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="col col-md-6 col-sm-12 banner__inner-left">
            <div class="text-box text-box--no-bg text-box--text-white text-box--text-shadow">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__subtitle">Subtitle</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
        <div class="col col-md-6 col-sm-12 banner__inner-right">
        </div>
    </div>
</div>

Styleguide 4.6.6
*/

/*
Variant 7

No Box, text center aligned

Markup:
<div class="banner banner--text-center banner--font-proxima" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="col col-sm-6 col-sm-push-3">
            <div class="text-box text-box--no-bg text-box--text-white text-box--text-shadow">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.7
*/

/*
Variant 8

No Box, text right

Markup:
<div class="banner banner--text-right banner--font-proxima" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="banner__inner-left col col-md-6 col-sm-12"></div>
        <div class="banner__inner-right col col-md-6 col-sm-12">
            <div class="text-box text-box--no-bg text-box--text-gray">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.8
*/

/*
Variant 9

No Box, text left

Markup:
<div class="banner banner--text-left banner--font-proxima" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="banner__inner-left col col-md-6 col-sm-12">
            <div class="text-box text-box--no-bg text-box--text-white text-box--logo-top">
                <div class="text-box__inner">
                    <img src="http://placehold.it/350x50?text=Moen logo text">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
        <div class="banner__inner-right col col-md-6 col-sm-12">
        </div>
    </div>
</div>

Styleguide 4.6.9
*/

/*
Variant 10

50/50 split with subtitle

Markup:
<div class="banner banner--split" style="background-color:#eee">
    <div class="banner__inner row">
        <div class="banner__inner-left col-sm-5">
            <div class="banner__image-bg">
                <div class="banner__subtitle">Commercial Products</div>
                <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
            </div>
        </div>
        <div class="banner__inner-right col-sm-7">
            <div class="text-box text-box--no-bg text-box--title-gray">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <a href="#" class="link link--icon--right-blue">
                        <span>Read More</span>
                        <img class="icon" src="./images/icon-arrow_point_right_blue.svg" alt="" aria-hidden="true">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.10
*/

/*
Variant 11

50/50 Logo no image, gray

Markup:
<div class="banner banner--split-logo" style="background-color:#eee">
    <div class="banner__inner">
        <div class="banner__inner-left">
            <div class="banner__image-bg--logo">
                <img src="http://placehold.it/350x50?text=Moen logo text">
            </div>
        </div>
        <div class="banner__inner-right">
            <div class="text-box text-box--no-bg">
                <div class="text-box__inner">
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.<a href="#" class="link link--icon--left "><img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Read More</a></div>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.11
*/

/*
Variant 12

50/50 Logo no image, dark gray

Markup:
<div class="banner banner--split-logo" style="background-color:#999">
    <div class="banner__inner">
        <div class="banner__inner-left">
            <div class="banner__image-bg--logo">
                <img src="http://placehold.it/350x50?text=Moen logo text">
            </div>
        </div>
        <div class="banner__inner-right">
            <div class="text-box text-box--text-white text-box--no-bg text-box--white">
                <div class="text-box__inner">
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.<a href="#" class="arrowlink arrowlink--white">Read More</a></div>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.12
*/

/*
Variant 13

Text and blue background

Markup:
<div class="banner banner--text-only" style="background-color:#00a9e0">
    <div class="banner__inner row">
        <div class="col col-md-12">
            <div class="text-box text-box--text-white text-box--no-bg">
                <div class="text-box__inner">
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom. Brighten any style with easy-to-install, beautifully coordinated fixturesfor your bathroom.</div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div class="banner banner--text-only" style="background-color:#00a9e0">
    <div class="banner__inner row">
        <div class="col col-md-12">
            <div class="text-box text-box--text-white text-box--no-bg">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.13
*/

/*
Variant 14

Text and blue background

Markup:
<div class="banner banner--text-only" style="background-color:#999">
    <div class="banner__inner row">
        <div class="col col-md-12">
            <div class="text-box text-box--text-white text-box--no-bg">
                <div class="text-box__inner">
                    <div class="text-box__subtitle">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div class="banner banner--text-only" style="background-color:#999">
    <div class="banner__inner row">
        <div class="col col-md-12">
            <div class="text-box text-box--text-white text-box--no-bg">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.14
*/

/*
Variant 15

Quote and background image

Markup:
<div class="banner banner--quote" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="col col-md-8 col-md-push-2">
            <div class="text-box text-box--no-bg text-box--text-blue">
                <div class="text-box__inner">
                    <blockquote class="text-box__quote">
                        <p>Brighten any style with easy-to-install, beautifully coordinated <a href="#">fixtures</a> for your bathroom.</p>
                    </blockquote>
                    <div class="text-box__body text-box--gray">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.15
*/

/*
Banner bg-image

Banner with a non-repeating background image. Background is positioned in the center of the banner container.

Markup:
<div class="banner has-bg-image" style="background-color:#eee; background-image: url('./images/styleguide/media-contact_background.png');">
    <div class="banner__image-bg"></div>
        <div class="banner__inner row">
        <div class="col col-md-8 col-md-push-2">
            <div class="text-box text-box--no-bg text-box--text-blue">
                <div class="text-box__inner">
                    <blockquote class="text-box__quote">
                        <p>Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit Sed Do Eiusmod Tempor Incididu</p>
                    </blockquote>
                    <div class="text-box__body text-box--gray">Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit Sed Do Eiusmod Tempor Incididunt Ut</div>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.20
*/

/*
Banner bg-image (repeating)

Banner with a repeating background image.

Markup:
<div class="banner has-bg-image has-bg-image-repeat" style="background-image: url('./images/styleguide/repeating_pattern.jpg');">
    <div class="banner__image-bg"></div>
    <div class="banner__inner row">
        <div class="col col-md-8 col-md-push-2">
            <div class="text-box text-box--no-bg text-box--text-blue">
                <div class="text-box__inner">
                    <blockquote class="text-box__quote">
                        <p>Brighten any style with easy-to-install, beautifully coordinated <a href="#">fixtures</a> for your bathroom.</p>
                    </blockquote>
                    <div class="text-box__body text-box--gray">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.20.1
*/

/*
Variant 16: 'Buy It For Product Left'

Markup:
<div class="banner banner--text-left banner--font-proxima" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="//embed.widencdn.net/img/moen/j6g7ndzcxr/1400px@1x/bathroom.jpg?q=100">
    </div>
    <div class="banner__inner row">
        <div class="banner__inner-left col col-md-6 col-sm-12">
            <div class="text-box text-box--no-bg text-box--text-white">
                <div class="text-box__inner">
                    <div class="text-box__head">buy it for</div>
                    <div class="text-box__subtitle">attraction</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
        <div class="banner__inner-right col col-md-6 col-sm-12">
        </div>
    </div>
</div>

Styleguide 4.6.21
*/

/*
Variant 17: 'Buy It For Product Right'

Markup:
<div class="banner banner--text-right banner--font-proxima" style="background-color:#eee">
    <div class="banner__image-bg">
        <img src="//embed.widencdn.net/img/moen/j6g7ndzcxr/1400px@1x/bathroom.jpg?q=100">
    </div>
    <div class="banner__inner row">
        <div class="banner__inner-left col col-md-6 col-sm-12">
        </div>
        <div class="banner__inner-right col col-md-6 col-sm-12">
            <div class="text-box text-box--no-bg text-box--text-white">
                <div class="text-box__inner">
                    <div class="text-box__head">buy it for</div>
                    <div class="text-box__subtitle">attraction</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.22
*/

/*
Variant 18

Box Centered

Markup:
<div class="banner banner--box-centered">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="col col-sm-6 col-sm-push-3">
            <div class="text-box text-box--no-bg text-box--text-gray">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div class="banner banner--box-centered banner--text-bg-white">
    <div class="banner__image-bg">
        <img src="http://www.moen.com/assets/moencom/images/catalog/bathroom/lighting/hero.jpg">
    </div>
    <div class="banner__inner row">
        <div class="col col-sm-6 col-sm-push-3">
            <div class="text-box text-box--no-bg text-box--text-gray">
                <div class="text-box__inner">
                    <div class="text-box__head">Bathroom Lighting</div>
                    <div class="text-box__body">Brighten any style with easy-to-install, beautifully coordinated fixtures for your bathroom.</div>
                    <button class="button button--secondary">Secondary Button</button>
                </div>
            </div>
        </div>
    </div>
</div>

Styleguide 4.6.23
*/

@mixin text-left-right() {
  .text-box__body {
    margin-bottom: 0;
  }
  .text-box {
    width: 100%;
    .text-box__head {
      font-size: em(26px);
    }
    .text-box__subtitle {
      font-size: em(22px);
    }
    .button,
    .link {
      font-size: em(14px);
    }
    @include media($bp-md) {
      width: 70%;
    }

    @include media($bp-lg) {
      .text-box__head {
        font-size: 2.8em;
      }
      .button,
      .link {
        font-size: em(16px);
      }
    }
  }
}

@include block(banner) {
  font-size: 1rem;
  overflow: hidden;
  position: relative;
  z-index: z("banners");

  .col {
    height: 100%;
  }

  &.has-bg-image {
    min-height: 400px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100%;
  }

  &.has-bg-image-repeat {
    background-repeat: repeat;
    background-position: 0 0;
    background-size: auto;
  }

  .banner__inner {
    .button {
      width: 100%;
      @include media($bp-md) {
        width: auto;
      }
    }
  }

  @include element(inner) {
    @include outer-container();
    height: 100%;
  }

  @include element(image-bg) {
    text-align: center;
    position: relative;
    & > img {
      height: auto;
      width: 100%;
    }
    @include modifier(logo) {
      padding: 1rem 0;
    }
    @include media($bp-md) {
      min-height: 250px;
      z-index: 0;
      
      & + .banner__inner {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        z-index: 1;
      }
      
      & > img {
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 450px;
      }
    }
  }

  @include element(subtitle) {
    color: $c_white;
    font-size: 2rem;
    left: 0;
    padding: .5rem;
    position: absolute;
    top: 0rem;
    text-align: left;
    width: 100%;
  }

  @include modifier(text-left) {
    .banner__inner-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include text-left-right();
    }
  }

  @include modifier(text-right) {
    .banner__inner {
      transform: scaleY(-1);
      & > * {
        transform: scaleY(-1);
      }
      @include media($bp-md) {
        transform: translateX(-50%) scaleY(-1);
        & > * {
          transform: scaleY(-1);
        }
      }
    }
    .banner__inner-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include text-left-right();
    }
  }

  @include modifier(text-center) {
    .text-box {
      margin-top: 0;
    }
    .text-box__inner {
      text-align: center;
    }
    @include media($bp-sm) {
      .text-box {
        margin-top: 1.5rem;
      }
    }
  }

  @include modifier(split) {
    .banner__inner {
      font-size: 1rem;
      margin-top: 2em;
      margin-bottom: 2em;
      
      .banner__inner-right {
        margin-left: 1rem;
        margin-right: 1rem;
        
        @include media($bp-sm) {
          margin-left: 0;
          margin-right: 0;
        }
      }
      
    }
    .banner__image-bg {
      padding: 0;
      img {
        height: auto;
        width: 100%;
      }
      @include media($bp-md) {
        img {
          max-width: 100%;
        }
      }
    }    

    .text-box__inner {
      padding: 1rem 1rem 1rem 0;
    }
    .text-box__body {
      font-size: rem(14px);
      display: inline;
      & > * {
        display: inline;
        font-size: rem(14px);
      }
    }
    .text-box__head,
    .banner__subtitle {
      font-size: em(22);
      font-weight: normal;
      a {
        color: $c_white;
        text-decoration: none;
        &:hover {
            border-bottom: 2px solid $c_moen-bright-blue;
        }
      }
    }
    .link,
    .button {
        margin: 0;
    }
    .link {
      /* display: inline; */
      display: block;
      text-decoration: none;
      padding-right: 0;
      .icon,
      span {
        display:inline-block;
        vertical-align: baseline;
      }
      .icon {
          margin-left: .25em;
          position: relative;
          top: 0;
          transform: translateY(0%);
      }
    }
  }

  @include modifier(split-logo) {
    .banner__inner {
      @include centerer();
      flex-direction: column;
      @include media($bp-md) {
        flex-direction: row;
      }
    }
    .banner__image-bg--logo {
      padding: 0;
      text-align: center;
      img {
        height: auto;
        width: 100%;
      }
      @include media($bp-md) {
        text-align: right;
        img {
          max-width: 100%;
        }
      }
    }
    .text-box__inner {
      padding: 1rem;
    }
    .link {
      margin-bottom: 0;
    }
    .banner__inner-left,
    .banner__inner-right {
      display: block;
      width: 100%;
    }
    .banner__inner-left {
      width: 100%;
    }
    .banner__inner-right {
      margin-top: 1rem;
      @include media($bp-md) {
        margin-top: 0;
      }
    }
    @include media($bp-md) {
      .banner__inner-left,
      .banner__inner-right {
        padding: 1rem;
        .text-box__inner > *:last-child {
          margin-bottom: 0;
        }
      }
      .banner__inner-left {
        width: 40%;
        img {
          max-width: 100%;
        }
      }
      .banner__inner-right {
        width: 60%;
      }
    }
  }

  @include modifier(text-only) {
    text-align: center;
    .row,
    .banner__inner-left,
    .banner__inner-right {
      @include centerer();
    }
    .text-box__body {
      line-height: initial;
      min-height: auto;
      margin: 1rem 0 0 0;
    }
    .text-box__inner {
      .link,
      .button {
        margin-bottom: 0;
      }
    }
  }

  @include modifier(quote) {
    .col {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text-box__inner {
      text-align: center;
    }
    .text-box__quote {
      margin-bottom: 1em;
    }
  }

  @include modifier(cta) {
    &.banner--text-left .banner__inner-right {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    &.banner--text-right .banner__inner-left {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }

  @include modifier(box-centered) {
    .text-box__inner {
        background-color: $c_white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 1em;
        width: 80%;
        .text-box__head {
            font-size: 1.8em;
        }
    }
    
    .banner__inner {
        background-color: $c_moen-light-gray;
        height: 320px;
    }

    .banner__image-bg {
        img {
            display: none;
        }
    }

    @include media($bp-md) {
        .text-box__inner {
            background-color: transparent;
            width: inherit;
            .text-box__head {
                font-size: 2.8em;
            }
        }
        .banner__image-bg {
            img {
                display: block;
                margin: 0 auto;
            }
        }

        .banner__inner {
            background-color: transparent;
            height: 100%;
        }
    }
  }

  @include modifier(text-bg-white) {
    .text-box__inner {
        @include media($bp-md) {
          background-color: rgba($c_white, .7);
        }
    }
  }

  @include modifier(font-proxima) {
    .text-box__head {
      font-family: $proxima-bold;
      margin-bottom: .25em;
    }
    .text-box__subtitle {
      font-family: $proxima-thin;
    }
    .text-box__body {
      font-family: $proxima-regular
    }
    .section-rule {
      margin-right: 2em;
      width: auto;
    }
  }


  .text-box--text-white {

    @include media($bp-md) {
      background: rgba($c_moen-gray, 0.5);
    }

    .text-box__head,
    .text-box__subtitle,
    .text-box__body {
      color: $c_text-base !important;

      @include media($bp-md) {
        color: $c_white !important;
      }
    }
  }
  
  @include modifier(callout){
    height: 300px;
    @include media($bp-md) {
      height: 400px;
    }
    .banner__image-bg {
        position: absolute;
        top:0%;
        left:0;
        transform: translate(-20px,0);
        min-width: 100%;
        overflow: hidden;
        min-height: 300px;
        @include media($bp-sm) {
          &.mobile {
            display: block;
          }
          &.tablet {
            display: none;
          }
          &.desktop {
            display: none;
          }
        }
        @include media($bp-md) {
          left:50%;
          transform: translate(-50%,0%);
          &.mobile {
            display: none;
          }
          &.tablet {
            display: block;
          }
          &.desktop {
            display: none;
          }
        }
        @include media($bp-lg) {
          &.mobile {
            display: none;
          }
          &.tablet {
            display: none;
          }
          &.desktop {
            display: block;
          }
        }
        img {
            display: block;
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            max-width: none;
            max-height: none;
        }
    }
    .banner__inner {
      .text-box__inner {
        background: rgba($c_white,0.85);
        padding: 20px;
        color: $c_mid-gray;
        @include media($bp-md) {
          padding: 38px 30px;
          min-width: 420px;
        }
      }
      .button {
        width: auto;
        margin-top: auto;
      }
    }
  }
  
}




/// .banner-hero
@include block('banner-hero') {
  margin: 0 0 50px 0;
  min-width: $min-width;
  
  
  /// .banner-hero__image-container
  @include child('image-container') {
    margin-bottom: 20px;
    
    /// screen width > 768px
    @include media($bp-md) {
      margin-bottom: 15px;
      
    }
  }
  
  
  /// .banner-hero__image
  @include child('image') {
    display: block;
    height: auto;
    width: 100%;
  }
  
  
  /// .banner-hero__cta
  @include child('cta') {
    display: none;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      display: block;
      font-size: 12px;
      margin-bottom: 20px;
      padding:0 25px;
    }
    
    /// .banner-hero--cta-left .banner-hero__cta
    .banner-hero--cta-left & {
      text-align: left;
    }
    
    
    /// .banner-hero--cta-right .banner-hero__cta
    .banner-hero--cta-right & {
      text-align: right;
    }
    
  }
  
  /// .banner-hero__content
  @include child('content') {
    margin: 0 auto;
    max-width: $max-width;
    padding: 0 20px;
  }
  
  
  /// .banner-hero__title
  @include child('title') {
    font-family: $din-light;
    font-size: 30px;
    font-weight: 400;    
    letter-spacing: -0.05em;
    line-height: 1.1;
    margin-bottom: 0.25em;
    text-align: center;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      font-size: 35px;
    }
    
  }
  
  
  /// .banner-hero__subtitle
  @include child('subtitle') {
    font-family: $din-light;
    font-size: 20px;
    margin-bottom: 0.5em;
    text-align: center;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      font-size: 25px;
    }
    
  }
  
  
  /// .banner-hero__description
  @include child('description') {
    color: inherit;
    font-family: $din-regular;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    
    
    /// screen width > 768px
    @include media($bp-md) {    
      font-size: 18px;
      margin: 0 auto;
      max-width: 70%;
    }
    
  }
  
}



/// .banner-article
@include block('banner-article') {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto 30px auto;
  max-width: $max-width;
  min-width: $min-width;
  width: 100%;
  
  
  /// .banner-article--content-right
  @include modifier('content-right') {
    
    
    /// screen width > 768px
    @include media($bp-md) {
      flex-direction: row;
    }
      
  }
  
  
  /// .banner-article--content-left
  @include modifier('content-left') {
    
    
    /// screen width > 768px
    @include media($bp-md) {
      flex-direction: row-reverse;
    }
      
  }
  
  
  /// .banner-article__image-container
  @include child('image-container') {
    align-self: auto;
    background-position: center center;
    background-size: cover;
    flex: 0 0 100%;
    min-height: 200px;
    order: 0;
    position: relative;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      flex: 0 0 60%;        
    }
    
  }
  
  
  /// .banner-article__image-link
  @include child('image-link') {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  
  /// .banner-article__image
  @include child('image') {
    display: block;
    height: auto;
    width: 100%;
  }
  
  
  /// .banner-article__content
  @include child('content') {
    align-self: auto;
    flex: 0 1 100%;
    order: 0;
    padding: 20px 25px;
    text-align: center;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      flex: 0 1 40%; 
      padding: 25px; 
      text-align: left;
    }
    
  }
  
  
  /// .banner-article__title
  @include child('title') {
    font-family: $din-light;
    font-size: 30px;
    font-weight: 400;    
    letter-spacing: -0.05em;
    line-height: 1.1;
    margin-bottom: 0.25em;    
  }
  
  
  /// .banner-article__subtitle
  @include child('subtitle') {
    font-family: $din-light;
    font-size: 20px;
    margin-bottom: 0.5em;
  }
    
  
  /// .banner-hero__description
  @include child('description') {
    color: inherit;
    font-family: $din-regular;
    font-size: 16px;
    line-height: 1.5;
    
    
    /// screen width > 768px
    @include media($bp-md) {    
      font-size: 18px;
    }
    
  }
  
  
  /// .banner-article__link
  @include child('link') {
    
  }
  
}
