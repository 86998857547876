/**
Can be used in various areas across the site including PDPs. Also see Featured Product Showcase component.
@section Product Info Condensed
@childof Content
@page Content Product Info Condensed
@status in progress
@example
_markup/product-info-condensed.hbs
*/
@include plmr-component('product-info-condensed') {
  color: plmr-color('brand', 'primary');

  // .plmr-c-product-info-condensed__buttons
  @include element('buttons') {
    margin: -10px -20px;
    padding-top: 30px;
  }

  // .plmr-c-product-info-condensed__button
  @include element('button') {
    margin: 10px 20px;
  }

  // .plmr-c-product-info-condensed__copy
  @include element('copy') {
    color: plmr-color('text', 'primary');
    display: none;

    @include plmr-mq(md) {
      display: block;
      font-size: 1.143rem;
      line-height: 2rem;
      padding-top: 15px;
    }
  }

  // .plmr-c-product-info-condensed__title
  @include element('title') {
    // @include plmr-font-face($family: 'light');
    font-size: 1.286rem;
    line-height: 1.714rem;
    margin: 0;
    text-transform: capitalize;

    // .plmr-c-product-info-condensed__title--collection
    @include modifier('collection') {
      // @include plmr-font-face($family: 'bold');
    }

    @include plmr-mq(md) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  // .plmr-c-product-info-condensed__review
  @include element('review') {
    font-size: 1rem;
    line-height: 1.714rem;
    padding-top: 20px;
  }

  // .plmr-c-product-info-condensed__review-item
  @include element('review-item') {
    @include plmr-font-face($family: 'italic');


    & ~ & {
      margin-left: 10px;
    }

    // .plmr-c-product-info-condensed__review-item--stars

    // .plmr-c-product-info-condensed__review-item--count

    // .plmr-c-product-info-condensed__review-item--link
    @include modifier('link') {
      @include plmr-link($color: plmr-color('brand', 'secondary'), $is-color-hover: true, $underline: false);
    }

    // .plmr-c-product-info-condensed__review-item--anchor
    @include modifier('anchor') {
      @include plmr-font-face($family: 'regular');
      @include plmr-link($underline: true);
    }
  }

  // .plmr-c-product-info-condensed__star
  @include element('star') {
    height: 18px;
    width: 15px;
  }

  //.plmr-c-product-info-condensed__msrp
  @include element('msrp') {
    color: darken(plmr-color('text', 'secondary'), 8%);
    font-size: 1.143rem;
    line-height: 2rem;
    padding-bottom: 0;
    padding-top: 15px;
  }

  //.plmr-c-product-info-condensed__model
  @include element('model') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'secondary');
    font-size: 1rem;
    line-height: 1.285rem;
    padding-bottom: 10px;

    @include plmr-mq('md') {
      font-size: 1.143rem;
      line-height: 1.5rem;
      padding-bottom: 25px;
    }
  }

  //.plmr-c-product-info-condensed__list
  @include element('list') {
    font-size: 1rem;
    line-height: 1.285rem;
    list-style-type: none;
    margin: 15px 0 25px;
  }

  //.plmr-c-product-info-condensed__list-item
  @include element('list-item') {
    padding-left: 1.5rem;

    &:not(:first-child) {
      margin-top: 10px;
    }

    //.plmr-c-product-info-condensed__list-item::before
    &::before {
      color: plmr-color('brand', 'primary');
      font-size: 0.875rem;
    }
  }

  //.plmr-c-product-info-condensed__buy-button
  @include element('buy-button') {
    margin-top: 15px;
  }
}
