// .innovations
@include block(innovations) {
	
	// .innovations__hero
	@include element(hero) {
		display: block;
		text-align: center;
		position: relative;
		z-index: 1;
		@include media($bp-md) {
			text-align: left;
// 			margin-top: 60px;
		}
		@include media($bp-lg) {
// 			margin-top: 75px;
		}
		@include media($bp-xl) {
// 			margin-top: 50px;
		}
				
		// .innovations__hero .container
		.container {
			min-width: 0px;
		}
		
		
		/// .innovations__hero__disclaimer
		@include child('disclaimer') {
			margin-top: 20px;
		}
		
		// .innovations__hero .row
		.row {
			@include media($bp-md) {
// 				min-height: 535px;
				min-height: 565px;
			}
			@include media($bp-lg) {
// 				min-height: 670px;
// 				min-height: 745px;
// 				height: 745px;
					height: 585px;
// 				max-height: calc(100vh - 71px);
				max-height: 100vh;
			}
			@include media($bp-xl) {
// 				min-height: 765px;
// 				min-height: 815px;
// 				height: 815px;
		    
			}
		}
		
		// .innovations__hero .left
		.left {
			padding: 50px 25px 40px;
			font: normal 16px/24px $proxima-regular;
			color: $c_moen-gray;
			@include media($bp-md) {
				width: 50%;
				max-width: 500px;
				position: absolute;
				top:0;
				left:0;
				z-index: 2;
				background: linear-gradient(to right, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%);
				padding: 0 45px 0 40px;
				height: 100%;
				font: normal 18px/28px $proxima-regular;
				.flexwrap & {
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
					align-items: flex-start;
					align-content: center;
					justify-content: center;
					* {
						flex: none;
						max-width: 380px;
					}
				}
			}
			@include media($bp-lg) {
				font: normal 21px/32px $proxima-regular;
				padding-right: 145px;
		    width: calc(50% + 100px);
		    max-width: 600px;
			}
			p,h1,h2,h3 {
				margin:0;
				padding-bottom: 30px;
				font: inherit;
				@include media($bp-md) {
					padding-bottom: 40px;
				}
				@include media(1025px) {
					padding-bottom: 0px;
				}
			}
			.heading--1 {
				font: normal 30px/36px $proxima-thin;
				color: $c_text-base;
        letter-spacing: 0;
				margin:0;
				padding-bottom: 20px;
				strong {
					font-weight: inherit;
					font-family: inherit;
					color: #444;
				}
				@include media($bp-md) {
// 					font: normal 52px/58px $proxima-thin;
					font: normal 52px/54px $proxima-thin;
          letter-spacing: 0;
					padding-bottom: 30px;
				}
// 				@include media($bp-lg) {
// 					font: normal 64px/68px $proxima-thin;
//           letter-spacing: 0;
// 				}
			}
			.subheading {
				font-size: 85%;
				line-height: 1.7777777778em;
				font-family: $proxima-bold;
				padding-bottom: 15px !important;
			}
			.js-modal {
				font: normal 16px/1em $din-bold;
				color: $c_moen-bright-blue;
				text-decoration: none;
				@include media(1025px) {
					display: none;
				}
				img {
					display: inline-block;
					width: 27px;
					height: 27px;
					vertical-align: middle;
					margin-left: 15px;
				}
				span {
					position: relative;
					display: inline-block;
					&:after {
						content: ' ';
						display: block;
						width: 100%;
						height: 3px;
						background-color: $c_moen-bright-blue;
						position: absolute;
						bottom: -8px;
						left:0;
						opacity: 0;
						transition: 0.5s ease;
					}
				}
				&:hover span:after {
					opacity: 1;
				}
			}
			
		}
		
		// .innovations__hero .right
		.right {
			width: 100%;
			height: 280px;
			position: relative;
			flex: none;
			overflow: hidden;
			@include media($bp-md) {
				position: absolute;
				top:0;
				right:0;
				width: 100%;
				height: 100%;
				z-index: 1;
				&:after {
					content: ' ';
					display: block;
					z-index: 2;
					pointer-events: none;
					position: absolute;
					top:0;
					right: 0px;
					width: 150px;
					height: 100%;
					background-image: linear-gradient(90deg, rgba(255,255,255,0.00) 0%, rgba(255,255,255,1) 95%);
					opacity: 0;
					transition: opacity 0.5s ease;
					@include media($max-width) {
						opacity: 1;
					}
				}
			}
			
			// .innovations__hero .right .img
			.img {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 1;
				picture,img,video {
					position: absolute;
					top:0;
					right:50%;
					height: 100%;
					width: auto;
					z-index: 1;
					transform: translateX(50%);
					@include media($bp-md) {
						right:-1px;
						transform: none;
					}
				}
				video {
					display: none;
					@include media(1025px) {
						display: block;
					}
				}
				picture,img {
					display: block;
					@include media(1025px) {
						display: none;
					}
				}
			}
		}
		
		// .innovations__hero--overview
		@include modifier("overview") {
			position: relative;
			@include media($bp-md){
				&:after{
					content: '';
					position: absolute;
					left: 50%;
					//bottom: 0;
					height: 1px;
					border-bottom: 1px solid $c_moen_bright_gray;
					width: 92%;
					transform: translateX(-50%);
				}

			}
			
			@include media($bp-xl){
				&:after {
					width: 93.7%;
				}
			}
			.row {
				@include media($bp-md) {
	// 				min-height: 535px;
					min-height: 380px;
				}
				@include media($bp-lg) {
	// 				min-height: 670px;
	// 				min-height: 745px;
					height: 420px;
	// 				max-height: calc(100vh - 71px);
					max-height: 100vh;
				}
				@include media($bp-xl) {
	// 				min-height: 765px;
	// 				min-height: 815px;
					height: 450px;
			    
				}
			}
			.left {
				position: relative;
			    margin-bottom: -200px;
			    z-index: 2;
			    padding-bottom: 0;
			    padding-top: 0;
			    top: 50px;
			    
			    .heading--1 {
				    @include media($bp-lg) {
						font-size: 48px;
						line-height: 52px;
					}
			    }
			}
			.right {
				height: 480px;

				.img {
					picture, img {
						@include media(1025px){
							display: block;
						}
					}
				}
			}
		}

		// .innovations__hero__video-replay
		@include child(video-replay) {
			display: block;
// 			position: absolute;
// 			right: 60px;
// 			bottom:40px;
			font: normal 16px/1em $din-bold;
			color: #969696;
			z-index: 3;
			text-decoration: none !important;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.5s ease;
			display: none;
			margin-top: 40px;
			@include media(1025px) {
				display: block;
			}
			
			// .innovations__hero__video-replay img
			img {
				display: inline-block;
				vertical-align: middle;
				margin-left: 15px;
			}
			
			// .innovations__hero__video-replay.show
			&.show {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
	
	
	// "Campaign" variant of hero used on the overview page 
	// .innovations__hero-campaign 
	@include element(hero-campaign) {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 114px;
		@include media($bp-md) {
			height: 273px;
		}
		@include media(1025px) {
			height: 450px;
		}
		picture,img {
			display: block;
			position: absolute;
			top:50%;
			left:50%;
			transform: translate(-50%,-50%);
			min-height: 100%;
			min-width: 100%;
		}
	}
	
}
