
/**
@doc _docs/content-area.md
@section Content Area
@childof Content
@page Content Content Area
@status in progress
@example
_markup/content-area.hbs
*/
@include plmr-component('content-area') {
  min-height: 230px;
  position: relative;

  @include plmr-mq(md) {
    min-height: 450px;
  }

  //.plmr-c-content-area::after pseudo element
  &::after {
    @include plmr-pseudo;
    background-color: plmr-color('background', 'secondary');
    bottom: 0;
    left: 0;
    min-height: calc(100% - 150px);
    min-width: 100%;
    // z-index: 1;

    @include plmr-mq(md) {
      min-height: calc(100% - 315px);
    }
  }

  //.plmr-c-content-area__accent
  @include element('accent') {
    left: 0;
    min-height: 150px;
    min-width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;

    @include plmr-mq(md) {
      min-height: 315px;
      min-width: calc(100% - 55px);
    }

    @include plmr-mq(lg) {
      min-width: calc(100% - 91px);
    }

    @include plmr-mq(max) {
      margin-left: calc((100vw - 1300px) / 2 * -1);
      min-width: calc((100% - 91px) + ((100vw - 1300px) / 2));
    }

    //.plmr-c-content-area__accent::before pseudo element
    &::before {
      @include plmr-pseudo;
      background: plmr-color('brand', 'tertiary');
      height: 50px;
      left: 50%;
      top: 0;
      transform: translate(-50%, -25px);
      width: 1px;

      @include plmr-mq(md) {
        height: 75px;
        transform: translate(-50%, -37px);
      }

      @include plmr-mq(max) {
        margin-left: calc((100vw - 1300px) / 3);
      }
    }
  }

  //.plmr-c-content-area__container
  @include element('container') {
    padding: 30px 15px 50px;
    position: relative;
    z-index: 1;

    @include plmr-mq(md) {
      padding: 60px 110px 75px 55px;
    }

    @include plmr-mq(lg) {
      padding-left: calc(142 / 1300 * 100%);
      padding-right: calc(193 / 1300 * 100%);
    }

    @include plmr-mq(max) {
      padding-left: 142px;
      padding-right: 193px;
    }
  }

  //.plmr-c-content-area__content
  @include element('content') {
    position: relative;
    z-index: 2;
  }

  //.plmr-c-content-area__title
  @include element('title') {
    @extend .plmr-c-page-title__title;
    color: plmr-color('base', 'white');
    font-size: 2rem;
    padding-bottom: 25px;
    position: relative;

    // screen width > 768px
    @include plmr-mq(md) {
      font-size: 2.571rem;
      padding-bottom: 37px;
    }
  }

  //.plmr-c-content-area__title-icon
  @include element('title-icon') {
    @extend .plmr-c-page-title__icon;
  }
}
