// .video-gallery
@include block("video-gallery") {
	display: block;
	max-width: 1080px;
	margin: 0 auto;
	overflow: hidden;
	// Full size video
	// .video-gallery__full-video
	@include element("full-video") {
		display: block;
		position: relative;
		// .video-gallery__full-video__play
		@include child("play") {
			display:block;
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			z-index: 3;
			&::before {
				content: ' ';
				display: block;
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50%,-50%);
				width: 70px;
				height: 70px;
				background-size: 100% auto;
				background-position: center center;
				background-repeat: no-repeat;
				background-image: url('//embed.widencdn.net/img/moen/7vqj3t0o8f/exact/play-icon-largest.png?q=90&u=hhyaeb');
				transition: all 0.333s ease;
			}
			&:hover::before {
				transform: scale(1.07) translate(-50%,-50%);
			}
		}
		// .video-gallery__full-video__thumb
		@include child("thumb") {
			position: relative;
			z-index: 2;
			display: block;
			width: 100%;
			height: auto;
		}
		// .video-gallery__full-video__title
		@include child("title") {
			font: normal 28px/32px $proxima-thin;
			color: $c_moen-logo-black;
			text-align: left;
		}
	}
	// Video object 
	// .video-gallery__player
	@include element("player") {
		display: block;
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	
	// List of thumbnails
	// .video-gallery__thumbs
	@include element("thumbs") {
		width: calc(100% + 24px);
		margin-left: -12px;
		display: block;
		font-size: 0;
		line-height: 0;
	}
	// Individual thumbnail
	// .video-gallery__thumb
	@include element("thumb") {
		display:inline-block;
		vertical-align: top;
		width: 50%;
		padding: 12px;
		text-decoration: none !important;
		@include media($bp-lg) {
			width:25%;
		}
		// Individual thumbnail image
		// .video-gallery__thumb__img
		@include child("img") {
			display: block;
			width: 100%;
			height: auto;
			position: relative;
			&::before {
				content: ' ';
				display: block;
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50%,-50%);
				width: 46px;
				height: 46px;
				background-size: 100% auto;
				background-position: center center;
				background-repeat: no-repeat;
				background-image: url('images/ubymoen/play-icon-largest.png');
				transition: all 0.333s ease;
			}
			img {
				width: 100%;
			}
		}
		@include child("text") {
			font: 18px/22px $proxima-regular;
			color: $c_text-base;
			padding-top: 15px;
			text-align: left;
		}
		
		@include state("active") {
			display: none;
		}
		
		&:hover {
			.video-gallery__thumb__img::before {
				transform: scale(1.07) translate(-50%,-50%);
			}
		}
	}
	
}
