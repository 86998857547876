
// Base Colors
// -----------------------------------------------------------------------------

$color-white: #fff !default;
$color-black: #000 !default;

$color-base-brand-primary: #29327c !default;
$color-base-brand-secondary: #32a9c1 !default;
$color-base-brand-tertiary: #9aca40 !default;

$color-base-text-primary: #3b3838 !default;
$color-base-text-secondary: #535a5e !default;
$color-base-text-tertiary: #2196e9 !default;

$color-base-background-primary: $color-white !default;
$color-base-background-secondary: #ededed !default;
$color-base-background-tertiary: #f5f5f5 !default;

$color-base-accent-light: #cecfd0 !default;
$color-base-accent-dark: #2f3e48 !default;

$color-base-status-error: #dd5b4d !default;
$color-base-status-confirmation: #3dba82 !default;
$color-base-status-alert: #fee852 !default;
$color-base-status-info: #2d99e6 !default;

$color-finish-brushed-nickel: #9f9685 !default;
$color-finish-matte-black: #2e2e2e !default;
$color-finish-chrome: #98999d !default;
$color-finish-bronze: #39312e !default;
$color-finish-spot-resist-stainless: #a7a69a !default;
$color-finish-polished-nickel: #978a73 !default;
$color-finish-walnut: #412d26 !default;
$color-finish-deep-blue: #343444 !default;
$color-finish-blue: #687d9c !default;
$color-finish-ice-white: #e0e0e0 !default;


// Style Guide Definitions
// -----------------------------------------------------------------------------

/**
@colordef White
@color {hex} #ffffff
@color {var} $color-white
@color {textcolor} dark
@colorof Base.Colors
*/


/**
@colordef Black
@color {hex} #000000
@color {var} $color-black
@color {textcolor} light
@colorof Base.Colors
*/


/**
@section Primary Palette
@sectionof Base.Colors
@page Brands Base
*/


/**
@colordef Primary
@color {hex} #29327c
@color {var} $color-base-brand-primary
@color {textcolor} light
@colorof Base.Colors.Primary Palette
*/


/**
@colordef Secondary
@color {hex} #32a9c1
@color {var} $color-base-brand-secondary
@color {textcolor} light
@colorof Base.Colors.Primary Palette
*/


/**
@colordef Tertiary
@color {hex} #9aca40
@color {var} $color-base-brand-tertiary
@color {textcolor} light
@colorof Base.Colors.Primary Palette
*/


/**
@section Typography Palette
@sectionof Base.Colors
@page Brands Base
*/


/**
@colordef Text Primary
@color {hex} #3b3838
@color {var} $color-base-text-primary
@color {textcolor} light
@colorof Base.Colors.Typography Palette
*/


/**
@colordef Text Secondary
@color {hex} #535a5e
@color {var} $color-base-text-secondary
@color {textcolor} light
@colorof Base.Colors.Typography Palette
*/

/**
@colordef Text Tertiary
@color {hex} #2196e9
@color {var} $color-base-text-tertiary
@color {textcolor} light
@colorof Base.Colors.Typography Palette
*/


/**
@section Background Palette
@sectionof Base.Colors
@page Brands Base
*/


/**
@colordef Background Primary
@color {hex} #ffffff
@color {var} $color-base-background-primary
@color {textcolor} dark
@colorof Base.Colors.Background Palette
*/


/**
@colordef Background Secondary
@color {hex} #ededed
@color {var} $color-base-background-secondary
@color {textcolor} dark
@colorof Base.Colors.Background Palette
*/


/**
@colordef Background Tertiary
@color {hex} #f5f5f5
@color {var} $color-base-background-tertiary
@color {textcolor} dark
@colorof Base.Colors.Background Palette
*/


/**
@section Accent Palette
@sectionof Base.Colors
@page Brands Base
*/

/**
@colordef Accent Light
@color {hex} #cecfd0
@color {var} $color-base-accent-light
@color {textcolor} dark
@colorof Base.Colors.Accent Palette
*/

/**
@colordef Accent Dark
@color {hex} #2f3e48
@color {var} $color-base-accent-dark
@color {textcolor} light
@colorof Base.Colors.Accent Palette
*/


/**
@section Status Palette
@sectionof Base.Colors
@page Brands Base
*/


/**
@colordef Error
@color {hex} #dd5b4d
@color {var} $color-base-status-error
@color {textcolor} light
@colorof Base.Colors.Status Palette
*/


/**
@colordef Confirmation
@color {hex} #3dba82
@color {var} $color-base-status-confirmation
@color {textcolor} light
@colorof Base.Colors.Status Palette
*/


/**
@colordef Alert
@color {hex} #fee852
@color {var} $color-base-status-alert
@color {textcolor} dark
@colorof Base.Colors.Status Palette
*/


/**
@colordef Info
@color {hex} #2d99e6
@color {var} $color-base-status-info
@color {textcolor} light
@colorof Base.Colors.Status Palette
*/


/**
@section Product Finish Palette
@sectionof Base.Colors
@page Brands Base
*/


/**
@colordef Brushed Nickel
@color {hex} #9f9685
@color {var} $color-finish-brushed-nickel
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/


/**
@colordef Matte Black
@color {hex} #2e2e2e
@color {var} $color-finish-matte-black
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/


/**
@colordef Chrome
@color {hex} #98999d
@color {var} $color-finish-chrome
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/


/**
@colordef Bronze
@color {hex} #39312e
@color {var} $color-finish-bronze
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/


/**
@colordef Polished Nickel
@color {hex} #978a73
@color {var} $color-finish-polished-nickel
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/


/**
@colordef Spot Resist Stainless
@color {hex} #a7a69a
@color {var} $color-finish-spot-resist-stainless
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/


// Base Brand Colors config
// -----------------------------------------------------------------------------

$base-colors: (
  'base': (
    'white': $color-white,
    'black': $color-black
  ),

  'brand': (
    'primary': $color-base-brand-primary,
    'secondary': $color-base-brand-secondary,
    'tertiary': $color-base-brand-tertiary
  ),

  'text': (
    'primary': $color-base-text-primary,
    'secondary': $color-base-text-secondary,
    'tertiary': $color-base-text-tertiary
  ),

  'background': (
    'primary': $color-base-background-primary,
    'secondary': $color-base-background-secondary,
    'tertiary': $color-base-background-tertiary
  ),

  'status': (
    'error': $color-base-status-error,
    'confirmation': $color-base-status-confirmation,
    'alert': $color-base-status-alert,
    'info': $color-base-status-info
  ),

  'accent': (
    'light': $color-base-accent-light,
    'dark': $color-base-accent-dark
  ),

  'finish': (
    'brushed-nickel': $color-finish-brushed-nickel,
    'matte-black': $color-finish-matte-black,
    'chrome': $color-finish-chrome,
    'walnut':$color-finish-walnut,
    'deep-blue':$color-finish-deep-blue,
    'blue':$color-finish-blue,
    'ice-white':$color-finish-ice-white,
    'bronze': $color-finish-bronze,
    'polished-nickel': $color-finish-polished-nickel,
    'spot-resist-stainless': $color-finish-spot-resist-stainless
  )
);
