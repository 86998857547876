
/**
@doc _docs/cardlist-icon-large.md
@section Card List Icon Large
@childof Card Lists
@page Cardlists Cardlist Icon Large
@status in progress
@hippo card-list
@example
_markup/cardlist-icon-large.hbs
*/
@include plmr-component('cardlist-icon-large') {
  @extend .plmr-l-container;
  padding: 30px 0 0;
  text-align: center;

  @include plmr-mq(md) {
    padding: 50px 0 0;
  }

  // .plmr-c-cardlist-icon-large__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.286rem;
    margin: 0;
    padding: 0 40px;

    @include plmr-mq(md) {
      font-size: 2rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 688px;
    }
  }

  // .plmr-c-cardlist-icon-large__summary
  @include element('summary') {
    padding-bottom: 0;
    padding-top: 30px;

    @include plmr-mq(md) {
      font-size: 1.143rem;
      line-height: 1.714rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 688px;
    }
  }

  // .plmr-c-cardlist-icon-large__breadcrumb
  @include element('breadcrumb') {
    color: plmr-color('text', 'secondary');
    font-size: 0;
    padding-top: 15px;
    text-align: center;

    @include plmr-mq(md) {
      padding-top: 20px;
    }
  }

  // .plmr-c-cardlist-icon-large__crumb
  @include element('crumb') {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.714rem;

    // .plmr-c-cardlist-icon-large__crumb::before
    &::after {
      content: '/';
      padding: 0 7px;
    }

    &:last-child {
      &::after {
        content: '';
        padding: 0;
      }
    }
  }

  // .plmr-c-cardlist-icon-large__crumb-link
  @include element('crumb-link') {
    @include plmr-link($color: plmr-color('text', 'secondary'), $underline: true);

    // .plmr-c-cardlist-icon-large__crumb-link._current
    @include plmr-state('current') {
      @include plmr-link($color: plmr-color('text', 'secondary'), $underline: false);
    }
  }

  // .plmr-c-cardlist-icon-large__options
  @include element('options') {
    font-size: 0;
    padding-top: 30px;

    @include plmr-mq(md) {
      padding-bottom: 30px;
      padding-top: 40px;
    }
  }

  // .plmr-c-cardlist-icon-large__item
  @include element('item') {
    @include plmr-link($color: plmr-color('text', 'primary'));
    display: inline-block;
    font-size: 1rem;
    height: auto;
    line-height: 1.5rem;
    margin-bottom: 40px;
    padding: 0 5px;
    vertical-align: top;
    width: 115px;

    @include plmr-mq(md) {
      font-size: 1.143rem;
      line-height: 1.714rem;
      margin-bottom: 30px;
      padding: 0 15px;
      width: 185px;
    }

    @include plmr-mq(lg) {
      margin-bottom: 30px;
      padding: 0 20px;
      width: 210px;
    }
  }

  // .plmr-c-cardlist-icon-large__icon-container
  @include element('icon-container') {
    background-color: plmr-color('background', 'secondary');
    border-radius: 50%;
    display: block;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;
  }

  // .plmr-c-cardlist-icon-large__icon
  @include element('icon') {
    color: plmr-color('brand', 'primary');
    height: auto;
    left: 50%;
    max-height: 60%;
    max-width: 66%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  // .plmr-c-cardlist-icon-large__image
  @include element('image') {
    display: block;
    height: auto;
    left: 50%;
    max-height: 67.65%;
    max-width: 67.65%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  // .plmr-c-cardlist-icon-large__caption
  @include element('caption') {
    display: block;
    padding-top: 10px;

    @include plmr-mq(md) {
      padding-top: 15px;
    }
  }

  // .plmr-c-cardlist-icon-large__helper-text
  @include element('helper-text') {
    color: plmr-color('text', 'secondary');
    display: block;
    padding-top: 20px;

    @include plmr-mq(md) {
      padding-top: 26px;
    }
  }

  // .plmr-c-cardlist-icon-large__button
  @include element('button') {
    padding-bottom: 50px;
  }
}


/**
@doc _docs/cardlist-icon-large--with-cta.md
@section With CTA
@sectionof Card List Icon Large
@page Cardlists Cardlist Icon Large
@example
_markup/cardlist-icon-large--with-cta.hbs
*/
@include plmr-component('cardlist-icon-large') {
  // .plmr-c-cardlist-icon-large--with-cta
  @include modifier('with-cta') {
    .plmr-c-cardlist-icon-large__options {
      @include plmr-mq(md) {
        padding-bottom: 10px;
      }
    }
  }
}


/**
@doc _docs/cardlist-icon-large--no-background.md
@section No Background
@sectionof Card List Icon Large
@page Cardlists Cardlist Icon Large
@example
_markup/cardlist-icon-large--no-background.hbs
*/
@include plmr-component('cardlist-icon-large') {
  // .plmr-c-cardlist-icon-large--no-background
  @include modifier('no-background') {
    .plmr-c-cardlist-icon-large__icon-container {
      background-color: transparent;
    }
  }
}

/**
@doc _docs/cardlist-icon-large--jumbo.md
@section Jumbo
@sectionof Card List Icon Large
@page Cardlists Cardlist Icon Large
@example
_markup/cardlist-icon-large--jumbo.hbs
*/
@include plmr-component('cardlist-icon-large') {
  // .plmr-c-cardlist-icon-large--jumbo
  @include modifier('jumbo') {
    .plmr-c-cardlist-icon-large__item {
      display: block;
      font-size: 1.143rem;
      line-height: 1.714rem;
      margin-bottom: 50px;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      width: 299px;

      @include plmr-mq(md) {
        display: inline-block;
        padding: 0 31px;
        width: 361px;
      }

      @include plmr-mq(lg) {
        padding: 0 53px;
        width: calc(510 / 1300 * 100%);
      }
    }

    .plmr-c-cardlist-icon-large__caption {
      background-color: plmr-color('background', 'secondary');
      margin-top: 20px;
      padding: 8px 0;
      transition: background-color 0.25s ease;

      &:hover {
        background-color: darken($color: plmr-color('background', 'secondary'), $amount: 6%);
      }

      @include plmr-mq(md) {
        margin-top: 25px;
      }
    }

    .plmr-c-cardlist-icon-large__options {
      @include plmr-mq(md) {
        padding-bottom: 20px;
        padding-top: 50px;
      }
    }

    .plmr-c-cardlist-icon-large__icon-container {
      @include plmr-mq(md) {
        height: 280px;
        margin-left: auto;
        margin-right: auto;
        max-width: 280px;
        padding-top: 0;
      }

      &:hover {
        & + .plmr-c-cardlist-icon-large__caption {
          background-color: darken($color: plmr-color('background', 'secondary'), $amount: 6%);
        }
      }
    }

    .plmr-c-cardlist-icon-large__image {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
