@include block(homepage) {
  
  @include element(cards) {
    
    .card-list__list {
      padding: 0;
    }

    .card__title {
      color: $c_white;
      font-size: 2em;
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      
    }

    .card--box {
      .card__front-content {
        height: 100%;
        position: relative;
      }
      .card__back-content {
        p {
          font-size: 1.5em;
        }
      }
      &::before {
        background: linear-gradient(to top, rgba($c_white, 0) 0%, rgba(126, 126, 126, .1) 75%, rgba($c_black, 0.63) 100%);
      }
    }

    .list {
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-top: 1px solid $c_white;
      text-align: center;
      margin-top: 5px;
      padding-top: 25px;
      width: 90%;
    }

  // New styles for cards
  .card--mobile {
    display: block;
    background-position: 50%;
    min-height: 200px;

    @include media($bp-sm) {
      min-height: 276px;
    }
  }

  .card--desktop {
    display: none;   
  }

  .card--desktop,
  .card--mobile {
    margin: 0;
    &:first-of-type {
      margin-bottom: 1.75em;
    }
    @include media($bp-md) {
      &:first-of-type {
        margin-right: 1.75em;
        margin-bottom: 0;
      }  
    }
  }

  @include media($bp-md) {
    .card--mobile {
      display: none;   
    }
    .card--desktop {
      display: block;   
    }
  }

  }
}
