
/**
@section Banner Grid Gallery
@childof Galleries
@page Galleries Banner Grid Gallery
@status in progress
@example
_markup/banner-grid-gallery.hbs
*/
@include plmr-component('banner-grid-gallery') {
  @extend .plmr-l-container;
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: max-height 0.5s ease;

  //.plmr-c-banner-grid-gallery--max-3
  @include modifier('max-3') {

    @include plmr-mq(lg) {
      .plmr-c-banner-grid-gallery__grid-sizer {
        width: (1 / 3 * 100%);
      }

      .plmr-c-banner-grid-gallery__grid-item {
        width: (1 / 3 * 100%);
      }

      .plmr-c-banner-grid-gallery__grid-item--width2x1 {
        width: (2 / 3 * 100%);
      }

      .plmr-c-banner-grid-gallery__grid-item--width2x2 {
        width: (2 / 3 * 100%);
      }

      .plmr-c-banner-grid-gallery__grid-item--width1x2 {
        width: (1 / 3 * 100%);
      }
    }
  }

  // Modifier to include the load more button
  //.plmr-c-banner-grid-gallery--limited
  @include modifier('limited') {
    max-height: 1070px;

    .plmr-c-banner-grid-gallery__loadmore {
      display: block;
    }
  }

  //.plmr-c-banner-grid-gallery__grid
  @include element('grid') {
    display: block;
    left: -5px;
    //margin: -5px auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: calc(100% + 10px);
  }

  //.plmr-c-banner-grid-gallery__grid-sizer
  @include element('grid-sizer') {
    width: (1 / 2 * 100%);

    @include plmr-mq(sm) {
      width: (1 / 2 * 100%);
    }

    @include plmr-mq(md) {
      width: (1 / 3 * 100%);
    }

    @include plmr-mq(lg) {
      width: (1 / 4 * 100%);
    }
  }

  //.plmr-c-banner-grid-gallery__grid-item
  @include element('grid-item') {
    position: relative;
    width: (1 / 2 * 100%);

    //.plmr-c-banner-grid-gallery__grid-item::before
    &::before {
      content: ' ';
      display: block;
      padding-top: 100%;
    }

    @include plmr-mq(sm) {
      width: (1 / 2 * 100%);
    }

    @include plmr-mq(md) {
      width: (1 / 3 * 100%);
    }

    @include plmr-mq(lg) {
      width: (1 / 4 * 100%);
    }

    //.plmr-c-banner-grid-gallery__grid-item--width2x2
    @include modifier('width2x2') {
      width: 100%;

      @include plmr-mq(sm) {
        width: 100%;
      }

      @include plmr-mq(md) {
        width: (2 / 3 * 100%);
      }

      @include plmr-mq(lg) {
        width: (2 / 4 * 100%);
      }
    }

    //.plmr-c-banner-grid-gallery__grid-item--width2x1
    @include modifier('width2x1') {
      width: 100%;

      &::before {
        padding-top: 50%;
      }

      @include plmr-mq(sm) {
        width: 100%;
      }

      @include plmr-mq(md) {
        width: (2 / 3 * 100%);
      }

      @include plmr-mq(lg) {
        width: (2 / 4 * 100%);
      }
    }

    //.plmr-c-banner-grid-gallery__grid-item--width1x2
    @include modifier('width1x2') {
      &::before {
        padding-top: 200%;
      }

      @include plmr-mq(sm) {
        width: (1 / 2 * 100%);
      }

      @include plmr-mq(md) {
        width: (1 / 3 * 100%);
      }

      @include plmr-mq(lg) {
        width: (1 / 4 * 100%);
      }
    }
  }

  //.plmr-c-banner-grid-gallery__grid-item-link
  @include element('grid-item-link') {
    bottom: 5px;
    display: block;
    height: calc(100% - 10px);
    left: 5px;
    overflow: hidden;
    position: absolute;
    right: 5px;
    top: 5px;
    width: calc(100% - 10px);

    //.plmr-c-banner-grid-gallery__grid-item-link::after
    &::after {
      background-image: url('images/icon-expand.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      bottom: 20px;
      content: ' ';
      display: block;
      height: 20px;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 20px;
      transition: opacity 0.5s ease;
      width: 20px;
    }

    //.plmr-c-banner-grid-gallery__grid-item-link:hover
    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }

  //.plmr-c-banner-grid-gallery__grid-item-image
  @include element('grid-item-image') {
    display: block;
    height: auto;
    left: 50%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.5s ease;
    width: 100%;

    &.lazyload {
      transition: opacity 0.3s, transform 0.5s ease;
    }

    //.plmr-c-banner-grid-gallery__grid-item-image:hover
    &:hover {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  //.plmr-c-banner-grid-gallery__slider
  @include element('slider') {
    box-shadow: 0 0 20px 0 rgba(plmr-color('base', 'black'), 0.15);
    height: auto;
    left: 0;
    margin: 0 auto;
    max-width: calc(100% - 80px);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 20px;
    transition: all 0.5s ease;
    width: 120vh;
    z-index: 3;

    //.plmr-c-banner-grid-gallery__slider._open
    @include plmr-state('open') {
      opacity: 1;
      pointer-events: auto;

      .plmr-c-banner-grid-gallery__slider-overlay {
        opacity: 0.5;
      }
    }

    .slick-prev,
    .slick-next {
      background-color: plmr-color('brand', 'primary');
      background-image: url('images/icon-arrow_right_large-white.svg');
      background-position: center center;
      background-size: 10px auto;
      height: 40px;
      top: calc(50vw - 80px);
      transition: 0.5s ease;
      width: 21px;
      z-index: 4;

      &:hover,
      &:focus {
        background-color: lighten(plmr-color('brand', 'primary'), 4%);
        color: plmr-color('base', 'white');
        opacity: 1;
      }

      @include plmr-mq(lg) {
        background-size: 20px 37.86px;
        height: 80px;
        top: 50%;
        width: 42px;
      }
    }

    .slick-next {
      right: 0;

      @include plmr-mq(md) {
        right: 0;
      }
    }

    .slick-prev {
      left: 0;
      transform: scale(-1, 1) translateY(-50%);

      @include plmr-mq(md) {
        left: 0;
      }
    }
  }

  //.plmr-c-banner-grid-gallery__slider-overlay
  @include element('slider-overlay') {
    background-color: plmr-color('background', 'secondary');
    display: block;
    height: 100vh;
    left: 50%;
    opacity: 0;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease;
    width: 100vw;
    z-index: -1;
  }

  //.plmr-c-banner-grid-gallery__close
  @include element('close') {
    color: rgba(plmr-color('base', 'white'), 0.8);
    display: inline-block;
    font-size: 0;
    height: 20px;
    line-height: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: 0.5s ease;
    width: 20px;
    z-index: 3;

    //.plmr-c-banner-grid-gallery__close:hover
    &:hover {
      color: rgba(plmr-color('base', 'white'), 1);
    }

    @include plmr-mq(lg) {
      right: 20px;
      top: 20px;
    }
  }


  //.plmr-c-banner-grid-gallery__slide
  @include element('slide') {
    position: relative;
  }

  //.plmr-c-banner-grid-gallery__slide-image
  @include element('slide-image') {
    height: auto;
    width: 100%;
  }

  //.plmr-c-banner-grid-gallery__product
  @include element('product') {
    background-color: plmr-color('base', 'white');
    box-shadow: 0 0 20px 0 rgba(plmr-color('base', 'black'), 0.15);
    font-size: 0;
    line-height: 0;
    padding: 30px 35px;
    position: relative;
    width: 100%;

    @include plmr-mq(lg) {
      bottom: 40px;
      left: 40px;
      max-width: 650;
      position: absolute;
      width: calc(100% - 80px);
    }

    .col-sm-6 {
      display: inline-block;
      float: none;
      vertical-align: middle;
    }
  }

  //.plmr-c-banner-grid-gallery__product-cta
  @include element('product-cta') {
    min-width: 150px;
  }

  //.plmr-c-banner-grid-gallery__product-name
  @include element('product-name') {
    color: plmr-color('brand', 'primary');
    font-size: 1rem;
    line-height: 1.333rem;
    padding-bottom: 20px;

    @include plmr-mq(md) {
      font-size: 1.143rem;
    }

    @include plmr-mq(lg) {
      //font-size: 18px;
    }
  }

  //.plmr-c-banner-grid-gallery__product-number
  @include element('product-number') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'secondary');
    font-size: 0.8571rem;
    line-height: 1;
    padding-bottom: 20px;

    @include plmr-mq(md) {
      font-size: 1rem;
    }
  }

  //.plmr-c-banner-grid-gallery__product-price
  @include element('product-price') {
    color: plmr-color('text', 'primary');
    font-size: 0.8571rem;
    line-height: 1;

    @include plmr-mq(md) {
      font-size: 1rem;
      padding-bottom: 0;
    }
  }

  //.plmr-c-banner-grid-gallery__loadmore
  @include element('loadmore') {
    background-image: linear-gradient(180deg, rgba(plmr-color('base', 'white'), 0) 15%, rgba(plmr-color('base', 'white'), 0.85) 68%, plmr-color('base', 'white'), plmr-color('base', 'white') 93%);
    bottom: 0;
    display: none;
    height: 545px;
    left: 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  //.plmr-c-banner-grid-gallery__loadmore-button
  @include element('loadmore-button') {
    bottom: 50px;
    left: 0;
    margin: 0 auto;
    pointer-events: auto;
    position: absolute;
    right: 0;
    width: 190px;
  }
}
