/******************************************************************

  MOEN THEME FOR UNIFORM.JS

*******************************************************************/

// This is a base-64 encoded SVG image:
.icon-select-arrows:before {
  content: "";
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0ic2VsZWN0X2Fycm93cyIgZGF0YS1uYW1lPSJzZWxlY3QgYXJyb3dzIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCAxNCI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogIzhiOGQ4ZTsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHRpdGxlPnNlbGVjdC1hcnJvd3M8L3RpdGxlPgogIDxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxMCA0Ljk4IDQuOTcgMCAwIDUuMDQgMTAgNC45OCIvPgogIDxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIwIDkuMDIgNS4wMyAxNCAxMCA4Ljk2IDAgOS4wMiIvPgo8L3N2Zz4K);
  background-repeat: no-repeat;
}

.icon-select-arrow-down:before {
  content: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20288%20288%22%3E%3Ctitle%3E%20%20moen_icons_list1%3C/title%3E%3Cpath%20d%3D%22M288%2087.5a22.6%2022.6%200%200%201-7%2016.6l-120.3%20120.3a22.5%2022.5%200%200%201-16.9%207.3%2021.5%2021.5%200%200%201-16.6-7.3L7%20104.1A21.9%2021.9%200%200%201%200%2087.5a23%2023%200%200%201%207-16.9L20.6%2056.7A24.4%2024.4%200%200%201%2037.5%2050%2023.2%2023.2%200%200%201%2054%2056.7l89.8%2089.8%2089.8-89.8A23.6%2023.6%200%200%201%20250.5%2050a23.2%2023.2%200%200%201%2016.6%206.6L281%2070.6a23.7%2023.7%200%200%201%207%2016.9h0Z%22%20fill%3D%22%23727374%22/%3E%3C/svg%3E');
  bottom: auto !important;
}

/* General settings */
div.selector, div.selector span, div.checker span, div.radio span, div.uploader, div.uploader span.action, div.button, div.button span {
  background-repeat: no-repeat;
}

div.selector, div.checker, div.button, div.radio, div.uploader {
  display: inline-block;
  vertical-align: middle;
  /* Keeping this as :focus to remove browser styles */
}

div.selector:focus, div.checker:focus, div.button:focus, div.radio:focus, div.uploader:focus {
  outline: 0;
}

div.selector, div.selector *, div.radio, div.radio *, div.checker, div.checker *, div.uploader, div.uploader *, div.button, div.button * {
  margin: 0;
  padding: 0;
}

div.selector {
  background: transparent;
  padding: 5px 10px;
  font-family: $din-regular;
  font-size: 14px;
  color: #666;
  border: 2px solid $c_form_border;
  border-radius: 5px;
  line-height: 26px;
  position: relative;
  overflow: hidden;
}

.BVRRSortSelectWidget div.selector {
  // margin-top: 25px;
}

div.selector:before {
  position: absolute;
  right: 6px;
  bottom: 9px;
  width: 10px;
  height: 14px;
}

div.selector span {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  height: 26px;
  line-height: 26px;
  cursor: pointer;
  width: 100%;
  padding-right: 25px;
  text-align: left;
}

div.selector.fixedWidth {
  width: 190px;
}

div.selector.fixedWidth span {
  width: 155px;
}

div.selector select {
  opacity: 0;
  border: none;
  background: none;
  position: absolute;
  height: 36px;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
}

/* Checker */
div.checker {
  border: solid 1px #b1b1b1;
}

div.checker input {
  opacity: 0;
  border: none;
  background: none;
  position: absolute;
  height: 36px;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
}

/* uploader */
div.uploader {
  input {
    border: 2px solid $c_form_border;
    padding: 0.6em 0.8em 0.5em;
  }
}

/* radio */
div.radio {
  label {
    font-family: $helvetica;
    display: inline-block;
    margin-right: 1em;
  }
  input {
    margin-right: .25em;
  }
}
