/**
@section Pagination
@childof Navigation
@page Navigation Pagination
@status in progress
@example
_markup/pagination.hbs
*/
@include plmr-component('pagination') {
  @extend .plmr-l-container;
  font-size: 0;
  line-height: 22px;
  padding-top: 30px;
  position: relative;
  text-align: center;

  &::before {
    @include plmr-pseudo;
    background: plmr-color('accent', 'light');
    height: 1px;
    left: 50%;
    min-width: 100%;
    top: 0;
    transform: translateX(-50%);
    width: auto;
  }

  // .plmr-c-pagination__arrow
  @include element('arrow') {
    @include plmr-link($color: plmr-color('accent', 'dark'));
    cursor: pointer;
    display: inline-block;
    height: 10px;
    line-height: 0;
    margin-top: 2px;
    min-height: 0;
    opacity: 1;
    transition: 0.4s ease-in-out;
    vertical-align: middle;
    width: 6px;

    // .plmr-c-pagination__arrow._disabled
    @include plmr-state('disabled') {
      cursor: auto;
      opacity: 0.5;
      pointer-events: none;
    }

    // .plmr-c-pagination__arrow--prev
    @include modifier('prev') {
      transform: scale(-1, -1);
    }

    // .plmr-c-pagination__arrow--next
    @include modifier('next') {

    }
  }

  // .plmr-c-pagination__list
  @include element('list') {
    display: inline-block;
    font-size: 1rem;
    padding: 0 12.5px;
    vertical-align: middle;
  }

  // .plmr-c-pagination__link
  @include element('link') {
    @include plmr-font-face($family: 'bold');
    @include plmr-link;
    border-radius: 50%;
    display: inherit;
    line-height: 23px;
    margin: 0 6.5px;
    width: 22px;

    // .plmr-c-pagination__link._active
    @include plmr-state('active') {
      background-color: plmr-color('brand', 'tertiary');
      color: plmr-color('base', 'white');
    }

    //.plmr-c-pagination__link._last
    @include plmr-state('last') {
      position: relative;

      //.plmr-c-pagination__link._last::before
      &::before {
        @include plmr-pseudo($content: '...');
        bottom: 0;
        color: inherit;
        font: inherit;
        left: 0;
        margin-left: -4.5px;
        transform: translateX(-100%);
      }
    }
  }

  // .plmr-c-pagination__show-all
  @include element('show-all') {
    display: inline-block;
    font-size: 1rem;
    padding-left: 25px;
    vertical-align: middle;
  }
}
