/* https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping */

// WebFonts for Moen
// -----------------------------------------------------------------------------
@font-face {
  font-display: swap;
  font-family: 'DINWebLight';
  font-weight: 300;
  src: url('./fonts/DINWeb-Light.eot');
  src: url('./fonts/DINWeb-Light.woff') format('woff'),
  url('./fonts/DINWeb-Light.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'DINWebRegular';
  font-weight: 400;
  src: url('./fonts/DINWeb.eot');
  src: url('./fonts/DINWeb.woff') format('woff'),
  url('./fonts/DINWeb.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'DINWebMedium';
  font-weight: 500;
  src: url('./fonts/DINWeb-Medium.eot');
  src: url('./fonts/DINWeb-Medium.woff') format('woff'),
  url('./fonts/DINWeb-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'DINWebBold';
  font-weight: 700;
  src: url('./fonts/DINWeb-Bold.eot');
  src: url('./fonts/DINWeb-Bold.woff') format('woff'),
  url('./fonts/DINWeb.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'proxima-nova-thin';
  font-weight: 300;
  src: url('./fonts/proximanova-thin.eot');
  src: url('./fonts/proximanova-thin.woff') format('woff'),
  url('./fonts/proximanova-thin.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'proxima-nova-regular';
  font-weight: 400;
  src: url('./fonts/proximanova-regular.eot');
  src: url('./fonts/proximanova-regular.woff') format('woff'),
  url('./fonts/proximanova-regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'proxima-nova-bold';
  font-weight: 700;
  src: url('./fonts/proximanova-bold.eot');
  src: url('./fonts/proximanova-bold.woff') format('woff'),
  url('./fonts/proximanova-bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'fzdbs-ligth';
  font-weight: 300;
  src: url('./fonts/FZLT/FZLTXHJW.eot');
  src: url('./fonts/FZLT/FZLTXHJW.woff') format('woff'),
  url('./fonts/FZLT/FZLTXHJW.ttf') format('truetype');
}


@font-face {
  font-display: swap;
  font-family: 'fzdbs-regular';
  font-weight: 400;
  src: url('./fonts/FZLT/FZLTHJW.eot');
  src: url('./fonts/FZLT/FZLTHJW.woff') format('woff'),
  url('./fonts/FZLT/FZLTHJW.ttf') format('truetype');
}


@font-face {
  font-display: swap;
  font-family: 'fzdbs-medium';
  font-weight: 500;
  src: url('./fonts/FZLT/FZLTXIHJW.eot');
  src: url('./fonts/FZLT/FZLTXIHJW.woff') format('woff'),
  url('./fonts/FZLT/FZLTXIHJW.ttf') format('truetype');
}


@font-face {
  font-display: swap;
  font-family: 'fzdbs-bold';
  font-weight: 700;
  src: url('./fonts/FZLT/FZLTZHJW.eot');
  src: url('./fonts/FZLT/FZLTZHJW.woff') format('woff'),
  url('./fonts/FZLT/FZLTZHJW.ttf') format('truetype');
}

// DIN Web Fonts
// -----------------------------------------------------------------------------
$font-family-din-light: 'DINWebLight', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial','fzdbs-ligth', sans-serif !default;
$font-family-din-regular: 'DINWebRegular', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial','fzdbs-regular', sans-serif !default;
$font-family-din-medium: 'DINWebMedium', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', 'fzdbs-medium',sans-serif !default;
$font-family-din-bold: 'DINWebBold', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', 'fzdbs-bold',sans-serif !default;

// Legacy references
$din-light: $font-family-din-light;
$din-regular: $font-family-din-regular;
$din-medium: $font-family-din-medium;
$din-bold: $font-family-din-bold;

.plmr-b-din-light {
  font-family: $font-family-din-light;
}

.plmr-b-din-regular {
  font-family: $font-family-din-regular;
}

.plmr-b-din-medium {
  font-family: $font-family-din-medium;
}

.plmr-b-din-bold {
  font-family: $font-family-din-bold;
}



// Proxima Nova Fonts
// -----------------------------------------------------------------------------
$font-family-proxima-thin: 'proxima-nova-thin', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', 'fzdbs-ligth',sans-serif !default;
$font-family-proxima-regular: 'proxima-nova-regular', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial','fzdbs-regular', sans-serif !default;
$font-family-proxima-bold: 'proxima-nova-bold', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial','fzdbs-bold', sans-serif !default;

// Legacy references
$proxima-thin: $font-family-proxima-thin;
$proxima-regular: $font-family-proxima-regular;
$proxima-bold: $font-family-proxima-bold;

.plmr-b-proxima-nova-thin {
  font-family: $font-family-proxima-thin;
}

.plmr-b-proxima-nova-regular {
  font-family: $font-family-proxima-regular;
}

.plmr-b-proxima-nova-bold {
  font-family: $font-family-proxima-bold;
}



// FS Gill Web Font
// -----------------------------------------------------------------------------
$font-family-fs-gill:'Gill', 'Gill Sans', 'Gill Sans MT', 'Gill Sans Std', 'Tahoma', 'Trebuchet MS','fzdbs-regular',sans-serif !default;

// Legacy refrences
$fs-gill: $font-family-fs-gill;

.plmr-b-fs-gill {
  font-family: $font-family-fs-gill;
}



// Libre Baskerville
// -----------------------------------------------------------------------------
//@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400i');

$font-family-libre-baskerville:'Libre Baskerville', 'fzdbs-regular', serif !default;

.plmr-b-libre-baskerville {
  font-family: $font-family-libre-baskerville;
}


// Moen Brand fonts
// -----------------------------------------------------------------------------
$brand-fonts: (
  'base': $font-family-din-regular,
  'light': $font-family-din-light,
  'regular': $font-family-din-regular,
  'medium': $font-family-din-medium,
  'bold': $font-family-din-bold,
  'italic': $font-family-libre-baskerville
);
