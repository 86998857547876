/**
@doc _docs/carousel-featured-showcase.md
@section Carousel Featured Showcase
@childof Carousels
@page Carousels Carousel Featured Showcase
@status ready
@hippo banner carousel
@example
_markup/carousel-featured-showcase.hbs
*/
@include plmr-component('carousel-featured-showcase') {
  background: plmr-color('base', 'white');

  // .plmr-c-carousel-featured-showcase::before
  &::before {
    background: plmr-color('background', 'secondary');
    bottom: 0;
    content: '';
    display: block;
    height: 409px;
    left: 0;
    position: absolute;
    width: 100%;

    @include plmr-mq(md) {
      height: 609px;
    }

    @include plmr-mq(lg) {
      height: 216px;
    }
  }

  // .plmr-c-carousel-featured-showcase__container
  @include element('container') {
    min-height: 509px;
    position: relative;

    @include plmr-mq(md) {
      min-height: 735px;
    }

    @include plmr-mq(lg) {
      min-height: 725px;
    }
  }

  // .plmr-c-carousel-featured-showcase__inner-container
  @include element('inner-container') {
    margin-top: -20px;

    @include plmr-mq(lg) {
      display: flex;
      margin: 0;
      padding: 0 85px;
      width: 100%;
    }

    @include plmr-mq(xl) {
      padding: 0 142px;
    }
  }

  // .plmr-c-carousel-featured-showcase__slide
  @include element('slide') {}

  // .plmr-c-carousel-featured-showcase__nav
  @include element('nav') {
    bottom: 40px;

    @include plmr-mq(lg) {
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 170px);

      .plmr-c-slider__nav-inner {
        left: 164px;
        transform: translate(0, -50%);
      }
    }

    @include plmr-mq(xl) {
      width: calc(100% - 284px);
    }
  }

  // .plmr-c-carousel-featured-showcase__media
  @include element('media') {
    @include plmr-mq(lg) {
      flex: 1 0 455px;
      min-height: 644px;
      position: relative;
    }
  }

  // .plmr-c-carousel-featured-showcase__image
  @include element('image') {
    display: block;
    overflow: hidden;
    position: relative;

    // .plmr-c-carousel-featured-showcase__image--large
    @include modifier('large') {
      display: none;

      @include plmr-mq(lg) {
        display: block;
        height: 600px;
      }
    }

    // .plmr-c-carousel-featured-showcase__image--small
    @include modifier('small') {
      height: 240px; // add 40px to account for texture box shadow
      width: 100%;

      @include plmr-mq(md) {
        height: 290px;
      }

      @include plmr-mq(lg) {
        bottom: 0;
        left: 378px;
        position: absolute;
        width: 515px;
      }
    }

    // .plmr-c-carousel-featured-showcase__image--src
    @include modifier('src') {
      height: auto;
      left: 50%;
      //max-height: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;
    }

    // .plmr-c-carousel-featured-showcase__image--texture
    @include modifier('texture') {
      box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
      height: 100px;
      width: 305px;

      @include plmr-mq(md) {
        height: 125px;
        width: 455px;
      }
    }

    // .plmr-c-carousel-featured-showcase__image--knockout
    @include modifier('knockout') {
      max-height: 200px;
      max-width: 250px;

      @include plmr-mq(md) {
        max-height: 250px;
      }
    }
  }

  // .plmr-c-carousel-featured-showcase__content
  @include element('content') {
    margin: 0 auto;
    padding-bottom: 50px;
    padding-top: 20px;
    width: 305px;

    @include plmr-mq(md) {
      padding-bottom: 114px;
      padding-top: 30px;
      width: 598px;
    }

    @include plmr-mq(lg) {
      flex: 1 0 calc(100% - 455px);
      padding-left: 55px;
      padding-top: 0;
      width: auto;
    }
  }

  // .plmr-c-carousel-featured-showcase__copy
  @include element('copy') {
    @include plmr-mq(lg) {
      max-width: 506px;
    }
  }
}
