
@include block('unsubscribe') {

  @include child('load') {
    min-height: 200px;
    position: relative;

    @include media($bp-sm) {
      min-height: 400px;
    }
  }

  @include child('unknown') {
    min-height: 200px;

    @include media($bp-sm) {
      min-height: 400px;
      padding-right: 15em;
    }
  }

  @include child('error') {
    color: $c_error;
    font-size: rem(16);
    font-weight: bold;
    margin-bottom: 2em;
  }

  @include child('form') {
    // padding-top: 2em;
    position: relative;
  }

  @include child('email') {
    width: 100%;

    @include media($bp-sm) {
      width: 70%;
    }

    @include media($bp-md) {
      width: 50%;
    }
  }

  .form-label {
    margin-right: 1em;
  }

  @include child('submit') {
    width: 100%;

    @include media($bp-sm) {
      width: auto;
    }
  }


  @include child('confirmation') {
    min-height: 200px;

    @include media($bp-sm) {
      min-height: 300px;
    }

  }
}
