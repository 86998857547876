
// .plmr-c-button-scroll-arrow
@include plmr-component('button-scroll-arrow') {
  align-items: center;
  background: none;
  bottom: 5%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);


  // .plmr-c-button-scroll-arrow__label
  @include element('label') {
    @include plmr-font-face($family: 'light');
    color: currentColor;
    font-size: 1rem;
    margin-top: 0.5rem;
    text-transform: uppercase;


    // screen width > 768px
    @include plmr-mq('md') {
      font-size: 1.2rem;
    }

  }


  // .plmr-c-button-scroll-arrow__icon
  @include element('icon') {
    color: currentColor;
  }

}
