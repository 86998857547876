/*
The Tap

*/

.show-desktop {
	display: none;
}
.show-desktop {
	@include media($bp-lg) {
		display: block;
	}
}
.hide-desktop {
	@include media($bp-lg) {
		display: none;
	}
}
@include block(tap) {
	display: block;
	overflow: hidden;
	position: relative;
	@include block(tag) {
		display: inline-block;
		color: #FFF;
		border-radius: 3px;
		overflow: hidden;
		padding: 3px 11.5px 2px;
		font: normal 10px/10px $proxima-bold;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		//background-color: $c_moen-gray;
		text-decoration: none !important;
		@include modifier(inspiration) {
			background-color: $c_moen-maroon;
		}
		@include modifier(support) {
			background-color: $c_moen-green;
		}
		@include modifier(diy) {
			background-color: $c_moen-gray;
		}
		@include modifier(pros) {
			background-color: $c_moen-dark-blue;
		}
	}
	
}