/**
@doc _docs/navigation-document-link.md
@section Document Link Navigation
@childof Navigation
@page Navigation Document Link Navigation
@status in progress
@hippo navigation
@example
_markup/navigation-document-link.hbs
*/
@include plmr-component('navigation-document-link') {
  list-style: none;

  //.plmr-c-navigation-document-link__item
  @include element('item') {
    min-height: 30px;

    & ~ & {
      margin-top: 18px;
    }
  }

  //.plmr-c-navigation-document-link__anchor
  @include element('anchor') {
    @include plmr-font-face($family: 'bold');
    display: block;
    line-height: 1.5rem;
    padding-left: 50px;
    position: relative;
  }

  //.plmr-c-navigation-document-link__anchor-icon
  @include element('anchor-icon') {
    color: plmr-color('brand', 'primary');
    display: block;
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }
}


/**
@section No Icon
@sectionof Document Link Navigation
@example
_markup/navigation-document-link--no-icon.hbs
*/
@include plmr-component('navigation-document-link') {
  //.plmr-c-navigation-document-link--no-icon
  @include modifier('no-icon') {
    .plmr-c-navigation-document-link__item {
      min-height: 0;
    }

    .plmr-c-navigation-document-link__anchor {
      @include plmr-font-face($family: 'regular');
      padding-left: 0;
    }
  }
}
