/**
@doc _docs/tables.md
@section Tables
@childof Utilities
@page Tables
@status ready
@example
_markup/table-basic.hbs
*/


@mixin plmr-table-element() {
  border-top: 1px solid plmr-color('background', 'secondary');
  line-height: 1.5;
  padding: 0.5rem;
  vertical-align: top;
}

@mixin plmr-table-th() {
  border-bottom: 2px solid plmr-color('background', 'secondary');
  font-weight: bold;
  text-align: left;
  vertical-align: bottom;
}


// .plmr-u-table
@include plmr-utility('table') {
  margin-bottom: 2rem;
  max-width: 100%;
  width: 100%;


  // .plmr-u-table th
  th {
    @include plmr-table-element;
    @include plmr-table-th;

    border-top: 0;
    display: none;


    // screen width > 768px
    @include plmr-mq('md') {
      display: table-cell;
    }

  }


  // .plmr-u-table td
  td {
    @include plmr-table-element;

    border-top: 0;
    display: block;


    // .plmr-u-table td::before pseudo element
    &::before {
      @include plmr-table-element;
      @include plmr-table-th;

      border-top: 0;
      content: attr(data-th) ': ';
      display: block;
      margin-bottom: 0.5rem;
      padding-left: 0;
      padding-right: 0;
      width: 100%;


      // screen width > 768px
      @include plmr-mq('md') {
        display: none;
      }

    }


    // screen width > 768px
    @include plmr-mq('md') {
      border-top: 1px solid plmr-color('background', 'secondary');
      display: table-cell;
    }

  }

}
