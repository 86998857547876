

/**
Class to make an image responsive to its container, but not to exceed the images max-height or max-width.
@section Responsive Image
@sectionof Image
@page Utilities Image
@status ready
@example
_markup/img-responsive.hbs
*/
@include plmr-utility('img') {

  // .plmr-u-img--responsive
  @include modifier('responsive') {
    height: auto;
    // max-height: 100%;
    max-width: 100%;
    // width: auto;
  }

}



/**
Ability to float image to the left.
@section Image Floating Left
@sectionof Image
@page Utilities Image
@status ready
@example
_markup/img-left.hbs
*/
@include plmr-utility('img') {

  // .plmr-u-img--left
  @include modifier('left') {
    float: left;
  }

}



/**
Ability to float image to the right.
@section Image Floating Right
@sectionof Image
@page Utilities Image
@status ready
@example
_markup/img-right.hbs
*/
@include plmr-utility('img') {

  // .plmr-u-img--right
  @include modifier('right') {
    float: right;
  }

}



@include plmr-utility('lazyload') {
  opacity: 0;


  @include plmr-state('loading') {
    opacity: 0;
  }


  @include plmr-state('loaded') {
    opacity: 1;
    transition: opacity 1000ms;
  }


}
