@include block('add-to-cart') {

  @include media($bp-sm) {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  .hero-section__buy-button {

    @include media($bp-sm) {
      display: inline-block;
      margin: 0;
      width: auto;
    }

  }

}
