//.plmr-c-additional-product-specs
@include plmr-component('additional-product-specs') {
  position: relative;

  &::after {
    @include plmr-pseudo;
    background-color: plmr-color('accent', 'light');
    bottom: 0;
    height: 1px;
    left: 50%;
    max-width: 960px;
    transform: translateX(-50%);
    width: calc(305 / 375 * 100%);
    z-index: 1;

    @include plmr-mq('md') {
      width: calc(598 / 768 * 100%);
    }
  }


  //.plmr-c-additional-product-specs__benefits-container
  @include element('benefits-container') {
    padding: 28px 0 30px;
    padding-left: calc(18.66667% / 2);
    padding-right: calc(18.66667% / 2);

    @include plmr-mq('md') {
      padding: 38px 115px 40px;
    }

    @include plmr-mq('lg') {
      display: flex;
      flex-flow: row nowrap;
      padding-left: calc(204 / 1300 * 100%);
      padding-right: calc(170 / 1300 * 100%);
    }

    @include plmr-mq('xl') {
      padding-left: 204px;
      padding-right: 170px;
    }
  }

  //.plmr-c-additional-product-specs__content
  @include element('content') {
    padding: 0;

    ul {
      li {
        &::before {
          color: plmr-color('brand', 'primary');
          font-size: 0.75rem;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    @include plmr-mq('lg') {
      flex: 1 1 auto;
      max-width: calc(608 / 926 * 100%);
    }

    @include plmr-mq('xl') {
      max-width: 608px;
    }
    &-title{
      display: flex;
      align-items: center;
      &-img{
        width: 14px;
        margin: 0 0 0 2px !important;
        padding-bottom: 4px;
        cursor: pointer;
        display: inline-block !important;
      }
      &-j_plus{
        display: none !important;
      }
      &-j_reduce{
        display: none !important;
      }
      &-active{
        cursor: pointer;
        display: block !important;
      }
    }
    &-big{
      display: flex;
    }
    &-img1{
      opacity: 0;
      margin: 0 42px 0 0px;
      max-width:0px;
      transition:all 1s;
    }

  }

  //.plmr-c-additional-product-specs__links
  @include element('links') {
    @include plmr-mq('lg') {
      flex: 1 1 auto;
      max-width: calc(100% - (608 / 926 * 100%));
      padding-left: calc(44 / 926 * 100%);
      padding-top: 50px;
    }

    @include plmr-mq('xl') {
      max-width: calc(100% - 608px);
      padding-left: 44px;
    }
  }


}
