/// .feedback
@include block('feedback') {
  max-width: 80%;
  position: fixed;
  right: -80%;
  top: 65%;
  transition: all 0.5s linear;
  transform: translateY(-50%);
  z-index: z("feedback-window");
  
  
  /// screen width > 768px
  @include media($bp-md) {
    right: -600px;  
  }
  
  
  /// .feedback.is-opened  
  @include state('opened') {
    right: 0;    
  }
  

  /// .feedback__button
  @include child('button') {
    background: $c_moen-dark-blue;
    border: 0;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 30px 0 rgba($c_moen-gray, 0.40);
    color: $c_white;
    cursor: pointer;
    display: inline-block;
    font-family: $din-medium;
    left: -54px;
    padding: 8px 5px;
    position: absolute;
    top: 50%;
    transition: all 0.2s linear;
    transform: translateY(-50%) rotate(-90deg);
    transform-origin: center center;
    z-index: z("feedback-button"); 
    
    
    
    /// .feedback__button:hover, .feedback__button:active, .feedback__button:focus
    &:hover,
    &:active,
    &:focus {
      background-color: lighten($c_moen-dark-blue, 10%);      
    } 
    
    
    /// no-touchevents .feedback__button:hover
    .no-touchevents &:hover {
      padding-bottom: 25px;
    }
    
    
    /// .feedback__button.is-open
    @include state('opened') {
      background-color: lighten($c_moen-dark-blue, 10%);
      
      
      /// .feedback__button.is-open:hover
      &:hover {
        background-color: $c_moen-dark-blue;
      
      
        /// .no-touchevents .feedback__button.is-open:hover  
        .no-touchevents & {
          padding-bottom: 8px;  
        }
        
      }
      
    }
    
  }



  /// .feedback__window
  @include child('window') {
    background: $c_white;
    border: 3px solid lighten($c_moen-dark-blue, 10%);
    box-shadow: 0 0 30px 0 rgba($c_moen-gray, 0.40);
    padding: 50px 20px 20px;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      padding: 50px;
      width: 600px;
    }
    
  }

}



/// .feedback-content
@include block('feedback-content') {
  

  /// .feedback-content__logo  
  @include child('logo') {
    display: block;
    margin: 0 auto 1.5em auto;
    width: 150px;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      margin-bottom: 2em;
    }
    
  }
  
  
  /// .feedback-content__title
  @include child('title') {
    color: $c_text-dark;
    font-family: $din-medium;
    font-size: 18px;
    margin-bottom: 1.5em;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      font-size: 25px;
    }
    
  }
  
}




/// .feedback-buttons-container
@include block('feedback-buttons-container') {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
  
  /// screen width > 768px
  @include media($bp-md) {
    flex-direction: row;
  }
  
  
  /// .feedback-buttons-container__button
  @include child('button') {
    margin-bottom: 20px;
    
    
    /// .feedback-buttons-container__button:last-child
    &:last-child {
      margin-bottom: 0;
    }
    
    
    /// screen width > 768px
    @include media($bp-md) {
      margin-bottom: 0;
      margin-right: 20px;
      
      
      /// .feedback-buttons-container__button:last-child
      &:last-child {
        margin-right: 0;
      }
      
    }
    
  }
  
}



/// .feedback-close
@include block('feedback-close') {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: z("feedback-close");
  
  
  /// .feedback-close::after pseudo-element
  &::after {
    background: $c_moen-bright-blue;
    border: 2px solid $c_white;
    border-radius: 50%;
    box-shadow: 0 2px 3px 0 rgba($c_moen-gray, 0.40);
    color: $c_white;
    content: "\2715";
    display: inline-block;
    margin-left: .5em;
    padding: 2px 5px;
    text-align: center;
  }
  
}
