/// .stickynav
@include block('stickynav') {
  display: none;
  
  
  /// .stickynav.is-stick
  @include state('stick') {
    
    
    /// screen width > 768px
    @include media($bp-md) {
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: z("banners") + 1;
    }
    
  }
  
  
  /// screen width > 768px
  @include media($bp-md) {
    display: flex;
  }
  
  
  /// .stickynav--blue
  @include modifier('blue') {
    background-color: $c_moen-bright-blue;
    color: $c_white;
  }
  
  
  /// .stickynav--gray
  @include modifier('gray') {
    background-color: $c_concrete;
    color: $c_text-base;
  }


  /// .stickynav__menu
  @include child('menu') {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: $max-width;
    width: 100%;
  }
  
  
  /// .stickynav__search
  @include child('search') {
    list-style: none;
    flex-grow: 1;
    padding: 3px 5px;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      flex-grow: 0;
      margin-left: auto;
      width: 250px;
    }
    
  }
  
  
  /// .stickynav__cta
  @include child('cta') {
    list-style: none;
    flex-grow: 1;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      flex-grow: 0;
      margin-left: auto;
    }
    
  }

}



/// .stickynav-menu
@include block('stickynav-menu') {
  
  
  /// .sticknav--blue .stickynav-menu
  .stickynav--blue & {
    border-left: 1px solid $c_moen-logo-blue;
  }
  
  
  /// .sticknav--gray .stickynav-menu
  .stickynav--gray & {
    border-left: 1px solid $c_white;
  }
  
  
  /// .stickynav-menu__item
  @include child('item') {
    align-items: center;
    display: flex;
    flex-grow: 1;
    list-style: none;
    padding: 0;
    text-align: center;
    
    
    /// screen width > 768px
    @include media($bp-md) {
      flex-grow: 0;
    }
    
    
    /// .stickynav--blue .stickynav-menu__item
    .stickynav--blue & {
      border-right: 1px solid $c_moen-logo-blue;
    }
    
    
    /// .stickynav--gray .stickynav-menu__item
    .stickynav--gray & {
      border-right: 1px solid $c_white;
    }
    
    
    /// .stickynav-menu__item.is-selected
    @include state(selected) {
      position: relative;
      
      
      /// .stickynav-menu__item.is-selected::after pseudo-element
      &::after {
        bottom: 0;
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: z("stickynav-after");
      }
      
      
      /// .stickynav--blue .stickynav-menu__item.is-selected::after pseudo-element
      .stickynav--blue &::after {
        background-color: $c_moen-logo-blue;
      }
      
      
      /// .stickynav--gray .stickynav-menu__item.is-selected::after pseudo-element
      .stickynav--gray &::after {
        background-color: $c_moen-gray;
      }
      
    }
      
  }
  
  
  /// .stickynav-menu__link
  @include child('link') {
    color: inherit;
    display: block;
    font-size: 14px;
    padding: 12px 14px;
    text-decoration: none;
    
    
    /// .stickynav--blue .stickynav-menu__link:hover, .stickynav--blue .stickynav-menu__link:focus
    .stickynav--blue &:hover,
    .stickynav--blue &:focus {
      background-color: $c_moen-logo-blue;
      color: inherit;
    }
    
    
    /// .stickynav--gray .stickynav-menu__link:hover, .stickynav--gray .stickynav-menu__link:focus
    .stickynav--gray &:hover,
    .stickynav--gray &:focus {
      background-color: $c_moen-gray;
      color: $c_white;
    }
    
  }

}




/// .stickynav-search
@include block('stickynav-search') {
  background: $c_white;
  border: 1px solid $c_moen-bright-gray;
  display: flex;
  justify-content: space-between;
  
  
  /// .stickynav--gray .stickynav-search
  .stickynav--gray & {
    border-color: $c_moen-light-gray;
  }
  
  
  /// .stickynav-search__input
  @include child('input') {
    background: none;
    border: 0;
    flex: 1 1 auto;
  }
  
  
  /// .stickynav-search__button
  @include child('button') {
    border: 0;
  }  
  
}



/// .stickynav-cta
@include block('stickynav-cta') {
  

  /// .stickynav-cta__link  
  @include child('link') {
    background-color: $c_moen-dark-blue;
    color: $c_white;
    display: block;
    font-size: 14px;
    padding: 12px 14px;
    text-align: center;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      color: inherit;
    }
  }
  
}
