//.plmr-c-product-info
@include plmr-component('product-info') {
  padding: 30px 0;

  @include plmr-mq('md') {
    padding: 40px 0 50px;
  }

  @include plmr-mq('lg') {
    padding: 60px 0;
  }

  //.plmr-c-product-info__container
  @include element('container') {
    padding: 0 15px;

    @include plmr-mq('md') {
      padding: 0 55px;
    }

    @include plmr-mq('lg') {
      align-items: flex-start;
      display: flex;
      flex-flow: row-reverse;
      padding-left: calc(88 / 1300 * 100%);
      padding-right: calc(80 / 1300 * 100%);
    }

    @include plmr-mq('xl') {
      padding-left: 88px;
      padding-right: 80px;
    }
  }


  @include element('cta') {
    margin-top: 15px;
  }

  //.plmr-c-product-info__section
  @include element('section') {
    position: relative;

    @include plmr-mq('lg') {
      flex: 1 1 100%;
    }

    //.plmr-c-product-info__section--bottom
    @include modifier('bottom') {
      @include plmr-mq('md') {
        padding-top: 30px;
      }

      @include plmr-mq('lg') {
        padding-right: calc(76 / 1132 * 100%);
        padding-top: 0;
      }

      @include plmr-mq('xl') {
        padding-right: 76px;
      }
    }

    @include modifier('top') {
      @include plmr-mq('md') {
        min-width: calc(700 / 1132 * 100%);
      }

      @include plmr-mq('max') {
        min-width: 700px;
      }
    }
  }

  //.plmr-c-product-info__image-area
  @include element('image-area') {
    height: auto;
    padding-top: calc(295 / 345 * 100%);
    position: relative;
    width: 100%;

    @include plmr-mq('md') {
      height: 564px;
      padding-top: 0;
    }

    @include plmr-mq('lg') {
      height: 600px;
    }
  }

  //.plmr-c-product-info__image-wrap
  @include element('image-wrap') {
    left: 50%;
    max-height: 100%;
    max-width: 514px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 92px);
    z-index: 1;

    @include plmr-mq('md') {
      width: calc(100% - 172px);
    }

    @include plmr-mq('lg') {
      width: calc(100% - (186 / 700 * 100%));
    }

    //.plmr-c-product-info__image-wrap::before
    &::before {
      @include plmr-font-face($family: 'bold');
      @include plmr-pseudo($content: attr(data-pseudo-label));
      background-color: darken(plmr-color('text', 'secondary'), 8%);
      color: plmr-color('base', 'white');
      display: none;
      font-size: 11px;
      left: 0;
      letter-spacing: 2px;
      line-height: 8px;
      margin: 10px 0 0 10px;
      padding: 10px 20px 12px;
      text-transform: uppercase;
      top: 0;
      z-index: 2;

      @include plmr-mq('md') {
        margin: 15px 0 0 15px;
      }
    }
  }

  //.plmr-c-product-info__image
  @include element('image') {
    display: block;
    height: auto;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    // width: 514px;
    z-index: 1;
  }

  //.plmr-c-product-info__image-dimensions
  @include element('image-dimensions') {
    margin: 19px 0 20px;
    position: relative;
    text-align: center;
    z-index: 1;

    @include plmr-mq('md') {
      bottom: 0;
      left: 0;
      margin: 0;
      position: absolute;
      text-align: left;
    }

    @include plmr-mq('lg') {
      left: auto;
      right: 0;
    }
  }

  //.plmr-c-product-info__dimensions
  @include element('dimensions') {
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 50%;
    margin-bottom: 40px;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity 0.25s ease;

    @include plmr-mq('md') {
      border-bottom: 2px solid plmr-color('accent', 'light');
      border-left: 2px solid plmr-color('accent', 'light');
      display: block;
      left: 0;
      min-width: 0;
      transform: none;
    }

    @include plmr-mq('lg') {
      left: auto;
      right: 0;
    }

    //.plmr-c-product-info__dimensions::before,
    //.plmr-c-product-info__dimensions::after
    &::before,
    &::after {
      @include plmr-pseudo;
      background-color: plmr-color('accent', 'light');
      display: none;
      height: 2px;
      width: 10px;

      @include plmr-mq('md') {
        display: block;
      }
    }

    &::before {
      left: 0;
      margin-left: -6px;
      top: 0;
    }

    &::after {
      bottom: 0;
      margin-bottom: -7px;
      right: 0;
      transform: rotate(90deg);
      transform-origin: right;
    }

    //.plmr-c-product-info__dimensions._open
    @include plmr-state('open') {
      opacity: 1;
    }
  }

  //.plmr-c-product-info__dimensions-item
  @include element('dimensions-item') {
    color: plmr-color('text', 'primary');
    flex: 0 0 auto;
    font-size: 0.8571rem;
    line-height: 1.143rem;
    padding: 0 15px;
    text-align: center;

    @include plmr-mq('md') {
      font-size: 1rem;
      line-height: 1.285rem;
      padding: 0 0 17px 20px;
      text-align: left;
    }
  }

  //.plmr-c-product-info__dimensions-item-spec
  @include element('dimensions-item-spec') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    display: inline-block;
    font-size: 1.285rem;
    line-height: 1.5rem;
    padding-left: 10px;
    vertical-align: bottom;

    @include plmr-mq('md') {
      display: block;
      font-size: 1.5rem;
      line-height: 1.714rem;
      padding: 0;
    }
  }

  //.plmr-c-product-info__details
  @include element('details') {
    text-align: center;

    @include plmr-mq('md') {
      text-align: left;
    }
  }

  //.plmr-c-product-info__finishes
  @include element('finishes') {
    padding-top: 20px;

    @include plmr-mq('md') {
      padding-top: 25px;
    }
  }

  //.plmr-c-product-info__valve-info
  @include element('valve-info') {
    padding-top: 30px;
    text-align: left;

    @include plmr-mq('md') {
      padding-top: 50px;
    }
  }

  //.plmr-c-product-info__add-to-cart
  @include element('add-to-cart') {
    padding-top: 20px;

    @include plmr-mq(md) {
      padding-top: 30px;
    }
  }
}


//.plmr-c-product-info
@include plmr-component('product-info') {
  //.plmr-c-product-info--discontinued
  @include modifier('discontinued') {
    .plmr-c-product-info__image-wrap {
      &::before {
        display: block;
      }
    }

    .plmr-c-product-info__image {
      opacity: 0.5;
    }
  }
}


//.plmr-c-product-info
@include plmr-component('product-info') {
  //.plmr-c-product-info--replacement-part
  @include modifier('replacement-part') {

    .plmr-c-product-info__finishes {
      @include plmr-mq(md) {
        padding-bottom: 15px;
        padding-top: 0;
      }
    }

    .plmr-c-product-info-condensed__copy {
      @extend .plmr-c-general-content;
    }

    .plmr-c-product-info-condensed__msrp {
      color: plmr-color('text', 'primary');
    }

    .plmr-c-product-info__details {
      @include plmr-mq(lg) {
        padding-top: 40px;
      }
    }
  }
}
