// Tap Into Moen Cards and Card List
@include block(tap) {
	@include element(into) {
		display: block;
		background-color: $c_concrete;
		padding: 50px;
		@include media($bp-lg) {
			padding: 55px 50px 60px;
		}
		.inner {
			max-width: 1300px;
			margin: 0 auto;
			& > p {
				font-family: $proxima-regular;
				font-size: em(16);
				padding: 0;
				margin-bottom: 25px;
				margin-left: 7.5px;
				text-align: center;
				@include media($bp-md) {
					text-align: left;
				}
				@include media($bp-lg) {
					font-size: em(18);
					margin-bottom: 50px;
					margin-left: 10px;
				}
				&.heading {
					font-family: $playfair;
					font-size: em(24);
					font-weight: bold;
					line-height: 1.25em;
					margin-bottom: 8px;
					color: $c_moen-bright-blue;
					@include media($bp-lg) {
						font-size: em(28);
						margin-bottom: 10px;
					}
				}
			}
		}
		.slick-slider {
			visibility: hidden;
			&.slick-initialized {
				visibility: visible;
			}
			.slick-prev,.slick-next {
				width: 12px;
				height: 21px;
				background-size: 100% auto;
				background-repeat: no-repeat;
				background-position: center center;
				z-index: 9;
				outline: none;
				top: calc(50vw - 57px);
				@include media($bp-md) {
					top: calc(50vw / 3 - 20px);
				}
				@include media($bp-lg) {
					top: calc(50vw / 4 - 20px);
				}
				@include media(1400px) {
					top: 153px;
				}
			}
			.slick-prev {
				left:-30px;
				background-image: url('images/icon-angle_left_gray.svg')
			}
			.slick-next {
				right:-30px;
				background-image: url('images/icon-angle_left_gray.svg');
				transform: scale(-1,1) translateY(-50%);
			}
		}
		@include element(card) {
			display: block;
			text-decoration: none !important;
			color: $c_text-base;
			padding: 0 7.5px;
			text-align: left;
			outline: none;
			@include media($bp-lg) {
				padding: 0 10px;
			}
			img {
				display: block;
				width: 100%;
				height: auto;
				margin-bottom: 15px;
			}
			p {
				text-align: left;
				padding:0;
				margin:0;
			}
			.quote {
				font-family: $proxima-regular;
				font-size: em(14);
				@include media($bp-lg) {
					font-size: em(16);
				}
				line-height: 1.2857142857em;
				margin-bottom: 5px;
			}
			.user-name {
				font-family: $playfair;
				font-weight: bold;
				font-size: em(14);
				color: $c_moen-bright-blue;
			}
		}
	}
}