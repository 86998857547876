// $inspired-link: #1791d4 !default;
$inspired-by-hero-bg: #02346d !default;
$inspired-banner-mask_force: #efd0b1 !default;
$inspired-banner-mask_attraction: #ede9e8 !default;
$inspired-banner-mask_movement: #d69ba5 !default;
$featured-product-innovation-mask_force: #5e4332 !default;
$featured-product-innovation-mask_attraction: #c1ad88 !default;
$featured-product-innovation-mask_movement: #928c89 !default;


/// .inspired-by-hero
@include block('inspired-by-hero') {
  background: none;
  height: auto;
  position: relative;
  width: 100%;
  
  
  /// screen > 768px
  @include media($bp-md) {
    // background: $inspired-by-hero-bg url('//embed.widencdn.net/img/moen/gwdwsupb7w/exact/inspired-by-hero-bg.jpeg?q=90&u=hhyaeb') no-repeat 50% 50%;
    background-color: $inspired-by-hero-bg;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;    
  }

  
  /// .inspired-by-hero__container
  @include child('container') {
    background: $c_white;
    min-width: $min-width;
    width: 100%;
    
    
    /// screen > 768px
    @include media($bp-md) {
      align-content: center;
      align-items: center;
      background: none;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 115px); 
      justify-content: center;
      position: relative;
      top: auto;
    }
    
  }
  
  
  /// .inspired-by-hero__title
  @include child('title') {
    align-items: center;
    background: $inspired-by-hero-bg url('//embed.widencdn.net/img/moen/gwdwsupb7w/700px/inspired-by-hero-bg.jpeg?q=90&u=hhyaeb') no-repeat 50% 50%;
    background-size: cover;    
    color: $c_white;
    display: flex;
    font-family: $din-light;
    font-size: 20px;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    min-height: 125px;
    text-transform: uppercase;
    
    
    /// screen > 768px
    @include media($bp-md) {
      background: none;
      font-size: 40px;
      margin-bottom: 55px;
      position: relative;
      top: auto;
      transform: none;
    }
    
  }
  
  
  /// .inspired-by-hero__logo
  @include child('logo') {
    background: url('//embed.widencdn.net/svg/moen/rayum7bt2s/moen-logo-no-branding.svg?u=hhyaeb') no-repeat 0 50%;
    background-size: 100%;
    height: 36px;
    margin-left: 10px;
    max-height: 92px;
    max-width: 392px;
    width: 150px;
    
    
    /// screen > 768px
    @include media($bp-md) {
      height: 92px;
      margin-left: 18px;
      width: 392px;
    }
    
  }
  
  
  /// .inspired-by-hero__logo-splash
  @include child('logo-splash') {
    background: url('//embed.widencdn.net/img/moen/b9fa6a1p9g/175x93px/moen-logo-water-splash.png?keep=c&u=hhyaeb') no-repeat 0 50%;
    background-size: 100%;
    height: 93px;    
    max-height: 225px;
    max-width: 425px;
    width: 175px;
    
    
    /// screen > 768px
    @include media($bp-md) {
      background-image: url('//embed.widencdn.net/img/moen/b9fa6a1p9g/425x225px/moen-logo-water-splash.png?keep=c&u=hhyaeb');
      height: 225px;
      width: 425px;
    }
    
  }
  
  
  /// .inspired-by-hero__description
  @include child('description') {
    color: $c_dark-gray;
    font-family: $din-light;
    font-size: 16px;
    line-height: 1.75;
    margin: 0 auto;
    padding: 20px 20px 30px;
    text-align: center;
    
    
    /// screen > 768px
    @include media($bp-md) {
      color: $c_white;
      font-size: 21px;
      margin-top: -65px;
      max-width: 600px;
      padding: 0 40px;
      position: relative;
    }
    
  }
  
  
  /// .inspired-by-hero__scroll-arrow
  @include child('scroll-arrow') {
    display: none;
    
    
    /// screen > 768px
    @include media($bp-md) {
      display: block;
    }
    
  }
  
}





/// .inspired-by-banner
@include block('inspired-by-banner') {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 125px;
  max-height: 800px;
  min-height: 125px;
  min-width: $min-width;
  position: relative;
  width: 100%;
  
  
  /// screen > 768px
  @include media($bp-md) {
    background-size: cover;
    height: 0;
    padding-top: percentage( 800 / 2179 );  
  }
  
  
  /// .inspired-by-banner::after pseudo-element
  &::after {
    background-image: linear-gradient(to top, rgba($c_white, 1) 0%, rgba($c_white,0) 100%);
    bottom: 0;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: z("inspiration-transparent-overlay");
    
    
    /// screen > 768px
    @include media($bp-md) {
      height: 0;
      padding-top: percentage( 200 / 2179);  
    }
    
  }
    
  
  /// .inspired-by-banner__container
  @include child('container') {
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;


    /// screen > 768px
    @include media($bp-md) {
      justify-content: flex-start;
      margin-top: percentage( 210 / 2179 );
    }
    
  }
  
  
  /// .inspired-by-banner__link
  @include child('link') {
    color: $c_white;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    
    
    /// screen > 768px
    @include media($bp-md) {
      font-size: 20px;
    }
    
    
    /// .inspired-by-banner__link::after pseudo-element
    &::after {
      background: url('images/icon-play_outline_white.svg') no-repeat 0 50%;
      content: '';
      display: inline-block;
      height: 12px;
      margin-left: 7px;
      width: 12px;
      
      
      /// screen > 768px
      @include media($bp-md) {
        height: 15px;
        width: 15px;
      }
      
    }
    
  }  
  
}



/// .inspired-innovation-title
@include block('inspired-innovation-title') {
  align-items: center;
  align-content: center;
  color: $c_white;
  display: flex;
  flex-direction: column;
  justify-content: center;  
  text-transform: uppercase;
  text-align: center;
  
  
  /// screen > 768px
  @include media($bp-md) {
    height: 120px;    
  }
  
  
  /// .inspired-innovation-title__innovation
  @include child('innovation') {
    align-self: auto;
    display: block;
    flex: 1 0 auto;
    font-family: $din-regular;
    font-size: 15px;
    font-weight: bold;
    order: 0;
    
    
    /// screen > 768px
    @include media($bp-md) {
      font-size: 30px;
    }
    
  }
  
  
  /// .inspired-innovation-title__inspired-by
  @include child('inspired-by') {
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: row;
    font-family: $din-light;
    font-size: 18px;
    justify-content: center;
    
    
    /// screen > 768px
    @include media($bp-md) {
      font-size: 40px;
    }
    
  }
  
  
  /// .inspired-innovation-title__inspiration
  @include child('inspiration') {
    font-family: $din-bold;
    font-size: 35px;
    font-weight: bold;
    margin-left: 5px;
    
    
    /// .inspired-by-banner--force .inspired-innovation-title__inspiration
    .inspired-by-banner--force & {
      color: $inspired-banner-mask_force;
    }
    
    
    /// .inspired-by-banner--attraction .inspired-innovation-title__inspiration
    .inspired-by-banner--attraction & {
      color: $inspired-banner-mask_attraction;
    }
    
    
    /// .inspired-by-banner--movement .inspired-innovation-title__inspiration
    .inspired-by-banner--movement & {
      color: $inspired-banner-mask_movement;
    }
  
  
    /// .backgroundcliptext .inspired-innovation-title  
    .backgroundcliptext & {
      -webkit-background-clip: text;
      -ms-background-clip: text;
      background-clip: text;
      background-size: 100% 100%;
      color: transparent;  
    }
    
    
    /// .backgroundcliptext .inspired-by-banner--force .inspired-innovation-title__inspiration
    .backgroundcliptext .inspired-by-banner--force & {
      background-image: url('//embed.widencdn.net/img/moen/5sanpeikoz/exact/inspired-by-banner-mask_force.jpeg?q=90&u=hhyaeb');
    }
    
    
    /// .backgroundcliptext .inspired-by-banner--attraction .inspired-innovation-title__inspiration
    .backgroundcliptext .inspired-by-banner--attraction & {
      background-image: url('//embed.widencdn.net/img/moen/oaqvwxfro5/exact/inspired-by-banner-mask_attraction.jpeg?q=90&u=hhyaeb');
    }
    
    
    /// .backgroundcliptext .inspired-by-banner--movement .inspired-innovation-title__inspiration
    .backgroundcliptext .inspired-by-banner--movement & {
      background-image: url('//embed.widencdn.net/img/moen/wi2w5xjqsa/exact/inspired-by-banner-mask_movement.jpeg?q=90&u=hhyaeb');
    }
    
    
    /// screen > 768px
    @include media($bp-md) {
      font-size: 80px;
    }
    
  }
  
}



/// .featured-product-innovation
@include block('featured-product-innovation') {
  align-items: center;
  align-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: $max-width;
  min-width: $min-width;
  position: relative;
  width: 100%;
  
  
  /// .featured-product-innovation--product-on-left
  @include modifier('product-on-left') {
    
    
    /// screen width > 768px
    @include media($bp-md) {
      flex-direction: row-reverse;  
    }
    
  }
  
  
  /// .featured-product-innovation--product-on-right
  @include modifier('product-on-right') {
  
    
    /// screen width > 768px
    @include media($bp-md) {
      flex-direction: row;  
    }
    
  }
  
  
  /// .featured-product-innovation__content
  @include child('content') {
    align-self: flex-start;
    flex: 0 1 auto;
    order: 0;
    padding: 10px percentage( 10 / 320 ) 20px;
    
    
    /// screen > 768px
    @include media($bp-md) {
      align-self: center;
      flex: 0 1 65%;
      
      /// .featured-product-innovation--product-on-left .featured-product-innovation__content
      .featured-product-innovation--product-on-left & {
        padding: 0 20px 0 0;
      }
      
      
      /// .featured-product-innovation--product-on-right .featured-product-innovation__content
      .featured-product-innovation--product-on-right & {
        padding: 0 0 0 20px;
      }
      
    }
    
    
    /// screen > 992px
    @include media($bp-lg) {
      flex: 0 1 auto;
    }
    
    
    /// screen > 1300px
    @include media(1300px) {
      padding-left: 0;
    }
    
  }
  
  
  /// .featured-product-innovation__title
  @include child('title') {
    font-family: $din-bold;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    
    
    /// screen > 768px
    @include media($bp-md) {
      font-size: 33px;
    }
    
    
    /// screen > 992px
    @include media($bp-lg) {
      font-size: 44px;
    }
    
    
    /// .featured-product-innovation--force .featured-product-innovation__title
    .featured-product-innovation--force & {
      color: $featured-product-innovation-mask_force;
    }
    
    
    /// .featured-product-innovation--attraction .featured-product-innovation__title
    .featured-product-innovation--attraction & {
      color: $featured-product-innovation-mask_attraction;
    }
    
    
    /// .featured-product-innovation--movement .featured-product-innovation__title
    .featured-product-innovation--movement & {
      color: $featured-product-innovation-mask_movement;
    }
    
    
    /// .backgroundcliptext .featured-product-innovation__title
    .backgroundcliptext & {
      -webkit-background-clip: text;
      background-clip: text;
      background-size: 100% 100%;
      color: transparent;  
    }
    
    
    /// .backgroundcliptext .featured-product-innovation--force .featured-product-innovation__title
    .backgroundcliptext .featured-product-innovation--force & {
      background-image: url('//embed.widencdn.net/img/moen/ntmpkk5xjc/exact/featured-product-innovation-mask_force.jpeg?q=90&u=hhyaeb');
    }
    
    
    /// .backgroundcliptext .featured-product-innovation--attraction .featured-product-innovation__title
    .backgroundcliptext .featured-product-innovation--attraction & {
      background-image: url('//embed.widencdn.net/img/moen/u5rz9lcoeh/exact/featured-product-innovation-mask__attraction.jpeg?q=90&u=hhyaeb');
    }
    
    /// .backgroundcliptext .featured-product-innovation--movement .featured-product-innovation__title
    .backgroundcliptext .featured-product-innovation--movement & {
      background-image: url('//embed.widencdn.net/img/moen/syfiyvokia/exact/featured-product-innovation-mask__movement.jpeg?q=90&u=hhyaeb');
    }
    
  }
  
  
  /// .featured-product-innovation__description
  @include child('description') {
    color: $c_dark-gray;
    font-family: $din-light;
    font-size: 16px;
    padding-right: percentage( 20 / 320 );


    /// screen > 768px
    @include media($bp-md) {
      font-size: 18px;
      padding-right: percentage( 50 / 1300 );
    }
    
    
    /// screen > 992px
    @include media($bp-lg) {
      font-size: 22px;
    }
    
  }
  
  
  /// .featured-product-innovation__link
  @include child('link') {
    font-weight: bold;
    white-space: nowrap;
    
    
    /// .featured-product-innovation__link::after pseudo-element
    &::after {
      background: url('images/icon-angle_right_blue.svg') no-repeat 0 0;
      content:'';
      display: inline-block;
      height: 8px;
      margin-left: 7px;
      width: 5px;
      
      
      /// screen > 768px
      @include media($bp-md) {
        height: 10px;
        width: 6px;
      }
      
    }
    
  }
  
  
  /// .featured-product-innovation__product
  @include child('product') {
    flex: 0 1 auto;
    max-width: $min-width;
    order: 0;
    padding: 0 percentage( 10 / 320 );
    position: relative;
    
    
    /// screen > 768px
    @include media($bp-md) {
      flex: 0 1 45%;
      max-width: none;
      padding: 0;
      
      
      /// .featured-product-innovation--product-on-left .featured-product-innovation__product
      .featured-product-innovation--product-on-left & {
        margin-right: percentage( 100 / 1300 );
        order: 0;
      }
      
      
      /// .featured-product-innovation--product-on-right .featured-product-innovation__product
      .featured-product-innovation--product-on-right & {
        margin-left: percentage( 100 / 1300 );
      }
      
    }
    
    
    /// screen > 992px
    @include media($bp-lg) {
      flex: 1 0 auto;
    }
    
    
    /// .featured-product-innovation__product::before pseudo-element
    &::before {
      background-image: linear-gradient(rgba($c_white, 1) 0%, rgba($c_white, 1) 30%, rgba($c_white,0) 100%);
      content: '';
      height: 0;
      left: 0;
      padding-top: percentage( 200 / 550 );
      position: absolute;
      top: 0;
      width: 100%;
      z-index: z("inspiration-transparent-overlay");
    }
    
  }
  
  
  /// .featured-product-innovation__product-image
  @include child('product-image') {
    display: block;
    height: auto;
    width: 100%;
  }
  
}
