/*
Search Results Section Header

SRP section headers divide the content results page body into sections which correspond to the navigation.

markup:
<div class="search-results-section-header">
  <h2 class="search-results-section-header__heading">Products (66)</h2>
  <a href='' class="search-results-section-header__link">See all Products ></a>
</div>

Styleguide 4.30.3
*/

.search-results-section-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 3px solid $c_moen-bright-blue;
  padding-bottom: .2em;
  margin: 1em 0;
  @include media($bp-sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.search-results-section-header__heading {
  font-size: em(30);
  line-height: 1;
  color: $c_moen-gray;
  padding: 0;
  margin: 0;
}

/*
Search Results Section Header

SRP section headers divide the content results page body into sections which correspond to the navigation.

markup:
<div class="search-results-section-header">
  <h2 class="search-results-section-header__heading">Parts (2)</h2>
  <a href='' class="search-results-section-header__link">See all Parts ></a>
</div>

Styleguide 4.30.3.1
*/

/*
Search Results Section Header

SRP section headers divide the content results page body into sections which correspond to the navigation.

markup:
<div class="search-results-section-header">
  <h2 class="search-results-section-header__heading">Site Content (2)</h2>
  <a href='' class="search-results-section-header__link">See all Content ></a>
</div>

Styleguide 4.30.3.2
*/

.search-results-section-button {
  margin-bottom: 1em;
}


div.search-results-section-header-cn{
  flex-direction: inherit;
}
