/**
@doc _docs/cardlist-related.md
@section Card List Related
@childof Card Lists
@page Cardlists Cardlist Related
@status ready
@hippo card-list
@example
_markup/cardlist-related.hbs
*/
// .plmr-c-cardlist-related
@include plmr-component('cardlist-related') {
  background: plmr-color('base', 'white');
  //overflow: hidden;
  position: relative;
  z-index: 1;

  // .plmr-c-cardlist-related::after
  &::after {
    @include plmr-pseudo;
    background-color: plmr-color('background', 'secondary');
    bottom: 0;
    height: 100%;
    left: 0;
    width: 100%;
    z-index: 1;

    @include plmr-mq(md) {
      height: 54.5%;
    }
  }

  // .plmr-c-cardlist-related__container
  @include element('container') {
    @extend .plmr-l-container;
    overflow: hidden;
    padding: 0;
    position: relative;
    z-index: 2;
  }

  // .plmr-c-cardlist-related__intro
  @include element('intro') {
    color: plmr-color('base', 'white');
    padding: 60px 0 40px;
    position: relative;
    z-index: 1;

    @include plmr-mq(md) {
      padding: 30px 0 30px;
    }

    &::before {
      background-color: plmr-color('brand', 'primary');
      content: ' ';
      height: calc(100% + 100px);
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -1;

      @include plmr-mq(md) {
        min-height: 333px;
        width: calc(100% - 55px);
      }

      @include plmr-mq(lg) {
        width: calc(100% - 90px);
      }
    }


  }

  // .plmr-c-cardlist-related__intro-eyebrow
  @include element('intro-eyebrow') {
    color: inherit;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    opacity: 1;
    font-style:normal;
    font-size: 22px;
    font-family: DINWebRegular,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif
  }

  // .plmr-c-cardlist-related__intro-title
  @include element('intro-title') {
    color: inherit;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;

    @include plmr-mq(lg) {
      max-width: 610px;
      font-size: 24px;
      padding:0;
    }
  }

  // .plmr-c-cardlist-related__intro-cta
  @include element('intro-cta') {
    @include plmr-mq(xl) {
      margin: 0 auto;
      max-width: 1016px;
      text-align: right;
    }
  }

  // .plmr-c-cardlist-related__cards
  @include element('cards') {
    padding: 0 50px 60px;
    position: relative;
    z-index: 2;

    @include plmr-mq(md) {
      padding: 0 140px 75px;
    }

    &:not(.slick-initialized) {
      display: flex;
      justify-content: center;
    }

    .slick-list {
      overflow: visible;
    }

    .slick-track {
      display: flex;
      justify-content: center;
    }

  }

  // .plmr-c-cardlist-related__dots
  @include element('dots') {
    bottom: 25px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    @include plmr-mq(md) {
      bottom: 30px;
    }
  }

  // .plmr-c-cardlist-related__card
  @include element('card') {
    background-color: plmr-color('base', 'white');
    box-shadow: -10px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    margin-right: 25px;
    min-height: 400px;
    position: relative;
    width: 275px;
    z-index: 2;

    &:last-child {
      margin-right: 0;
    }

    &.slick-slide {
      float: none;
      min-height: 400px;
    }

    @include plmr-mq(md) {
      margin-right: 40px;
      min-height: 450px;
      width: 312px;

      &.slick-slide {
        max-height: 525px;
        min-height: 525px;
      }


    }

  }

  // .plmr-c-cardlist-related__card-image-container
  @include element('card-image-container') {
    display: block;
    overflow: hidden;
    width: 100%;
  }

  // .plmr-c-cardlist-related__card-image
  @include element('card-image') {
    display: block;
    transition: transform 0.5s ease;
    width: 100%;

    // .plmr-c-cardlist-related__card-image:hover
    &:hover {
      transform: scale(1.1);
    }
  }

  // .plmr-c-cardlist-related__card-content
  @include element('card-content') {
    color: plmr-color('text', 'primary');
    padding: 20px 30px 30px;

    @include plmr-mq(md) {
      padding: 20px 30px 50px;
    }

  }

  // .plmr-c-cardlist-related__card-title
  @include element('card-title') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    font-size: 1.143rem;
    line-height: 1.714;
    padding: 0;
  }

  // .plmr-c-cardlist-related__card-body
  @include element('card-body') {
    // display: none;

    @include plmr-mq(md) {
      display: block;
      font-size: 1.143rem;
      line-height: 1.714;
      //padding: 15px 0 0;
      padding: 11px 0 0;
      height: 130px;
      overflow: hidden;
    }

  }

  // .plmr-c-cardlist-related__card-cta
  @include element('card-cta') {
    color: plmr-color('brand', 'tertiary');
    //padding-top: 25px;
    padding-top: 17.5px;
  }

}
