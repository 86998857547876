
// This files is old and should no longer be used!!!
// -----------------------------------------------------------------------------

@import "../../brands/base/colors";
@import "../../brands/moen/colors";


$c_white: $color-white !default;
$c_black: $color-black !default;

// Moen branding color of yester year!
// $c_moen-bright-blue: #0090d7 !default; // last color #00a9e0

// Inheritting new branding to old references
$c_moen-bright-blue: $color-moen-brand-primary !default;

$c_moen-bright-aqua: #00b0ca !default;
$c_moen-dark-blue: #002f5f !default;
$c_moen-purple: #4b306a !default;
$c_moen-maroon: #882345 !default;
$c_moen-green: #427730 !default;
$c_moen-gray: #8b8d8e !default;
$c_moen-bright-gray: #e5e5e5 !default;
$c_moen-light-gray: #ccc !default; // nav and form borders

$c_moen-logo-red: #C60C30 !default;
// $c_moen-logo-blue: #007ac9 !default;
$c_moen-logo-blue: $color-moen-brand-secondary !default; // update new branding for legacy
$c_moen-logo-black: #1e1e1e !default;

$c_text-base: #727374 !default;
$c_text-dark: darken($c_text-base, 20) !default;

$c_error: $c_moen-logo-red !default;
$c_disabled: $c_moen-light-gray !default;

$c_concrete: #f3f3f3 !default; // table striping, product tile hover, ribbon backgound
$c_light-gray: #aaaaaa !default; // site search dotted border
$c_dove-gray: #6d6d6d !default; // site search text input, gallery rating stars
$c_dark-gray: #434343 !default; // pdp page text

$c_full-nav-grad-start: rgba(0, 0, 0, 0) !default;
$c_full-nav-grad-end: rgba(0, 0, 0, .04) !default;
$c_full-nav-shadow: rgba(0, 0, 0, 0.7) !default;

$c_rule: $c_moen-gray !default;
$c_shadow: $c_moen-gray !default;

$c_link_color: $color-moen-brand-tertiary !default;

// Banner specfific colors
$c_chateu_green: #44B060 !default;
$c_sea_green: #49B967 !default;

// Table
$c_table-head: #767676 !default;

//Product Detail Page
$c_pdp_collection: #dadada !default;
$c_pdp_installation_support: $c_concrete !default;

// even/odd background grays (e.g. table stripes, faq headers, etc.)
$c_bg-gray-even: $c_concrete !default;
$c_bg-gray-odd: $c_moen-bright-gray !default;

$c_mid-gray: #5c5c5c !default;

// Navigation Colors from MOEN.COM
$c_nav_gray: $c_moen-light-gray !default;
$c_nav_dark_gray: #666 !default;
$c_nav_dark_gray_bg: $c_concrete !default;
$c_nav_suplementary: #f2f2f2 !default;

// Tool Tip
$c_tooltip_gray: #efefef !default;

// BazaarVoice colors:
$c_bv_error-bg: #FBE3E4 !default;
$c_bv_error: #740D00 !default;

// Product Support Page
$c_psp_bg_gray: $c_concrete !default;

// Form
$c_form_border: #d9dce0 !default;
$c_form-invalid-border: $c_error !default;
$c_form-invalid-bg: #feecea !default;
$c_form-valid-border: $c_sea_green !default;
$c_form-valid-bg: #e6fff0 !default;

// Home Sections
$c_home_replacement_bg: $c_dove-gray !default;
$c_home_replacement_inputs: $c_moen-light-gray !default;
$c_home_replacement_text_box: $c_concrete !default;


// FCR Section
$c_fcr_gray_background: $c_dove-gray !default;
$c_fcr_callout_text: $c_moen-bright-blue !default;
$c_fcr_even: $c_dove-gray !default;
$c_fcr_odd: $c_moen-bright-gray !default;
$c_fcr_border: $c_dove-gray !default;

// Product gallery
$c_pattens_blue: #CFE3F3 !default;
$c_facet_dark_blue: #0275AA !default;
$c_facet_gray: #F4F4F4 !default;
$c_facet_borders: #B8B8B8 !default;
$c_button_filters_bg: $c_moen-bright-blue !default;
$c_button_text_blue: $c_moen-bright-blue !default;
$c_button_filters_bg_hover: #e2e2e2 !default;
$c_facets_bg: $c_moen-bright-blue !default;
$c_select_sort_bg: $c_moen-bright-gray !default;

// For the fun of it
$c_attention: #ff66b2 !default;

/// Background for count dislay in Customer Nav
$c_header-icon-count-bg: #f1403a !default;

$c_legacy: $c_form-invalid-bg !default;
