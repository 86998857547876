
// Navigation - Main
// -----------------------------------------------------------------------------

// .plmr-c-nav-main
@include plmr-component('nav-main') {
  display: none;


  // screen width > 768px
  @include plmr-mq('md') {
    align-self: stretch;
    display: flex;
    justify-content: center;
  }


  // .plmr-c-nav-main__list
  @include element('list') {
    // background: lightgreen;
    display: flex;
    justify-content: center;
    margin: 0;
  }


  // .plmr-c-nav-main__item
  @include element('item') {
    align-content: center;
    display: flex;
    flex-grow: 0;


    // .plmr-c-nav-main__item._visible-on-lg
    @include plmr-state('visible-on-lg') {
      display: none;


      // screen width > 992px
      @include plmr-mq('lg') {
        display: flex;
      }
    }
  }


  // .plmr-c-nav-main__link
  @include element('link') {
    @include plmr-font-face($family: 'bold');
    @include plmr-link($color: plmr-color('base', 'white'), $is-color-hover: false);
    align-items: center;
    background: transparent;
    display: flex;
    font-size: 14px;
    justify-content: center;
    letter-spacing: 1px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;


    // .plmr-c-nav-main__link--button
    @include modifier('button') {
      cursor: default;
    }


    // screen width > 768px
    @include plmr-mq('md') {
      padding: 0 18px;
    }

    // screen width > 992px
    @include plmr-mq('lg') {
      padding: 0 45px;
      font-size: 15px;
    }



    // .plmr-c-nav-main__link:active, .plmr-c-nav-main__link:focus, .plmr-c-nav-main__link:hover
    &:active,
    &:focus,
    &:hover,
    &._open {

      .plmr-c-nav-main__link-wrap::after {
        opacity: 1;
      }

    }

  }


  // .plmr-c-nav-main__link-wrap
  @include element('link-wrap') {
    display: inline-block;
    pointer-events: none;
    position: relative;


    // .plmr-c-nav-main__link-wrap::after pseudo-element
    &::after {
      background-color: plmr-color('brand', 'tertiary');
      bottom: -7px;
      content: '';
      display: block;
      height: 2px;
      opacity: 0;
      position: absolute;
      transition: all 0.5ms ease;
      width: 100%;
      z-index: 1;
    }

  }


  // .plmr-c-nav-main__flyout
  @include element('flyout') {

  }

}





