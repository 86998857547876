/**
@doc _docs/banner-intro.md
@section Banner Intro
@childof Banners
@page Banners Banner Intro
@status ready
@hippo banner
@example
_markup/banner-intro.hbs
*/
@include plmr-component('banner-intro') {
  display: block;
  height: auto;
  padding: 40px 0;
  position: relative;
  text-align: center;
  width: 100%;


  @include plmr-mq(md) {
    padding: 50px 0;
  }


  // .plmr-c-banner-intro__container
  @include element('container') {
    height: auto;
    position: relative;
    width: 100%;
  }


  // .plmr-c-banner-intro__eyebrow
  @include element('eyebrow') {
    @include plmr-font-face($family: 'normal');
    color: plmr-color('text', 'secondary');
    display: block;
    font-size: 16px;
    line-height: 21px;
    margin: 0;


    @include plmr-mq(md) {
      font-size: 18px;
      line-height: 24px;
    }


    // .plmr-c-banner-intro__eyebrow--subtitle
    @include modifier('subtitle') {
      color: plmr-color('text', 'tertiary');
      font-size: 21px;
      line-height: 28px;
      margin: 0;
      padding-top: 15px;
      @include plmr-mq(md) {
        font-size: 21px;
        line-height: 32px;
        padding-top: 20px;
        max-width: 400px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }


  // .plmr-c-banner-intro__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    display: block;
    font-size: 28px;
    line-height: 32px;
    margin: 0;
    padding: 15px 0 0;


    @include plmr-mq(md) {
      font-size: 36px;
      line-height: 48px;
    }
  }


  // .plmr-c-banner-intro__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
    font-size: 18px;
    height: auto;
    line-height: 32px;
    margin: 0 auto;
    color: #555;
    padding: 15px 18px 10px;
    width: 100%;


    @include plmr-mq(md) {
      font-size: 21px;
      line-height: 38px;
      max-width: 580px;
      padding-left: 0;
      padding-right: 0;
    }


    @include plmr-mq(lg) {
      max-width: 580px;
    }
  }


  // .plmr-c-banner-intro__cta
  @include element('cta') {
    padding: 30px 0 0;


    // .plmr-c-banner-intro__cta--right
    @include modifier('right') {
      @include plmr-mq(xl) {
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-90px, 52px);
      }
    }
  }
}


/**
@doc _docs/banner-intro--with-copy.md
@section With Copy
@sectionof Banner Intro
@page Banners Banner Intro
@example
_markup/banner-intro--with-copy.hbs
*/


/**
@doc _docs/banner-intro--with-copy-cta.md
@section With Copy and Call To Action
@sectionof Banner Intro
@page Banners Banner Intro
@example
_markup/banner-intro--with-copy-cta.hbs
*/


/**
@doc _docs/banner-intro--right-cta.md
@section With Right Call To Action
@sectionof Banner Intro
@page Banners Banner Intro
@example
_markup/banner-intro--right-cta.hbs
*/


/**
@doc _docs/banner-intro--with-subtitle.md
@section With Subtitle
@sectionof Banner Intro
@page Banners Banner Intro
@example
_markup/banner-intro--with-subtitle.hbs
*/


/**
Banner Intro when styled for use inside modal content.
@section Banner Intro For Modal
@sectionof Banner Intro
@page Banners Banner Intro
@status in progress
@example
_markup/banner-intro-for-modal.hbs
*/
@include plmr-component('banner-intro') {
  //.plmr-c-banner-intro--for-modal
  @include modifier('for-modal') {
    padding: 0 0 30px;

    @include plmr-mq(md) {
      padding: 0 0 30px;
    }

    .plmr-l-container {
      min-width: 0;
    }

    .plmr-c-banner-intro__title {
      font-size: 24px;
      padding-top: 0;

      @include plmr-mq(md) {
        font-size: 28px;
      }
    }

    .plmr-c-banner-intro__copy {
      font-size: 16px;
      line-height: 24px;
      padding: 10px 0 0;

      @include plmr-mq(md) {
        padding-top: 20px;
      }

      @include plmr-mq(lg) {
        max-width: none;
      }
    }
  }
}
