//.plmr-c-product-hero
@include plmr-component('product-hero') {
  //.plmr-c-product-hero__container
  @include element('container') {
    padding: 0;
    position: relative;
  }

  //.plmr-c-product-hero__social-share
  @include element('social-share') {
    display: none;

    @include plmr-mq('md') {
      display: block;
      margin: 40px 40px 0 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }
  }
}
