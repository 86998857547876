@include block(homepage) {
  
  @include element(innovation) {
    .card-list {
      background-color: transparent;
      margin: 0 auto;
      padding: 0;
    }

    .slick-initialized {
      .slick-slide {
        margin: 0 2em;
        &.card {
          width: 300px;
          @include media($bp-md) {
            width: 300px;
          }
        }
      }
    }

    .homepage__innovation-mobile-cta {
      margin-top: 1em;
      @include media($bp-md) {
        display: none;
      }
    }
  }

}
