// Email Signup
// section 3.22


@include block('email-signup') {

  @include child('input') {
    width: 100%;
  }

  @include child('button') {
    width: 100%;

    @include media($bp-sm) {
      width: auto;
    }
  }

  input[type=number] {
    width: 100%;
  }

  @include child('pro') {
    font-size: rem(11);
    margin-top: 1em;

    @include media($bp-sm) {
      margin-bottom: 2em;
    }

    @include media($bp-md) {
      margin-bottom: 0;
    }
  }

  @include child('terms') {
    font-size: rem(11);
    margin-top: 3em;
    text-align: center;

    @include media($bp-sm) {
      bottom: 0.5em;
      margin-top: 0;
      position: absolute;
      right: 0.5em;
    }
  }

  .form {
    padding-top: 0.5em;
    position: relative;

    @include media($bp-sm) {
      padding-top: 2em;
    }
    
    &::before {

      @include media($bp-sm) {
        content: '';
        display: block;
        height: 100%;
        left: -52%;
        max-width: 234px;
        position: absolute;
        width: 51.655629139073%;
      }
    }
    
    @include child('promotion') {
      
      @include modifier('giftcard') {
        
        &::before {
          
          @include media($bp-sm) {
            background: url('//embed.widencdn.net/img/moen/rxbcgrvhks/exact/email-signup-gift-card.jpeg?q=90&u=hhyaeb') no-repeat 0 10%;
            background-size: 100%;
          }
        }
      }
      
      @include modifier('build') {
        
        &::before {
          
          @include media($bp-sm) {
            background: url('//embed.widencdn.net/img/moen/sfyrdzaoiq/exact/build_logo.png?q=90&u=hhyaeb') no-repeat 0 10%;
            background-size: 100%;
            left: -56%;
          }
        }
      }
      
    }
    
  }
}


@include block('email-signup-error-messages') {
  padding-top: 2em;

  @include media($bp-sm) {
    padding-top: 0;
    position: absolute;
    top: 0.5em;
    width: 100%;
  }

  @include child('send-error') {
    font-weight: bold;

    @include media($bp-sm) {
      position: absolute;
      top: -1em;
    }
  }

  @include child('label') {
    font-size: 0.6875rem;

    @include modifier('error') {
      color: $c_error;
      display: none;

      @include state('shown') {
        display: block;

        @include media($bp-sm) {
          display: inline;
        }
      }
    }

  }
}



@include block('footer-signup') {

  @include child('title') {
    margin: 0;
  }

  @include child('sub-title') {
    margin: 0 0 0.5em 0;
    padding-bottom: 0;
  }
}


@include block('send-me-updates') {
  display: block;
  margin: 0 auto;
  // max-width: 280px;
  // width: 100%;

  @include element('image') {
    max-height: 20px;
    max-width: 24px;
  }

}

@include block('email-signup-form') {
  position: relative;
  width: 100%;
}


@include block('email-signup-modal') {
  display: none;
  padding: 0 0 1em 0;

  @include media($bp-md) {
    height: 600px;
    max-height: 600px;
    max-width: 800px;
    padding: 0 0 1em 0;
  }

  &--is-shown & {
    display: block;
  }

  @include child('header') {
    background: transparent url('//embed.widencdn.net/img/moen/ro1ntspo4h/exact/preference-quiz-confirmation-header.jpeg?q=90&u=hhyaeb') no-repeat 0 0;
    background-size: 100%;
    height: 0;
    max-height: 140px;
    max-width: 100%;
    padding-bottom: 17.5%;
  }

  @include child('body') {
    padding: 1em 0.5em;

    @include media($bp-sm) {
      padding: 1em 2em;
    }
  }

}
