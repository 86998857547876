// .innovations
@include block(innovations) {
	// .innovations__section-heading
	@include element(section-heading) {
		display: block;
		font: normal 18px/1em $proxima-regular;
		color: $c_moen-bright-blue;
	}
	//innovations__overview
	@include element(overview) {
		width: calc(100% - 70px);
		max-width: 650px;
		margin:60px auto;
		text-align: center;
		font: normal 18px/1.5em $proxima-regular;
		p,h1,h2,h3 {
			font: inherit;
		}
		.heading--1 {
			font: normal 38px/1.25em $proxima-thin;
			padding-bottom: 20px;
		}
	}
	
}

@import "innovations/type-select";
@import "innovations/subnav";
@import "innovations/hero";
@import "innovations/feature";
@import "innovations/benefits";
@import "innovations/callouts";
@import "innovations/campaign";
@import "innovations/demo";
@import "innovations/table";
@import "innovations/devices";