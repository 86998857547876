

// Accessbility Hidden Text
// -----------------------------------------------------------------------------

@mixin plmr-hidden-text() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0.5rem;
  position: absolute;
  // top: -40px;
  white-space: nowrap;
  width: 1px;
}



@mixin plmr-hidden-text-with-focus() {
  @include plmr-hidden-text();
  top: -40px;


  &:focus {
    @include plmr-font-face($family: 'medium');
    background: plmr-color('brand', 'secondary');
    clip: auto;
    color: plmr-color('base', 'white');
    font-size: 1rem;
    height: auto;
    letter-spacing: 1px;
    overflow: auto;
    top: 0;
    width: auto;
  }
}
