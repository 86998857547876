
/// .mobile-nav
@include block('mobile-nav') {
  margin: 0 -20px 30px;
  position: relative;
  
  
  /// screen > 768px
  @include media($bp-md) {
    display: none;
  }
  
  
  /// .mobile-main-nav__list
  @include child('list') {
    
  } 
  
  
  /// .mobile-main-nav__item
  @include child('item') {
    
    
    /// .mobile-main-nav__item:first-child
    &:first-child {
      
      /// .mobile-main-nav__item:first-child .mobile-main-nav__link
      .mobile-nav__link {
        border-top: 1px solid $c_moen-bright-gray;
      }
      
    }
    
  } 
  
  /// .mobile-main-nav__link
  @include child('link') {
    border-bottom: 1px solid $c_moen-bright-gray;
    color: inherit;
    display: block;
    font-family: $din-bold;
    font-size: 16px;
    line-height: 12px;
    padding: 30px 50px 30px 20px;
    position: relative;
    text-transform: uppercase;
  
    
    /// .mobile-main-nav__link::after pseudo-element  
    &::after {
      background: transparent url('images/icon-angle_right_blue.svg') 0 0 no-repeat;
      content: '';
      height: 14px;
      margin-top: -7px;
      position: absolute;
      right: 30px;
      top: 50%;
      width: 8px;
    }
    
    
    /// .mobile-main-nav__link::hover
    &:hover {
      background-color: $c_concrete;
      text-decoration: none;
    }
    
    
    /// .mobile-main-nav__link--active
    @include modifier('active') {
      background: $c_concrete;
      color: $c_moen-bright-blue;
      text-align: center;
      
      
      /// .mobile-main-nav__link--active::after pseudo-element
      &::after {
        background-image: url('images/icon-angle_left_blue.svg');
        left: 30px;
        right: auto;
      }
      
    }
    
    
    /// .mobile-main-nav__link--additional
    @include modifier('additional') {
      color: $c_moen-bright-blue;
      font-family: $din-regular;
    }
    
  }

}

/// .mobile-sub-nav
@include block('mobile-sub-nav') {

  
  /// .mobile-sub-nav[aria-expanded='false']  
  &[aria-expanded='false'] {
    display: none;
  }
  
  
  /// .mobile-sub-nav__list
  @include child('list') {
    margin-bottom: 30px;  
  }
  
  
  /// .mobile-sub-nav__item
  @include child('item') {
    border-bottom: 1px solid $c_moen-bright-gray;
  }
  
  
  /// .mobile-sub-nav__link
  @include child('link') {
    align-content: flex-start;
    align-items: center;
    color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 12px 20px; 
  
  
    /// .mobile-sub-nav__link:hover  
    &:hover {
      background: #f9f9f9;
      text-decoration: none;
    }
    
  }
  
  
  /// .mobile-sub-nav__image
  @include child('image') {
    align-self: auto;
    flex: 0 1 auto;
    height: 50px;
    margin-right: 15px;
    order: 0;
    width: 50px;
  }
  
  
  /// .mobile-sub-nav__label
  @include child('label') {
    
  }
  
  
  /// .mobile-sub-nav__view-all
  @include child('view-all') {
    font-size: 16px;
    margin-bottom: 27px;
    text-align: center;
  }
  
  
  //// .mobile-sub-nav--support
  @include modifier('support') {
  
  
    /// .mobile-sub-nav--support .mobile-sub-nav__view-all
    .mobile-sub-nav__view-all {
      padding: 0 20px;
      text-align: left;
    }
    
  }
  
}



/// .mobile-support-search
@include block('mobile-support-search') {
  border-bottom: 1px solid $c_moen-bright-gray;
  margin-bottom: 15px;
  padding: 25px 35px;
  text-align: center;


  /// .mobile-support-search__search-box  
  @include child('search-box') {
    margin-bottom: 15px;
  }
  
  
  /// .mobile-support-search__label
  @include child('label') {
    font-size: 12px;
    margin: 0 0 20px 0;
    text-align: center;
  }
  
  
  /// .mobile-support-search__input
  @include child('input') {
    max-width: none;
    
    
    /// screen > 448px
    @include media($bp-xs) {
      max-width: none;
    }
    
  }
  
  
  /// .mobile-support-search__button
  @include child('button') {
    
  }
  
}
