/*
FAQ List

Styleguide 4.11
*/

$c_menu-selected: #1785b8 !default;
$w_menu-breakpoint: 768px !default;

@include block(faq-list) {
  + li {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  @include element(title){
    padding: 0 2em;
    h2{
      margin-top: 0.8em;
      margin-bottom: 0.5em;
    }
  }
  @include element(category) {

    @include modifier(level-1) {
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;
      @include media($w_menu-breakpoint) {
        flex-direction: row;
      }
      @include element(item) {
        line-height: 40px;
        font-size: em(20);
        background-color: $c_moen-bright-blue;
        border-bottom: 2px solid $c_white;
        position: relative;

        &.is-selected {
          background-color: $c_menu-selected;
          @include media($w_menu-breakpoint) {
            &:after {
              @include pointer(16px, $c_menu-selected);
            }
          }
        }

        @include media($w_menu-breakpoint) {
          line-height: 1.2;
          flex-grow: 1;
          border-right: 1px solid $c_moen-dark-blue;
          border-bottom: 0;
          &:last-child {
            border-right: 0;
          }
        }

        .link {
          padding: 0 1em;
          padding-left: 1.8em; // offsets the label with horizontal record count so it appears visually centered.
          color: $c_white;
          text-decoration: none;
          width: 100%;
          height: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          &:focus {
            outline-offset: -2px;
            outline-color: $c_white;
          }
          @include media($w_menu-breakpoint) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-content: center;
            padding: .5em;
            text-align: center;
            line-height: 1;
          }
        }
      }
    }

    @include modifier(level-2) {
      color: $c_moen-bright-aqua;
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;
      @include media($w_menu-breakpoint) {
        flex-direction: row;
      }
      @include element(item) {
        line-height: 40px;
        font-size: em(16);
        background-color: $c_moen-bright-gray;
        border: 1px solid $c_white;
        position: relative;
        margin-right: 3px;
        &.is-selected {
          background-color: $c_moen-bright-blue;
          .link {
            color: $c_white;
          }
        }

        @include media($w_menu-breakpoint) {
          line-height: 1.2;
          flex-grow: 0;
        }

        .link {
          padding: 0 2em;
          color: $c_moen-bright-blue;
          text-decoration: none;
          width: 100%;
          height: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;

          @include media($w_menu-breakpoint) {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            align-content: center;
            padding: .5em;
            text-align: center;
            line-height: 1;
            flex-wrap: wrap;
          }
        }
      }
    }

    @include modifier(level-3) {
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;
      @include media($w_menu-breakpoint) {
        flex-direction: row;
        flex-wrap: wrap;

      }
      @include element(item) {
        line-height: 40px;
        font-size: em(16);
        border: 1px solid $c_moen-gray;
        background-color: $c_white;
        position: relative;
        margin: 0 0 em(8) 0;
        .icon {
          display: none;
          width: 1em;
          height: 1em;
          margin-left: 1em;
        }
        &:hover,
        &:focus {
          border-color: $c_moen-bright-blue;
        }
        &.is-selected {
          .icon {
            display: inline
          }
        }

        @include media($w_menu-breakpoint) {
          line-height: 1.2;
          flex-grow: 0;
          margin-right: em(4);
          margin-left: 0;
        }

        .link {
          padding: 0 2em;
          color: $c_moen-gray;
          text-decoration: none;
          width: 100%;
          height: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          @include media($w_menu-breakpoint) {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            align-content: center;
            padding: .5em;
            text-align: center;
            line-height: 1;
            flex-wrap: wrap;
          }
        }
      }
    }

  }

  @include element(collection) {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .faq-question-list {
   // margin-bottom: 1.1em;
  }

  .faq-grp-title {
    margin-bottom: .4em;
  }

  .small {
    font-size: .8em;
    font-style: normal;
    padding: 0 .3em;
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
    @include media($w_menu-breakpoint) {
      line-height: 2em;
    }
  }
  .link {
    cursor: pointer;
  }

  @include block (faq__answer) {
    padding: 1em;
    margin: 0;
    @include media($bp-xs) {
      padding: 1em 2em;
    }
  }
}
