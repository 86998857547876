/// .inspiration-list
@include block('inspiration-list') {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  
  
  /// .inspiration-list__item
  @include child('item') {
    margin: 0 10px;  
  }
  
}


/// .featured-inspiration
@include block('featured-inspiration') {

  
  /// .featured-inspiration__item
  @include child('item') {
    margin: 0 auto 14px;
    max-width: 280px;
    
    
    /// screen > 768px
    @include media($bp-md) {
      max-width: 220px;
    }
    
  }
  
  
  /// .featured-inspiration__image
  @include child('image') {
    height: 165px;
    width: 200px;  
  }
  
  
  /// .featured-inspiration__title
  @include child('title') {
    color: $c_text-base;
    font-size: 21px;
    line-height: 18px;
    margin: 0 0 rem(14) 0;
  }
  
  
  /// .featured-inspiration__explore-all
  @include child('explore-all') {
    background: url('images/icon-angle_right_blue.svg') right 50% no-repeat;
    background-size: 5px 9px;
    font-family: $din-bold;
    font-size: 14px;
    line-height: 14px;
    padding-right: 15px;
  }
  
}



/// .inspiration
@include block('inspiration') {
  
  
  /// .inspiration__link
  @include child('link') {
    display: block;
    margin: 0 auto;
    position: relative;
  }
  
  
  /// .inspiration__image
  @include child('image') {
    display: block;
    height: auto;
    position: relative;
    width: 100%;
  }
  
  
  /// .inspiration__label
  @include child('label') {
    background: $c_moen-bright-blue;
    border-radius: 3px;
    color: $c_white;
    display: inline-block;
    font-family: $proxima-bold;
    font-size: 10px;
    left: 10px;
    letter-spacing: 0.5px;
    line-height: 10px;
    padding: 3px 10px;
    position: absolute;
    text-transform: uppercase;
    top: 10px;
    z-index: z("inspiration-label");
  }
  
  
  /// .inspiration--video
  @include modifier('video') {
    
    
    .inspiration__link {

      &::before {
        background: $c_moen-bright-blue url('images/icon-play_white.svg') no-repeat 50% 50%;
        background-size: 12px 12px;
        border-radius: 9px;
        content: '';
        display: block;
        height: 30px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 43px;
        z-index: z("inspiration-video-icon");
      }
      
    }
    
  }
  
}


/// .inspiration-carousel
@include block('inspiration-carousel') {
  margin-bottom: 20px;


  /// .inspiration-carousel__item  
  @include child('item') {
    // display: none;
    
    
    /// .inspiration-carousel__item:first-child 
    &:first-child {
      // display: block;
    }
    
  }
  
  
  /// .inspiration-carousel__link
  @include child('link') {
    max-width: 280px;
    
  }
  
  
  /// .inspiration-carousel__image
  @include child('image') {
    
  }
  
}


/// .inspiration-carousel-nav
@include block('inspiration-carousel-nav') {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  margin: 25px 0 0 0;
  padding: 0;
  text-align: center;
  width: 100%;
  
  
  /// .inspiration-carousel-nav li  
  li {
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0 5px;
    padding: 5px;
    position: relative;
    width: 20px;
  }
  
  
  /// .inspiration-carousel-nav button
  button {
    background: transparent;
    border: 2px solid $c_moen-bright-blue;
    border-radius: 100%;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 12px;
    line-height: 0;
    padding: 0;
    width: 12px;
  }
  
  
  /// .inspiration-carousel-nav .slick-active
  .slick-active {
  
  
    /// .inspiration-carousel-nav .slick-active button  
    button {
      background-color: $c_moen-bright-blue;
    }
    
  }
  
}
