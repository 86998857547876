//.plmr-c-installation-support
@include plmr-component('installation-support') {
  //.plmr-c-installation-support__questions
  @include element('questions') {
    padding: 20px 25px 40px;

    @include plmr-mq(md) {
      padding: 40px 55px;
    }

    @include plmr-mq(md) {
      padding-bottom: 50px;
      padding-left: calc(91 / 1300 * 100%);
      padding-right: calc(91 / 1300 * 100%);
    }

    @include plmr-mq(max) {
      padding-left: 91px;
      padding-right: 91px;
    }
  }
}
