/**
Accessibily utility classes.
@section Accessibility
@childof Utilities
@page Utilities Accessibility
@nobrandtoggle
*/



/**
@section Skip Link
@childof Accessibility
@page Utilities Accessibility Skip Link
@status ready
@example
_markup/skip-link.hbs
*/

@include plmr-utility('skip-link') {
  @include plmr-hidden-text-with-focus();
  z-index: plmr-z($layer: 'masthead', $offset: 2);
}




/**
@section Visually Hidden Text
@childof Accessibility
@page Utilities Accessibility Visually Hidden Text
@status ready
@example
_markup/visually-hidden-text.hbs
*/

@include plmr-utility('visually-hidden') {
  @include plmr-hidden-text();
}
