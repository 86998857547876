// Mixin to change Arrow hover fill color
// -----------------------------------------------------------------------------
@mixin plmr-arrow-hover($fill, $percentage) {

  &:hover,
  &:focus,
  &:active {
    fill: darken($fill, $percentage);
  }

}




/**
@doc _docs/carousels.md
@section Carousels
@childof Components
@page Carousels
@nobrandtoggle
*/


//.plmr-c-carousel
@include plmr-component('carousel') {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  display: block;
  position: relative;
  touch-action: pan-y;
  user-select: none;


  @include element('container') {
    margin-left: auto;
    margin-right: auto;
    //max-width: 1300px;
    //min-width: 320px;
    max-width: plmr-config('container', 'max-width');
    min-width: plmr-config('container', 'min-width');
  }


  // .plmr-c-carousel__dots
  @include element('dots') {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    padding: 0;
    text-align: center;
    width: 100%;


    li {
      cursor: pointer;
      display: inline-block;
      height: 10px;
      margin: 0 6px;
      padding: 0;
      position: relative;
      width: 10px;


      button {
        background: transparent;
        border: 2px solid plmr-color('brand', 'tertiary');
        border-radius: 50%;
        color: transparent;
        cursor: pointer;
        display: block;
        font-size: 0;
        height: 10px;
        line-height: 0;
        width: 10px;


        &::before {
          background: plmr-color('brand', 'tertiary');
          border-radius: 100%;
          content: '';
          display: block;
          height: 50%;
          left: 50%;
          opacity: 0;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.5s ease;
          width: 50%;
        }

        &:hover,
        &:focus {

          @include plmr-mq(lg) {
            &::before {
              opacity: 1;
            }
          }
        }


        &:focus {
          outline: 2px dashed plmr-color('background', 'secondary');
        }
      }


      &.slick-active {
        button {
          background: plmr-color('brand', 'tertiary');
          border-color: transparent;
        }
      }


      @include  plmr-mq(md) {
        height: 12px;
        width: 12px;


        button {
          height: 12px;
          width: 12px;
        }
      }
    }

    // .plmr-c-carousel__dots._light
    @include plmr-state('light') {
      li {
        button {
          border: 2px solid plmr-color('base', 'white');

          &::before {
            background: plmr-color('base', 'white');
          }
        }

        &.slick-active {
          button {
            background: plmr-color('base', 'white');
            border-color: transparent;
          }
        }
      }
    }
  }

  // .plmr-c-carousel__arrow
  @include element('arrow') {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: auto;
    border: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 30px;
    line-height: 0;
    //opacity: 0.6;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s ease;
    width: 17px;
    z-index: 2;


    &:hover,
    &:focus {
      .plmr-c-carousel__arrow-path--light {
        @include plmr-arrow-hover(plmr-color('accent', 'light'), 13%);
      }


      .plmr-c-carousel__arrow-path--dark {
        @include plmr-arrow-hover(plmr-color('accent', 'dark'), 15%);
      }
    }


    &:focus {
      outline: 2px dashed plmr-color('background', 'secondary');
    }


    // .plmr-c-carousel__arrow--next
    @include modifier('next') {
      right: 40px;
    }


    // .plmr-c-carousel__arrow--prev
    @include modifier('prev') {
      left: 40px;
      transform: scale(-1, -1) translateY(50%);
    }
  }


  // .plmr-c-carousel__arrow-path
  @include element('arrow-path') {


    // .plmr-c-carousel__arrow-path--light
    @include modifier('light') {
      fill: plmr-color('accent', 'light');
      transition: 0.5s ease;
    }


    // .plmr-c-carousel__arrow-path--dark
    @include modifier('dark') {
      fill: plmr-color('accent', 'dark');
      transition: 0.5s ease;
    }
  }

  // .plmr-c-carousel__nav
  @include element('nav') {
    @include plmr-font-face($family: 'italic');
    bottom: 30px;
    color: plmr-color('text', 'secondary');
    font-size: 1rem;
    height: 24px;
    left: 50%;
    line-height: 24px;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: 100%;

    // .plmr-c-carousel__arrow--prev
    .plmr-c-carousel__arrow--prev {
      left: 0;
    }

    // .plmr-c-carousel__arrow--next
    .plmr-c-carousel__arrow--next {
      right: 0;
    }

    @include plmr-mq(md) {
      bottom: 50px;
    }
  }

  // .plmr-c-carousel__nav-inner
  @include element('nav-inner') {
    left: 50%;
    padding: 0 42px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .slick-list,
  .slick-track {
    perspective: 2000px;
    transform: translate3d(0, 0, 0);
  }


  .slick-slide {
    &:focus {
      outline: 2px dashed plmr-color('background', 'secondary');
      outline-offset: -1px;
    }


    &:not(.slick-current.slick-active),
    &:not(.slick-active) {
      display: none;
    }
  }


  .slick-initialized,
  &.slick-initialized {
    .slick-slide {
      &:not(.slick-current.slick-active),
      &:not(.slick-active) {
        display: block;
      }
    }
  }
}



@import 'slick-carousel-defaults';

@import 'carousel-campaign-slider';

@import 'carousel-category-collection-detail';

@import 'carousel-collage';

@import 'carousel-collection-slider';

@import 'carousel-column-gallery';

@import 'carousel-featured-showcase';

@import 'carousel-feature-slider'; // Featured Product Carousel in Hippo CMS

@import 'carousel-icon-tabbing';

@import 'carousel-featured-parts';

@import 'carousel-feature-staggered';

@import 'carousel-coordinating-items';

@import 'carousel-featured-campaign';

@import 'carousel-gallery';
