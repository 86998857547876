
// plmr-l-main
@include plmr-layout('main') {
  min-height: calc(100vh - (600px + 70px + 34px));


  // screen width > 768px
  @include plmr-mq('md') {
    min-height: calc(100vh - (589px + 77px + 34px));
  }
}
