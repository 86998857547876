
/**
@doc _docs/cardlist-callouts-small.md
@section Card List Callouts Small
@childof Card Lists
@page Cardlists Cardlist Callouts Small
@status ready
@example
_markup/cardlist-callouts-small.hbs
*/
@include plmr-component('cardlist-callouts-small') {
  //.plmr-c-cardlist-callouts-small__items
  @include element('items') {
    font-size: 0;
    margin-top: -15px;
    padding: 0 35px 25px;
    text-align: center;

    @include plmr-mq(md) {
      padding: 0 24px 30px;
    }

    @include plmr-mq(lg) {
      margin-top: -25px;
      padding: 0 27.5px 40px;
    }

    @include plmr-mq(xl) {
      padding-left: 114.5px;
      padding-right: 114.5px;
    }
  }

  //.plmr-c-cardlist-callouts-small__item
  @include element('item') {
    font-size: 0;
    padding: 15px 0;
    position: relative;
    text-align: left;

    @include plmr-mq(md) {
      display: inline-block;
      padding: 20px 31px;
      vertical-align: top;
      width: 50%;
    }

    @include plmr-mq(lg) {
      padding: 25px 27.5px;
      width: 33.33%;
    }
  }


  //.plmr-c-cardlist-callouts-small__item-inner
  @include element('item-inner') {
    @include plmr-mq(md) {
      box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    }
  }

  //.plmr-c-cardlist-callouts-small__item-image
  @include element('item-image') {
    display: inline-block;
    position: relative;

    @include plmr-mq(md) {
      display: block;
      overflow: hidden;
      padding-top: calc(170 / 302 * 100%);
    }

    //.plmr-c-cardlist-callouts-small__item-image--src
    @include modifier('src') {
      display: none;
      height: auto;
      left: 50%;
      max-width: 100%;
      min-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.5s ease;
      width: auto;

      @include plmr-mq(md) {
        display: block;
      }

      //.plmr-c-cardlist-callouts-small__item-image--src:hover
      &:hover {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }

  //.plmr-c-cardlist-callouts-small__item-tag
  @include element('item-tag') {
    display: inline-block;
    max-width: calc(100% - 50px);
    padding-left: 15px;
    vertical-align: middle;

    @include plmr-mq(md) {
      background: plmr-color('base', 'white');
      display: block;
      max-width: none;
      padding: 10px 15px 44px;
    }
  }

  //.plmr-c-cardlist-callouts-small__item-title
  @include element('item-title') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    font-size: 1.143rem;
    line-height: 1.714rem;
    min-height: 0;
    padding: 0;
    text-align: left;
  }

  //.plmr-c-cardlist-callouts-small__item-button
  @include element('item-button') {
    border-color: plmr-color('brand', 'primary');
    height: 50px;
    width: 50px;

    @include plmr-mq(md) {
      border-color: plmr-color('base', 'white');
      bottom: 0;
      left: 0;
      margin: 20px;
      position: absolute;
      z-index: 2;
    }

    //.plmr-c-cardlist-callouts-small__item-button::before
    &::before {
      border-left-color: plmr-color('brand', 'primary');

      @include plmr-mq(md) {
        border-left-color: plmr-color('base', 'white');
      }
    }
  }
}
