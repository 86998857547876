
/// .support-container
@include block('support-container') {
  padding: 20px 15px;
  
  /// screen > 992px
  @include media($bp-lg) {
    padding: 25px 30px;  
  }
  
}



/// .support-search 
@include block('support-search') {
  
  
  /// .mobile-support-search__label
  @include child('label') {
    display: block;
    font-family: $din-bold;
    font-size: 12px;
    font-weight: bold;
    // margin: 0 0 20px 0;
    // text-align: center;
    text-transform: uppercase;
  }
  
  
  /// .mobile-support-search__input
  @include child('input') {
    max-width: none;
    
    
    /// screen > 448px
    @include media($bp-xs) {
      max-width: none;
    }
    
  }
  
  
  /// .mobile-support-search__button
  @include child('button') {
    
  }
  
}



/// .main-support-search
@include block('main-support-search') {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  
  /// .main-support-search__item
  @include child('item') {
    align-self: auto;
    flex: 0 1 auto;
    font-size: 13px;
    margin-right: 10px;
    order: 0;
    
    
    /// screen > 992px
    @include media($bp-lg) {
      margin-right: 20px;
    }
    
  }
  

  /// .main-support-search__label  
  @include child('label') {
    font-size: 12px;
    
    
    /// screen > 992px
    @include media($bp-lg) {
      font-size: 13px;
    }
    
  }
  
  
  /// .main-support-search__search-box
  @include child('search-box') {
    
  }
  
  
  /// .main-support-search__input
  @include child('input') {
    max-width: 330px;
    width: 242px;
    
    
    /// screen > 992px  
    @include media($bp-lg) {
      width: 300px;
    }
    
  }
  
  
  /// .main-support-search__button
  @include child('button') {
    
  }
  
  
  /// .main-support-search__conjunction
  @include child('conjunction') {
    font-size: 12px;
    
    
    /// screen > 992px
    @include media($bp-lg) {
      font-size: 13px;
    }
    
  }
  
}


/// .support-menu-container
@include block('support-menu-container') {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
  max-width: $max-width;
  min-width: $min-width;

  /// .support-menu-container__item  
  @include child('item') {
    align-self: auto;
    flex: 0 1 auto;
    // margin: 0 5px;
    order: 0;
    
    
    /// screen > 992px;
    @include media($bp-lg) {
      margin: 0 10px;
    }
    
  }
  
}


/// .main-support-buy-moen
@include block('main-support-buy-moen') {
  font-size: 16px;
  
  
  /// screen > 768px
  @include media($bp-md) {
    font-size: 16px;
  }
  
  
  /// screen > 992px
  @include media($bp-lg) {
    font-size: 24px;
  }
  
}



/// .support-menu-nav
@include block('support-menu-nav') {
  padding: 15px 20px;
  
  
  /// .support-menu-nav__title
  @include child('title') {
    font-family: $din-bold;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 3px;
    text-transform: uppercase;
  }
  
  
  /// .support-menu-nav__list
  @include child('list') {
    
  }
  
  
  /// .support-menu-nav__item
  @include child('item') {
    margin: 0 0 0.5em;
  }
  
  
  /// .support-menu-nav__link 
  @include child('link') {
    color: $c_text-base;
    font-size: 14px;
  }
  
}
