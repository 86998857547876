// .innovations
@include block(innovations) {
	
	
	// Container for innovations callouts
	// .innovations__callouts 
	@include element(callouts) {
		display: block;
		font-size: 0;
		max-width: 1600px;
		margin: 80px auto 20px;
		padding:0 10px;
		@include media($bp-md) {
			margin: 120px auto 30px;
			padding: 0 15px;
		}
		@include media($bp-lg) {
			margin: 150px auto 40px;
			padding: 0 20px;
		}
		// .innovations__callouts--less-margin
		@include modifier("less-margin") {
			margin: 20px auto 20px;
			padding:0 10px;
			@include media($bp-md) {
				margin: 30px auto 30px;
				padding: 0 15px;
			}
			@include media($bp-lg) {
				margin: 40px auto 40px;
				padding: 0 20px;
			}
		}
		// .innovations__callouts--full-width
		@include modifier("full-width") {
			@include media($bp-md) {
				margin: 50px auto 30px;
			}
			@include media($bp-lg) {
				margin: 80px auto 40px;
			}
			// .innovations__callouts--full-width .innovations__callout
			.innovations__callout {
				height: 250px;
				@include media($bp-md){
					width: calc(100% - 40px);
					height: 325px;
				}
				@include media($bp-lg){
					width: calc(100% - 40px);
					height: 400px;
					padding: 60px 30px 0;
				}
				// .innovations__callouts--full-width .innovations__callout .product-imgs
				.product-imgs {
					display: flex;
					justify-content: center;
					position: absolute;
					bottom: 0;
					right: 0;
					font-size: 0;
					.product-img {
						flex: none;
					}
					// .innovations__callouts--full-width .innovations__callout .product-imgs.centered
					&.centered {
						left:50%;
						transform: translateX(-50%);
					}

				}
				// .innovations__callouts--full-width .innovations__callout .product-img
				.product-img {
					right:auto;
					display: inline-block;
					position: relative;
					height: 90px;
					margin: 0 10px;
					// .innovations__callouts--full-width .innovations__callout .product-img.tablet
					&.tablet {
						display: none;
					}
					@include media($bp-md) {
						position: relative;
						//height: 100px;
						//margin: 0 15px;
						height: 115px;
						margin: 0 25px;
						&.tall {
						}
						&.tablet {
							display: inline-block;
						}
					}
					@include media($bp-lg) {
						//height: 130px;
						//margin: 0 20px;
						height: 145px;
						margin: 0 30px;
						&.tall {
						}
					}
					@include media($bp-xl) {
						height: 165px;
						margin: 0 45px;
						//margin: 0 25px;
						//height: 180px;
						//margin: 0 35px;
					}
				}
			}
		}
	}
	
	
	// Individual innovations callout
	// .innovations__callout
	@include element(callout) {
		display: block;
		text-align: center;
		padding: 30px 28px 0;
		width: calc(100% - 20px);
		margin: 20px auto;
		height: 300px;
		overflow: hidden;
		background-color: $c_concrete;
		text-decoration: none !important;
		transition: 0.5s ease;
		position: relative;
		@include media($bp-md) {
			height: 450px;
			width: calc(50% - 30px);
			margin: 0 15px;
			display: inline-block;
			padding: 50px 45px 0;
		}
		@include media($bp-lg) {
			height: 650px;
			width: calc(50% - 40px);
			margin: 0 20px;
			padding: 60px 40px 0;
		}
		
		// .innovations__callout .innovations__section-heading
		.innovations__section-heading {
			font-size: 16px;
			padding-bottom: 15px;
			@include media($bp-md) {
				font-size: 18px;
				padding-bottom: 20px;
			}
		}
		
		// .innovations__callout .heading--1
		.heading--1 {
			font: normal 21px/1.2em $proxima-thin;
			color: $c_text-base;
			padding-bottom: 20px;
			@include media($bp-md) {
				font-size: 30px;
				padding-bottom: 30px;
			}
			@include media($bp-lg) {
				font-size: 38px;
			}
		}
		
		// .innovations__callout .cta 
		.cta {
			font: normal 14px/1em $din-bold;
			color: $c_moen-bright-blue;
			padding-bottom: 24px;
			
			img {
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				margin-bottom: 8px;
				width: 6px;
				height: 10px;
			}
			span {
				display: inline-block;
				vertical-align: middle;
				padding-bottom: 8px;
				position: relative;
				&:after{
					content: ' ';
					display: block;
					position: absolute;
					width: 100%;
					height: 3px;
					background-color: $c_moen-bright-blue;
					bottom: 0;
					opacity: 0;
					transition: 0.5s ease;
				}
			}
		}
		
		// .innovations__callout .product-img
		.product-img {
			display: block;
			width: auto;
			height: 140px;
			position: absolute;
			bottom:0;
			right: 0;
			transition: 0.5s ease;
			
			// .innovations__callout .product-img.centered
			&.centered {
				left:50%;
				transform: translateX(-50%);
			}
			&.left {
				right: auto;
				left: 0;
			}
			// .innovations__callout .product-img.tall
			&.tall {
				height: 230px;
			}
			@include media($bp-md) {
				height: 225px;
				&.tall {
					height: 315px;
				}
			}
			@include media($bp-lg) {
				height: 390px;
				&.tall {
					height: 480px;
				}
			}
			@include media($bp-xl) {
				height: 430px;
				&.tall {
					height: 510px;
				}
			}
		}
		
		
		// .innovations__callout:hover
		&:hover {
			background-color: darken($c_concrete,2.75%);
			.cta span:after {
				opacity: 1;
			}
			.product-img {
				transform: scale(1.1);
				&.centered {
					transform: translateX(-50%) scale(1.05);
				}
			}
		}
		
	}
}