
@include block('kitchen-101') {

  .container--main {
    margin-top: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  // Ribbon element
  @include block('ribbon') {
    height: 33px;
    background: url('//embed.widencdn.net/img/moen/gshzsfydti/exact/kitchen-101_background_ribbon_new.png?q=90') 100% 0 no-repeat;
    display: inline-block;
    padding: 0 23px 0 15px;
    color: $c_white;
    line-height: 30px;
  }

  // Blue divider from prev version
  .blue_divider:after {
      content: ' ';
      width: 44px;
      height: 4px;
      background-color: $c_moen-bright-blue;
      display: block;
      margin: 25px 0 25px;
  }

  // Custom style for first overview section
  #overview {
    .banner:first-child {
      .banner__image-bg>img {
        display: block;
        max-width: none;
        height: 450px;
        width: auto;
        position: relative;
        top:0;
        bottom:0;
        left:50%;
        transform: translateX(-50%);
        @media screen and (max-width: 768px) {
          transform: translateX(calc(-50% + 200px));
        }
      }
      .banner__image-bg+.banner__inner {
        position: absolute;
        top: 0;
          left: 50%;

          transform: translateX(-50%);
          width: 100%;
          z-index: 1;

        .text-box {
          background-color: transparent;
            bottom:0;
            position: absolute;
            left:0;
            right:0;
            margin: auto;
          }
      }
    }
  }

  .text-box__inner {
    padding: 2em;
  }

  .banner.banner--split-logo {
    padding: 15px 0;
  }

  // What is your style section
  .what-is-your-style {

    .a_floating-box {
      margin-top: 60%;
      margin-top: calc(60% + 25px);
    }
    .banner {
      background-size: 100% auto;
      background-position: 0 0;
    }
    @media screen and (min-width:1020px) {
      .banner {
        min-height: 600px;
      }
      .a_floating-box {
        position: absolute;
        top:auto;
        right:50px;
        bottom:50px;
        left:auto;
        margin-top: 0;
        background-color: $c_white;
        padding: 15px;
        border: solid 7px #b1b1b1;
        max-width: 320px;
      }
    }
  }

  // Tab items fix
  .list--horizontal.is-centered {
    justify-content: space-around;
    flex-wrap: nowrap;
    li {
      display: inline-block;
      margin-right: 0;
      &:hover .link--gray, &.is-active .link--gray {
        color: $c_moen-bright-blue
      }
      .link--gray {
        display: block;
        pointer-events: none;
      }
    }
  }

  .a_tab {
    cursor: pointer;
  }


  // Sink hole section
  .sink-holes {
    .a_tabbed {
      .a_tab {
        padding: 15px;
        max-width: 154px;
        // background: #02a9e0;
        background: $c_moen-bright-blue;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        position: relative;
        img {
          max-width: 100%;
          height: auto;
          display: inline-block;
          vertical-align: middle;
        }
        &.is-active {
          background-color: #999;
          box-shadow: inset -2px 2px 6px -3px $c_black;
          &::before{
            content:'';
            display: block;
            position: absolute;
            bottom:-40px;
            left:0;
            right:0;
            margin: 0 auto;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 12px 8px;
            border-color: transparent transparent #efefef transparent;
          }
        }
      }
      .a_tab_content > div {
        background-color: #efefef;
        padding: 30px 40px;
        margin-top: 40px;
      }

    }
  }

  // Pre-defined max height too short
  @media screen and (min-width: 48rem) {
    .banner__image-bg>img {
      max-height: 700px;
    }
  }
  .orbs .orb {
    margin-bottom: 20px;
  }

  // White text that drops down into a white area on mobile need to appear
  @media screen and (max-width: 767px) {
    .mobile_invert--white {
      *:not(.ribbon) {
        color: #727374 !important;
      }
    }
  }
  // White text that drops down into a white area on mobile need to appear
  @media screen and (max-width: 1020px) {
    .tablet_invert--white {
      *:not(.ribbon) {
        color: #727374 !important;
      }
    }
  }




  // Stack cards on mobile

  .card--blue-box .card__back-content p {
    text-align: center;
    border-top: 2px dotted #999;
    padding-top: 1em;
    &:first-child {
      border-top:none;
    }
  }
  @media screen and (max-width: 1024px) {
    .card--blue-box {
      height: auto;
      &:hover {
        text-decoration: none;
      }
      img {
        height: 250px;
        width: auto;
      }
      .card__back-content, .card__front-content {
        position: relative;
        opacity: 1;
        display: block !important;
      }
      .card__back-content {
        padding-top: 15px
      }
    }


  }


  @media screen and (max-width: 480px) {
    .browse-style__menu-item .link--gray {
      font-size: 12px;
    }
  }



  // Atypic styles prefixed with a_
  .a_stickynav {
    height: 33px;
    .stickynav.stick {
      position: fixed;
      top:0;
      left:0;
      right:0;
      z-index: 99999;
    }
  }



  #arcs {
    background-size: cover;
    background-position: center center;
  }
  @media screen and (max-width:767px) {
    #arcs {
      background-size: 100% auto;
      background-position: center top;
      padding-top: 43%;
      padding-top: calc(43% + 25px);
      .text-white {
        color: #727374;
      }
    }
  }
  // Custom this or that
  .a_thisorthat {
    background: $c_black;
    background: rgba($c_black, 0.6);
    padding: 10px;

    .conjunction-badge {
      background-color: $c_black;
      color: $c_white;
    }

    .a_thisorthat_option {
      background-color: $c_moen-bright-blue;
      margin: 10px;
      padding: 40px 25px;
      position: relative;

      &:hover {
        background-color: rgba($c_moen-bright-blue,0.8);
      }

      &.active {
        color: #666;
        background-color: $c_white;

        &:hover {
          background-color: $c_white;
        }

        &::before {
          content:'';
          display: none;
          @media screen and (min-width: 768px) {
            display: block;
          }
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 20px 0 20px 35px;
          border-color: transparent transparent transparent $c_white;
          right:-30px;
          top:50%;
          transform: translateY(-20px);
          position: absolute;
        }
      }
    }
    .a_thisorthat_desc {
      display: none;
      padding: 20px 50px;
      &.active {
        display: block;
      }
    }

  }

  // For vertical alignment
  .a_vertical_align_middle {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  // Tab functionality
  .a_tabbed {
    position: relative;
    .a_tab_content {
      opacity: 0;
      pointer-events: none;
      height: 0;
      overflow: hidden;
      &.is-active {
        position: relative;
        opacity: 1;
        pointer-events: auto;
        height: auto;
      }
    }
  }

  // Faucets section
  .a_faucets-slider {
    img {
      width: 100%;
      height: auto;
    }
    .slick-arrow {
          position: absolute;
          height: 40px;
          width: 40px;
          background: #777;
          background: rgba($c_black, 0.4);
          color: $c_white;
          line-height: 0.5;
          text-align: center;
          bottom: 1px;
          right: 0px;
          z-index: 10;
          font-size: 35px;
          min-height: 0;
          vertical-align: middle;
          top: auto;
          transform: none;
          right:0;
          left: auto;
          appearance: none;
          &.slick-prev {
            right: 50px;
          }
          &:before {
            display: none !important;
          }

        }

  }
  .a_faucets-description {
  /*
    @media screen and (min-width:1000px) {
      position: absolute;
      bottom: 0px;
      right: 0;
    }
  */
    padding-top: 10px;
    border-top: 1px solid #999;
  }
}
