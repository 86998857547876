/**
@doc _docs/carousel-collection-slider.md
@section Carousel Collection Slider
@childof Carousels
@page Carousels Carousel Collection Slider
@hippo banner carousel
@status ready
*/

/**
@section Dark Arrows
@sectionof Carousel Collection Slider
@page Carousels Carousel Collection Slider
@example
_markup/carousel-collection-slider-dark-arrow.hbs
*/

@include plmr-component('collection-slider') {
  background: plmr-color('background', 'secondary');

  // .plmr-c-collection-slider__slider
  @include element('slider') {
    .plmr-c-carousel__arrow--next {
      margin-top: 58.75px;
    }


    .plmr-c-carousel__arrow--prev {
      margin-top: -58.75px;
    }
  }


  // .plmr-c-collection-slider__slide
  @include element('slide') {
    padding: 30px 0;
    position: relative;


    &:not(:first-child) {
      display: none;
    }


    @include plmr-mq(md) {
      padding: 60px 0;
    }


    @include plmr-mq(lg) {
      font-size: 0;
      padding: 75px 0 150px;
    }
  }


  // .plmr-c-collection-slider__image-area
  @include element('image-area') {
    display: block;
    height: auto;
    overflow: hidden;
    padding: 100% 0 0;
    position: relative;
    width: 100%;
    will-change: transform;


    @include plmr-mq(md) {
      height: 683px;
      padding: 0;
      width: 683px;
    }


    @include plmr-mq(lg) {
      display: inline-block;
      height: auto;
      padding: 50% 0 0;
      width: 50%;
    }


    // .plmr-c-collection-slider__image--bottom
    @include modifier('bottom') {
      box-shadow: 0 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);


      @include plmr-mq(md) {
        box-shadow: -20px -10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
        margin: -75px 0 0 auto;
      }


      @include plmr-mq(lg) {
        box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
        margin: 0 0 -75px;
      }
    }
  }


  // .plmr-c-collection-slider__image
  @include element('image') {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }


  // .plmr-c-collection-slider__content
  @include element('content') {
    background: plmr-color('base', 'white');
    box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
    color: plmr-color('text', 'secondary');
    display: block;
    font-size: 0;
    height: auto;
    left: 50%;
    min-height: 270px;
    padding: 30px 30px 40px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 73.33%;
    z-index: 2;


    @include plmr-mq(md) {
      min-height: 385px;
      padding: 40px 40px 50px;
      width: 450px;
    }


    @include plmr-mq(lg) {
      min-height: 395px;
    }
  }
}


// .plmr-c-collection-slider-nav
@include plmr-component('collection-slider-nav') {
  background: transparent;
  height: auto;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: auto;

  //.plmr-c-collection-slider-nav__slider
  @include element('slider') {
    background: plmr-color('base', 'white');
    box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
    color: plmr-color('text', 'secondary');
    display: block;
    font-size: 0;
    height: auto;
    left: 50%;
    padding: 30px 30px 40px;
    pointer-events: all;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 73.33%;
    z-index: 2;


    &.slick-initialized {
      .plmr-c-collection-slider-nav__slide {
        display: block;
      }
    }


    @include plmr-mq(md) {
      padding: 40px 40px 50px;
      width: 450px;
    }
  }


  //.plmr-c-collection-slider-nav__slide
  @include element('slide') {
    &:not(:first-child) {
      display: none;
    }
  }


  // .plmr-c-collection-slider__eyebrow
  @include element('eyebrow') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'secondary');
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding-bottom: 10px;


    @include plmr-mq(md) {
      font-size: 19px;
      padding-bottom: 20px;
    }
  }


  // .plmr-c-collection-slider-nav__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 28px;
    line-height: 36px;
    margin: 0;


    @include plmr-mq(md) {
      font-size: 36px;
      line-height: 48px;
    }
  }


  // .plmr-c-collection-slider-nav__text
  @include element('text') {
    @include plmr-mq(md) {
      font-size: 16px;
      line-height: 24px;
      padding-top: 20px;
    }
  }


  // .plmr-c-collection-slider-nav__button
  @include element('button') {
    padding-top: 20px;
  }



  // .plmr-c-collection-slider-nav__dots
  @include element('dots') {
    padding-top: 40px;
  }
}


/**
@section Light Arrows
@sectionof Carousel Collection Slider
@page Carousels Carousel Collection Slider
@example
_markup/carousel-collection-slider-light-arrow.hbs
*/
