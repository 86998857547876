

@include block('innovation-animations') {

  @include child('image') {
    display: block;
    height: auto;
    width: 100%;
    max-width: 950px;


    @include modifier('magnetix') {
      max-width: 1144px;
    }

    @include modifier('pressmark') {
      max-width: 1154px;
    }
  }

}
