/**
@doc _docs/background-colors.md
@section Background Colors
@childof Utilities
@page Background Colors
@status ready
@example
_markup/background-colors.hbs
*/


// .plmr-u-bg--#${plmr-colors key}-#{$plmr-colors variant}
// .plmr-u-bg--brand-primary
@include plmr-utility('bg') {
  @include plmr-modifiers($plmr-colors, 'background-color');


  // .plmr-u-bg--black
  @include modifier('black') {
    background-color: plmr-color('base', 'black');
  }


  // .plmr-u-bg--transparent
  @include modifier('transparent') {
    background-color: transparent;
  }

}
