
// .plmr-c-banner-collage
@include plmr-component('banner-collage') {
  background: plmr-color('background', 'secondary');
  position: relative;
  z-index: 1;

  // .plmr-c-banner-collage__container
  @include element('container') {
    @extend .plmr-l-container;
    padding: 0;
    position: relative;
  }

  // .plmr-c-banner-collage__cta
  @include element('cta') {
    display: block;
    padding: 0;
  }

  // .plmr-c-banner-collage__description
  @include element('description') {
    background-color: plmr-color('base', 'white');
    max-width: plmr-config('container', 'max-width');
    opacity: 1;
    padding: 30px 192px 145px 25px;
    position: relative;
    transition: all 0.75s ease;
    z-index: 2;


    @include plmr-mq(md) {
      margin: -54px auto 0;
      min-height: 305px;
      padding: 40px 360px 60px 30px;
      width: calc(100% - 110px);
    }


    @include plmr-mq(lg) {
      margin: -130px auto 0;
      min-height: 235px;
      padding: 40px 511px 50px 50px;
      width: calc(100% - 180px);
    }

    // .plmr-c-banner-collage__description._hidden
    @include plmr-state('hidden') {
      opacity: 0;
    }

  }

  // .plmr-c-banner-collage__eyebrow
  @include element('eyebrow') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('text', 'tertiary');
    display: none;
    font-size: 1.1428571429rem;
    font-style: italic;
    line-height: 1.5rem;
    margin: 0;
    padding-bottom: 15px;

    @include plmr-mq(md) {
      display: block;
    }

  }

  // .plmr-c-banner-collage__image
  @include element('image') {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;

    @supports(object-fit: cover) {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }


  }

  // .plmr-c-banner-collage__item
  @include element('item') {
    box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
    font-size: 0;
    opacity: 1;
    position: relative;
    transform: translateX(0);
    transition: all 0.75s ease;

    // .plmr-c-banner-collage__item--large
    @include modifier('large') {
      box-shadow: none;
      width: 100%;
      z-index: 1;

      @include plmr-mq(md) {
        height: 413px;
        width: 77%;

        // Force the image to fit the container height
        .slick-slider,
        .slick-list,
        .slick-track,
        .slick-slide {
          height: 100%;
        }

      }


      @include plmr-mq(lg) {
        height: 700px;
      }

    }

    @include modifier('large-v2') {
      @include plmr-mq(md) {
        height: 413px;
        width: 100%;
      }
    }

    // .plmr-c-banner-collage__item--medium
    @include modifier('medium') {
      position: absolute;
      right: 0;
      top: 230px;
      width: 172px;
      z-index: 3;


      @include plmr-mq(md) {
        height: 399px;
        top: 47px;
        width: 293px;
      }


      @include plmr-mq(lg) {
        height: 550px;
        right: 40px;
        top: 52px;
        width: 404px;
      }


    }

    // .plmr-c-banner-collage__item--small
    @include modifier('small') {
      display: none;
      z-index: 4;


      @include plmr-mq(md) {
        display: block;
        height: 147px;
        position: absolute;
        right: 85px;
        top: 413px;
        width: 298px;
      }


      @include plmr-mq(lg) {
        height: 200px;
        right: 142px;
        top: 500px;
        width: 404px;
      }


    }

    // .plmr-c-banner-collage__item._hidden
    @include plmr-state(hidden) {
      opacity: 0;
      transform: translateX(-20px);
    }


  }

  // .plmr-c-banner-collage__nav
  @include element('nav') {
    @include plmr-font-face($family: 'italic');
    bottom: 75px;
    font-size: 0.875rem;
    font-style: italic;
    left: 25px;
    line-height: 30px;
    padding: 0 42px;
    position: absolute;

    // .plmr-c-banner-collage__nav .plmr-c-slider__arrow--prev
    .plmr-c-slider__arrow--prev {
      left: 0;
    }

    // .plmr-c-banner-collage__nav .plmr-c-slider__arrow--next
    .plmr-c-slider__arrow--next {
      right: 0;
    }

    @include plmr-mq(md) {
      bottom: auto;
      left: auto;
      right: 115px;
      top: 230px;
    }

    @include plmr-mq(lg) {
      right: 189px;
      top: 160px;
    }

  }

  // .plmr-c-banner-collage__nav-text
  @include element('nav-text') {
    display: block;
  }

  // .plmr-c-banner-collage__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.5rem;
    line-height: 1.333;
    margin: 0;
    padding-bottom: 20px;

    @include plmr-mq(md) {
      font-size: 2rem;
      line-height: 1.125;
    }


  }

  // .plmr-c-banner-collage__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
  }


}

/**
@doc _docs/banner-collage-small.md
@section Banner Collage Small
@childof Banners
@page Banners Banner Collage Small
@status ready
@hippo banner
@example
_markup/banner-collage-small.hbs
*/

// .plmr-c-banner-collage-small
@include plmr-component('banner-collage-small') {
  //padding: 0;

  @include plmr-mq(md) {
    padding-bottom: 50px;
  }

  &::before {
    @include plmr-pseudo;
    background: plmr-color('brand', 'tertiary');
    bottom: 0;
    height: 50px;
    left: 50%;
    transform: translate(-50%, 25px);
    width: 1px;
    z-index: 3;

    @include plmr-mq(md) {
      height: 75px;
      transform: translate(-50%, -13px);
    }
  }

  // .plmr-c-banner-collage-small__item
  @include element('item') {

    // .plmr-c-banner-collage-small__item--large
    @include modifier('large') {
      height: 275px;

      @include plmr-mq(md) {
        height: 363px;
      }

      @include plmr-mq(lg) {
        height: 450px;
      }
    }


    // .plmr-c-banner-collage-small__item--medium
    @include modifier('medium') {
      box-shadow: -10px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
      height: 175px;
      width: 172px;

      @include plmr-mq(md) {
        box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
        height: 290px;
        width: 293px;
      }

      @include plmr-mq(lg) {
        height: 400px;
        width: 404px;
      }
    }

    // .plmr-c-banner-collage-small__item--small
    @include modifier('small') {
      @include plmr-mq(md) {
        right: 115px;
        top: 266px;
        width: 208px;
      }

      @include plmr-mq(lg) {
        height: 177px;
        right: 295px;
        top: 273px;
        width: 251px;
      }
    }
  }

  // .plmr-c-banner-collage__description
  @include element('description') {
    min-height: 0;
    padding-bottom: 70px;

    @include plmr-mq(md) {
      min-height: 0;
      padding-bottom: 40px;
    }

    @include plmr-mq(lg) {
      margin-top: -42px;
      min-height: 0;
      padding: 30px 410px 30px 40px;
    }
  }
  @include element('description-v2') {
    padding-bottom: 35px;
  }


  // .plmr-c-banner-collage-small__title
  @include element('eyebrow') {
    display: block;

    @include plmr-mq(md) {
      font-size: 1.2857142857rem;
    }

    @include plmr-mq(lg) {
      display: inline-block;
      padding: 10px 40px 10px 0;
      vertical-align: middle;
    }
  }

  @include element('eyebrow-v2') {

    @include plmr-mq(md) {
      font-size: 1.6rem;
      font-style: normal;
    }

  }

  // .plmr-c-banner-collage-small__cta
  @include element('cta') {
    display: block;

    @include plmr-mq(lg) {
      display: inline-block;
      padding: 10px 0;
      vertical-align: middle;
    }
  }

}


/**
@doc _docs/banner-collage-small--with-text.md
@section With Text
@sectionof Banner Collage Small
@page Banners Banner Collage Small
@example
_markup/banner-collage-small--with-text.hbs
*/
// .plmr-c-banner-collage-small
@include plmr-component('banner-collage-small') {
  // .plmr-c-banner-collage-small--with-text
  @include modifier('with-text') {
    padding-bottom: 30px;

    // .plmr-c-banner-collage-small--with-text::before
    &::before {
      transform: translate(-50%, -5px);
    }

    .plmr-c-banner-collage-small__item--medium {
      display: none;

      @include plmr-mq(md) {
        display: block;
      }
    }

    .plmr-c-banner-collage-small__description {
      margin: -20px auto 0;
      padding: 25px 20px 50px;
      width: (325 / 375 * 100%);

      @include plmr-mq(md) {
        margin-top: -54px;
        padding: 40px 30px 50px;
      }

      @include plmr-mq(lg) {
        margin-top: -42px;
        padding: 40px 50px 50px;
      }
    }

    .plmr-c-banner-collage-small__eyebrow {
      padding-bottom: 10px;
    }

    .plmr-c-banner-collage-small__title {
      font-size: 2rem;
      line-height: 2.571rem;
      padding-bottom: 15px;

      @include plmr-mq(md) {
        font-size: 2.571rem;
        line-height: 3.428rem;
      }
    }
  }
}


/**
@doc _docs/banner-collage-small--simplified.md
@section Simplified
@sectionof Banner Collage Small
@page Banners Banner Collage Small
@example
_markup/banner-collage-small--simplified.hbs
*/
// .plmr-c-banner-collage-small
@include plmr-component('banner-collage-small') {
  // .plmr-c-banner-collage-small--simplified
  @include modifier('simplified') {
    .plmr-c-banner-collage-small__description {
      background-color: transparent;
      padding-bottom: 98px;
      padding-right: calc(222 / 375 * 100%);

      @include plmr-mq(md) {
        margin-top: -70px;
        padding-bottom: 30px;
        padding-top: 0;
        width: 100%;
      }
    }

    .plmr-c-banner-collage-small__cta {
      @include plmr-mq(lg) {
        padding: 0;
      }
    }

    .plmr-c-banner-collage-small__description {
      @include plmr-mq(lg) {
        padding-right: 455px;
      }
    }
  }
}


/**
@doc _docs/banner-collage-small--three-image.md
@section Three Images
@sectionof Banner Collage Small
@page Banners Banner Collage Small
@example
_markup/banner-collage-small--three-image.hbs
*/
@include plmr-component('banner-collage-small') {
  // .plmr-c-banner-collage-small--three-image
  @include modifier('three-image') {

    .plmr-c-banner-collage-small__item--medium {
      @include plmr-mq(lg) {
        height: 358px;
        top: 50px;
        width: 455px;
      }
    }

    .plmr-c-banner-collage-small__item--large {
      @include plmr-mq(lg) {
        width: 65.5%;
      }

    }




    .plmr-c-banner-collage-small__description {
      @include plmr-mq(lg) {
        padding-right: 455px;
      }
    }
  }
}
