// Inspired by
// http://flexboxgrid.com/
// https://codepen.io/absolutholz/post/flex-box-grid-with-sass-mixins



@mixin plmr-flex-row() {
  align-items: flex-start;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -(plmr-config('container', 'gutter-width') / 2);
  margin-right: -(plmr-config('container', 'gutter-width') / 2);
  min-width: plmr-config('container', 'min-width');
}



@mixin plmr-flex-row-reverse() {
  flex-direction: row-reverse;
}



@mixin plmr-flex-row-alignment-horizontal($alignment) {
  $justify: inherit;

  @if ( $alignment == center ) {
    $justify: center;
  } @else if ( $alignment == end ) {
    $justify: flex-end;
  } @else if ($alignment == start ) {
    $justify: flex-start;
  }

  justify-content: $justify;
}



@mixin plmr-flex-row-alignment-vertical ($alignment) {

  @if ($alignment == middle) {
    align-items: center;
  } @else if ( $alignment == bottom ) {
    align-items: flex-end;
  } @else if ( $alignment == stretch ) {
    align-items: stretch;
  } @else {
    align-items: flex-start;
  }
}



@mixin plmr-flex-row-spacing($spacing) {
  justify-content: $spacing;
}



@mixin plmr-flex-column($reverse: false) {
  @include plmr-flex-column-direction($reverse);
  flex: 0 1 auto;
  padding: 0 (plmr-config('container', 'gutter-width') / 2) plmr-config('container', 'gutter-width');
}



@mixin plmr-flex-column-direction ($reverse: false) {
  @if ($reverse) {
    flex-direction: column-reverse;
  } @else {
    flex-direction: column;
  }
}



@mixin plmr-flex-column-span ($columns-to-span: 12) {

  @if ($columns-to-span == auto) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

  } @else {
    $span-percentage: $columns-to-span / plmr-config('grid', 'columns');

    flex-basis: $span-percentage * 100%;
    max-width: $span-percentage * 100%;
  }
}



@mixin plmr-flex-column-offset ($columns-to-offset) {
  margin-left: ($columns-to-offset / plmr-config('grid', 'columns')) * 100%;
}



@mixin plmr-flex-column-order ($position) {
  @if ($position == last) {
    $position: 9999;
  } @else if ($position == first) {
    $position: -9999;
  }

  order: $position;
}



@mixin plmr-flex-column-reverse() {
  flex-direction: column-reverse;
}


@mixin build-plmr-flex-grid() {

  @each $name, $width in $plmr-breakpoints {


    @include plmr-mq($name) {


      .plmr-l-flex-col-#{$name} {

        @include plmr-flex-column-span(auto);


        @for $i from 1 through plmr-config('grid', 'columns') {
          &-#{$i} {
            @include plmr-flex-column-span($i);
          }

          &-offset-#{$i} {
            @include plmr-flex-column-offset($i);
          }
        }

      }


      .plmr-l-flex {

        &-start-#{$name} {
          @include plmr-flex-row-alignment-horizontal(start);
        }

        &-center-#{$name} {
          @include plmr-flex-row-alignment-horizontal(center);
        }

        &-end-#{$name} {
          @include plmr-flex-row-alignment-horizontal(end);
        }

        &-top-#{$name} {
          @include plmr-flex-row-alignment-vertical(top);
        }

        &-middle-#{$name} {
          @include plmr-flex-row-alignment-vertical(middle);
        }

        &-bottom-#{$name} {
          @include plmr-flex-row-alignment-vertical(bottom);
        }

        &-stretch-#{$name} {
          @include plmr-flex-row-alignment-vertical(stretch);
        }

        &-around-#{$name} {
          @include plmr-flex-row-spacing(space-around);
        }

        &-between-#{$name} {
          @include plmr-flex-row-spacing(space-between);
        }

        &-first-#{$name} {
          @include plmr-flex-column-order(first);
        }

        &-last-#{$name} {
          @include plmr-flex-column-order(last);
        }



      }

    }

  }

}
