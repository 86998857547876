@include block('forgot-password') {
  padding-bottom: 3em;

  @include child('entry') {

  }

  @include child('submit') {

  }

  @include child('reset') {
    display: block;

    @include media($bp-sm) {
      display: inline;
    }
  }
}
