
/**
@doc _docs/banner-featured-innovation.md
@section Banner Featured Innovation
@childof Banners
@page Banners Banner Featured Innovation
@status ready
@hippo banner
*/

/**
@section Content Left
@sectionof Banner Featured Innovation
@example
_markup/banner-featured-innovation--left.hbs
*/
@include plmr-component('banner-featured-innovation') {
  @extend .plmr-l-container;
  background: plmr-color('base', 'white');
  padding: 40px 0;

  @include plmr-mq(md) {
    padding: 50px 0;
  }

  @include plmr-mq(lg) {
    padding: 60px 0;
  }

  // .plmr-c-banner-featured-innovation__hero
  @include element('hero') {
    box-shadow: 20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    margin-left: 0;
    margin-right: auto;
    position: relative;
    width: calc(100% - 25px);
    z-index: 2;

    @include plmr-mq(md) {
      width: calc(100% - 55px);
    }

    @include plmr-mq(lg) {
      // width: calc(100% - 91px);
      width: calc(100% - (91 / 1300 * 100%));
    }
  }

  // .plmr-c-banner-featured-innovation__content
  @include element('content') {
    background: plmr-color('base', 'white');
    padding: 30px 25px 52px;

    @include plmr-mq(md) {
      background: transparent;
      background-image: linear-gradient(-90deg, rgba(plmr-color('base', 'white'), 0) 1%, rgba(plmr-color('base', 'white'), 0.8) 62%, plmr-color('base', 'white') 99%);
      height: 100%;
      max-width: 500px;
      padding: 85px 237px 85px 55px;
      position: relative;
      width: 70%;
      z-index: 2;
    }

    @include plmr-mq(lg) {
      max-width: 605px;
      padding: 205px 130px 205px 91px;
    }
  }

  // .plmr-c-banner-featured-innovation__title
  @include element('title') {

    @extend .plmr-c-banner__title, .plmr-c-banner__title--italic;
  }


  @include element('title-v2') {
    font-style: normal;
    color: #5b7f95;
  }

  // .plmr-c-banner-featured-innovation__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
    padding-top: 15px;
  }

  // .plmr-c-banner-featured-innovation__button
  @include element('button') {
     padding-top: 30px;

    // .plmr-c-banner-featured-innovation__button--bar
    @include modifier('bar') {
      padding-top: 20px;

      @include plmr-mq(lg) {
        padding-left: 40px;
        padding-top: 0;
      }
    }
  }

  // .plmr-c-banner-featured-innovation__image
  @include element('image') {
    height: auto;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    width: 100%;

    @include plmr-mq(md) {
      left: 0;
      min-height: 100%;
      padding-top: 0;
      position: absolute;
      top: 0;
      z-index: 1;
    }

    // .plmr-c-banner-featured-innovation__image--src
    @include modifier('src') {
      height: auto;
      left: 0;
      max-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 0;
      width: auto;

      @include plmr-mq(md) {
        left: 50%;
        max-width: none;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    // .plmr-c-banner-featured-innovation__image--small
    @include modifier('small') {
      display: none;

      @include plmr-mq(md) {
        bottom: -88px;
        display: block;
        height: 150px;
        left: auto;
        min-height: 0;
        right: 40px;
        top: auto;
        width: 178px;
        z-index: 3;

        .plmr-c-banner-featured-innovation__image--src {
          max-width: 100%;
          min-height: 0;
        }
      }

      @include plmr-mq(lg) {
        bottom: -60px;
        height: 275px;
        right: 50px;
        width: 302px;

        // .plmr-c-banner-featured-innovation__image--src {
        //   margin-top: -20px;
        //   transform: translate(-50%, 0%);
        // }
      }
    }
  }

  // .plmr-c-banner-featured-innovation__bar
  @include element('bar') {
    background: plmr-color('brand', 'primary');
    color: plmr-color('base', 'white');
    margin-left: auto;
    margin-right: 0;
    margin-top: -50px;
    padding: 80px 30px 40px;
    position: relative;
    width: calc(100% - 25px);
    z-index: 1;

    @include plmr-mq(md) {
      padding: 80px 40px 40px;
      width: calc(100% - 55px);
    }

    @include plmr-mq(lg) {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-top: -80px;
      padding: 118px 50px 45px;
      width: calc(100% - 91px);
    }
  }

  // .plmr-c-banner-featured-innovation__name
  @include element('name') {
    font-size: 1.143rem;
    line-height: 1.286rem;
    padding: 0;

    // .plmr-c-banner-featured-innovation__name--bold
    @include modifier('bold') {
      @include plmr-font-face($family: 'bold');
    }

    @include plmr-mq(md) {
      max-width: 365px;
    }
  }

}

/**
@section Content Right
@sectionof Banner Featured Innovation
@example
_markup/banner-featured-innovation--right.hbs
*/
@include plmr-component('banner-featured-innovation') {

  // .plmr-c-banner-featured-innovation--right
  @include modifier('right') {

    .plmr-c-banner-featured-innovation__hero {
      box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
      margin-left: auto;
      margin-right: 0;
    }

    .plmr-c-banner-featured-innovation__bar {
      margin-left: 0;
      margin-right: auto;

      @include plmr-mq(md) {
        padding-left: 40%;
      }

      @include plmr-mq(lg) {
        justify-content: flex-end;
      }
    }

    .plmr-c-banner-featured-innovation__image--small {
      @include plmr-mq(md) {
        left: 40px;
        right: auto;
      }

      @include plmr-mq(lg) {
        left: 50px;
      }
    }

    .plmr-c-banner-featured-innovation__content {
      @include plmr-mq(md) {
        background-image: linear-gradient(90deg, rgba(plmr-color('base', 'white'), 0) 1%, rgba(plmr-color('base', 'white'), 0.8) 62%, plmr-color('base', 'white') 99%);
        margin-left: auto;
        margin-right: 0;
        padding-left: 237px;
        padding-right: 55px;
      }

      @include plmr-mq(lg) {
        padding-left: 211px;
        padding-right: 91px;
      }
    }
  }
}


/**
@section Without Bar
@sectionof Banner Featured Innovation
@example
_markup/banner-featured-innovation--without-bar.hbs
*/
@include plmr-component('banner-featured-innovation') {
  //.plmr-c-banner-featured-innovation--without-bar
  @include modifier('without-bar') {
    .plmr-c-banner-featured-innovation__title {
      @include plmr-font-face($family: 'light');
      color: plmr-color('brand', 'primary');
      font-size: 1.714rem;
      line-height: 32px;

      @include plmr-mq(md) {
        font-size: 2.286rem;
        line-height: 36px;
      }

      @include plmr-mq(lg) {
        padding-right: 70px;
      }
    }

    .plmr-c-banner-featured-innovation__content {
      @include plmr-mq(md) {
        background-image: linear-gradient(90deg, rgba(plmr-color('base', 'white'), 0) 1%, rgba(plmr-color('base', 'white'), 0.8) 62%, plmr-color('base', 'white') 99%);
        margin-left: auto;
        margin-right: 0;
        max-width: 550px;
        padding: 91px 30px 91px 222px;
        width: 77%;
      }

      @include plmr-mq(lg) {
        max-width: 750px;
        padding: 133px 51px 133px 244px;
      }
    }
  }
}


/**
@section Without Bar Right
@sectionof Banner Featured Innovation
@example
_markup/banner-featured-innovation--without-bar-right.hbs
*/
@include plmr-component('banner-featured-innovation') {
  //.plmr-c-banner-featured-innovation--without-bar-right
  @include modifier('without-bar-right') {
    .plmr-c-banner-featured-innovation__content {
      @include plmr-mq(md) {
        background-image: linear-gradient(-90deg, rgba(plmr-color('base', 'white'), 0) 1%, rgba(plmr-color('base', 'white'), 0.8) 62%, plmr-color('base', 'white') 99%);
        margin-left: 0;
        margin-right: auto;
        padding: 91px 222px 91px 30px;
      }

      @include plmr-mq(lg) {
        padding: 133px 244px 133px 51px;
      }
    }

    .plmr-c-banner-featured-innovation__hero {
      box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
      margin-left: auto;
      margin-right: 0;
    }
  }
}
