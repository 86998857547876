/*
Grid stuff.
@section Grid
@sectionof Layout
@page Layout
*/


/*
Grid container stuff.
@section Grid Container
@sectionof Layout.Grid
@page Layout
*/




// Inspired by Stuart Robson
// https://24ways.org/2017/design-systems-and-css-grid/
@supports (display: grid) {

  @include plmr-layout('grid-container') {
    display: grid;
    grid-gap: 0.75em;
    grid-template-columns: unquote(' [full-start] minmax(calc(calc(100% - #{plmr-config('container', 'max-width')}) / 2), 1fr) [main-start] repeat(12, [col-start] 1fr) [main-end] minmax(calc(calc(100% - #{plmr-config('container', 'max-width')}) / 2), 1fr) [full-end] ');
  }

} // end @supports (display: grid)



@supports (display: grid) {

  .plmr-l-grid-inner {
    display: grid;
    grid-column: main-start / main-end;
    grid-gap: 0.75em;
    grid-template-columns: unquote('repeat(12, [col-start] 1fr)');
  }


  .plmr-l-grid-col {
    background: #ff69b4;
    border: 1px solid #2f4f4f;
    height: 200px;
  }

} // end @supports (display: grid)
