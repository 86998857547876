/**
@section Radio Input
@childof Forms
@page Forms Radio Input
@status in progress
@example
_markup/form-radio.hbs
*/
@include plmr-component('form-radio') {
  display: inline-block;
  padding-right: 40px;
  vertical-align: middle;

  //.plmr-c-form-radio__input
  @include element('input') {
    //.plmr-c-form-radio__input:checked
    &:checked {
      & + .plmr-c-form-radio__label {
        @include plmr-font-face($family: 'bold');
        color: plmr-color('brand', 'primary');

        &::before {
          border-color: plmr-color('brand', 'primary');
          border-width: 4px;
        }
      }
    }

    //.plmr-c-form-radio__input:focus
    &:focus + .plmr-c-form-radio__label::before {
      outline: 1px dashed currentColor;
    }
  }

  //.plmr-c-form-radio__label
  @include element('label') {
    color: plmr-color('text', 'secondary');
    cursor: pointer;
    display: block;
    font-size: 16px;
    line-height: 22px;
    padding-left: 29px;
    position: relative;

    //.plmr-c-form-radio__label::before pseudo element
    &::before {
      @include plmr-pseudo;
      background-color: plmr-color('base', 'white');
      border: 1.5px solid plmr-color('accent', 'dark');
      border-radius: 50%;
      height: 16px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
    }

    //.plmr-c-form-radio__label::after pseudo element
    // &::after {
    //   @include plmr-pseudo;
    //   background-color: plmr-color('base', 'white');

    // }
  }
}
