@import "moen-homepage-video";
@import "moen-homepage-cards";
@import "moen-homepage-featured-products";
@import "moen-homepage-innovation";
@import "moen-homepage-replacement";
@import "moen-homepage-email";
@import "moen-homepage-banner";


// Global Styles for the homepage

@include block(homepage) {
  section {
    margin: 1.8em auto;
    overflow: hidden;
  }
  
  .slick-next,
  .slick-prev {
    background-position: center center;
    height: 90px;
    width: 40px;
    position: absolute;
  }

  .slick-prev {
    z-index: 1;
    background-image: url("./images/icon-arrow_left_large-dark-gray.svg");
    left: 2px;
  }

  .slick-next {
    background-image: url("./images/icon-arrow_right_large-dark-gray.svg");
    right: 2px;
  }

  .slick-arrow {
    width: 20px !important;
    @include media($bp-md) {
      width: 40px !important;
    }
  }
}
