/**
@doc _docs/cardlist-coordinating-items.md
@section Card List Coordinating Items
@childof Card Lists
@page Cardlists Cardlist Coordinating Items
@status in progress
@hippo card-list
@example
_markup/cardlist-coordinating-items.hbs
*/
@include plmr-component('cardlist-coordinating-items') {
  @extend .plmr-c-cardlist-category-callouts;

  //.plmr-c-cardlist-coordinating-items__title
  @include element('title') {
    @extend .plmr-c-cardlist-category-callouts__subtitle;
    color: plmr-color('text', 'secondary');
    padding-top: 0;
  }

  //.plmr-c-cardlist-coordinating-items__list
  @include element('list') {
    @extend .plmr-c-cardlist-category-callouts__list;
    padding-top: 30px;

    @include plmr-mq(md) {
      padding-top: 30px;
    }
  }

  //.plmr-c-cardlist-coordinating-items__item
  @include element('item') {
    @extend .plmr-c-cardlist-category-callouts__item;
    cursor: auto;

    &:hover {
      @include plmr-mq(lg) {
        .plmr-c-cardlist-coordinating-items__caption {
          background-color: transparent;
        }
      }
    }
  }

  //.plmr-c-cardlist-coordinating-items__caption
  @include element('caption') {
    @extend .plmr-c-cardlist-category-callouts__caption;
    background-color: transparent;
  }

  //.plmr-c-cardlist-coordinating-items__image-container
  @include element('image-container') {
    @extend .plmr-c-cardlist-category-callouts__image-container;
    height: 65px;

    @include plmr-mq(md) {
      height: 80px;
    }

    @include plmr-mq(lg) {
      height: 136px;
    }
  }

  //.plmr-c-cardlist-coordinating-items__image
  @include element('image') {
    @extend .plmr-c-cardlist-category-callouts__image;
  }

  //.plmr-c-cardlist-coordinating-items__icon
  @include element('icon') {
    color: plmr-color('brand', 'primary');
    height: 58px;
    margin-bottom: 15px;
    width: auto;

    @include plmr-mq(md) {
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
    }
  }

  //.plmr-c-cardlist-coordinating-items__text
  @include element('text') {
    color: plmr-color('text', 'primary');
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding-bottom: 0;
    padding-top: 10px;
  }

  //.plmr-c-cardlist-coordinating-items__anchor
  @include element('anchor') {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.143rem;
    margin-top: 20px;
  }

  //.plmr-c-cardlist-coordinating-items__cta
  @include element('cta') {
    @extend .plmr-c-cardlist-category-callouts__cta;
  }
}


/**
@doc _docs/cardlist-coordinating-items--with-icon.md
@section With Icon
@sectionof Card List Coordinating Items
@example
_markup/cardlist-coordinating-items--with-icon.hbs
*/
@include plmr-component('cardlist-coordinating-items') {
  //.plmr-c-cardlist-coordinating-items--with-icon
  @include modifier('with-icon') {
    padding-top: 50px;

    .plmr-c-cardlist-coordinating-items__caption {
      color: plmr-color('brand', 'primary');
      font-size: 1.285rem;
      line-height: 1.714rem;
      margin-top: 0;

      @include plmr-mq(md) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .plmr-c-cardlist-coordinating-items__item {
      width: 225px;

      @include plmr-mq(md) {
        // padding-top: 58px;
        padding-top: 78px;
      }
    }

    .plmr-c-cardlist-coordinating-items__list {
      padding-top: 0;
    }
  }
}
