
// .plmr-c-select-language
@include plmr-component('select-language') {
  max-width: 242px;
  position: relative;
  width: 100%;


  // .plmr-c-select-language__form
  @include element('form') {
    width: 100%;
  }


  // .plmr-c-select-language__form-select
  @include element('form-select') {
    color: plmr-color('text', 'secondary');
    display: block;
    position: relative;
    width: 100%;
  }


  // .plmr-c-select-language__input
  @include element('input') {
    @include plmr-form-input-base();
    border: 1px solid plmr-color('background', 'secondary');
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    padding: 13px 45px 13px 25px;
  }


  // .plmr-c-select-language__option
  @include element('option') {
    color: currentColor;
  }


  // .plmr-c-select-language__optgroup
  @include element('optgroup') {
    color: currentColor;
  }


  // .plmr-c-select-language::before, .plmr-c-select-language::after pseudo-elements
  &::before,
  &::after {
    border: solid transparent;
    content: '';
    display: block;
    height: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 0;
    z-index: 1;
  }


  // .plmr-c-select-language::before pseudo-element
  &::before {
    border-color: rgba(plmr-color('text', 'secondary'), 0);
    border-top-color: plmr-color('text', 'secondary');
    border-width: 7px;
    margin-left: -7px;
    right: 26px;
    // top: 15px;
  }


  // .plmr-c-select-language::after pseudo-element
  &::after {
    border-color: rgba(plmr-color('base', 'white'), 0);
    border-top-color: plmr-color('base', 'white');
    border-width: 6px;
    margin-left: -6px;
    right: 27px;
    // top: 15px;
  }

}
