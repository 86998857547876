
/**
@section Divider
@childof Spacers
@page Spacers Divider
@status ready
@example
_markup/spacer-divider.hbs
*/
@include plmr-component('spacer-divider') {
  background-color: plmr-color('accent', 'light');
  display: block;
  height: 1px;
  margin: 0 auto;
  max-width: 1016px;
  //min-width: 320px;
  position: relative;
  width: (325 / 375 * 100%);
  z-index: 1;
}


/**
@section Full Width
@sectionof Divider
@page Spacers Divider
@example
_markup/spacer-divider--full-width.hbs
*/
@include plmr-component('spacer-divider') {
  //.plmr-c-spacer-horizontal-rule--full-width
  @include modifier('full-width') {
    max-width: none;
    width: 100%;
  }
}
