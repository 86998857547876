/*
List Molecules

[Description Needed]

Styleguide 3.4
*/

/*
Supplementary Links (Primary Navigation)

Horizontal link-list used in the Kitchen and the Bath navigation panels.

markup:
<ul class="list supplementary-links">
  <li class="is-first"><a href="#" class="link link--icon--left supplementary-links__item is-first"><img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Design &amp; Planning</a></li>
  <li><a href="#" class="link link--icon--left supplementary-links__item"><img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Design Topic One</a></li>
  <li><a href="#" class="link link--icon--left supplementary-links__item"><img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Design Topic Two</a></li>
  <li><a href="#" class="link link--icon--left supplementary-links__item"><img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Design Topic Three</a></li>
</ul>

Styleguide 3.4.1
*/

@include block(supplementary-links) {
  $this-height: 45px;
  height: $this-height;
  background-color: $c_nav_suplementary;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  li {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    height: $this-height;
    &.is-first {
      font-family: $din-medium;
      background-color: darken($c_nav_suplementary, 5%);
    }
  }

  a {
    color: $c_nav_dark_gray;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    margin:0;
    line-height: $this-height;
    @include state(first) {
      text-transform: uppercase;
      padding:0 1.2em;
      color: $c_nav_dark_gray;
    }
  }
}

/*
Tools List

[Description Needed]

markup:
<ul class="list tools-list">
  <li><label class="tools-list__label">Label</li>
</ul>

Styleguide 3.4.2
*/

@include block(tools-list) {
  $icon-height: 24px; // PX units to work around ie10 bug
  @include list-base();
  @include element(label) {
    margin-bottom: 1em;
    text-transform: uppercase;
    display: block;
    font-family: $din-bold;
    color: $c_nav_dark_gray;
    column-span: all;
  }
  @include element(item) {
    padding: 0 0 .5em;
    min-height: 30px;
    &.has-border {
      padding: 1.2em 0;
      border-bottom: 1px dotted $c_nav_gray;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  @include element(link) {
    @include link-base();
    color: $c_nav_dark_gray;
    display: block;
    line-height: 1.2;
    span {
      display: block;
    }
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
  &.has-columns {
    column-count: 2;
    column-gap: $grid-gutter-width;
    min-height: 150px;
  }
  .icon {
    height: $icon-height;
    width: $icon-height; // using table-cell, ie10 is happier with the width specified. In this case, the icon is square, so it works.
    margin-right: .25 * $icon-height;
    display: inline-block;
    vertical-align: middle;
    width: 20%
  }
  span {
    display: inline-block;
    vertical-align: middle;
    width: 74%;
  }

  &.tools-list--no-image {
    span {
      display: block;
      width: 100%;
    }
  }
}

/*
Subnav List

Subnav list is used inside the design article right rail.

markup:
<nav class="subnav">
  <ul class="subnav__main list">
    <li>
      <h3 class="subnav__main-title">Design Planning Kitchen</h3>
      <ul class="subnav__child list">
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Faucet Selector
          </a>
        </li>
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Design Advice
          </a>
        </li>
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Kitchen Remodeling Tips
          </a>
        </li>
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Kitchen Design Videos
          </a>
        </li>
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Inspirational Photo Gallery
          </a>
        </li>
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Design Your Own Kitchen
          </a>
        </li>
      </ul>
    </li>
    <li>
      <h3 class="subnav__main-title">Design Rooms</h3>
      <ul class="subnav__child list">
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Faucet Selector
          </a>
        </li>
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Design Advice
          </a>
        </li>
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Kitchen Remodeling Tips
          </a>
        </li>
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Kitchen Design Videos
          </a>
        </li>
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Inspirational Photo Gallery
          </a>
        </li>
        <li>
          <a href="#" class="link link--icon--left">
            <img class="icon" src="./images/icon-arrow_right_thick.svg" alt="" aria-hidden="true">Design Your Own Kitchen
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>

Styleguide 3.4.3
*/

@include block(subnav) {
  border-left: 1px solid $c_moen_bright_gray;
  font-size: 1rem;
  margin-bottom: 1em;
  padding: 0.5em 1em .5em 1em;

  @include element(main) {
    font-size: 1em;
    line-height: 1.5em;
    & > li {
      margin-bottom: .5em;
    }
  }

  @include element(main-title) {
    color: $c_text-dark;
    font-size: 1.2em;
  }
}

/*
Error page link list

markup:
<ul class="errorpage__menu-list">
  <li><a class="arrowlink" href="">Home</a></li>
  <li><a class="arrowlink" href="">Bathroom Products</a></li>
  <li><a class="arrowlink" href="">Kitchen Products</a></li>
  <li><a class="arrowlink" href="">Where to Buy</a></li>
  <li><a class="arrowlink" href="">Customer Support</a></li>
  <li><a class="arrowlink" href="">Purchase Select Products Online</a></li>
  <li><a class="arrowlink" href="">Design &amp; Planning Bath</a></li>
  <li><a class="arrowlink" href="">Design &amp; Planning Kitchen</a></li>
  <li><a class="arrowlink" href="">Press Room</a></li>
</ul>

Styleguide 3.4.4
*/

@include block(errorpage) {
  @include element(menu-list) {
    @include list-base();
    & > li {
      margin-bottom: 1em;
      line-height: 1;
      @include media($bp-md) {
        margin-bottom: .3em;
      }
    }
  }
}

/*
List Links 

List Links used in List Component 
http://localhost:3000/section/4.21.4

markup:
<div class="list-links">
  <div class="list-links__container">
    <div class="label">Faucets</div>
    <ul class="list">
      <li><a href="#">Kitchen</a></li>
      <li><a href="#">Bar &amp; Prep</a></li>
      <li><a href="#">Beverage</a></li>
      <li><a href="#">Laundry &amp; Utility</a></li>
    </ul>
  </div>
</div>

Styleguide 3.4.5
*/

@include block('list-links') {
  margin-bottom: 1em;
  min-width: 200px;

  @include element('container'){
    margin: 0 auto;
    padding: 0 1.5em 2em 1.5em;
    @include media($bp-md) {
      // max-width: 70%;
    }
  }

  .label {
    background-color: #0090d7; 
    color: $c_white;
    display: block;
    font-family: $din-medium;
    text-decoration: none;
    margin-bottom: 1em;
    padding: .5em;
    text-transform: uppercase;
    @include modifier(plain) {
    	text-transform: none;
    }
  }

  &:empty {
    height: 0;
    margin: 0;
    min-width: 0;
  }
}
