
// .plmr-c-nav-footer-main
@include plmr-component('nav-footer-main') {
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 6%;
  padding-top: 20px;


  // screen width > 768px
  @include plmr-mq('md') {

  }


  // .plmr-c-nav-footer-main__title
  @include element('title') {
    line-height: 1;
    margin: 0 0 15px 0;
    padding: 0;
    width: 100%;
  }


  // .plmr-c-nav-footer-main__title-link
  @include element('title-link') {
    @include plmr-font-face($family: 'normal');
    @include plmr-link($color: plmr-color('base', 'white'), $is-color-hover: false);
    font-size: 16px;
    line-height: 21px;
  }


  // .plmr-c-nav-footer-main__nav
  @include element('nav') {
    display: flex;
    justify-content: space-between;
  }


  // .plmr-c-nav-footer-main__list
  @include element('list') {
    flex-direction: column;
    margin-right: 50px;
    max-width: 150px;


    // .plmr-c-nav-footer-main__list:last-child
    &:last-child {
      margin-right: 0;
    }

  }


  // .plmr-c-nav-footer-main__item
  @include element('item') {
    margin-bottom: 15px;


    // .plmr-c-nav-footer-main__item:last-child
    &:last-child {
      margin-bottom: 0;
    }

  }


  // .plmr-c-nav-footer-main__link
  @include element('link') {
    @include plmr-link($color: plmr-color('base', 'white'), $is-color-hover: false);
    display: inline-block;
    font-size: 16px;
    line-height: 21px;


    // .plmr-c-nav-footer-main__link--bold
    @include modifier('bold') {
      @include plmr-font-face($family: 'bold');
    }

  }

}
