
// .plmr-c-masthead-select-language
@include plmr-component('masthead-select-language') {


  @include plmr-state('visible-on-md') {
    display: none;

    @include plmr-mq('md') {
      display: flex;
      max-width: 180px;
    }
  }



  // .plmr-c-masthead-select-language__input
  .plmr-c-select-language__input {
    border-bottom: 0;
    border-top: 0;
  }
}
