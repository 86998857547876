@include block(tap) {
	// Landing page banner
	@include element(landing-banner) {
		visibility: hidden;
/*
		max-width: $bp-xl;
		margin:0 auto;
*/
		&.slick-initialized {
			visibility: visible;
		}
		
		.banner-slide {
			overflow: hidden;
		}
		.banner {
			position: relative;
			height: 425px;
			overflow: hidden;
			background-color: #FFF;
			@include media($bp-sm) {
				height: 500px;
			}
			@include media($bp-lg) {
				height: 600px;
			}
			@include media($bp-xl) {
				max-width: 1400px;
				margin: 0 auto;
				overflow: visible;
			}
			.banner__image-bg {
				position: absolute;
				top:0;
				left:205px;
				width: calc(100% - 205px);
				height: 100%;
				z-index: 1;
				@include media($bp-sm) {
					left: 360px;
					width: calc(100% - 360px);
				}
				@include media($bp-lg) {
					left: 500px;
					width: calc(100% - 500px);
				}
				@include media($bp-xl) {
					left:37.878787879%;
					width: 62.121212121%;
				}
				@include media(1400px) {
					
			    padding-right: calc(50vw - 700px);
			    margin-right: calc(-50vw + 700px);
			    left: auto;
			    right: 0;
			    box-sizing: content-box;

				}
				picture,img {
					position: absolute;
					top:0;
					left:0;
					bottom:0;
					width: auto;
					height: 100%;
					@include media($bp-xl) {
						width: auto;
						height: auto;
						min-width: 100%;
						min-height: 100%;
						top:50%;
						left:50%;
						transform: translate(-50%,-50%);
					}
				}
			}
			.banner__inner {
				position: absolute;
				left:0;
				top:0;
				bottom:0;
				transform: none;
				width: 250px;
				z-index: 2;
				padding-right: 45px;
				mix-blend-mode: multiply;
				@include media($bp-sm) {
					padding-right: 75px;
					width: 435px;
				}
				@include media($bp-lg) {
					padding-right: 100px;
					width: 600px;
				}
				@include media($bp-xl) {
					padding-right: 7.5757575758%;
					width: 45.454545455%;
				}
				@include media(1400px) {
					margin-left: calc(-50vw + 700px);
					padding-left: calc(50vw - 700px);
					box-sizing: content-box;
					padding-right: 0px;
				}
				img {
					margin-left: 0;
				}
				.banner__inner-left {
					background: transparent;
					display: -webkit-box;
					display: -webkit-flex;
					display: -moz-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-webkit-flex-direction: column;
					-moz-box-orient: vertical;
					-moz-box-direction: normal;
					-ms-flex-direction: column;
					flex-direction: column;
					-webkit-align-content: center;
					-ms-flex-line-pack: center;
					align-content: center;
					-webkit-box-align: start;
					-webkit-align-items: flex-start;
					-moz-box-align: start;
					-ms-flex-align: start;
					align-items: flex-start;
					-webkit-box-pack: center;
					-webkit-justify-content: center;
					-moz-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					height: 100%;
					.text-box {
						color: #FFF;
						background: transparent;						
						width: 205px;
						@include media($bp-sm) {
							width: 360px;
						}
						@include media($bp-lg) {
							width: 500px;
						}
						.text-box__inner {
							background: transparent;
							padding: 0 15px 40px 20px;
							@include media($bp-sm) {
								padding: 0 20px 40px 30px;
							}
							@include media($bp-lg) {
								padding: 0 60px 40px;
							}
							@include media(1520px) {
								padding-left: 0px;	
							}
							.text-box__pillar {
								margin-bottom: 20px;
								img {
									width: auto;
									height: 27px;
									display: inline-block;
									vertical-align: middle;
									margin:0;
									margin-right: 15px;
								}
								span {
									display: inline-block;
									vertical-align: middle;
									font: normal 14px/14px $proxima-bold;
									color: #FFF;
									text-transform: uppercase;
									letter-spacing: 0.7px;
								}
								@include media($bp-sm) {
									img {
										height: 32px;
									}
									span {
										font-size: 16px;
										line-height: 16px;
									}
								}
								@include media($bp-lg) {
									img {
										height: 34px;
									}
									span {
										font-size: 18px;
										line-height: 18px;
									}
								}
							}
							.text-box__head {
								color: #FFF !important;
								font: normal 28px/32px $playfair;
								margin-bottom: 40px;
								@include media($bp-sm) {
									font: normal 36px/40px $playfair;
									margin-bottom: 50px;
								}
								@include media($bp-lg) {
									font: normal 40px/40px $playfair;
								}
							}
							.text-box__body {
								color: #FFF !important;
							}
							.text-box__link {
								a {
									color: #FFF;
									text-decoration: none;
									text-transform: uppercase;
									font: normal 12px/15px $proxima-bold;
									letter-spacing: 0.5px;
									display: inline-block;
									vertical-align: middle;
									&:after {
										content: " ";
										display: inline-block;
										vertical-align: top;
										width: 6px;
										height: 15px;
										background-image: url(images/icon-angle_right_white.svg);
										background-repeat: no-repeat;
										background-size: 100% auto;
										background-position: 50%;
										margin-left: 10px;
									}
									
								}
							}
						}
					}
				}
			}
			
			&.banner--default {
				.banner__inner {
					background-image: linear-gradient(135deg, #00C9E8 0%, #002F5F 100%);
					.banner__inner-left {
						.text-box {
							.text-box__inner {
								.text-box__logo {
									width: 100px;
									height: auto;
									margin-bottom: 15px;
									max-height: none;
								}
								.text-box__head {
									font: normal 21px/24px $playfair;
									margin-bottom: 15px;
								}
								.text-box__body {
									font: normal 14px/18px $proxima-regular;
									margin-bottom: 0;
								}
							}
							@include media($bp-sm) {
								.text-box__inner {
									.text-box__logo {
										width: 125px;
										margin-bottom: 23px;
									}
									.text-box__head {
										font: normal 24px/28px $playfair;
									}
									.text-box__body {
										font: normal 16px/21px $proxima-regular;
									}
								}
							}
							@include media($bp-lg) {
								.text-box__inner {
									.text-box__logo {
										width: 150px;
										margin-bottom: 20px;
									}
									.text-box__head {
										font: normal 28px/30px $playfair;
									}
									.text-box__body {
										font: normal 18px/24px $proxima-regular;
									}
								}
							}
						}
					}
				}
			}
			&.banner--inspiration {
				.banner__inner {
					background-image: linear-gradient(137deg, #A62B54 50%, #882345 99%);
				}
			}
			&.banner--support {
				.banner__inner {
					background-image: linear-gradient(137deg, #53973C 50%, #427730 99%);
				}
			}
			&.banner--pros {
				.banner__inner {
					background-image: linear-gradient(137deg, #003E7D 50%, #002F5F 99%);
				}
			}
			&.banner--diy {
				.banner__inner {
					background-image: linear-gradient(137deg, #AAACAE 50%, #8B8D8E 99%);
				}
			}
			
		}
		.slick-dots {
			text-align: left;
			display: -webkit-box;
			display: -webkit-flex;
			display: -moz-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-webkit-flex-direction: row;
			-moz-box-orient: horizontal;
			-moz-box-direction: normal;
			-ms-flex-direction: row;
			        flex-direction: row;
			-webkit-box-pack: start;
			-webkit-justify-content: flex-start;
			-moz-box-pack: start;
			-ms-flex-pack: start;
			        justify-content: flex-start;
			position: absolute;
			bottom: 30px;
			left: 20px;
			z-index: 999;
			@include media($bp-sm) {
				left:30px;
			}
			@include media($bp-lg) {
				left:60px;
		    right: 60px;
		    margin: 0 auto;
		    max-width: 1400px;
			}
			li {
				width: 12px;
				height: 12px;
				margin: 0 10px 0 0;
				button {
					outline: none;
					width: 12px;
					height: 12px;
					padding:0;
					&:before {
						opacity: 1;
						background-color: #FFF;
						font-size: 0 !important;
						line-height: 0 !important;
						width: 12px;
						height: 12px;
						border-radius: 100%;
						border: solid 2px #FFF;
					}
				}
				&.slick-active button:before {
					background-color: transparent;
					border-color: #FFF;
				}
			}
				
		}
	}
	
	// Detail page banner
	@include element(detail-banner) {
		display: block;
		background-image: linear-gradient(137deg, #A62B54 50%, #882345 99%);
		@include modifier(inspiration) {
			background-image: linear-gradient(137deg, #A62B54 50%, #882345 99%);
		}
		@include modifier(diy) {
			background-image: linear-gradient(137deg, #AAACAE 50%, #8B8D8E 99%);
		}
		@include modifier(support) {
			background-image: linear-gradient(137deg, #53973C 50%, #427730 99%);
		}
		@include modifier(pros) {
			background-image: linear-gradient(137deg, #003E7D 50%, #002F5F 99%);
		}
		@include element(inner) {
			max-width: 1400px;
			position: relative;
			padding: 30px 40px;
			text-align: center;
			margin: 0 auto;
			.logo {
				display: inline-block;
				width: 70px;
				height: auto;
				margin-bottom: 15px;
			}
			.thumbnail {
				display: none;
			}
			.title {
				text-align: inherit;
				font: normal 24px/28px $playfair;
				color: #FFF;
				margin: 0;
				padding:0;
			}
		}
		@include media($bp-md){
			.tap__detail-banner__inner {
				text-align: left;
				height: 200px;
				display: -webkit-box;
				display: -webkit-flex;
				display: -moz-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-webkit-flex-direction: row;
				-moz-box-orient: horizontal;
				-moz-box-direction: normal;
				-ms-flex-direction: row;
				        flex-direction: row;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-moz-box-align: center;
				-ms-flex-align: center;
				        align-items: center;
				-webkit-align-content: flex-start;
				-ms-flex-line-pack: start;
				        align-content: flex-start;
				-webkit-box-pack: start;
				-webkit-justify-content: flex-start;
				-moz-box-pack: start;
				-ms-flex-pack: start;
				        justify-content: flex-start;
				padding-right: 275px;
				.thumbnail {
					display: block;
					position: absolute;
					height: 100%;
					width: auto;
					right: 0;
					top:0;
				}
				.logo {
					margin-right: 40px;
					margin-bottom: 0;
					width: 100px;
				}
				.title {
					font-size: 32px;
					line-height: 36px;
				}
			}
		}
		@include media($bp-lg){
			.tap__detail-banner__inner {
				height: 250px;
				.title {
					font-size: 40px;
					line-height: 45px;
				}
			}
		}
		
	}
	
	// Pillar page banner
	@include element(pillar-banner) {
		display: block;
		.banner {
			height: 100px;
			padding: 30px 0px;
			@include media($bp-lg) {
				height: 150px;
				padding: 50px;
			}
			&.banner--inspiration {
				background-image: linear-gradient(137deg, #A62B54 50%, #882345 99%);
			}
			&.banner--support {
				background-image: linear-gradient(137deg, #53973C 50%, #427730 99%);
			}
			&.banner--pros {
				background-image: linear-gradient(137deg, #003E7D 50%, #002F5F 99%);
			}
			&.banner--diy {
				background-image: linear-gradient(137deg, #AAACAE 50%, #8B8D8E 99%);
			}
			.text-box {
				text-align: center;
				color: #fff;
				img {
					display: inline-block;
					vertical-align: middle;
					height: 40px;
					width: auto;
					margin-right: 15px;
				}
				span {
					display: inline-block;
					vertical-align: middle;
					font-family: $proxima-bold;
					font-weight: normal;
					font-size: em(18);
					line-height: 40px;
					letter-spacing: 0.9px;
					text-transform: uppercase;
				}
				@include media($bp-lg) {
					img {
						height: 50px;
					}
					span {
						font-size: em(22);
						line-height: 50px;
					}
				}
			}
		}
	}
	
}