
/**
@doc _docs/banner-video.md
@section Banner Video
@childof Components.Banners
@page Banners Banner Video
@status in progress
@hippo banner
@example
_markup/banner-video.hbs
*/
@include plmr-component('banner-video') {
  padding: 40px 0;

  @include plmr-mq(md) {
    padding: 50px 0;
  }

  //.plmr-c-banner-video__button
  @include element('button') {
    background: rgba(plmr-color('base', 'white'), 0);
    border: 2px solid plmr-color('base', 'white');
    border-radius: 100%;
    bottom: 0;
    display: block;
    height: 40px;
    left: 0;
    margin: 15px;
    position: absolute;
    transition: 0.5s ease;
    width: 40px;
    z-index: 2;

    @include plmr-mq(md) {
      height: 60px;
      margin: 25px;
      width: 60px;
    }

    @include plmr-mq(lg) {
      height: 75px;
      margin: 50px;
      width: 75px;
    }

    //.plmr-c-banner-video__button::before
    &::before {
      @include plmr-pseudo;
      border-bottom: 10px solid transparent;
      border-left: 15px solid plmr-color('base', 'white');
      border-radius: 2px;
      border-top: 10px solid transparent;
      height: 0;
      left: calc(50% + 12px);
      top: calc(50% + 15px);
      transform: translate(-17px, -25px);
      z-index: 2;

      @include plmr-mq(md) {
        border-bottom: 13px solid transparent;
        border-left: 18px solid plmr-color('base', 'white');
        border-top: 13px solid transparent;
        top: calc(50% + 12px);
      }

      @include plmr-mq(lg) {
        border-bottom: 15px solid transparent;
        border-left: 23px solid plmr-color('base', 'white');
        border-top: 15px solid transparent;
        left: calc(50% + 9px);
        top: calc(50% + 10px);
      }
    }

    //.plmr-c-banner-video__button:hover
    &:hover {
      background: rgba(plmr-color('base', 'white'), 0.35);
      cursor: pointer;
    }

    //.plmr-c-banner-video__button._paused
    @include plmr-state('paused') {
      border-width: 0;
      transition: none;

      &::before {
        border-color: plmr-color('base', 'white');
        border-radius: 0;
        border-width: 0 4px;
        height: 21px;
        left: 0;
        margin-left: 11.5px;
        margin-top: 9.5px;
        top: 0;
        transform: none;
        width: 15px;

        @include plmr-mq('md') {
          border-width: 0 8px;
          height: 40px;
          margin-left: 15px;
          width: 28px;
        }

        @include plmr-mq('lg') {
          margin-left: 23px;
          margin-top: 17.5px;
        }
      }

      &:hover {
        background: transparent;
      }
    }
  }

  //.plmr-c-banner-video__video-wrap
  @include element('video-wrap') {
    height: auto;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

  //.plmr-c-banner-video__video
  @include element('video') {
    display: block;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
  }
}
