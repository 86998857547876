
// .plmr-c-brand-logo
@include plmr-component('brand-logo') {

  // .plmr-c-brand-logo__link
  @include element('link') {
    color: currentColor;


    // .plmr-c-brand-logo__link:active, .plmr-c-brand-logo__link:focus, .plmr-c-brand-logo:hover
    &:active,
    &:focus,
    &:hover {
      color: currentColor;
    }
  }
}