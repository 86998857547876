@include block(homepage) {
  $specificMobileSize: 370px;

  @include element(banner) {
    @include outer-container(100%);
    overflow: hidden;

    &-button {
      &-desktop {
        display: inline-block;
      }
      &-mobile {
        display: none;
      }

      @media (min-width: $specificMobileSize) {
        &-desktop {
          display: none;
        }
        &-mobile {
          display: inline-block;
          margin-bottom: 2em;
          position: absolute;
          bottom: 1em;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      @include media($bp-sm) {
        &-desktop {
          display: inline-block;
        }
        &-mobile {
          display: none;
        }
      }
    }

    .banner__inner {
      .button {
        width: auto;
      }
    }

    .banner__image-bg {
      height: 100%;
      img {
        height: auto;
        width:100%;
      }
    }

    .text-box {
      max-width: 300px;

      @include media($bp-md) {
        max-width: 450px;
      }

      .text-box__head {
        // margin-bottom: 0;
      }

      .text-box__subtitle {
        font-size: 2.4em;
      }

      .text-box__body {
        margin-top: .5em;
      }

      @media (min-width: $specificMobileSize) {
        background-color: rgba(139, 141, 141, 0.5);
        font-size: 14px;
        height: auto;
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        width: 210px;

        .text-box__head,
        .text-box__subtitle,
        .text-box__body {
          color: $c_white !important;
        }
      }

      @include media($bp-sm) {
        width: auto;
        font-size: 16px;
        top: 50%;
      }
    }

    .slick-dots {
      li {
        button:before {
          font-size: 1rem;
          color: $c_dove-gray;
        }
      }

      li.slick-active {
        button:before {
          color: $c_moen-dark-blue;
        }
      }

      @include media($specificMobileSize) {
        position: absolute;
        bottom: 5px;
        li {
          button:before {
            color: $c_white;
          }
        }
      }
    }

    @include modifier(video) {
      //max-width: 1300px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
      visibility: hidden;
      &.slick-initialized {
        visibility: visible;
      }
      .slick-list,.slick-track {
        transform: none;
        -webkit-perspective: inherit;
        z-index: 2;
      }
      .slick-dots {
        text-align: left;
        justify-content: flex-start;
        padding: 0 20px;
        bottom: 55px;
        position: absolute;
        z-index: 999;
        @include media($specificMobileSize) {
          bottom: 55px;
        }
        @include media($bp-md) {
          padding: 0 40px;
          bottom: 60px;
        }
        @include media($bp-lg) {
          padding: 0 60px;
          bottom: 80px;
        }
        @include media($bp-xl) {
          max-width: 1300px;
          right: 0;
          left: 0;
          margin: auto;
        }
        li {
          width: 12px;
          height: 12px;
          margin: 0 10px 0 0;
          button {
            outline: none;
            width: 12px;
            height: 12px;
            padding:0;
            &:before {
              opacity: 1;
              background-color: #FFF;
              font-size: 0 !important;
              line-height: 0 !important;
              width: 12px;
              height: 12px;
              border-radius: 100%;
              border: solid 2px $c_moen-bright-blue; // Updated the border color
            }
          }
          &.slick-active button:before {
            background-color: $c_moen-bright-blue; // Updated the background color
            border-color: $c_moen-bright-blue;
          }
        }
      }
      .banner {
        background-color: $c_concrete;
        outline: none;
        @include media($bp-md) {
          display: flex;
          flex-direction: row-reverse;
          align-items: stretch;
          align-content: stretch;
          justify-content: center;
          height: 450px;
        }
        @include media($bp-lg) {
          height: 600px;
          display: flex;
        }
        @include media($bp-xl) {
          display: block;
        }
        
        .banner__inner {
          background-color: $c_concrete;
          max-width: 1300px;
          margin: 0 auto;
          @include media($bp-md) {
            width: 50%;
            position: relative;
            left: 0px;
            z-index: 2;
            transform: none;
          }
          @include media($bp-md) {
            width: 425px;
          }
          @include media($bp-xl) {
            width: 500px;
            width: 1300px;
            background-color: transparent;
            margin: 0 auto;
          }
          &:after {
            content:' ';
            display: block;
            width: 200vw;
            height: 3000px;
            position: absolute;
            left:50%;
            transform: translate(-50%,100%);
            background-color: $c_white;
            z-index: 1;
            bottom: 30px;
          }
          .text-box {
            background-color: $c_concrete;
            color: $c_moen-gray;
            max-width: 100%;
            padding: 30px 20px;
            font-size: 16px;
            .text-box__head,
            .text-box__subtitle,
            .text-box__body {
              color: $c_moen-gray !important;
              font-size: em(16);
            }
            .text-box__head,
            .text-box__subtitle {
              font-size: em(42);
            }
            .text-box__inner {
              padding:0;
            }
            @include media ($specificMobileSize) {
              background-color: $c_concrete;
              font-size: 16px;
              height: auto;
              position: static;
              top: auto;
              transform: none;
              width: 100%;
              .text-box__head,
              .text-box__subtitle,
              .text-box__body {
                color: $c_moen-gray !important;
              }
            }
            @include media($bp-md) {
              padding: 0px 30px 50px 40px;
              position: absolute;
              top:50%;
              transform: translateY(-50%);
            }
            @include media($bp-lg) {
              padding: 0px 70px 50px 40px;
            }
            @include media($bp-xl) {
              padding: 0px 60px 50px 60px;
              width: 500px;
              .text-box__head,
              .text-box__subtitle {
                font-size: em(48);
              }
              .text-box__body  {
                font-size: em(18);
              }
            }
          } // .text-box
          .banner__inner__product-img {
            position: relative;
            bottom: 1px;
            float: right;
            margin-right: 45px;
            z-index: 2;
            img {
              max-width: 150px;
              max-height: 150px;
              width: auto;
              height: auto;
            }
            @include media($bp-md) {
              position: absolute;
              bottom:0;
              right: 50px;
              margin-right: 0;
              img {
                position: absolute;
                left:50%;
                bottom:0;
                transform: translateX(-50%);
                max-width: 195px;
                max-height: 195px;
              }
            }
            @include media($bp-lg) {
              img {
                max-width: 232px;
                max-height: 232px;
              }
            }
            @include media($bp-xl) {
              left:450px;
              right: auto;
              img {
                max-width: 300px;
                max-height: 300px;
              }
            }
          }// .banner__inner__product-img
        } // .banner__inner
        .banner__image-bg {
          width: 100%;
          height: 250px;
          overflow: hidden;
          @include media($bp-md) {
            margin-bottom: 30px;
            width: 50%;
            height: auto;
          }
          @include media($bp-lg) {
            width: calc(100% - 425px);
          }
          @include media($bp-xl) {
            position: absolute;
            right: 0;
            width: calc(100% - 500px);
            max-width: 760px;
            height: 100%;
          }

          video {
            display: none;
            @media only screen and (min-width: $bp-lg) and (orientation: landscape) {
              display: block;
              height: 100%;
              left: 50%;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              width: auto;
              & + picture {
                display: none;
              }
            }
          } // video, .vimeo-video-conatiner
          img {
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            @include media($bp-md) {
              height: 100%;
              width: auto;
            }
          } // img
        } // .banner__image-bg
      } // .banner

      // Vimeo Video Player
      .vimeo-video-container {
        display: none;
        @media only screen and (min-width: $bp-lg) and (orientation: landscape) {
          display: block;
          height: 100%;
          & + picture {
            display: none;
          }
          & > iframe {
            height: 110%;
            left: -55%;
            position: relative;
            width: 220%;
          }
        }
      }
    } // END --video
  }
}


// Also for video banner
.homepage__banner--video-outer {
  background-color: $c_concrete;
  display: block;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    display: block;
    background-color: $c_white;
    position: absolute;
    bottom:0;
    left: 50%;
    right: auto;
    width: 100vw;
    height: 30px;
    transform: translateX(-50%);
    z-index: 1;
    
  }
}




/// .banner-text-din
@include block('banner-text-din') {
  
  
  /// .banner-text-din__bold
  @include child('bold') {
    font-family: $din-bold;
    text-transform: uppercase;
  }
  
  
  /// .banner-text-din
  @include child('light') {
    font-family: $din-light;
  }
  
}
