/*
Search Results Page Navigation

The navigation is made up of a list of hyperlinks, each styled to appear as a button.

The .is-selected class is applied to the currently selected navigation link, its visual interactive state will appear as
if the link is non-responsive to hover and focus. NOTE: It is up to the developer to implement the html response & template logic to ensure the
href value in the link is void. (e.g. by implementing it as " < a href='' ")

markup:
<ul class="list list--horizontal search-results-navigation">
  <li class="search-results-navigation__item"><a href='' class="button button--blue-hover is-selected">Total Results: 68</a></li>
  <li class="search-results-navigation__item"><a href='' class="button button--blue-hover">Products: 66</a></li>
  <li class="search-results-navigation__item"><a href='' class="button button--blue-hover">Parts: 2</a></li>
  <li class="search-results-navigation__item"><a href='' class="button button--blue-hover">Site Content: 0</a></li>
</ul>

Styleguide 4.30.2
*/

.search-results-navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
  @include media($bp-sm) {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3em;
  }
}

.search-results-navigation__item {
  margin-bottom: -1px;
  @include media($bp-sm) {
    flex: 1 0 0%;
    margin-bottom: 0;
    margin-right: -1px;
    &:last-child {
      margin-right: 0;
    }
  }
  .button {
    display: block;
    padding: .7em .5em;
    font-size: 1.2em;
    &:hover,
    &:focus {
      text-decoration: none;
      font-family: $din-medium;
    }
    @include media($bp-sm) {
      padding: .5em;
      font-size: 1em;
    }
      &.is-selected {
      background-color: $c_moen-bright-blue;
      color: $c_white;
      font-family: $din-medium;
      cursor: default;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

}
