
// .plmr-c-nav-footer-cta
@include plmr-component('nav-footer-cta') {
  border-bottom: 1px solid plmr-color('base', 'white');
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  padding-bottom: 20px;
  width: 100%;


  // .plmr-c-nav-footer-cta__list
  @include element('list') {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;


    // screen width > 768px
    @include plmr-mq('md') {
      flex-direction: row;
      justify-content: space-around;
    }


  }


  // .plmr-c-nav-footer-cta__item
  @include element('item') {
    margin-bottom: 20px;



    // screen width > 768px
    @include plmr-mq('md') {
      margin-bottom: 0;
      margin-right: 30px;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      margin-right: 10%;
    }


    // .plmr-c-nav-footer-cta__item:last-child
    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }

  }


  // .plmr-c-nav-footer-cta__link
  @include element('link') {
    @include plmr-link($color: plmr-color('base', 'white'), $is-color-hover: false);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }


  // .plmr-c-nav-footer-cta__icon
  @include element('icon') {
    align-self: center;
    margin-right: 20px;


    // screen width > 768px
    @include plmr-mq('md') {
      margin-right: 15px;
      transform: scale(0.74);
    }


    // screen with > 992px
    @include plmr-mq('lg') {
      margin-right: 20px;
      transform: scale(1);
    }

  }


  // .plmr-c-nav-footer-cta__content
  @include element('content') {
    display: flex;
    flex-direction: column;
    width: 100%;


    // screen width > 992px
    @include plmr-mq('lg') {
      max-width: 240px;
    }

  }



  // .plmr-c-nav-footer-cta__title
  @include element('title') {
    @include plmr-font-face($family: 'bold');
    font-size: 16px;
    line-height: 24px;


    // screen width > 768px
    @include plmr-mq('md') {
      font-size: 14px;
      line-height: 16px;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      font-size: 16px;
      line-height: 24px;
    }

  }


  // .plmr-c-nav-footer-cta__copy
  @include element('copy') {
    font-size: 14px;
    line-height: 21px;


    // screen width > 768px
    @include plmr-mq('md') {
      font-size: 12px;
      line-height: 16px;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      font-size: 14px;
      line-height: 21px;
    }

  }

}
