
/**
@section Full Page Tabs Navigation
@childof Navigation
@page Navigation Full Page Tabs
@status in progress
@example
_markup/navigation-full-page-tabs.hbs
*/
@include plmr-component('navigation-full-page-tabs') {
  //.plmr-c-navigation-full-page-tabs__tab-menu
  @include element('tab-menu') {
    background-color: plmr-color('background', 'secondary');
    text-align: center;
    width: 100%;

    @include plmr-mq(md) {
      overflow: hidden;
    }
  }

  //.plmr-c-navigation-full-page-tabs__tab-menu-toggle
  @include element('tab-menu-toggle') {
    @include plmr-font-face($family: 'bold');
    background-color: plmr-color('brand', 'primary');
    color: plmr-color('base', 'white');
    display: block;
    letter-spacing: 1px;
    min-height: 1px;
    outline-offset: -1px;
    padding: 18px 0 24px;
    position: relative;
    text-transform: uppercase;
    width: 100%;

    @include plmr-mq(md) {
      display: none;
    }

    //.plmr-c-navigation-full-page-tabs__tab-menu-toggle::before
    &::before {
      @include plmr-pseudo;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 10px solid plmr-color('brand', 'primary');
      height: 0;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      width: 0;
    }

    .plmr-c-navigation-full-page-tabs__tab-label {
      &::after {
        display: inline-block;
      }
    }

    //.plmr-c-navigation-full-page-tabs__tab-menu-toggle._open
    @include plmr-state('open') {
      .plmr-c-navigation-full-page-tabs__tab-label {
        &::after {
          transform: rotate(135deg) scale(-1, -1);
        }
      }
    }
  }

  //.plmr-c-navigation-full-page-tabs__tabs-wrapper
  @include element('tabs-wrapper') {
    @extend .plmr-l-container;
    padding: 0;
    position: relative;
  }

  //.plmr-c-navigation-full-page-tabs__tabs
  @include element('tabs') {
    font-size: 0;
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease;

    @include plmr-mq(md) {
      margin: 0 0 -20px auto;
      max-height: none;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 30px 25px 20px;
      white-space: nowrap;
    }

    @include plmr-mq(lg) {
      padding-left: 90px;
      padding-right: 90px;
    }

    //.plmr-c-navigation-full-page-tabs__tabs._open
    @include plmr-state('open') {
      max-height: 500px;
    }
  }

  //.plmr-c-navigation-full-page-tabs__tab
  @include element('tab') {
    color: plmr-color('text', 'secondary');
    position: relative;

    @include plmr-mq(md) {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top;
    }

    //.plmr-c-navigation-full-page-tabs__tab::before
    &::before {
      @include plmr-pseudo;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 10px solid transparent;
      display: none;
      height: 0;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      width: 0;

      @include plmr-mq('md') {
        display: block;
      }
    }

    //.plmr-c-navigation-full-page-tabs__tab:first-child
    &:first-child {
      padding-top: 16px;

      @include plmr-mq(md) {
        padding: 0;
      }
    }

    //.plmr-c-navigation-full-page-tabs__tab:last-child
    &:last-child {
      padding-bottom: 16px;

      @include plmr-mq(md) {
        padding: 0;
      }
    }

    //.plmr-c-navigation-full-page-tabs__tab:hover
    &:hover {
      color: darken(plmr-color('text', 'secondary'), 8%);
      cursor: pointer;
    }

    //.plmr-c-navigation-full-page-tabs__tab._active
    @include plmr-state('active') {
      color: plmr-color('brand', 'primary');

      @include plmr-mq(md) {
        background-color: plmr-color('brand', 'primary');
        color: plmr-color('base', 'white');

        &::before {
          border-top-color: plmr-color('brand', 'primary');
        }
      }
    }
  }

  //.plmr-c-navigation-full-page-tabs__tab-button
  @include element('tab-button') {
    @include plmr-font-face($family: 'bold');
    display: block;
    font-size: 0.8571rem;
    letter-spacing: 0.92px;
    min-height: 1px;
    outline-offset: -1px;
    //overflow: hidden;
    padding: 16px 30px;
    //text-overflow: ellipsis;
    text-transform: uppercase;
    //white-space: nowrap;
    width: 100%;

    @include plmr-mq(md) {
      font-size: 1rem;
      letter-spacing: 1px;
      min-height: 75px;
      padding: 0 40px;
    }

    @include plmr-mq(lg) {
      padding: 0 50px;
    }

    //.plmr-c-navigation-full-page-tabs__tab-button:hover
    &:hover {
      cursor: pointer;
    }
  }

  //.plmr-c-navigation-full-page-tabs__tab-label
  @include element('tab-label') {
    display: block;
    margin: 0 auto;
    max-width: 300px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    //.plmr-c-navigation-full-page-tabs__tab-label::after
    &::after {
      @include plmr-pseudo;
      border-style: solid;
      border-width: 1.25px 1.25px 0 0;
      display: none;
      height: 6.85px;
      left: 15px;
      position: relative;
      top: 3.5px;
      transform: rotate(135deg);
      vertical-align: top;
      width: 6.85px;
    }

    //.plmr-c-navigation-full-page-tabs__tab-label--sub
    @include modifier('sub') {
      @include plmr-font-face($family: 'regular');

      @include plmr-mq(md) {
        display: block;
      }

      //.plmr-c-navigation-full-page-tabs__tab-label--sub::after
      &::after {
        display: none;
      }
    }
  }

  //.plmr-c-navigation-full-page-tabs__scroll-button
  @include element('scroll-button') {
    background-color: plmr-color('accent', 'light');
    color: plmr-color('accent', 'dark');
    display: none;
    height: (105 / 135 * 100%);
    line-height: 98px;
    outline-offset: -1px;
    position: absolute;
    top: 0;
    width: 40px;

    @include plmr-mq(md) {
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease;
    }

    //.plmr-c-navigation-full-page-tabs__scroll-button::before
    &::before {
      @include plmr-pseudo;
      background-image: linear-gradient(270deg, plmr-color('background', 'secondary') 0%, rgba(plmr-color('background', 'secondary'), 0) 100%);
      height: 100%;
      pointer-events: none;
      right: 100%;
      top: 0;
      width: 100px;
    }

    //.plmr-c-navigation-full-page-tabs__scroll-button:hover
    // &:hover {
    //   cursor: pointer;
    // }

    //.plmr-c-navigation-full-page-tabs__scroll-button--left
    @include modifier('left') {
      left: 0;
      transform: scale(-1, -1);
    }

    //.plmr-c-navigation-full-page-tabs__scroll-button--right
    @include modifier('right') {
      right: 0;
    }

    //.plmr-c-navigation-full-page-tabs__scroll-button._visible
    @include plmr-state('visible') {
      opacity: 1;
      pointer-events: auto;
    }
  }

  //.plmr-c-navigation-full-page-tabs__tab-panel-container
  @include element('tab-panel-container') {
    background-color: plmr-color('base', 'white');
    padding-top: 10px;

    @include plmr-mq(md) {
      margin-top: -10px;
    }
  }

  //.plmr-c-navigation-full-page-tabs__tab-panel
  @include element('tab-panel') {
    @extend .plmr-l-container;
    display: none;
    margin-top: -10px;
    padding: 0;

    //.plmr-c-navigation-full-page-tabs__tab-panel._active
    @include plmr-state('active') {
      display: block;
    }
  }

  //.plmr-c-navigation-full-page-tabs__tab-panel-subpanel
  @include element('tab-panel-subpanel') {
    display: none;

    //.plmr-c-navigation-full-page-tabs__tab-panel-subpanel._active
    @include plmr-state('active') {
      display: block;
    }
  }

  //.plmr-c-navigation-full-page-tabs__tab-panel-row
  @include element('tab-panel-row') {
    @extend .plmr-l-flex-row;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    padding-bottom: 50px;

    @include plmr-mq(md) {
      padding-bottom: 75px;
    }
  }

  //.plmr-c-navigation-full-page-tabs__tab-panel-column
  @include element('tab-panel-column') {
    @extend .plmr-l-flex-col-xs-12;
    padding: 0;

    //.plmr-c-navigation-full-page-tabs__tab-panel-column--left
    @include modifier('left') {
      @include plmr-mq(md) {
        @extend .plmr-l-flex-col-md-4;
        padding: 76px 15px 0 25px;
      }

      @include plmr-mq(lg) {
        padding-left: calc(91 / 1300 * 100%);
        padding-right: calc(47.5 / 1300 * 100%);
      }

      @include plmr-mq(max) {
        padding-left: 91px;
        padding-right: 47.5px;
      }
    }

    //.plmr-c-navigation-full-page-tabs__tab-panel-column--right
    @include modifier('right') {
      padding: 30px 25px 0;

      @include plmr-mq(md) {
        @extend .plmr-l-flex-col-md-8;
        padding: 60px 55px 0 15px;
      }

      @include plmr-mq(lg) {
        padding-left: calc(47.5 / 1300 * 100%);
        padding-right: calc(91 / 1300 * 100%);
      }

      @include plmr-mq(max) {
        padding-left: 47.5px;
        padding-right: 91px;
      }
    }
  }

  //.plmr-c-navigation-full-page-tabs__tab-banner-container
  //.plmr-c-navigation-full-page-tabs__tab-banner
  @include element('tab-banner') {
    display: none;

    //.plmr-c-navigation-full-page-tabs__tab-banner._active
    @include plmr-state('active') {
      display: block;
    }
  }
}


/**
@doc _docs/navigation-full-page-tabs--no-sublabel.md
@section No Sublabel
@sectionof Full Page Tabs Navigation
@example
_markup/navigation-full-page-tabs--no-sublabel.hbs
*/


/**
@doc _docs/navigation-full-page-tabs--two-column.md
@section Two Column
@sectionof Full Page Tabs Navigation
@example
_markup/navigation-full-page-tabs--two-column.hbs
*/


/**
@doc _docs/navigation-full-page-tabs--with-banner.md
@section With Banner
@sectionof Full Page Tabs Navigation
@example
_markup/navigation-full-page-tabs--with-banner.hbs
*/
