/**
@doc _docs/carousel-campaign-slider.md
@section Carousel Campaign Slider
@childof Carousels
@page Carousels Carousel Campiagn Slider
@hippo banner carousel
@status ready
*/


/**
@section Image Left
@sectionof Carousel Campaign Slider
@page Carousels Carousel Campiagn Slider
@example
_markup/carousel-campaign-slider-left.hbs
*/
@include plmr-component('campaign-slider') {
  background: plmr-color('base', 'white');
  padding: 30px 0;
  position: relative;

  @include plmr-mq(md) {
    padding: 60px 0;
  }

  @include plmr-mq(lg) {
    padding-bottom: 75px;
  }

  // .plmr-c-campaign-slider::before
  &::before {
    background: plmr-color('background', 'white');
    content: '';
    display: block;
    height: 385px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  // .plmr-c-campaign-slider__container
  @include element('container') {
    margin: 0 auto;
    //max-width: 1390px;
    max-width: plmr-config('container', 'max-width');
    position: relative;

    @include plmr-mq(md) {
      padding-right: 55px;
    }

    @include plmr-mq(xl) {
      padding-right: 90px;
    }

    // .plmr-c-campaign-slider__container--right
    @include modifier('right') {

      @include plmr-mq(md) {
        padding-left: 55px;
        padding-right: 0;
      }

      @include plmr-mq(xl) {
        padding-left: 90px;
        padding-right: 0;
      }
    }
  }

  // .plmr-c-campaign-slider__slider
  @include element('slider') {
    box-shadow: 20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    max-width: 1300px;
    position: relative;
    width: 100%;

    &.slick-initialized {
      .plmr-c-campaign-slider__slide:not(.slick-active) {
        display: block;
      }
    }

    // .plmr-c-campaign-slider__slider--right
    @include modifier('right') {
      box-shadow: -20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
      margin-left: auto;
      margin-right: 0;
    }
  }

  // .plmr-c-campaign-slider__slide
  @include element('slide') {
    background: plmr-color('base', 'white');

    &:not(:first-child) {
      display: none;
    }

    @include plmr-mq(lg) {
      display: flex;
      flex-direction: row;
    }
  }

  // .plmr-c-campaign-slider__flex-container
  @include element('flex-container') {
    @include plmr-mq(lg) {
      display: flex;
      flex-direction: row;
    }

    // .plmr-c-campaign-slider__flex-container--right
    @include modifier('right') {
      @include plmr-mq(lg) {
        flex-direction: row-reverse;
      }
    }
  }

  // .plmr-c-campaign-slider__image-area
  @include element('image-area') {
    height: auto;
    overflow: hidden;
    padding-top: 66.67%;
    position: relative;
    width: 100%;

    @include plmr-mq(md) {
      padding-top: 53%;
    }

    @include plmr-mq(lg) {
      padding-top: 0;
      //width: calc(100% - 455px);
      width: calc(100% - (455 / 1209 * 100%));
    }
  }

  // .plmr-c-campaign-slider__image
  @include element('image') {
    height: auto;
    left: 50%;
    max-height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: auto;

    @include plmr-mq(md) {
      min-width: 100%;
    }
  }

  // .plmr-c-campaign-slider__content
  @include element('content') {
    padding: 30px 25px 120px;
    text-align: center;

    @include plmr-mq(md) {
      padding: 60px 72px 130px;
    }

    @include plmr-mq(lg) {
      min-height: 400px;
      padding: 60px 52px 140px;
      //width: 455px;
      width: 455 / 1209 * 100%;
    }
  }

  // .plmr-c-campaign-slider__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.286rem;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 2rem;
    }
  }

  // .plmr-c-campaign-slider__copy
  @include element('copy') {
    color: plmr-color('text', 'primary');
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding-top: 10px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }
  }

  // .plmr-c-campaign-slider__button
  @include element('button') {
    padding-top: 25px;

    @include plmr-mq(md) {
      padding-top: 30px;
    }
  }

  // .plmr-c-campaign-slider__nav
  @include element('nav') {
    @include plmr-mq(md) {
      left: 0;
      transform: none;
      width: calc(100% - 55px);
    }

    @include plmr-mq(lg) {
      left: auto;
      right: 55px;
      width: 455 / 1300 * 100%;
    }

    @include plmr-mq(xl) {
      right: 90px;
    }

    // .plmr-c-campaign-slider__nav--right
    @include modifier('right') {
      @include plmr-mq(md) {
        left: auto;
        right: 0;
      }

      @include plmr-mq(lg) {
        //left: 90px;
        left: 55px;
        right: auto;
      }

      @include plmr-mq(xl) {
        left: 90px;
      }
    }
  }
}


/**
@section Image Right
@sectionof Carousel Campaign Slider
@page Carousels Carousel Campiagn Slider
@example
_markup/carousel-campaign-slider-right.hbs
*/
