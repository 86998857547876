
// PLMR Component Block
@mixin plmr-component($name) {
  @include block('plmr-c-#{$name}') {
    @content;
  }
}



// PLMR Layout Block
@mixin plmr-layout($name) {
  @include block('plmr-l-#{$name}') {
    @content;
  }
}



// PLMR Utility Block
@mixin plmr-utility($name) {
  @include block('plmr-u-#{$name}') {
    @content;
  }
}



// PLMR Object Block
@mixin plmr-object($name) {
  @include block('plmr-o-#{$name}') {
    @content;
  }
}



// PLMR Brand Block
@mixin plmr-brand($name) {
  @include block('plmr-b-#{$name}') {
    @content;
  }
}



// PLMR State
@mixin plmr-state($name) {

  &._#{$name} {
    @content;
  }
}


// PLMR Style Guide Block
@mixin plmr-styleguide($name) {
  @include block('plmr-sg-#{$name}') {
    @content;
  }
}


// PLMR Documentation Block
@mixin plmr-doc($name) {
  @include block('plmr-d-#{$name}') {
    @content;
  }
}
