
/**
Reusuable play button styling
@section Play Button
@childof Buttons
@page Buttons Play Button
@status ready
@example
_markup/button-play-icon.hbs
*/
// .plmr-c-button-play-icon
@include plmr-component('button-play-icon') {
  background: rgba(plmr-color('base', 'white'), 0);
  border: 2px solid plmr-color('base', 'white');
  border-radius: 100%;
  display: inline-block;
  height: 40px;
  margin: 15px;
  position: relative;
  transition: background 0.5s ease;
  vertical-align: middle;
  width: 40px;

  @include plmr-mq(xl) {
    height: 50px;
    margin: 25px;
    width: 50px;
  }

  // .plmr-c-card-list-lightbox__play::before
  &::before {
    @include plmr-pseudo;
    border-bottom: 10px solid transparent;
    border-left: 15px solid plmr-color('base', 'white');
    border-radius: 2px;
    border-top: 10px solid transparent;
    height: 0;
    left: calc(50% + 12px);
    top: calc(50% + 15px);
    transform: translate(-17px, -25px);
    z-index: 2;

    @include plmr-mq(xl) {
      // border-bottom: 13px solid transparent;
      // border-left: 18px solid plmr-color('base', 'white');
      // border-top: 13px solid transparent;
      // top: calc(50% + 12px);
    }
  }

  // .plmr-c-card-list-lightbox__play:hover
  &:hover {
    background: rgba(plmr-color('base', 'white'), 0.35);
    cursor: pointer;
  }
}
