/*
Form Elements

Each form element is considered an atom, with combined elements (e.g. a label and its input element) as molecules.

Styleguide 2.5
*/

/*
Text Input

Markup:
<input type="text" name="text" class="form-input form-input__text">
<input type="text" name="text" class="form-input form-input__text" value="Some Text Content aqkMLZ">
<input type="text" name="text" class="form-input form-input__text" placeholder="a placeholder">
<input type="text" name="text" class="form-input form-input__text is-disabled" placeholder="disabled" disabled>
<input type="text" name="text" class="form-input form-input__text is-error" placeholder="invalid input">
<input type="text" name="text" class="form-input form-input__text is-valid" placeholder="valid input">

Styleguide 2.5.1
*/

@include block(form-input) {
  border: 1px solid $c_moen-gray;
  background-color: $c_white;
  padding: $form-element-padding-base;
  margin: 0;
  @include element(text) {
    width: 100%;
  }
  @include state(disabled) {
    border-color: lighten($c_moen-gray, 25);
    background-color: lighten($c_moen-gray, 40);
    cursor: not-allowed;
  }

  @include state('error') {
    background-color: $c_form-invalid-bg;
    border-color: $c_form-invalid-border;
    box-shadow: inset 0 0 0 2px $c_form-invalid-border;
  }

  @include state('valid') {
    background-color: $c_form-valid-bg;
    border-color: $c_form-valid-border;
    box-shadow: inset 0 0 0 2px $c_form-valid-border;
  }
}

/*
Email Input

Markup:
<input type="email" name="email" class="form-input form-input__email">
<input type="email" name="email" class="form-input form-input__email" value="Some Text Content aqkMLZ">
<input type="email" name="email" class="form-input form-input__email" placeholder="a placeholder">
<input type="email" name="email" class="form-input form-input__email is-disabled" placeholder="disabled" disabled >
<input type="email" name="email" class="form-input form-input__email is-error" placeholder="invalid email input">
<input type="email" name="email" class="form-input form-input__email is-valid" placeholder="valid email input">

Styleguide 2.5.2
*/

@include block(form-input) {
  @include element(email) {
  }
}


/*
Number Input

Markup:
<input type="number" name="number" class="form-input form-input__number">
<input type="number" name="number" class="form-input form-input__number" value="1245">
<input type="number" name="number" class="form-input form-input__number" placeholder="a placeholder">
<input type="number" name="number" class="form-input form-input__number is-disabled" placeholder="disabled" disabled>
<input type="number" name="number" class="form-input form-input__number is-error" placeholder="invalid number input">
<input type="number" name="number" class="form-input form-input__number is-valid" placeholder="valid number input">

Styleguide 2.5.3
*/
@include block(form-input) {
  @include element(number) {
    width: 100% !important;
  }
}


// Turn off Number Input Spinners
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/*
Search Input

Markup:
<input type="search" name="search" class="form-input form-input__search" results="3">
<input type="search" name="search" class="form-input form-input__search" value="Some Text Content aqkMLZ" results="3">
<input type="search" name="search" class="form-input form-input__search" placeholder="a placeholder" results="3">
<input type="search" name="search" class="form-input form-input__search is-disabled" placeholder="disabled" disabled results="3">
<input type="search" name="search" class="form-input form-input__search is-error" results="3" placeholder="invalid search input">
<input type="search" name="search" class="form-input form-input__search is-valid" results="3" placeholder="valid search input">

Styleguide 2.5.4
*/

@include block(form-input) {
  @include element(search) {
  }
}


@include block(form-input) {
  @include element('browse') {
    width: 100% !important;

    @include child('preview') {
      display: block;
      height: auto;
      margin: 0 auto;
      max-height: 36px;
      max-width: 75px;
    }

    @include child('remove') {
      font-size: em(12);
      line-height: rem(36);
    }
  }
}

@include block('form-input') {

  @include child('textarea') {
    height: rem(150);
    resize: none;
  }
}

/*
Select Input

Markup:
<select name="catordog" class="form-select">
<option disabled="disabled" selected="selected">Select a Pet...</option>
 <optgroup label="Cats">
   <option>Tiger</option>
   <option>Leopard</option>
   <option>Lynx</option>
 </optgroup>
 <optgroup label="Dogs">
   <option>Grey Wolf</option>
   <option>Red Fox</option>
   <option>Fennec</option>
 </optgroup>
</select>
<br>
<br>
<div class="form-select--blue">
  <select name="catordog" class="form-select">
  <option disabled="disabled" selected="selected">Select a Pet...</option>
   <optgroup label="Cats">
     <option>Tiger</option>
     <option>Leopard</option>
     <option>Lynx</option>
   </optgroup>
   <optgroup label="Dogs">
     <option>Grey Wolf</option>
     <option>Red Fox</option>
     <option>Fennec</option>
   </optgroup>
  </select>
</div>
<br>
<br>
<div class="form-select--blue  form-select--blue-border">
  <select name="catordog" class="form-select">
  <option disabled="disabled" selected="selected">Select a Pet...</option>
   <optgroup label="Cats">
     <option>Tiger</option>
     <option>Leopard</option>
     <option>Lynx</option>
   </optgroup>
   <optgroup label="Dogs">
     <option>Grey Wolf</option>
     <option>Red Fox</option>
     <option>Fennec</option>
   </optgroup>
  </select>
</div>
<br>
<br>
<div class="form-select-wrapper">
  <select class="form-select">
    <option value="">Select</option>
    <option value="1">Option 1</option>
    <option value="1">Option 2</option>
    <option value="1">Option 3</option>
    <option value="1">Option 4</option>
  </select>
</div>
<br>
<br>
<div class="form-select-wrapper is-error">
  <select class="form-select">
    <option value="">Invalid Select</option>
    <option value="1">Option 1</option>
    <option value="1">Option 2</option>
    <option value="1">Option 3</option>
    <option value="1">Option 4</option>
  </select>
</div>
<br><br>
<div class="form-select-wrapper is-valid">
  <select class="form-select">
    <option value="">Valid Select</option>
    <option value="1">Option 1</option>
    <option value="1">Option 2</option>
    <option value="1">Option 3</option>
    <option value="1">Option 4</option>
  </select>
</div>
<br>
<br>
This is a select using uniform.js
<div class="selector icon-select-arrow-down">
  <span>Uniform Select</span>
  <select class="form-select" name="catordog">
  <option disabled="disabled" selected="selected">Select a Pet...</option>
   <optgroup label="Cats">
     <option>Tiger</option>
     <option>Leopard</option>
     <option>Lynx</option>
   </optgroup>
   <optgroup label="Dogs">
     <option>Grey Wolf</option>
     <option>Red Fox</option>
     <option>Fennec</option>
   </optgroup>
  </select>
</div>
<br>
<br>
<div class="selector selector--unrounded icon-select-arrow-down">
  <span>Uniform Select</span>
  <select class="form-select" name="catordog">
  <option disabled="disabled" selected="selected">Select a Pet...</option>
   <optgroup label="Cats">
     <option>Tiger</option>
     <option>Leopard</option>
     <option>Lynx</option>
   </optgroup>
   <optgroup label="Dogs">
     <option>Grey Wolf</option>
     <option>Red Fox</option>
     <option>Fennec</option>
   </optgroup>
  </select>
</div>
<br>
<br>
<div class="form-select-wrapper form-select-wrapper--padded">
  <select class="form-select">
    <option value="">Select</option>
    <option value="1">Option 1</option>
    <option value="1">Option 2</option>
    <option value="1">Option 3</option>
    <option value="1">Option 4</option>
  </select>
</div>

Styleguide 2.5.5
*/

@mixin sel-blue() {
  background-color: $c_moen-bright-blue;
  border: 1px solid $c_pattens_blue;
  position: relative;
  .form-select {
    appearance: none;
    background-color: $c_moen-bright-blue;
    border:0;
    border-radius: 0;
    color: $c_white;
    font-family: $din-regular;
    padding: 0 .375em;
    &::-ms-expand {
      display: none;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: em(5px) solid transparent;
    border-right: em(5px) solid transparent;
    border-top: em(5px) solid $c_white;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: .5em;
  }
}

@include block(form-select) {
  // background: $c_white; /// Probably need to break this out to a modifier
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  padding: $form-element-padding-base;
  // undo the hard reset in sanitize
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  width: 100%;
  &::-ms-expand {
    display: block; // Edge 12+, Internet Explorer 11-
  }
  @include element(language) {
    position: relative;
    overflow: hidden;
  }
  @include modifier(blue) {
    @include sel-blue();
  }
  @include modifier(blue-border) {
    @include sel-blue();
    border: 1px solid $c_pattens_blue;
  }
}

@include block(form-select-wrapper) {
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  overflow: hidden;
  position: relative;

  @include state(disabled) {
    border-color: lighten($c_moen-gray, 25);
    background-color: lighten($c_moen-gray, 40);
    cursor: not-allowed;

    &::before {
      background-image: url('images/icon-down-open_light-gray.svg');
    }
  }

  @include state('error') {
    background-color: $c_form-invalid-bg;
    border-color: $c_form-invalid-border;
    box-shadow: inset 0 0 0 2px $c_form-invalid-border;
  }

  @include state('valid') {
    background-color: $c_form-valid-bg;
    border-color: $c_form-valid-border;
    box-shadow: inset 0 0 0 2px $c_form-valid-border;
  }

  &::before {
    background: url('images/icon-down-open_gray.svg') 0 50% no-repeat;
    content: '';
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    // z-index: 2;
  }

  .form-select {
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 100%;
    position: relative;
    width: 100%;
    // z-index: 1;

    &::-ms-expand {
      display: none;
    }
  }


  @include modifier('padded') {
    background-color: $c_white;
    border-color: $c_moen-bright-gray;
    height: 46px;

    &::before {
      background-image: url('images/global/icon-down-open_blue.svg');
    }

  }


  /// .form-select-wrapper--blue
  @include modifier('blue') {
    background-color: $c_moen-bright-blue;
    border-color: $c_white;
    color: $c_white;


    /// .form-select-wrapper--blue::before pseudo-element
    &::before {
      background-image: url('images/icon-down-open_white.svg');
    }

  }

}

@include block(form-label) {
  @include modifier(is-block) {
    padding-bottom: $spacing-base-v;
    display: block;
  }
  @include modifier(bold) {
    color: $c_nav_dark_gray;
    font-family: $din-bold;
  }
}

/*
Checkbox

markup:
<div class="form-checkbox">
  <input id="ckName" name="ckName" type="checkbox">
  <label for="ckName">Arm Diverter (36)</label>
</div>
<br />
<div class="form-checkbox form-checkbox--blue">
  <input id="ckName2" name="ckName2" type="checkbox">
  <label for="ckName2">Arm Diverter (36)</label>
</div>
<br />
<div class="form-checkbox form-checkbox--moen-blue">
  <input id="ckName3" name="ckName3" type="checkbox">
  <label for="ckName3">Arm Diverter (36)</label>
</div>
<div style="background-color: #dedede;">
  <div class="form-checkbox form-checkbox--white">
    <input id="ckName4" name="ckName4" type="checkbox">
    <label for="ckName4">Arm Diverter (36)</label>
  </div>
</div>
<br>
<div class="form-checkbox form-checkbox--white form-checkbox--image">
  <input id="ckDropEli" name="ckDropEli" type="checkbox">
  <label for="ckDropEli"><img src="./images/styleguide/oil-rubbed-bronze.png" alt="" aria-hidden="true">Oil Rubbed Bronze (13)</label>
</div>

Styleguide 2.5.6
*/

@include block(form-checkbox) {
  label {
    margin-left: .5em;
  }

  @include modifier(blue) {
    font-family: $din-regular;

    label {
      display: inline-block;
      position: relative;
      line-height: 16px;
      margin-left: 0;
      padding-left: 1.5em;
    }
    [type="checkbox"] {
      &:not(:checked),
      &:checked {
        display:none;
      }
      &:not(:checked),
      &:checked {
        & + label:before {
          background-color: $c_moen-bright-blue;
          content: '';
          height: 14px;
          position: absolute;
          top: 2px;
          left: 0;
          width: 14px;
        }
      }
      &:checked {
        & + label:after {
          content: '';
          height: 14px;
          position: absolute;
          top: 2px;
          left: 0;
          width: 14px;
          background: url(./images/icon-checkmark_white.svg) no-repeat;
        }
      }
    }
  }

  @include modifier(white) {
    font-family: $din-regular;

    label {
      display: inline-block;
      position: relative;
      line-height: 16px;
      margin-left: 0;
      padding-left: 1.5em;
    }
    [type="checkbox"] {
      &:not(:checked),
      &:checked {
        display:none;
      }
      &:not(:checked),
      &:checked {
        & + label:before {
          background-color: $c_white;
          border: 1px solid $c_facet_borders;
          content: '';
          height: 14px;
          position: absolute;
          top: 2px;
          left: 0;
          width: 14px;
        }
      }
      &:checked {
        & + label:after {
          content: '';
          height: 14px;
          position: absolute;
          top: 2px;
          left: 0;
          width: 14px;
          background: url(./images/icon-checkmark_white.svg) $c_facet_dark_blue no-repeat;
        }
      }
    }
  }

  @include modifier('moen-blue') {
    font-family: $din-regular;
    margin-bottom: 0.5em;

    label {
      display: inline-block;
      position: relative;
      line-height: 14px;
      margin-left: 0;
      padding-left: 1.5em;
    }

    [type="checkbox"] {
      &:not(:checked),
      &:checked {
        display: none;
      }

      & + label::before {
        border: 1px solid $c_moen-gray;
        content: '';
        height: 14px;
        position: absolute;
        top: 1px;
        left: 0;
        width: 14px;
      }

      &:checked {
        & + label::before {
          background-color: $c_moen-bright-blue;
          border-color: $c_moen-bright-blue;
        }
      }

      &:checked {
        & + label {
          font-weight: bold;

          &::after {
            background: url(./images/icon-checkmark_white.svg) no-repeat;
            content: '';
            height: 14px;
            left: 0;
            position: absolute;
            top: 1px;
            width: 14px;
          }
        }
      }
    }
  }

  @include modifier('image') {
    label {
      padding-left: 3.15em;
    }
    img {
      height: auto;
      width: 1.5em;
      left: 1.5em;
      top: -2px;
      position: absolute;
    }

    &:after,
    &:before {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}




/// Form Checkbox and Radio button base styling
@mixin form-checkbox() {

  @include child('input') {
    opacity: 0;
    position: absolute;
  }


  @include child('label') {
    display: inline-block;
    padding-left: 22px;
    position: relative;


    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
    }


    &::before {
      border: 1px solid $c_moen-gray;
      height: 14px;
      left: 0;
      top: 3px;
      width: 14px;

      .ie11 & {
        top: 0;
      }

    }

  }

}






/// .f-checkbox
@include block('f-checkbox') {

  @include form-checkbox();


  /// .f-checkbox__label
  @include child('label') {


    /// .f-checkbox__label::before
    /// Checkmark
    &::after {
      border-left: 3px solid;
      border-bottom: 3px solid;
      height: 6px;
      left: 1px;
      top: 6px;
      transform: rotate(-45deg);
      width: 12px;


      .ie11 & {
        top: 2px;
      }
    }

  }


  /// Checkbox not checked
  .f-checkbox__input[type="checkbox"] + .f-checkbox__label::after {
    content: none;
  }


  /// Checkbox checked
  .f-checkbox__input[type="checkbox"]:checked + .f-checkbox__label {
    font-weight: bold;


     &::after {
      content: "";
    }

  }

  /// Adding focus styles on the outer-box of the fake checkbox
  .f-checkbox__input[type="checkbox"]:focus + label::before {
      outline: 2px solid rgba($c_moen-bright-blue, 0.5);
  }


  /// .f-checkbox--moen-blue
  @include modifier('moen-blue') {

    /// Checkbox checked
    .f-checkbox__input[type="checkbox"]:checked + .f-checkbox__label::before {
      background-color: $c_moen-bright-blue;
      border-color: $c_moen-bright-blue;
    }


    .f-checkbox__label::after {
      color: $c_white;
    }
  }

}





/// .f-radio
@include block('f-radio') {

  @include form-checkbox();


  /// .f-radio__label
  @include child('label') {

    /// .f-radio__label::before pseudo-element
    /// radio box
    &::before {
      border-radius: 50%;
    }


    /// .f-radio__label::after pseudo-element
    /// radio checked
    &::after {
      background-color: $c_text-base;
      border-radius: 50%;
      height: 6px;
      left: 4px;
      top: 7px;
      width: 6px;

      /// .ie11 .f-radio__label::after pseudo-element
      .ie11 & {
        top: 4px;
      }

    }

  }


  /// Radio not checked
  .f-radio__input[type="radio"] + .f-radio__label::after {
    content: none;
  }


  /// Radio checked
  .f-radio__input[type="radio"]:checked + .f-radio__label {
    font-weight: bold;


     &::after {
      content: "";
    }

  }


  /// Adding focus styles on the outer-box of the fake radio
  .f-radio__input[type="radio"]:focus + label::before {
      outline: 2px solid rgba($c_moen-bright-blue, 0.5);
  }



  /// .f-checkbox--moen-blue
  @include modifier('moen-blue') {

    /// radio checked
    .f-radio__input[type="radio"]:checked + .f-radio__label::before {
      background-color: $c_moen-bright-blue;
      border-color: $c_moen-bright-blue;
    }


    .f-radio__label::after {
      background-color: $c_white;
    }
  }



}




/*
Text Area

markup:
<textarea class="form-textarea" cols="40" rows="10">
  this is a textarea
</textarea>

Styleguide 2.5.7
*/

@include block(form-textarea) {
  border: 1px solid $c_form_border;
  min-height: 300px;
}

/*
Form Label

markup:
<label class="form-label"> This is a Label</label>

Styleguide 2.5.8
*/

@include block(form-label) {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 0.3em;

  @include element(error) {
    color: $c_error;
    font-size: rem(12);
    font-weight: normal;

    .rtf-component {
      color: $c_error;

      a {
        font-weight: bold;
      }

    }

    @include modifier(inline) {
      margin-left: 1em;
    }
  }

  @include state('error') {
    color: $c_error;
  }
}

@include block(form-control) {
  display: block;
  width: 100%;

  @include modifier(is-required) {
    color: $c_error;
  }
}

@include block('form-note') {
  font-size: rem(12);
  line-height: rem(17);
  padding: 3px 0;

  * {
    font-size: inherit;
  }

  @include modifier('error') {
    color: $c_error;
  }
}

/*
Radio

markup:
<div class="form-radio">
  <input id="rdName1" name="rdGroup" type="radio">
  <label for="rdName1">Yes</label>
</div>
<br />
<div class="form-radio">
  <input id="rdName2" name="rdGroup" type="radio">
  <label for="rdName2">No</label>
</div>
<br />
<div class="form-radio form-radio--moen-blue">
  <input id="rdName3" name="rdGroup" type="radio">
  <label for="rdName3">Maybe</label>
</div>

Styleguide 2.5.9
*/
@include block(form-radio) {

  label {
    margin-left: .5em;
  }

  @include modifier('moen-blue') {
    font-family: $din-regular;

    label {
      display: inline-block;
      position: relative;
      line-height: 14px;
      margin-left: 0;
      padding-left: 1.5em;
    }

    [type="radio"] {
      &:not(:checked),
      &:checked {
        display: none;
      }

      & + label::before {
        border: 1px solid $c_moen-gray;
        border-radius: 50%;
        content: '';
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
      }

      &:checked {
        & + label::before {
          background-color: $c_moen-bright-blue;
          border-color: $c_moen-bright-blue;
        }
      }

      &:checked {
        & + label {
          font-weight: bold;

          &::after {
            background-color: $c_white;
            border-radius: 50%;
            content: '';
            height: 6px;
            left: 5px;
            position: absolute;
            top: 5px;
            width: 6px;
          }
        }
      }
    }
  }

}


/*
Drag & Drop Input


Styleguide 2.5.10
*/
@include block('form-input') {

  @include child('drag-and-drop') {
    border-style: dashed;
    border-width: 3px;
    height: 100px;
    text-align: center;

    @include state('dragover') {
      background: $c_concrete;
    }
  }
}


/// .form-error-list
@include block('form-error-list') {
  color: $c_error;
  display: inline-block;
  list-style: none;

  /// .form-error-list__item
  @include child('item') {
    width: auto;
  }

}


::placeholder {
  color: $c_light-gray;
}

// Prevent Zoom on iOS when tapping into field
@media screen and (-webkit-min-device-pixel-ratio:0) {
  select:not([class*="plmr-"]),
  textarea:not([class*="plmr-"]),
  input[type="email"]:not([class*="plmr-"]),
  input[type="number"]:not([class*="plmr-"]),
  input[type="search"]:not([class*="plmr-"]),
  input[type="tel"]:not([class*="plmr-"]),
  input[type="text"]:not([class*="plmr-"]) {
    font-size: 16px !important;
  }

  .form-input.is-disabled {
    color: $c_dark-gray;
  }
}
