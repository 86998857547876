
/**
@doc _docs/banner-hero-split.md
@section Banner Hero Split
@childof Banners
@page Banners Banner Hero Split
@status ready
*/


/**
@section Image Left
@sectionof Banner Hero Split
@page Banners Banner Hero Split
@example
_markup/banner-hero-split--left.hbs
*/
@include plmr-component('banner-hero-split') {
  //background-color: plmr-color('background', 'secondary');

  // .plmr-c-banner-hero-split__inner
  @include element('inner') {
    @extend .plmr-l-container;
    padding: 0;

    @include plmr-mq(md) {
      align-items: center;
      display: flex;
    }
  }

  // .plmr-c-banner-hero-split__content
  @include element('content') {
    padding: 0 25px 40px;
    .plmr-c-banner-hero-split__copy p{
      font-size: 26px;
    }
    @include plmr-mq(md) {
      flex: 1 0 calc(50% - 32px);
      padding: 0 25px 0 0;
    }

    @include plmr-mq(lg) {
      flex: 1 0 calc(46% - 55px);
      padding-right: 7%;
    }
  }

  // .plmr-c-banner-hero-split__title
  @include element('title') {}

  // .plmr-c-banner-hero-split__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
    padding-top: 10px;

    @include plmr-mq(md) {
      padding-top: 15px;
    }
  }

  // .plmr-c-banner-hero-split__image
  @include element('image') {
    height: auto;
    min-height: 100px;
    overflow: hidden;
    padding-top: 26.67%;
    position: relative;
    transition: 0.4s ease;
    width: 100%;

    // .plmr-c-banner-hero-split__image--src
    @include modifier('src') {
      height: auto;
      left: 50%;
      max-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;

      @include plmr-mq(md) {
        max-height: 100%;
        max-width: none;
      }
    }

    @include plmr-mq(md) {
      flex: 1 0 50%;
      height: 250px;
      margin-right: 32px;
      padding: 0;
    }

    @include plmr-mq(lg) {
      flex: 1 0 54%;
      height: 300px;
      margin-right: 55px;
    }
  }
}





/**
@section Image Right
@sectionof Banner Hero Split
@page Banners Banner Hero Split
@example
_markup/banner-hero-split--right.hbs
*/
@include plmr-component('banner-hero-split') {

  // .plmr-c-banner-hero-split--right
  @include modifier('right') {
    .plmr-c-banner-hero-split__inner {
      flex-direction: row-reverse;
    }

    .plmr-c-banner-hero-split__content {
      @include plmr-mq(md) {
        padding: 0 0 0 25px;
      }

      @include plmr-mq(lg) {
        padding: 0;
        padding-left: calc(7% + 32px);
      }
    }

    .plmr-c-banner-hero-split__image {
      @include plmr-mq(md) {
        margin: 0;
        margin-left: 32px;
      }

      @include plmr-mq(lg) {
        margin-left: 55px;
      }
    }
  }
}



/**
@section Wider Image Left
@sectionof Banner Hero Split
@page Banners Banner Hero Split
@example
_markup/banner-hero-split--wide-image-left.hbs
*/
@include plmr-component('banner-hero-split-wider') {
  //.plmr-c-banner-hero-split-wider__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 2rem;
    line-height: 2.571rem;
    padding-top: 10px;

    @include plmr-mq(md) {
      font-size: 2.571rem;
      line-height: 3.428rem;
      padding-top: 15px;
    }

    //.plmr-c-banner-hero-split-wider__title--italic
    @include modifier('italic') {
      @include plmr-font-face($family: 'italic');
      color: plmr-color('brand', 'secondary');
      font-size: 1.143rem;
      line-height: 1.714rem;

      @include plmr-mq(md) {
        font-size: 1.286rem;
      }
    }
  }

  //.plmr-c-banner-hero-split-wider__content
  @include element('content') {
    @include plmr-mq(lg) {
      flex: 1 0 calc(38.46% - 55px);
      padding-right: 7%;
    }
  }

  //.plmr-c-banner-hero-split-wider__image
  @include element('image') {
    @include plmr-mq(md) {
      height: 300px;
    }

    @include plmr-mq(lg) {
      flex: 1 0 61.54%;
      height: 350px;
    }
  }
}


/**
@section Wider Image Right
@sectionof Banner Hero Split
@page Banners Banner Hero Split
@example
_markup/banner-hero-split--wide-image-right.hbs
*/
@include plmr-component('banner-hero-split-wider') {
  //.plmr-c-banner-hero-split-wider--right
  @include modifier('right') {
    .plmr-c-banner-hero-split-wider__content {
      @include plmr-mq(lg) {
        padding-left: 7%;
      }
    }
  }
}
