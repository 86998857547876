/**
@doc _docs/navigation-social-share.md
@section Social Share Navigation
@childof Navigation
@page Navigation Social Share Navigation
@status in progress
@hippo navigation
@example
_markup/navigation-social-share.hbs
*/
@include plmr-component('navigation-social-share') {
  color: plmr-color('text', 'secondary');
  font-size: 0;

  //.plmr-c-navigation-social-share__title
  @include element('title') {
    display: inline-block;
    font-size: 0.857rem;
    line-height: 1rem;
    padding-bottom: 0;
    vertical-align: middle;
  }

  //.plmr-c-navigation-social-share__list
  @include element('list') {
    display: inline-block;
    vertical-align: middle;
  }

  //.plmr-c-navigation-social-share__item
  @include element('item') {
    display: inline-block;
    list-style-type: none;
    padding-left: 15px;
  }

  //.plmr-c-navigation-social-share__link
  @include element('link') {
    @include plmr-link($color: lighten(desaturate(adjust-hue(plmr-color('text', 'secondary'), -285), 1.71), 18.82));
    display: block;
  }

  //.plmr-c-navigation-social-share__link-icon
  @include element('link-icon') {
    height: 17px;
    max-width: 17px;
    width: auto;
  }
}
