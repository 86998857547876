
/**
@doc _docs/carousel-column-gallery.md
@section Carousel Column Gallery
@childof Carousels
@page Carousels Carousel Column Gallery
@status ready
@hippo banner carousel
@example
_markup/carousel-column-gallery.hbs
*/
@include plmr-component('carousel-column-gallery') {
  @extend .plmr-l-container;
  background: plmr-color('base', 'white');
  color: plmr-color('base', 'white');
  padding: 40px 0;

  @include plmr-mq(md) {
    padding: 50px 0;
  }

  // .plmr-c-carousel-column-gallery__row
  @include element('row') {
    @extend .plmr-l-flex-row;
    margin: 0;
  }

  // .plmr-c-carousel-column-gallery__column
  @include element('column') {
    @extend .plmr-l-flex-col;
    flex-basis: 100%;
    height: auto;
    max-width: 100%;
    min-height: 175px;
    overflow: hidden;
    padding-top: 46.667%;
    position: relative;
    transition: 0.4s ease-out;

    // .plmr-c-carousel-column-gallery__column::before
    &::before {
      @include plmr-pseudo;
      background-image: linear-gradient(-180deg, rgba(plmr-color('text', 'primary'), 0) 0%, rgba(plmr-color('text', 'primary'), 0.5) 59%, rgba(plmr-color('text', 'primary'), 0.65) 95%);
      bottom: 0;
      height: 69%;
      left: 0;
      pointer-events: none;
      transition: 0.4s ease-in-out;
      width: 100%;
      z-index: 2;
    }

    // .plmr-c-carousel-column-gallery__column:hover
    &:hover {
      &::before {
        height: 100%;
      }
    }

    @include plmr-mq(md) {
      flex-basis: 0%;
      min-height: 651px;
      padding-top: 0;

      &::before {
        height: 38%;
        min-height: 250px;
      }
    }

    @include plmr-mq(lg) {
      min-height: 751px;
    }

    // .plmr-c-carousel-column-gallery__column._open
    @include plmr-state('open') {
      padding-top: 0;

      @include plmr-mq(md) {
        flex-basis: 100%;
      }
    }

    // .plmr-c-carousel-column-gallery__column._closed
    @include plmr-state('closed') {
      min-height: 0;
      padding-top: 0;

      @include plmr-mq(md) {
        flex-basis: 0%;
        min-height: 651px;
      }

      @include plmr-mq(lg) {
        min-height: 751px;
      }
    }
  }

  // .plmr-c-carousel-column-gallery__column-background
  @include element('column-background') {
    height: auto;
    left: 50%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    z-index: 1;

    @include plmr-mq(md) {
      max-height: 100%;
      max-width: none;
    }

    // .plmr-c-carousel-column-gallery__column-background--large
    @include modifier('large') {
      max-width: none;

      @include plmr-mq(md) {
        max-height: none;
      }
    }
  }

  // .plmr-c-carousel-column-gallery__column-flyout
  @include element('column-flyout') {
    height: auto;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: 0.4s ease-in-out;
    width: auto;
    z-index: 2;

    // .plmr-c-carousel-column-gallery__column-flyout::before
    &::before {
      @include plmr-pseudo;
      background-image: linear-gradient(-180deg, rgba(plmr-color('text', 'primary'), 0) 0%, rgba(plmr-color('text', 'primary'), 0.5) 59%, rgba(plmr-color('text', 'primary'), 0.65) 95%);
      bottom: 0;
      height: 100%;
      left: 0;
      pointer-events: none;
      width: 100%;
      z-index: 2;
    }

    // .plmr-c-carousel-column-gallery__column-flyout._open
    @include plmr-state('open') {
      opacity: 1;
      pointer-events: auto;
    }
  }

  // .plmr-c-carousel-column-gallery__column-content
  @include element('column-content') {
    bottom: 0;
    display: none;
    height: auto;
    left: 0;
    min-width: 100%;
    padding: 15px 15px 80px;
    position: absolute;
    width: auto;
    z-index: 2;

    @include plmr-mq(md) {
      padding: 30px 30px 30px 286px;
    }

    @include plmr-mq(lg) {
      padding: 44px 0 40px;
      padding-left: 33%;
      padding-right: 8%;
    }
  }

  // .plmr-c-carousel-column-gallery__content-inner
  @include element('content-inner') {
    @include plmr-mq(lg) {
      max-width: 600px;
      width: 76%;
    }
  }

  // .plmr-c-carousel-column-gallery__column-title
  @include element('column-title') {
    @include plmr-font-face($family: 'italic');
    color: plmr-color('base', 'white');
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 1.714rem;
      line-height: 2.571rem;
    }
  }

  // .plmr-c-carousel-column-gallery__column-copy
  @include element('column-copy') {
    @extend .plmr-c-banner__copy;
    color: plmr-color('base', 'white');
    padding-top: 15px;

    @include plmr-mq(md) {
      line-height: 2rem;
      padding-top: 10px;
    }
  }

  // .plmr-c-carousel-column-gallery__column-button
  @include element('column-button') {
    padding-top: 25px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }

    @include plmr-mq(lg) {
      position: absolute;
      right: 8%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  // .plmr-c-carousel-column-gallery__toggle
  @include element('toggle') {
    bottom: 21px;
    color: plmr-color('base', 'white');
    height: auto;
    left: 1px;
    padding: 6px 0 6px 65px;
    position: absolute;
    width: auto;
    z-index: 3;

    @include plmr-mq(md) {
      bottom: 31px;
      left: 31px;
      max-width: calc(100% - 60px);
      padding: 0 0 70px 0;
      //padding: 20px 30px 100px;
    }

    @include plmr-mq(lg) {
      bottom: 41px;
      left: 41px;
      padding: 0 0 60px 0;
      //padding: 20px 40px 110px;
    }
  }

  // .plmr-c-carousel-column-gallery__toggle-label
  @include element('toggle-label') {
    @include plmr-font-face($family: 'bold');
    font-size: 1.286rem;
    line-height: 1;
    margin: 0;
    text-align: left;

    @include plmr-mq(md) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    // .plmr-c-carousel-column-gallery__toggle-label:hover
    &:hover {
      cursor: pointer;
    }
  }

  // .plmr-c-carousel-column-gallery__toggle-icon
  @include element('toggle-icon') {
    background: rgba(plmr-color('base', 'white'), 0);
    border: 2px solid currentColor;
    border-radius: 50%;
    color: inherit;
    height: 30px;
    left: 15px;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    transition: 0.25s ease-in-out;
    width: 30px;

    // .plmr-c-carousel-column-gallery__toggle-icon::before,
    // .plmr-c-carousel-column-gallery__toggle-icon::after
    &::before,
    &::after {
      background: currentColor;
      content: '';
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.25s ease-in-out;
      width: 14px;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    // .plmr-c-carousel-column-gallery__toggle-icon:hover
    &:hover {
      background: rgba(plmr-color('base', 'white'), 0.35);
      cursor: pointer;
    }

    @include plmr-mq(md) {
      //bottom: 30px;
      bottom: 0;
      height: 40px;
      //left: 30px;
      left: 0;
      top: auto;
      transform: none;
      width: 40px;

      &::before,
      &::after {
        width: 18px;
      }
    }

    // .plmr-c-carousel-column-gallery__toggle-icon._toggled-open
    @include plmr-state('toggled-open') {
      &::before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }
}
