/**
@doc _docs/navigation-breadcrumbs.md
@section Breadcrumbs Navigation
@childof Navigation
@page Navigation Breadcrumbs Navigation
@status in progress
@hippo navigation
@example
_markup/navigation-breadcrumbs.hbs
*/
@include plmr-component('navigation-breadcrumbs') {
  border-bottom: 1px solid plmr-color('accent', 'light');

  //.plmr-c-navigation-breadcrumbs__crust
  @include element('crust') {
    font-size: 0;
    overflow: hidden;
    padding: 9px 15px 9px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include plmr-mq(md) {
      padding: 17px 25px 20px;
    }

    @include plmr-mq(lg) {
      padding: 17px 40px 20px;
    }
  }

  //.plmr-c-navigation-breadcrumbs__crumb
  @include element('crumb') {
    display: inline-block;
    font-size: 0.8571rem;
    line-height: 1.714rem;

    @include plmr-mq(md) {
      font-size: 1rem;
    }

    // .plmr-c-navigation-breadcrumbs__crumb::after
    &::after {
      color: plmr-color('text', 'secondary');
      content: '/';
      padding: 0 6px;
    }

    &:last-child {
      &::after {
        content: '';
        padding: 0;
      }
    }

    // .plmr-c-navigation-breadcrumbs__crumb--special
    @include modifier('special') {
      @include plmr-font-face($family: 'bold');
      display: none;
      line-height: 0;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);

      @include plmr-mq(md) {
        display: block;
      }

      @include plmr-mq(lg) {
        right: 40px;
      }

      // .plmr-c-navigation-breadcrumbs__crumb--special::after
      &::after {
        display: none;
      }

      .plmr-c-navigation-breadcrumbs__crumb-link {
        @include plmr-link($underline: true);
      }
    }
  }

  //.plmr-c-navigation-breadcrumbs__crumb-link
  @include element('crumb-link') {
    @include plmr-link($color: plmr-color('text', 'secondary'), $underline: true);

    // .plmr-c-navigation-breadcrumbs__crumb-link._current
    @include plmr-state('current') {
      @include plmr-link($color: plmr-color('text', 'secondary'), $underline: false);
    }
  }

  //.plmr-c-navigation-breadcrumbs__button
  @include element('button') {

  }
}


/**
@doc _docs/navigation-breadcrumbs--with-cta.md
@section With Call To Action
@sectionof Breadcrumbs Navigation
@page Navigation Breadcrumbs Navigation
@example
_markup/navigation-breadcrumbs--with-cta.hbs
*/
@include plmr-component('navigation-breadcrumbs') {
  //.plmr-c-navigation-breadcrumbs--with-cta
  @include modifier('with-cta') {
    .plmr-c-navigation-breadcrumbs__crust {
      @include plmr-mq(md) {
        padding-right: 235px;
      }

      @include plmr-mq(lg) {
        padding-right: 345px;
      }
    }
  }
}


/**
@section With Primary Call To Action
@sectionof Breadcrumbs Navigation
@example
_markup/navigation-breadcrumbs--with-button.hbs
*/
