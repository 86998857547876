// .innovations
@include block(innovations) {
	
	
	// Innovations Campaign Graphic container 
	// .innovations__campaign
	@include element(campaign) {
		display: block;
		width: calc(100% - 40px);
		margin: 40px auto;
		max-width: 1600px;
		a {
			display: block;
			outline: none;
			img {
				width: 100%;
				height: auto;
			}
		}
		
		@include media($bp-md) {
			width: calc(100% - 80px);
			margin: 80px auto;
		}
		
		@include media($bp-lg) {
			width: calc(100% - 120px);
			margin: 120px auto;
		}
		
	}
}