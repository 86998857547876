/*
Helpful Tools & Contact Us

Styleguide 4.18
*/

/*
Helpful Tools, No Menu

Variant 1, no menu

markup:
<div class="container">
  <div class="helpful-tools helpful-tools--no-menu">
    <div class="helpful-tools__intro">
      <h2 class="helpful-tools__intro--heading">Helpful Tools</h2>
      <div class="helpful-tools__intro--content">
        <p>Don‘t know where to start? Use these simple tools to help you find the perfect products for your space.</p>
      </div>
    </div>
    <div class="helpful-tools__tools-group"
    <!-- First Tool Link -->
      <div class="helpful-tools__tool">
        <a href="#" data-tool-link="faucet selector" data-tool-cat="" class="gtm_tool_link helpful-tools__tool--link">
          <img class="helpful-tools__tool--image" src=" /images/icon-faucet_selector.svg" alt="">
          <span class="helpful-tools__tool--label">Faucet Selector</span>
        </a>
      </div>
      <!-- Second Tool Link -->
      <div class="helpful-tools__tool">
        <a href="#" data-tool-link="faucet selector" data-tool-cat="" class="fgtm_tool_link helpful-tools__tool--link">
          <img class="helpful-tools__tool--image" src="./images/icon-dyo_bath.svg" alt="">
          <span class="helpful-tools__tool--label">Design Your Own Bathroom</span>
        </a>
      </div>
      <!-- Third Tool Link -->
      <div class="helpful-tools__tool">
        <a href="#" data-tool-link="faucet selector" data-tool-cat="" class="gtm_tool_link helpful-tools__tool--link">
          <img class="helpful-tools__tool--image" src="./images/icon-dyo_kitchen.svg" alt="">
          <span class="helpful-tools__tool--label">Design Your Own Kitchen</span>
        </a>
      </div>
    </div>
  </div>
</div>

Styleguide 4.18.1
*/

@include block(helpful-tools) {
  font-size: 1rem;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 1em .5em;
  color: $c_mid-gray;
  background-color: $c_white;
  @include modifier(no-menu) {
    flex-direction: column;
    @include media($bp-md) {
      flex-direction: row;
    }
  }

  @include element(intro) {
    color: inherit;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: flex-start;
    max-width: 100%; // ie11, ie10 fix
    @include media($bp-md) {
      width: 30%;
    }
    @include modifier(heading) {
      letter-spacing: -.05em;
      line-height: 1;
      font-family: $din-light;
      font-weight: inherit;
      font-size: em(40);
      padding: 0 0 .5em 0;
      margin: 0;
    }
  }

  @include element(tools-group) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    @include media($bp-xs) {
      flex-direction: row;
    }
  }

  @include element(tool) {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    text-align: center;
    width: 100%; // full-width in column layout
    @include modifier(link) {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: em(20) 1em;
      margin: 0 auto;
      height: 130px;

      &:hover,
      &:focus {
        box-shadow: inset 0 0 6px 1px $c_dove-gray;
        & > img {
          transform: scale(1.1, 1.1);
        }
      }
      @include media($bp-sm) {
        border-left: 1px solid $c_light-gray;
        width: auto;
      }

    }

    @include modifier(image) {
      width: 60px;
      height: 60px;
      @include transition(transform .5s);
    }

    @include modifier(label) {
      font-size: em(14);
      text-transform: uppercase;
      color: $c_moen-bright-blue;
      margin-top: 1em;
      line-height: 1;
      max-width: 100%; // ie11, ie10 fix
      display: block; // ie10 fix
      flex-shrink: 1; // ie10 fix
    }
    &:first-child {
      a {
        border-left: none;
      }
    }
    @include media($bp-md) {
      width: 32%;
    }
  }
}

/*
Helpful Tools Menu

Variant 1, with menu

markup:
<div class="container">
  <div class="helpful-tools helpful-tools--menu">
    <div class="helpful-tools__intro">
      <h2 class="helpful-tools__intro--heading">Helpful Tools</h2>
    </div>
    <div class="helpful-tools__tools-group">
      <!-- First Tool Link -->
      <div class="helpful-tools__tool">
        <a href="#" data-tool-link="faucet selector" data-tool-cat="" class="gtm_tool_link helpful-tools__tool--link">
          <img class="helpful-tools__tool--image" src=" /images/icon-faucet_selector.svg" alt="">
          <label class="helpful-tools__tool--label">Faucet Selector</label>
        </a>
      </div>
      <!-- Second Tool Link -->
      <div class="helpful-tools__tool">
        <a href="#" data-tool-link="faucet selector" data-tool-cat="" class="fgtm_tool_link helpful-tools__tool--link">
          <img class="helpful-tools__tool--image" src="./images/icon-dyo_bath.svg" alt="">
          <label class="helpful-tools__tool--label">Design Your Own Bathroom</label>
        </a>
      </div>
      <!-- Third Tool Link -->
      <div class="helpful-tools__tool">
        <a href="#" data-tool-link="faucet selector" data-tool-cat="" class="gtm_tool_link helpful-tools__tool--link">
          <img class="helpful-tools__tool--image" src="./images/icon-dyo_kitchen.svg" alt="">
          <label class="helpful-tools__tool--label">Design Your Own Kitchen</label>
        </a>
      </div>
    </div>
    <!-- menu -->
    <div class="helpful-tools__menu">
      <h3 class="helpful-tools__menu-title">Design &amp; Planning</h3>
      <ul class="helpful-tools__menu-list">
        <li><a class="link" href="#">Kitchen Design</a></li>
        <li><a class="link" href="#">Design Videos</a></li>
        <li><a class="link" href="#">Inspiration Gallery</a></li>
        <li><a class="link" href="#">Featured Articles</a></li>
      </ul>
    </div>
  </div>
</div>

Styleguide 4.18.2
*/

@include block(helpful-tools) {
  @include element(tools-group) {
    align-items: center;
  }
  @include modifier(menu) {
    @include media($bp-md) {
      flex-direction: row;
    }
    flex-direction: column;
    .helpful-tools__tools-group {
      @include media($bp-md) {
        width: 50%;
      }
    }
    .helpful-tools__tool {
      & > a {
        border-left-color: transparent;
      }
    }
    .helpful-tools__intro {
      width: auto;
      @include media($bp-md) {
        width: 20%;
      }
    }
    .helpful-tools__intro--heading {
      @include media($bp-md) {
        font-size: rem(34);
      }
    }
  }
  @include element(menu) {
    flex-grow: 1;
    flex-shrink: 10;
    //flex-basis: 30%;
    width: auto;
    border-left: 1px solid transparent;
    margin-top: 1em;
    margin-right: auto;
    @include media($bp-md) {
      border-left-color: $c_light-gray;
      padding-left: 2%;
      width: 30%;
    }
  }
  @include element(menu-title) {
    font-family: $din-light;
    line-height: 1;
    font-size: em(18);
  }
  @include element(menu-list) {
    @include list-base();
    & > li {
      margin-bottom: 1em;
      line-height: 1;
      @include media($bp-md) {
        margin-bottom: .3em;
      }
    }
    @include media($bp-md) {
      flex-direction: row;
    }
  }
}

/*
Helpful Tools, centered

Variant 2, centered, no menu

markup:
<div class="container">
  <div class="helpful-tools helpful-tools--centered">
    <div class="helpful-tools__intro">
      <h2 class="helpful-tools__intro--heading">Helpful Tools</h2>
      <div class="helpful-tools__intro--content">
        <p>Don‘t know where to start? Use these simple tools to help you find the perfect products for your space.</p>
      </div>
    </div>
    <div class="helpful-tools__tools-group">
      <!-- First Tool Link -->
      <div class="helpful-tools__tool">
        <a href="#" data-tool-link="faucet selector" data-tool-cat="" class="gtm_tool_link helpful-tools__tool--link">
          <img class="helpful-tools__tool--image" src=" /images/icon-faucet_selector.svg" alt="">
          <label class="helpful-tools__tool--label">Faucet Selector</label>
        </a>
      </div>
      <!-- Second Tool Link -->
      <div class="helpful-tools__tool">
        <a href="#" data-tool-link="faucet selector" data-tool-cat="" class="fgtm_tool_link helpful-tools__tool--link">
          <img class="helpful-tools__tool--image" src="./images/icon-dyo_bath.svg" alt="">
          <label class="helpful-tools__tool--label">Design Your Own Bathroom</label>
        </a>
      </div>
      <!-- Third Tool Link -->
      <div class="helpful-tools__tool">
        <a href="#" data-tool-link="faucet selector" data-tool-cat="" class="gtm_tool_link helpful-tools__tool--link">
          <img class="helpful-tools__tool--image" src="./images/icon-dyo_kitchen.svg" alt="">
          <label class="helpful-tools__tool--label">Design Your Own Kitchen</label>
        </a>
      </div>
    </div>
  </div>
</div>

Styleguide 4.18.3
*/

@include block(helpful-tools) {
  @include modifier(centered) {
    flex-direction: column;
    text-align: center;
    .helpful-tools__intro,
    .helpful-tools__tools-group {
      align-self: center;
      width: auto;
    }
  }
}

/*
Helpful Tools, var 3

Variant 3, contact us

markup:
markup:
<div class="container">
  <div class="helpful-tools helpful-tools--contact">
    <div class="helpful-tools__intro">
      <h2 class="helpful-tools__intro--heading">Still Not Finding What You’re Looking For?</h2>
      <div class="helpful-tools__intro--content">
        <p><a class="link--tel" href="tel:1-800-289-6636">Call 1-800-BUY-MOEN</a></p>
        <p>Call us at: <a class="link--tel" href="tel:1-800-289-6636">1-800-289-6636</a></p>
        <p><a class="link">CONTACT US</a></p>
      </div>
    </div>
    <div class="helpful-tools__hours-of-operation-group">
        <p>Customer Services</p>
        <p>M-F 8am to 7pm EST  |  Sat 9am to 3pm EST</p>
        <p>Closed on All Nationally Observed Holidays</p>
    </div>
  </div>
</div>

Styleguide 4.18.4
*/

@include block(helpful-tools) {
  font-size: 1rem;
  @include modifier(contact) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: $c_text-base;
    p {
      margin: 0;
      padding: 0;
    }
    @include media($bp-md) {
      flex-direction: row;
    }
    .helpful-tools__intro {
      border-right: none;
      @include media($bp-md) {
        flex-basis: 60%;
        border-right: 1px solid $c_light-gray;
      }
    }

    .helpful-tools__intro--heading {
      font-size: em(24);
      font-family: $din-light;
      line-height: 1;
      font-weight: normal;
      margin-bottom: .5em;
    }
    .helpful-tools__intro--content {
      p:first-child {
        font-size: em(28);
        font-family: $din-medium;
        line-height: .9;
        margin-bottom: .3em;
        @include media($bp-md) {
          font-size: em(47);
        }
      }
      p:nth-child(2) {
        font-size: em(34);
        font-family: $din-light;
        line-height: .9;
        display: none;
        @include media($bp-md) {
          display: block;
        }
      }
      p:last-child {
        font-size: 1em;
        font-family: $din-regular;
        margin: 1em 0;
      }
    }
  }
  @include element(hours-of-operation-group) {
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 0;
    @include media($bp-md) {
      flex-basis: 40%;
      padding-left: em(20);
    }
    p:first-child {
      font-size: em(16);
      font-family: $helvetica;
      font-weight: bold;
      line-height: .9;
      margin-bottom: .5em;
    }
    p:nth-child(2) {
      font-size: em(14);
      font-family: $din-medium;
      line-height: .9;
      margin-bottom: .5em;
    }
    p:last-child {
      font-size: 1em;
      font-family: $din-medium;
      margin: 0;
    }
  }
}
