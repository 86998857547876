/**
@doc _docs/cardlist-help.md
@section Card List Help
@childof Card Lists
@page Cardlists Cardlist Help
@status in progress
@hippo card-list
@example
_markup/cardlist-help.hbs
*/
@include plmr-component('cardlist-help') {
  //.plmr-c-cardlist-help__topics
  @include element('topics') {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: -7.5px 0;
    padding: 30px 35px 40px;

    @include plmr-mq(md) {
      margin: 0;
      padding: 35px 40px 45px;
    }

    @include plmr-mq(lg) {
      padding-bottom: 50px;
      padding-left: calc(71 / 1300 * 100%);
      padding-right: calc(71 / 1300 * 100%);
      padding-top: 40px;
    }
  }

  //.plmr-c-cardlist-help__topic
  @include element('topic') {
    @include plmr-font-face($family: 'bold');
    @include plmr-link($background: plmr-color('brand', 'primary'), $color: plmr-color('base', 'white'), $is-color-hover: false);
    border-radius: 2px;
    display: block;
    flex: 0 0 auto;
    font-size: 18px;
    line-height: 32px;
    margin: 7.5px 0;
    padding: 20px 20px 23px;
    text-align: center;
    transition: background 0.5s ease;
    width: 100%;

    &:hover {
      background: darken(plmr-color('brand', 'primary'), 6%);
    }

    @include plmr-mq(md) {
      align-items: center;
      display: flex;
      flex-flow: column nowrap;
      font-size: 21px;
      justify-content: center;
      line-height: 38px;
      margin: 15px;
      padding: 25px 30px 24px;
      width: calc(50% - 30px);
    }

    @include plmr-mq(lg) {
      margin: 20px;
      padding: 37px 30px;
      width: calc(33.33% - 40px);
    }
  }

  //.plmr-c-cardlist-help__topic-count
  @include element('topic-count') {
    @include plmr-font-face($family: 'regular');

    @include plmr-mq(md) {
      display: block;
      flex: 0 0 auto;
      width: 100%;
    }
  }
}


/**
@doc _docs/cardlist-help--with-count.md
@section With Count
@sectionof Card List Help
@page Cardlists Cardlist Help
@example
_markup/cardlist-help--with-count.hbs
*/
