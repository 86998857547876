
/**
@doc _docs/banner-content-split.md
@section Banner Content Split
@childof Banners
@page Banners Banner Content Split
@status ready
@hippo banner
*/

/**
@section Image Left
@sectionof Banner Content Split
@page Banners Banner Content Split
@example
_markup/banner-content-split--left.hbs
*/
@include plmr-component('banner-content-split') {
  //.plmr-c-banner-content-split__inner
  @include element('inner') {
    @extend .plmr-l-container;
    font-size: 0;
    padding: 25px 0;

    @include plmr-mq(md) {
      padding: 40px 0;
    }

    @include plmr-mq(lg) {
      padding-left: (91 / 1300 * 100%);
      padding-right: (91 / 1300 * 100%);
    }

    @include plmr-mq(xl) {
      padding-left: 91px;
      padding-right: 91px;
    }
  }

  //.plmr-c-banner-content-split__inner-section
  @include element('inner-section') {
    display: inline-block;
    padding: 15px 25px;
    vertical-align: middle;
    width: 100%;

    @include plmr-mq(md) {
      padding: 20px 55px;
    }

    @include plmr-mq(lg) {
      padding: 20px 0;
      width: 50%;
    }

    //.plmr-c-banner-content-split__inner-section._has-text
    @include plmr-state('has-text') {
      @include plmr-mq(lg) {
        padding-right: (55 / 1300 * 100%);
      }

      @include plmr-mq(xl) {
        padding-right: 55px;
      }
    }
  }

  //.plmr-c-banner-content-split__image
  @include element('image') {
    @extend .plmr-u-img--responsive;
  }

  //.plmr-c-banner-content-split__image-wrapper
  @include element('image-wrapper') {
    text-align: center;
  }

  //.plmr-c-banner-content-split__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.286rem;
    margin: 0;

    @include plmr-mq(md) {
      font-size: 2.286rem;
      line-height: 2.571rem;
    }
  }

  //.plmr-c-banner-content-split__copy
  @include element('copy') {
    @extend .plmr-c-banner__copy;
    padding-top: 15px;

    @include plmr-mq(md) {
      padding-top: 20px;
    }

    //.plmr-c-banner-content-split__copy--stat
    @include modifier('stat') {
      @extend .plmr-c-banner__copy--stat;
      padding-top: 10px;

      @include plmr-mq(md) {
        padding-top: 15px;
      }
    }
  }
}


/**
@section Image Right
@sectionof Banner Content Split
@page Banners Banner Content Split
@example
_markup/banner-content-split--right.hbs
*/
@include plmr-component('banner-content-split') {
  // .plmr-c-banner-content-split--right
  @include modifier('right') {
    .plmr-c-banner-content-split__inner {
      @include plmr-mq(lg) {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
      }
    }

    .plmr-c-banner-content-split__inner-section {
      &._has-text {
        @include plmr-mq(lg) {
          padding-left: (55 / 1300 * 100%);
          padding-right: 0;
        }

        @include plmr-mq(xl) {
          padding-left: 55px;
          padding-right: 0;
        }
      }
    }
  }
}

/**
@section No Stat Image Left
@sectionof Banner Content Split
@page Banners Banner Content Split
@example
_markup/banner-content-split--no-stat-left.hbs
*/

/**
@section No Stat Image Right
@sectionof Banner Content Split
@page Banners Banner Content Split
@example
_markup/banner-content-split--no-stat-right.hbs
*/
