/**
@doc _docs/margin.md
@section Margin
@childof Spacing
@page Spacing Margin
@status ready
@nobrandtoggle
@example
_markup/margin.hbs
*/


@include plmr-spacing-margin();


@include plmr-utility('margin-horizontal') {

  // .plmr-u-margin-horizontal--auto
  @include modifier('auto') {
    margin-left: auto;
    margin-right: auto;
  }

}
