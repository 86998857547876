
/**
Secondary button styling
@section Secondary Button
@childof Buttons
@page Buttons Secondary Button
@status ready
@example
_markup/button-secondary.hbs
*/
@include plmr-component('button') {


  // .plmr-c-button--secondary
  @include modifier('secondary') {
    background: plmr-color('brand', 'secondary');

    @include plmr-button-hover(plmr-color('brand', 'secondary'));
  }

}
