
/**
@section Search Input
@childof Forms
@page Forms Search Input
@status in progress
@example
_markup/form-search.hbs
*/
@include plmr-component('form-search') {

  //.plmr-c-form-search__form
  @include element('form') {
    position: relative;
    width: 100%;
  }

  //.plmr-c-form-search__input
  @include element('input') {
    @include plmr-font-face($family: 'regular');
    @include plmr-form-input-base();
    border: 1px solid plmr-color('accent', 'light');
    border-radius: 0;
    color: plmr-color('text', 'secondary');
    font-size: 16px;
    line-height: 28px;
    padding: 16px 50px 16px 57px;
    text-overflow: ellipsis;
    width: 100%;

    @include plmr-mq('md') {
      padding-right: 147px;
    }

    //.plmr-c-form-search__input:focus
    &:focus {
      border-color: plmr-color('accent', 'dark');
    }
  }

  //.plmr-c-form-search__button
  @include element('button') {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include plmr-mq('md') {
      display: block;
      right: 10px;
    }

    @include modifier('icon') {
      color: plmr-color('brand', 'secondary');
      display: block;
      left: 18px;
    }
  }
}
