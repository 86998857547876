
/**
@doc _docs/navigation-interior-page.md
@section Interior Page Navigation
@childof Navigation
@page Navigation Interior Page Navigation
@status in progress
@example
_markup/navigation-interior-page.hbs
*/
@include plmr-component('navigation-interior-page') {
  border-bottom: 1px solid plmr-color('accent', 'light');
  border-top: 1px solid plmr-color('accent', 'light');

  //.plmr-c-navigation-interior-page._fixed
  @include plmr-state('fixed') {
    padding-top: 122px;

    @include plmr-mq(md) {
      border: 0;
      padding-top: 93px;
    }
  }

  //.plmr-c-navigation-interior-page__inner
  @include element('inner') {
    @extend .plmr-l-container;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    position: relative;

    //.plmr-c-navigation-interior-page._fixed
    @include plmr-state('fixed') {
      background-color: plmr-color('base', 'white');
      border-bottom: 1px solid plmr-color('accent', 'light');
      border-top: 1px solid plmr-color('accent', 'light');
      left: 0;
      min-width: 100vw;
      position: fixed;
      top: 0;
      z-index: plmr-z('modal');
    }
  }

  //.plmr-c-navigation-interior-page__content-section
  @include element('content-section') {
    align-items: center;
    border-bottom: 1px solid plmr-color('accent', 'light');
    color: plmr-color('brand', 'primary');
    display: flex;
    flex: 1 0 auto;
    font-size: 0.9285rem;
    min-height: 77px;
    padding: 0 15px;
    width: 50%;

    @include plmr-mq(md) {
      font-size: 1.143rem;
      height: 91px;
      left: 0;
      padding: 0 25px;
      position: absolute;
      top: 0;
      width: 33.33%;
      z-index: 2;
    }

    @include plmr-mq(lg) {
      border: 0;
      width: (317 / 1300 * 100%);
    }

    @include plmr-mq(xl) {
      padding-left: calc(40 / 1300 * 100%);
      padding-right: calc(40 / 1300 * 100%);
    }

    //.plmr-c-navigation-interior-page__content-section--right
    @include modifier('right') {
      text-align: right;

      @include plmr-mq(md) {
        left: auto;
        right: 0;
      }

      @include plmr-mq(xl) {
        padding-left: 0;
      }
    }

    //.plmr-c-navigation-interior-page__content-section--left
    @include modifier('left') {
      @include plmr-mq(xl) {
        padding-right: 0;
      }
    }
  }

  //.plmr-c-navigation-interior-page__content-section-inner
  @include element('content-section-inner') {
    max-height: 100%;
    width: 100%;
  }

  //.plmr-c-navigation-interior-page__content-cta
  @include element('content-cta') {
    display: inline-block;

    @include plmr-mq(lg) {
      padding-left: calc(25 / 317 * 100%);
    }
  }

  //.plmr-c-navigation-interior-page__menu
  @include element('menu') {
    flex: 1 0 auto;
    order: 3;
    text-align: center;
    width: 100%;
  }

  //.plmr-c-navigation-interior-page__menu-toggle
  @include element('menu-toggle') {
    border-bottom: 1px solid plmr-color('accent', 'light');
    display: block;
    margin-bottom: -1px;
    min-height: 1px;
    outline-offset: -1px;
    padding-top: 12px;
    width: 100%;

    @include plmr-mq(md) {
      padding-top: 38px;
    }

    @include plmr-mq(lg) {
      display: none;
    }
  }

  //.plmr-c-navigation-interior-page__menu-toggle-label
  @include element('menu-toggle-label') {
    @include plmr-font-face($family: 'bold');
    border-bottom: 3px solid currentColor;
    color: plmr-color('brand', 'secondary');
    display: inline-block;
    font-size:  0.8571rem;
    letter-spacing: 0.92px;
    line-height: 1rem;
    padding-bottom: 11px;
    padding-right: 1rem;
    position: relative;
    text-transform: uppercase;

    @include plmr-mq(md) {
      font-size: 1rem;
      letter-spacing: 1.08px;
      line-height: 1.214rem;
      padding-bottom: 33px;
    }

    //.plmr-c-navigation-interior-page__menu-toggle-label::after
    &::after {
      @include plmr-pseudo;
      border-style: solid;
      border-width: 1.25px 1.25px 0 0;
      display: inline-block;
      height: 6.85px;
      left: 1rem;
      position: relative;
      top: 0;
      transform: rotate(135deg);
      vertical-align: top;
      width: 6.85px;
    }

    //.plmr-c-navigation-interior-page__menu-toggle-label._active
    @include plmr-state('active') {
      &::after {
        transform: rotate(135deg) scale(-1, -1);
      }
    }
  }

  //.plmr-c-navigation-interior-page__menu-list
  @include element('menu-list') {
    list-style: none;
    max-height: 0;
    overflow: hidden;
    padding: 0 1px;
    transition: 0.5s ease;

    @include plmr-mq(lg) {
      align-items: center;
      display: flex;
      justify-content: center;
      max-height: none;
      padding: 1px 0 0;
    }

    //.plmr-c-navigation-interior-page__menu-list._open
    @include plmr-state('open') {
      max-height: 500px;
    }
  }

  //.plmr-c-navigation-interior-page__menu-item
  @include element('menu-item') {
    line-height: 47px;

    @include plmr-mq(md) {
      line-height: 54px;
    }

    @include plmr-mq(lg) {
      line-height: 87px;
      padding: 0 25px;
    }

    &:first-child {
      padding-top: 18.5px;

      @include plmr-mq(md) {
        padding-top: 21.5px;
      }

      @include plmr-mq(lg) {
        padding: 0 25px;
      }
    }

    &:last-child {
      padding-bottom: 23.5px;

      @include plmr-mq(lg) {
        display: none;
      }
    }
  }

  //.plmr-c-navigation-interior-page__menu-anchor
  @include element('menu-anchor') {
    @include plmr-font-face($family: 'bold');
    @include plmr-link($color: plmr-color('text', 'secondary'), $underline: false);
    cursor: pointer;
    display: block;
    font-size:  0.8571rem;
    letter-spacing: 0.92px;
    text-transform: uppercase;
    transition: color 0.5s ease, border-color 0.25s ease;

    @include plmr-mq(md) {
      font-size: 1rem;
      letter-spacing: 1.08px;
    }

    @include plmr-mq(lg) {
      border-bottom: 3px solid transparent;
      font-size: 0.9286rem;
    }

    //.plmr-c-navigation-interior-page__menu-anchor:hover
    &:hover {
      color: plmr-color('brand', 'secondary');
    }

    //.plmr-c-navigation-interior-page__menu-anchor._active
    @include plmr-state('active') {
      color: plmr-color('brand', 'secondary');

      @include plmr-mq(lg) {
        border-color: plmr-color('brand', 'secondary');
      }
    }

    //.plmr-c-navigation-interior-page__menu-anchor--link
    @include modifier('link') {
      @extend .plmr-c-button-link;
      @include plmr-link($color: plmr-color('brand', 'tertiary'), $underline: false);
      display: inline-block;
    }
  }

  /// Elements specific to PDP example

  //.plmr-c-navigation-interior-page__product-title
  @include element('product-title') {
    margin: 0;
    text-transform: capitalize;

    //.plmr-c-navigation-interior-page__product-title--collection
    @include modifier('collection') {
      @include plmr-font-face($family: 'bold');
    }
  }

  //.plmr-c-navigation-interior-page__product-review
  @include element('product-review') {
    font-size: 0.8571rem;
    padding-top: 13px;

    @include plmr-mq(md) {
      font-size: 1rem;
    }
  }

  //.plmr-c-navigation-interior-page__product-price
  @include element('product-price') {
    color: plmr-color('text', 'primary');
    display: none;
    font-size: 1rem;
    padding: 0 0 9px;

    @include plmr-mq(md) {
      display: block;
    }

    @include plmr-mq(xl) {
      display: inline-block;
    }
  }
}


/**
This example has content on either side of the menu links
@section With Content
@sectionof Interior Page Navigation
@page Navigation Interior Page Navigation
@example
_markup/navigation-interior-page--with-content.hbs
*/
@include plmr-component('navigation-interior-page') {
  //.plmr-c-navigation-interior-page--with-content
  @include modifier('with-content') {
    .plmr-c-navigation-interior-page__menu-toggle {
      @include plmr-mq(md) {
        margin-left: auto;
        margin-right: auto;
        width: 33.33%;
      }
    }

    .plmr-c-navigation-interior-page__menu-list {
      @include plmr-mq(lg) {
        justify-content: space-around;
        padding-left: (317 / 1300 * 100%);
        padding-right: (317 / 1300 * 100%);
      }

      @include plmr-mq(xl) {
        justify-content: center;
      }
    }

    .plmr-c-navigation-interior-page__menu-item {
      @include plmr-mq(lg) {
        flex: 0 0 auto;
        padding: 0;
      }

      @include plmr-mq(xl) {
        padding: 0 25px;
      }
    }
  }
}


/**
This is an example of the nav with content that is used specifically on the product detail pages
@section PDP
@sectionof Interior Page Navigation
@page Navigation Interior Page Navigation
@example
_markup/navigation-interior-page--pdp.hbs
*/
@include plmr-component('navigation-interior-page') {
  //.plmr-c-navigation-interior-page--pdp
  @include modifier('pdp') {
    .plmr-c-navigation-interior-page__content-section {
      // .plmr-c-navigation-interior-page__content-section--left
      @include modifier('left') {
        font-size: 1rem;
        line-height: 1.285rem;
        width: 55%;

        @include plmr-mq(md) {
          font-size: 1.143rem;
          line-height: 1.5rem;
          width: 40%;
        }

        @include plmr-mq(md) {
          width: 33.33%;
        }

        @include plmr-mq(lg) {
          width: (317 / 1300 * 100%);
        }
      }

      // .plmr-c-navigation-interior-page__content-section--right
      @include modifier('right') {
        width: 40%;

        @include plmr-mq(md) {
          width: 33.33%;
        }

        @include plmr-mq(lg) {
          width: (317 / 1300 * 100%);
        }
      }
    }

    .plmr-c-navigation-interior-page__content-cta {
      margin-left: -15px;
      white-space: nowrap;

      @include plmr-mq(md) {
        margin-left: 0;
        white-space: normal;
      }
    }
  }
}
