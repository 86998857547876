// .innovations
@include block(innovations) {
	// .innovations__type-select
	@include element(type-select) {
		
		max-height: 35px;
		padding-top: 35px;
		overflow: hidden;
		position: relative;
		@include media($bp-md) {
			max-height: none;
			padding-top: 0px;
			text-align: center;
		}
		// .innovations__type-select a
		a {
			display: block;
			height: 35px;
			padding: 10px 28px 11px 0;
			font: normal 14px/1em $proxima-regular;
			color: $c_text-base;
			text-decoration: none !important;
			@include media($bp-md) {
				display: inline-block;
				height: auto;
				text-align: center;
				padding: 0 20px;
			}
			img {
				display: none;
				opacity: 0.75;
				transition: 0.5s ease;
				@include media($bp-md) {
					display: inline-block;
					&.current {
						display: none;
					}
				}
			}
			span {
				@include media($bp-md) {
					margin-top: 5px;
					display: block;
					font: normal 11px/1em $proxima-regular;
				}
			}
			
			// .innovations__type-select a:hover
			&:hover {
				color: $c_mid-gray;
				img {
					opacity: 1;
				}
			}
			
			// .innovations__type-select a.current
			&.current {
				color: $c_moen-bright-blue !important;
				position: absolute;
				top:0;
				left:0;
				@include media($bp-md) {
					position: static;
					top:auto;
					left: auto;
					img.current {
						display: inline-block;
					}
					img:not(.current) {
						display: none;
					}
				}
				&:after {
					content: ' ';
					display: block;
					position: absolute;
					top:0;
					bottom:0;
					right:0;
					left:auto;
					background-image: url('images/icon-angle_right_blue.svg');
					background-repeat: no-repeat;
					background-size: 5.83px 10px;
					background-position: center center;
					width: 6px;
					height: 10px;
					transform: rotate(90deg);
					margin:auto;
					@include media($bp-md) {
						content: none;
						display: none;
					}
				}
				img {
					opacity: 1;
				}
			}
		}
		// .innovations__type-select.drop
		&.drop {
			max-height: none;
			a.current:after {
				transform: rotate(-90deg);
			}
		}
		
		@include modifier("power") {
			a span {
				@include media($bp-md) {
					margin-top: -30px;
			    margin-bottom: 20px;
				}
			}
		}
		
	}
}