
// https://frontstuff.io/generate-all-your-utility-classes-with-sass-maps
// -----------------------------------------------------------------------------


// .element--modifier
@mixin plmr-modifiers($map, $attributes, $prefix: '--', $separator: '-', $base: 'base') {
  @each $key, $value in $map {

    &#{if($key != $base, #{$prefix}#{$key}, '')} {
      @if type-of($value) == 'map' {
        @include plmr-modifiers($value, $attributes, $separator);
      } @else {
        @each $attribute in $attributes {
          #{$attribute}: $value;
        }
      }
    }
  }
}
