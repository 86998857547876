
@include block('the-moen-story') {
  padding-left: rem(24);
  padding-right: rem(24);

  @include media($bp-md) {
    padding-left: 0;
    padding-right: 0;
  }

  @include child('al-moen') {
    border: 5px solid #e5dcd3;
    margin: 0 auto 2em auto;
    max-width: 165px;

    @include child('image') {
      display: block;
      height: auto;
      max-width: 155px;
      width: 100%;
    }
  }
}

@include block('timeline-list') {
  margin-bottom: 2em;

  @include media ($bp-md) {
    background: url('//embed.widencdn.net/img/moen/zabxjxpdzp/exact/timeline-bg.png') 50% 0 repeat-y;
    margin-bottom: 4em;
    position: relative;
  }

  &::before {

    @include media($bp-md) {
      background: url('//embed.widencdn.net/img/moen/el0otrm11p/exact/timeline-sprite.png') 0 0 no-repeat;
      content: ' ';
      display: block;
      height: 35px;
      left: 50%;
      margin-left: -20px;
      position: absolute;
      top: -12px;
      width: 35px;
    }
  }

  &::after {

    @include media($bp-md) {
      clear: both;
      content: '';
      display: table;
    }
  }

  @include child('nav') {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include modifier('previous') {
      left: rem(-24);
    }

    @include modifier('next') {
      right: rem(-24);
    }
  }

  .slick-track {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    .timeline-element.slick-slide {
      margin: 5px;
    }

  }

  &.slick-slider {
    .button.slick-disabled {
      cursor: default;
      opacity: 0.2;
    }
  }
}


@include block('timeline-card') {
  display: block;
  font-size: 16px;
  margin: 50px 5px 5px 5px;
  padding: 5px;
  position: relative;

  @include media($bp-md) {
    margin: 75px 0 0 0;
    min-height: auto;
    padding: 0;
    width: calc(50% - 50px);
  }

  &::before {

    @include media($bp-md) {
      background: url('//embed.widencdn.net/img/moen/el0otrm11p/exact/timeline-sprite.png') 0 0 no-repeat;
      content: '';
      display: block;
      height: 30px;
      position: absolute;
      top: 109px;
      width: 110px;
      z-index: 1;
    }
  }

  &:nth-child(odd) {

    @include media($bp-md) {
      clear: left;
      float: left;
      margin-right: 50px;

      &::before {
        background-position: 0 -50px;
        right: -65px;
      }

    }
  }

  &:nth-child(even) {

    @include media($bp-md) {
      clear: right;
      float: right;
      margin-top: 150px;
      margin-left: 50px;

      &::before {
        background-position: -14px -101px;
        left: -65px;
      }
    }
  }

  &:nth-child(2) {

    @include media($bp-md) {
      margin-top: 250px;
    }
  }

  &:nth-last-child(1)::after {

    @include media($bp-md) {
      background-color: $c_white;
      content: '';
      height: calc(100% - 120px);
      position: absolute;
      top: 123px;
      width: 50px;
      z-index: 0;
    }
  }

  &:nth-last-child(1):nth-child(odd) {

    @include media($bp-md) {

      &::after {
        right: -75px;
      }
    }
  }

  &:nth-last-child(1):nth-child(even) {

    @include media($bp-md) {

      &::after {
        left: -75px;
      }
    }
  }


  @include modifier('text-only') {

    @include media($bp-md) {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      min-height: auto;
    }

    p {
      padding: 10px 0;
    }


    &::before {

      @include media($bp-md) {
        top: 50% ;
        transform: translateY(-50%);
      }
    }

    &:nth-child(odd) {

      &::before {

        @include media($bp-md) {
          background-position: 0 -156px;

        }
      }
    }

    &:nth-child(even) {

      &::before {

        @include media($bp-md) {
          background-position: -13px -217px;
        }
      }
    }
  }

  @include child('preview') {
    position: relative;
     z-index: 3;

    @include child('image') {
      height: auto;
      width: 100%;
    }
  }

  @include child('date') {
    background-color: $c_moen-bright-blue;
    color: $c_white;
    display: inline-block;
    font-size: rem(36);
    height: 63px;
    left: 20px;
    line-height: 63px;
    padding: 0 12px;
    position: absolute;
    transform: translateY(-50%);
    z-index: 4;

    .timeline-card--text-only & {
      background-color: transparent;
      color: $c_moen-bright-blue;
      font-size: rem(30);
      height: 50px;
      line-height: 50px;
      top: 50%;
    }
  }

  @include child('description') {
    background-color: $c_white;
    font-size: rem(16);
    line-height: 24px;
    padding: 40px 20px 20px;
    position: relative;
    top: -5px;
    z-index: 2;

    .timeline-card--text-only & {
      padding: 20px 20px 20px 120px;
    }
  }
}
