/*
Product Tiles

Styleguide 4.12
*/

/*
Product Tile - US Market Primary Variant

markup:
<a class="tile product-tile" href="#">
<div class="product-tile__like"></div>
<div class="product-tile__head">
  <img src="images/faucet-1-fpo.png" class="product-tile__image" />
  <p class="product-tile__name">Voss one-handle high arc pullout kitchen faucet in oil rubbed bronze kitchen faucet in oil rubbed bronze</p>
  <p class="product-tile__sku">Model: TS1234-5678</p>
</div>
<div class="product-tile__body">
  <div class="rating product-tile__rating">
    <div class="stars stars--gray">
      <div class="ratings-meter">
        <div class="cutouts"><span style="width:95.676%;"></span></div>
      </div>
      <div class="rating">4.8</div>
    </div>
  </div>
  <p class="product-tile__msrp">$397.40 msrp</p>
</div>
</a>

Styleguide 4.12.1
*/

@include block(product-tile) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $c_text-base;
  align-items: center;
  width: 50%;
  text-align: center;
  margin-bottom:5px;
  max-width: 260px;
  overflow: hidden;
  padding-top: 1.7em;
  position: relative;
  text-decoration: none;
  -ms-flex-preferred-size: auto;
  z-index: z("facets") - 1;

  .rating {
    margin-left: 6px;
  }

  &:focus,
  &:hover {

    .favorite__icon {
      display: block;
    }

    .cutouts {
      &:before {
        content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20130%2022%22%3E%3Cstyle%20type%3D%22text/css%22%3E%20%20%0A%09.st0%7Bfill%3A%23F3F3F3%3B%7D%0A%3C/style%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M0%200v22h130V0H0zM18.2%2020L12%2016l-6.2%204%202.1-6.8L2%208.8h7.5L12%202l2.5%206.8H22l-5.8%204.4L18.2%2020zM44.8%2020l-6.2-4%20-6.2%204%202.1-6.8%20-5.8-4.4H36L38.5%202%2041%208.8h7.5l-5.8%204.4L44.8%2020zM71.2%2020L65%2016l-6.2%204%202.1-6.8L55%208.8h7.5L65%202l2.5%206.8H75l-5.8%204.4L71.2%2020zM97.8%2020l-6.2-4%20-6.2%204%202.1-6.8%20-5.8-4.4H89L91.5%202%2094%208.8h7.5l-5.8%204.4L97.8%2020zM124.2%2020l-6.2-4%20-6.2%204%202.1-6.8L108%208.8h7.5L118%202l2.5%206.8h7.5l-5.8%204.4L124.2%2020z%22/%3E%3C/svg%3E");
      }
    }
  }
  // For empty elements added
  &:empty {
    height: 0;
    padding: 0;
  }
  @include media($bp-md) {
    width: 25%;
    max-width: 250px;
  }

  // Elements
  @include element(head) {
    flex-shrink: 0;
    width: 100%;
  }
  @include element(body) {
    flex-shrink: 0;
    width: 100%;
  }
  @include element(image) {
    height: auto;
    max-width: 100%;
    margin-bottom: 1em;
    @include media($bp-md) {
      height: auto;
      max-width: 165px;
    }
  }
  @include element(name) {
    font-size: inherit;
    color: $c_moen-bright-blue;
    max-width: 240px; // ie10, ie11 fix
    padding: 1em .5em;
    text-transform: capitalize; /// This maybe removed
  }
  @include element(sku) {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include element(rating) {
    margin-bottom: 1rem;
    line-height: em(14);
    justify-content: center;
    .stars {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      @include media($bp-xs) {
        justify-content: space-between;
        align-items: flex-start;
      }
    }
    .ratings-meter {
      width: 100px;
    }

    @include modifier(count) {
      margin-left: .5rem;
      font-size: em(12);
      font-family: $din-regular;
    }
  }
  @include element(msrp) {
    font-family: $din-regular;
  }

  @include element(like) {
    display: block;
    z-index: 1;
    position: absolute;
    top: .5em;
    right: .5em;
    img {
      width: 16px;
      height: 16px;
    }
    @include state(selected) {
      display: flex;
    }
  }


  @include modifier('new') {
    .corner-ribbon::before {
      background: $c_moen-bright-blue;
      color: $c_white;
      content: '新品';
      width: 145px;
      top: auto;
      left: auto;
      bottom: 2px;
      right: -54px;
      transform: rotate(-40deg);
    }
  }
  @include modifier('hot') {
    .corner-ribbon::before {
      background: $c_moen-bright-blue;
      color: $c_white;
      content: '热销';
      width: 145px;
      top: auto;
      left: auto;
      bottom: 2px;
      right: -54px;
      transform: rotate(-40deg);
    }

  }

  @include modifier('discontinued') {
    .corner-ribbon {
      position: relative;
      height: auto;
      margin: 0 auto;
      max-width: 100%;

      @include media($bp-md) {
        height: auto;
        max-width: 165px;
      }

      &:before {
        content: 'DISCONTINUED';
      }
    }
  }

  @include modifier('with-swatch') {

    background-color: transparent !important;
    padding: 20px;
    border: solid $c_moen-bright-gray 1px;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 0 20px 0 rgba(150,150,150,0);
    &:hover {
			box-shadow: 0 0 20px 0 rgba(150,150,150,0.25);
    }
    a {
	    text-decoration: none !important;
    }

    .product-tile__image {
        display: block;
        height: 0;
        opacity: 0;
        max-height: 263px;
        max-width: 263px;
        transition: opacity 1s linear;
        &.is-selected {
            height: auto;
            opacity: 1;
            width: 100%;
        }
    }

    .product-tile__like {
	    .favorite__icon {
		    display: block;
		    outline: none;
		    cursor: pointer;
		    background-image: url('images/icon-like-inactive.svg');
		    background-size: 18px auto;
		    background-position: center center;
		    background-repeat: no-repeat;
		    transition: background 0.3s ease;
		    width: 18px;
		    height: 18px;
		    &:hover {
			    background-image: url('images/icon-like-hover.svg');
		    }
		    &.is-active {
  		    background-image: url('images/icon-like-active.svg');
		    }
	    }
    }
    .product-tile__badge {
	    background-color: $c_moen-bright-blue;
	    color: #FFF;
	    border-radius: 3px;
	    font: normal 10px/10px $proxima-bold;
	    text-transform: uppercase;
	    letter-spacing: 1.36px;
	    padding: 3px 10px;
	    position: absolute;
	    top: 10px;
	    left: 10px;
	    display: inline-block;
	    text-align: center;
	    z-index: 3;
    }
	  .product-tile__image {
		  display: none;
		  //margin-bottom: em(20);
			// max-width: 100%;
			outline: none;
		  &.is-selected {
			  display: block;
        margin-bottom: em(20);
		  }
	  }
	  .hero-section__finish-list {
		  margin-bottom: em(10);
		  .hero-section__finish-button {
			  line-height: 0;
			  border-width: 1px;
			  padding: 1px;
			  outline: none;
			  &:hover {
				  border-color: #969696;
			  }
			  &.is-selected, &.is-selected:hover {
				  border-color: $c_moen-bright-blue;
			  }
		  }
		  img {
        height: 15px;
			  width: 15px;
		  }
	  }
    .product-tile__body {
	    .product-tile__sku {
		    font: normal em(12) $din-regular;
		    color: $c_moen-gray;
		    padding:0;
		    margin-bottom: 10px;
		    a {
			    color: inherit;
		    }
	    }
	    .product-tile__msrp {
		    font: normal em(14) $din-bold;
		    color: $c_text-base;
		    text-transform: uppercase;
		    padding:0;
		    margin-bottom: 35px;
		    a {
			    color: inherit;
		    }
	    }
      .rating {
        justify-content: center;
        .rating {
          display: none;
        }
        .ratings-meter {
          height: 10px;
          width: 70px;
          .cutouts:before {
            width: 76px;
            content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20130%2022%22%3E%3Cstyle%20type%3D%22text/css%22%3E%20%20%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C/style%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M0%200v22h130V0H0zM18.2%2020L12%2016l-6.2%204%202.1-6.8L2%208.8h7.5L12%202l2.5%206.8H22l-5.8%204.4L18.2%2020zM44.8%2020l-6.2-4%20-6.2%204%202.1-6.8%20-5.8-4.4H36L38.5%202%2041%208.8h7.5l-5.8%204.4L44.8%2020zM71.2%2020L65%2016l-6.2%204%202.1-6.8L55%208.8h7.5L65%202l2.5%206.8H75l-5.8%204.4L71.2%2020zM97.8%2020l-6.2-4%20-6.2%204%202.1-6.8%20-5.8-4.4H89L91.5%202%2094%208.8h7.5l-5.8%204.4L97.8%2020zM124.2%2020l-6.2-4%20-6.2%204%202.1-6.8L108%208.8h7.5L118%202l2.5%206.8h7.5l-5.8%204.4L124.2%2020z%22/%3E%3C/svg%3E") !important;
          }
        }
        .review-count {
          font: normal em(10) $din-regular;
          color: $c_moen-bright-blue;
          margin-left: 7px;
        }
        .no-stars {
	        font: normal em(10) $din-regular;
        }
      }
      .cta {
        font: normal em(14) $din-bold;
        color: $c_moen-bright-blue;
        margin-left: 6px;
        text-decoration: none !important;
        img {
	        height: 10px;
	        width: auto;
	        vertical-align: middle;
	        display: inline-block;
	        margin-left: 10px;
        }
      }
    }


	  .product-tile__name {
      color: $c_text-base;
      font: normal em(14) $din-regular;
      padding-top:0;
      display: block;
      padding-bottom: em(15);
    }
  }


}

/*
Product Tile - US Market Primary Variant (No MSRP)

markup:
<a class="tile product-tile" href="#">
<div class="product-tile__like"></div>
<div class="product-tile__head">
  <img src="images/faucet-1-fpo.png" class="product-tile__image" />
  <p class="product-tile__name">Voss one-handle high arc pullout kitchen faucet in oil rubbed bronze kitchen faucet in oil rubbed bronze</p>
  <p class="product-tile__sku">Model: TS1234-5678</p>
</div>
<div class="product-tile__body">
  <div class="rating product-tile__rating">
    <div class="stars stars--gray">
      <div class="ratings-meter">
        <div class="cutouts"><span style="width:95.676%;"></span></div>
      </div>
      <div class="rating">4.8</div>
    </div>
  </div>
  <p class="product-tile__msrp">&nbsp;</p>
</div>
</a>

Styleguide 4.12.1.1
*/

/*
Product Tile - Like Selected

A user has set an individual product as a favorite, reflected by the icon at top-right.

markup:
<a class="tile product-tile" href="#">
<div class="product-tile__like is-selected"></div>
<div class="product-tile__head">
  <img src="images/faucet-1-fpo.png" class="product-tile__image" />
  <p class="product-tile__name">Voss one-handle high arc pullout kitchen faucet in oil rubbed bronze kitchen faucet in oil rubbed bronze</p>
</div>
<div class="product-tile__body">
  <p class="product-tile__sku">Model: TS1234-5678</p>
  <div class="rating product-tile__rating">
    <div class="stars stars--gray">
      <div class="ratings-meter">
        <div class="cutouts"><span style="width:95.676%;"></span></div>
      </div>
      <div class="rating">4.8</div>
    </div>
  </div>
  <p class="product-tile__msrp">$397.40 msrp</p>
</div>
</a>

Styleguide 4.12.2
*/

/*
Product Tile - Canada Market Variant

For the Canada market, no pricing (MSRP) is displayed. This variant simply omits the msrp element from the product tile.

markup:
<a class="tile product-tile" href="#">
<div class="product-tile__like"></div>
<div class="product-tile__head">
  <img src="images/faucet-1-fpo.png" class="product-tile__image" />
  <p class="product-tile__name">Voss one-handle high arc pullout kitchen faucet in oil rubbed bronze kitchen faucet in oil rubbed bronze</p>
  <p class="product-tile__sku">Model: TS1234-5678</p>
</div>
<div class="product-tile__body">
  <div class="rating product-tile__rating">
    <div class="stars stars--gray">
      <div class="ratings-meter">
        <div class="cutouts"><span style="width:95.676%;"></span></div>
      </div>
      <div class="rating">4.8</div>
    </div>
  </div>
</div>
</a>

Styleguide 4.12.3
*/

/*
Product Tile - Customer Support Page Variant

For the Customer Support Page, no pricing (MSRP) or reviews are displayed. This variant simply omits the msrp and rating elements from the product tile.

markup:
<a class="tile product-tile" href="#">
<div class="product-tile__like"></div>
<div class="product-tile__head">
<img src="images/faucet-1-fpo.png" class="product-tile__image" />
<p class="product-tile__name">Voss one-handle high arc pullout kitchen faucet in oil rubbed bronze kitchen faucet in oil rubbed bronze</p>
</div>
<div class="product-tile__body">
<p class="product-tile__sku">Model: TS1234-5678</p>
</div>
</a>

Styleguide 4.12.4
*/

/*
Product Tile - With Discontinued Ribbon

markup:
<a class="tile product-tile product-tile--discontinued" href="#">
<div class="product-tile__like"></div>
<div class="product-tile__head">
  <div class="corner-ribbon"><img src="images/faucet-1-fpo.png" class="product-tile__image" /></div>
  <p class="product-tile__name">Voss one-handle high arc pullout kitchen faucet in oil rubbed bronze kitchen faucet in oil rubbed bronze</p>
  <p class="product-tile__sku">Model: TS1234-5678</p>
</div>
<div class="product-tile__body">
  <div class="rating product-tile__rating">
    <div class="stars stars--gray">
      <div class="ratings-meter">
        <div class="cutouts"><span style="width:95.676%;"></span></div>
      </div>
      <div class="rating">4.8</div>
    </div>
  </div>
  <p class="product-tile__msrp">$397.40 msrp</p>
</div>
</a>

Styleguide 4.12.5
*/


/*
Product Tile - With NEW Ribbon

markup:
<a class="tile product-tile product-tile--new" href="#">
<div class="product-tile__like"></div>
<div class="product-tile__head">
  <div class="corner-ribbon"><img src="images/faucet-1-fpo.png" class="product-tile__image" /></div>
  <p class="product-tile__name">Voss one-handle high arc pullout kitchen faucet in oil rubbed bronze kitchen faucet in oil rubbed bronze</p>
  <p class="product-tile__sku">Model: TS1234-5678</p>
</div>
<div class="product-tile__body">
  <div class="rating product-tile__rating">
    <div class="stars stars--gray">
      <div class="ratings-meter">
        <div class="cutouts"><span style="width:95.676%;"></span></div>
      </div>
      <div class="rating">4.8</div>
    </div>
  </div>
  <p class="product-tile__msrp">$397.40 msrp</p>
</div>
</a>

Styleguide 4.12.6
*/

// Layout into a pseudo-grid using flexbox instead of the 12-col grid:

.tile-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  -ms-flex-preferred-size: auto;
  @include media ($bp-lg) {
    justify-content: flex-start;
  }
}

.tile-row-button {
  .button--load-more {
    margin-top: 10px;
  }
}


// Modernizr Touch Events Behave
.touchevents {
  .product-tile__like {
    display: block;
  }
}



@include block('featured-benefit') {

  @include child('tile') {
    overflow: visible !important;
  }

}
