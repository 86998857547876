/**
@section Flyout Sidebar
@childof Galleries
@page Galleries Flyout Sidebar
@status in progress
@example
_markup/flyout.hbs
*/
@include plmr-component('filtering') {

  // .plmr-c-filtering__flyout
  @include element('flyout') {

  }

  // .plmr-c-filtering__flyout-sortbar
  @include element('flyout-sortbar') {
    display: block;

    @include plmr-mq(md) {
      display: none;
    }
  }

  // .plmr-c-filtering__flyout-tags
  @include element('flyout-tags') {
    display: none;
    font-size: 0;
    overflow: hidden;
    padding: 30px 25px 1px;

    @include plmr-mq(md) {
      align-items: flex-start;
      display: flex;
    }

    @include plmr-mq(lg) {
      padding-left: 55px;
      padding-right: 55px;
    }

    @include plmr-mq(xl) {
      padding: 0;
    }
  }

  // .plmr-c-filtering__flyout-tags-button
  @include element('flyout-tags-button') {
    flex: 1 0 auto;
    max-width: 127px;
  }
}
