/*
Hero Section no variants

Markup:
<!-- container element is just needed for the styleguide -->
<div class="container">
  <div class="hero-section row">
    <div class="hero-section__image">
      <img src="http://placehold.it/400x400" alt="" aria-hidden="true">
    </div>
    <div class="hero-section__description">
      <div class="hero-section__model">Model: TS1234-5678</div>
      <h1 class="hero-section__text">90 Degree Spot Resist Stainless One-Handle High Arc Kitchen Faucet</h1>
      <div class="hero-section__social-group">
        <div class="hero-section__reviews">
          <div class="rating">
            <ul class="rating__stars">
              <li class="rating__star is-active"></li>
              <li class="rating__star is-active"></li>
              <li class="rating__star is-active"></li>
              <li class="rating__star"></li>
              <li class="rating__star"></li>
            </ul>
          </div>
          <a class="link" href="#">2 reviews</a>
        </div>
        <div class="hero-section__share">
          <span class="hero-section__share__label">Share</span>
          <a href="#"><img src="./images/icon-facebook_gray.svg" alt="" aria-hidden="true"></a>
          <a href="#"><img src="./images/icon-twitter_gray.svg" alt="" aria-hidden="true"></a>
          <a href="#"><img src="./images/icon-pinterest_gray.svg" alt="" aria-hidden="true"></a>
          <a href="#"><img src="./images/icon-houzz_gray.svg" alt="" aria-hidden="true"></a>
        </div>
        <div class="hero-section__favorite favorite">
          <span>Add to Favorites</span>
          <a href="#" class="favorite__icon">
            <img src="./images/icon-favorite_o_gray.svg" alt="" aria-hidden="true" class="favorite__icon__unliked">
            <img src="./images/icon-favorite_gray.svg" alt="" aria-hidden="true" class="favorite__icon__liked">
          </a>
        </div>
      </div>
      <div class="hero-section__finish">
        <div class="hero-section__left">
          <span>Finish: Brushed Nickel</span>
          <ul class="hero-section__finish-list">
            <li>
              <a href="#" role="button" class="hero-section__finish-button is-selected">
                <img src="http://www.moen.com/shared/swatches/brushed-nickel.png" alt="Brushed Nickel">
              </a>
            </li>
            <li>
              <a href="#" role="button" class="hero-section__finish-button">
                <img src="http://www.moen.com/shared/swatches/chrome.png" alt="Chrome">
              </a>
            </li>
            <li>
              <a href="#" role="button" class="hero-section__finish-button">
                <img src="http://www.moen.com/shared/swatches/chrome.png" alt="Chrome">
              </a>
            </li>
            <li>
              <a href="#" role="button" class="hero-section__finish-button">
                <img src="http://www.moen.com/shared/swatches/chrome.png" alt="Chrome">
              </a>
            </li>
            <li>
              <a href="#" role="button" class="hero-section__finish-button">
                <img src="http://www.moen.com/shared/swatches/chrome.png" alt="Chrome">
              </a>
            </li>
          </ul>
        </div>
        <div class="hero-section__right">
          <span>$88.50 msrp</span>
        </div>
      </div>
      <div class="hero-section__buy">
        <div class="hero-section__buy-price">
          <span>$788.50 msrp</span>
          <a href="javacript:void(0);" class="tooltip">
            <div class="tooltip__icon tooltip__icon--question"><img src="images/icon-question_mark.svg" alt="Help"></div>
            <div class="tooltip__modal">Manufacturer Suggested Retail Price is suggested only. Store pricing will vary.</div>
          </a>
        </div>
        <a href="#" class="hero-section__buy-button button button--secondary-flat">Where to buy</a>
      </div>
      <div class="hero-section__share">
        <a href="#"><img src="./images/icon-facebook_gray.svg" alt="" aria-hidden="true"></a>
        <a href="#"><img src="./images/icon-twitter_gray.svg" alt="" aria-hidden="true"></a>
        <a href="#"><img src="./images/icon-pinterest_gray.svg" alt="" aria-hidden="true"></a>
        <a href="#"><img src="./images/icon-houzz_gray.svg" alt="" aria-hidden="true"></a>
      </div>
      <div class="row">
        <a href="#" class="link">
           Support for this product
        </a>
      </div>
    </div>
  </div>
</div><!-- end container -->

Styleguide 4.10.1
*/

/*
Hero Section with variants

Markup:
<!-- container element is just needed for the styleguide -->
<div class="container">
  <div class="hero-section row">
    <div class="hero-section__image">
      <img src="http://placehold.it/400x400" alt="" aria-hidden="true">
    </div>
    <div class="hero-section__description">
      <div class="hero-section__model">Model: TS1234-5678</div>
      <h1 class="hero-section__text">90 Degree Spot Resist Stainless One-Handle High Arc Kitchen Faucet</h1>
      <div class="hero-section__social-group">
        <div class="hero-section__reviews">
          <div class="rating">
            <ul class="rating__stars">
              <li class="rating__star"></li>
              <li class="rating__star"></li>
              <li class="rating__star"></li>
              <li class="rating__star"></li>
              <li class="rating__star"></li>
            </ul>
          </div>
          <a class="link" href="#">write a review</a>
        </div>
        <div class="hero-section__share">
          <span>Share</span>
          <a href="#"><img src="./images/icon-facebook_gray.svg" alt="" aria-hidden="true"></a>
          <a href="#"><img src="./images/icon-twitter_gray.svg" alt="" aria-hidden="true"></a>
          <a href="#"><img src="./images/icon-pinterest_gray.svg" alt="" aria-hidden="true"></a>
          <a href="#"><img src="./images/icon-houzz_gray.svg" alt="" aria-hidden="true"></a>
        </div>
        <div class="hero-section__favorite favorite">
          <span>Add to Favorites</span>
          <a href="#" class="favorite__icon">
            <img src="./images/icon-favorite_o_gray.svg" alt="" aria-hidden="true" class="favorite__icon__unliked">
            <img src="./images/icon-favorite_gray.svg" alt="" aria-hidden="true" class="favorite__icon__liked">
          </a>
        </div>
      </div>
      <div class="hero-section__finish">
        <div class="hero-section__left">
          <span>Finish: Brushed Nickel</span>
          <ul class="hero-section__finish-list">
            <li>
              <a href="#" role="button" class="hero-section__finish-button is-selected">
                <img src="http://www.moen.com/shared/swatches/brushed-nickel.png" alt="Brushed Nickel">
              </a>
            </li>
            <li>
              <a href="#" role="button" class="hero-section__finish-button">
                <img src="http://www.moen.com/shared/swatches/chrome.png" alt="Chrome">
              </a>
            </li>
          </ul>
        </div>
        <div class="hero-section__right">
          <span>$88.50 msrp</span>
        </div>
      </div>
      <div class="hero-section__options">
        <div class="hero-section__subtitle heading--4">Valve</div>
        <div class="hero-section__left">
          <select class="form-select">
              <option data-sku="2510" data-price="112.900000">2510: Posi–Temp® 1/2" IPS connection includes pressure balancing - $112.90</option>
              <option data-sku="2520" data-price="112.900000" selected="">2520: Posi–Temp® 1/2" CC connection includes pressure balancing - $112.90</option>
              <option data-sku="2570" data-price="137.050000">2570: Posi–Temp® 1/2" CC connection includes pressure balancing - $137.05</option>
              <option data-sku="2590" data-price="137.050000">2590: Posi–Temp® 1/2" IPS connection includes pressure balancing - $137.05</option>
          </select>
        </div>
        <div class="hero-section__right">
          <span>$112.90 msrp</span>
        </div>
      </div>
      <div class="hero-section__required">
        <div class="hero-section__subtitle heading--4">Required Parts</div>
        <div class="hero-section__required-parts">
          <div class="hero-section__left">
            S3371: Moen ExactTemp® 3/4" IPS connection includes check stops thermostatic
          </div>
          <div class="hero-section__right">
            <span>$112.90 msrp</span>
          </div>
        </div>
        <div class="hero-section__required-parts">
          <div class="hero-section__left">
            S3371: Moen ExactTemp® 3/4" IPS connection includes check stops thermostatic
          </div>
          <div class="hero-section__right">
            <span>$112.90 msrp</span>
          </div>
        </div>
      </div>
      <div class="hero-section__buy">
        <div class="hero-section__buy-price">
          <span>$788.50 msrp</span>
          <a href="javacript:void(0);" class="tooltip">
            <div class="tooltip__icon tooltip__icon--question"><img src="images/icon-question_mark.svg" alt="Help"></div>
            <div class="tooltip__modal">Manufacturer Suggested Retail Price is suggested only. Store pricing will vary.</div>
          </a>
        </div>
        <a href="#" class="hero-section__buy-button button button--secondary-flat">Where to buy</a>
      </div>
      <div class="hero-section__share">
        <a href="#"><img src="./images/icon-facebook_gray.svg" alt="" aria-hidden="true"></a>
        <a href="#"><img src="./images/icon-twitter_gray.svg" alt="" aria-hidden="true"></a>
        <a href="#"><img src="./images/icon-pinterest_gray.svg" alt="" aria-hidden="true"></a>
        <a href="#"><img src="./images/icon-instagram_gray.svg" alt="" aria-hidden="true"></a>
      </div>
      <div class="row">
        <a class="link" href="#">Support for this product</a>
      </div>
    </div>
  </div>
</div><!-- end container -->

Styleguide 4.10.2
*/

@include block(hero-section) {
  //padding: 0 1em;


  /// .hero-section.has-carousel
  &.has-carousel {
    margin-top: 320px;


    /// screen width > 768px
    @include media($bp-md) {
      margin-top: 0;
    }
  }



  @include media($bp-md) {
    align-items: flex-start;
    display: flex;
    margin-top: 0;
    justify-content: space-between;
    padding: em(30) 0;
    padding-bottom: em(25);
    column-gap: em(30);
  }


  @include element(top_container) {
    @include media($bp-md) {
       width: 50%;
    }

    .product-detail-attribute-pc .product-detail-attribute{
        margin-top: 15px;
    }

    .selling-poinnt-section-title{
      font-family: 'DINWebRegular' !important;
    }

  }

  @include element(brand) {
      display: flex;
      column-gap: 13px;
      flex-wrap: wrap;
      row-gap: 13px;
      padding: 1em;
      padding-bottom: 0px;

     .hero-section__brand_item{
        width: 50px;
       @include media($bp-md) {
         width: 60px;
       }
     }
    .hero-section__brand_item img{
      width: 100%;
      display: block;
    }


  }

  @include element(image) {
    padding: 1.2em 1em;
    position: relative;
    /// .has-carousel .hero-section__image
    .has-carousel & {
      display: none;


      /// screen width > 768px
      @include media($bp-md) {
        display: block;

      }

    }


    #hero-section-main-img {
      display: block;
      height: auto;
      max-width: 400px;
      margin: 0 auto;
      width: 100%;
      border-radius: 13px;
    }

    video {
      display: block;
      height: auto;
      margin: 0 auto;
      width: 100%;
      border-radius: 13px;
    }

    .hero-section__video_container{
      position: relative;
      border-radius: 13px;
      overflow: hidden;
      display: none;
    }

    .hero-section__video_mask{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.4);
        top: 0;
        left: 0;
    }
    .hero-section__video_play{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
        width: 64px;
        cursor: pointer;
    }
  .hero-section-pdp-main{

    @include media($bp-md) {
      position: relative;
      //width: 570px;
      //height: 400px;
    }
  }
    .hero-section-pdp-main_search{
      width: 220px;
      height: 220px;
      //border: 1px solid #CCC;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(91,127,149,0.4);
       cursor: move;
       display: none;
         opacity: 0;
          @include media($bp-md) {
          opacity: 1;
         }
      }


      .section__image_magnifying_glass{
        overflow: hidden;
        position: absolute;
        top: -70px;
        border: 1px solid #CCCCCC;
        background: #fff;
        z-index: 1;
        display: none;

        @include media($bp-md) {
          width:600px;
          height: 550px;
          left: 620px;
        }
      }

    .section__image_magnifying_glass img{
      position: absolute;
    }


      @include media($bp-md) {
        display: block;
        padding: 1.5em 1em;
      }
    }

    @include element(imageItem) {
      display: flex;
      column-gap: 10px;
      padding: 1em;
      padding-top: 0px;

      .hero-section__imageItem_img{
        border: 1px solid #DCDCDC;
        border-radius: 5px;
        width: 45px;
        height: 45px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media($bp-md) {
          width: 60px;
          height: 60px;
        }
      }

      .hero-section__imageItem_img_video{
        padding: 8px;
        padding-top: 7px;
      }

      .hero-section__imageItem_img_play{
          width: 25px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
      }

      .hero-section__imageItem_img_play img{
          width: 100%;
          display: block;
      }
      .hero-section__imageItem_img:hover{
        @include media($bp-md) {
          border-color: #5B7F95;
        }
      }

      .imageItem_selected{
        border-color: #5B7F95;
      }

      .hero-section__imageItem_img img{
        display: block;
        width: 100%;
      }

    }


    @include element(line) {
      width: 100%;
      height: 1px;
      border-top: 1px solid #F2F2F2;

      @include media($bp-md) {
        display: none;
      }
    }

     @include element(peculiarity) {
       opacity: 0;
       transition: all .3s;

       @include media($bp-md) {
         padding: 0 3em;
       }


       .hero-section__peculiarity-list-item{
         display: block !important;
         padding: 6px;

         @include media($bp-md) {
           padding: 0 8px !important;
         }

       }

       .hero-section__peculiarity-list-item .hero-section__peculiarity-list-item-img{
         width: 45px;
       }

       .hero-section__peculiarity-list-item img{
           width: 100%;
           display: block !important;
       }



       @media (max-width: 450px) {
         .slick-slide{
           width: 45px !important;
           margin-right: 12px;
         }
       }


       .slick-list{
         padding: 0 10px;
         @include media($bp-md) {
           padding: 0px;
         }
       }
       .slick-prev{
         @include media($bp-md) {
           background-image: none;
           border: solid #5b7f95;
           border-width: 0 1px 1px 0;
           display: inline-block;
           padding: 3px;
           width: 11px;
           height: 11px;
           transform: rotate(135deg);
           cursor: pointer;
           top: 42%;
         }
       }

       .slick-next{
         @include media($bp-md) {
           background-image: none;
           border: solid #5b7f95;
           border-width: 0 1px 1px 0;
           display: inline-block;
           padding: 3px;
           width: 11px;
           height: 11px;
           transform: rotate(-45deg);
           cursor: pointer;
           top: 42%;
         }
       }

     }

     @include element(peculiarity_padding){
        padding: 0 1em !important;

       .slick-list{
         padding: 0  !important;
       }
       .slick-slide{
         width: 45px !important;
         margin-right: 12px;
       }
       .hero-section__peculiarity-list-item{
         padding: 0;
       }
       @include media($bp-md) {
         .slick-slide{
           margin-right: 15px;
         }
       }
     }



    @include element('support-image') {
      padding: 1em 0;

      img {
        display: block;
        height: auto;
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
      }

      @include media($bp-md) {
        display: block;
        margin-right: 40px;
        width: 40%;
      }
    }


    @include modifier('support-hero') {
      margin-top: 0;
    }



    @include element(description) {
      display: block;
      line-height: 2.5em;
      padding: 1em 0px;
      padding-bottom: .75em;

      .hero-section__finish{
        padding: 0px 1em;
      }
      .plmr-c-finishes-nav__finish{
        width: 32px !important;
        height: 32px !important;
        padding: 4px;
        padding-top: 3.5px;
      }

      .hero-section__finish_color{
          margin: 0px;
          margin-top: 8px;
      }

      .hero-section__finish_size{
        margin-top: 5px;
      }

      .hero-section__finish-size li:hover{
        border-color: #5b7f95 !important;
      }

      .hero-section__finish-size li._selected{
        border: 1px solid;
        border-color: #5b7f95 !important;
        font-weight: 500;
        @include media($bp-md) {
          border: 2px solid;
        }

      }
      .hero-section__finish-size li._selected a{
         font-size: 13px;
         color: #5B7F95 !important;
      }
      .hero-section__finish-size li{
        line-height: 18px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        border-radius: 2px;
      }

      .plmr-c-finishes-nav__finish._selected{
        border: 1px solid;
        border-color: #5b7f95 !important;
        @include media($bp-sm) {
          border: 2px solid;
          padding-top: 2px;
        }
      }

      p{
        padding-bottom:0;
        font-size: 14px;
        line-height:22px;
      }


      @include media($bp-sm) {
        border-bottom: 0;
        p{
          font-size: 13px;
          line-height: 24px;
          color: #888;
        }
      }


      @include media($bp-md) {
        width: 50%;
        padding: 1em;
      }

      @include media($bp-lg) {
        .hero-section__social-group {
          .hero-section__favorite {
            display: inline-flex;
          }
        }
      }


    }

    @include element(buy) {
      margin: .6rem 0 2rem 0;
      @include media($bp-md) {
        margin-top: 2em;
        display: flex;
        justify-content: space-between;

        .hero-section__buy-price {
          order: 2;
          margin: 0;
        }
        .hero-section__buy-button {
          order: 1;
          margin-right: 2em;
        }
      }
    }

    @include element(buy-price) {
      font-size: 1.3em;
      margin-bottom: .5rem;
      text-align: left;
      & > * {
        display: inline-block;
        vertical-align: middle;
      }
    }

    @include element(buy-button) {
      display: block;
      width: 100%;
      margin: 0 auto;
      padding: 1.4rem 0!important;
      font-size: 1rem;
       &.plmr-c-button{
         color: #ffffff !important;
       }
      @include media($bp-md) {
        display: inline-block;
        margin: auto 0;
        width: auto;
        padding: 1.4rem 3rem!important;
        font-size: 1rem;

      }
    }

    @include element(text) {
      position: relative;
      a{
        outline: none;
      }
     @include  media($bp-md){
       //border-bottom: 1px solid #5b7f95;
       .hero-section__text-box{
         display: inline-block;
       }
     }
      span{
        margin-left: 20px;
      }
      .hero-section__text-box{

        display: inline-block;
        float: right;
      }
      span img{
        width:26px;
      }
      span .collection-dislike img,span .collection-like img{
        width:20px;
      }
      h1{
        font-weight: 600;
        display: inline;
        letter-spacing: 1px;
        font-size: 18px;
        color: #252C32;
        font-family: 'DINWebRegular';
        @include media($bp-md) {
          font-size: 25px;
          font-weight: 800;
          margin: 15px 0;
          display: block;
        }
      }
      .hero-section__text-box{
        display:none;
      }

      font-size: em(24px);
      font-family: $din-light;
      font-weight: normal;
      //line-height: 1.2;
      line-height: 2;
      letter-spacing: -.05em;
      margin-bottom: 0;
      text-transform: capitalize;
      max-width:530px;
      padding:0px 0.7em;
    }


    @include element(series) {
      display: flex;
      align-items: center;
      column-gap: 10px;
      line-height: normal;
      padding:0px 1em;
      .hero-section__series_tag{
         border-radius: 4px;
         background: #EEF0F2;
         padding: 4px 10px;
         line-height: normal;
        color: #252C32;
        @include media($bp-md) {
          padding: 2px 8px;
          padding-top: 1px;
        }
      }
      .hero-section__series_tag span{
        font-size: 12px;
        color: #000;
      }

      .hero-section__series_model{
          font-size: 13px;
          color: #60717F;
      }

    }


    @include element(model) {
      overflow: hidden;
      font-size: em(14px);
      span img{
        width:26px;
      }
     span{
       margin-left: 20px;
     }
      p{
        font-size: 14px;
        display:inline-block;
        color: #888;
      }
      .hero-section__text-box{
        font-size: 12px;
        display:inline-block;
        float: right;
      }
      @include media($bp-md) {
        height: 24px;
        .hero-section__text-box{
          display:none;
        }
      }
    }


    @include element(contact) {
      margin: 22px 0 10px 0;
      .comtact_tel{
        text-indent: 23px;
        font-size: 16px;
        font-weight: 600;
        color:#1f1f1f;
        &:first-child{
          margin-bottom: 10px;
        }
      }
      .comtact_tel a{
        color: black;
        font-family: DINWebRegular,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
      }
    }

    @include element(reviews) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @include media($bp-sm) {
        width: auto;
      }
    }

    @include element(border) {
      @include media($bp-sm) {
        border-top: 1px solid #ffffff !important;
        margin: 0;
        padding-top:0;
      }
      border-top: 1px solid #ffffff !important;
      margin: 10px 0 0;
      padding-top:0;
    }

    @include element(finish) {
      a{
        outline: none;
        color: #888 !important;
        text-decoration: none;
        font-weight: 400;
      }
      img{
        border: 0;
      }
      span{
        font-size: 14px;
        color: #252C32;
      }
      margin-bottom: .5rem;
      @include media($bp-md) {
        border-top: 1px solid $c_moen-bright-gray;
        //margin: .7em 0;
        margin: 0;
        padding-top: 0;
      }
    }

    @include element(finish-button) {
      display: block;
      border: 2px solid $c_white;
      border-radius: 50%;
      line-height: 1rem;
      &:hover {
        border-color: $c_moen-bright-blue;
      }
      @include state(selected) {
        border-color: $c_moen-bright-blue;
      }
    }

    @include element(finish-purchase){
      list-style: none;
      margin-top: 10px;
      display: flex;
      column-gap: 14px;

      .hero-section__finish-purchase_item{
           width:25%;
           border: 1px solid rgba(91,127,149,0.3);
        height: 70px;
        display: flex;
        justify-content: center;
        border-radius: 2px;
        @include media($bp-md) {
          height: 44px;
          cursor: pointer;
        }
      }
      .hero-section__finish-purchase_item:hover{
        @include media($bp-md) {
           border-color: #5b7f95;
        }
      }
      .hero-section__finish-purchase_item a{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include media($bp-md) {
          flex-direction: row;
          column-gap: 6px;
          align-items: center;
        }
      }
      .hero-section__finish-purchase_item a span{
        font-size: 12px;
        line-height: normal;
        font-weight: 500;
        color: #5B7F95;
        margin-top: 8px;
        @include media($bp-md) {
          margin-top: 0px;
          font-size: 14px;
        }
      }
      //div{
      //  display: flex;
      //  align-items: center;
      //  margin-bottom:22px;
      //  div {
      //    margin-right: 22px;
      //    display: inline-block;
      //  }
      //}
    }
    @include element(finish-list) {
      padding-left:0;
      a{
        color: black;
      }
      li {
        display: inline-block;
        cursor:pointer;
        &:hover{
          border-color:#c8893b;
          padding-top: 2px;
        }
        img{
          vertical-align: baseline;
        }
        &:last-child {
          margin: 0;
        }
      }
      @include media($bp-sm) {
        padding-left:0;
        li {
          display: inline-block;
          padding: 1px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    @include element(finish-list2){
      li{
        //margin-right:32px;
      }
      @include media($bp-sm) {
        li{
          margin-right:5px;
        }
      }
    }

    @include element(finish-size) {
      li{
        border:1px solid #cccccc;
        padding:4px !important;
        margin-right: 12px;
        line-height: 22px;
        font-size: 12px;
        margin-bottom: 5px;
        &._selected{
          border:2px solid #c8893b;
        }
        &:last-child {
          margin: 0;
        }
      }
      @include media($bp-md) {
        li{
          border:2px solid #cccccc;
          padding: 4px 4px 0;
          &:last-child {
            margin: 0;
          }
        }
      }
    }

    @include element(state) {

      @include media($bp-sm) {
        margin-top:10px;
      }
    }



    @include element(share) {
      display: flex;
      justify-content: space-around;
      list-style: none;
      margin: 0 auto 1em auto;

      @include child('label') {
        display: none;

        @include media($bp-md) {
          display: block;
        }
      }

      .hero-section__social-group & {
        display: none;
      }

      @include media($bp-md) {
        display: none;

        .hero-section__social-group & {
          display: block;
          line-height: normal;
        }

        .hero-section__social-group--no-reviews & {
          display: flex;
          margin: 0 1em 0 0;
        }
      }

      // a {
      //   text-decoration: none;
      // }
      // img {
      //   display: inline-block;
      //   height: 24px;
      //   margin: 0 .5em;
      //   width: 24px;
      // }
    }

    @include element(social-group) {
      font-size: em(13);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0;

      * > {
        width: 33.3333%;
      }

      @include modifier('no-reviews') {
        justify-content: flex-start;

        * > {
          width: auto;
        }
      }

      @include media($bp-sm) {
        flex-direction: row;
      }

      @include media($bp-md) {
        margin-top: 0.5em;
      }
    }

    @include element(options) {
      line-height: normal;
      margin: 0.5em 0;
      padding-top: 0.5em;
    }

    @include element(left) {
      display: block;
      width: 100%;
      @include media($bp-md) {
        display: inline-block;
        margin-top: 10px;
        vertical-align: bottom;
        width: 70%;
      }
    }


    @include child('favorite') {

      @include block('favorite') {

        @include child('icon') {
          display: block;

          @include child('unliked') {
            display: block;
          }

          @include child('liked') {
            display: none;
          }

          @include state('active') {

            .favorite__icon__unliked {
              display: none;
            }

            .favorite__icon__liked {
              display: block;
            }
          }

        }
      }

    }

    @include element(right) {
      display: block;
      text-align: right;
      width: 100%;
      @include media($bp-md) {
        display: inline-block;
        vertical-align: top;
        margin-left: -4px;
        width: 30%;
      }
    }

    @include element(required) {
      line-height: 1.5em;
    }

    @include element(required-parts) {
      margin-bottom: 1em;
      > * {
        vertical-align: top;
      }
    }

    @include element(subtitle) {
      margin: 1em 0;
    }

    @include element(thumbs) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 80%;
    }

    @include element(thumbs-image) {
      border: 1px solid $c_light-gray;
      width: 75px;
      height: 75px;
      margin-right: 1em;
      overflow: hidden;
      position: relative;
      &:last-child {
        margin-right: 0;
      }

      & > img {
        height: 100%;
        width: auto;
      }

      @include modifier('360') {
        .hero-section__thumbs-icon {
          width: 75px;
          height: 75px;
          width: auto;
          opacity: .75;
        }
      }
    }

    @include element(thumbs-video) {
      display: block;
      position: relative;
      border-left: 2px solid $c_white;
      border-right: 2px solid $c_white;
      img {
        height: 100%;
        width: auto;
      }
    }

    @include element(thumbs-icon) {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: .5;
      background-position: 50%;
      background-size: 80%;
    }

    @include element(fcr-title) {
      color: $c_text-dark;
      text-transform: uppercase;
    }

    @include element(fcr-title) {
        line-height: 1.6em;
    }
    @include element(tbox) {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.4);
      z-index: 999;
      display: none;
      h1{
        font-weight: 400;
        display: inline;
        font-size: 18px;
      }
    }
    @include element(tbox-small) {
      width: 280px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff;
      padding: 20px 30px;
      box-sizing: border-box;
      text-align: center;
      img{
        width: 100%;
      }
    }
    @include element(text-frame){
      width: 120px;
      position: absolute;
      top: 43px;
      right: 0px;
      display: none;
      padding: 12px;
      line-height: 1;
      text-align: center;
      background: #ffffff;
      box-sizing: border-box;
      border: 1px solid #5b7f95;
      border-width: 0px 1px 1px;

      img{
        width: 100%;
      }
    }
    @include element(wx-saoma) {
      font-size: 12px;
      margin-top: 6px;
      font-weight: 600;
      color: #1f1f1f;
    }

    @include element(wx-cr){
      font-size: 14px;
      margin-top: 8px;
      font-weight: 600;
      color: #1f1f1f;
    }
    @include element(tbox-small-topBox){
      margin-top: 7px;
      font-weight: 600;
      color: #1f1f1f;
      span{
        display: block;
        line-height: normal;
        font-size: 16px;
      }
      span.hero-section__tbox-small-span{
        margin-top: 7px;
      }
      .span_moen{
        display: inline-block;
        color: rgb(91, 127, 149);
      }

    }


  }


.moen-pdp-hero-modal {

   display: none;

  .moen-pdp-hero-modal-mask{
    position: fixed;
    inset: 0;
    z-index:1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    pointer-events: none;
    //transition: .3s all;
  }

  .moen-pdp-hero-modal-container{
    position: fixed;
    inset: 0;
    z-index: 1001;
    overflow: auto;
    outline: 0;
  }

  .moen-pdp-hero-modal-container-warp{
    height: 100%;
    text-align: center;
    pointer-events: none;
  }


  .moen-pdp-hero-modal-container-warp-body{
    position: absolute;
    inset: 0;
    overflow: hidden;
  }

  .moen-pdp-hero-modal-container-close{
    position: fixed;
    top: 32px;
    right: 32px;
    display: flex;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    padding: 12px;
    outline: 0;
    border: 0;
    cursor: pointer;
    z-index: 1002;

    .anticon-close{
      font-size: 18px;
      display: inline-flex;
      align-items: center;
      color: inherit;
      font-style: normal;
      line-height: 0;
      text-align: center;
      text-transform: none;
      vertical-align: -0.125em;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
    }
  }

  .moen-pdp-hero-modal-container-warp-body-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10px;
  }

}



  /// .pdp-feature-innovations
  @include block('pdp-feature-innovations') {
    line-height: normal;
    margin-top: 30px;


    /// .pdp-feature-innovations--sm
    @include modifier('sm') {


      /// screen width > 768px
      @include media($bp-md) {
        display: none;
      }

    }


    /// ..pdp-feature-innovations--xl
    @include modifier('xl') {
      display: none;


      /// screen width > 768px
      @include media($bp-md) {
        display: block;
      }

    }


    /// .pdp-feature-innovations__logo
    @include child('logo') {

    }


    /// .pdp-feature-innovations__logo-image
    @include child('logo-image') {
      display: block;
      margin: 0 auto;
      max-width: 190px !important;
    }

  }




  /// .annoucement-label
  @include block('annoucement-label') {
    margin: 0 auto 20px;
    max-width: 190px;
    text-align: center;


    /// .annoucement-label--v1
    @include modifier('v1') {
      font-size: 12px;
      margin-top: 5px;
    }


    /// .annoucement-label--v2
    @include modifier('v2') {
      font-size: 12px;
      margin: 5px 0 5px 45px;
      text-align: left;
    }

  }



  /// .pdp-banner
  @include block('pdp-banner') {
    margin-left: auto;
    margin-right: auto;
    min-width: $min-width;
    width: 100%;


    /// .pdp-banner--image-max
    @include modifier('image-max') {
      max-width: 1400px;
    }


    /// .pdp-banner--container-max
    @include modifier('container-max') {
      max-width: $max-width;
    }


    /// .pdp-banner__image
    @include child('image') {
      display: block;
      height: auto;
      width: 100%;
    }


  }


  //.selling-poinnt-section
  @include block(selling-poinnt-section){
    padding: 0 1em;

    .selling-poinnt-section-title{
      font-size: 16px;
      color: #5B7F95;
      font-weight: 600;
      font-family: $din-light;
      @include media($bp-md) {
        margin-bottom: 5px;
      }
    }


    .selling-poinnt-section-line{
      width: 100%;
      height: 1px;
      border-top: 1px solid #ECECEC;
      margin: 12px 0;
      @include media($bp-md) {
        display: none;
      }
    }
    .selling-poinnt-section-list{
      margin-bottom: 22px;
      list-style: none;
      @include media($bp-md) {
        margin-bottom: 10px;
        max-height: 200px;
        overflow-y: auto;
        padding-right: 30px;
      }
    }

    .selling-poinnt-section-list::-webkit-scrollbar {
       width: 3px;
       height: 1px;
    }

    .selling-poinnt-section-list::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
    border-radius: 6px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #5B7F95;
  }

  .selling-poinnt-section-list::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background: #ECECEC;
  }

  .selling-poinnt-section-list li:before {
    color: #5b7f95;
    font-size: .70rem;
    content: "●";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
  .selling-poinnt-section-list li{
    position: relative;
    padding-left: 1.2rem;
    font-size: 14px;
    color: #51575B;
    line-height: 20px;
  }

  .selling-poinnt-section-list li:not(:last-child){
     margin-bottom: 8px;
  }

}

@include block(product-desc-images-carousel){


  .slick-dots{
    position: absolute;
    bottom: 25px;
  }
  .slick-dots li{
    margin: 0;
    background: #ffff;
    height: 2.5px;
    width: 28px;
  }
  .slick-dots .slick-active{
     background: #5B7F95;
  }
  .slick-dots li:first-child{
     border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

  }
  .slick-dots li:last-child{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .slick-dots li button:before{
    content: '';
  }
  .slick-list {

    padding:0 5% 0 7px !important;

    @include media($bp-md) {
       padding: 0px !important;
    }
  }

  .desc-image-carousel-a{
     display: block;
    padding: 0 .5em;
  }
  .desc-image-carousel-a img{
    height: auto;
    width: 100%;
    border-radius: 13px;
  }
}

@include block(product-detail-attribute){
    margin-top: 22px;
    padding: 0 1em;

  .selling-poinnt-section-title{
    font-size: 16px;
    color: #5B7F95;
    font-weight: 600;
    font-family: $din-light;
    @include media($bp-md) {
       margin-bottom: 12px;
    }
  }

  .selling-poinnt-section-title .attribute_copy{
    margin-left: 3px;
    position: relative;
    top:-1px;

    @include media($bp-md) {
      cursor: pointer;
    }
  }

  .selling-poinnt-section-title .tooltip{
    position: relative;
    display: inline-block;
    cursor:auto;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 75px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 12px;
    /* 位置 */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: 18px;
    margin-top: -20px;
    /* 淡入效果 */
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltip .tooltiptext:after{
    content: '';
    width: 0;
    height: 0;
    border-width: 4px;
    border-color: transparent transparent transparent #000;
    border-style: solid;
    position: absolute;
    left: -8px;
    top: 8px;
    transform: rotate(180deg);
  }

  .tooltip .tooltip_open{
    visibility: visible !important;
    opacity: 1 !important;
  }


  .selling-poinnt-section-line{
    width: 100%;
    height: 1px;
    border-top: 1px solid #ECECEC;
    margin: 12px 0;
    @include media($bp-md) {
      display: none;
    }
  }
  .product-detail-attribute-container{
    display: flex;
    flex-direction: column;
  }
  .product-detail-attribute-container-list{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    //max-height: 195px;
    overflow: hidden;
    transition: max-height .2s ease;
  }

  .product-detail-attribute-container-list .attribute-container-list-copy {
    display: none;
  }
  .attribute-container-list-row{
     display: flex;
     width: 100%;
     align-items: center;
     flex-wrap: wrap;
  }
  .attribute-container-list-row span{
    font-size: 13px;
    color: #51575B;
  }
  .attribute-container-list-title{
      font-size: 13px;
      color: #51575B;
      font-weight: 600;
      text-align-last: justify;
      width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .attribute-container-list-value{
      color: #51575B;
      font-size: 13px;
   }
  .product-detail-arrow i{
     border: solid #5B7F95;
     border-width: 0 1px 1px 0;
     display: inline-block;
     padding: 3px;
    position: relative;
    top: -2px;
    margin-left: 5px;
  }


  .product-detail-arrow-down i {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .product-detail-arrow-up i {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

}

@include block(product-detail-arrow-text){
   color:#5B7F95;
   font-size: 13px;
   margin-top: 10px;
   position: relative;
   display: none;

  @include media($bp-md) {
    cursor: pointer;
  }

  .open{
    display: none;
  }
  .packUp{
    display: none;
  }
}


@include block(product-detail-attribute-feature){
  margin-top: 22px;
  padding: 0 1em;

  .product-detail-attribute-feature-list{
     display: flex;
     flex-direction: column;
     row-gap: 14px;
  }

  .product-detail-attribute-feature-list-item{
      width: 100%;
      position: relative;
      height: 48px;
      border-radius: 2px;
      background: linear-gradient(to left, #FFFFFF 0%, rgba(91, 127, 149, 0.12) 100%);
      display: flex;
      align-items: center;
      padding: 0 14px;
      justify-content: space-between;
  }

  .feature-list-item-title{
      color: #5B7F95;
      font-size: 13px;
      font-weight: 600;
  }
  .feature-list-item-right{
     display: flex;
     align-items: center;
     column-gap: 30px;
  }
  .feature-list-item-icon{
     cursor: pointer;
     position: relative;
  }
  .feature-list-item-icon-tag::after{
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background: #5B7F95;
      opacity: 0.2;
      border-radius: 50%;
      left: -5px;
      top: 4px;
      z-index: 1;
    @include media($bp-md) {
      top: 14px;
    }
  }

   .feature-list-item-icon a{
      position: relative;
   }

  .feature-list-item-icon-tag a::before{
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    top: -8px;
    left: -11px;
    z-index: 2;
  }

    .feature-list-item-size{
       position: relative;
    }
    .feature-list-item-size-img {
        width: 76px;
    }

      .feature-list-item-water{
       width: 19px !important;
      }

    .feature-list-item-size-img img{
      display: block;
      width: 100%;
    }
  .feature-list-item-size-icon{
      position: absolute;
      bottom: 0;
      right: 0;
  }
  .feature-list-item-icon .a-contact{
      text-decoration: none;
      color: #5B7F95;
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: 500;
    font-size: 13px;
  }
}


@include block(product-detail-block-mb){
   display: block;
   @include media($bp-md) {
     display: none;
   }
}

@include block(product-detail-block-pc){
  display: none;
  @include media($bp-md) {
    display: block;
    .selling-poinnt-section{
      margin-top: 1em;
    }
  }
}


