
// .plmr-c-nav-social-networks
@include plmr-component('nav-social-networks') {
  margin-bottom: 30px;


  // screen width > 992px
  @include plmr-mq('lg') {
    margin-bottom: 0;
    margin-right: 20px;
  }


  // .plmr-c-nav-social-networks__list
  @include element('list') {
    display: flex;
  }


  // .plmr-c-nav-social-networks__item
  @include element('item') {
    margin-right: 20px;


    // .plmr-c-nav-social-networks__item:last-child
    &:last-child {
      margin-right: 0;
    }

  }


  // .plmr-c-nav-social-networks__link
  @include element('link') {
    @include plmr-link($color: plmr-color('base', 'white'), $is-color-hover: false);
  }

}
