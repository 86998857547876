//.plmr-c-add-to-cart
@include plmr-component('add-to-cart') {
  font-size: 0;

  //.plmr-c-add-to-cart__form-field
  @include element('form-field') {
    display: inline-block;
    vertical-align: middle;
  }

  //.plmr-c-add-to-cart__form-button
  @include element('form-button') {
    margin-left: 15px;
    min-height: 40px;
    vertical-align: middle;

    @include plmr-mq(md) {
      margin-left: 45px;
    }
  }

  //.plmr-c-add-to-cart__label
  @include element('label') {
    @include plmr-font-face($family: 'bold');
    color: plmr-color('brand', 'primary');
    display: none;
    font-size: 1.143rem;
    padding-bottom: 8px;
    text-transform: uppercase;

    @include plmr-mq(md) {
      display: block;
    }
  }
}






// .plmr-c-add-to-cart-status
@include plmr-component('add-to-cart-status') {
  color: plmr-color('status', 'confirmation');
  font-size: 0.9rem;
  margin-top: 0.5rem;
  opacity: 0;
  transition: all 5ms ease;


  // .plmr-c-add-to-cart-status__icon
  @include element('icon') {
    height: 15px;
    width: 15px;
  }


  // .plmr-c-add-to-cart-status._show
  @include plmr-state('show') {
    opacity: 1;
  }

}
