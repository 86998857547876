/*
 * https://gist.github.com/jonathantneal/a9b8901273475d261dd3
 * http://alistapart.com/article/creating-intrinsic-ratios-for-video
 *
 */


/* USAGE:

.my-container {
    @include fluid-aspect(16 9);
}

*/
@mixin fluid-aspect($ratio: 1 1, $selector: '> :first-child') {
  $selector: unquote($selector);

  overflow: hidden;
  padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
  position: relative;

  #{$selector} {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@mixin intrinsic-ratio($ratio: 1 1) {
  display: block;
  padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
  width: 100%;
}
