/**
@doc _docs/page-title.md
@section Page Title
@childof Content
@page Content Page Title
@status in progress
@hippo page title
@example
_markup/page-title.hbs
*/

/**
@section With CTA
@sectionof Page Title
@example
_markup/page-title--with-cta.hbs
*/

// .plmr-c-page-title
@include plmr-component('page-title') {
  padding: 2rem 0;


  // .plmr-c-page-title__title
  @include element('title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.5rem;
    line-height: 1.333;
    margin: 0;
    padding-bottom: 15px;


    // screen width > 768px
    @include plmr-mq('md') {
      font-size: 2rem;
      line-height: 1.125;
    }

  }


  // .plmr-c-page-title__eyebrow
  @include element('eyebrow') {
    @extend .plmr-c-banner__title--italic;
    font-size: 16px;
    line-height: 21px;


    // screen width > 768px
    @include plmr-mq(md) {
      font-size: 18px;
      line-height: 24px;
    }

  }


  // .plmr-c-page-title__container
  @include element('container') {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }


  // .plmr-c-page-title__description


  // .plmr-c-page-title__cta


  //.plmr-c-page-title__icon
  @include element('icon') {
    color: inherit;
    display: inline-block;
    height: 15px;
    margin-bottom: 5px;
    margin-right: 20px;
    vertical-align: middle;
    width: auto;

    // screen width > 768px
    @include plmr-mq(md) {
      height: 25px;
      margin-right: 30px;
    }
  }
}


/**
@section Centered
@sectionof Page Title
@example
_markup/page-title--centered.hbs
*/

// .plmr-c-page-title
@include plmr-component('page-title') {

  // .plmr-c-page-title--center
  @include modifier('centered') {


    // .plmr-c-page-title--center .plmr-c-page-title__description
    .plmr-c-page-title__description {
      width: 100%;
    }


    // .plmr-c-page-title--center .plmr-c-page-title__title, .plmr-c-page-title--center .plmr-c-page-title__eyebrow
    .plmr-c-page-title__title,
    .plmr-c-page-title__eyebrow {
      text-align: center;
    }

  }

}


/**
@section With Icon
@sectionof Page Title
@example
_markup/page-title--with-icon.hbs
*/
@include plmr-component('page-title') {
  //.plmr-c-page-title--with-icon
  @include modifier('with-icon') {
    .plmr-c-page-title__title {
      // screen width > 768px
      @include plmr-mq(md) {
        
      }
    }
  }
}

