
// .plmr-c-site-search
@include plmr-component('site-search') {
  color: plmr-color('base', 'white');



  // .plmr-c-site-search__flyout
  @include element('flyout') {
    background-color: plmr-color('brand', 'primary');
    display: none;
    left: 0;
    min-width: plmr-config('container', 'min-width');
    padding: 10px 0;
    position: absolute;
    top: 70px;
    transform: translateY(-100%);
    transition: height 250ms ease-in-out;
    // visibility: hidden;
    width: 100%;
    will-change: transform, visibility;



    // screen width > 768px
    @include plmr-mq('md') {
      top: 77px;
    }


    // .plmr-c-site-search__flyout._opened
    @include plmr-state('opened') {
      display: block;
      transform: translateY(0);
      // visibility: visible;
    }

  }


  // .plmr-c-site-search__button
  @include element('button') {
    cursor: pointer;
    height: 25px;
    position: relative;
    width: 25px;


    // .plmr-c-site-search__button._opened
    @include plmr-state('opened') {


      // .plmr-c-site-search__button._opened .plmr-c-site-search__button-icon--search
      .plmr-c-site-search__button-icon--search {
        opacity: 0;
      }


      // .plmr-c-site-search__button._opened .plmr-c-site-search__button-icon--close
      .plmr-c-site-search__button-icon--close {
        opacity: 1;
      }

    }

  }



  // .plmr-c-site-search__form
  @include element('form') {
    width: 100%;
  }



  // .plmr-c-site-search__button-icon
  @include element('button-icon') {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 250ms ease;



    // .plmr-c-site-search__button-icon--search
    @include modifier('search') {
      opacity: 1;
    }


    // .plmr-c-site-search__button-icon--close
    @include modifier('close') {
    }

  }





  // .plmr-c-site-search__container
  @include element('container') {
    display: flex;
    margin: 0 auto;
    max-width: plmr-config('container', 'max-width');
    padding: 0 15px;
    position: relative;


    // screen width > 768px
    @include plmr-mq('md') {
      padding-left: 25px;
      padding-right: 25px;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      padding-left: 40px;
      padding-right: 40px;
    }

  }


  // .plmr-c-site-search__input
  @include element('input') {
    @include plmr-font-face($family: 'regular');
    @include plmr-form-input-base();
    appearance: none;
    border: 0;
    border-radius: 0;
    color: plmr-color('text', 'secondary');
    font-size: 16px;
    line-height: 28px;
    padding: 11px 50px 11px 15px;
    text-overflow: ellipsis;
    width: 100%;


    // screen width > 768px
    @include plmr-mq('md') {
      padding-right: 55px;
    }

  }


  // .plmr-c-site-search__input-button
  @include element('input-button') {
    color: plmr-color('brand', 'secondary');
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;


    // screen width > 768px
    @include plmr-mq('md') {
      right: 40px;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      right: 55px;
    }

  }


  // .plmr-c-site-search__input-icon
  @include element('input-icon') {
    color: plmr-color('brand', 'secondary');
  }

}



// SearchSpring AutoComplete Flyout
// -----------------------------------------------------------------------------
[ss-autocomplete] {
  background: plmr-color('background', 'primary');
  color: plmr-color('text', 'primary');
  padding-top: 0 !important;
  top: 60px !important;


  .ss-ac-wrapper {
    max-width: plmr-config('container', 'max-width') !important;

  }

  #ss-ac-content {
    padding: 20px !important;
    width: 100% !important;
  }
}
