
// .plmr-c-mastfoot
@include plmr-component('mastfoot') {
  background-color: plmr-color('brand', 'primary');
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: plmr-color('base', 'white');
  min-width: plmr-config('container', 'min-width');
  padding-top: 23px;
  position: relative;
  z-index: plmr-z($layer: 'mastfoot');


  // .plmr-c-mastfoot__container
  @include element('container') {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: plmr-config('container', 'max-width');
    min-width: plmr-config('container', 'min-width');
    padding: 0 25px;
    width: 100%;


    // screen width > 992px
    @include plmr-mq('lg') {
      padding-left: 40px;
      padding-right: 40px;
    }


    // .plmr-c-mastfoot__container--secondary
    @include modifier('secondary') {
      flex-direction: column;
      padding-bottom: 20px;
      padding-top: 20px;


      // screen width > 992px
      @include plmr-mq('lg') {
        flex-direction: row;
      }

    }

  }


  // .plmr-c-mastfoot__main
  @include element('main') {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    max-width: plmr-config('container', 'max-width');
    padding: 0 25px;
    width: 100%;


    // screen > 992px
    @include plmr-mq('lg') {
      flex-direction: row;
      padding: 0 40px;
    }

  }


  // .plmr-c-mastfoot__company
  @include element('company') {

    // screen width > 992px
    @include plmr-mq('lg') {
      margin-right: percentage(100px / (1300px - 80px));
      max-width: 305px;
      order: 0;
    }

  }


  // .plmr-c-mastfoot__logo
  @include element('logo') {
    margin-bottom: 20px;
    margin-right: 20px;
  }


  // .plmr-c-mastfoot__select-language
  @include element('select-language') {
    margin-bottom: 30px;
  }


  // .plmr-c-mastfoot__nav-main
  @include element('nav-main') {
    display: none;


    // screen width > 768px
    @include plmr-mq('md') {
      display: flex;
      flex-grow: 1;
      justify-content: space-around;
      margin-bottom: 30px;
      order: 0;
    }


    // screen width > 992px
    @include plmr-mq('lg') {
      order: 1;
    }

  }


  // .plmr-c-mastfoot__secondary
  @include element('secondary') {
    background-color: plmr-color('brand', 'primary');
  }

}




