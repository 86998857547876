/**
@doc _docs/carousel-feature-staggered.md
@section Carousel Product Feature Staggered
@childof Carousels
@page Carousels Carousel Product Feature Staggered
@status in progress
@example
_markup/carousel-feature-staggered.hbs
*/
@include plmr-component('carousel-feature-staggered') {
  padding: 40px 0;
  position: relative;

  @include plmr-mq('md') {
    padding: 60px 0 100px;
  }

  @include plmr-mq('lg') {
    padding: 75px 0 120px;
  }

  //.plmr-c-carousel-feature-staggered::before,
  //.plmr-c-carousel-feature-staggered::after 
  // &::before,
  // &::after {
  //   @include plmr-pseudo;
  //   background-color: plmr-color('background', 'secondary');
  //   height: 40px;
  //   left: 0;
  //   min-width: 100%;
  //   position: absolute;
  //   z-index: 1;
  // }

  // //.plmr-c-carousel-feature-staggered::before
  // &::before {
  //   top: 0;

  //   @include plmr-mq('md') {
  //     height: calc(100% - 100px);
  //   }

  //   @include plmr-mq('lg') {
  //     // height: calc(100% - 463px);
  //     display: none;
  //   }
  // }

  // //.plmr-c-carousel-feature-staggered::after
  // &::after {
  //   bottom: 0;
  //   // min-width: calc(350 / 375 * 100%);

  //   @include plmr-mq('md') {
  //     height: 100px;
  //     // min-width: calc(713 / 768 * 100%);
  //   }

  //   @include plmr-mq('lg') {
  //     // height: 463px;
  //     height: 100%;
  //     max-width: calc(((100vw - 1300px) / 2) + 1209px);
  //     min-width: 0;
  //     width: calc(1209 / 1300 * 100%);
  //   }
  // }

  //.plmr-c-carousel-feature-staggered__inner
  @include element('inner') {
    @extend .plmr-l-container;
    font-size: 0;
    padding: 0;
    position: relative;
    z-index: 2;
  }

  //.plmr-c-carousel-feature-staggered__carousel
  @include element('carousel') {
    position: relative;

    @include plmr-mq('md') {
      margin: 0 auto;
      width: calc(683 / 768 * 100%);
    }

    @include plmr-mq('lg') {
      // display: inline-block;
      // vertical-align: top;
      width: calc(50%);
    }

    .plmr-c-carousel__arrow--next {
      right: 15px;

      @include plmr-mq('md') {
        right: 25px;
      }
    }

    .plmr-c-carousel__arrow--prev {
      left: 15px;

      @include plmr-mq('md') {
        left: 25px;
      }
    }

    //.plmr-c-carousel-feature-staggered__carousel--detail
    @include modifier('detail') {
      background-color: plmr-color('base', 'white');
      box-shadow: 20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.15);
      text-align: center;
      z-index: 2;

      @include plmr-mq('md') {
        margin-bottom: -75px;
        margin-left: 0;
      }

      @include plmr-mq('lg') {
        margin-bottom: calc(-575 / 1300 * 100%);
        margin-left: 0;
      }

      .plmr-c-carousel-feature-staggered__carousel-item {
        padding: 50px 63px 73px;

        @include plmr-mq('md') {
          padding: 75px 140px 130px;
        }

        @include plmr-mq('lg') {
          min-height: calc(50vw - 57px);
          padding-left: 125px;
          padding-right: 125px;
        }

        @include plmr-mq('xl') {
          min-height: calc(50vw - 57px);
          padding-bottom: 150px;
          padding-top: 150px;
        }

        @include plmr-mq('max') {
          min-height: 593px;
        }
      }
    }

    //.plmr-c-carousel-feature-staggered__carousel--image
    @include modifier('image') {
      z-index: 1;

      @include plmr-mq('md') {
        margin-right: 0;
      }
    }
  }

  //.plmr-c-carousel-feature-staggered__carousel-item
  @include element('carousel-item') {
    position: relative;

    &:not(:first-child) {
      display: none;
    }
  }

  //.plmr-c-carousel-feature-staggered__carousel-item-icon
  @include element('carousel-item-icon') {
    color: plmr-color('accent', 'dark');
    height: 45px;
    margin-bottom: 25px;
    width: 45px;

    @include plmr-mq('md') {
      margin-bottom: 35px;
    }
  }

  //.plmr-c-carousel-feature-staggered__carousel-item-title
  @include element('carousel-item-title') {
    @include plmr-font-face($family: 'light');
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.285rem;
    margin: 0;

    @include plmr-mq('md') {
      font-size: 2rem;
    }
  }

  //.plmr-c-carousel-feature-staggered__carousel-item-copy
  @include element('carousel-item-copy') {
    font-size: 1.143rem;
    line-height: 2rem;
    padding: 6px 0 40px;

    @include plmr-mq('md') {
      padding: 20px 0 50px;
    }
  }

  //.plmr-c-carousel-feature-staggered__carousel-item-image
  @include element('carousel-item-image') {
    max-width: 100%;
    width: 100%;
  }

  //.plmr-c-carousel-feature-staggered__dots
  @include element('dots') {
    padding-bottom: 40px;

    @include plmr-mq('md') {
      padding-bottom: 45px;
    }
  }
}
