
/**
@doc _docs/cardlist-accordion.md
@section Card List Accordion
@childof Card Lists
@page Cardlists Cardlist Accordion
@status in progress
@hippo card-list
@example
_markup/cardlist-accordion.hbs
*/
// .plmr-c-cardlist-accordion
@include plmr-component('cardlist-accordion') {
  @extend .plmr-l-container;
  padding: 40px 25px;
  width: 100%;

  @include plmr-mq(md) {
    padding: 50px 55px;
  }

  @include plmr-mq(lg) {
    padding: 50px 90px;
  }


  // .plmr-c-cardlist-accordion__container
  @include element('container') {
    background: plmr-color('brand', 'primary');
    box-shadow: 20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
    color: plmr-color('base', 'white');
    position: relative;
    transition: min-height 0.3s ease-in-out;

    @include plmr-mq(md) {
      box-shadow: none;
      height: 100%;
      margin-bottom: 50px;
      margin-right: 10%;
      min-height: 730px;
    }
  }


  // .plmr-c-cardlist-accordion__title
  @include element('title') {
    font-size: 1.714rem;
    line-height: 2.286rem;
    margin: 0;
    padding: 30px 20px;

    @include plmr-mq(md) {
      max-width: 40%;
      padding: 50px 40px 30px;
    }

    @include plmr-mq(lg) {
      font-size: 2rem;
      max-width: 35%;
    }
  }


  // .plmr-c-cardlist-accordion__item-label
  @include element('item-label') {
    @include plmr-font-face($family: 'bold');
    font-size: 1.143rem;
    line-height: 1.714rem;
    margin: 0;
    padding: 15px 20px;
    position: relative;
    text-align: left;
    transition: opacity 0.3s ease-in-out;
    width: 100%;

    // .plmr-c-cardlist-accordion__item-label:hover
    &:hover {
      cursor: pointer;
    }

    @include plmr-mq(md) {
      padding: 30px 30px 30px 40px;
      width: 40%;
    }

    @include plmr-mq(lg) {
      max-width: 35%;

      // .plmr-c-cardlist-accordion__item-label._sibling-hover
      @include plmr-state('sibling-hover') {
        opacity: 0.5;
      }
    }

    // .plmr-c-cardlist-accordion__item-label._toggled-open
    @include plmr-state('toggled-open') {
      background: lighten(plmr-color('brand', 'primary'), 19%);
      opacity: 1;
    }
  }


  // .plmr-c-cardlist-accordion__item-toggle-icon
  @include element('item-toggle-icon') {
    color: inherit;
    height: 13px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0%, -50%);
    transition: 0.25s ease-in-out;
    width: 13px;

    // .plmr-c-cardlist-accordion__item-toggle-icon::before,
    // .plmr-c-cardlist-accordion__item-toggle-icon::after
    &::before,
    &::after {
      @include plmr-pseudo();
      background: currentColor;
      height: 2px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.25s ease-in-out;
      width: 13px;
    }

    // .plmr-c-cardlist-accordion__item-toggle-icon::before
    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    // .plmr-c-cardlist-accordion__item-toggle-icon._toggled-open
    @include plmr-state('toggled-open') {
      // .plmr-c-cardlist-accordion__item-toggle-icon._toggled-open::before
      &::before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }

    @include plmr-mq(md) {
      display: none;
    }
  }


  // .plmr-c-cardlist-accordion__item-content
  @include element('item-content') {
    background: plmr-color('base', 'white');
    color: plmr-color('text', 'primary');
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s ease-in-out;

    @include plmr-mq(md) {
      box-shadow: 20px 10px 20px 0 rgba(plmr-color('base', 'black'), 0.1);
      // display: none;
      left: 40%;
      max-height: none;
      min-height: 100%;
      opacity: 0;
      position: absolute;
      right: -10%;
      top: 50px;
      visibility: hidden;

      // .plmr-c-cardlist-accordion__item-content._open
      @include plmr-state('open') {
        // display: block;
        opacity: 1;
        visibility: visible;
      }
    }

    @include plmr-mq(lg) {
      left: 35%;
    }
  }


  // .plmr-c-cardlist-accordion__item-content-inner
  @include element('item-content-inner') {
    padding: 30px 20px 40px;

    @include plmr-mq(md) {
      opacity: 0;
      padding: 40px;
      transition: visibility 0s, opacity 1s ease-in-out;
      visibility: hidden;

      // .plmr-c-cardlist-accordion__item-content-inner._visible
      @include plmr-state('visible') {
        opacity: 1;
        visibility: visible;
      }
    }

    @include plmr-mq(lg) {
      padding: 50px 40px;
    }
  }


  // .plmr-c-cardlist-accordion__item-content-image-container
  @include element('item-content-image-container') {
    height: 128px;
    min-width: 100%;
    overflow: hidden;
    position: relative;
    width: auto;

    @include plmr-mq(md) {
      height: 165px;
      opacity: 0;
      transition: visibility 0s, opacity 1s ease-in-out;
      visibility: hidden;

      // .plmr-c-cardlist-accordion__item-content-image-container._visible
      @include plmr-state('visible') {
        opacity: 1;
        visibility: visible;
      }
    }

    @include plmr-mq(lg) {
      height: 300px;
    }
  }


  // .plmr-c-cardlist-accordion__item-content-image
  @include element('item-content-image') {
    height: auto;
    left: 50%;
    max-width: 100%;
    // min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;

    @include plmr-mq(lg) {
      max-width: none;
      min-height: 100%;
    }
  }


  // .plmr-c-cardlist-accordion__item-content-link-wrapper
  @include element('item-content-link-wrapper') {
    margin-top: -15px;
    text-align: right;
    text-decoration: underline;

    @include plmr-mq(md) {
      margin-top: -25px;
    }

    @include plmr-mq(lg) {
      margin-top: -35px;
    }
  }


  // .plmr-c-cardlist-accordion__item-content-link
  @include element('item-content-link') {
    @include plmr-link($color: plmr-color('brand', 'tertiary'));
  }


  // .plmr-c-cardlist-accordion__item-content-title
  @include element('item-content-title') {
    color: plmr-color('brand', 'primary');
    font-size: 1.714rem;
    line-height: 2.286rem;
    margin: 0 0 15px 0;
    padding: 0;
  }


  // .plmr-c-cardlist-accordion__item-content-body
  @include element('item-content-body') {
    font-size: 1.143rem;
    line-height: 2rem;
    margin: 0 0 15px 0;
    padding: 0;
  }


  // .plmr-c-cardlist-accordion__item-content-footnote
  @include element('item-content-footnote') {
    @include plmr-font-face($family: 'italic');
    color: darken(plmr-color('text', 'secondary'), 8%);
    font-size: 0.8571rem;
    font-style: italic;
    line-height: 1.5rem;
    padding: 0;
  }


  // .plmr-c-cardlist-accordion__item-content-cta
  @include element('item-content-cta') {
    margin-top: calc(30px - 1em);
  }
}


/**
@doc _docs/cardlist-accordion--with-links.md
@section With Links
@sectionof Card List Accordion
@page Cardlists Cardlist Accordion
@example
_markup/cardlist-accordion--with-links.hbs
*/
