/**
@section Checkbox Input
@childof Forms
@page Forms Checkbox Input
@status in progress
@example
_markup/form-checkbox.hbs
*/
@include plmr-component('form-checkbox') {
  display: block;
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  //.plmr-c-form-checkbox__input
  @include element('input') {
    //.plmr-c-form-checkbox__input:checked
    &:checked + .plmr-c-form-checkbox__label,
    &:checked + .plmr-c-form-checkbox__label:hover {
      &::after {
        background-color: plmr-color('brand', 'primary');
      }

      &::before {
        border-color: plmr-color('brand', 'primary');
      }
    }

    //.plmr-c-form-checkbox__input:focus
    &:focus + .plmr-c-form-checkbox__label::before {
      outline: 1px dashed currentColor;
    }
  }

  //.plmr-c-form-checkbox__label
  @include element('label') {
    color: darken(plmr-color('text', 'secondary'), 8%);
    cursor: pointer;
    display: block;
    font-size: 16px;
    line-height: 18px;
    padding-left: 36px;

    &:hover {
      &::after {
        background-color: plmr-color('accent', 'light');
      }
    }

    //.plmr-c-form-checkbox__label::before pseudo element
    &::before {
      @include plmr-pseudo;
      // border: 1px solid plmr-color('brand', 'primary');
      border: 1px solid plmr-color('accent', 'light');
      border-radius: 2px;
      height: 18px;
      left: 0;
      top: 0;
      width: 18px;
    }

    //.plmr-c-form-checkbox__label::after pseudo element
    &::after {
      @include plmr-pseudo;
      background-color: transparent;
      // background-color: plmr-color('brand', 'primary');
      height: 10px;
      left: 0;
      margin-left: 4px;
      margin-top: 4px;
      top: 0;
      transition: background-color 0.25s ease;
      width: 10px;
    }
  }
}
