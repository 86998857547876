// .innovations
@include block(innovations) {

	// List of benefits
	// .innovations__benefits
	@include element(benefits) {
		display: block;
		text-align: center;
		padding: 80px 35px 0;
		font-size: 0px;
		line-height: 0px;
		position: relative;
		z-index: 1;
		overflow: hidden;
		
		// .innovations__benefits .container
		.container {
			min-width: 0px;
		}
		
		// .innovations__benefits .innovations__section-heading
		.innovations__section-heading {
			margin-bottom: 20px;
			padding:0;
		}
		
		// .innovations__benefits .section-heading--1
		.section-heading--1 {
			font: normal 28px/32px $proxima-thin;
			color: $c_mid-gray;
			margin-bottom: 40px;
			padding:0;
		}
		// .innovations__benefits__heading
		@include child("heading") {
			max-width: 650px;
			margin: 0 auto;
			font: normal 28px/32px $proxima-thin;
			color: $c_mid-gray;
			padding-bottom: 20px;
			@include media($bp-md) {
				font: normal 38px/48px $proxima-thin;
			}
		}
		
		// .innovations__benefits__disclaimer
		@include child("disclaimer") {
		 margin: 30px auto;
		 width: 100%;
		 
		 
		 @include media($bp-md) {
			 width: 600px;
		 }
		 
		}
		
		// .innovations__benefits__intro-text
		@include child("intro-text") {
			max-width: 650px;
			margin: 0 auto;
			font: normal 16px/1.55555em $proxima-regular;
			padding-bottom: 50px;
			@include media($bp-md) {
				font-size: 18px;
			}
		}
		@include media($bp-md) {
			padding: 80px 15px 75px;
			.innovations__section-heading {
				margin-bottom: 30px;
			}
			.section-heading--1 {
				font: normal 38px/42px $proxima-thin;
				margin-bottom: 70px;
			}
		}
		@include media($bp-lg) {
			padding: 80px 0 100px;
		}
		
		// .innovations__benefits__flexbox
		@include child("flexbox") {
			@include media($bp-md) {
				.flexwrap & {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: stretch;
					align-content: flex-start;
					justify-content: center;
				}
			}
		}
		
		//.innovations__benefits__cta
		@include child("cta") {
			padding:40px 0 20px;
			font-size: 16px;
			.button {
				min-width: 150px;
			}
			
		}
		
		// .innovations__benefits--no-padding
		@include modifier("no-padding") {
			padding-bottom: 0 !important;
		}
		// .innovations__benefits--overview
		@include modifier("overview") {
			padding-bottom: 60px;
		}

	}
	
	
	// Individual Benefit
	// .innovations__benefit
	@include element(benefit) {
		display: block;
		width: 100%;
		max-width: 250px;
		margin: 0 auto;
		margin-bottom: 30px;
		font: normal 16px/24px $proxima-regular;
		color: $c_moen-gray;
		text-decoration: none !important;
		p,h1,h2,h3 {
			font: inherit;
			padding:0;
			margin:0;
		}
		// .innovations__benefit__title
		@include child(title) {
			font: normal 18px/1.22222222em $proxima-regular;
			color: $c_text-base;
			margin-bottom: 15px;
			// .innovations__benefit__title--blue
			@include modifier("blue") {
				color: $c_moen-bright-blue;
			}
		}
		// .innovations__benefit__icon
		@include child("icon") {
			display: inline-block;
			width: 83px;
			height: 83px;
			text-align: center;
			margin-bottom: 30px;
			display: inline-flex;
			flex-direction: column;
			align-content: center;
			align-items: center;
			justify-content: center;
			@include modifier("small") {				
				display: inline-block;
				width: 52px;
				height: 52px;
				text-align: center;
				margin-bottom: 25px;
				display: inline-flex;
				flex-direction: column;
				align-content: center;
				align-items: center;
				justify-content: center;
			}
		}
		
		@include media($bp-md) {
			display: inline-block;
			vertical-align: top;
			width: calc(50% - 120px);
			margin: 0 60px 60px;
			.innovations__benefit__title {
				font-size: 21px;
				margin-bottom: 18px;
			}
		}
		@include media($bp-lg) {
			width: calc(25% - 35px);
			margin: 0 17.5px;
// 			max-width: 225px;
			max-width: 260px;
			p:not(.innovations__benefit__title) {
				max-width: 225px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		@include media($bp-xl) {
			width: calc(25% - 65px);
			margin: 0 32.5px;
			
		}
		// .innovations__benefit--bordered
		@include modifier("bordered") {
			border: 1px solid #cbcbcb;
			padding: 30px 32px 46px;
			&:hover {
				border-color: $c_moen-bright-blue;
			}
			@include media($bp-md) {
				
				width: calc(50% - 20px);
				margin: 0 10px 20px;
			}
			@include media($bp-lg) {
				width: calc(25% - 20px);
				margin: 0 10px;
				max-width: 265px;
			}
			@include media($bp-xl) {
				width: calc(25% - 20px);
				margin: 0 10px;
			}
			
			
		}
		// .innovations__benefit--wide
		@include modifier("wide") {
			@include media($bp-lg) {
				width: calc(33.333% - 90px);
				margin: 0 45px;
				max-width: 300px;
			}
			@include media($bp-xl) {
				width: calc(33.333% - 90px);
				margin: 0 45px;
			}
		}
		
		
	}
	
	
	// .innovations__benefits-splash 
	@include element(benefits-splash) {
		position: relative;
		width: 100%;
		height: 180px;
		margin-top: 50px;
		picture,img {
			position: absolute;
			bottom: 0;
			left:50%;
			transform: translateX(-50%);
			min-height: 180px;
			width: auto;
			min-width: 100vw;
		}
		@include media(480px) {
			height: 384px;
			margin-top: 20px;
			picture,img {
				min-height: 384px;
			}
		}
		@include media($bp-lg) {
			height: 488px;
			margin-top: 100px;
			picture,img {
				min-height: 488px;
			}
		}
		@include media($bp-xl) {
			height: 600px;
			picture,img {
				min-height: 600px;
			}
		}
		@include media($max-width) {
			height: auto;
			picture {
				position: static;
				transform: none;
			}
			img {
				position: relative;
			}
		}
	}
	
}
