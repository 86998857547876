/* Vertical Centering Mixin using CSS Flexbox Syntax
 *
 * @param $height _required_  the parent container height
 *
 */

@mixin vertical-center($height) {
  align-items: center;
  display: flex;


  height: $height;
  // Check for argument validity
  @if $height != '' {
    @content;
  } @else {
    @error '$height value is not defined for mixin `vertical-center`.';
  }
}

/* Vertical and Horizontal Centering Mixin using CSS Flexbox Syntax */

@mixin centerer() {
  align-items: center;
  display: flex;
  justify-content: center;

}
