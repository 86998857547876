

/**
@section Select Input
@childof Forms
@page Forms Select Input
@status in progress
@example
_markup/form-select.hbs
*/

// .plmr-c-form-select
@include plmr-component('form-select') {
  color: plmr-color('text', 'primary');
  display: block;
  // min-height: 50px;
  position: relative;
  width: 100%;


  // .plmr-c-form-select__input
  @include element('input') {
    @include plmr-form-input-base();
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    padding: 17px 40px 17px 18px;
  }


  // .plmr-c-form-select__option
  @include element('option') {
    color: currentColor;

    // disabled
    &[disabled] {
      color: lighten(plmr-color('text', 'secondary'), 10%);
    }
  }


  // .plmr-c-form-select__optgroup
  @include element('optgroup') {
    color: currentColor;
  }

  // .plmr-c-form-select__input-wrapper
  @include element('input-wrapper') {
    min-height: 50px;
    position: relative;

    // .plmr-c-form-select__input-wrapper::before, .plmr-c-form-select__input-wrapper::after pseudo-elements
    &::before,
    &::after {
      @include plmr-pseudo;
      border: solid transparent;
      height: 0;
      pointer-events: none;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
    }

    // .plmr-c-form-select__input-wrapper::before pseudo-element
    &::before {
      border-color: rgba(plmr-color('text', 'secondary'), 0);
      border-top-color: plmr-color('text', 'secondary');
      border-width: 7px;
      margin-right: 17px;
      margin-top: 4.5px;
    }


    // .plmr-c-form-select__input-wrapper::after pseudo-element
    &::after {
      border-color: rgba(plmr-color('base', 'white'), 0);
      border-top-color: plmr-color('base', 'white');
      border-width: 6px;
      margin-right: 18px;
      margin-top: 2.5px;
    }
  }

  //.plmr-c-form-select__label
  @include element('label') {
    @include plmr-form-label-base();
  }
}


/**
@section Quantity
@sectionof Select Input
@example
_markup/form-select--quantity.hbs
*/
// .plmr-c-form-select
@include plmr-component('form-select') {
  // .plmr-c-form-select--quantity
  @include modifier('quantity') {
    color: plmr-color('text', 'secondary');

    .plmr-c-form-select__input-wrapper {
      max-width: 65px;
      min-height: 40px;

      &::after {
        display: none;
      }

      &::before {
        border-width: 4px;
        margin-top: 3px;
      }
    }

    .plmr-c-form-select__input {
      @include plmr-font-face($family: 'bold');
      font-size: 16px;
      line-height: 14px;
      padding: 13px 30px 13px 20px;
    }
  }
}
