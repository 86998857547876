
// .plmr-c-icon
@include plmr-component('icon') {

  // .plmr-c-icon__path, .plmr-c-icon__polygon, .plmr-c-icon__polyline, .plmr-c-icon__rect  
  &__path,
  &__polygon,
  &__polyline,
  &__rect {
    fill: currentColor;
    transition: 250ms;
  }


  // .plmr-c-icon__circle
  &__circle {
    stroke: currentColor;
    stroke-width: 1;
    transition: 250ms;
  }

}